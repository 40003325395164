import moment from 'moment';
import { z } from 'zod';

import { getMaxDate } from './PayWithAmax.helpers';
import { messages } from './PayWithAmax.messages';

export const schema = z
    .object({
        scheduledDate: z.nullable(z.date()),
    })
    .passthrough()
    .refine((value) => !(value.scheduledDate && moment(value.scheduledDate).isSameOrBefore(moment().add(1, 'days'))), {
        message: messages.scheduledDateShouldBeGreater,
        path: ['scheduledDate'],
    })
    .refine((value) => !(value.scheduledDate && moment(value.scheduledDate).isSameOrAfter(getMaxDate())), {
        message: messages.scheduledDateShouldNoLater,
        path: ['scheduledDate'],
    });
