import { QueryOptions } from '@approvalmax/data';
import { objectHelpers } from '@approvalmax/utils';
import { useGetLegacy } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { UseGetAuditReportDetailsParams, UseGetAuditReportDetailsResponse } from './useGetAuditReportDetails.types';

export const useGetAuditReportDetails = (
    params: Partial<UseGetAuditReportDetailsParams>,
    queryOptions?: QueryOptions<UseGetAuditReportDetailsResponse>
) =>
    useGetLegacy<UseGetAuditReportDetailsResponse>(requestsApiPaths.getAuditReportDetails, {
        queryParams: params,
        queryOptions: {
            enabled: queryOptions?.enabled === false ? false : Boolean(params.requestId && params.companyId),
            ...queryOptions,
        },
        mapData: objectHelpers.pascalCaseToCamelCase,
    });
