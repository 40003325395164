import { Reference } from '@approvalmax/types';
import { arrayHelpers, compareHelpers, errorHelpers } from '@approvalmax/utils';
import map from 'lodash/map';
import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import { createSelector } from 'reselect';

import { getMatrixDefinition } from '../../config/matrixDefinitions';
import { MatrixDefinition } from '../../config/matrixDefinitions.shared';
import { MatrixType } from '../../types/matrix';
import { ExpandedTemplateUser } from '../../types/selectors';
import { getActiveEditingMatrix, getActiveMatrix, getActiveTemplate, getPage } from '../pageSelectors';
import { sortConditions } from '../stepSelectors';
import { manualRateFields, qbooksPayeeFields } from './templateSelectors.constants';
import { messages } from './templateSelectors.messages';

const getSubmitterRulesPreviewText = (
    integrationCode: domain.IntegrationCode | null,
    rules: domain.MatrixRule[],
    matrixDefinition: MatrixDefinition
) => {
    const showCustomSupplierText =
        integrationCode &&
        [
            domain.IntegrationCode.XeroPo,
            domain.IntegrationCode.XeroBill,
            domain.IntegrationCode.XeroInvoice,
            domain.IntegrationCode.XeroQuote,
        ].includes(integrationCode);

    const showCustomVendorText =
        integrationCode &&
        [
            domain.IntegrationCode.QBooksBill,
            domain.IntegrationCode.QBooksPo,
            domain.IntegrationCode.QBooksExpense,
        ].includes(integrationCode);

    const showCustomNetSuiteVendorText =
        integrationCode &&
        [domain.IntegrationCode.NetSuiteBill, domain.IntegrationCode.NetSuitePO].includes(integrationCode);

    const showCustomBeneficiaryText =
        integrationCode && [domain.IntegrationCode.XeroAirwallexBatchPayment].includes(integrationCode);

    const getConditionText = (condition: domain.MatrixCondition, conditionPosition?: number) => {
        if (manualRateFields.includes(condition.fieldSystemPurpose)) {
            return conditionPosition && conditionPosition !== 0
                ? 'with a manual exchange rate'
                : 'a manual exchange rate';
        }

        const isNegativeExactConstraint = condition.conditionType === domain.ConditionType.NegativeExactValuesCondition;
        const fvpPattern = isNegativeExactConstraint
            ? messages.submitterRulesPreviewFieldNotValuePattern
            : messages.submitterRulesPreviewFieldValuePattern;

        let values: Reference[] = (condition as any).exactValues || [];
        let valuesText: string;

        if (values.length === 0) {
            return '';
        }

        if (values.length < 3) {
            valuesText = map(values, (fcv) => fvpPattern({ value: fcv.text })).join(', ');
        } else {
            const v1 = fvpPattern({ value: values[0].text });
            const v2 = fvpPattern({ value: values[1].text });

            valuesText = `${v1}, ${v2} ...`;
        }

        const fieldName = selectors.field.getFieldNameBySystemPurpose(
            condition.fieldSystemPurpose,
            integrationCode,
            condition.fieldName
        );

        return isNegativeExactConstraint
            ? messages.submitterRulesPreviewNotFieldPattern({
                  fieldName,
                  values: valuesText,
              })
            : messages.submitterRulesPreviewFieldPattern({
                  fieldName,
                  values: valuesText,
              });
    };

    if (!rules || !rules.length) {
        if (showCustomSupplierText) {
            return messages.submitterRulesPreviewSubmitsWith({
                rules: messages.supplierFieldAnyContactText,
            });
        }

        if (showCustomVendorText) {
            return messages.submitterRulesPreviewSubmitsWith({
                rules: messages.vendorFieldAnyText,
            });
        }

        if (showCustomNetSuiteVendorText) {
            return messages.submitterRulesPreviewSubmitsWith({
                rules: messages.vendorFieldAnyText,
            });
        }

        if (showCustomBeneficiaryText) {
            return messages.submitterRulesPreviewSubmitsWith({
                rules: messages.beneficiaryFieldAnyText,
            });
        }

        return messages.submitterRulesPreviewAlwaysSubmit;
    }

    let res = '';

    rules.forEach((rule) => {
        let ruleText: string | null = null;

        if (showCustomSupplierText) {
            const conditionWithContact = rule.conditions.find((condition) =>
                [domain.FieldSystemPurpose.XeroSupplier, domain.FieldSystemPurpose.XeroCustomer].includes(
                    condition.fieldSystemPurpose
                )
            );

            if (!conditionWithContact) {
                ruleText = messages.supplierFieldAnyContactText;
            } else {
                switch (conditionWithContact.conditionType) {
                    case null:
                        ruleText = messages.supplierFieldAnyContactText;
                        break;

                    case domain.ConditionType.ServerCondition:
                        switch (conditionWithContact.serverConditionType) {
                            case domain.ServerConditionType.AllContacts:
                                ruleText = conditionWithContact.allowCreation
                                    ? messages.supplierFieldAnyContactOrNewText
                                    : messages.supplierFieldAnyContactText;
                                break;

                            case domain.ServerConditionType.CustomersOnly:
                                ruleText = messages.customerFieldAnyCustomerText;
                                break;

                            case domain.ServerConditionType.SuppliersOnly:
                                ruleText = messages.supplierFieldAnySupplierText;
                                break;

                            default:
                                errorHelpers.throwInvalidOperationError();
                        }

                        break;

                    case domain.ConditionType.ExactValuesCondition:
                    case domain.ConditionType.NegativeExactValuesCondition:
                        ruleText = getConditionText(conditionWithContact);
                        break;

                    default:
                        errorHelpers.throwInvalidOperationError();
                }
            }
        }

        if (showCustomVendorText) {
            const vendorCondition = rule.conditions.find((condition) =>
                [domain.FieldSystemPurpose.QBooksVendor, domain.FieldSystemPurpose.QBooksPayeeVendor].includes(
                    condition.fieldSystemPurpose
                )
            );

            if (!vendorCondition) {
                ruleText = messages.vendorFieldAnyText;
            } else {
                switch (vendorCondition.conditionType) {
                    case null:
                        ruleText = messages.vendorFieldAnyText;
                        break;

                    case domain.ConditionType.ServerCondition:
                        switch (vendorCondition.serverConditionType) {
                            case domain.ServerConditionType.AllContacts:
                                ruleText = vendorCondition.allowCreation
                                    ? messages.vendorFieldAnyOrNewText
                                    : messages.vendorFieldAnyText;
                                break;

                            default:
                                errorHelpers.throwInvalidOperationError();
                        }

                        break;

                    case domain.ConditionType.ExactValuesCondition:
                    case domain.ConditionType.NegativeExactValuesCondition:
                        ruleText = getConditionText(vendorCondition);
                        break;

                    default:
                        errorHelpers.throwInvalidOperationError();
                }
            }
        }

        if (showCustomBeneficiaryText) {
            const beneficiaryCondition = rule.conditions.find(
                (c) => c.fieldSystemPurpose === domain.FieldSystemPurpose.AirwallexBeneficiary
            );

            if (!beneficiaryCondition) {
                ruleText = messages.beneficiaryFieldAnyText;
            } else {
                switch (beneficiaryCondition.conditionType) {
                    case null:
                        ruleText = messages.beneficiaryFieldAnyText;
                        break;

                    case domain.ConditionType.ServerCondition:
                        switch (beneficiaryCondition.serverConditionType) {
                            case domain.ServerConditionType.AllContacts:
                                ruleText = beneficiaryCondition.allowCreation
                                    ? messages.beneficiaryFieldAnyOrNewText
                                    : messages.beneficiaryFieldAnyText;
                                break;

                            default:
                                errorHelpers.throwInvalidOperationError(
                                    'Unsupported server condition type for field with system purpose "Airwallex Beneficiary"'
                                );
                        }

                        break;

                    default:
                        errorHelpers.throwInvalidOperationError(
                            'Unsupported condition type for field with system purpose "Airwallex Beneficiary"'
                        );
                }
            }
        }

        if (showCustomNetSuiteVendorText) {
            const vendorCondition = rule.conditions.find(
                (condition) => condition.fieldSystemPurpose === domain.FieldSystemPurpose.NetSuiteVendor
            );

            if (!vendorCondition) {
                ruleText = messages.vendorFieldAnyText;
            } else {
                switch (vendorCondition.conditionType) {
                    case null:
                        ruleText = messages.vendorFieldAnyText;
                        break;

                    case domain.ConditionType.ServerCondition:
                        switch (vendorCondition.serverConditionType) {
                            case domain.ServerConditionType.AllContacts:
                                ruleText = vendorCondition.allowCreation
                                    ? messages.vendorFieldAnyOrNewText
                                    : messages.vendorFieldAnyText;
                                break;

                            default:
                                errorHelpers.throwInvalidOperationError();
                        }

                        break;

                    case domain.ConditionType.ExactValuesCondition:
                    case domain.ConditionType.NegativeExactValuesCondition:
                        ruleText = getConditionText(vendorCondition);
                        break;

                    default:
                        errorHelpers.throwInvalidOperationError();
                }
            }
        }

        const conditions = sortConditions(rule.conditions, matrixDefinition);

        conditions.forEach((condition, index) => {
            if (showCustomSupplierText && condition.fieldSystemPurpose === domain.FieldSystemPurpose.XeroSupplier) {
                return;
            }

            if (showCustomVendorText && condition.fieldSystemPurpose === domain.FieldSystemPurpose.QBooksVendor) {
                return;
            }

            if (
                showCustomNetSuiteVendorText &&
                condition.fieldSystemPurpose === domain.FieldSystemPurpose.NetSuiteVendor
            ) {
                return;
            }

            let conditionText = getConditionText(condition, index);

            let connectionMessage = messages.submitterRulesPreviewAndText;

            if (integrationCode === domain.IntegrationCode.QBooksExpense) {
                const prevCondition = index > 0 ? conditions[index - 1] : undefined;

                if (
                    prevCondition &&
                    qbooksPayeeFields.includes(condition.fieldSystemPurpose) &&
                    qbooksPayeeFields.includes(prevCondition.fieldSystemPurpose)
                ) {
                    connectionMessage = messages.submitterRulesPreviewOrText;
                }
            }

            if (conditionText) {
                ruleText = ruleText
                    ? connectionMessage({
                          left: ruleText,
                          right: conditionText,
                      })
                    : conditionText;
            }
        });

        if (ruleText) {
            res = res
                ? messages.submitterRulesPreviewOrText({
                      left: res,
                      right: ruleText,
                  })
                : ruleText;
        }
    });

    if (!res && showCustomSupplierText) {
        res = messages.supplierFieldAnyContactText;
    }

    if (!res && showCustomVendorText) {
        res = messages.vendorFieldAnyText;
    }

    if (!res && showCustomNetSuiteVendorText) {
        res = messages.vendorFieldAnyText;
    }

    res = res
        ? messages.submitterRulesPreviewSubmitsWith({
              rules: res,
          })
        : messages.submitterRulesPreviewAlwaysSubmit;

    return res;
};

export const getTemplateSubmitters: (state: State, companyId: string) => ExpandedTemplateUser[] = createSelector(
    (state: State) => getActiveTemplate(state)!.integrationCode,
    (state: State) => getActiveTemplate(state)!.submitterMatrix,
    (state: State, companyId: string) => selectors.company.getCompanyById(state, companyId),
    selectors.user.getUsers,
    (
        integrationCode: domain.IntegrationCode,
        submitterMatrix: domain.Template['submitterMatrix'],
        company,
        users: ExpandedTemplateUser[]
    ) => {
        const matrixDefinition = getMatrixDefinition({
            integrationCode,
            betaFeatures: company.betaFeatures,
            licenseFeatures: company.licenseFeatures,
            matrixType: MatrixType.Requester,
        });

        return arrayHelpers.arraySort(
            submitterMatrix.map((x) => {
                const user = users.find((u) => u.id === x.lineId);

                if (!user) {
                    throw errorHelpers.notFoundError();
                }

                return {
                    ...user,
                    rulesPreviewText: getSubmitterRulesPreviewText(integrationCode, x.rules, matrixDefinition),
                };
            }),
            (u1: ExpandedTemplateUser, u2: ExpandedTemplateUser) =>
                compareHelpers.stringComparator2AscI(u1.displayName, u2.displayName)
        );
    }
);

export const getTemplatePayers: (state: State, companyId: string) => ExpandedTemplateUser[] = createSelector(
    (state: State) => getActiveTemplate(state)!.integrationCode,
    (state: State) => getActiveTemplate(state)!.payerMatrix,
    (state: State, companyId: string) => selectors.company.getCompanyById(state, companyId),
    selectors.user.getUsers,
    (
        integrationCode: domain.IntegrationCode,
        payerMatrix: domain.Template['payerMatrix'],
        company,
        users: ExpandedTemplateUser[]
    ) => {
        if (!payerMatrix) {
            return [];
        }

        const unsortedPayers = payerMatrix.map((x) => {
            const user = users.find((u) => u.id === x.lineId);

            if (!user) {
                throw errorHelpers.notFoundError();
            }

            return user;
        });

        return arrayHelpers.arraySort(unsortedPayers, (u1: ExpandedTemplateUser, u2: ExpandedTemplateUser) =>
            compareHelpers.stringComparator2AscI(u1.displayName, u2.displayName)
        );
    }
);

export const getTemplateExternalSubmitter = (state: State): selectors.types.ExpandedUser | null => {
    const userId = getActiveTemplate(state)!.externalSubmitter;

    if (!userId) {
        return null;
    }

    return selectors.user.getUserById(state, userId);
};

export const getTemplateEmailExternalSubmitter = (state: State): selectors.types.ExpandedUser | null => {
    const userId = getActiveTemplate(state)?.emailExternalSubmitter;

    if (!userId) {
        return null;
    }

    return selectors.user.getUserById(state, userId);
};

export const getTemplateReceiptBankExternalSubmitter = (state: State): selectors.types.ExpandedUser | null => {
    const userId = getActiveTemplate(state)!.receiptBankExternalSubmitter;

    if (!userId) {
        return null;
    }

    return selectors.user.getUserById(state, userId);
};

export const getHasChanges = (state: State): boolean => {
    const template = getActiveTemplate(state);

    if (!template) {
        return false;
    }

    const company = selectors.company.getCompanyById(state, template.companyId);
    const integrationErrorPreventsWork = Boolean(!company.flags.hasActiveIntegration && template.integrationCode);

    if (integrationErrorPreventsWork) {
        // Changes cannot be saved in this case
        return false;
    }

    const activeMatrix = getActiveMatrix(state);
    const activeEditingMatrix = getActiveEditingMatrix(state);

    return (
        getPage(state).activeTemplateModified ||
        Boolean(activeMatrix && activeMatrix.modified) ||
        Boolean(activeEditingMatrix && activeEditingMatrix.modified)
    );
};

export const isMatrixUnavailable = (state: State) => {
    const template = getActiveTemplate(state);

    if (!template) {
        return true;
    }

    const company = selectors.company.getCompanyById(state, template.companyId);
    const integrationErrorPreventsWork = Boolean(!company.flags.hasActiveIntegration && template.integrationCode);

    return integrationErrorPreventsWork || company.isReadonly;
};
