import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { profileApiPaths } from '../paths';
import { SetPassword } from './useSetPassword.types';

export const useSetPassword = (mutationOptions?: RequestMutationOptions<SetPassword>) => {
    return useMutate(profileApiPaths.setPassword, {
        mutationOptions,
        apiVersion: 'v1',
    });
};
