import { messages } from './DiscrepanciesStep.messages';

export const getModifiedPropertyName = (property: string) => {
    switch (property) {
        case 'AccountName':
            return messages.accountName;

        case 'BankCountryCode':
            return messages.bankCountryCode;

        case 'BankName':
            return messages.bankName;

        case 'Iban':
            return messages.iban;

        case 'SwiftCode':
            return messages.swiftCode;

        case 'AccountNumber':
            return messages.accountNumber;

        case 'BankBranch':
            return messages.bankBranch;

        case 'LocalClearingSystem':
            return messages.localClearingSystem;

        case 'AccountRoutingType1':
            return messages.accountingRoutingType1;

        case 'AccountRoutingType2':
            return messages.accountingRoutingType2;

        case 'AccountRoutingValue1':
            return messages.accountingRoutingValue1;

        case 'AccountRoutingValue2':
            return messages.accountingRoutingValue2;

        default:
            return property;
    }
};
