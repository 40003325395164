import { AddonPromotionType } from 'shared/states';

export interface FormValues {
    companyId: string;
}

export enum TryPayOrCapturePopupOrigin {
    ViewForm = 'ViewForm',
    EditForm = 'EditForm',
    CreateNewPopup = 'CreateNewPopup',
    Workflow = 'Workflow',
    SideNavigation = 'SideNavigation',
    ForceOpen = 'ForceOpen',
}

export interface TryPayOrCapturePopupInfoState {
    type: AddonPromotionType;
    origin: TryPayOrCapturePopupOrigin;
}
