import { AmCaptureDisabledImage } from '@approvalmax/ui';
import { Button, Flex, Popup, Text } from '@approvalmax/ui/src/components';
import { memo, useCallback } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { contentOcrNotificationState, ocrNotificationPopupOpenState } from './OcrNotificationPopupContent.states';

export const OcrNotificationPopupContent = memo(() => {
    const setOcrNotificationPopupOpen = useSetRecoilState(ocrNotificationPopupOpenState);
    const [contentOcrNotification, setContentOcrNotification] = useRecoilState(contentOcrNotificationState);

    const onTryAgain = useCallback(() => {
        setOcrNotificationPopupOpen(false);
        setContentOcrNotification({});
    }, [setContentOcrNotification, setOcrNotificationPopupOpen]);

    return (
        <>
            <Popup.Header />

            <Popup.Body>
                <Flex direction='column' alignItems='center' justifyContent='space-between'>
                    <AmCaptureDisabledImage height={200} width={200} />

                    <Flex spacing='24' direction='column' alignItems='center'>
                        <Text textAlign='center' font='headline' fontSize='small' fontWeight='medium'>
                            {contentOcrNotification.description || ''}
                        </Text>

                        <Button onClick={onTryAgain} size='large' color='blue80'>
                            {contentOcrNotification.buttonText || ''}
                        </Button>
                    </Flex>
                </Flex>
            </Popup.Body>
        </>
    );
});

OcrNotificationPopupContent.displayName = 'OcrNotificationPopupContent';
