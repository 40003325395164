import { SupportIcon } from '@approvalmax/ui';
import { Button, Flex, Form, Link, List, Popup, Select, toast } from '@approvalmax/ui/src/components';
import { mathService } from '@approvalmax/utils';
import { constants } from 'modules/common';
import { domain } from 'modules/data';
import { hooks, loadContactSupportPopup } from 'modules/profile';
import { useDispatch } from 'modules/react-redux';
import { useUserProfile } from 'modules/utils';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import YouTubePlayer from 'react-player/youtube';
import { useEvent, useMount } from 'react-use';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { amplitudeService } from 'services/amplitude';
import { routingService } from 'services/routing';
import { activeCompanyIdState, AddonPromotionType } from 'shared/states';
import { getPath, Path } from 'urlBuilder';

import {
    captureItemLabels,
    captureVideoSrc,
    payItemLabels,
    payVideoSrc,
} from './TryPayOrCapturePopupContent.constants';
import { getOriginForAmplitude } from './TryPayOrCapturePopupContent.helpers';
import { useGetCompanies, useGetRedirectTemplate } from './TryPayOrCapturePopupContent.hooks';
import { messages } from './TryPayOrCapturePopupContent.messages';
import {
    tryPayOrCapturePopupCloseCallbackState,
    tryPayOrCapturePopupInfoState,
    tryPayOrCapturePopupOpenState,
} from './TryPayOrCapturePopupContent.states';
import { FormValues } from './TryPayOrCapturePopupContent.types';

const { commonConstants } = constants;

export const TryPayOrCapturePopupContent = memo(() => {
    const dispatch = useDispatch();
    const profile = useUserProfile();
    const activeCompanyId = useRecoilValue(activeCompanyIdState);
    const setTryPayOrCapturePopupOpen = useSetRecoilState(tryPayOrCapturePopupOpenState);
    const playerRef = useRef<YouTubePlayer>(null);
    const [hasVideoStarted, setHasVideoStarted] = useState(false);

    const tryPayOrCapturePopupInfo = useRecoilValue(tryPayOrCapturePopupInfoState);

    const setTryPayOrCapturePopupCloseCallback = useSetRecoilState(tryPayOrCapturePopupCloseCallbackState);

    const labels = tryPayOrCapturePopupInfo.type === AddonPromotionType.Pay ? payItemLabels : captureItemLabels;

    const companies = useGetCompanies();

    const form = useForm<FormValues>({
        defaultValues: {
            companyId: companies.find((company) => company.id === activeCompanyId)?.id ?? companies[0]?.id,
        },
    });

    const selectedCompanyId = form.watch('companyId');

    const selectedCompany = useMemo(
        () => companies.find((company) => company.id === selectedCompanyId),
        [companies, selectedCompanyId]
    );

    const redirectTemplate = useGetRedirectTemplate(selectedCompanyId);

    const isAwaitingActivationAllFeatureTrial =
        selectedCompany?.allFeaturesTrialStatus === domain.AllFeaturesTrialStatus.AwaitingActivation;

    useMount(() => {
        if (!selectedCompany?.isNotManager) {
            amplitudeService.sendData('promo: land on add-on promo paywall', {
                origin: getOriginForAmplitude(tryPayOrCapturePopupInfo.origin),
                addon: tryPayOrCapturePopupInfo.type === AddonPromotionType.Pay ? 'pay' : 'capture',
                cta: selectedCompany?.isNotManager
                    ? 'nudge admin'
                    : isAwaitingActivationAllFeatureTrial
                      ? 'try'
                      : 'contact support',
                org_id: selectedCompanyId,
                current_plan: selectedCompany?.licenseProductPlan,
                gl: selectedCompany?.integrationType,
            });
        }
    });

    const sendVideoViewEvent = useCallback(() => {
        const currentTime = playerRef.current?.getCurrentTime();
        const duration = playerRef.current?.getDuration();

        if (duration && currentTime && duration > 0 && hasVideoStarted) {
            const percentage = mathService.multiply(mathService.divide(currentTime, duration), 100).toFixed(2);

            amplitudeService.sendData('promo: video view on add-on promo paywall', {
                origin: getOriginForAmplitude(tryPayOrCapturePopupInfo.origin),
                addon: tryPayOrCapturePopupInfo.type === AddonPromotionType.Pay ? 'pay' : 'capture',
                org_id: selectedCompanyId,
                view_percentage: percentage,
                current_plan: selectedCompany?.licenseProductPlan,
                gl: selectedCompany?.integrationType,
            });
        }
    }, [
        selectedCompany?.integrationType,
        selectedCompany?.licenseProductPlan,
        selectedCompanyId,
        tryPayOrCapturePopupInfo.origin,
        tryPayOrCapturePopupInfo.type,
        hasVideoStarted,
    ]);

    const handleCloseEvents = useCallback(() => {
        amplitudeService.sendData('promo: close add-on promo paywall', {
            origin: getOriginForAmplitude(tryPayOrCapturePopupInfo.origin),
            addon: tryPayOrCapturePopupInfo.type === AddonPromotionType.Pay ? 'pay' : 'capture',
            org_id: selectedCompanyId,
            current_plan: selectedCompany?.licenseProductPlan,
            gl: selectedCompany?.integrationType,
        });

        sendVideoViewEvent();
    }, [
        selectedCompany?.integrationType,
        selectedCompany?.licenseProductPlan,
        selectedCompanyId,
        sendVideoViewEvent,
        tryPayOrCapturePopupInfo.origin,
        tryPayOrCapturePopupInfo.type,
    ]);

    useEffect(() => {
        setTryPayOrCapturePopupCloseCallback(() => handleCloseEvents);
    }, [handleCloseEvents, setTryPayOrCapturePopupCloseCallback]);

    useEvent('beforeunload', handleCloseEvents);

    const trialActiveCallback = useCallback(() => {
        if (tryPayOrCapturePopupInfo.type === AddonPromotionType.Capture && redirectTemplate) {
            if (
                redirectTemplate.enabled &&
                redirectTemplate.submitterMatrix.some((submitter) => submitter.lineId === profile.email)
            ) {
                routingService.push(getPath(Path.newRequest, redirectTemplate.id, redirectTemplate.companyId));
                toast.info(messages.redirectNewRequestPageMessage);
            } else {
                routingService.push(getPath(Path.workflow, redirectTemplate.companyId, redirectTemplate.id));
                toast.info(messages.redirectWorkflowPageMessage);
            }
        }

        if (tryPayOrCapturePopupInfo.type === AddonPromotionType.Pay) {
            routingService.push(getPath(Path.bankAccounts, selectedCompanyId));
        }

        sendVideoViewEvent();
        setTryPayOrCapturePopupOpen(false);
    }, [
        profile.email,
        redirectTemplate,
        selectedCompanyId,
        sendVideoViewEvent,
        setTryPayOrCapturePopupOpen,
        tryPayOrCapturePopupInfo.type,
    ]);

    const { handleStartTrial, isLoadingActivateAllFeature } = hooks.useTryAllFeatures(trialActiveCallback);
    const { handleNotifyAdmins, notifyAdminTimeoutsByCompanyId, isLoadingCreateTrialRequest } = hooks.useNotifyAdmins();

    let buttonText;

    if (selectedCompany?.isNotManager) {
        buttonText = notifyAdminTimeoutsByCompanyId?.[selectedCompanyId] || messages.notifyAdminsButtonText;
    } else if (isAwaitingActivationAllFeatureTrial) {
        buttonText =
            tryPayOrCapturePopupInfo.type === AddonPromotionType.Pay
                ? messages.tryNowPayButtonText
                : messages.tryNowCaptureButtonText;
    } else {
        buttonText = messages.contactSupportButtonText;
    }

    const buttonProgress = selectedCompany?.isNotManager
        ? isLoadingCreateTrialRequest
        : isAwaitingActivationAllFeatureTrial
          ? isLoadingActivateAllFeature
          : false;
    const buttonDisabled =
        selectedCompany?.isNotManager && Boolean(notifyAdminTimeoutsByCompanyId?.[selectedCompanyId]);
    const buttonStartIcon =
        !selectedCompany?.isNotManager && !isAwaitingActivationAllFeatureTrial ? <SupportIcon /> : undefined;

    const onSubmit = form.handleSubmit((data) => {
        if (selectedCompany?.isNotManager) {
            handleNotifyAdmins(data.companyId);
        } else if (selectedCompany?.allFeaturesTrialStatus === domain.AllFeaturesTrialStatus.AwaitingActivation) {
            amplitudeService.sendData('promo: cta click on add-on promo paywall', {
                origin: getOriginForAmplitude(tryPayOrCapturePopupInfo.origin),
                addon: tryPayOrCapturePopupInfo.type === AddonPromotionType.Pay ? 'pay' : 'capture',
                cta: 'try',
                org_id: selectedCompanyId,
                current_plan: selectedCompany?.licenseProductPlan,
                gl: selectedCompany?.integrationType,
            });
            handleStartTrial(data.companyId);
        } else {
            amplitudeService.sendData('promo: cta click on add-on promo paywall', {
                origin: getOriginForAmplitude(tryPayOrCapturePopupInfo.origin),
                addon: tryPayOrCapturePopupInfo.type === AddonPromotionType.Pay ? 'pay' : 'capture',
                cta: 'contact support',
                org_id: selectedCompanyId,
                current_plan: selectedCompany?.licenseProductPlan,
                gl: selectedCompany?.integrationType,
            });
            dispatch(loadContactSupportPopup());
            sendVideoViewEvent();
            setTryPayOrCapturePopupOpen(false);
        }
    });

    const onLearnMoreClick = useCallback(() => {
        amplitudeService.sendData('promo:　learn more click on add-on promo paywall', {
            origin: getOriginForAmplitude(tryPayOrCapturePopupInfo.origin),
            addon: tryPayOrCapturePopupInfo.type === AddonPromotionType.Pay ? 'pay' : 'capture',
            url:
                tryPayOrCapturePopupInfo.type === AddonPromotionType.Pay
                    ? commonConstants.payLearnMoreLink
                    : commonConstants.captureLearnMoreLink,
            org_id: selectedCompanyId,
            current_plan: selectedCompany?.licenseProductPlan,
            gl: selectedCompany?.integrationType,
        });
    }, [
        selectedCompany?.integrationType,
        selectedCompany?.licenseProductPlan,
        selectedCompanyId,
        tryPayOrCapturePopupInfo.origin,
        tryPayOrCapturePopupInfo.type,
    ]);

    const onPlay = useCallback(() => {
        setHasVideoStarted(true);
    }, []);

    return (
        <Form form={form} onSubmit={onSubmit}>
            <Popup.Header
                title={
                    tryPayOrCapturePopupInfo.type === AddonPromotionType.Pay ? messages.payTitle : messages.captureTitle
                }
            />

            <Popup.Body>
                <Flex direction='column' spacing='32'>
                    <YouTubePlayer
                        ref={playerRef}
                        url={tryPayOrCapturePopupInfo.type === AddonPromotionType.Pay ? payVideoSrc : captureVideoSrc}
                        width='100%'
                        height='410px'
                        onPlay={onPlay}
                        controls
                        title={messages.videoTitle}
                    />

                    <Flex spacing='8' direction='column'>
                        <List listStyle='inside'>
                            {labels.map((label) => (
                                <List.Item key={label}>{label}</List.Item>
                            ))}
                        </List>

                        <Link
                            font='body'
                            external
                            as='a'
                            onClick={onLearnMoreClick}
                            href={
                                tryPayOrCapturePopupInfo.type === AddonPromotionType.Pay
                                    ? commonConstants.payLearnMoreLink
                                    : commonConstants.captureLearnMoreLink
                            }
                        >
                            {tryPayOrCapturePopupInfo.type === AddonPromotionType.Pay
                                ? messages.learnMorePay
                                : messages.learnMoreCapture}
                        </Link>
                    </Flex>

                    <Select
                        name='companyId'
                        required
                        label={messages.chooseOrganisation}
                        items={companies}
                        clearable={false}
                        disabled={isLoadingActivateAllFeature || isLoadingCreateTrialRequest}
                    />

                    <Button
                        color='mint80'
                        block
                        type='submit'
                        disabled={buttonDisabled}
                        progress={buttonProgress}
                        startIcon={buttonStartIcon}
                        size='large'
                    >
                        {buttonText}
                    </Button>
                </Flex>
            </Popup.Body>
        </Form>
    );
});

TryPayOrCapturePopupContent.displayName = 'TryPayOrCapturePopupContent';
