import { useTheme } from '@approvalmax/theme';
import { Box, Flex, Menu, Switch, Text } from '@approvalmax/ui/src/components';
import { constants } from '@approvalmax/utils';
import { FC, memo } from 'react';

import { useMenuItems } from './UserSettingsMenu.hooks';
import { messages } from './UserSettingsMenu.messages';
import { UserSettingsMenuProps } from './UserSettingsMenu.types';

const UserSettingsMenu: FC<UserSettingsMenuProps> = memo((props) => {
    const { onClickItem } = props;

    const items = useMenuItems();

    const { toggleTheme, isLightTheme } = useTheme();

    if (!items.length) return null;

    return (
        <Box spacing='16 8 8' color='silver80' radius='xsmall'>
            {constants.isDev && (
                <Box spacing='0 8 8'>
                    <Flex justifyContent='space-between' alignItems='center' as='label'>
                        <Text font='label' fontSize='small'>
                            {messages.darkTheme}
                        </Text>

                        <Switch size='small' onChange={toggleTheme} checked={!isLightTheme} />
                    </Flex>
                </Box>
            )}

            <Menu size='small'>
                {items.map(({ id, name, title, onClick, dataQa }) => (
                    <Menu.Item
                        key={id}
                        name={name}
                        title={title}
                        onClick={onClickItem(onClick)}
                        data-qa={dataQa}
                        data-qa-id={id}
                    />
                ))}
            </Menu>
        </Box>
    );
});

export default UserSettingsMenu;
