import { RequestMutationOptions } from '@approvalmax/data';
import { useQueryClient } from '@tanstack/react-query';
import { useMutate } from 'shared/data';

import { companiesApiPaths } from '../paths';
import { UpdateNetSuiteFields } from './useUpdateNetSuiteFields.types';

export const useUpdateNetSuiteFields = (mutationOptions?: RequestMutationOptions<UpdateNetSuiteFields>) => {
    const queryClient = useQueryClient();

    return useMutate(companiesApiPaths.updateNetSuiteFields, {
        mutationOptions: {
            ...mutationOptions,
            onSuccess: (response, variables, context) => {
                void queryClient.invalidateQueries([
                    companiesApiPaths.getNetSuiteCustomCompanyFields,
                    { companyId: variables.body?.companyId },
                ]);
                mutationOptions?.onSuccess?.(response, variables, context);
            },
        },
    });
};
