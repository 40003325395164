import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { authApiPaths } from '../paths';
import { ResetPassword } from './useResetPassword.types';

export const useResetPassword = (mutationOptions?: RequestMutationOptions<ResetPassword>) => {
    return useMutate(authApiPaths.resetPassword, {
        mutationOptions,
        apiVersion: 'v1',
    });
};
