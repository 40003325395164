import { constants } from 'modules/common';
import { DoneIcon } from 'modules/sprites';
import FieldsSection from 'pages/requestList/components/FieldsSection';
import { FC, memo, useMemo } from 'react';

import { StyledField } from '../../NetSuiteRequestCard.styles';
import { getCustomFieldValue, sortCustomFields } from '../../NetSuiteRequestCard.utils';
import { CheckboxValueWrapper } from './NetSuiteCustomFields.styles';
import { NetSuiteCustomFieldsProps } from './NetSuiteCustomFields.types';

const { netSuiteConstants } = constants;

const NetSuiteCustomFields: FC<NetSuiteCustomFieldsProps> = memo((props) => {
    const { customFields = [], companyCustomFields } = props;

    const preparedCustomFields = useMemo(() => {
        return sortCustomFields(
            customFields.filter((field) =>
                companyCustomFields.find((companyField) => companyField.NetSuiteField.Field.ScriptId === field.scriptId)
            ),
            companyCustomFields
        );
    }, [customFields, companyCustomFields]);

    if (preparedCustomFields.length === 0) {
        return null;
    }

    return (
        <>
            {preparedCustomFields.map((field) => {
                if (field.fieldType && netSuiteConstants.ignoredCustomFields.includes(field.fieldType)) {
                    return null;
                }

                const fieldReference = companyCustomFields.find(
                    (companyField) => companyField.NetSuiteField.Field.ScriptId === field.scriptId
                );

                if (!fieldReference) {
                    return null;
                }

                const value = getCustomFieldValue(field);

                return (
                    <StyledField
                        key={`headerCustomField-${field.internalId}`}
                        title={fieldReference.NetSuiteField.Field.Name}
                        valueType={FieldsSection.Field.ValueType.String}
                        value={field.fieldType !== 'CheckBox' ? String(value) : undefined}
                        isHtml={field.fieldType === 'RichText' || field.fieldType === 'Hyperlink'}
                        forceDisplayValue={
                            field.fieldType === 'CheckBox' && value ? (
                                <CheckboxValueWrapper>
                                    <DoneIcon width={13} height={10} />
                                </CheckboxValueWrapper>
                            ) : undefined
                        }
                        hideIfEmpty
                    />
                );
            })}
        </>
    );
});

export default NetSuiteCustomFields;
