import { errorHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { getRequest } from 'pages/requestForm/selectors/pageSelectors';
import { FC, memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ContentSplitViewMode, contentSplitViewState, netSuiteBillPaymentFieldValuesState } from 'shared/states';

import RequestCardSkeleton from '../../../components/RequestCard/RequestCard.skeleton';
import { getActiveRequestSafe } from '../../../selectors/pageSelectors';
import { NetSuiteRequestCard } from '../NetSuiteRequestCard/NetSuiteRequestCard';
import { useBillPaymentBill } from './NetSuiteRequestCardWrapper.hooks';
import { NetSuiteRequestCardWrapperProps } from './NetSuiteRequestCardWrapper.types';

export const NetSuiteRequestCardWrapper: FC<NetSuiteRequestCardWrapperProps> = memo((props) => {
    const { setContainerTitle } = props;

    const { companyId } = useParams<{ companyId: string }>();
    const contentSplitView = useRecoilValue(contentSplitViewState);
    const fieldValues = useRecoilValue(netSuiteBillPaymentFieldValuesState);
    const activePageId = useSelector(selectors.navigation.getActivePageId);

    const getActiveRequest = activePageId === 'requestForm' ? getRequest : getActiveRequestSafe;

    const billPaymentRequest = useSelector(getActiveRequest);
    const billPaymentRequestId = billPaymentRequest?.id;

    const billLinkedToBillPaymentId =
        (contentSplitView.mode === ContentSplitViewMode.NetSuiteBillLinkedToBillPayment && contentSplitView.billId) ||
        null;

    if (billPaymentRequest?.integrationCode !== domain.IntegrationCode.NetSuiteBillPayment || !billPaymentRequestId) {
        throw errorHelpers.createError('Bill Payment Request is missing in state');
    }

    const payeeId = billPaymentRequest.details.payee?.id ?? fieldValues.payee?.id;
    const accountId = billPaymentRequest.details.payablesAccount?.id ?? fieldValues.payablesAccount?.id;
    const currencyId = billPaymentRequest.details.currency?.id ?? fieldValues.currency?.id;

    const { request } = useBillPaymentBill({
        companyId,
        currencyId,
        billPaymentRequestId,
        accountId,
        payeeId,
        lineRequestId: billLinkedToBillPaymentId || '',
    });

    useEffect(() => {
        if (request && request.displayName) {
            setContainerTitle(request.displayName);
        }
    }, [request, setContainerTitle]);

    if (!request) return <RequestCardSkeleton />;

    if (request.integrationType !== domain.IntegrationType.NetSuite) {
        return null;
    }

    return <NetSuiteRequestCard request={request} readonly />;
});

NetSuiteRequestCardWrapper.displayName = 'NetSuiteRequestCardWrapper';
