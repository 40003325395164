import FieldsSection from 'pages/requestList/components/FieldsSection';
import styled from 'styled-components';

export const Root = styled.div`
    display: flex;
    flex-flow: row wrap;
    padding: 0px 50px 0 60px;
    border-bottom: 1px solid #e4e4e4;
`;

export const StyledField = styled(FieldsSection.Field)`
    min-width: 144px;
    max-width: 144px;
    margin-right: 10px;
    margin-bottom: 34px;
`;

export const NarrationField = styled(StyledField)`
    max-width: 50%;
`;

export const ShowOnCashBasisReportsField = styled(FieldsSection.Field)`
    width: 100%;
    margin-bottom: 34px;
`;

export const BooleanValueWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 18px;
`;
