import { domain } from 'modules/data';

export const beneficiaryDetailsPropNames: (keyof domain.AirwallexBeneficiaryBankDetails)[] = [
    'accountName',
    'bankCountryCode',
    'bankName',
    'iban',
    'swiftCode',
    'accountNumber',
    'bankBranch',
    'localClearingSystem',
    'accountingRoutingType1',
    'accountingRoutingType2',
    'accountingRoutingValue1',
    'accountingRoutingValue2',
];
