import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { companiesApiPaths } from '../paths';
import { RespondToInvitation } from './useRespondToInvitation.types';

export const useRespondToInvitation = (mutationOptions?: RequestMutationOptions<RespondToInvitation>) => {
    return useMutate(companiesApiPaths.respondToInvitation, {
        mutationOptions,
        apiVersion: 'v1',
    });
};
