import { Box, Checkbox, DatePicker, Radio, Spacing, Text } from '@approvalmax/ui/src/components';
import { dateTimeHelpers } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { FC, memo, useCallback } from 'react';

import { SettingsSection } from '../SettingsSection/SettingsSection';
import SettingsSubsection from '../SettingsSubsection/SettingsSubsection';
import { messages } from './SectionNetSuiteFraudBypassing.messages';
import { FraudBypassingOption, SectionNetSuiteFraudBypassingProps } from './SectionNetSuiteFraudBypassing.types';

const SectionNetSuiteFraudBypassing: FC<SectionNetSuiteFraudBypassingProps> = memo((props) => {
    const {
        template,
        templateSettings,
        savedTemplateSettings,
        effectiveDateLimits,
        checkValidFraudBypassing,
        onChange,
        readonly,
        qa,
    } = props;

    const settings = templateSettings.netSuiteApprovalDisregardDetectionSettings;

    const handleEnabledChange = useCallback(
        (selectedValue: FraudBypassingOption) => {
            onChange((prevTemplateSettings) => ({
                ...prevTemplateSettings,
                netSuiteApprovalDisregardDetectionSettings:
                    prevTemplateSettings.netSuiteApprovalDisregardDetectionSettings
                        ? {
                              ...prevTemplateSettings.netSuiteApprovalDisregardDetectionSettings,
                              enabled: selectedValue === FraudBypassingOption.Pull,
                          }
                        : undefined,
            }));
        },
        [onChange]
    );

    const handleEffectiveDateChange = useCallback(
        (newValue: Date | undefined) => {
            onChange((prevTemplateSettings) => ({
                ...prevTemplateSettings,
                netSuiteApprovalDisregardDetectionSettings:
                    prevTemplateSettings.netSuiteApprovalDisregardDetectionSettings
                        ? {
                              ...prevTemplateSettings.netSuiteApprovalDisregardDetectionSettings,
                              effectiveDate:
                                  dateTimeHelpers
                                      .getMaxDate(
                                          dateTimeHelpers.getMinDate(newValue, effectiveDateLimits?.minDate),
                                          effectiveDateLimits?.maxDate
                                      )
                                      ?.toISOString() ?? null,
                          }
                        : undefined,
            }));
        },
        [effectiveDateLimits?.maxDate, effectiveDateLimits?.minDate, onChange]
    );

    const handleNotifyAdminsChange = useCallback(
        (notifyAdmins: boolean) => {
            onChange((prevTemplateSettings) => ({
                ...prevTemplateSettings,
                netSuiteApprovalDisregardDetectionSettings:
                    prevTemplateSettings.netSuiteApprovalDisregardDetectionSettings
                        ? {
                              ...prevTemplateSettings.netSuiteApprovalDisregardDetectionSettings,
                              notifyAdmins,
                          }
                        : undefined,
            }));
        },
        [onChange]
    );

    if (!settings) {
        return null;
    }

    const disabled = !template.externalSubmitter;
    const dateTimeDisabled =
        disabled ||
        !settings.enabled ||
        Boolean(savedTemplateSettings.netSuiteApprovalDisregardDetectionSettings?.effectiveDate);

    return (
        <SettingsSection
            title={messages.title}
            learnMoreLink='https://support.approvalmax.com/portal/en/kb/articles/what-is-the-fraud-detection-bypassing-the-approval-workflow-feature-netsuite'
        >
            <SettingsSubsection subtitle={messages.subtitle} warning={disabled ? messages.warning : undefined}>
                <Radio.Group
                    disabled={disabled || readonly}
                    value={settings.enabled ? FraudBypassingOption.Pull : FraudBypassingOption.DoNotPull}
                    onChange={handleEnabledChange}
                    name='approvalDisregard'
                    block
                    data-qa={qa('NetSuiteFraudDetectionBypassing-enabled')}
                >
                    <Radio value={FraudBypassingOption.DoNotPull}>{messages.doNotPullDocumentLabel}</Radio>

                    <Radio value={FraudBypassingOption.Pull}>{messages.pullDocumentLabel}</Radio>
                </Radio.Group>

                <Box spacing='0 0 0 28'>
                    <Text font='body' color='midnight70' fontSize='medium'>
                        {messages.pullDocumentDescription({
                            templateName: template.displayName,
                        })}
                    </Text>

                    <Spacing height={12} />

                    <Box width={150}>
                        <DatePicker
                            value={settings.effectiveDate ? new Date(settings.effectiveDate) : undefined}
                            invalid={!checkValidFraudBypassing(domain.IntegrationType.NetSuite)}
                            minDate={effectiveDateLimits?.minDate ? new Date(effectiveDateLimits.minDate) : undefined}
                            maxDate={effectiveDateLimits?.maxDate ? new Date(effectiveDateLimits.maxDate) : undefined}
                            onChange={handleEffectiveDateChange}
                            disabled={dateTimeDisabled || readonly}
                            placeholder={messages.datePlaceholder}
                            data-qa={qa('NetSuiteFraudDetectionBypassing-effective-date')}
                        />
                    </Box>
                </Box>

                <Checkbox
                    disabled={!settings.enabled || disabled || readonly}
                    checked={Boolean(settings.notifyAdmins)}
                    onChange={handleNotifyAdminsChange}
                    data-qa={qa('FraudDetectionBypassing-notify-admins')}
                >
                    {messages.notifyCheckboxText}
                </Checkbox>
            </SettingsSubsection>
        </SettingsSection>
    );
});

export default SectionNetSuiteFraudBypassing;
