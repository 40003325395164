import { SortableContainer } from '@approvalmax/ui';
import { Flex } from '@approvalmax/ui/src/components';
import { FC, PropsWithChildren, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { reorderSteps } from '../../actions';
import { draggbleActiveStepClassName } from '../../constants';

const DraggableStepList: FC<PropsWithChildren> = (props) => {
    const { children } = props;

    const dispatch = useDispatch();

    const onSortEnd = useCallback(
        ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
            if (oldIndex !== newIndex) {
                dispatch(reorderSteps(oldIndex, newIndex));
            }
        },
        [dispatch]
    );

    return (
        <SortableContainer
            onSortEnd={onSortEnd}
            useDragHandle
            lockToContainerEdges
            helperClass={draggbleActiveStepClassName}
            axis='x'
            lockAxis='x'
        >
            <Flex wrap={false} spacing='0'>
                {children}
            </Flex>
        </SortableContainer>
    );
};

export default DraggableStepList;
