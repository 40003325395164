import { mods, theme } from '@approvalmax/theme';
import { Link } from '@approvalmax/ui';
import { StyledMods } from '@styled-kit/mods';
import { font } from 'modules/styles';
import styled from 'styled-components';

import backgroundImg from './resources/am-bg-old.png';

export const PageLayout = styled.div<StyledMods<{ enableMobileAdaptive?: boolean; oldDesign?: boolean }>>`
    display: flex;
    flex-flow: column;
    min-height: 100%;

    background-color: ${theme.color.midnight20};

    ${mods('oldDesign', true)`
        background: url(${backgroundImg});
        background-size: cover;
    `};

    ${mods('enableMobileAdaptive', true)`
        @media (max-width: ${theme.container.breakpoint.xsmall}) {
            background-image: initial;
            overflow: auto;
        }
    `};
`;

export const ScrollWrapper = styled.div<StyledMods<{ enableMobileAdaptive?: boolean }>>`
    flex: 1;
    overflow: auto;

    ${mods('enableMobileAdaptive', true)`
        @media (max-width: ${theme.container.breakpoint.xsmall}) {
            overflow: initial;
        }
    `};
`;

export const Content = styled.div`
    min-height: 100%;
    min-width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
`;

export const Logo = styled.a`
    margin-top: 10px;
    margin-bottom: 10px;
    flex-shrink: 1;
`;

export const CardWrapper = styled.div`
    padding: 10px;
    max-width: 100%;
`;

export const Card = styled.div<{ fadeOut: boolean }>`
    max-width: 100%;
    display: flex;
    flex-flow: column;
    transition: opacity 200ms;

    ${(props) => (props.fadeOut ? 'opacity: 0;' : '')}
`;

export const MobileApps = styled.div<StyledMods<{ enableMobileAdaptive?: boolean }>>`
    flex: none;
    ${font(14, '#fff')}
    background: rgba(0, 0, 0, 0.3);
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    ${mods('enableMobileAdaptive', true)`
        @media (max-width: ${theme.container.breakpoint.xsmall}) {
            background: initial;
        }
    `};
`;

export const MobileAppText = styled.div`
    margin-right: 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const MobileAppLinks = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledLink = styled(Link)`
    margin-right: 10px;
`;
