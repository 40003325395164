import { MutateDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { twoFaApiPaths } from '../paths';
import {
    VerifyTFAWithOneTimePasswordRequest,
    VerifyTFAWithOneTimePasswordResponse,
} from './useVerifyTFAWithOneTimePassword.types';

export const useVerifyTFAWithOneTimePassword = () => {
    return useMutateLegacy<MutateDataParams<VerifyTFAWithOneTimePasswordRequest>, VerifyTFAWithOneTimePasswordResponse>(
        twoFaApiPaths.verifyTFAWithOneTimePassword
    );
};
