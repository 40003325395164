import { ErrorCode } from '@approvalmax/types';
import { toast } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { useCallback } from 'react';
import { useResendQuoteForCustomerDecision } from 'shared/data';

import { messages } from './ResendQuote.messages';

export const useResendQuote = (request: selectors.types.XeroQuoteExpandedRequest) => {
    const { mutate: resendQuote, isLoading } = useResendQuoteForCustomerDecision();

    const onResendQuote = useCallback(() => {
        resendQuote(
            {
                body: {
                    companyId: request.companyId,
                    requestId: request.id,
                },
            },
            {
                onSuccess: () => {
                    toast.success(messages.quoteWasSuccessfullyResent);
                },
                onError: (error) => {
                    if (error?.code === ErrorCode.E5043_XERO_QUOTE_UNAVAILABLE_FOR_CUSTOMER_DECISION) {
                        toast.error(error.message);
                    }
                },
            }
        );
    }, [request.companyId, request.id, resendQuote]);

    return {
        onResendQuote,
        isLoading,
    };
};
