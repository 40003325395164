import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { receiptBankApiPaths } from '../paths';
import { IntegrateReceiptBankCompany } from './useIntegrateReceiptBankCompany.types';

export const useIntegrateReceiptBankCompany = (
    mutationOptions?: RequestMutationOptions<IntegrateReceiptBankCompany>
) => {
    return useMutate(receiptBankApiPaths.integrateCompany, {
        mutationOptions,
        apiVersion: 'v1',
        method: 'post',
        mapToCamelCase: true,
    });
};
