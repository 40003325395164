import { memo } from 'react';
import { usePayLinkItems } from 'shared/components/Drawer/components/PayMenu/PayMenu.hooks';

import { Navigation } from '../Navigation/Navigation';
import { PayMenuProps } from './PayMenu.types';

export const PayMenu = memo<PayMenuProps>((props) => {
    const { company, onClick } = props;

    const settingsLinkItems = usePayLinkItems(company);

    return <Navigation items={settingsLinkItems} onClick={onClick} />;
});

PayMenu.displayName = 'PayMenu';
