import '../workflowSection.scss';

import { Tooltip } from '@approvalmax/ui';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { BubbleSeparatorIcon, CheckIcon } from 'modules/sprites';
import { FC, memo, useMemo } from 'react';
import bemFactory from 'react-bem-factory';

import { ReviewStepDetails } from '../ReviewStepDetails/ReviewStepDetails';
import { messages } from './ReviewStep.messages';
import { ReviewStepProps } from './ReviewStep.types';

const bem = bemFactory.block('reql-workflow-section');
const qa = bemFactory.qa('reql-workflow-section');

export const ReviewStep: FC<ReviewStepProps> = memo((props) => {
    const { request, readonly, selectedStepId, setSelectedStepId } = props;

    const hasWorkflowAccessToReviewStep = selectors.template.getHasAccessToReviewStepByIntegrationCode(
        request.integrationCode
    );
    const reviewStep = request.reviewStep;
    const skipped = reviewStep?.isCompleted && !reviewStep.completedByUser;

    const isCompleted = reviewStep?.isCompleted;
    const reviewStepId = reviewStep?.reviewStepId;
    const selected = reviewStepId === selectedStepId;
    const isForceReviewedByAdmin =
        reviewStep?.isCompleted &&
        reviewStep.completedByUser &&
        reviewStep.reviewers.every((reviewer) => reviewer.id !== reviewStep.completedByUser?.id);

    const approverDisplayName = reviewStep?.completedByUser
        ? selectors.user.getDisplayName(
              reviewStep.completedByUser.firstName,
              reviewStep.completedByUser.lastName,
              reviewStep.completedByUser.userEmail
          )
        : '';

    const onSelectStep = () => {
        if (reviewStepId && selectedStepId !== reviewStepId) {
            setSelectedStepId(reviewStepId);
        }
    };

    const stepStatus = useMemo(() => {
        switch (true) {
            case !isCompleted:
                return messages.active;

            case isForceReviewedByAdmin:
                return messages.forceReviewedBy({ name: approverDisplayName });

            case skipped:
                return messages.skipped;

            default:
                return messages.reviewed;
        }
    }, [approverDisplayName, isCompleted, isForceReviewedByAdmin, skipped]);

    if (!reviewStep || !reviewStepId || !hasWorkflowAccessToReviewStep) return null;

    return (
        <div
            key={reviewStepId}
            className={bem('step-container', { selected })}
            data-qa={qa('step')}
            data-qa-id={reviewStepId}
            data-qa-name='review_step'
        >
            <div className={bem('step-bubble-row')}>
                <div className={bem('step-bubble-wrp')} onClick={onSelectStep}>
                    <Tooltip
                        tooltip={
                            <div className={bem('step-tooltip')}>
                                <div className={bem('step-tooltip-name')}>{messages.reviewStep}</div>

                                <div className={bem('step-tooltip-status')} data-qa={qa('step-tooltip-status')}>
                                    {stepStatus}
                                </div>
                            </div>
                        }
                        className={bem('step-bubble', {
                            selected,
                            active: !isCompleted || isForceReviewedByAdmin,
                            approved: isCompleted && !isForceReviewedByAdmin,
                            locked: isForceReviewedByAdmin,
                        })}
                        qa={qa('step-bubble')}
                    >
                        {isCompleted && !isForceReviewedByAdmin && (
                            <CheckIcon className={bem('step-approved-icon')} width={10} height={7} />
                        )}

                        <div className={bem('step-name')}>{messages.reviewStep}</div>

                        {selected && !isCompleted && request.statusV2 === domain.RequestStatusV2.OnReview && (
                            <div className={bem('step-active-text')} data-qa={qa('step-active-text')}>
                                {messages.active}
                            </div>
                        )}

                        {!selected && !isCompleted && <div className={bem('step-active-icon')} />}
                    </Tooltip>
                </div>

                {request.steps.length > 0 && (
                    <BubbleSeparatorIcon className={bem('step-separator-icon')} width={6} height={12} />
                )}
            </div>

            {selected && <ReviewStepDetails request={request} readonly={readonly} reviewStep={reviewStep} />}
        </div>
    );
});

ReviewStep.displayName = 'ReviewStep';
