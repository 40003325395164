import { Box, Button, Flex, Form, Label, Select, Text } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { getCreatableTemplatesByCompanyId } from 'modules/common/selectors/metaSelectors';
import { getTemplatesByCompanyId } from 'modules/common/selectors/templateSelectors';
import { useSelector } from 'modules/react-redux';
import { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import { isOcrEnabledForTemplate } from 'shared/helpers';

import { bulkCreationPopupContentState } from '../BulkCreationPopupContent.states';
import { messages } from './AdditionalOptions.messages';
import { AdditionalOptionsProps, FormValues } from './AdditionalOptions.types';

export const AdditionalOptions: FC<AdditionalOptionsProps> = memo((props) => {
    const { editMode, setEditMode, disabled } = props;

    const [bulkCreationPopupContent, setBulkCreationPopupContent] = useRecoilState(bulkCreationPopupContentState);

    const form = useForm<FormValues>({
        defaultValues: {
            selectedCompanyId: bulkCreationPopupContent.companyId,
            selectedTemplateIntegrationCode: bulkCreationPopupContent.templateIntegrationCode,
        },
    });
    const selectedCompanyId = form.watch('selectedCompanyId');
    const activeCompany = useSelector((state) =>
        selectors.company.getCompanyById(state, bulkCreationPopupContent.companyId)
    );
    const selectedCompany = useSelector((state) => selectors.company.getCompanyById(state, selectedCompanyId));
    const companies = useSelector(selectors.company.getOcrAvailableCompanies);
    const selectedCompanyTemplates = useSelector((state) => getCreatableTemplatesByCompanyId(state, selectedCompanyId));
    const activeCompanyTemplates = useSelector((state) =>
        getTemplatesByCompanyId(state, bulkCreationPopupContent.companyId)
    );
    const filteredCompanyTemplates = useMemo(
        () =>
            selectedCompanyTemplates.filter(
                (companyTemplate) =>
                    companyTemplate.integrationCode &&
                    isOcrEnabledForTemplate(selectedCompany, companyTemplate.integrationCode)
            ),
        [selectedCompany, selectedCompanyTemplates]
    );
    const integrationTitle = activeCompanyTemplates.find(
        (template) => template.integrationCode === bulkCreationPopupContent.templateIntegrationCode
    )?.displayName;

    useEffect(() => {
        const selectedTemplateIntegrationCode = form.getValues('selectedTemplateIntegrationCode');

        if (
            filteredCompanyTemplates.length > 0 &&
            filteredCompanyTemplates.every((template) => template.integrationCode !== selectedTemplateIntegrationCode)
        ) {
            form.setValue('selectedTemplateIntegrationCode', filteredCompanyTemplates[0].integrationCode);
        }
    }, [filteredCompanyTemplates, form]);

    const onEdit = useCallback(() => {
        setEditMode(true);
    }, [setEditMode]);

    const onCancel = useCallback(() => {
        setEditMode(false);
        form.reset();
    }, [form, setEditMode]);

    const handleSubmit = form.handleSubmit((values) => {
        setBulkCreationPopupContent(() => ({
            companyId: values.selectedCompanyId,
            templateIntegrationCode: values.selectedTemplateIntegrationCode,
        }));
        setEditMode(false);
    });

    return (
        <Flex spacing='16' size={10} height='fit-content'>
            <Text font='headline' fontSize='xsmall' fontWeight='medium'>
                {messages.additionalOptionsTitle}
            </Text>

            <Box color='midnight20' spacing='24' radius='xsmall' width='100%'>
                {editMode ? (
                    <Form form={form} onSubmit={handleSubmit}>
                        <Box color='white100' width='100%' spacing='12'>
                            <Flex direction='column'>
                                <div>
                                    <Label required>{messages.companySelectTitle}</Label>

                                    <Select
                                        name='selectedCompanyId'
                                        size='small'
                                        clearable={false}
                                        itemNameKey='displayName'
                                        items={companies}
                                    />
                                </div>

                                <Flex spacing='8' wrap={false}>
                                    <Flex block>
                                        <Label required>{messages.templateSelectTitle}</Label>

                                        <Select
                                            name='selectedTemplateIntegrationCode'
                                            size='small'
                                            clearable={false}
                                            itemNameKey='displayName'
                                            itemIdKey='integrationCode'
                                            items={filteredCompanyTemplates}
                                        />
                                    </Flex>

                                    <Flex justifyContent='end' spacing='8' size={12} alignItems='end'>
                                        <Button size='small' color='midnight40' onClick={onCancel}>
                                            {messages.cancelButtonTitle}
                                        </Button>

                                        <Button size='small' color='blue80' type='submit'>
                                            {messages.saveButtonTitle}
                                        </Button>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Box>
                    </Form>
                ) : (
                    <Flex spacing='8' width='100%' justifyContent='space-between'>
                        <Flex direction='column' spacing='8'>
                            <Text font='body' fontSize='medium' fontWeight='medium'>
                                {integrationTitle}
                            </Text>

                            <Text font='body'>{`"${activeCompany?.displayName}"`}</Text>
                        </Flex>

                        <Button disabled={disabled} onClick={onEdit} color='blue80' outline>
                            {messages.editButtonTitle}
                        </Button>
                    </Flex>
                )}
            </Box>
        </Flex>
    );
});

AdditionalOptions.displayName = 'AdditionalOptions';
