import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.NetSuiteRequestCard.NetSuiteVendorFooterFields', {
    contactInformationSection: 'Contact Information',
    emailFieldText: 'Email',
    phoneFieldText: 'Phone',
    altPhoneFieldText: 'Alt phone',
    faxFieldText: 'Fax',
    financialInformationSection: 'Financial Information',
    legalNameFieldText: 'Legal name',
    accountNumberFieldText: 'Account number',
    defaultExpenseAccountFieldText: 'Default expense account',
    defaultPayablesAccountFieldText: 'Default payables account',
    termsFieldText: 'Terms',
    creditLimitFieldText: 'Credit limit',
    incotermFieldText: 'Incoterm',
    taxNumberFieldText: 'Tax id',
    is1099EligibleFieldText: 'Is 1099 eligible',
    isJobResourceFieldText: 'Is job resource',
    positiveValue: 'Yes',
    subsidiariesSection: 'Subsidiaries',
    primarySubsidiaryFieldText: 'Primary subsidiary',
    currenciesSection: 'Currencies',
    primaryCurrencyFieldText: 'Primary currency',
    currenciesListFieldText: 'Currencies list',
    addressSection: 'Address Section',
});
