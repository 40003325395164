import { errorHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { createSelector } from 'reselect';

import { GetMatrixDefinitionOptions } from '../types/matrix';
import { getDearMatrixDefinitions } from './matrixDefinitions.dear';
import { getNetSuiteMatrixDefinitions } from './matrixDefinitions.netsuite';
import { getQBooksMatrixDefinitions } from './matrixDefinitions.qbooks';
import { MatrixDefinition } from './matrixDefinitions.shared';
import { getStandaloneMatrixDefinitions } from './matrixDefinitions.standalone';
import { getXeroMatrixDefinitions } from './matrixDefinitions.xero';

export const getMatrixDefinition: (options: GetMatrixDefinitionOptions) => MatrixDefinition = createSelector(
    (options: GetMatrixDefinitionOptions) => options,
    (options) => {
        const integrationType = selectors.integration.getIntegrationType(options.integrationCode);

        switch (integrationType) {
            case domain.IntegrationType.Xero:
                return getXeroMatrixDefinitions(options);

            case domain.IntegrationType.QBooks:
                return getQBooksMatrixDefinitions(options);

            case domain.IntegrationType.NetSuite:
                return getNetSuiteMatrixDefinitions(options);

            case domain.IntegrationType.Dear:
                return getDearMatrixDefinitions(options);

            case domain.IntegrationType.None:
                return getStandaloneMatrixDefinitions(options);

            default:
                throw errorHelpers.assertNever(integrationType);
        }
    }
);
