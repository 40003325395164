import styled from 'styled-components';

export const DetailsSection = styled.div`
    display: flex;
    margin-bottom: 30px;
`;

export const Column1 = styled.div`
    flex: 1;
`;

export const Column2 = styled.div`
    flex: 1;
    display: flex;
    flex-flow: column;
`;

export const Column3 = styled.div`
    flex: 2;
`;
