import { Button, Flex, Link, Spacing, Text } from '@approvalmax/ui/src/components';
import { memo } from 'react';
import { useGetTFAEnforcementInformation } from 'shared/data';

import { messages } from './SoftEnforcementStart.messages';
import { SoftEnforcementStartSkeleton } from './SoftEnforcementStart.skeleton';
import { SoftEnforcementStartProps } from './SoftEnforcementStart.types';

export const SoftEnforcementStart = memo<SoftEnforcementStartProps>((props) => {
    const { onStart } = props;

    const { data, isLoading } = useGetTFAEnforcementInformation();
    const isDefaultEnforcement = Boolean(data?.tfaEnforcementIntegrationTypes.length);

    if (isLoading) {
        return <SoftEnforcementStartSkeleton />;
    }

    return (
        <Flex direction='column'>
            <Text font='body' fontSize='large' fontWeight='regular'>
                {isDefaultEnforcement
                    ? messages.descriptionAdministratorEnforcement
                    : messages.descriptionCompanyEnforcement}
            </Text>

            <Text font='body' fontSize='large' fontWeight='regular'>
                {messages.moreInformation({
                    supportlink: (chunks) => (
                        <Link
                            href='https://support.approvalmax.com/portal/en/newticket'
                            font='body'
                            fontSize='large'
                            fontWeight='regular'
                        >
                            {chunks}
                        </Link>
                    ),
                })}
            </Text>

            <Spacing height={8} />

            <Button onClick={onStart} color='blue80' size='large'>
                {messages.button}
            </Button>
        </Flex>
    );
});

SoftEnforcementStart.displayName = 'SoftEnforcementStart';
