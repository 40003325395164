import moment from 'moment';
import { z } from 'zod';

import { messages } from './AddBillInvoicesPopup.messages';

export const schema = z
    .object({
        dateFrom: z.nullable(z.date()),
        dateTo: z.nullable(z.date()),
        dueDateFrom: z.nullable(z.date()),
        dueDateTo: z.nullable(z.date()),
        plannedDateFrom: z.nullable(z.date()),
        plannedDateTo: z.nullable(z.date()),
    })
    .passthrough()
    .refine(
        (value) =>
            !(
                value.dateFrom &&
                value.dateTo &&
                (moment(value.dateFrom).isAfter(value.dateTo) || moment(value.dateFrom).isSame(value.dateTo))
            ),
        { message: messages.dateFromCantBeAfterDateTo, path: ['dateFrom'] }
    )
    .refine(
        (value) =>
            !(
                value.dueDateFrom &&
                value.dueDateTo &&
                (moment(value.dueDateFrom).isAfter(value.dueDateTo) ||
                    moment(value.dueDateFrom).isSame(value.dueDateTo))
            ),
        { message: messages.dateFromCantBeAfterDateTo, path: ['dueDateFrom'] }
    )
    .refine(
        (value) =>
            !(
                value.plannedDateFrom &&
                value.plannedDateTo &&
                (moment(value.plannedDateFrom).isAfter(value.plannedDateTo) ||
                    moment(value.plannedDateFrom).isSame(value.plannedDateTo))
            ),
        { message: messages.dateFromCantBeAfterDateTo, path: ['plannedDateFrom'] }
    );
