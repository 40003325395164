import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.EmailToSupplier', {
    title: 'Email to the Supplier',
    fieldTo: 'To',
    fieldFrom: 'From',
    fieldSubject: 'Subject',
    fieldCc: 'cc',
    fieldReplyTo: 'Reply to',
    fieldBody: 'Body',
    attachmentsTitle: 'Attachments for the Supplier',
});
