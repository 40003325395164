import { MutateDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { twoFaApiPaths } from '../paths';
import { mapData } from './useAddTFAEnablingBackupCodes.map';
import {
    AddTFAEnablingBackupCodesBackend,
    AddTFAEnablingBackupCodesRequest,
    AddTFAEnablingBackupCodesResponse,
} from './useAddTFAEnablingBackupCodes.types';

export const useAddTFAEnablingBackupCodes = () => {
    return useMutateLegacy<
        MutateDataParams<AddTFAEnablingBackupCodesRequest>,
        AddTFAEnablingBackupCodesBackend,
        AddTFAEnablingBackupCodesResponse
    >(twoFaApiPaths.addTFAEnablingBackupCodes, {
        mapData,
    });
};
