import type { QueryOptions } from '@approvalmax/data';
import { useGetLegacy } from 'shared/data/api';

import { paymentServicesApiPaths } from '../paths';
import type { UseAmaxPayConsentPathParams, UseAmaxPayConsentResponse } from './useAmaxPayConsent.types';

export const useAmaxPayConsent = (
    pathParams: UseAmaxPayConsentPathParams,
    queryOptions: QueryOptions<UseAmaxPayConsentResponse>
) =>
    useGetLegacy<UseAmaxPayConsentResponse>(paymentServicesApiPaths.getAmaxPayConsent, {
        pathParams,
        queryOptions,
        method: 'get',
    });
