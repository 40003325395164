import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { integrationApiPaths } from '../paths';
import { GetNetSuiteAmortizationSchedules } from './useGetNetSuiteAmortizationSchedules.types';

export const useGetNetSuiteAmortizationSchedules = (
    params?: RequestParams<GetNetSuiteAmortizationSchedules>,
    queryOptions?: RequestQueryOptions<GetNetSuiteAmortizationSchedules>
) => {
    return useGet(integrationApiPaths.getNetSuiteAmortizationSchedules, {
        params,
        queryOptions: {
            enabled: enabledByParams<GetNetSuiteAmortizationSchedules['params']['query']>(
                {
                    requestId: params?.query?.requestId,
                    companyId: params?.query?.companyId,
                },
                queryOptions
            ),
            ...queryOptions,
        },
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};
