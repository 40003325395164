import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages(
    'pages.RequestList.Toolbar.PayWithAmax.PaymentSummaryPopupContent.PaymentSummarySelectedLine',
    {
        pay: 'Pay {amount}',
        payInfo: `You are paying {amount}\nfor the {friendlyName}`,
        payNow: `Pay now`,
        schedulePayment: `Schedule payment`,
    }
);
