import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { delegationsApiPaths } from '../paths';
import { GetDelegations } from './useGetDelegations.types';

export const useGetDelegations = (
    params?: RequestParams<GetDelegations>,
    queryOptions?: RequestQueryOptions<GetDelegations>
) => {
    return useGet(delegationsApiPaths.delegations, {
        params,
        queryOptions,
        apiVersion: 'v2',
    });
};
