export const budgetsApiPaths = <const>{
    xeroBudgets: 'Budgets/xero/select',
    toggleXeroBudgetsSync: 'Budgets/xero/toggleBudgetsSync',
    syncXeroBudgets: 'Budgets/xero/syncBudgets',
    syncXeroBudget: 'Budgets/xero/syncBudget',
    setXeroBudgetCheckPeriod: 'Budgets/xero/setCheckPeriod',
    toggleXeroBudget: 'Budgets/xero/toggleBudget',
    setXeroBudgetZeroAmountHandlingType: 'Budgets/xero/setZeroAmountHandlingType',
    updateXeroBudget: 'Budgets/xero/edit',
    createXeroBudget: 'Budgets/xero/create',
    deleteXeroBudget: 'Budgets/xero/delete',
    setXeroBudgetReviewedTracking: 'Budgets/xero/setReviewedBudgetTracking',
    uploadXeroBudget: 'Budgets/xero/uploadBudget',
    downloadXeroBudgetSourceFile: 'Budgets/xero/downloadBudgetSourceFile',
    getBudgetImpacts: 'Budgets/getBudgetImpacts',
};
