import { FC, memo } from 'react';

import { useInvoiceItemListFieldSettings } from '../../NetSuiteRequestCard.hooks';
import { NetSuiteLineTable } from '../NetSuiteLineTable/NetSuiteLineTable';
import { useTableDefinitions } from './NetSuiteInvoiceItemList.hooks';
import { messages } from './NetSuiteInvoiceItemList.messages';
import { NetSuiteInvoiceItemListProps } from './NetSuiteInvoiceItemList.types';

export const NetSuiteInvoiceItemList: FC<NetSuiteInvoiceItemListProps> = memo((props) => {
    const { invoiceItemList, companyCustomFields, documentFields, exchangeRate } = props;

    const invoiceItemListFieldSettings = useInvoiceItemListFieldSettings(documentFields || []);

    const tableDef = useTableDefinitions(
        invoiceItemList,
        companyCustomFields,
        invoiceItemListFieldSettings,
        exchangeRate
    );

    if (invoiceItemList.length === 0) {
        return null;
    }

    return (
        <NetSuiteLineTable
            title={messages.sectionHeaderText}
            columnDefinitions={tableDef}
            lineItems={invoiceItemList}
        />
    );
});

NetSuiteInvoiceItemList.displayName = 'NetSuiteInvoiceItemList';
