import { Box, Flex, Tabs } from '@approvalmax/ui/src/components';
import { State } from 'modules/data';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SortableElement as sortableElement } from 'react-sortable-hoc';

import { finalizeAddStep, removeStep, renameStep } from '../../actions';
import {
    addingNewStep,
    getActiveTemplate,
    isPageReadonly,
    showCardValidationErrors,
} from '../../selectors/pageSelectors';
import { getStepApprovers } from '../../selectors/stepSelectors';
import ApprovalStepContent from '../ApprovalStepContent/ApprovalStepContent';
import DynamicStepContainer from '../DynamicStepContainer/DynamicStepContainer';
import ReviewStepContent from '../ReviewStepContent/ReviewStepContent';
import { messages } from './XeroApproveReviewStep.messages';
import { ActiveTab, XeroApproveReviewStepProps } from './XeroApproveReviewStep.types';

const XeroApproveReviewStep: FC<XeroApproveReviewStepProps> = memo((props) => {
    const { stepIndex, step } = props;

    const dispatch = useDispatch();
    const template = useSelector(getActiveTemplate)!;
    const readonly = useSelector(isPageReadonly);
    const renameOnMount = useSelector(addingNewStep);
    const showValidationErrors = useSelector(showCardValidationErrors);
    const approvers = useSelector((state: State) => getStepApprovers(state, template, step));

    const [activeTab, setActiveTab] = useState<string | number>(ActiveTab.Approvers);

    useEffect(() => {
        if (showValidationErrors && approvers.length === 0) {
            setActiveTab(ActiveTab.Approvers);
        }
    }, [showValidationErrors, approvers.length]);

    const onRename = useCallback(
        (newName: string) => {
            dispatch(renameStep(stepIndex, newName));
        },
        [dispatch, stepIndex]
    );

    const onRemove = useCallback(() => {
        dispatch(removeStep(stepIndex));
    }, [dispatch, stepIndex]);

    const onMounted = useCallback(() => {
        dispatch(finalizeAddStep());
    }, [dispatch]);

    const tabsItems = useMemo(
        () => [
            {
                label: messages.approvers,
                width: '50%',
                textAlign: 'center' as const,
                value: ActiveTab.Approvers,
                children: (
                    <Box spacing='8 0 0'>
                        <Flex direction='column' spacing='12'>
                            <ApprovalStepContent step={step} stepIndex={stepIndex} />
                        </Flex>
                    </Box>
                ),
            },
            {
                label: messages.reviewers,
                width: '50%',
                textAlign: 'center' as const,
                value: ActiveTab.Reviewers,
                children: (
                    <Box spacing='8 0 0'>
                        <Flex direction='column' spacing='12'>
                            <ReviewStepContent step={step} stepIndex={stepIndex} />
                        </Flex>
                    </Box>
                ),
            },
        ],
        [step, stepIndex]
    );

    return (
        <DynamicStepContainer
            readonly={readonly}
            renameOnMount={renameOnMount}
            color='green80'
            removable
            onRename={onRename}
            onRemove={onRemove}
            onMounted={onMounted}
            stepName={step.name}
        >
            <Box width='100%'>
                <Tabs color='midnight80' items={tabsItems} size='small' onChange={setActiveTab} value={activeTab} />
            </Box>
        </DynamicStepContainer>
    );
});

export default sortableElement<XeroApproveReviewStepProps>(XeroApproveReviewStep);
