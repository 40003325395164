import { CloseIcon } from '@approvalmax/ui';
import { Box, Button, Flex, Grid, Spacing, Text } from '@approvalmax/ui/src/components';
import { hooks } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { RequestListFilter } from 'pages/requestList/config';
import { memo, useCallback } from 'react';
import { routingService } from 'services/routing';
import { getPath, Path } from 'urlBuilder';

import { featureDescriptionList } from './AccrualReportsBanner.constants';
import { messages } from './AccrualReportsBanner.messages';
import { AccrualReportsBannerProps } from './AccrualReportsBanner.types';

export const AccrualReportsBanner = memo<AccrualReportsBannerProps>((props) => {
    const { company, withSpacing } = props;

    const [showAccrualReportsBanner, setShowAccrualReportsBanner] = hooks.useLocalStorage(
        'showAccrualReportsBanner',
        true
    );

    const handleClose = useCallback(() => {
        setShowAccrualReportsBanner(false);
    }, [setShowAccrualReportsBanner]);

    const handleTryForFree = useCallback(() => {
        if (company.subscriptionId) {
            routingService.navigateToExternalUrlInNewTab(
                getPath(Path.requestMyList, RequestListFilter.MyDecisionRequired, {
                    showStartTrialPopupForCompanyId: company.id,
                    startTrialPopupOrigin: 'accrual_reports_popup',
                })
            );
        }
    }, [company]);

    const isXeroStandardPlan =
        company.licenseProductPlan === domain.CompanyPlan.Xero ||
        company.licenseProductPlan === domain.CompanyPlan.ReceiptBankXeroStandard;

    const isTryForFreeEnabled =
        company.subscriptionId &&
        isXeroStandardPlan &&
        company.allFeaturesTrialStatus === domain.AllFeaturesTrialStatus.AwaitingActivation &&
        company.flags.isManager;

    if (!showAccrualReportsBanner) {
        return null;
    }

    return (
        <>
            {withSpacing && <Spacing height={32} />}

            <Box bordered radius='small' spacing='16 24'>
                <Grid gridTemplateColumns='auto max-content' columnGap={8} padding='0 0 16 0'>
                    <Text font='headline' fontSize='xxsmall' fontWeight='medium'>
                        {messages.title}
                    </Text>

                    <Button icon onClick={handleClose}>
                        <CloseIcon size={16} />
                    </Button>
                </Grid>

                <Grid gridTemplateColumns='auto max-content' columnGap={24} padding='0 0 20 0'>
                    <Grid rowGap={16}>
                        {featureDescriptionList.map((feature, index) => (
                            <Grid key={index} rowGap={4}>
                                <Text font='label' fontSize='small' fontWeight='medium'>
                                    {feature.title}
                                </Text>

                                <Text font='body' fontSize='small'>
                                    {feature.description}
                                </Text>
                            </Grid>
                        ))}
                    </Grid>

                    <iframe
                        width='430px'
                        height='240px'
                        src='https://www.youtube.com/embed/ppvj6ikCkLg?si=T5eki2lz1-H8rv2P'
                        title='YouTube video player'
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                        referrerPolicy='strict-origin-when-cross-origin'
                    />
                </Grid>

                <Flex justifyContent='end' spacing='12'>
                    <Button
                        color='midnight40'
                        outline
                        as='a'
                        href='https://support.approvalmax.com/portal/en/kb/articles/how-to-create-line-item-accrual-report-and-export-it-to-a-manual-journal'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        {messages.learnMore}
                    </Button>

                    {isTryForFreeEnabled && (
                        <Button color='mint80' onClick={handleTryForFree}>
                            {messages.tryForFree}
                        </Button>
                    )}
                </Flex>
            </Box>
        </>
    );
});

AccrualReportsBanner.displayName = 'AccrualReportsBanner';
