import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.requestList.PayWithAirwallex.SchedulePaymentPopup', {
    titleSchedule: 'Schedule payment',
    titleTwoFA: 'Authorise the payment',
    create: 'Create',
    save: 'Save',
    description: 'Select payment date and time',
    paymentDate: 'Payment date',
    paymentTime: 'Payment time',
    placeholder: 'Select...',
    twoFAToConfirm:
        "To confirm the batch payment for processing in Airwallex {br} on {date}, please enter the 6-digit authentication code provided by the Authentication app connected to your ApprovalMax account.{br}{br} You can cancel the payment before the scheduled time. Once the batch is fully processed (or in case of any issues), you'll receive a notification. Additionally, you can track the payment status on the batch form.{br}{br}",
    twoFAYouCanCancel:
        "You can cancel the payment before the scheduled time. Once the batch is fully processed (or in case of any issues), you'll receive a notification. Additionally, you can track the payment status on the batch form.",
    linkText: 'If you have problems, refer to this {link}.',
    supportArticle: 'support article',
    twoFAEnterCode: 'Enter the Authenticator code',
});
