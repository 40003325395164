import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { integrationApiPaths } from '../paths';
import { CheckDearConnection } from './useCheckDearConnection.types';

export const useCheckDearConnection = (mutationOptions?: RequestMutationOptions<CheckDearConnection>) => {
    return useMutate(integrationApiPaths.checkDearConnection, {
        mutationOptions,
        apiVersion: 'v1',
    });
};
