import { RichEditor } from '@approvalmax/ui/src/components';
import { AttachmentList } from 'modules/components/Attachment/AttachmentList';
import { domain } from 'modules/data';
import FieldEntry from 'pages/requestList/components/FieldEntry';
import { SectionContainer } from 'pages/requestList/components/SectionContainer/SectionContainer';
import { replacePlaceholders } from 'pages/requestList/utils/helpers';
import { memo, useCallback } from 'react';

import { customMentionConfig } from './EmailToSupplier.config';
import { messages } from './EmailToSupplier.messages';
import {
    AttachContent,
    AttachRow,
    AttachTitle,
    FieldCc,
    Row,
    Section,
    StyledResendQuote,
} from './EmailToSupplier.styles';
import { EmailToSupplierProps } from './EmailToSupplier.types';

const EmailToSupplier = memo<EmailToSupplierProps>((props) => {
    const { request, placeholderReplacements, activeAttachmentId, labels, onAttachmentOpen } = props;

    const details = request.details;
    const isSalesInvoice = details.integrationCode === domain.IntegrationCode.QBooksInvoice;

    const isQuote = request.integrationCode === domain.IntegrationCode.XeroQuote;
    const isApproved = request.statusV2 === domain.RequestStatusV2.Approved;
    const isResendQuoteEnabled = isQuote && isApproved && !request.details.isStatusPushPending;

    const getMentionItems = useCallback(
        ({ query }: { query: string }) =>
            Object.keys(placeholderReplacements || {})
                .filter((item) => item.toLowerCase().includes(query.toLowerCase()))
                .map((item) => ({
                    placeholderName: item,
                })),
        [placeholderReplacements]
    );

    const emailToSupplier = isSalesInvoice ? details.emailToContact : details.emailToSupplier;

    if (!emailToSupplier || !emailToSupplier.emailHasToBeSent) {
        return null;
    }

    const emailToSupplierBody = replacePlaceholders(emailToSupplier.body, placeholderReplacements || {});

    const emailToSupplierSubject = replacePlaceholders(emailToSupplier.subject, placeholderReplacements || {});

    return (
        <SectionContainer title={labels?.title || messages.title}>
            <Section>
                <Row>
                    <FieldEntry
                        qa='email-to-supplier-to-field'
                        title={messages.fieldTo}
                        value={emailToSupplier.to.join(', ')}
                    />

                    <FieldEntry
                        qa='email-to-supplier-from-field'
                        title={messages.fieldFrom}
                        value={emailToSupplier.from}
                    />

                    {isResendQuoteEnabled && <StyledResendQuote request={request} />}
                </Row>

                <Row>
                    <FieldCc
                        hide={emailToSupplier.cc.length === 0}
                        qa='email-to-supplier-cc-field'
                        title={messages.fieldCc}
                        value={emailToSupplier.cc.join(', ')}
                    />

                    <FieldEntry
                        qa='email-to-supplier-reply-to-field'
                        title={messages.fieldReplyTo}
                        value={emailToSupplier.replyTo}
                    />
                </Row>

                <Row>
                    <FieldEntry
                        qa='email-to-supplier-subject-field'
                        title={messages.fieldSubject}
                        forceDisplayValue={
                            <RichEditor
                                value={emailToSupplierSubject || ''}
                                allowMention
                                mentionItems={getMentionItems}
                                customMentionConfig={customMentionConfig}
                                allowTextFormatting={false}
                                minHeight={30}
                                readOnly
                            />
                        }
                    />
                </Row>

                <Row>
                    <FieldEntry
                        qa='email-to-supplier-body-field'
                        title={messages.fieldBody}
                        forceDisplayValue={
                            <RichEditor
                                value={emailToSupplierBody || ''}
                                allowMention
                                mentionItems={getMentionItems}
                                customMentionConfig={customMentionConfig}
                                readOnly
                                allowTextFormatting={false}
                            />
                        }
                    />
                </Row>

                {emailToSupplier.attachments.length > 0 && (
                    <AttachRow>
                        <AttachTitle>
                            <span>{labels?.attachmentsTitle || messages.attachmentsTitle}</span>
                        </AttachTitle>

                        <AttachContent>
                            <AttachmentList
                                attachments={emailToSupplier.attachments}
                                onOpen={onAttachmentOpen}
                                activeAttachmentId={activeAttachmentId}
                            />
                        </AttachContent>
                    </AttachRow>
                )}
            </Section>
        </SectionContainer>
    );
});

export default EmailToSupplier;
