import { Reference } from '@approvalmax/types';
import { selectors } from 'modules/common';
import { backend, schemas, State } from 'modules/data';
import { normalize } from 'normalizr';

import { RowMeta } from '../config/types';
import { Entities } from '../reducers/entitiesReducer';

export const parseNoop = (value: string | number | null) => {
    return value;
};

export const parseReference = (value: { Id: string; Name: string } | string | null): Reference => {
    if (value && typeof value === 'object' && 'Id' in value) {
        return {
            id: value.Id,
            text: value.Name,
        };
    } else {
        return {
            id: value || '',
            text: value || '',
        };
    }
};

export const parseUser = (
    value: backend.UserAnswer,
    meta: RowMeta,
    state: State
): selectors.types.ExpandedUser | null => {
    const companyUser = selectors.company.findCompanyMemberById(
        state,
        selectors.navigation.getActiveCompany(state),
        value.UserEmail || ''
    );

    if (!companyUser) {
        let entities: Entities = normalize(value, schemas.userSchema).entities as any;

        const firstUser = Object.values(entities.users)[0];

        return firstUser ? selectors.user.expandUser(firstUser) : null;
    }

    return companyUser;
};

export const parsePercentage = (value?: string | number | null) => {
    return value !== null && value !== undefined ? `${value}%` : value;
};
