import { RequestQueryOptions } from '@approvalmax/data';
import { useQuery } from '@tanstack/react-query';

import { customerFeedbackApiPaths } from '../paths';
import { apiRequest } from './useGetCannyIoBoards.api';
import { GetCannyIoBoards } from './useGetCannyIoBoards.types';

export const useGetCannyIoBoards = (queryOptions?: RequestQueryOptions<GetCannyIoBoards>) => {
    return useQuery([customerFeedbackApiPaths.cannyIoBoards], apiRequest, queryOptions);
};
