import { MutateDataParams, MutationOptions } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { UseAmaxXeroPayData, UseAmaxXeroPayPathParams, UseAmaxXeroPayResponse } from './useAmaxXeroPay.types';

export const useAmaxXeroPay = (
    mutationOptions?: MutationOptions<
        UseAmaxXeroPayResponse,
        MutateDataParams<UseAmaxXeroPayData, UseAmaxXeroPayPathParams>
    >
) => {
    return useMutateLegacy<MutateDataParams<UseAmaxXeroPayData, UseAmaxXeroPayPathParams>, UseAmaxXeroPayResponse>(
        requestsApiPaths.amaxPayXeroPay,
        {
            mutationOptions,
        }
    );
};
