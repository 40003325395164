import { memo, useState } from 'react';

import BalanceStep from './BalanceStep/BalanceStep';
import { usePay } from './CompleteAirwallexPaymentPopup.hooks';
import { CompleteAirwallexPaymentPopupProps, Step } from './CompleteAirwallexPaymentPopup.types';
import DiscrepanciesStep from './DiscrepanciesStep/DiscrepanciesStep';
import ProcessingStep from './ProcessingStep/ProcessingStep';
import TwoFaStep from './TwoFaStep/TwoFaStep';

const CompleteAirwallexPaymentPopup = memo<CompleteAirwallexPaymentPopupProps>((props) => {
    const { onRequestClose, request } = props;
    const [currentStep, setCurrentStep] = useState<Step>(Step.Balance);

    const { mutate: payApiCall, error } = usePay({
        onRequestClose,
        setCurrentStep,
        request,
    });

    switch (currentStep) {
        case Step.Balance:
            return <BalanceStep onRequestClose={onRequestClose} setCurrentStep={setCurrentStep} request={request} />;

        case Step.TwoFa:
            return (
                <TwoFaStep
                    onRequestClose={onRequestClose}
                    payApiCall={payApiCall}
                    request={request}
                    setCurrentStep={setCurrentStep}
                />
            );

        case Step.Processing:
            return <ProcessingStep />;

        case Step.Discrepancies:
            return (
                <DiscrepanciesStep
                    title={request.displayName}
                    beneficiaryDiscrepancies={error?.beneficiaryDiscrepancies || []}
                    onRequestClose={onRequestClose}
                />
            );
    }
});

export default CompleteAirwallexPaymentPopup;
