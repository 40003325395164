import { forwardRef, memo, useCallback, useImperativeHandle, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { Button, Flex, Text } from '../../../../components';
import { CloseIcon } from '../../../../icons';
import { fullscreenSplitViewState, openSplitViewState } from '../../SplitView.states';
import { ToggleFullscreen } from '../index';
import { useHideParentElement } from './Item.hooks';
import { Body, Root, Toolbar } from './Item.styles';
import { ItemProps } from './Item.types';

export const Item = memo(
    forwardRef<HTMLDivElement, ItemProps>((props, ref) => {
        const {
            children,
            title,
            hideToggleFullscreen,
            showInFullScreen,
            renderByDefault,
            customToolbar,
            onClose,
            actions,
            first,
            last,
            qa,
            ...restProps
        } = props;
        const innerRef = useRef<HTMLDivElement>(null);

        useImperativeHandle(ref, () => innerRef.current as HTMLDivElement);

        const fullscreenState = useRecoilValue(fullscreenSplitViewState);
        const [openState, setOpenState] = useRecoilState(openSplitViewState);

        const hasFullscreenMode = openState && fullscreenState && showInFullScreen;
        const showCloseButton = (openState && !fullscreenState && first) || hasFullscreenMode;
        const showToggleFullscreen =
            !hideToggleFullscreen && ((openState && !fullscreenState && last) || hasFullscreenMode);

        useHideParentElement({ innerRef, fullscreenState, openState, renderByDefault, showInFullScreen });

        const close = useCallback(() => {
            onClose?.();
            setOpenState(false);
        }, [onClose, setOpenState]);

        return (
            <Root ref={innerRef} $open={openState} {...restProps}>
                <Toolbar $open={openState} alignItems='center' wrap={false} justifyContent='space-between' shrink={0}>
                    {customToolbar ? (
                        customToolbar
                    ) : (
                        <>
                            <Flex alignItems='center' wrap={false} spacing='8'>
                                {showCloseButton && (
                                    <Button
                                        onClick={close}
                                        icon
                                        size='small'
                                        color='white100'
                                        data-qa={qa?.('toolbar-back-button')}
                                    >
                                        <CloseIcon size={28} />
                                    </Button>
                                )}

                                {typeof title === 'string' ? (
                                    <Text ellipsis={1} font='body' data-qa={qa?.('toolbar-title')}>
                                        {title}
                                    </Text>
                                ) : (
                                    title
                                )}
                            </Flex>

                            <Flex alignItems='center' shrink={0} grow={0} spacing='16'>
                                {actions}

                                {showToggleFullscreen && <ToggleFullscreen />}
                            </Flex>
                        </>
                    )}
                </Toolbar>

                <Body $open={openState}>{children}</Body>
            </Root>
        );
    })
);

Item.displayName = 'SplitView.Item';
