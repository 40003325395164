import uniqueId from 'lodash/uniqueId';
import { domain } from 'modules/data';
import { FC, memo, useMemo } from 'react';

import { getSalesOrderItemsFieldSettings, useLineCustomFieldsDefinition } from '../../NetSuiteRequestCard.hooks';
import { NetSuiteLineTable } from '../NetSuiteLineTable/NetSuiteLineTable';
import { getNetSuiteSalesOrderLineItemsColumnDefenitions } from './NetSuiteSalesOrderItemLines.config';
import { messages } from './NetSuiteSalesOrderItemLines.messages';
import { NetSuiteSalesOrderItemLinesProps } from './NetSuiteSalesOrderItemLines.types';

const NetSuiteSalesOrderItemLines: FC<NetSuiteSalesOrderItemLinesProps> = memo((props) => {
    const { itemLines, companyCustomFields, exchangeRate, documentFields } = props;

    const enrichedItemsLines = useMemo<domain.NetSuiteSalesOrderItemWithId[]>(() => {
        return itemLines.map((itemLine) => ({
            ...itemLine,
            id: uniqueId('netSuiteSalesOrderItemLine-'),
        }));
    }, [itemLines]);

    const itemsFieldSettings = getSalesOrderItemsFieldSettings(documentFields);

    const customFieldsColumnDefinition = useLineCustomFieldsDefinition(enrichedItemsLines, companyCustomFields);

    const resultColumnDefinition = useMemo(
        () => [
            ...getNetSuiteSalesOrderLineItemsColumnDefenitions(itemsFieldSettings, exchangeRate),
            ...customFieldsColumnDefinition,
        ],
        [customFieldsColumnDefinition, exchangeRate, itemsFieldSettings]
    );

    return itemLines.length > 0 ? (
        <NetSuiteLineTable
            title={messages.sectionHeaderText}
            columnDefinitions={resultColumnDefinition}
            lineItems={enrichedItemsLines}
        />
    ) : null;
});

export default NetSuiteSalesOrderItemLines;
