import { TransparentButton } from '@approvalmax/ui';
import { intl } from '@approvalmax/utils';
import { DropdownTriangleIcon } from 'modules/sprites';
import { font } from 'modules/styles';
import { FC, PropsWithChildren, useState } from 'react';
import { defineMessages } from 'react-intl';
import styled, { css } from 'styled-components/macro';
import { VelocityTransitionGroup } from 'velocity-react';

const i18nPrefix =
    'requestList/containers/xero/XeroMatchingV2Section/components/BillView/components/ChangeMatchingPopup/components/ChangeMatchingPopupFilter/components/CollapsibleSection/CollapsibleSection';

const messages = defineMessages({
    filtersAreApplied: {
        id: `${i18nPrefix}.filtersAreApplied`,
        defaultMessage: 'Filters are applied',
    },
});

const ToggleButton = styled(TransparentButton)`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0 10px;
    fill: #477753;
    transition: fill 500ms ease-in-out;
    cursor: pointer;
`;

const ButtonContent = styled.div`
    display: flex;
    align-items: center;
`;

const LabelAppliedFilters = styled.div`
    ${font(13, '#4c8a5c', 'semibold')}
    margin-right: 60px;
`;

const StyledIconWrapper = styled.div<{ expanded: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;

    svg {
        transform: rotate(180deg);
        transition: transform 300ms ease-in-out;
    }

    ${(props) =>
        props.expanded &&
        css`
            svg {
                transform: rotate(0);
            }
        `}
`;

const SectionTitle = styled.span`
    margin-right: 5px;
    text-transform: uppercase;
    ${font(14, '#000', 'semibold')}
`;

const ChildrenContainer = styled.div`
    padding: 0 60px 0 80px;
`;

interface CollapsibleSectionProps extends PropsWithChildren {
    defaultExpanded?: boolean;
    hasAppliedFilter: boolean;
    title: string;
}

const CollapsibleSection: FC<CollapsibleSectionProps> = (props) => {
    const { defaultExpanded = false, hasAppliedFilter, children, title } = props;

    const [expanded, setExpanded] = useState(defaultExpanded);

    const showLabelAppliedFilter = hasAppliedFilter && !expanded;

    const onToggleExpanded = () => {
        setExpanded((prevState) => !prevState);
    };

    return (
        <div>
            <ToggleButton execute={onToggleExpanded}>
                <ButtonContent>
                    <StyledIconWrapper expanded={expanded}>
                        <DropdownTriangleIcon />
                    </StyledIconWrapper>

                    <SectionTitle>{title}</SectionTitle>
                </ButtonContent>

                <ButtonContent>
                    <VelocityTransitionGroup
                        enter={{ animation: 'slideDown', duration: 300 }}
                        leave={{ animation: 'slideUp', duration: 300 }}
                    >
                        {showLabelAppliedFilter && (
                            <LabelAppliedFilters>{intl.formatMessage(messages.filtersAreApplied)}</LabelAppliedFilters>
                        )}
                    </VelocityTransitionGroup>
                </ButtonContent>
            </ToggleButton>

            <VelocityTransitionGroup
                enter={{ animation: 'slideDown', duration: 300 }}
                leave={{ animation: 'slideUp', duration: 300 }}
            >
                {expanded ? <ChildrenContainer>{children}</ChildrenContainer> : undefined}
            </VelocityTransitionGroup>
        </div>
    );
};

export default CollapsibleSection;
