import styled from 'styled-components';

export const Root = styled.div`
    display: flex;
    padding: 0 50px 10px 60px;
    margin-bottom: 20px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e4e4e4;

    & > * {
        margin-right: 10px;
        margin-bottom: 10px;
        min-width: 120px;
        max-width: 100%;
        flex: 1;
    }
`;

export const Header = styled.div`
    flex: none;
    font-family: 'Open Sans', sans-serif, Arial;
    font-weight: 600;
    font-size: 14px;
    color: #000;
`;

export const ButtonsSection = styled.div`
    display: flex;
    justify-content: flex-end;

    & > :not(:last-child) {
        margin-right: 5px;
    }
`;
