import { Guid } from '@approvalmax/types';
import { mathService } from '@approvalmax/utils';
import { backend, domain } from 'modules/data';
import { ApiTypes } from 'shared/data/v2';

import { ApiHandler } from '../types';

export const formatResponse = <
    T extends backend.Answer<
        | backend.XeroPOMatchingInfo
        | backend.XeroBillMatchingInfo
        | backend.XeroMatchingAllocations
        | ApiTypes['MatchingXeroBillMatchingInfo']
    >,
>(
    response: T
): T => {
    const newResponse = {
        ...response,
    };

    // we can receive totalAmount and totalAllocatedAmount with wrong accuracy, it may break the calculation
    if ('totalAmount' in response.data && response.data.totalAmount) {
        newResponse.data = {
            ...response.data,
            totalAmount: response.data.totalAmount ? mathService.round(response.data.totalAmount, 2) : 0,
        };
    }

    if ('totalAllocatedAmount' in response.data && response.data.totalAllocatedAmount) {
        newResponse.data = {
            ...response.data,
            totalAllocatedAmount: response.data.totalAllocatedAmount
                ? mathService.round(response.data.totalAllocatedAmount, 2)
                : 0,
        };
    }

    if ('documents' in newResponse.data && newResponse.data.documents?.length) {
        // It was a bad idea to merge v1 and v2 api answers
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        newResponse.data.documents = newResponse.data.documents.map((doc) => ({
            ...doc,
            totalAmount: doc.totalAmount ? mathService.round(doc.totalAmount, 2) : 0,
            allocatedAmount: doc.allocatedAmount ? mathService.round(doc.allocatedAmount, 2) : 0,
        }));
    }

    if ('allocations' in newResponse.data && newResponse.data.allocations?.length) {
        newResponse.data.allocations = newResponse.data?.allocations.map((allocation) => ({
            ...allocation,
            totalAmount: allocation.totalAmount ? mathService.round(allocation.totalAmount, 2) : 0,
            allocatedAmount: allocation.allocatedAmount ? mathService.round(allocation.allocatedAmount, 2) : 0,
        }));
    }

    return newResponse;
};

export default class XeroMatchingApi {
    constructor(private _apiHandler: ApiHandler) {}

    getBillMatchingInfo = async (data: {
        billId: string;
        companyId: Guid;
    }): Promise<backend.Answer<backend.XeroBillMatchingInfo>> => {
        const response = await this._apiHandler.doApiCall({
            data,
            action: 'matching/xero/getInfo',
            method: 'POST',
            allowsAnonymous: false,
        });

        return formatResponse(response);
    };

    getPOMatchingInfo = async (data: {
        purchaseOrderId: string;
        companyId: Guid;
    }): Promise<backend.Answer<backend.XeroPOMatchingInfo>> => {
        const response = await this._apiHandler.doApiCall({
            data,
            action: 'matching/xero/getInfo',
            method: 'POST',
            allowsAnonymous: false,
        });

        return formatResponse(response);
    };

    applyMatching(data: domain.XeroMatchingTransfer): Promise<backend.BaseAnswer> {
        return this._apiHandler.doApiCall({
            data,
            action: 'matching/xero/apply',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    getMatchingContext = async (
        billId: string,
        companyId: string,
        POFilter: domain.XeroMatchingV2POFilter
    ): Promise<backend.Answer<backend.XeroMatchingContext>> => {
        const response = await this._apiHandler.doApiCall({
            data: { billId, companyId, POFilter },
            action: 'matching/xero/getContext',
            method: 'POST',
            allowsAnonymous: false,
        });

        return formatResponse(response);
    };

    getListAllocations = async (
        purchaseOrderId: string,
        companyId: Guid
    ): Promise<backend.Answer<backend.XeroMatchingAllocations>> => {
        const response = await this._apiHandler.doApiCall({
            data: { purchaseOrderId, companyId },
            action: 'matching/xero/listAllocations',
            method: 'POST',
            allowsAnonymous: false,
        });

        return formatResponse(response);
    };
}
