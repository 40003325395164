import { selectors } from 'modules/common';
import { backend, domain } from 'modules/data';
import moment from 'moment';
import { FC, memo } from 'react';

import FieldEntry from '../../../../../components/FieldEntry';
import { messages } from './MainInformationSection.messages';
import { Container, FieldOrdered, Root } from './MainInformationSection.styles';
import { MainInformationSectionProps } from './MainInformationSection.types';

const MainInformationSection: FC<MainInformationSectionProps> = memo((props) => {
    const { billBatchPayment, countryCode, lockDateWarningText } = props;

    const isUkCompany = countryCode === backend.OrganisationVersion.UK;

    return (
        <Root>
            <Container>
                <FieldOrdered order={1}>
                    <FieldEntry
                        title={messages.paymentDate}
                        value={billBatchPayment.paymentDate && moment.utc(billBatchPayment.paymentDate!).format('ll')}
                        warningText={lockDateWarningText}
                    />
                </FieldOrdered>

                <FieldOrdered order={2}>
                    <FieldEntry title={messages.bankAccount} value={billBatchPayment.bankAccount} />
                </FieldOrdered>

                {selectors.field.getSupportRegionalFieldByCountryCode(countryCode, domain.RegionalField.Narrative) && (
                    <FieldOrdered order={isUkCompany ? 4 : 3}>
                        <FieldEntry title={messages.narrative} value={billBatchPayment.narrative} />
                    </FieldOrdered>
                )}

                {selectors.field.getSupportRegionalFieldByCountryCode(countryCode, domain.RegionalField.Details) && (
                    <FieldOrdered order={isUkCompany ? 3 : 4}>
                        <FieldEntry title={messages.details} value={billBatchPayment.details} hideIfEmpty />
                    </FieldOrdered>
                )}

                {selectors.field.getSupportRegionalFieldByCountryCode(
                    countryCode,
                    domain.RegionalField.Particulars
                ) && (
                    <FieldOrdered order={5}>
                        <FieldEntry title={messages.particulars} value={billBatchPayment.particulars} />
                    </FieldOrdered>
                )}

                {selectors.field.getSupportRegionalFieldByCountryCode(countryCode, domain.RegionalField.Code) && (
                    <FieldOrdered order={6}>
                        <FieldEntry title={messages.code} value={billBatchPayment.code} />
                    </FieldOrdered>
                )}

                {selectors.field.getSupportRegionalFieldByCountryCode(countryCode, domain.RegionalField.Reference) && (
                    <FieldOrdered order={7}>
                        <FieldEntry title={messages.reference} value={billBatchPayment.reference} />
                    </FieldOrdered>
                )}
            </Container>
        </Root>
    );
});

export default MainInformationSection;
