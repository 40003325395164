import { ConfirmationDropdown, TextButton } from '@approvalmax/ui';
import { domain } from 'modules/data';
import { GlobalLoadingBar } from 'modules/page';
import { FC, memo } from 'react';
import bemFactory from 'react-bem-factory';

import CopyOperation from '../../../CopyOperation/CopyOperation';
import { useSecondaryActions } from './SecondaryActions.hooks';
import { messages } from './SecondaryActions.messages';
import {
    Container,
    SecondaryActionsButtons,
    SecondaryActionsSeparator,
    SecondaryActionsTitle,
} from './SecondaryActions.styles';
import { SecondaryActionsProps } from './SecondaryActions.types';

const qa = bemFactory.qa('reql-toolbar-body');

const SecondaryActions: FC<SecondaryActionsProps> = memo((props) => {
    const { isLocked, hasMatchingValidationError, request } = props;

    const {
        startOver,
        edit,
        revoke,
        remove,
        cancel,
        reverse,
        requestCommands,
        isReverseRequestLoading,
        editCommand,
        isDisconnected,
        isCompanyReadonly,
        templateName,
    } = useSecondaryActions(request, hasMatchingValidationError);

    const isActionDisabled = isLocked || isCompanyReadonly || isDisconnected;
    const isAwxScheduledPayment =
        request.integrationCode === domain.IntegrationCode.XeroAirwallexBatchPayment &&
        !!request.details.scheduledPaymentDate;

    return (
        <>
            <GlobalLoadingBar isLoading={isReverseRequestLoading} />

            {requestCommands.some((c) => !c.hidden) && (
                <Container>
                    <SecondaryActionsTitle>{messages.secondaryActions({ templateName })}</SecondaryActionsTitle>

                    <SecondaryActionsButtons>
                        <TextButton
                            qa={qa('edit-button')}
                            execute={edit}
                            disabled={isActionDisabled}
                            command={editCommand}
                            title={messages.editButtonTitle}
                        >
                            {messages.editButtonText}
                        </TextButton>

                        {!editCommand.hidden && <SecondaryActionsSeparator />}

                        <TextButton
                            qa={qa('start-over-button')}
                            execute={startOver}
                            disabled={isActionDisabled}
                            command={request.commands.startOver}
                            title={messages.startOverButtonTitle}
                        >
                            {messages.startOverButtonText}
                        </TextButton>

                        {!request.commands.startOver.hidden && <SecondaryActionsSeparator />}

                        {<CopyOperation request={request} disabled={isActionDisabled} />}

                        {(!request.commands.clone.hidden || !request.commands.clonePOToBill.hidden) && (
                            <SecondaryActionsSeparator />
                        )}

                        <TextButton
                            qa={qa('reverse-button')}
                            execute={reverse}
                            disabled={isActionDisabled || isReverseRequestLoading}
                            command={request.commands.reverse}
                            title={messages.reverseButtonTitle}
                        >
                            {messages.reverseButtonText}
                        </TextButton>

                        {!request.commands.reverse.hidden && <SecondaryActionsSeparator />}

                        <TextButton
                            qa={qa('revoke-button')}
                            execute={revoke}
                            disabled={isActionDisabled}
                            command={request.commands.revoke}
                            title={messages.revokeButtonTitle}
                        >
                            {messages.revokeButtonText}
                        </TextButton>

                        {!request.commands.revoke.hidden && <SecondaryActionsSeparator />}

                        <ConfirmationDropdown confirmation={messages.deleteConfirmationText}>
                            <TextButton
                                disabled={isActionDisabled}
                                qa={qa('delete-button')}
                                execute={remove}
                                command={request.commands.delete}
                                title={messages.deleteButtonTitle}
                            >
                                {messages.deleteButtonText}
                            </TextButton>
                        </ConfirmationDropdown>

                        {!request.commands.delete.hidden && <SecondaryActionsSeparator />}

                        <TextButton
                            qa={qa('cancel-button')}
                            execute={cancel}
                            disabled={isActionDisabled || isAwxScheduledPayment}
                            command={request.commands.cancel}
                            title={
                                isAwxScheduledPayment
                                    ? messages.cancelButtonTitleScheduledReason
                                    : messages.cancelButtonTitle
                            }
                        >
                            {messages.cancelButtonText}
                        </TextButton>

                        {!request.commands.cancel.hidden && <SecondaryActionsSeparator />}
                    </SecondaryActionsButtons>
                </Container>
            )}
        </>
    );
});

SecondaryActions.displayName = 'SecondaryActions';

export default SecondaryActions;
