import { selectors } from 'modules/common';
import { getLockDateWarningText } from 'modules/common/selectors/requestSelectors.Xero';
import { domain, State } from 'modules/data';
import { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import SectionSeparator from '../../../components/SectionSeparator/SectionSeparator';
import BillsInformationSection from './components/BillsInformationSection/BillsInformationSection';
import MainInformationSection from './components/MainInformationSection/MainInformationSection';

const Root = styled.div`
    padding: 20px 60px;
`;

interface BillBatchPaymentRequestCardProps {
    request: domain.XeroBillBatchPaymentRequest;
}

const BillBatchPaymentRequestCard: FC<BillBatchPaymentRequestCardProps> = (props) => {
    const { request } = props;
    const expandedRequest = useSelector((state: State) => selectors.request.expandRequest(state, request));
    const lockDateWarningText = getLockDateWarningText(request);

    const billBatchPayment = request.details;
    const integration = useSelector((state: State) =>
        selectors.integration.getIntegrationByCompanyId(state, request.companyId)
    );

    return (
        <Root>
            <MainInformationSection
                billBatchPayment={billBatchPayment}
                countryCode={integration!.countryCode}
                lockDateWarningText={lockDateWarningText}
            />

            <SectionSeparator />

            <BillsInformationSection
                billBatchPayment={billBatchPayment}
                currency={request.currency}
                amount={request.amount}
                countryCode={integration!.countryCode}
                request={expandedRequest}
            />
        </Root>
    );
};

export default memo(BillBatchPaymentRequestCard);
