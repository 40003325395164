import {
    AllFeatureTrialPaywallPopup,
    TrialCompany,
    TrialIntegrationType,
} from '@approvalmax/ui/src/components/complex';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { hooks } from 'modules/profile';
import { useSelector } from 'modules/react-redux';
import { useUserProfile } from 'modules/utils';
import { FC, memo, useCallback, useMemo } from 'react';
import { useMount } from 'react-use';
import { amplitudeService } from 'services/amplitude';
import { routingService } from 'services/routing';
import { AccountPath, getAccountUrl } from 'urlBuilder';

import { getInitialFeatureByOrigin, getPlanType } from './StartTrialPeriodPopup.helpers';
import { StartTrialPeriodPopupProps } from './StartTrialPeriodPopup.types';

export const StartTrialPeriodPopup: FC<StartTrialPeriodPopupProps> = memo((props) => {
    const { data, isOpen, onClose } = props;

    const profile = useUserProfile();
    const awaitingActivationTrialCompanies = useSelector(selectors.company.getAwaitingActivationTrialCompaniesAllRoles);

    const subscriptions = useSelector(selectors.subscription.getAllSubscriptions);

    const companies = useMemo(() => {
        const companies: TrialCompany[] = [];

        awaitingActivationTrialCompanies.forEach((company) => {
            if (company.integration?.integrationType === domain.IntegrationType.Xero) {
                const subscription = subscriptions.find(({ id }) => company.subscriptionId === id);

                companies.push({
                    id: company.id,
                    name: company.name,
                    integrationType: TrialIntegrationType.Xero,
                    plan: getPlanType(company?.licenseProductPlan),
                    isUpgradeSubcriptionVisible: company.author === profile.email && !subscription?.isFrozenForUpgrade,
                    isNotManager: !company.flags.isManager,
                    hasAddOnCapture: subscription?.addonStatistics.some(
                        ({ feature }) => feature === domain.AddonTypes.Capture
                    ),
                    hasAddOnPay:
                        subscription?.addonStatistics.some(({ feature }) => feature === domain.AddonTypes.Pay) ||
                        company.betaFeatures.includes(domain.CompanyBetaFeature.PayOpenBankingUK),
                    isSubscriptionActive:
                        subscription?.status &&
                        subscription.status !== domain.SubscriptionStatus.Canceled &&
                        subscription.status !== domain.SubscriptionStatus.Expired,
                    currency: subscription?.nextBillingCurrency ?? undefined,
                });
            } else if (company.integration?.integrationType === domain.IntegrationType.QBooks) {
                companies.push({
                    id: company.id,
                    name: company.name,
                    integrationType: TrialIntegrationType.Quickbooks,
                    plan: getPlanType(company?.licenseProductPlan),
                    isUpgradeSubcriptionVisible: company.author === profile.email,
                    isNotManager: !company.flags.isManager,
                });
            }
        });

        return companies;
    }, [awaitingActivationTrialCompanies, profile.email, subscriptions]);

    useMount(() => {
        const initCompany = awaitingActivationTrialCompanies.find((company) => company.id == data.company.id);

        amplitudeService.sendData('billing: land on all feature trial paywall', {
            origin: data.origin,
            org_id: data.company.id,
            current_plan: data.company.licenseProductPlan,
            gl: data.company.integration?.integrationType,
            type: !initCompany || initCompany.flags.isManager ? 'for admins' : 'for users',
        });
    });

    const handleUpgradeSubscription = useCallback(
        (companyId: string) => {
            const company = awaitingActivationTrialCompanies.find((el) => el.id === companyId);

            if (!company?.subscriptionId) {
                return;
            }

            const isAdvanced =
                company?.licenseProductPlan === domain.CompanyPlan.XeroAdvanced ||
                company?.licenseProductPlan === domain.CompanyPlan.QBooksAdvanced;

            routingService.navigateToExternalUrlInNewTab(
                getAccountUrl(AccountPath.upgradeSubscription, {
                    subscriptionId: company?.subscriptionId,
                    plan: isAdvanced
                        ? domain.OrganisationPlanPrivilege.Premium
                        : domain.OrganisationPlanPrivilege.Advanced,
                    origin: 'wa_aft_paywall',
                })
            );
        },
        [awaitingActivationTrialCompanies]
    );

    const { handleStartTrial, isLoadingActivateAllFeature } = hooks.useTryAllFeatures();
    const { handleNotifyAdmins, notifyAdminTimeoutsByCompanyId, isLoadingCreateTrialRequest } = hooks.useNotifyAdmins();

    const onNotifyAdmins = useCallback(
        (companyId: string) => {
            amplitudeService.sendData('billing: clicked on "notify admin" on AFT paywall', {
                user_id: profile.id,
                org_id: companyId,
            });
            handleNotifyAdmins(companyId);
        },
        [handleNotifyAdmins, profile.id]
    );

    return (
        <AllFeatureTrialPaywallPopup
            companies={companies}
            isOpen={isOpen}
            onClose={onClose}
            onUpgradeSubcription={handleUpgradeSubscription}
            onStartTrial={handleStartTrial}
            onNotifyAdmins={onNotifyAdmins}
            notifyAdminTimeoutsByCompanyId={notifyAdminTimeoutsByCompanyId}
            initialFeature={getInitialFeatureByOrigin(data.company, data.origin)}
            initialCompanyId={data.company?.id}
            loading={isLoadingActivateAllFeature || isLoadingCreateTrialRequest}
        />
    );
});

StartTrialPeriodPopup.displayName = 'StartTrialPeriodPopup';
