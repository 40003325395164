import { Reference } from '@approvalmax/types';
import findIndex from 'lodash/findIndex';
import { getCompanyById } from 'modules/common/selectors/companySelectors';
import { NetSuiteExpandedRequest } from 'modules/common/selectors/types';
import { useSelector } from 'modules/react-redux';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { useGetNetSuiteExchangeRates } from 'shared/data/v1';

export const useFetchItems = (
    requestCurrency: Reference | null,
    request: NetSuiteExpandedRequest,
    companyId: string
) => {
    const company = useSelector((state) => getCompanyById(state, companyId));

    const { data, refetch, isFetching } = useGetNetSuiteExchangeRates(
        { query: { companyId, forDate: moment().startOf('day').toISOString() } },
        { enabled: false }
    );

    const dataItems = useMemo(() => {
        if (!data?.rates || !data?.rates.length) return [];

        let rates = [...data.rates];

        const companyCurrencyIndex = findIndex(rates, { isoCode: company.defaultCurrency });

        if (companyCurrencyIndex !== -1 && companyCurrencyIndex !== 0) {
            [rates[0], rates[companyCurrencyIndex]] = [rates[companyCurrencyIndex], rates[0]];
        }

        const requestCurrencyIndex = findIndex(rates, { isoCode: request.currency });
        const requestCurrency = rates[requestCurrencyIndex];

        if (requestCurrency && requestCurrencyIndex !== 0) {
            rates = [requestCurrency, ...rates.filter(({ id }) => id !== requestCurrency.id)];
        }

        return rates;
    }, [company.defaultCurrency, data, request.currency]);

    const items = useMemo(() => {
        const defaultItems = [
            {
                isoCode: request.currency,
                id: requestCurrency?.id || '',
                currencyName: requestCurrency?.text || '',
                rateValue: 1,
            },
        ];

        return dataItems.length ? dataItems : defaultItems;
    }, [dataItems, request.currency, requestCurrency?.id, requestCurrency?.text]);

    const trigger = useCallback(() => !data && refetch(), [data, refetch]);

    return {
        items,
        isFetching,
        trigger,
        refetch,
    };
};
