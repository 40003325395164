import { ReactElement } from 'react';
import { atom } from 'recoil';

import { BreadcrumbsProps } from '../Breadcrumbs/Breadcrumbs.types';

export const dividerHeaderState = atom({
    key: 'dividerHeaderState',
    default: true,
});

export const backHeaderState = atom<(() => void | Promise<void>) | undefined>({
    key: 'backHeaderState',
    default: undefined,
});

export const breadcrumbsHeaderState = atom<BreadcrumbsProps['items']>({
    key: 'breadcrumbsHeaderState',
    default: [],
});

export const slotHeaderState = atom<ReactElement | null>({
    key: 'slotHeaderState',
    default: null,
});

export const companyHeaderState = atom({
    key: 'companyHeaderState',
    default: true,
});

export const displayActionsHeaderState = atom({
    key: 'displayActionsHeaderState',
    default: true,
});
