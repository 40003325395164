import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { integrationApiPaths } from '../paths';
import { CheckNetSuiteConnection } from './useCheckNetSuiteConnection.types';

export const useCheckNetSuiteConnection = (mutationOptions?: RequestMutationOptions<CheckNetSuiteConnection>) => {
    return useMutate(integrationApiPaths.checkNetSuiteConnection, {
        mutationOptions,
        apiVersion: 'v1',
    });
};
