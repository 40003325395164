import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { companiesApiPaths } from '../paths';
import { GetUserContext } from './useGetUserContext.types';

export const useGetUserContext = (
    params?: RequestParams<GetUserContext>,
    queryOptions?: RequestQueryOptions<GetUserContext<true>>
) => {
    return useGet(companiesApiPaths.getUserContext, {
        params,
        queryOptions,
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};

export const useGetUserContextRaw = (
    params?: RequestParams<GetUserContext>,
    queryOptions?: RequestQueryOptions<GetUserContext>
) => {
    return useGet(companiesApiPaths.getUserContext, {
        params,
        queryOptions,
        apiVersion: 'v1',
    });
};
