import { selectors } from 'modules/common';
import { TwoFaActionWizard } from 'modules/components';
import { FC, memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToggle } from 'react-use';
import {
    useRequestTFADisablingEmailCode,
    useVerifyTFADisablingAppCode,
    useVerifyTFADisablingBackupCode,
    useVerifyTFADisablingEmailCode,
} from 'shared/data';

import {
    changeTwoFaAlternativeEmailIsEnabled,
    changeTwoFaBackupCodesIsEnabled,
    changeTwoFaIsEnabled,
} from '../../../../actions';
import TwoFaDisableSuccessPopup from '../TwoFaDisableSuccessPopup/TwoFaDisableSuccessPopup';
import { messages } from './TwoFaDisablePopup.messages';
import { TwoFaDisablePopupProps } from './TwoFaDisablePopup.types';

const TwoFaDisablePopup: FC<TwoFaDisablePopupProps> = memo((props) => {
    const { isOpen, onClose } = props;

    const [stateId, setStateId] = useState('');
    const [isSuccessPopupOpen, toggleIsSuccessPopupOpen] = useToggle(false);
    const profile = useSelector(selectors.profile.getProfile);
    const dispatch = useDispatch();
    const { mutateAsync: requestEmailCode } = useRequestTFADisablingEmailCode();
    const { mutateAsync: verifyAppCode } = useVerifyTFADisablingAppCode();
    const { mutateAsync: verifyEmailCode } = useVerifyTFADisablingEmailCode();
    const { mutateAsync: verifyBackupCode } = useVerifyTFADisablingBackupCode();

    const onCloseSuccessPopup = useCallback(() => {
        toggleIsSuccessPopupOpen();
    }, [toggleIsSuccessPopupOpen]);

    const onSuccess = useCallback(() => {
        dispatch(changeTwoFaAlternativeEmailIsEnabled(false));
        dispatch(changeTwoFaBackupCodesIsEnabled(false));
        onClose();
        toggleIsSuccessPopupOpen();
        dispatch(changeTwoFaIsEnabled(false, profile.email));
    }, [dispatch, onClose, profile.email, toggleIsSuccessPopupOpen]);

    const onEnterAppCode = useCallback(
        async (appCode: string) => {
            await verifyAppCode({ body: { oneTimePassword: appCode } });
        },
        [verifyAppCode]
    );

    const onSendCodeViaEmail = useCallback(async () => {
        const response = await requestEmailCode({ body: {} });

        setStateId(response.stateId);
    }, [requestEmailCode]);

    const onEnterEmailCode = useCallback(
        async (emailCode: string) => {
            await verifyEmailCode({ body: { stateId, code: emailCode } });
        },
        [stateId, verifyEmailCode]
    );

    const onEnterBackupCode = useCallback(
        async (backupCode: string) => {
            await verifyBackupCode({ body: { backupCode } });
        },
        [verifyBackupCode]
    );

    return (
        <>
            <TwoFaActionWizard
                title={messages.title}
                isOpen={isOpen}
                onEnterAppCode={onEnterAppCode}
                onEnterBackupCode={onEnterBackupCode}
                onEnterEmailCode={onEnterEmailCode}
                onSendCodeViaEmail={onSendCodeViaEmail}
                onSuccess={onSuccess}
                isTFAAlternativeEmailEnabled={profile.isTFAAlternativeEmailEnabled}
                isTFABackupCodesEnabled={profile.isTFABackupCodesEnabled}
                isTrustThisDeviceEnabled={false}
                onClose={onClose}
            />

            <TwoFaDisableSuccessPopup isOpen={isSuccessPopupOpen} onClose={onCloseSuccessPopup} />
        </>
    );
});

export default TwoFaDisablePopup;
