import { Reference } from '@approvalmax/types';
import { objectHelpers } from '@approvalmax/utils';
import { backend, domain } from 'modules/data';

export const parseXeroAddress = (value: backend.IntegrationsAddress): XeroAddress => {
    const addressText = [
        value.AddressLine1,
        value.AddressLine2,
        value.AddressLine3,
        value.AddressLine4,
        value.City,
        value.Region,
        value.PostalCode,
        value.Country,
    ]
        .filter((x) => x && x.trim())
        .map((x) => x.trim())
        .join('\n');
    const type = value.AddressType === backend.AddressType.Postal ? XeroAddressType.Postal : XeroAddressType.Physical;

    return {
        id: `${type}${addressText}`,
        text: addressText,
        type,
        address: objectHelpers.pascalCaseToCamelCase(value),
        attentionTo: value.AttentionTo || '',
    };
};

export enum XeroAddressType {
    Postal = 'Postal',
    Physical = 'Physical',
}

export interface XeroAddress extends Reference {
    type: XeroAddressType;
    address: domain.Address;
    attentionTo: string;
}
