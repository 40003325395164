import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { ocrApiPaths } from '../paths';
import { UploadOcrDocument } from './useUploadOcrDocument.types';

export const useUploadOcrDocument = (mutationOptions?: RequestMutationOptions<UploadOcrDocument>) => {
    return useMutate(ocrApiPaths.uploadDocument, {
        mutationOptions,
        apiVersion: 'v2',
    });
};
