import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useCurrentUser } from 'modules/utils';
import { getIsAuthor, getIsManager, getIsParticipant } from 'pages/requestList/selectors/requestSelectors';

export const useOnHoldActionsFlags = (request: selectors.types.ExpandedRequest) => {
    const { statusV2, company } = request;
    const me = useCurrentUser();
    const isOnApproval = statusV2 === domain.RequestStatusV2.OnApproval;
    const isOnHold = statusV2 === domain.RequestStatusV2.OnHold;
    const isParticipant = getIsParticipant(me.databaseId || '', me.userEmail, request);
    const isManager = getIsManager(me.databaseId || '', me.userEmail, company);
    const isAuthor = getIsAuthor(me.databaseId || '', request);
    const canPutOnHold =
        (isParticipant || isManager || isAuthor) &&
        selectors.company.getIsOnHoldStatusAvailable(company) &&
        isOnApproval;
    const canTakeOffHold = (isParticipant || isManager || isAuthor) && isOnHold;

    return { canPutOnHold, canTakeOffHold };
};
