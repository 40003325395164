import { font } from 'modules/styles';
import styled from 'styled-components';

export const Root = styled.span`
    ${font(12, '#5e5c5c')};
`;

export const Value = styled.b`
    color: black;
`;
