import { selectors } from 'modules/common';
import { backend, schemas, stateTree } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { normalize } from 'normalizr';
import { useMemo } from 'react';
import { useGetNetSuiteBillPaymentBill } from 'shared/data/v2';

export const useNormalizedLinkedNetSuiteBillResponse = (
    requestBillId: string | null | undefined,
    { data }: ReturnType<typeof useGetNetSuiteBillPaymentBill>
) => {
    const entities = useMemo(
        () =>
            data?.data
                ? normalize<backend.RequestAnswer, stateTree.Entities>(data.data.Request, schemas.requestSchema)
                      .entities
                : null,
        [data]
    );

    const request = requestBillId && entities ? entities.requests[requestBillId] : null;

    const expandedRequest = useSelector((state) => (request ? selectors.request.expandRequest(state, request) : null));

    const requestDetails = useMemo(
        () =>
            data?.data
                ? {
                      possibleDuplicates: data.data.PossibleDuplicates?.map(schemas.request.mapPossibleDuplicateItem),
                      counterparty: data.data.Counterparty,
                  }
                : undefined,
        [data]
    );

    return {
        request: expandedRequest,
        requestDetails,
    };
};
