import { mods } from '@approvalmax/theme';
import { font } from '@approvalmax/ui';
import { domain } from 'modules/data';
import styled from 'styled-components';

import { SectionContainer } from '../SectionContainer/SectionContainer';
import { StatusLabelMod } from './GoodsReceivedNotes.types';

export const Wrap = styled(SectionContainer)`
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 10px;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 40px;
        right: 40px;
        bottom: 0;
        display: block;
        border: solid 1px #dfdfdf;
        pointer-events: none;
    }
`;

export const Header = styled.header`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
`;

export const Title = styled.div`
    ${font(14, '#000', 'semibold')}
    flex: none;
    line-height: 30px;
    text-transform: uppercase;
    margin-right: auto;
`;

export const StatusLabel = styled.div<StatusLabelMod>`
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    user-select: none;
    cursor: default;

    ${font(10, '#777d81', 'bold')};
    text-transform: uppercase;
    border: 2px solid #777d81;
    background-color: #fff;

    ${mods('status', domain.GoodsReceivedNotesStatus.PartiallyReceived)`
        color: #13b4db;
        border-color: #13b4db;
        background-color: #edfafd;
    `}

    ${mods('status', domain.GoodsReceivedNotesStatus.FullyReceived)`
        color: #4c8a5c;
        border-color: #4c8a5c;
        background-color: #fff;
    `}
`;

export const Content = styled.div`
    margin-top: 10px;
`;

export const ContentTitle = styled.div`
    ${font(12, '#000', 'standard')};
    margin-bottom: 10px;
    font-weight: 600;
    line-height: 1.33;
`;

export const ContentFieldsSection = styled.div`
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 2fr);
`;
