import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('requestList.xero.ContactRequestCard.RequestContactInformationSection', {
    header: 'CONTACT INFORMATION',
    phoneNumber: 'Phone',
    fax: 'Fax',
    mobile: 'Mobile',
    directDial: 'Direct dial',
    skype: 'Skype name/number',
});
