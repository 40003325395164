import { Link } from '@approvalmax/ui';
import styled from 'styled-components';

export const Root = styled.div`
    display: flex;
    align-items: center;
    margin-left: 32px;
    overflow: hidden;
`;

export const Content = styled.div`
    margin-left: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const StyledLink = styled(Link)`
    font-weight: 600;
    word-break: break-word;
    padding-right: 10px;
`;
