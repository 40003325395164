import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.selectors.requestActivitySelectors.NetSuite', {
    approvedExternally: 'The request has been approved directly in Oracle NetSuite',
    rejectedExternally: 'The request has been rejected in Oracle NetSuite.',
    postApprovalChangeDetected: 'Changes have been made in Oracle NetSuite after the approval.',
    updatedExternallyResetTemplate:
        'This request has been updated in Oracle NetSuite, therefore, the workflow has restarted.',
    emailToPartnerSent: 'Purchase Order has been sent to supplier.',
});
