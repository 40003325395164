import { selectors } from 'modules/common';
import { useMemo } from 'react';

export const useRequestWatchers = (
    request: selectors.types.ExpandedRequest,
    allUsers: selectors.types.ExpandedUser[]
) => {
    return useMemo(
        () =>
            request.watchers
                .map((requestWatcher) => requestWatcher.watcher)
                /** some users may be off-boarded, so they are still in watchers list,
                 * but there is no corresponding entities. We need to filter them
                 */
                .filter((watcher) => allUsers.find((user) => user.id === watcher.id)),
        [allUsers, request.watchers]
    );
};
