import { Avatar, Box, Flex, Grid, Text } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { useUserProfile } from 'modules/utils';
import moment from 'moment';
import { memo } from 'react';

import { ItemProps } from './ItemProps.types';

export const Item = memo<ItemProps>((props) => {
    const { author, message, date, isSystemEvent } = props.data;

    const profile = useUserProfile();

    const isMe = author.userId === profile.id && !isSystemEvent;

    const userDisplayName = selectors.user.getDisplayName(author.firstName, author.lastName, author.userEmail || '');

    return (
        <Flex justifyContent={isMe ? 'end' : 'start'}>
            <Flex spacing='8' direction={isMe ? 'row-reverse' : 'row'}>
                <Box spacing='18 0 0 0'>
                    <Avatar alt={userDisplayName} src={author.avatarHttps || ''} />
                </Box>

                <Grid gap={4}>
                    <Text
                        color='midnight100'
                        font='label'
                        fontSize='small'
                        fontWeight='medium'
                        textAlign={isMe ? 'right' : 'left'}
                    >
                        {userDisplayName}
                    </Text>

                    <Text color='midnight100' font='body' fontSize='small' textAlign={isMe ? 'right' : 'left'}>
                        {message}
                    </Text>

                    <Text color='midnight70' font='label' fontSize='small' textAlign={isMe ? 'right' : 'left'}>
                        {moment(date).format('lll')}
                    </Text>
                </Grid>
            </Flex>
        </Flex>
    );
});

Item.displayName = 'Item';
