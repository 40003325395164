import { SyntheticEvent, useCallback, useState } from 'react';

import { TagProps } from './Tag.types';

export const useClosable = (props: Pick<TagProps, 'onClose'>) => {
    const { onClose } = props;

    const [isOpen, setIsOpen] = useState(true);

    const handleClose = useCallback(
        (event: SyntheticEvent<HTMLButtonElement>) => {
            setIsOpen(false);

            onClose?.(event);
        },
        [onClose]
    );

    return {
        isOpen,
        handleClose,
    };
};
