import moment from 'moment';

/**
 * Generates an array of time options, each representing an hour of the day in a 24-hour format.
 *
 * @returns {Array} - An array of objects, where each object has 'name' and 'id' properties.
 *                   'name' represents the formatted time (e.g., "09:00")
 *                   'id' represents the hour as a string to avoid unexpected behaviour of Select component with value 0
 */
export const getTimeOptions = () => {
    const result = [];

    for (let hours = 0; hours < 24; hours++) {
        result.push({
            name: `${String(hours).padStart(2, '0')}:00`,
            id: String(hours),
        });
    }

    return result;
};

/**
 * Returns a new Date object representing the start of the day for the provided Date object.
 * The time components (hours, minutes, seconds, milliseconds) of the input date are set to zero.
 *
 * @param {Date} date - The input Date object.
 * @returns {Date} - A new Date object representing the start of the day.
 */
export const getStartOfDayDate = (date: Date) => {
    return moment.utc(date).startOf('day').toDate();
};
