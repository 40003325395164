import { RequestMutationOptions } from '@approvalmax/data';
import { useQueryClient } from '@tanstack/react-query';
import { useMutate } from 'shared/data';

import { companiesApiPaths } from '../paths';
import { UpdateParticipantPermissions } from './useUpdateParticipantPermissions.types';

export const useUpdateParticipantPermissions = (
    mutationOptions?: RequestMutationOptions<UpdateParticipantPermissions>
) => {
    const queryClient = useQueryClient();

    return useMutate(companiesApiPaths.updateParticipantPermissions, {
        mutationOptions: {
            ...mutationOptions,
            onSuccess: async (data, variables, context) => {
                await queryClient.invalidateQueries([
                    companiesApiPaths.select,
                    { companyId: variables.params?.path?.companyId },
                ]);
                mutationOptions?.onSuccess?.(data, variables, context);
            },
        },
        method: 'put',
        apiVersion: 'v1',
    });
};
