import { intl } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { FC, memo } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import FieldEntry from '../../../../../components/FieldEntry';
import FieldEntryWrapper from '../../../../../components/FieldEntryWrapper/FieldEntryWrapper';
import SectionHeaderText from '../SectionHeaderText/SectionHeaderText';

const i18nPrefix =
    'requestList/containers/xero/ContactRequestCard/components/SalesSettingsSection/SalesSettingsSection';

interface SalesSettingsSectionProps {
    contact: domain.XeroContact;
}

const Root = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(50% - 30px);
`;

const SalesSettingsSection: FC<SalesSettingsSectionProps> = (props) => {
    const { contact } = props;

    if (!contact.salesAccount && !contact.salesTrackingCategories.length) {
        return null;
    }

    return (
        <Root>
            <SectionHeaderText>
                <FormattedMessage id={`${i18nPrefix}.header`} defaultMessage='SALES SETTINGS' />
            </SectionHeaderText>

            <FieldEntryWrapper>
                <FieldEntry
                    title={intl.formatMessage({
                        id: `${i18nPrefix}.defaultAccount`,
                        defaultMessage: 'Default account',
                    })}
                    value={contact.salesAccount}
                />
            </FieldEntryWrapper>

            {contact.salesTrackingCategories.map((tc, index) => (
                <FieldEntryWrapper key={tc.category.id}>
                    <FieldEntry
                        title={
                            tc.category.text ||
                            intl.formatMessage(
                                {
                                    id: `${i18nPrefix}.trackingOption`,
                                    defaultMessage: 'Tracking option {index}',
                                },
                                { index }
                            )
                        }
                        value={tc.value}
                    />
                </FieldEntryWrapper>
            ))}
        </Root>
    );
};

export default memo(SalesSettingsSection);
