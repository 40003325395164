import { MutateDataParams, QueryOptions } from '@approvalmax/data';
import { useGetLazyLegacy, useGetLegacy } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { UseGetSearchRequestsParams, UseGetSearchRequestsResponse } from './useGetSearchRequests.types';

export const useGetLazySearchRequests = (queryOptions?: QueryOptions<UseGetSearchRequestsResponse>) =>
    useGetLazyLegacy<MutateDataParams<UseGetSearchRequestsParams>, UseGetSearchRequestsResponse>(
        requestsApiPaths.searchRequests,
        { queryOptions }
    );

export const useGetSearchRequests = (
    params: UseGetSearchRequestsParams,
    queryOptions?: QueryOptions<UseGetSearchRequestsResponse>
) =>
    useGetLegacy<UseGetSearchRequestsResponse>(requestsApiPaths.searchRequests, {
        queryParams: params,
        queryOptions,
    });
