import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages(
    'pages.RequestList.Toolbar.PayWithAirwallex.CompleteAirwallexPaymentPopup.DiscrepanciesStep',
    {
        beforeTable:
            'The request can’t be paid. Following beneficiary bank details were edited in Airwallex during the approval process:',
        afterTable:
            'In order to send payments with the actual bank details this request should be cancelled and a new one is submitted.',
        beneficiary: 'Beneficiary',
        discrepancy: 'Fields edited in Airwallex',
        ok: 'OK',
        isRemoved: 'The beneficiary has been removed from Airwallex.',
        accountName: 'Account name',
        bankCountryCode: 'Bank location',
        bankName: 'Bank name',
        iban: 'IBAN',
        swiftCode: 'SWIFT code',
        accountNumber: 'Account number',
        bankBranch: 'Bank branch',
        localClearingSystem: 'Payment method',
        accountingRoutingType1: 'Routing code type',
        accountingRoutingType2: 'Routing code type',
        accountingRoutingValue1: 'Sort code',
        accountingRoutingValue2: 'Routing code value',
    }
);
