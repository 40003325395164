import { CloseCircleFilledIcon } from '@approvalmax/ui';
import { Box, Button, Flex, Text } from '@approvalmax/ui/src/components';
import { arrayHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserAvatar, UserCard } from 'shared/components';
import { useGetUserWithProfileInfo } from 'shared/hooks/useGetUserWithProfileInfo';

import { addTempUser, setDefaultReviewerToActiveMatrix } from '../../../../actions';
import { getActiveMatrix, getActiveTemplate } from '../../../../selectors/pageSelectors';
import { MatrixType } from '../../../../types/matrix';
import AddUserButton from '../../../AddUserButton/AddUserButton';
import { messages } from './DefaultReviewerSection.messages';
import { CloseButton, Root } from './DefaultReviewerSection.styles';

export const DefaultReviewerSection = memo(() => {
    const dispatch = useDispatch();
    const matrix = useSelector(getActiveMatrix);
    const template = useSelector(getActiveTemplate);
    const company = useSelector((state: State) =>
        template ? selectors.company.getCompanyById(state, template.companyId) : null
    );
    const team = useSelector((state: State) => (company ? selectors.company.getCompanyTeam(state, company) : []));
    const integrationErrorPreventsWork = Boolean(!company?.flags.hasActiveIntegration && template?.integrationCode);
    const defaultReviewer = useSelector((state: State) =>
        company && matrix?.type === MatrixType.Reviewer && matrix.defaultReviewer
            ? selectors.company.getCompanyUserById(state, company, matrix.defaultReviewer)
            : null
    );
    const canUpdateActiveCompanySettings = useSelector(selectors.company.canUpdateActiveCompanySettings);
    const readonly = !canUpdateActiveCompanySettings || integrationErrorPreventsWork;
    const teamUser = team.find((user) => user.id === defaultReviewer?.id);
    const { getUserWithProfileInfo, loading } = useGetUserWithProfileInfo(teamUser);

    const setDefaultReviewer = useCallback(
        (user: domain.User) => {
            dispatch(setDefaultReviewerToActiveMatrix(user.id));
        },
        [dispatch]
    );

    const removeDefaultReviewer = useCallback(() => {
        dispatch(setDefaultReviewerToActiveMatrix(null));
    }, [dispatch]);

    const onAddTempUser = useCallback(
        (user: domain.User) => {
            dispatch(addTempUser(user));
        },
        [dispatch]
    );

    return (
        <Box spacing='24'>
            <Box spacing='16' color='midnight20' bordered radius='small' height={56}>
                <Flex alignItems='center' wrap={false}>
                    {!defaultReviewer ? (
                        <AddUserButton
                            users={team}
                            excludedUsers={arrayHelpers.emptyArray()}
                            disabled={readonly}
                            onAddNew={onAddTempUser}
                            onSelect={setDefaultReviewer}
                            title={messages.addDefaultReviewer}
                        />
                    ) : (
                        <Root spacing='8'>
                            <UserCard getUser={getUserWithProfileInfo} loading={loading}>
                                <Button noPadding uppercase={false} icon>
                                    <Flex alignItems='center' spacing='8'>
                                        <UserAvatar size={24} user={defaultReviewer} />

                                        <Text font='body' fontSize='small'>
                                            {defaultReviewer.displayName}
                                        </Text>
                                    </Flex>
                                </Button>
                            </UserCard>

                            <CloseButton
                                onClick={removeDefaultReviewer}
                                size='small'
                                noPadding
                                startIcon={
                                    <CloseCircleFilledIcon
                                        size={12}
                                        color='midnight100'
                                        onClick={removeDefaultReviewer}
                                    />
                                }
                            />
                        </Root>
                    )}

                    <Text font='label' fontSize='small' color='midnight80' ellipsis={1}>
                        {messages.addDefaultReviewerDescription}
                    </Text>
                </Flex>
            </Box>
        </Box>
    );
});

DefaultReviewerSection.displayName = 'DefaultReviewerSection';
