import { domain } from 'modules/data';

export const integrationCodesWithRequestVersions: (domain.IntegrationCode | null)[] = [
    domain.IntegrationCode.XeroAirwallexBatchPayment,
    domain.IntegrationCode.XeroBill,
    domain.IntegrationCode.XeroBillBatchPayment,
    domain.IntegrationCode.XeroContact,
    domain.IntegrationCode.XeroCreditNotesPayable,
    domain.IntegrationCode.XeroCreditNotesReceivable,
    domain.IntegrationCode.XeroInvoice,
    domain.IntegrationCode.XeroPo,
    domain.IntegrationCode.XeroQuote,
    domain.IntegrationCode.XeroManualJournal,
    domain.IntegrationCode.QBooksPo,
    domain.IntegrationCode.QBooksBill,
    domain.IntegrationCode.QBooksExpense,
];
