import { ReactNode } from 'react';

export interface TwoFaActionWizardProps {
    onEnterAppCode: (code: string, trustThisDevice?: boolean) => void;
    onSendCodeViaEmail?: VoidFunction;
    onEnterEmailCode?: (code: string, trustThisDevice?: boolean) => void;
    onEnterBackupCode?: (code: string, trustThisDevice?: boolean) => void;
    isTrustThisDeviceEnabled?: boolean;
    isTFAAlternativeEmailEnabled?: boolean;
    isTFABackupCodesEnabled?: boolean;
    isOpen: boolean;
    isLoading: boolean;
    onClose: () => void;
    title: string;
    description?: ReactNode;
    disableAutoClose?: boolean;
}

export enum TwoFaActionWizardMethods {
    authenticatorAppCode = 'authenticatorAppCode',
    emailCode = 'emailCode',
    backupCode = 'backupCode',
}

export type TwoFaActionWizardActiveScreen = TwoFaActionWizardMethods | 'methodSelect';

export type TwoFaActionWizardContext = {
    activeScreen: TwoFaActionWizardActiveScreen;
};
