import { Box, Button, Flex, openDrawerState, Text } from '@approvalmax/ui/src/components';
import { FC, memo, useCallback } from 'react';
import bemFactory from 'react-bem-factory';
import { Link } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { getPath, Path } from 'urlBuilder';

import { messages } from './DisabledWorkflowPanel.messages';
import { DisabledWorkflowPanelProps } from './DisabledWorkflowPanel.types';

const qa = bemFactory.qa('disabled-workflow-panel');

const DisabledWorkflowPanel: FC<DisabledWorkflowPanelProps> = memo((props) => {
    const { workflowId, company, startIcon } = props;

    const setIsDrawerOpen = useSetRecoilState(openDrawerState);
    const closeDrawer = useCallback(() => setIsDrawerOpen(false), [setIsDrawerOpen]);

    const to = company!.flags.hasActiveIntegration
        ? getPath(Path.workflow, company!.id, workflowId)
        : getPath(Path.companyWorkflows, company!.id);

    return (
        <Box data-qa={qa()} color='white100' bordered radius='xsmall' spacing='8'>
            <Flex alignItems='center' justifyContent='space-between' spacing='4'>
                {startIcon}

                <Flex grow={1}>
                    <Text font='label' fontSize='small' color='midnight70'>
                        {messages.title}
                    </Text>
                </Flex>

                <Button
                    data-qa={qa('setup-button')}
                    as={Link}
                    to={to}
                    onClick={closeDrawer}
                    color='green80'
                    size='xsmall'
                >
                    {messages.setupButton}
                </Button>
            </Flex>
        </Box>
    );
});

DisabledWorkflowPanel.displayName = 'DisabledWorkflowPanel';

export default DisabledWorkflowPanel;
