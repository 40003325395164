import { toast } from '@approvalmax/ui/src/components';
import { domain } from 'modules/data';

import { messages } from './UploadPlaceholder.messages';

export const handleDuplicateFileNames = (newFiles: File[], files: domain.RequestAttachment[]) => {
    return newFiles.filter((file) => {
        if (files.some((item) => item.name === file.name)) {
            toast.error(messages.duplicateFile({ fileName: file.name }));

            return false;
        }

        return true;
    });
};
