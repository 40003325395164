import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('requestList.xero.ContactRequestCard.RequestContactPersonSection', {
    headerText: 'CONTACT PERSON',
    accountNumber: 'Account number',
    primaryPerson: 'Primary person',
    primaryPersonEmail: 'Primary person email',
    anotherPerson: 'Another person {index}',
    anotherPersonEmail: 'Another person {index} email',
});
