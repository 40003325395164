import { FC, memo } from 'react';

import FieldEntry from '../../../../../components/FieldEntry';
import SectionSeparator from '../../../../../components/SectionSeparator/SectionSeparator';
import SectionHeaderText from '../SectionHeaderText/SectionHeaderText';
import { messages } from './RequestContactInformationSection.messages';
import { Container, StyledFieldWrapper } from './RequestContactInformationSection.styles';
import { RequestContactInformationSectionProps } from './RequestContactInformationSection.types';
import { displayPhoneNumber } from './RequestContactInformationSection.utils';

const RequestContactInformationSection: FC<RequestContactInformationSectionProps> = memo((props) => {
    const { contact } = props;

    const phoneNumber = displayPhoneNumber(contact.phoneNumber);
    const fax = displayPhoneNumber(contact.fax);
    const mobile = displayPhoneNumber(contact.mobile);
    const directDial = displayPhoneNumber(contact.directDial);

    if (!phoneNumber && !fax && !mobile && !directDial && !contact.skypeName) {
        return null;
    }

    return (
        <>
            <div>
                <SectionHeaderText>{messages.header}</SectionHeaderText>

                <Container>
                    {phoneNumber && (
                        <StyledFieldWrapper>
                            <FieldEntry title={messages.phoneNumber} value={displayPhoneNumber(contact.phoneNumber)} />
                        </StyledFieldWrapper>
                    )}

                    {fax && (
                        <StyledFieldWrapper>
                            <FieldEntry title={messages.fax} value={displayPhoneNumber(contact.fax)} />
                        </StyledFieldWrapper>
                    )}

                    {mobile && (
                        <StyledFieldWrapper>
                            <FieldEntry title={messages.mobile} value={displayPhoneNumber(contact.mobile)} />
                        </StyledFieldWrapper>
                    )}

                    {directDial && (
                        <StyledFieldWrapper>
                            <FieldEntry title={messages.directDial} value={displayPhoneNumber(contact.directDial)} />
                        </StyledFieldWrapper>
                    )}

                    {contact.skypeName && (
                        <StyledFieldWrapper>
                            <FieldEntry title={messages.skype} value={contact.skypeName} />
                        </StyledFieldWrapper>
                    )}
                </Container>
            </div>

            <SectionSeparator />
        </>
    );
});

export default RequestContactInformationSection;
