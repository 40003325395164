import { FC, Fragment, memo } from 'react';

import { ChevronRightIcon } from '../../icons';
import { Flex } from '../Flex/Flex';
import { Text } from '../Text/Text';
import { getUrlProps } from './Breadcrumbs.helpers';
import { Item, Link } from './Breadcrumbs.styles';
import { BreadcrumbsProps } from './Breadcrumbs.types';
import { DropdownSections } from './components';

/**
 * Breadcrumbs allow showing a list of previous pages and selecting sections
 */
const Breadcrumbs: FC<BreadcrumbsProps> = memo((props) => {
    const { items, as = 'a', ...restProps } = props;

    if (!items.length) return null;

    return (
        <nav {...restProps}>
            <Flex as='ul' spacing='4' wrap={false} alignItems='center'>
                {items.map(({ name, to, onClick, value, subItems }, index) => (
                    <Fragment key={`${to || value}-${index}`}>
                        <Item>
                            {subItems ? (
                                <DropdownSections items={subItems} value={value} as={as} />
                            ) : (
                                <Link {...getUrlProps(as, to)} onClick={onClick} {...restProps}>
                                    <Text font='label' fontSize='small' fontWeight='medium' as='span'>
                                        {name}
                                    </Text>
                                </Link>
                            )}
                        </Item>

                        {index !== items.length - 1 && (
                            <Item>
                                <ChevronRightIcon size={12} color='midnight60' />
                            </Item>
                        )}
                    </Fragment>
                ))}
            </Flex>
        </nav>
    );
});

export default Breadcrumbs;
