import { Reference } from '@approvalmax/types';
import { intl } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { LineItemsTable } from 'pages/requestList/components/lineItemsSection/LineItemsTable';
import OpenInSourceLink from 'pages/requestList/components/OpenInSourceLink/OpenInSourceLink';
import { SectionContainer } from 'pages/requestList/components/SectionContainer/SectionContainer';
import { memo, useMemo } from 'react';

import { getColumnDefinition } from './XeroManualJournalLinesSection.config';
import { messages } from './XeroManualJournalLinesSection.messages';
import {
    AmountLabel,
    AmountValue,
    FooterWrapper,
    InfoTable,
    Separator,
    TaxInfoTextWrapper,
} from './XeroManualJournalLinesSection.styles';
import { XeroManualJournalLinesSectionProps } from './XeroManualJournalLinesSection.types';
import { calculateAmounts } from './XeroManualJournalLinesSection.utils';

export const XeroManualJournalLinesSection = memo<XeroManualJournalLinesSectionProps>((props) => {
    const { request } = props;

    const details = request.details;

    const usedTrackingCategories = useMemo(() => {
        const trackingCategories = details.lineItems.reduce<Record<string, Reference>>((acc, line) => {
            line.tracking.forEach((item) => {
                const categoryId = item.category.id;

                if (!acc[categoryId]) {
                    acc[categoryId] = item.category;
                }
            });

            return acc;
        }, {});

        return Object.values(trackingCategories);
    }, [details.lineItems]);

    const { debitSubTotal, creditSubTotal, debitTotal, creditTotal, taxes } = useMemo(() => {
        return calculateAmounts(details.lineItems, details.lineAmountType);
    }, [details.lineItems, details.lineAmountType]);

    const taxInfoText = useMemo(() => {
        switch (details.lineAmountType) {
            case domain.LineAmountType.TaxInclusive:
                return messages.taxInclusiveText;

            case domain.LineAmountType.TaxExclusive:
                return messages.taxExclusiveText;

            default:
                return null;
        }
    }, [details.lineAmountType]);

    if (details.lineItems.length === 0) {
        return null;
    }

    return (
        <SectionContainer title={messages.title}>
            <LineItemsTable
                columnDefinitions={getColumnDefinition(
                    request.currency,
                    details.lineAmountType,
                    usedTrackingCategories
                )}
                lineItems={details.lineItems}
            />

            <FooterWrapper>
                <div>
                    <OpenInSourceLink
                        url={details.url}
                        editUrl={null}
                        statusV2={request.statusV2}
                        integrationType={request.integrationType}
                        integrationCode={request.integrationCode}
                    />
                </div>

                <div>
                    {taxInfoText && <TaxInfoTextWrapper>{taxInfoText}</TaxInfoTextWrapper>}

                    <InfoTable>
                        <thead>
                            <tr>
                                <td />

                                <AmountLabel>{`${messages.debitTitle} (${request.currency})`}</AmountLabel>

                                <AmountLabel>{`${messages.creditTitle} (${request.currency})`}</AmountLabel>
                            </tr>
                        </thead>

                        <tbody>
                            {Object.keys(taxes).length > 0 && (
                                <tr>
                                    <AmountLabel>{messages.subtotalTitle}</AmountLabel>

                                    <AmountValue>{intl.formatNumber(debitSubTotal, 2)}</AmountValue>

                                    <AmountValue>{intl.formatNumber(creditSubTotal, 2)}</AmountValue>
                                </tr>
                            )}

                            {Object.keys(taxes).map((tax) => (
                                <tr key={tax}>
                                    <AmountLabel>{taxes[tax].taxName}</AmountLabel>

                                    <AmountValue>{intl.formatNumber(taxes[tax].debit, 2)}</AmountValue>

                                    <AmountValue>{intl.formatNumber(taxes[tax].credit, 2)}</AmountValue>
                                </tr>
                            ))}

                            <tr>
                                <td colSpan={3}>
                                    <Separator />
                                </td>
                            </tr>

                            <tr>
                                <AmountLabel>{messages.totalTitle}</AmountLabel>

                                <AmountValue>{intl.formatNumber(debitTotal, 2)}</AmountValue>

                                <AmountValue>{intl.formatNumber(creditTotal, 2)}</AmountValue>
                            </tr>
                        </tbody>
                    </InfoTable>
                </div>
            </FooterWrapper>
        </SectionContainer>
    );
});
