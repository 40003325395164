import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('modules.components.LicensingNotification', {
    contactSalesButtonText: 'Contact sales',
    contactOwnerButtonText: 'Contact account owner',
    trialMyAccountManageText: 'Manage',
    trialMyAccountBuyNowText: 'Buy now',
    graceMessage: `
        There seems to be an issue with the payment method.
        Your subscription is now in grace period, which will end in {days, plural, one {1 day} other {# days}}.
    `,
    graceMyAccountText: 'Update payment details',
    expiredCommercialOneMessage: 'The subscription for “{companyName}” expired on {date}.',
    expiredCommercialManyMessage:
        'The subscription for {count, plural, one {1 Organisation} other {# Organisations}} expired on {date}.',
    expiredTrialOneMessage: 'The trial for “{companyName}” expired on {date}.',
    expiredTrialManyMessage:
        'The trial for {count, plural, one {1 Organisation} other {# Organisations}} expired on {date}.',
    trialExpiresSingleCompanyMessage: `
        The trial for “{companyName}” expires\u00a0
        {days, plural, =0 {today} one {in\u00a0#\u00a0day} other {in\u00a0#\u00a0days}}.
    `,
    trialExpiresMultipleCompaniesMessage:
        'The trial for {count, plural, one {1 Organisation} other {# Organisations}} expires in less than 14 days.',
    trialContactSalesText: 'Contact sales',

    expiredTrialUseCaseDefault: 'Get a subscription and strengthen your internal financial controls.',
    expiredTrialUseCaseStreamlineAuditProcesses: 'Get a subscription to streamline audit processes.',
    expiredTrialUseCaseEstablishInternalFinancialControls:
        'Get a subscription to establish internal financial controls.',
    expiredTrialUseCaseAutomateApprovalWorkflows: 'Get a subscription to automate approval workflows.',
    expiredTrialUseCaseSpeedupTheApprovalProcess: 'Get a subscription to speed up the approval process.',
    expiredTrialUseCaseStreamlineAuditProcessesAndEstablishInternalFinancialControls:
        'Get a subscription to streamline financial controls and audit processes.',
    expiredTrialUseCaseStreamlineAuditProcessesAndAutomateApprovalWorkflows:
        'Get a subscription to streamline financial controls and approvals.',
    expiredTrialUseCaseStreamlineAuditProcessesAndSpeedupTheApprovalProcess:
        'Get a subscription to speed up the approval and audit processes.',
    expiredTrialUseCaseEstablishInternalFinancialControlsAndAutomateApprovalWorkflows:
        'Get a subscription to automate approvals and establish financial controls.',
    expiredTrialUseCaseEstablishInternalFinancialControlsAndSpeedupTheApprovalProcess:
        'Get a subscription to speed up approvals and establish financial controls.',
    expiredTrialUseCaseAutomateApprovalWorkflowsAndSpeedupTheApprovalProcess:
        'Get a subscription to automate and speed up the approval process.',
    expiredTrialUseCaseStreamlineAuditProcessesAndEstablishInternalFinancialControlsAndAutomateApprovalWorkflows:
        'Get a subscription to establish financial controls and streamline approvals and audits.',
    expiredTrialUseCaseStreamlineAuditProcessesAndEstablishInternalFinancialControlsAndSpeedupTheApprovalProcess:
        'Get a subscription to establish financial controls, streamline audits and automate approvals.',
    expiredTrialUseCaseStreamlineAuditProcessesAndAutomateApprovalWorkflowsAndSpeedupTheApprovalProcess:
        'Get a subscription to streamline your audits and speed up approvals with automation.',
    expiredTrialUseCaseEstablishInternalFinancialControlsAndAutomateApprovalWorkflowsAndSpeedupTheApprovalProcess:
        'Get a subscription to establish financial controls and speed up approvals with automation.',
});
