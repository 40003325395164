import { LockIcon } from '@approvalmax/ui';
import { Box, Flex, Text } from '@approvalmax/ui/src/components';
import { memo } from 'react';

import { messages } from './RequestOnHoldInfo.messages';

export const RequestOnHoldInfo = memo(() => {
    return (
        <Box spacing='0 60 20'>
            <Box spacing='16' bordered>
                <Flex alignItems='center' wrap={false} spacing='16'>
                    <LockIcon />

                    <Text font='body' fontSize='small' color='midnight80'>
                        {messages.description}
                    </Text>
                </Flex>
            </Box>
        </Box>
    );
});

RequestOnHoldInfo.displayName = 'RequestOnHoldInfo';
