import { Flex, List, Text } from '@approvalmax/ui/src/components';
import { memo } from 'react';

import { DetailsBlockProps } from './DetailsBlock.types';

export const DetailsBlock = memo<DetailsBlockProps>(({ title, list }) => {
    return (
        <Flex direction='column' spacing='8'>
            <Text font='label' fontSize='large' fontWeight='medium'>
                {title}
            </Text>

            <List as='ol'>
                {list({
                    item: (content: [string]) => <List.Item>{content}</List.Item>,
                })}
            </List>
        </Flex>
    );
});

DetailsBlock.displayName = 'DetailsBlock';
