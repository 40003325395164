import { LoadingSpinner } from '@approvalmax/ui';
import { Box } from '@approvalmax/ui/src/components';
import FieldsSection from 'pages/requestList/components/FieldsSection';
import { SectionContainer } from 'pages/requestList/components/SectionContainer/SectionContainer';
import { memo } from 'react';
import BemFactory from 'react-bem-factory';
import { useGetWorkflowVersionDocumentFields } from 'shared/data/v1';

import { useInvoiceFieldSettings } from '../../NetSuiteRequestCard.hooks';
import { FieldsSectionWrap, StyledField } from '../../NetSuiteRequestCard.styles';
import { isFieldVisible } from '../../NetSuiteRequestCard.utils';
import { messages } from './NetSuiteInvoiceFooterFields.messages';
import { NetSuiteInvoiceFooterFieldsProps } from './NetSuiteInvoiceFooterFields.types';

const qa = BemFactory.qa('netsuite-invoice-fields-section');

export const NetSuiteInvoiceFooterFields = memo<NetSuiteInvoiceFooterFieldsProps>((props) => {
    const { request } = props;

    const { data: workflowVersionDocumentFields, isFetching: isFetchingGetWorkflowVersionDocumentFields } =
        useGetWorkflowVersionDocumentFields({
            query: {
                companyId: request.companyId,
                workflowVersionIds: [request.workflowVersionId],
            },
        });

    const fieldSettings = useInvoiceFieldSettings(workflowVersionDocumentFields?.data || []);

    const { details } = request;

    const hasSalesDetailsSection =
        details.salesRep ||
        details.opportunity ||
        details.salesEffectiveDate ||
        details.excludeCommissions ||
        details.partner ||
        details.leadSource;

    const hasShippingDetailsSection = details.shipDate || details.shipMethod || details.shippingAddress;

    return (
        <>
            {hasSalesDetailsSection && (
                <SectionContainer title={messages.salesDetailsSection} noPadding withHeaderPadding>
                    {isFetchingGetWorkflowVersionDocumentFields ? (
                        <Box spacing='20'>
                            <LoadingSpinner />
                        </Box>
                    ) : (
                        <FieldsSectionWrap>
                            {isFieldVisible(fieldSettings.salesRep) && (
                                <StyledField
                                    qa={qa('sales-rep')}
                                    title={messages.salesRep}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.salesRep}
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.opportunity) && (
                                <StyledField
                                    qa={qa('opportunity')}
                                    title={messages.opportunity}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.opportunity}
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.salesEffectiveDate) && (
                                <StyledField
                                    qa={qa('sales-effective-date')}
                                    title={messages.salesEffectiveDate}
                                    valueType={FieldsSection.Field.ValueType.Date}
                                    value={details.salesEffectiveDate}
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.excludeCommissions) && (
                                <StyledField
                                    qa={qa('exclude-commissions')}
                                    title={messages.excludeCommissions}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.excludeCommissions ? messages.positiveBooleanValue : null}
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.partner) && (
                                <StyledField
                                    qa={qa('partner')}
                                    title={messages.partner}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.partner}
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.leadSource) && (
                                <StyledField
                                    qa={qa('lead-source')}
                                    title={messages.leadSource}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.leadSource}
                                    hideIfEmpty
                                />
                            )}
                        </FieldsSectionWrap>
                    )}
                </SectionContainer>
            )}

            {hasShippingDetailsSection && (
                <SectionContainer title={messages.shippingDetailsSection} noPadding withHeaderPadding>
                    {isFetchingGetWorkflowVersionDocumentFields ? (
                        <Box spacing='20'>
                            <LoadingSpinner />
                        </Box>
                    ) : (
                        <FieldsSectionWrap>
                            {isFieldVisible(fieldSettings.shipDate) && (
                                <StyledField
                                    qa={qa('shipping-date')}
                                    title={messages.shipDate}
                                    valueType={FieldsSection.Field.ValueType.Date}
                                    value={details.shipDate}
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.shipMethod) && (
                                <StyledField
                                    qa={qa('shipping-method')}
                                    title={messages.shipMethod}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.shipMethod}
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.shippingAddress) && (
                                <StyledField
                                    qa={qa('shipping-address')}
                                    title={messages.shippingAddress}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.shippingAddress?.fullAddress?.replace(/<br>/g, ' ').trim() ?? null}
                                    hideIfEmpty
                                />
                            )}
                        </FieldsSectionWrap>
                    )}
                </SectionContainer>
            )}
        </>
    );
});

NetSuiteInvoiceFooterFields.displayName = 'NetSuiteInvoiceFooterFields';
