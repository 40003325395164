import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { GetCurrencyExchangeRate } from './useGetCurrencyExchangeRate.types';

export const useGetCurrencyExchangeRate = (
    params?: RequestParams<GetCurrencyExchangeRate>,
    queryOptions?: RequestQueryOptions<GetCurrencyExchangeRate>
) => {
    return useGet(requestsApiPaths.currencyExchangeRate, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetCurrencyExchangeRate['params']['query']>(
                {
                    currency: params?.query?.currency,
                    date: params?.query?.date,
                },
                queryOptions
            ),
        },
        apiVersion: 'v2',
    });
};
