import { font } from '@approvalmax/ui';
import styled from 'styled-components';

export const FooterWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 10px;
`;

export const TaxInfoTextWrapper = styled.div`
    margin-bottom: 15px;
    text-align: right;
    ${font(11, '#777d81', 'semibold')}
`;

export const AmountInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

export const AmountLine = styled.div`
    display: flex;
    align-items: center;
    text-align: right;
`;

export const InfoTable = styled.table`
    text-align: right;

    & td {
        padding-bottom: 5px;
        padding-left: 10px;
    }
`;

export const AmountLabel = styled.td`
    ${font(11, '#777d81', 'semibold')}
`;

export const AmountValue = styled.td`
    ${font(13, '#000', 'semibold')}
`;

export const Separator = styled.div`
    padding-top: 5px;
    border-bottom: 1px solid #000;
`;
