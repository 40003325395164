import { hooks } from '@approvalmax/utils';
import range from 'lodash/range';
import moment, { Moment } from 'moment';
import { forwardRef, memo, useCallback, useEffect, useState } from 'react';

import { CalendarIcon, ClockIcon } from '../../../../icons';
import Dropdown from '../../../drop/dropdown/Dropdown';
import { DeleteIcon } from '../../../icons';
import { TextButton } from '../../../ui';
import DateTimeEditor from '../dateTime/DateTimeEditor';
import { messages } from './TimeEditor.messages';
import {
    ClearButton,
    EditorIcon,
    EditorWrapper,
    Footer,
    Input,
    InputWrapper,
    Panel,
    ValueColumn,
    ValueItem,
    ValuesWrapper,
} from './TimeEditor.styles';
import { TimeEditorProps } from './TimeEditor.types';

const hours = range(0, 24);
const minutes = range(0, 60);
const seconds = range(0, 60);

const TimeEditor = memo(
    forwardRef<HTMLInputElement, TimeEditorProps>((props, ref) => {
        const { disabled, value, variant = 'time', theme = 'form', invalid = false, onChange } = props;

        const [isOpen, setIsOpen] = useState(false);

        const onOpen = useCallback(() => setIsOpen(true), []);

        const onClose = useCallback(() => {
            setIsOpen(false);

            setLocalValue(value ? moment.utc(value) : null);
        }, [value]);

        const [localValue, setLocalValue] = useState<Moment | null>(null);

        const onClear = useCallback(() => {
            onChange(null);
        }, [onChange]);

        const onHourChange = useCallback((hour: number) => {
            setLocalValue((current) => {
                if (current) {
                    return moment.utc(current).hour(hour);
                } else {
                    return moment.utc().hour(hour).minute(0).second(0);
                }
            });
        }, []);

        const onMinuteChange = useCallback((minute: number) => {
            setLocalValue((current) => {
                if (current) {
                    return moment.utc(current).minute(minute);
                } else {
                    return moment.utc().hour(0).minute(minute).second(0);
                }
            });
        }, []);

        const onSecondChange = useCallback((second: number) => {
            setLocalValue((current) => {
                if (current) {
                    return moment.utc(current).second(second);
                } else {
                    return moment.utc().hour(0).minute(0).second(second);
                }
            });
        }, []);

        const onSubmit = useCallback(() => {
            onChange(localValue?.toISOString() ?? null);

            setIsOpen(false);
        }, [localValue, onChange]);

        const onDateChange = useCallback((value: string | null) => {
            setLocalValue((current) => {
                const currentHour = current?.hour() ?? 0;
                const currentMinute = current?.minute() ?? 0;
                const currentSecond = current?.second() ?? 0;

                const newValue = moment.utc(value).hour(currentHour).minute(currentMinute).second(currentSecond);

                return newValue;
            });
        }, []);

        const onTimeInputFocus = useCallback(() => {
            setIsOpen(true);
        }, []);

        useEffect(() => {
            setLocalValue(value ? moment.utc(value) : null);
        }, [value]);

        const [onMount] = hooks.useForwardedRef(ref);

        const selectedHour = localValue?.hour() ?? 0;
        const selectedMinute = localValue?.minute() ?? 0;
        const selectedSecond = localValue?.second() ?? 0;

        return (
            <Dropdown
                onRequestClose={onClose}
                isOpen={isOpen}
                button={
                    <InputWrapper theme={theme} disabled={disabled} invalid={invalid}>
                        {variant === 'datetime' && (
                            <EditorWrapper>
                                <DateTimeEditor
                                    theme='transparent'
                                    value={localValue?.toISOString() ?? null}
                                    hideClearButton
                                    placeholder={messages.selectDatePlaceholder}
                                    onChange={onDateChange}
                                    disabled={disabled}
                                />

                                <EditorIcon>
                                    <CalendarIcon />
                                </EditorIcon>
                            </EditorWrapper>
                        )}

                        <EditorWrapper>
                            <Input
                                ref={onMount}
                                value={localValue?.format('HH:mm:ss') ?? ''}
                                onClick={onOpen}
                                onFocus={onTimeInputFocus}
                                disabled={disabled}
                                type='text'
                                placeholder={messages.selectTimePlaceholder}
                            />

                            <EditorIcon>
                                <ClockIcon />
                            </EditorIcon>
                        </EditorWrapper>

                        {value && (
                            <ClearButton
                                title={messages.clearField}
                                focusable={false}
                                disabled={disabled}
                                execute={onClear}
                            >
                                <DeleteIcon width={8} height={8} />
                            </ClearButton>
                        )}
                    </InputWrapper>
                }
                panelMinWidth='none'
                panelFlow={variant === 'datetime' ? 'to-left' : 'to-right'}
            >
                <Panel>
                    <ValuesWrapper>
                        <ValueColumn>
                            {hours.map((hour) => (
                                <ValueItem key={hour} active={selectedHour === hour} onClick={() => onHourChange(hour)}>
                                    {hour.toString().padStart(2, '0')}
                                </ValueItem>
                            ))}
                        </ValueColumn>

                        <ValueColumn>
                            {minutes.map((minute) => (
                                <ValueItem
                                    key={minute}
                                    active={selectedMinute === minute}
                                    onClick={() => onMinuteChange(minute)}
                                >
                                    {minute.toString().padStart(2, '0')}
                                </ValueItem>
                            ))}
                        </ValueColumn>

                        <ValueColumn>
                            {seconds.map((second) => (
                                <ValueItem
                                    key={second}
                                    active={selectedSecond === second}
                                    onClick={() => onSecondChange(second)}
                                >
                                    {second.toString().padStart(2, '0')}
                                </ValueItem>
                            ))}
                        </ValueColumn>
                    </ValuesWrapper>

                    <Footer>
                        <TextButton execute={onSubmit}>{messages.ok}</TextButton>
                    </Footer>
                </Panel>
            </Dropdown>
        );
    })
);

export default TimeEditor;
