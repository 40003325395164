import { forwardRef, memo, useCallback } from 'react';
import { useController } from 'react-hook-form';

import { DatePicker } from '../../DatePicker';
import type { DatePickerProps } from '../../DatePicker.types';
import type { ControllerProps } from './Controller.types';

/**
 * The `DatePicker.Controller` allows you to use the `DatePicker` with the `react-hook-form` library.
 * They take the same properties as the `DatePicker` component,
 * plus the `control` property to bind it with the library and `rules` for validation.
 *
 * You can use control without the `Controller` subcomponent if it is in a `Form` or `Form.Part` component.
 */
const Controller = memo(
    forwardRef<HTMLInputElement, ControllerProps>((props, ref) => {
        const { control, name, rules, onChange: onChangeProp, ...restProps } = props;

        const {
            field: { onChange, ...field },
            fieldState,
        } = useController({
            control,
            name: name || 'datePicker',
            rules: { required: restProps.required },
        });

        const handleChange = useCallback<NonNullable<DatePickerProps['onChange']>>(
            (value) => {
                // need to pass `null` instead of `undefined` in order to clear the value
                onChange(value ?? null);
                onChangeProp?.(value);
            },
            [onChange, onChangeProp]
        );

        return (
            <DatePicker
                {...field}
                onChange={handleChange}
                {...fieldState}
                {...restProps}
                ref={ref}
                hint={fieldState.error?.message || restProps.hint}
            />
        );
    })
);

export default Controller;
