import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { GetBillRequestsForXeroBatchPayment } from './useGetBillRequestsForXeroBatchPayment.types';

export const useGetBillRequestsForXeroBatchPayment = (
    params?: RequestParams<GetBillRequestsForXeroBatchPayment>,
    queryOptions?: RequestQueryOptions<GetBillRequestsForXeroBatchPayment>
) => {
    return useGet(requestsApiPaths.billRequestsForXeroBatchPayment, {
        params,
        queryOptions,
        apiVersion: 'v1',
    });
};
