import { useCallback, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { backHeaderState, breadcrumbsHeaderState, dividerHeaderState, slotHeaderState } from './Header.states';
import { HeaderProps } from './Header.types';

export const useResetHeaderStates = (props: Pick<HeaderProps, 'resetState'>) => {
    const { resetState } = props;

    const setHasDividerHeader = useSetRecoilState(dividerHeaderState);
    const setBackHeaderState = useSetRecoilState(backHeaderState);
    const setBreadcrumbsHeader = useSetRecoilState(breadcrumbsHeaderState);
    const setSlotHeader = useSetRecoilState(slotHeaderState);

    const reset = useCallback(() => {
        setHasDividerHeader(true);
        setBackHeaderState(undefined);
        setBreadcrumbsHeader([]);
        setSlotHeader(null);
    }, [setHasDividerHeader, setBackHeaderState, setBreadcrumbsHeader, setSlotHeader]);

    useEffect(() => {
        resetState?.(reset);
    }, [reset, resetState]);
};
