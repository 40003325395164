import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.ReviewStep', {
    active: 'ACTIVE',
    reviewed: 'REVIEWED',
    forceReviewedBy: 'Force reviewed by {name}',
    activeStep: 'ACTIVE STEP',
    reviewStep: 'Review step',
    skipped: 'SKIPPED',
});
