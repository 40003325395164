import { constants, statics } from 'modules/common';
import { domain } from 'modules/data';

import AmountConditionHeaderColumn from '../components/MatrixPopupContent/components/AmountConditionHeader/AmountConditionHeader';
import { ConditionHeaderColumn } from '../components/MatrixPopupContent/components/ConditionHeaderColumn/ConditionHeaderColumn';
import GeneralConditionCell from '../components/MatrixPopupContent/components/GeneralConditionCell/GeneralConditionCell';
import { GetMatrixDefinitionOptions, MatrixType } from '../types/matrix';
import {
    getFilteredDefinitions,
    InternalMatrixDefinitionMap,
    renderAmountCell,
    renderDefaultHeader,
    renderExactAsyncCell,
} from './matrixDefinitions.shared';

const { standaloneConstants } = constants;

export function getStandaloneMatrixDefinitions(options: GetMatrixDefinitionOptions) {
    const matrixDefinitions: InternalMatrixDefinitionMap = {
        null: {
            [MatrixType.Approval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: ({ company, readonly }) => {
                            const currencyText = statics.currency.getCurrencyShortText(company.defaultCurrency);

                            return <AmountConditionHeaderColumn currency={currencyText} readonly={readonly} />;
                        },
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: standaloneConstants.TOTAL_MIN_AMOUNT,
                                maxValue: standaloneConstants.TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.General,
                        renderHeader: ({ field, readonly, matrixType }) => (
                            <ConditionHeaderColumn
                                fieldId={field.id}
                                fieldName={field.name}
                                readonly={readonly}
                                canRename
                                canRemove
                                matrixType={matrixType}
                            />
                        ),
                        renderCell: ({
                            rule,
                            field,
                            readonly,
                            integrationCode,
                            lineId,
                            condition,
                            onConditionChange,
                            onCreateFieldOption,
                            requiredFieldIds,
                            matrixType,
                        }) => {
                            return (
                                <GeneralConditionCell
                                    rule={rule}
                                    field={field}
                                    readonly={readonly}
                                    integrationCode={integrationCode}
                                    lineId={lineId}
                                    onConditionChange={onConditionChange}
                                    condition={condition as domain.ExactValuesCondition | domain.AlwaysTrueCondition}
                                    onCreateFieldOption={onCreateFieldOption}
                                    requiredFieldIds={requiredFieldIds}
                                    matrixType={matrixType}
                                />
                            );
                        },
                    },
                ],
            },
            [MatrixType.Editor]: { columns: [] },
            [MatrixType.Requester]: { columns: [] },
            [MatrixType.AutoApproval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: ({ company, readonly }) => {
                            const currencyText = statics.currency.getCurrencyShortText(company.defaultCurrency);

                            return <AmountConditionHeaderColumn currency={currencyText} readonly={readonly} />;
                        },
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: standaloneConstants.TOTAL_MIN_AMOUNT,
                                maxValue: standaloneConstants.TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.General,
                        renderHeader: ({ field, readonly, matrixType }) => (
                            <ConditionHeaderColumn
                                fieldId={field.id}
                                fieldName={field.name}
                                readonly={readonly}
                                canRename
                                canRemove
                                matrixType={matrixType}
                            />
                        ),
                        renderCell: ({
                            rule,
                            field,
                            readonly,
                            integrationCode,
                            lineId,
                            condition,
                            onConditionChange,
                            onCreateFieldOption,
                            requiredFieldIds,
                            matrixType,
                        }) => {
                            return (
                                <GeneralConditionCell
                                    rule={rule}
                                    field={field}
                                    readonly={readonly}
                                    integrationCode={integrationCode}
                                    lineId={lineId}
                                    onConditionChange={onConditionChange}
                                    condition={condition as domain.ExactValuesCondition | domain.AlwaysTrueCondition}
                                    onCreateFieldOption={onCreateFieldOption}
                                    requiredFieldIds={requiredFieldIds}
                                    matrixType={matrixType}
                                />
                            );
                        },
                    },
                ],
            },
            [MatrixType.Reviewer]: { columns: [] },
        },
    };

    return getFilteredDefinitions(matrixDefinitions, options);
}
