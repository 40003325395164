import { RichEditorView } from '@approvalmax/ui/src/components';
import { getLockDateWarningText } from 'modules/common/selectors/requestSelectors.Xero';
import { FC, memo } from 'react';
import bemFactory from 'react-bem-factory';

import FieldsSection from '../../../components/FieldsSection';
import { messages } from './XeroQuoteFields.messages';
import { FieldsSectionStyled } from './XeroQuoteFields.styles';
import { XeroQuoteFieldsProps } from './XeroQuoteFields.types';

const qa = bemFactory.qa('reql-xero-fields-section');

const XeroQuoteFields: FC<XeroQuoteFieldsProps> = memo((props) => {
    const { request } = props;

    const details = request.details;
    const lockDateWarningText = getLockDateWarningText(request);

    const shouldAppendEmptyField = !details.expiryDate || !details.reference || !details.brandingTheme;

    return (
        <>
            <FieldsSectionStyled>
                <FieldsSection.Field
                    qa={qa('date')}
                    title={messages.dateFieldText}
                    valueType={FieldsSection.Field.ValueType.Date}
                    value={details.date}
                    warningText={lockDateWarningText}
                />

                {details.expiryDate && (
                    <FieldsSection.Field
                        qa={qa('expiryDate')}
                        title={messages.expiryDateFieldText}
                        valueType={FieldsSection.Field.ValueType.Date}
                        value={details.expiryDate}
                    />
                )}

                <FieldsSection.Field
                    qa={qa('number')}
                    title={messages.quoteNumberFieldText}
                    value={details.number || messages.emptyQuoteNumber}
                />

                {details.reference && (
                    <FieldsSection.Field
                        qa={qa('reference')}
                        title={messages.referenceFieldText}
                        value={details.reference}
                    />
                )}

                {details.brandingTheme && (
                    <FieldsSection.Field
                        qa={qa('brandingTheme')}
                        title={messages.brandingThemeFieldText}
                        value={details.brandingTheme}
                    />
                )}

                {shouldAppendEmptyField && <FieldsSection.Field.Empty />}
            </FieldsSectionStyled>

            {(details.title || details.summary || details.terms) && (
                <FieldsSectionStyled>
                    {details.title && (
                        <FieldsSection.Field
                            qa={qa('title')}
                            title={messages.titleFieldText}
                            forceDisplayValue={<RichEditorView html={details.title} />}
                        />
                    )}

                    {details.summary && (
                        <FieldsSection.Field
                            qa={qa('summary')}
                            title={messages.summaryFieldText}
                            forceDisplayValue={<RichEditorView html={details.summary} />}
                        />
                    )}

                    {details.terms && (
                        <FieldsSection.Field
                            qa={qa('terms')}
                            title={messages.termsFieldText}
                            forceDisplayValue={<RichEditorView html={details.terms} />}
                        />
                    )}
                </FieldsSectionStyled>
            )}
        </>
    );
});

export default XeroQuoteFields;
