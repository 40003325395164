import { Button, Popup } from '@approvalmax/ui';
import { intl } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useDispatch, useSelector } from 'modules/react-redux';
import { EditIcon } from 'modules/sprites';
import { cancelActivePopup, showMatchingPopup } from 'pages/requestList/actions';
import { useXeroBillMatchingData } from 'pages/requestList/hooks';
import { MATCHING_POPUP } from 'pages/requestList/reducers/page/activePopup/matchingPopupReducer';
import { MATCHING_PO_REQUESTERS_POPUP } from 'pages/requestList/reducers/page/activePopup/matchingPORequestersPopupReducer';
import { getActivePopupId, isActiveRequestLocked } from 'pages/requestList/selectors/pageSelectors';
import { FC, memo, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { ContentSplitViewMode, contentSplitViewState } from 'shared/states';

import CollapsibleSection from '../CollapsibleSection/CollapsibleSection';
import ColorBarSection from '../ColorBarSection/ColorBarSection';
import Legend from '../Legend/Legend';
import { messages } from './BillView.messages';
import { Header, HeaderContainer, NoMatching, StyledSectionContainer, Title } from './BillView.styles';
import { BillViewProps } from './BillView.types';
import AddMatchedPOsRequestersPopup from './components/AddMatchedPOsRequestersAsApproversForBillPopup/AddMatchedPOsRequestersPopup';
import BillMatchingTable from './components/BillMatchingTable/BillMatchingTable';
import ChangeMatchingPopup from './components/ChangeMatchingPopup/ChangeMatchingPopup';

const BillView: FC<BillViewProps> = (props) => {
    const { request, preloadedMatchingData, readonly } = props;

    const isLocked = useSelector(isActiveRequestLocked);
    const commands = useSelector((state) => selectors.request.getXeroMatchingCommands(state, request));
    const activePopupId = useSelector(getActivePopupId);
    const contentSplitView = useRecoilValue(contentSplitViewState);

    const { isActualVersion } = request.flags;

    const isInSplitView =
        contentSplitView.mode === ContentSplitViewMode.BillLinkedToBatchPayment &&
        request.id === contentSplitView.billId;

    const isCompanyReadonly = request.company.isReadonly;

    const dispatch = useDispatch();

    const closeMatchingPopup = useCallback(() => {
        dispatch(cancelActivePopup());
    }, [dispatch]);

    const billMatchingData = useXeroBillMatchingData(request, preloadedMatchingData).data;

    if (
        !billMatchingData ||
        (request.company.flags.isXeroMatchingV2ReadOnly && billMatchingData.tableData.length === 0)
    )
        return null;

    const {
        tableData,
        totalAllocatedAmountToThisBill,
        currency,
        totalAmount,
        totalPOsAmount,
        allocatedAmountToBilledPOs,
        allocatedAmount,
        remaining,
    } = billMatchingData;

    const hasMatchedPO = tableData.length > 0;
    const isNotDraft = request.statusV2 !== domain.RequestStatusV2.Draft;

    return (
        <StyledSectionContainer
            text={
                <HeaderContainer $hasButton={isNotDraft}>
                    <Title>{messages.sectionHeaderText}</Title>

                    {isNotDraft && isActualVersion && (
                        <Button
                            disabled={isLocked || isCompanyReadonly || readonly}
                            colorTheme='grey'
                            theme='primary'
                            padding='narrow15'
                            command={commands.changeMatchingV2}
                            execute={() => dispatch(showMatchingPopup())}
                            title={messages.changeMatchingButtonTitle}
                        >
                            {hasMatchedPO ? (
                                <>
                                    <EditIcon css='margin-right: 8px' width={16} height={16} />

                                    {messages.editMatching}
                                </>
                            ) : (
                                messages.matchWithPO
                            )}
                        </Button>
                    )}
                </HeaderContainer>
            }
        >
            <Popup isOpen={activePopupId === MATCHING_POPUP} onRequestClose={closeMatchingPopup} disableAutoClose>
                <ChangeMatchingPopup billId={request.id} companyId={request.companyId} onClose={closeMatchingPopup} />
            </Popup>

            <Popup
                isOpen={activePopupId === MATCHING_PO_REQUESTERS_POPUP}
                onRequestClose={closeMatchingPopup}
                disableAutoClose
            >
                <AddMatchedPOsRequestersPopup
                    companyId={request.companyId}
                    requestId={request.id}
                    requestVersion={request.version}
                    onClose={closeMatchingPopup}
                />
            </Popup>

            <Header>
                {hasMatchedPO ? (
                    <>
                        <Legend
                            legends={[
                                {
                                    color: '#4c8a5c',
                                    title: messages.allocatedAmountToBilledPOs,
                                    amount: intl.formatCurrency(allocatedAmountToBilledPOs, currency),
                                },
                                {
                                    color: '#b9d4c1',
                                    title: messages.allocatedAmount,
                                    amount: intl.formatCurrency(allocatedAmount, currency),
                                },
                                {
                                    color: '#e9eef0',
                                    title: messages.remaining,
                                    amount: intl.formatCurrency(remaining, currency),
                                },
                            ]}
                        />

                        <ColorBarSection
                            darkGreenBarValue={allocatedAmountToBilledPOs}
                            lightGreenBarValue={allocatedAmount}
                            totalAmount={totalAmount}
                            currency={currency}
                            isOverflowed={remaining < 0}
                        />
                    </>
                ) : (
                    <NoMatching>{messages.noMatchedPO}</NoMatching>
                )}
            </Header>

            {hasMatchedPO && (
                <CollapsibleSection title={messages.showTable}>
                    <BillMatchingTable
                        request={request}
                        lineItems={tableData}
                        totalAmount={totalPOsAmount}
                        totalAllocatedAmountToThisBill={totalAllocatedAmountToThisBill}
                        currency={currency}
                        companyId={request.companyId}
                        isInSplitView={isInSplitView}
                    />
                </CollapsibleSection>
            )}
        </StyledSectionContainer>
    );
};

export default memo(BillView);
