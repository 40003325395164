import { Divider, Flex, Grid, Text } from '@approvalmax/ui/src/components';
import range from 'lodash/range';
import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';

export const Loading = memo(() => {
    return (
        <Grid rowGap={12}>
            <Text font='label' fontSize='large' fontWeight='medium'>
                <Skeleton width={150} />
            </Text>

            <div>
                {range(5).map((index) => (
                    <div key={index}>
                        <Flex height={40} justifyContent='center' direction='column'>
                            <Skeleton height={16} width='100%' />
                        </Flex>

                        <Divider />
                    </div>
                ))}
            </div>
        </Grid>
    );
});

Loading.displayName = 'Loading';
