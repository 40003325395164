import { intl } from '@approvalmax/utils';
import ImageSleep from 'modules/components/ImageIcons/ImageSleep';
import { FC, memo } from 'react';
import { defineMessages } from 'react-intl';
import styled from 'styled-components/macro';

const i18nPrefix =
    'requestList/containers/xero/XeroMatchingV2Section/components/BillView/components/ChangeMatchingPopup/components/ChangeMatchingPopupBody/components/PanelEmpty/PanelEmpty';

const messages = defineMessages({
    noPOMatchingFilter: {
        id: `${i18nPrefix}.noPOMatchingFilter`,
        defaultMessage: 'No Purchase Orders were found',
    },
});

const NoPOMatchingFilterContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px 20px;
`;

const NoPOMatchingFilterText = styled.div`
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
`;

const PanelEmpty: FC = () => (
    <NoPOMatchingFilterContainer>
        <ImageSleep />

        <NoPOMatchingFilterText>{intl.formatMessage(messages.noPOMatchingFilter)}</NoPOMatchingFilterText>
    </NoPOMatchingFilterContainer>
);

export default memo(PanelEmpty);
