import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.Card.Toolbar.OnHoldStatusActions', {
    setOnHold: 'Set on hold',
    returnToApproval: 'Return to approval',
    setOnHoldPopupTitle: 'Reason to put the request on hold',
    returnToApprovalPopupTitle: 'Reason to return the request on approval',
    save: 'Save',
    comment: 'Comment',
});
