import { getEmailDomain } from '@approvalmax/data';
import { Reference } from '@approvalmax/types';
import { errorHelpers, miscHelpers, numberHelpers } from '@approvalmax/utils';
import uniqueId from 'lodash/uniqueId';
import { selectors } from 'modules/common';
import { schema } from 'normalizr';
import { getDefaultEnvironment } from 'shared/data';
import { emailToSupplierHelpers } from 'shared/helpers';

import { netSuiteConstants } from '../../../common/constants';
import * as backend from '../../backend';
import { domain } from '../../index';
import { getStringEnumMapper } from '../../utils';
import {
    AmountType,
    BaseCondition,
    ConditionType,
    MatrixCondition,
    NumericRangeConditionType,
    ServerConditionType,
} from '../Matrix';
import { DocumentField, Template, TemplateStepType } from '../Template';
import {
    TemplateSettings,
    TemplateSettingsAutoDecisionPolicyType,
    TemplateSettingsDecisionPolicyType,
    TemplateSettingsLockDatePolicy,
    TemplateSettingsQBooksEnabledLineItemType,
} from '../TemplateSettings';
import {
    mapCompanyBillMatchingSettings,
    mapCompanyBillMatchingSettingsBack,
    mapCompanyPurchaseOrderMatchingSettings,
    mapCompanyPurchaseOrderMatchingSettingsBack,
} from './companySchema';
import { mapFieldSystemPurpose } from './fieldSchema';
import userSchema, { mapUser } from './userSchema';

const mapAutoDecisionPolicy = getStringEnumMapper(
    backend.AutoDecisionPolicyType,
    TemplateSettingsAutoDecisionPolicyType
);

const mapLockDatePolicy = getStringEnumMapper(
    backend.AccountingTemplatesLockDatePolicyType,
    TemplateSettingsLockDatePolicy
);

const mapAutoDecisionPolicyBack = getStringEnumMapper(
    TemplateSettingsAutoDecisionPolicyType,
    backend.AutoDecisionPolicyType
);

const mapLockDatePolicyBack = getStringEnumMapper(
    TemplateSettingsLockDatePolicy,
    backend.AccountingTemplatesLockDatePolicyType
);

export const mapTemplateSettings = (value: backend.TemplatesTemplateSettings): TemplateSettings => {
    return {
        templateId: value.templateId!,
        approvalDisregardDetectionSettings: value.approvalDisregardDetectionSettings
            ? {
                  effectiveDate: value.approvalDisregardDetectionSettings.effectiveDate || null,
                  enabled: value.approvalDisregardDetectionSettings.enabled,
                  notifyAdmins: value.approvalDisregardDetectionSettings.notifyAdmins,
              }
            : undefined,
        autoDecisionPolicySettings: value.autoDecisionPolicySettings
            ? {
                  autoDecisionPolicy: mapAutoDecisionPolicy(value.autoDecisionPolicySettings.autoDecisionPolicy),
              }
            : undefined,
        lockDatePolicySettings: value.lockDatePolicySettings
            ? {
                  lockDatePolicy: mapLockDatePolicy(value.lockDatePolicySettings.lockDatePolicy),
              }
            : undefined,
        qBooksApprovalDisregardDetectionSettings: value.qBooksApprovalDisregardDetectionSettings
            ? {
                  effectiveDate: value.qBooksApprovalDisregardDetectionSettings.effectiveDate || null,
                  enabled: value.qBooksApprovalDisregardDetectionSettings.enabled,
              }
            : undefined,
        qBooksPostApprovalChangeDetectionSettings: value.qBooksPostApprovalChangeDetectionSettings,
        postApprovalChangeDetectionSettings: value.postApprovalChangeDetectionSettings,
        requesterInstructionSettings: value.requesterInstructionSettings,
        billMatchingSettings: value.matchingSettings
            ? mapCompanyBillMatchingSettings(value.matchingSettings)
            : undefined,
        purchaseOrderMatchingSettings: value.matchingSettings
            ? mapCompanyPurchaseOrderMatchingSettings(value.matchingSettings)
            : undefined,
        lineItemSettings: value.lineItemSettings
            ? {
                  qbooksEnabledLineItemType: mapQBooksEnabledLineItemType(value.lineItemSettings.availableLineItemType),
              }
            : undefined,
        approveAllStepsEnabled: value.approveAllStepsEnabled
            ? TemplateSettingsDecisionPolicyType.AllSteps
            : TemplateSettingsDecisionPolicyType.OnlyCurrent,
        appUrlOverridingEnabled: value.appUrlOverridingEnabled,
        supplierEmailSettings: value.supplierEmailSettings && {
            ...value.supplierEmailSettings,
            subject: emailToSupplierHelpers.escapeExpressions(value.supplierEmailSettings.subject || ''),
        },
        isSupplierBankAccountRequired: value.isSupplierBankAccountRequired,
        historyEventsPushingSettings: value.historyEventsPushingSettings
            ? {
                  historyEventsPushingType: value.historyEventsPushingSettings.historyEventsPushingType,
              }
            : undefined,
        netSuiteApprovalDisregardDetectionSettings: value.netSuiteApprovalDisregardDetectionSettings
            ? {
                  effectiveDate: value.netSuiteApprovalDisregardDetectionSettings.effectiveDate || null,
                  enabled: value.netSuiteApprovalDisregardDetectionSettings.enabled,
                  notifyAdmins: value.netSuiteApprovalDisregardDetectionSettings.notifyAdmins,
              }
            : undefined,
        netSuitePostApprovalChangeDetectionSettings: value.netSuitePostApprovalChangeDetectionSettings,
        dearApprovalDisregardDetectionSettings: value.dearApprovalDisregardDetectionSettings
            ? {
                  effectiveDate: value.dearApprovalDisregardDetectionSettings.effectiveDate || null,
                  enabled: value.dearApprovalDisregardDetectionSettings.enabled,
                  notifyAdmins: value.dearApprovalDisregardDetectionSettings.notifyAdmins,
              }
            : undefined,
        dearPostApprovalChangeDetectionSettings: value.dearPostApprovalChangeDetectionSettings,
        netSuiteAvailableLineItemType: value.netSuiteAvailableLineItemType ?? undefined,
        isGrnEnabled: value.isGrnEnabled || false,
        isPriceCheckerEnabled: value.isPriceCheckerEnabled || false,
        postingPreferencesType: value.postingPreferencesType ?? undefined,
        useRejectedPrefix: value.useRejectedPrefix || false,
        terms: value.terms,
        sendRejectedNoteToDear: value.sendRejectedNoteToDear || false,
        pushApprovalMaxURLToNetSuite: value.pushApprovalMaxURLToNetSuite || false,
        ocrEmailAddress: value.ocrEmailAddress?.replace(getEmailDomain(getDefaultEnvironment()), ''),
        ocrRequestInitialStatus: value.ocrRequestInitialStatus,
        isBalanceControlCheckEnabled: value.isBalanceControlCheckEnabled || false,
    };
};

export const mapTemplateSettingsBack = (value: TemplateSettings) => {
    const matchingSettings =
        mapCompanyBillMatchingSettingsBack(value.billMatchingSettings) ||
        mapCompanyPurchaseOrderMatchingSettingsBack(value.purchaseOrderMatchingSettings);

    return {
        templateId: value.templateId,
        approvalDisregardDetectionSettings: value.approvalDisregardDetectionSettings,
        requesterInstructionSettings: value.requesterInstructionSettings,
        autoDecisionPolicySettings: value.autoDecisionPolicySettings
            ? {
                  autoDecisionPolicy: mapAutoDecisionPolicyBack(value.autoDecisionPolicySettings.autoDecisionPolicy),
              }
            : undefined,
        lockDatePolicySettings: value.lockDatePolicySettings
            ? {
                  lockDatePolicy: mapLockDatePolicyBack(value.lockDatePolicySettings.lockDatePolicy),
              }
            : undefined,
        netSuiteApprovalDisregardDetectionSettings: value.netSuiteApprovalDisregardDetectionSettings,
        netSuitePostApprovalChangeDetectionSettings: value.netSuitePostApprovalChangeDetectionSettings,
        dearApprovalDisregardDetectionSettings: value.dearApprovalDisregardDetectionSettings,
        dearPostApprovalChangeDetectionSettings: value.dearPostApprovalChangeDetectionSettings,
        qBooksApprovalDisregardDetectionSettings: value.qBooksApprovalDisregardDetectionSettings,
        qBooksPostApprovalChangeDetectionSettings: value.qBooksPostApprovalChangeDetectionSettings,
        postApprovalChangeDetectionSettings: value.postApprovalChangeDetectionSettings,
        matchingSettings,
        lineItemSettings: value.lineItemSettings
            ? {
                  availableLineItemType: mapQBooksEnabledLineItemTypeBack(
                      value.lineItemSettings.qbooksEnabledLineItemType
                  ),
              }
            : undefined,
        approveAllStepsEnabled: TemplateSettingsDecisionPolicyType.AllSteps === value.approveAllStepsEnabled,
        appUrlOverridingEnabled: value.appUrlOverridingEnabled,
        supplierEmailSettings: value.supplierEmailSettings?.state ? value.supplierEmailSettings : undefined,
        isSupplierBankAccountRequired: value.isSupplierBankAccountRequired,
        historyEventsPushingSettings: value.historyEventsPushingSettings,
        netSuiteAvailableLineItemType: value.netSuiteAvailableLineItemType,
        isGrnEnabled: value.isGrnEnabled,
        isPriceCheckerEnabled: value.isPriceCheckerEnabled,
        postingPreferencesType: value.postingPreferencesType,
        useRejectedPrefix: value.useRejectedPrefix,
        terms: value.terms,
        sendRejectedNoteToDear: value.sendRejectedNoteToDear,
        pushApprovalMaxURLToNetSuite: value.pushApprovalMaxURLToNetSuite || false,
        ocrEmailAddress: value.ocrEmailAddress
            ? value.ocrEmailAddress + getEmailDomain(getDefaultEnvironment())
            : undefined,
        ocrRequestInitialStatus: value.ocrRequestInitialStatus,
        isBalanceControlCheckEnabled: value.isBalanceControlCheckEnabled,
    };
};

export const mapQBooksEnabledLineItemType = (
    value: backend.QBooksTemplatesAvailableLineItemType
): TemplateSettingsQBooksEnabledLineItemType => {
    return getStringEnumMapper(
        backend.QBooksTemplatesAvailableLineItemType,
        TemplateSettingsQBooksEnabledLineItemType
    )(value);
};

export const mapQBooksEnabledLineItemTypeBack = (
    value: TemplateSettingsQBooksEnabledLineItemType
): backend.QBooksTemplatesAvailableLineItemType => {
    return getStringEnumMapper(
        TemplateSettingsQBooksEnabledLineItemType,
        backend.QBooksTemplatesAvailableLineItemType
    )(value);
};

const getNumericRangeConditionType = (condition: backend.RuleConditionAnswer): NumericRangeConditionType => {
    const hasLess = Number.isFinite(condition.RangeConstraintLess);
    const hasGreaterEquals = Number.isFinite(condition.RangeConstraintGreaterEquals);

    if (hasLess && hasGreaterEquals) {
        return NumericRangeConditionType.Within;
    }

    if (hasLess) {
        return NumericRangeConditionType.Below;
    }

    if (hasGreaterEquals) {
        return NumericRangeConditionType.Above;
    }

    return NumericRangeConditionType.Any;
};

const getServerConditionType = (conditionType: backend.ServerConditionType): ServerConditionType => {
    switch (conditionType) {
        case backend.ServerConditionType.SuppliersOnly:
            return ServerConditionType.SuppliersOnly;

        case backend.ServerConditionType.CustomersOnly:
            return ServerConditionType.CustomersOnly;

        case backend.ServerConditionType.AllContacts:
            return ServerConditionType.AllContacts;

        default:
            throw errorHelpers.invalidOperationError();
    }
};

export const mapMatrixCondition = (condition: backend.RuleConditionAnswer): MatrixCondition => {
    const isNetSuiteNumericField =
        condition.NetSuiteField?.Type && netSuiteConstants.numericCustomFields.includes(condition.NetSuiteField.Type);
    const baseCondition: BaseCondition = {
        fieldId: condition.FieldId,
        fieldName: condition.Name,
        fieldSystemPurpose: mapFieldSystemPurpose(condition.SystemPurpose),
        allowEditing: condition.AllowEditing,
        allowCreation: condition.AllowCreation,
    };
    const exactValues: Reference[] = (condition.ExactConstraint || []).map((ref) => ({
        id: ref.Id,
        text: ref.Value,
    }));

    if (
        (condition.ConditionType === backend.FieldType.ExactValueRange ||
            condition.ConditionType === backend.FieldType.ExactValueRangeNegative) &&
        exactValues.length === 0 &&
        !numberHelpers.isNumber(condition.FieldValuesFilterType)
    ) {
        return {
            ...baseCondition,
            conditionType: null,
        };
    }

    if (condition.ConditionType === backend.FieldType.NumericRange || isNetSuiteNumericField) {
        return {
            ...baseCondition,
            conditionType: ConditionType.NumericRangeCondition,
            numericRangeConditionType: getNumericRangeConditionType(condition),
            numericRangeLess: numberHelpers.isNumber(condition.RangeConstraintLess)
                ? condition.RangeConstraintLess
                : null,
            numericRangeGreaterEquals: numberHelpers.isNumber(condition.RangeConstraintGreaterEquals)
                ? condition.RangeConstraintGreaterEquals
                : null,
            amountType: getAmountType(condition.AmountType),
        };
    }

    if (condition.ConditionType === backend.FieldType.ExactValueRangeNegative) {
        return {
            ...baseCondition,
            conditionType: ConditionType.NegativeExactValuesCondition,
            exactValues,
        };
    }

    if (condition.ConditionType === backend.FieldType.ExactValueRange) {
        if (numberHelpers.isNumber(condition.FieldValuesFilterType)) {
            return {
                ...baseCondition,
                conditionType: ConditionType.ServerCondition,
                serverConditionType: getServerConditionType(condition.FieldValuesFilterType),
                allowCreation: condition.AllowCreation || false,
            };
        }

        if (typeof condition.ExactBooleanConstraint === 'boolean') {
            return {
                ...baseCondition,
                conditionType: ConditionType.BoolCondition,
                exactConstraintBool: condition.ExactBooleanConstraint,
            };
        }

        return {
            ...baseCondition,
            conditionType: ConditionType.ExactValuesCondition,
            exactValues,
        };
    }

    throw errorHelpers.invalidOperationError();
};

const getAmountType = (amountType: backend.AmountConditionType | undefined): AmountType => {
    switch (amountType) {
        case backend.AmountConditionType.Net:
            return AmountType.Net;

        case backend.AmountConditionType.Gross:
        default:
            return AmountType.Gross;
    }
};

const mapMatrixData = (users: backend.TemplateUserAnswer[]): domain.MatrixLine[] => {
    return users.map((user) => ({
        lineId: user.UserEmail || '',
        isBackup: user.IsBackup,
        rules: user.Rules.map((rule) => ({
            conditions: rule.Conditions.map(mapMatrixCondition).filter(selectors.matrix.filterBrokenConditions),
        })),
    }));
};

const mapEditingMatrixData = (users: backend.TemplateUserAnswer[]): domain.MatrixLine[] => {
    return users.map((user) => ({
        lineId: user.UserEmail || '',
        rules: (user.EditPermissions || []).map((rule) => ({
            conditions: rule.Conditions.map(mapMatrixCondition),
        })),
    }));
};

const mapPayer = (user: backend.UserAnswer): domain.MatrixLine => {
    return {
        lineId: user.UserEmail || '',
        rules: [],
    };
};

const mapAutoApproveMatrixData = (autoApprovalRule: backend.AutoApprovalRuleAnswer[]): domain.MatrixLine[] => {
    const result: domain.MatrixLine[] = autoApprovalRule.map((rule) => ({
        lineId: rule.Name,
        rules: [
            {
                conditions: rule.Conditions.map(mapMatrixCondition).filter(selectors.matrix.filterBrokenConditions),
            },
        ],
    }));

    return result;
};

export const mapDocumentFields = (value: backend.TemplateDocumentField[]): DocumentField[] => {
    return value.map((item) => ({
        name: item.name,
        state: item.state,
        workflowVersionId: item.workflowVersionId,
        purpose: item.purpose,
        defaultOrder: item.defaultOrder,
        defaultState: item.defaultState,
        availableStates: item.availableStates,
    }));
};

const mapDeadlineRule = (value?: backend.DeadlineRuleAnswer | null): domain.TemplateStepDeadlineRule | null => {
    if (!value) return null;

    return {
        duration: value.Duration,
        calculator: value.Calculator,
    };
};

const mapReviewStep = (value: backend.TemplateReviewStepAnswer | null): domain.TemplateReviewStep => {
    return {
        requiredFieldIds: value?.RequiredFieldIds || [],
        readonlyFieldIds: value?.ReadonlyFieldIds || [],
        deadlineRule: mapDeadlineRule(value?.DeadlineRule),
        reviewers: mapMatrixData(value?.Reviewers || []),
    };
};

const getStepType = (amountType: backend.StepType | undefined): TemplateStepType => {
    switch (amountType) {
        case backend.StepType.AnyOfDecisionStep:
            return TemplateStepType.AnyOfDecisionStep;

        case backend.StepType.DecisionStep:
        default:
            return TemplateStepType.DecisionStep;
    }
};

const mapStep = (value: backend.TemplateStepAnswer): domain.TemplateStep => {
    return {
        id: value.StepId || uniqueId('template.'),
        name: value.Name,
        defaultDuration: value.DefaultDuration,
        deadlineRule: mapDeadlineRule(value.DeadlineRule),
        generalFieldOrder: value.RuleOrder || [],
        requiredFieldIds: value.RequiredFieldIds || [],
        readonlyFieldIds: value.ReadonlyFieldIds || [],
        editPermissionsRequiredFieldIds: value.EditPermissionsRequiredFieldIds || [],
        participantMatrix: mapMatrixData(value.Participants),
        editingMatrix: mapEditingMatrixData(value.Participants),
        editorMatrix: mapMatrixData(value.Editors),
        approvalCount: value.ApprovalCount,
        createDate: value.CreateDate,
        modifiedDate: value.ModifiedDate,
        type: getStepType(value.Type),
    };
};

const getUserEntities = (value: backend.TemplateAnswer): backend.UserAnswer[] => {
    const result = [
        value.Author,
        value.ExternalSubmitter,
        value.ReceiptBankExternalSubmitter,
        value.EmailExternalSubmitter,
        ...(value.ReviewStep?.Reviewers ?? []),
        ...(value.Submitters || []),
        ...(value.PaymentStep?.Payers ?? []),
        ...value.Steps.flatMap((step) => [...(step.Participants || []), ...(step.Editors || [])]),
    ];

    return result.reduce((accum, user) => {
        if (!user) return accum;

        return [...accum, user];
    }, []);
};

export const mapTemplate = (value: backend.TemplateAnswer): Template & { userEntities: backend.UserAnswer[] } => {
    if (!miscHelpers.isEnumValue(domain.IntegrationCode, value.IntegrationCode) && value.IntegrationCode !== null) {
        throw errorHelpers.notSupportedError('Unknown integration code.');
    }

    const userEntities = getUserEntities(value);

    return {
        id: value.TemplateId,
        companyId: value.CompanyId,
        templateName: value.TemplateName || '',
        enabled: value.Enabled,
        hasOutdatedRequests: !!value.HasOutdatedRequests,
        integrationCode: value.IntegrationCode,
        requiredFieldIds: value.RequiredFieldIds || [],
        createDate: value.CreateDate,
        modifiedDate: value.ModifiedDate,
        author: value.Author?.UserEmail || null,
        externalSubmitter: value.ExternalSubmitter?.UserEmail || null,
        emailExternalSubmitter: value.EmailExternalSubmitter?.UserEmail || null,
        ocrEmailAddress: value.OcrEmailAddress,
        ocrRequestInitialStatus: value.OcrRequestInitialStatus,
        receiptBankExternalSubmitter: value.ReceiptBankExternalSubmitter?.UserEmail || null,
        submitterMatrix: mapMatrixData(value.Submitters),
        submitterRuleOrders: value.SubmitterRuleOrders || null,
        payerMatrix: (value.PaymentStep?.Payers ?? []).map(mapPayer),
        reviewStep: mapReviewStep(value.ReviewStep),
        autoApprovalRules: mapAutoApproveMatrixData(value.AutoApprovalRules || []),
        isActual: value.IsActual,
        version: value.Version,
        comment: value.Comment || '',
        documentFields: mapDocumentFields(value.DocumentFields || []),
        steps: value.Steps.map(mapStep),
        userEntities,
    };
};

export const mapWorkflowVersionAnswer = (templates: backend.WorkflowVersionAnswer[]): domain.WorkflowVersion[] => {
    return templates.map((template) => ({
        templateVersionId: template.TemplateVersionId,
        version: template.Version,
        isActual: template.IsActual,
        createDate: template.CreateDate,
        author: template.Author ? mapUser(template.Author) : null,
        comment: template.Comment,
    }));
};

const templateSchema = new schema.Entity(
    'templates',
    { userEntities: [userSchema] },
    { idAttribute: 'TemplateId', processStrategy: mapTemplate }
);

export default templateSchema;
