import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.QBooksMatchingPopup', {
    billColumnName: 'Bill',
    dateColumnName: 'Date',
    accountColumnName: 'Category',
    descriptionColumnName: 'Description',
    amountColumnName: 'Amount {currency}',
    remainingColumnName: 'Remaining {currency}',
    taxColumnName: 'Tax',
    customerColumnName: 'Customer',
    classColumnName: 'Class',
    billableColumnName: 'Billable',
    taxableColumnName: 'Tax',
    billableColumnTooltip: 'This line is billable',
    taxableColumnTooltip: 'This line is taxable',
    itemColumnName: 'Product/Service',
    qtyColumnName: 'Qty',
    unitPriceColumnName: 'Rate',
    date: 'Date: <b>{date}</b>',
    totalAmount: 'Total amount: {totalAmount}',
    matchLines: 'Match lines',
    matchTooltip: 'Match',
});
