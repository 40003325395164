import { FC, memo } from 'react';

import AttachmentItem from './AttachmentItem';
import { Root } from './AttachmentList.styles';
import { AttachmentListProps } from './AttachmentList.types';

export const AttachmentList: FC<AttachmentListProps> = memo((props) => {
    const { attachments, onOpen, activeAttachmentId, disabled } = props;

    return (
        <Root disabled={disabled}>
            {attachments.map((attachment) => (
                <AttachmentItem
                    key={attachment.id}
                    attachment={attachment}
                    onOpen={onOpen}
                    activeAttachmentId={activeAttachmentId}
                />
            ))}
        </Root>
    );
});

AttachmentList.displayName = 'AttachmentList';
