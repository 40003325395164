import { Dropdown } from '@approvalmax/ui';
import { hooks } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { InfoIconGreen, InfoIconOrange, InfoIconRed } from 'modules/sprites';
import { FC, useCallback, useMemo } from 'react';

import { beneficiaryDetailsPropNames } from './BeneficiaryIndicator.constants';
import { messages } from './BeneficiaryIndicator.messages';
import {
    BankDetails,
    BankDetailsContent,
    BankDetailsHeader,
    BankDetailsHeaderContent,
    Gradient,
    Header,
    HeaderStatusText,
    IconButton,
    Label,
    Line,
    Panel,
    Root,
    StyledInfoIconGray,
    StyledTooltip,
    Value,
} from './BeneficiaryIndicator.styles';
import { BeneficiaryIndicatorProps } from './BeneficiaryIndicator.types';

const BeneficiaryIndicator: FC<BeneficiaryIndicatorProps> = (props) => {
    const { lastPaymentProperties, children, beneficiary, forDropDown, status } = props;

    const [isOpen, toogleIsOpen] = hooks.useToggle(false);

    const handleOpen = useCallback(() => {
        if (!beneficiary) return;

        toogleIsOpen();
    }, [beneficiary, toogleIsOpen]);

    const handleClose = useCallback(() => {
        toogleIsOpen(false);
    }, [toogleIsOpen]);

    const infoIcon = useMemo(() => {
        switch (status) {
            case 'paid-previously':
                return <InfoIconGreen />;

            case 'never-paid':
                return <InfoIconOrange />;

            case 'payment-details-changed':
                return <InfoIconRed />;

            default:
                return <StyledInfoIconGray $disabled={!beneficiary} />;
        }
    }, [beneficiary, status]);

    const headerContent = useMemo(() => {
        switch (status) {
            case 'paid-previously':
                return (
                    <>
                        <div>
                            <InfoIconGreen />
                        </div>

                        <HeaderStatusText>{messages.greenText}</HeaderStatusText>
                    </>
                );

            case 'never-paid':
                return (
                    <>
                        <div>
                            <InfoIconOrange />
                        </div>

                        <HeaderStatusText>{messages.yellowText}</HeaderStatusText>
                    </>
                );

            case 'payment-details-changed':
                return (
                    <>
                        <div>
                            <InfoIconRed />
                        </div>

                        <HeaderStatusText>{messages.redText}</HeaderStatusText>
                    </>
                );

            default:
                return null;
        }
    }, [status]);

    const getUpdatedProperty = useCallback(
        (propertyName: keyof domain.AirwallexBeneficiaryBankDetails) => {
            const property = lastPaymentProperties?.find(
                (p) => p.name.toLocaleLowerCase() === propertyName.toLocaleLowerCase()
            );

            return property;
        },
        [lastPaymentProperties]
    );

    const mapBankDetailsContent = useCallback(
        (propName: keyof domain.AirwallexBeneficiaryBankDetails, updated?: boolean) => {
            const value = (!updated && getUpdatedProperty(propName)?.value) || beneficiary?.bankDetails?.[propName];

            return (
                <Line $hide={!beneficiary?.bankDetails?.[propName]} key={propName}>
                    <Label>{messages[propName]}</Label>

                    <StyledTooltip tooltip={value}>
                        <Value
                            $invalid={!updated && Boolean(getUpdatedProperty(propName))}
                            $active={updated && Boolean(getUpdatedProperty(propName))}
                        >
                            {value}
                        </Value>
                    </StyledTooltip>
                </Line>
            );
        },
        [beneficiary?.bankDetails, getUpdatedProperty]
    );

    const bankDetailsContent = useMemo(
        () => beneficiaryDetailsPropNames.map((propName) => mapBankDetailsContent(propName, false)),
        [mapBankDetailsContent]
    );

    const updatedBankDetailsContent = useMemo(
        () => beneficiaryDetailsPropNames.map((propName) => mapBankDetailsContent(propName, true)),
        [mapBankDetailsContent]
    );

    return (
        <Dropdown
            button={(ref) => (
                <Root ref={ref}>
                    {forDropDown && <Gradient />}

                    <IconButton $forDropDown={forDropDown} disabled={!beneficiary} execute={handleOpen}>
                        {infoIcon}
                    </IconButton>

                    {children}
                </Root>
            )}
            isOpen={isOpen}
            onRequestClose={handleClose}
        >
            <Panel $fullWidth={status === 'payment-details-changed'}>
                <Header>{headerContent}</Header>

                {status === 'payment-details-changed' && (
                    <BankDetailsHeader>
                        <BankDetailsHeaderContent>{messages.previous}</BankDetailsHeaderContent>

                        <BankDetailsHeaderContent>{messages.actual}</BankDetailsHeaderContent>
                    </BankDetailsHeader>
                )}

                <BankDetailsContent>
                    <BankDetails $fullWidth={status !== 'payment-details-changed'}>{bankDetailsContent}</BankDetails>

                    {status === 'payment-details-changed' && <BankDetails>{updatedBankDetailsContent}</BankDetails>}
                </BankDetailsContent>
            </Panel>
        </Dropdown>
    );
};

export default BeneficiaryIndicator;
