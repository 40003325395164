import { DropdownEditor } from '@approvalmax/ui';
import { mentionHelpers } from '@approvalmax/ui/src/components';
import { constants, selectors } from 'modules/common';
import { HelpShowButton, HelpStripe, useHelpStripe } from 'modules/common/ui';
import { PlainDataProvider } from 'modules/data-providers';
import { useSelector } from 'modules/react-redux';
import FileDropProvider from 'pages/requestForm/components/FileDropProvider';
import FileList from 'pages/requestForm/components/filesSection/FileList/FileList';
import { AttachmentKind } from 'pages/requestForm/data/AttachmentKind';
import { useReloadRequestCard } from 'pages/requestList/hooks';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { notificationService } from 'services/notification';
import { useCompanyMentionItems } from 'shared/hooks/useCompanyMentionItems';

import { getMentionedUsersWithoutAccessToRequest } from '../../../../utils/getUsersWithoutAccessToRequest';
import { addAsWatcherPopupState } from '../../../AddAsWatchersPopup/AddAsWatchersPopup.states';
import { statusOptions } from './EditPopup.config';
import { useEditPopupAttachments, useEditPopupData, useEditPopupHelpStripe } from './EditPopup.hooks';
import { messages } from './EditPopup.messages';
import {
    Content,
    ContentDescription,
    ContentField,
    RichEditorStyled,
    Root,
    UpdateButton,
    Wrap,
} from './EditPopup.styles';
import { EditPopupProps } from './EditPopup.types';

const EditPopup = memo<EditPopupProps>((props) => {
    const { isOpen, onClose, companyId, requestId } = props;

    const request = useSelector((state) => selectors.request.getRequestById(state, requestId));

    const setWatchersPopup = useSetRecoilState(addAsWatcherPopupState);

    const helpItemId = useEditPopupHelpStripe(companyId);
    const { isVisible: isHelpStripeVisible, onShow: onHelpStripeShow } = useHelpStripe(helpItemId);

    const { handleGetMentionItems } = useCompanyMentionItems(request.company);

    const reloadRequestCard = useReloadRequestCard();

    const { statusOption, commentText, onChangeStatusOption, onChangeComment, onResetData, handleChangeStatus } =
        useEditPopupData(request);
    const { attachments, attachmentsInProgress, onUpload, onDownloadAttachment, onRemove, onResetAttachments } =
        useEditPopupAttachments(companyId);

    const [isFormDisabled, setIsFormDisabled] = useState(false);

    const { mentionedUserIds } = useMemo(() => mentionHelpers.prepareCommentPayload(commentText), [commentText]);

    const isUpdateDisabled = !statusOption || attachmentsInProgress.length > 0 || isFormDisabled;

    const handleUpdateGrnStatus = useCallback(async () => {
        if (statusOption) {
            try {
                setIsFormDisabled(true);

                await handleChangeStatus({
                    companyId,
                    requestId,
                    commentText,
                    grnStatus: statusOption.id,
                    attachments: attachments.map((attach) => attach.id),
                    mentionedUserIds,
                });

                onResetData();
                onResetAttachments();

                reloadRequestCard(companyId, requestId);

                onClose();
            } catch (err) {
                notificationService.showErrorToast(err.errorMessage);
            } finally {
                setIsFormDisabled(false);
            }
        }
    }, [
        attachments,
        commentText,
        companyId,
        handleChangeStatus,
        mentionedUserIds,
        onClose,
        onResetAttachments,
        onResetData,
        reloadRequestCard,
        requestId,
        statusOption,
    ]);

    const handleSubmit = useCallback(() => {
        const usersWithoutAccess = getMentionedUsersWithoutAccessToRequest(request, mentionedUserIds);

        if (usersWithoutAccess.length) {
            setWatchersPopup((state) => ({ ...state, usersWithoutAccess }));
        } else {
            handleUpdateGrnStatus();
        }
    }, [request, mentionedUserIds, setWatchersPopup, handleUpdateGrnStatus]);

    useEffect(() => {
        setWatchersPopup((state) => ({ ...state, onSubmit: handleUpdateGrnStatus }));
    }, [handleUpdateGrnStatus, setWatchersPopup]);

    return (
        <Root isOpen={isOpen} onRequestClose={onClose}>
            <Wrap
                title={messages.title}
                buttons={
                    <>
                        {!isHelpStripeVisible && <HelpShowButton onClick={onHelpStripeShow} />}

                        <UpdateButton
                            execute={handleSubmit}
                            title={messages.buttonUpdateTitle}
                            disabled={isUpdateDisabled}
                        >
                            {messages.buttonUpdate}
                        </UpdateButton>
                    </>
                }
            >
                <>
                    {helpItemId && <HelpStripe helpItemId={helpItemId} />}

                    <Content>
                        <ContentDescription>{messages.description}</ContentDescription>

                        <ContentField title={messages.fieldStatus} short required>
                            <PlainDataProvider items={statusOptions}>
                                <DropdownEditor
                                    value={statusOption}
                                    onChange={onChangeStatusOption}
                                    placeholder={messages.fieldStatusPlaceholder}
                                    buttonComponent={DropdownEditor.NoFilterButton}
                                    disabled={isFormDisabled}
                                />
                            </PlainDataProvider>
                        </ContentField>

                        <ContentField title={messages.fieldComment}>
                            <RichEditorStyled
                                allowTextFormatting={false}
                                invalid={false}
                                value={commentText}
                                minHeight={4}
                                onChange={onChangeComment}
                                maxLength={512}
                                placeholder={messages.fieldCommentPlaceholder}
                                changeOnBlur
                                allowMention
                                mentionItems={handleGetMentionItems}
                                disabled={isFormDisabled}
                            />
                        </ContentField>

                        <ContentField title={messages.fieldAttachments}>
                            <FileDropProvider onUpload={onUpload} disabled={isFormDisabled}>
                                <FileList
                                    attachments={attachments}
                                    newAttachments={attachmentsInProgress}
                                    removeAttachment={onRemove}
                                    customHandleClickOnAttachment={onDownloadAttachment}
                                    readonly={false}
                                    attachmentKind={AttachmentKind.General}
                                    maxFilesCount={constants.uploadsConstants.COMMENT_MAX_ATTACHMENT_COUNT}
                                />
                            </FileDropProvider>
                        </ContentField>
                    </Content>
                </>
            </Wrap>
        </Root>
    );
});

export default EditPopup;
