import { useMemo } from 'react';

import { useLineCustomFieldsDefinition } from '../../NetSuiteRequestCard.hooks';
import { getColumnDefs } from './NetSuiteItemLines.config';
import { ItemsFieldsSettings, NetSuiteItemLinesProps } from './NetSuiteItemLines.types';

export const useTableDefinitions = (
    itemLines: NetSuiteItemLinesProps['itemLines'],
    companyCustomFields: NetSuiteItemLinesProps['companyCustomFields'],
    integrationCode: NonNullable<NetSuiteItemLinesProps['integrationCode']>,
    fieldSettings: ItemsFieldsSettings,
    exchangeRate?: number | null
) => {
    const customFieldsColumnDefinition = useLineCustomFieldsDefinition(itemLines, companyCustomFields);

    const resultColumnDefinition = useMemo(
        () => [...getColumnDefs(integrationCode, fieldSettings, exchangeRate), ...customFieldsColumnDefinition],
        [customFieldsColumnDefinition, integrationCode, fieldSettings, exchangeRate]
    );

    return resultColumnDefinition;
};
