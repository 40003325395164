import styled from 'styled-components';

import FieldsSection from '../../../components/FieldsSection';

export const FieldsSectionStyled = styled(FieldsSection)`
    border-bottom: 1px solid #e4e4e4;
    && {
        padding: 0 50px 10px 60px;
    }
`;
