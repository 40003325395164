import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { authApiPaths } from '../paths';
import { GetFido2Credentials } from './useGetFido2Credentials.types';

export const useGetFido2Credentials = (
    params?: RequestParams<GetFido2Credentials>,
    queryOptions?: RequestQueryOptions<GetFido2Credentials>
) => {
    return useGet(authApiPaths.fido2Credentials, {
        params,
        queryOptions,
        apiVersion: 'v2',
    });
};
