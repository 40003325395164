import { compareHelpers } from '@approvalmax/utils';
import { backend, domain } from 'modules/data';

import { parseQBooksAddress } from './QBooksAddress';
import { parseQBooksCurrency } from './QBooksCurrency';

export function mapQBooksContacts(items: backend.IntegrationsQBOCounterparty[]): domain.QBooksContact[] {
    let firstLevel: domain.QBooksContact[] = [];

    const idToChildren: { [key: string]: domain.QBooksContact[] } = {};

    items.forEach((item) => {
        if (item.ParentId) {
            idToChildren[item.ParentId] = idToChildren[item.ParentId] || [];
            idToChildren[item.ParentId].push(parseQBooksContact(item));
        } else {
            firstLevel.push(parseQBooksContact(item));
        }
    });

    // The tree might be inconsistent (parentId not on the list). Then consider the parent null.
    Object.keys(idToChildren).forEach((parentId) => {
        const parentContact = items.find((item) => item.Id === parentId);

        if (!parentContact) {
            const contactWithoutParent = idToChildren[parentId];

            delete idToChildren[parentId];
            firstLevel.push(...contactWithoutParent);
        }
    });

    const result: domain.QBooksContact[] = [];

    firstLevel.sort(compareHelpers.comparatorFor<domain.QBooksContact>(compareHelpers.stringComparator2Asc, 'text'));

    const recurseAddToResult = (root: domain.QBooksContact, nestingLevel: number) => {
        result.push(root);
        root.nestingLevel = nestingLevel;

        const children = idToChildren[root.id];

        if (children) {
            children.sort(
                compareHelpers.comparatorFor<domain.QBooksContact>(compareHelpers.stringComparator2Asc, 'text')
            );

            children.forEach((item) => {
                recurseAddToResult(item, nestingLevel + 1);
            });
        }
    };

    firstLevel.forEach((item) => recurseAddToResult(item, 0));

    return result;
}

export function parseQBooksContact(value: backend.IntegrationsQBOCounterparty): domain.QBooksContact {
    const onlyTruthy = (x: string) => (x ? Boolean(x.trim()) : false);
    const contactPersons = (value.ContactPersons || []).map(
        (x): domain.QBooksContactPerson => ({
            firstName: x.FirstName || '',
            lastName: x.LastName || '',
            emailAddress: x.EmailAddress || '',
            includeInEmails: Boolean(x.IncludeInEmails),
        })
    );

    let contactFragment = '';

    if (contactPersons.length > 0) {
        contactFragment = [contactPersons[0].firstName, contactPersons[0].lastName].filter(onlyTruthy).join(' ');
    }

    let contact: domain.QBooksContact = {
        id: value.Id,
        parentId: (value.ParentId && value.ParentId.trim()) || null,
        text: value.Name,
        addresses: (value.Addresses || [])
            .map((a) => {
                let address = parseQBooksAddress(a);

                address.text = [
                    contactFragment,
                    value.Name,
                    a.AddressLine1,
                    a.AddressLine2,
                    a.AddressLine3,
                    a.AddressLine4,
                    [a.City, [a.Region, a.PostalCode, a.Country].filter(onlyTruthy).join(' ')]
                        .filter(onlyTruthy)
                        .join(', '),
                ]
                    .filter(onlyTruthy)
                    .join('\n');

                return address;
            })
            .filter((a) => a.address),
        contactPersons,
        currency: value.Currency ? parseQBooksCurrency(value.Currency) : undefined,
        nestingLevel: 0,
        taxId: value.TaxId,
        termId: value.TermId,
    };

    if (!contact.addresses || contact.addresses.length === 0) {
        const addressText = [contactFragment, value.Name].filter(onlyTruthy).join('\n');

        contact.addresses = [
            {
                id: addressText,
                text: addressText,
                attentionTo: '',
                address: {
                    addressType: backend.AddressType.Physical,
                },
                type: domain.QBooksAddressType.Physical,
            },
        ];
    }

    return contact;
}
