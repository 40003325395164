import { intl, mathService } from '@approvalmax/utils';
import { FC, memo } from 'react';

import { messages } from './ExpenseReportAmountInfo.messages';
import { BlockLabel, BlockLine, BlockValue, Main, Separator } from './ExpenseReportAmountInfo.styles';
import { ExpenseReportAmountInfoProps } from './ExpenseReportAmountInfo.types';

const ExpenseReportAmountInfo: FC<ExpenseReportAmountInfoProps> = memo((props) => {
    const { currency, totalExpenses, advance, reimbursableAmount, exchangeRate, qa } = props;

    const totalExpensesResult =
        typeof totalExpenses === 'number' && typeof exchangeRate === 'number'
            ? mathService.multiply(totalExpenses, exchangeRate)
            : totalExpenses;

    const advanceResult =
        typeof advance === 'number' && typeof exchangeRate === 'number'
            ? mathService.multiply(advance, exchangeRate)
            : advance;

    const reimbursableAmountResult =
        typeof reimbursableAmount === 'number' && typeof exchangeRate === 'number'
            ? mathService.multiply(reimbursableAmount, exchangeRate)
            : reimbursableAmount;

    return (
        <Main>
            <BlockLine data-qa={qa('total-expenses')}>
                <BlockLabel data-qa={qa('total-expenses-label')}>{messages.totalExpenses}</BlockLabel>

                <BlockValue data-qa={qa('total-expenses-value')}>
                    {intl.formatCurrency(totalExpensesResult, currency, 2, false)}
                </BlockValue>
            </BlockLine>

            <BlockLine data-qa={qa('advance')}>
                <BlockLabel data-qa={qa('advance-label')}>{messages.advance}</BlockLabel>

                <BlockValue data-qa={qa('advance-value')}>
                    {intl.formatCurrency(advanceResult, currency, 2, false)}
                </BlockValue>
            </BlockLine>

            <BlockLine>
                <Separator />
            </BlockLine>

            <BlockLine data-qa={qa('reimbursable-amount')}>
                <BlockLabel data-qa={qa('reimbursable-amount-label')}>{messages.reimbursableAmount}</BlockLabel>

                <BlockValue data-qa={qa('reimbursable-amount-value')}>
                    {intl.formatCurrency(reimbursableAmountResult, currency, 2, false)}
                </BlockValue>
            </BlockLine>
        </Main>
    );
});

export default ExpenseReportAmountInfo;
