import { FC, memo } from 'react';

import { useItemsFieldSettings } from '../../NetSuiteRequestCard.hooks';
import { NetSuiteLineTable } from '../NetSuiteLineTable/NetSuiteLineTable';
import { useTableDefinitions } from './NetSuiteItemLines.hooks';
import { messages } from './NetSuiteItemLines.messages';
import { NetSuiteItemLinesProps } from './NetSuiteItemLines.types';

const NetSuiteItemLines: FC<NetSuiteItemLinesProps> = memo((props) => {
    const { itemLines, companyCustomFields, integrationCode, documentFields, exchangeRate } = props;

    const itemsFieldSettings = useItemsFieldSettings(documentFields || []);

    const tableDef = useTableDefinitions(
        itemLines,
        companyCustomFields,
        integrationCode,
        itemsFieldSettings,
        exchangeRate
    );

    return itemLines.length > 0 ? (
        <NetSuiteLineTable title={messages.sectionHeaderText} columnDefinitions={tableDef} lineItems={itemLines} />
    ) : null;
});

export default NetSuiteItemLines;
