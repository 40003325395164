import { backend, domain, schemas } from 'modules/data';

import { parseQBooksAccount } from './QBooksAccount';
import { parseQBooksTaxCode } from './QBooksTaxCode';

function parseItemDetails(
    value: backend.IntegrationsQBooksQBOItemDetails | undefined
): domain.QBooksItemDetails | undefined {
    if (!value) {
        return undefined;
    }

    return {
        description: value.Description,
        unitPrice: value.UnitPrice,
        account: value.Account ? parseQBooksAccount(value.Account) : undefined,
        taxCode: value.TaxCode ? parseQBooksTaxCode(value.TaxCode) : undefined,
    };
}

export function parseQBooksItem(value: backend.IntegrationsQBooksItem): domain.QBooksItem {
    return {
        id: value.Id,
        text: value.Name,
        code: value.Code,
        isInventory: Boolean(value.IsInventory),
        salesDetails: parseItemDetails(value.SalesDetails),
        purchaseDetails: parseItemDetails(value.PurchaseDetails),
        class: schemas.mapReferenceWithValue(value.Class),
    };
}
