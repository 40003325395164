import { enabledByParams, QueryParams } from '@approvalmax/data';
import { ApiError } from '@approvalmax/types';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import axios from 'axios';
import { getApiUrl, UseGetQuoteForCustomerDecisionPdfResponse } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { UseGetQuoteForCustomerDecisionPdfParams } from './useGetQuoteForCustomerDecisionPdf.types';

export const useGetQuoteForCustomerDecisionPdf = (
    params: QueryParams<UseGetQuoteForCustomerDecisionPdfParams>,
    queryOptions?: UseQueryOptions<UseGetQuoteForCustomerDecisionPdfResponse, ApiError>
) => {
    // TODO: Update useGetLegacy for using with get PDFs
    return useQuery<UseGetQuoteForCustomerDecisionPdfResponse>(
        [requestsApiPaths.getQuoteForCustomerDecisionPdf, params],
        async () => {
            const apiUrl = getApiUrl();
            const url = `${apiUrl}/${requestsApiPaths.getQuoteForCustomerDecisionPdf}`;
            const response = await axios.post(url, params, {
                responseType: 'blob',
            });

            const res = await response.data;

            return URL.createObjectURL(res);
        },
        {
            ...queryOptions,
            enabled: enabledByParams<UseGetQuoteForCustomerDecisionPdfParams>(
                {
                    companyId: params.companyId,
                    requestId: params.requestId,
                    code: params.code,
                    inline: params.inline,
                },
                queryOptions
            ),
        }
    );
};
