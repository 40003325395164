import { Button, Flex, SplitView, Text } from '@approvalmax/ui/src/components';
import { ChevronLeftIcon } from '@approvalmax/ui/src/icons';
import { FC, memo, useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { ContentSplitViewMode, contentSplitViewState } from 'shared/states';

import { splitViewGoToBarDefaultSettings } from './SplitViewGoToBar.constants';
import { messages } from './SplitViewGoToBar.messages';
import { splitViewGoToBarSettingsState } from './SplitViewGoToBar.states';
import { SplitViewGoToBarProps } from './SplitViewGoToBar.types';

export const SplitViewGoToBar: FC<SplitViewGoToBarProps> = memo((props) => {
    const { paginationProps, downloadButtonProps, qa } = props;

    const [contentSplitView, setContentSplitView] = useRecoilState(contentSplitViewState);
    const [goToBarSettings, setGoToBarSettings] = useRecoilState(splitViewGoToBarSettingsState);

    const onGoTo = useCallback(() => {
        goToBarSettings.targetContent && setContentSplitView(goToBarSettings.targetContent);
        setGoToBarSettings(splitViewGoToBarDefaultSettings);
    }, [goToBarSettings.targetContent, setContentSplitView, setGoToBarSettings]);

    const isAttachmentsToLinkedBill =
        contentSplitView.mode === ContentSplitViewMode.AttachmentsToBillLinkedToBatchPayment ||
        contentSplitView.mode === ContentSplitViewMode.AttachmentsToNetSuiteBillLinkedToBillPayment;

    return (
        <Flex justifyContent='space-between' spacing='8' width='100%' wrap={false}>
            <Flex alignItems='center' spacing='8' wrap={false}>
                <Button icon color='transparent' noPadding onClick={onGoTo}>
                    <ChevronLeftIcon size={28} />
                </Button>

                <Text font='body' ellipsis={1}>
                    {goToBarSettings.targetContentName
                        ? messages.goTo({ contentName: goToBarSettings.targetContentName })
                        : messages.goBack}
                </Text>
            </Flex>

            <Flex alignItems='center' spacing='8' wrap={false}>
                {isAttachmentsToLinkedBill && downloadButtonProps && (
                    <SplitView.Addons.DownloadButton url={downloadButtonProps.url || ''} qa={qa} />
                )}

                {isAttachmentsToLinkedBill && paginationProps && (
                    <SplitView.Addons.Pagination
                        currentIndex={paginationProps.currentIndex}
                        total={paginationProps.total}
                        goToNextDocument={paginationProps.goToNextDocument}
                        goToPrevDocument={paginationProps.goToPrevDocument}
                        qa={qa}
                    />
                )}
            </Flex>
        </Flex>
    );
});

SplitViewGoToBar.displayName = 'SplitViewGoToBar';
