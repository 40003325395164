import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import { useShallowEqualSelector } from 'modules/react-redux';
import { useMemo } from 'react';

import { getActiveSettings, getActiveTemplate, isPageReadonly } from '../../selectors/pageSelectors';
import { UseEffectiveDateLimits } from './WorkflowSettingsPopupContent.types';

export const useEffectiveDateLimits: UseEffectiveDateLimits = (templateSettings) => {
    return useMemo(() => {
        if (templateSettings.approvalDisregardDetectionSettings) {
            return selectors.template.getTemplateSettingsFraudEffectiveDateLimits(
                templateSettings.approvalDisregardDetectionSettings.effectiveDate
            );
        }

        if (templateSettings.netSuiteApprovalDisregardDetectionSettings) {
            return selectors.template.getTemplateSettingsFraudEffectiveDateLimits(
                templateSettings.netSuiteApprovalDisregardDetectionSettings.effectiveDate
            );
        }

        if (templateSettings.dearApprovalDisregardDetectionSettings) {
            return selectors.template.getTemplateSettingsFraudEffectiveDateLimits(
                templateSettings.dearApprovalDisregardDetectionSettings.effectiveDate
            );
        }

        if (templateSettings.qBooksApprovalDisregardDetectionSettings) {
            return selectors.template.getTemplateSettingsFraudEffectiveDateLimits(
                templateSettings.qBooksApprovalDisregardDetectionSettings.effectiveDate
            );
        }

        return null;
    }, [templateSettings]);
};

export const useWorkflowSettingsPopupData = () => {
    return useShallowEqualSelector((state: State) => {
        const template = selectors.template.getTemplateById(state, getActiveTemplate(state)!.id);
        const company = selectors.company.getCompanyById(state, template.companyId);
        const savedTemplateSettings = getActiveSettings(state)!.data;
        const readonly = isPageReadonly(state);
        const me = selectors.profile.getProfileUser(state);
        const role = selectors.company.getCompanyUserRole(company, me.id);

        const isSaveDisabled = role === domain.CompanyUserRole.Auditor;

        return {
            company,
            template,
            savedTemplateSettings,
            readonly,
            isSaveDisabled,
        };
    });
};
