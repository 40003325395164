import { RequestMutationOptions } from '@approvalmax/data';
import { notificationService } from 'services/notification';
import { useMutate } from 'shared/data';

import { integrationApiPaths } from '../paths';
import { messages } from './useRefreshNetSuiteCache.messages';
import { RefreshNetSuiteCache } from './useRefreshNetSuiteCache.types';

export const useRefreshNetSuiteCache = (mutationOptions?: RequestMutationOptions<RefreshNetSuiteCache>) => {
    return useMutate(integrationApiPaths.refreshNetSuiteCache, {
        mutationOptions: {
            ...mutationOptions,
            onSuccess: async (response, variables, context) => {
                notificationService.showInfoToast(messages.successMessages);

                mutationOptions?.onSuccess?.(response, variables, context);
            },
        },
        apiVersion: 'v1',
    });
};
