import { QueryOptions } from '@approvalmax/data';
import { useGetLegacy } from 'shared/data';

import { paymentServicesApiPaths } from '../paths';
import {
    UseGetBillLinkedToBatchPaymentBackend,
    UseGetBillLinkedToBatchPaymentParams,
} from './useGetBillLinkedToBatchPayment.types';

export const useGetBillLinkedToBatchPayment = (
    params: UseGetBillLinkedToBatchPaymentParams,
    queryOptions?: QueryOptions<UseGetBillLinkedToBatchPaymentBackend>
) =>
    useGetLegacy<UseGetBillLinkedToBatchPaymentBackend>(paymentServicesApiPaths.getBillLinkedToBatchPayment, {
        queryParams: params,
        queryOptions,
    });
