export type UseAmaxPayConsentCreatePathParams = {
    companyId: string;
};

export type UseAmaxPayConsentCreateData = {
    callbackUrl: string;
    bankId: string;
};

/**
 * @see https://approvalmax.atlassian.net/wiki/spaces/PRD/pages/2723840035/Bank+accounts#Statuses
 */
export enum ConsentStatus {
    Authorized = 'Authorized',
    AwaitingAuthorization = 'AwaitingAuthorization',
    Expired = 'Expired',
    Rejected = 'Rejected',
    Revoked = 'Revoked',
}

export type Consent = {
    id: string;
    /**
     * A link to a bank web application
     */
    authorisationUrl: string;

    /**
     * A datetime string that represents a time then user scans a QR code and opens the link within
     */
    authorisationUrlOpenedAt?: string;
    /**
     * Datetime string of consent creation
     */
    createdAt: string;

    status: ConsentStatus;
};

export type UseAmaxPayConsentCreateResponse = {
    data: Consent;
};
