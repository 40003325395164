import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages(
    'pages.RequestList.Toolbar.PayWithAirwallex.CompleteAirwallexPaymentPopup.ProcessingStep',
    {
        title: 'Airwallex Batch Payment processing underway',
        description:
            "We are currently verifying the beneficiaries' bank details. Please bear with us as this may take a moment.",
    }
);
