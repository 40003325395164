import { MutateDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { twoFaApiPaths } from '../paths';
import { mapData } from './useSendTFAVerifyingEmailCode.map';
import {
    SendTFAVerifyingEmailCodeBackend,
    SendTFAVerifyingEmailCodeRequest,
    SendTFAVerifyingEmailCodeResponse,
} from './useSendTFAVerifyingEmailCode.types';

export const useSendTFAVerifyingEmailCode = () => {
    return useMutateLegacy<
        MutateDataParams<SendTFAVerifyingEmailCodeRequest>,
        SendTFAVerifyingEmailCodeBackend,
        SendTFAVerifyingEmailCodeResponse
    >(twoFaApiPaths.sendTFAVerifyingEmailCode, {
        mapData,
    });
};
