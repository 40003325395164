import { domain } from 'modules/data';

export enum SignInPostActionType {
    Connect = 'connect',
    Disconnect = 'disconnect',
}

export type SignInPostAction =
    | {
          type: SignInPostActionType.Connect;
          externalOrgId?: string;
      }
    | {
          type: SignInPostActionType.Disconnect;
          externalOrgId: string;
      };

export interface SignInUiState {
    app?: domain.SsoLoginApplication;
    postAction?: SignInPostAction;
    afterLoginRedirectUrl?: string;
    afterSignUpRedirectUrl?: string;
    startingPoint?: domain.SsoStartingPoint;
    tenantId?: string;
}

export function encodeSignInUiState(value: SignInUiState) {
    return encodeURIComponent(JSON.stringify(value));
}

export function decodeSignInUiState(value: any): SignInUiState {
    return JSON.parse(decodeURIComponent(value));
}

/**
 * @deprecated Use Redesigned version
 */
export const getSSOMethodForAnalytics = (provider: domain.OAuthProvider) => {
    switch (provider) {
        case domain.OAuthProvider.Xero:
            return 'xero_sso';

        case domain.OAuthProvider.QBooks:
            return 'qbo_sso';

        case domain.OAuthProvider.Google:
            return 'google_sso';

        case domain.OAuthProvider.Microsoft:
            return 'microsoft_sso';

        default:
            return provider;
    }
};
