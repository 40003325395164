import { MutateDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { twoFaApiPaths } from '../paths';
import {
    UseVerifyTFADisablingAppCodeRequest,
    UseVerifyTFADisablingAppCodeResponse,
} from './useVerifyTFADisablingAppCode.types';

export const useVerifyTFADisablingAppCode = () => {
    return useMutateLegacy<MutateDataParams<UseVerifyTFADisablingAppCodeRequest>, UseVerifyTFADisablingAppCodeResponse>(
        twoFaApiPaths.disablingVerifyAppCode
    );
};
