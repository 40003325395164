import { LoadingSpinner } from '@approvalmax/ui';
import { Box, Flex } from '@approvalmax/ui/src/components';
import FieldsSection from 'pages/requestList/components/FieldsSection';
import ActivitySection from 'pages/requestList/containers/card/ActivitySection';
import AddCommentSection from 'pages/requestList/containers/card/AddCommentSection/AddCommentSection';
import AttachmentsSection from 'pages/requestList/containers/card/AttachmentsSection';
import Toolbar from 'pages/requestList/containers/card/Toolbar/Toolbar';
import { FC, memo } from 'react';
import { useGetWorkflowVersionDocumentFields } from 'shared/data/v1';

import { WorkflowSection } from '../../../../card/WorkflowSection';
import {
    useDisplayCurrency,
    useGetExchangeInfo,
    useJournalEntryFieldSettings,
    useNetSuiteCustomFields,
} from '../../NetSuiteRequestCard.hooks';
import {
    CurrencySwitcherContainer,
    FieldsSectionWrap,
    StyledField,
    StyledScrollableArea,
} from '../../NetSuiteRequestCard.styles';
import { isFieldVisible } from '../../NetSuiteRequestCard.utils';
import CurrencySwitcher from '../CurrencySwitcher/CurrencySwitcher';
import NetSuiteCustomFields from '../NetSuiteCustomFields/NetSuiteCustomFields';
import { NetSuiteJournalEntryLines } from '../NetSuiteJournalEntryLines/NetSuiteJournalEntryLines';
import NetSuiteRequestFooter from '../NetSuiteRequestFooter/NetSuiteRequestFooter';
import { messages } from './RequestCardJournalEntry.messages';
import { RequestCardJournalEntryProps } from './RequestCardJournalEntry.types';

export const RequestCardJournalEntry: FC<RequestCardJournalEntryProps> = memo((props) => {
    const { request, className } = props;

    const { data: companyCustomFields } = useNetSuiteCustomFields(request.companyId);

    const { data: workflowVersionDocumentFields, isFetching: isFetchingGetWorkflowVersionDocumentFields } =
        useGetWorkflowVersionDocumentFields({
            query: {
                companyId: request.companyId,
                workflowVersionIds: [request.workflowVersionId],
            },
        });

    const { details } = request;

    const fieldSettings = useJournalEntryFieldSettings(workflowVersionDocumentFields?.data || []);

    const { displayCurrency, onDisplayCurrencyChange } = useDisplayCurrency();
    const { exchangeRate } = useGetExchangeInfo(request.companyId, details.exchangeRate, displayCurrency?.id);

    return (
        <Flex direction='column' spacing='0' wrap={false} className={className}>
            <Toolbar request={request} />

            <StyledScrollableArea scrollResetKey={request.id}>
                {isFetchingGetWorkflowVersionDocumentFields ? (
                    <Box spacing='20'>
                        <LoadingSpinner />
                    </Box>
                ) : (
                    <>
                        <FieldsSectionWrap>
                            {isFieldVisible(fieldSettings.reference) && (
                                <StyledField
                                    title={messages.referenceFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.reference}
                                    hideIfEmpty
                                />
                            )}

                            <StyledField
                                title={messages.transactionNumberFieldText}
                                valueType={FieldsSection.Field.ValueType.String}
                                value={details.transactionNumber}
                                hideIfEmpty
                            />

                            {isFieldVisible(fieldSettings.transactionDate) && (
                                <StyledField
                                    title={messages.transactionDateFieldText}
                                    valueType={FieldsSection.Field.ValueType.Date}
                                    value={details.transactionDate}
                                />
                            )}

                            <StyledField
                                title={messages.postingPeriodFieldText}
                                valueType={FieldsSection.Field.ValueType.String}
                                value={details.postingPeriod}
                                hideIfEmpty
                            />

                            {isFieldVisible(fieldSettings.reversalDate) && (
                                <StyledField
                                    title={messages.reversalDateFieldText}
                                    valueType={FieldsSection.Field.ValueType.Date}
                                    value={details.reversalDate}
                                />
                            )}

                            {isFieldVisible(fieldSettings.reversalDefer) && (
                                <StyledField
                                    title={messages.reversalDeferFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.reversalDefer ? messages.reversalDeferPositiveValue : null}
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.department) && (
                                <StyledField
                                    title={messages.departmentFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.department}
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.class) && (
                                <StyledField
                                    title={messages.classFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.class}
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.location) && (
                                <StyledField
                                    title={messages.locationFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.location}
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.memo) && (
                                <StyledField
                                    title={messages.memoFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.memo}
                                    hideIfEmpty
                                />
                            )}

                            <NetSuiteCustomFields
                                customFields={details.customFields}
                                companyCustomFields={companyCustomFields?.Fields ?? []}
                            />
                        </FieldsSectionWrap>

                        <NetSuiteJournalEntryLines
                            itemLines={details.lines}
                            companyCustomFields={companyCustomFields?.Fields ?? []}
                            documentFields={workflowVersionDocumentFields?.data || []}
                        />

                        {typeof exchangeRate === 'number' && (
                            <CurrencySwitcherContainer>
                                <CurrencySwitcher
                                    request={request}
                                    selectedCurrency={displayCurrency}
                                    requestCurrency={details.currency}
                                    onCurrencyChange={onDisplayCurrencyChange}
                                    companyId={request.companyId}
                                />
                            </CurrencySwitcherContainer>
                        )}
                    </>
                )}

                <NetSuiteRequestFooter
                    request={request}
                    displayCurrency={displayCurrency}
                    exchangeRate={exchangeRate}
                />

                <AttachmentsSection request={request} />

                <WorkflowSection request={request} />

                <ActivitySection request={request} />

                {!request.flags.status.isDraft && <AddCommentSection request={request} />}
            </StyledScrollableArea>
        </Flex>
    );
});

RequestCardJournalEntry.displayName = 'RequestCardJournalEntry';
