import './xeroFieldsSection.scss';

import { errorHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { FC, memo } from 'react';

import AirwallexBatchPaymentRequestCard from './AirwallexBatchPaymentRequestCard/AirwallexBatchPaymentRequestCard';
import BillBatchPaymentRequestCard from './BillBatchPaymentRequestCard/BillBatchPaymentRequestCard';
import ContactRequestCard from './ContactRequestCard/ContactRequestCard';
import { XeroAmaxPayBatchPaymentRequestCard } from './XeroAmaxPayBatchPaymentRequestCard/XeroAmaxPayBatchPaymentRequestCard';
import XeroBillFields from './XeroBillFields/XeroBillFields';
import XeroCreditNotePayableFields from './XeroCreditNotePayableFields/XeroCreditNotePayableFields';
import XeroCreditNoteReceivableFields from './XeroCreditNoteReceivableFields/XeroCreditNoteReceivableFields';
import XeroInvoiceFields from './XeroInvoiceFields/XeroInvoiceFields';
import XeroManualJournalFields from './XeroManualJournalFields/XeroManualJournalFields';
import XeroPurchaseOrderFields from './XeroPurchaseOrderFields/XeroPurchaseOrderFields';
import XeroQuoteFields from './XeroQuoteFields/XeroQuoteFields';

interface XeroFieldsSectionProps {
    request: selectors.types.ExpandedRequest;
}

const XeroFieldsSection: FC<XeroFieldsSectionProps> = memo((props) => {
    const { request } = props;

    if (request.integrationType !== domain.IntegrationType.Xero) {
        throw errorHelpers.formatError();
    }

    switch (request.integrationCode) {
        case domain.IntegrationCode.XeroPo:
            return <XeroPurchaseOrderFields request={request} />;

        case domain.IntegrationCode.XeroQuote:
            return <XeroQuoteFields request={request} />;

        case domain.IntegrationCode.XeroBill:
            return <XeroBillFields request={request} />;

        case domain.IntegrationCode.XeroInvoice:
            return <XeroInvoiceFields request={request} />;

        case domain.IntegrationCode.XeroCreditNotesPayable:
            return <XeroCreditNotePayableFields request={request} />;

        case domain.IntegrationCode.XeroCreditNotesReceivable:
            return <XeroCreditNoteReceivableFields request={request} />;

        case domain.IntegrationCode.XeroContact:
            return <ContactRequestCard request={request} />;

        case domain.IntegrationCode.XeroBillBatchPayment:
            return <BillBatchPaymentRequestCard request={request} />;

        case domain.IntegrationCode.XeroAirwallexBatchPayment:
            return <AirwallexBatchPaymentRequestCard request={request} />;

        case domain.IntegrationCode.XeroAmaxPayBatchPayment:
            return <XeroAmaxPayBatchPaymentRequestCard request={request} />;

        case domain.IntegrationCode.XeroManualJournal:
            return <XeroManualJournalFields request={request} />;

        default:
            throw errorHelpers.notSupportedError();
    }
});

export default XeroFieldsSection;
