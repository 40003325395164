import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.AmaxPayPaymentStatusTag', {
    ready: 'Ready to pay',
    paid: 'Paid',
    failed: 'Failed',
    cancelled: 'Cancelled',
    processing: 'Processing',
    scheduled: 'Scheduled',
    failedTooltip: 'Attempt failed. Please try again.',
});
