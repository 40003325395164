import { FC, memo } from 'react';
import styled, { css } from 'styled-components';

const LegendContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 14px;
    width: 100%;
`;

const LegendItem = styled.div`
    display: flex;
    align-items: center;
    padding-right: 20px;
    margin-bottom: 14px;
`;

const LegendTitle = styled.span`
    margin-right: 10px;
    font-size: 12px;
    color: #5e5c5c;
    font-weight: 600;
`;

const LegendAmount = styled.div<{ warning?: boolean }>`
    color: #000;
    font-weight: 600;
    font-size: 12px;
    ${({ warning }) => (warning ? 'color: #ca5c41;' : '')}
`;

const Square = styled.div<{ backgroundColor?: string }>`
    display: inline-block;
    margin-right: 5px;
    width: 12px;
    height: 12px;

    ${(props) =>
        props.backgroundColor &&
        css`
            background-color: ${props.backgroundColor};
        `}
`;

interface LegendProps {
    legends: Array<{
        color: string;
        title: string;
        amount: string;
        warning?: boolean;
    }>;
}

const Legend: FC<LegendProps> = (props) => {
    const { legends } = props;

    return (
        <LegendContainer>
            {legends.map((legend) => (
                <LegendItem key={legend.title}>
                    <LegendTitle>
                        <Square backgroundColor={legend.color} />

                        {legend.title}
                    </LegendTitle>

                    <LegendAmount warning={Boolean(legend.warning)}>{legend.amount}</LegendAmount>
                </LegendItem>
            ))}
        </LegendContainer>
    );
};

export default memo(Legend);
