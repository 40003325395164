import { ExtractComponentProp } from '@approvalmax/types';
import { Box, Flex, Radio, Spacing, Text } from '@approvalmax/ui/src/components';
import { miscHelpers } from '@approvalmax/utils';
import { FC, memo, useCallback } from 'react';
import { OcrFileContentType } from 'shared/data/v2';

import { messages } from './FileContentType.messages';
import { FileContentTypeProps } from './FileContentType.types';

export const FileContentType: FC<FileContentTypeProps> = memo((props) => {
    const { fileContentType, setFileContentType, disabled } = props;

    const onChangeFileContentType = useCallback<ExtractComponentProp<typeof Radio.Group, 'onChange'>>(
        (fileTypeToUploadOption) => {
            if (miscHelpers.isEnumValue(OcrFileContentType, fileTypeToUploadOption)) {
                setFileContentType(fileTypeToUploadOption);
            }
        },
        [setFileContentType]
    );

    return (
        <Flex spacing='16' size={14} wrap={false} direction='column'>
            <Text font='headline' fontSize='xsmall' fontWeight='medium'>
                {messages.fileToUploadButtonSectionTitle}
            </Text>

            <Radio.Group
                name='fileContentType'
                size='medium'
                value={OcrFileContentType.DocumentPerFile}
                onChange={onChangeFileContentType}
                width='100%'
                disabled={disabled}
            >
                <Flex width='100%' spacing='8' direction='column'>
                    <Box
                        spacing='16'
                        radius='small'
                        color={fileContentType === OcrFileContentType.DocumentPerFile ? 'midnight20' : 'transparent'}
                    >
                        <Radio value={OcrFileContentType.DocumentPerFile}>
                            <Text font='body' fontSize='medium' fontWeight='medium'>
                                {messages.oneDocumentPerFileButtonTitle}
                            </Text>

                            <Spacing height={4} />

                            <Text font='body' fontSize='small' color='midnight70'>
                                {messages.oneDocumentPerFileDescription}
                            </Text>
                        </Radio>
                    </Box>

                    <Box
                        spacing='16'
                        radius='small'
                        color={fileContentType === OcrFileContentType.MultiDocumentFile ? 'midnight20' : 'transparent'}
                    >
                        <Radio value={OcrFileContentType.MultiDocumentFile}>
                            <Text font='body' fontSize='medium' fontWeight='medium'>
                                {messages.oneDocumentPerPageButtonTitle}
                            </Text>

                            <Spacing height={4} />

                            <Text font='body' fontSize='small' color='midnight70'>
                                {messages.oneDocumentPerPageButtonDescription}
                            </Text>
                        </Radio>
                    </Box>
                </Flex>
            </Radio.Group>
        </Flex>
    );
});

FileContentType.displayName = 'FileContentType';
