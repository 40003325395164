import { Grid } from '@approvalmax/ui/src/components';
import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';

export const BankAccountInfoLoading = memo(() => {
    return (
        <Grid gridAutoRows='max-content' gap={12}>
            <Grid gridTemplateColumns='auto max-content'>
                <Grid gap={2}>
                    <Skeleton width={75} height={12} />

                    <Skeleton width={100} height={12} />
                </Grid>

                <Skeleton width={26} height={26} />
            </Grid>

            <Grid gap={2}>
                <Skeleton width={75} height={12} />

                <Skeleton width={100} height={12} />
            </Grid>

            <Grid gap={2}>
                <Skeleton width={75} height={12} />

                <Skeleton width={100} height={12} />
            </Grid>
        </Grid>
    );
});

BankAccountInfoLoading.displayName = 'BankAccountInfoLoading';
