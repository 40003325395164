import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages(
    'pages.requestList.AirwallexBatchPaymentRequestCard.BillsInformationSection.Disclaimer',
    {
        text:
            'The final total amount of the batch payment may include a fee, which is subject to Airwallex pricing conditions.' +
            '{br}{br}' +
            'If the source currency differs from the bill currency, Airwallex FX rates will be applied to calculate final amount in the source currency.' +
            '{br}{br}' +
            'For further pricing details, please contact your Airwallex account manager.',
    }
);
