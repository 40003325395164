import { Collapse, Flex, Grid, Progress, Text } from '@approvalmax/ui/src/components';
import { memo } from 'react';

import { messages } from './AuditTrail.messages';
import { AuditTrailProps } from './AuditTrail.types';
import { Item } from './components/Item/Item';

export const AuditTrail = memo<AuditTrailProps>((props) => {
    const { id, isLoading, items } = props;

    const noData = items.length === 0;

    return (
        <Collapse
            name={messages.title}
            localStorageKey={id}
            spacing='0 24 16 24'
            radius='small'
            bordered
            collapsed={noData}
        >
            {isLoading ? (
                <Flex alignItems='center' justifyContent='center'>
                    <Progress shape='circle' />
                </Flex>
            ) : (
                <Grid gap={24}>
                    {items.map((item) => (
                        <Item key={item.id} data={item} />
                    ))}

                    {noData && (
                        <Text font='body' fontSize='medium'>
                            {messages.noData}
                        </Text>
                    )}
                </Grid>
            )}
        </Collapse>
    );
});

AuditTrail.displayName = 'AuditTrail';
