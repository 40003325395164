import { intl } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { backend, domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import OpenInSourceLink from 'pages/requestList/components/OpenInSourceLink/OpenInSourceLink';
import { FC, memo } from 'react';
import styled from 'styled-components';

import SectionSeparator from '../../../components/SectionSeparator/SectionSeparator';
import { FinancialDetailsSection } from './components/FinancialDetailsSection/FinancialDetailsSection';
import PurchaseSettingsSection from './components/PurchaseSettingsSection/PurchaseSettingsSection';
import RequestContactAddressSection from './components/RequestContactAddressSection/RequestContactAddressSection';
import RequestContactInformationSection from './components/RequestContactInformationSection/RequestContactInformationSection';
import RequestContactPersonSection from './components/RequestContactPersonSection/RequestContactPersonSection';
import SalesSettingsSection from './components/SalesSettingsSection/SalesSettingsSection';
import TaxSection from './components/TaxSection/TaxSection';
import { isAddressEmpty, isPurchaseSettingsEmpty, isSalesSettingsEmpty } from './ContactRequestCard.utils';

const i18nPrefix = 'requestList/containers/xero/ContactRequestCard/ContactRequestCard';

interface ContactRequestCardProps {
    request: domain.XeroContactRequest;
}

const Root = styled.div`
    padding: 20px 60px;
`;

const SettingContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ContactRequestCard: FC<ContactRequestCardProps> = (props) => {
    const { request } = props;

    const contact = request.details || {};

    const company = useSelector((state) => selectors.company.findCompanyById(state, request.companyId));

    const isUKCompany = company?.integration?.countryCode === backend.OrganisationVersion.UK;

    const isPostalAddressEmpty = isAddressEmpty(contact.postalAddress);
    const isMailingAddressEmpty = isAddressEmpty(contact.mailingAddress);

    const showMailingAddress =
        !isMailingAddressEmpty || (!isPostalAddressEmpty && contact.mailingAddress?.sameAsPostalAddress);

    const hasSalesSettings = !isSalesSettingsEmpty(contact);
    const hasPurchaseSettings = !isPurchaseSettingsEmpty(contact);

    const showSettings = hasSalesSettings || hasPurchaseSettings;

    return (
        <Root>
            <RequestContactPersonSection contact={contact} />

            <RequestContactInformationSection contact={contact} />

            {!isPostalAddressEmpty && (
                <>
                    <RequestContactAddressSection
                        address={contact.postalAddress}
                        title={intl.formatMessage({
                            id: `${i18nPrefix}.postalAddress`,
                            defaultMessage: 'POSTAL ADDRESS',
                        })}
                    />

                    <SectionSeparator />
                </>
            )}

            {showMailingAddress && (
                <>
                    <RequestContactAddressSection
                        sameAsPostal={contact.mailingAddress?.sameAsPostalAddress}
                        address={contact.mailingAddress}
                        title={intl.formatMessage({
                            id: `${i18nPrefix}.streetAddress`,
                            defaultMessage: 'STREET ADDRESS',
                        })}
                    />

                    <SectionSeparator />
                </>
            )}

            {showSettings && (
                <>
                    <SettingContainer>
                        {hasSalesSettings && <SalesSettingsSection contact={contact} />}

                        {hasPurchaseSettings && <PurchaseSettingsSection contact={contact} />}
                    </SettingContainer>

                    <SectionSeparator />
                </>
            )}

            <TaxSection contact={contact} />

            <FinancialDetailsSection contact={contact} isUKCompany={isUKCompany} />

            <OpenInSourceLink
                url={contact.url}
                integrationType={contact.integrationType}
                integrationCode={contact.integrationCode}
            />
        </Root>
    );
};

export default memo(ContactRequestCard);
