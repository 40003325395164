import { recoilHelpers } from '@approvalmax/utils';
import { atom } from 'recoil';

import { AddonPromotionType } from './pageVisiblePromotions';

interface PromotionInfo {
    timesClosed: number;
    lastCloseDate: string | null;
    forcePopupHasBeenShown: boolean;
}

interface AddonPromotionInfoState {
    [AddonPromotionType.Pay]: PromotionInfo;
    [AddonPromotionType.Capture]: PromotionInfo;
}

const defaultPromotionInfo: PromotionInfo = {
    timesClosed: 0,
    lastCloseDate: null,
    forcePopupHasBeenShown: false,
};

const defaultAddonPromotionInfoState: AddonPromotionInfoState = {
    [AddonPromotionType.Pay]: defaultPromotionInfo,
    [AddonPromotionType.Capture]: defaultPromotionInfo,
};

export const addonPromotionInfoState = atom<AddonPromotionInfoState>({
    key: 'addonPromotionInfo',
    default: defaultAddonPromotionInfoState,
    effects: [recoilHelpers.connectToLocalStorage('addonPromotionInfo')],
});
