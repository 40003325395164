import { Collapse, Flex, List, Spacing } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import { memo } from 'react';
import bemFactory from 'react-bem-factory';
import { useSelector } from 'react-redux';
import { useRecoilValue } from 'recoil';
import { IntegrationHeader } from 'shared/components';
import { activeCompanyIdState } from 'shared/states';

import WorkflowListItem from '../WorkflowListItem/WorkflowListItem';
import { useWorkflowListItem } from '../WorkflowListItem/WorkflowListItem.hooks';
import { messages } from './NetSuiteWorkflowList.messages';

const qa = bemFactory.qa('wfl-net-suite-card');

export const NetSuiteWorkflowList = memo(() => {
    const companyId = useRecoilValue(activeCompanyIdState);
    const company = useSelector((state: State) => selectors.company.getCompanyById(state, companyId));
    const workflows = useSelector((state: State) =>
        selectors.template.getIntegrationTemplatesByCompanyId(state, companyId)
    );

    const isRAAvailable = company.betaFeatures.includes(domain.CompanyBetaFeature.NetSuiteRA);
    const isJournalEntryAvailable = company.betaFeatures.includes(domain.CompanyBetaFeature.NetSuiteJournalEntry);
    const isVendorAvailable = company.betaFeatures.includes(domain.CompanyBetaFeature.NetSuiteVendor);

    const payableWorkflows = useWorkflowListItem(workflows, [
        {
            code: domain.IntegrationCode.NetSuitePO,
        },
        {
            code: domain.IntegrationCode.NetSuiteBill,
        },
        {
            code: domain.IntegrationCode.NetSuiteExpenseReport,
        },
        {
            code: domain.IntegrationCode.NetSuiteVRA,
        },
        {
            code: domain.IntegrationCode.NetSuiteBillPayment,
        },
    ]);

    const receivableWorkflows = useWorkflowListItem(workflows, [
        {
            code: domain.IntegrationCode.NetSuiteSalesOrder,
        },
        {
            code: domain.IntegrationCode.NetSuiteRA,
            available: isRAAvailable,
        },
        {
            code: domain.IntegrationCode.NetSuiteInvoice,
        },
    ]);

    const otherWorkflows = useWorkflowListItem(workflows, [
        {
            code: domain.IntegrationCode.NetSuiteJournalEntry,
            available: isJournalEntryAvailable,
        },
        {
            code: domain.IntegrationCode.NetSuiteVendor,
            available: isVendorAvailable,
        },
    ]);

    return (
        <>
            <IntegrationHeader company={company} />

            <Flex direction='column' spacing='16'>
                {payableWorkflows.length > 0 && (
                    <Collapse
                        name={messages.payableGroup}
                        spacing='0 24 0 52'
                        localStorageKey={`${company.id}-payable`}
                        data-qa={qa('list-group-item-payable')}
                        radius='small'
                    >
                        <List divider removeLastDivider>
                            {payableWorkflows.map((workflowItemProps) => (
                                <List.Item key={workflowItemProps.workflow.integrationCode}>
                                    <WorkflowListItem
                                        data-qa={qa(`list-item-${workflowItemProps.workflow.integrationCode}`)}
                                        {...workflowItemProps}
                                    />
                                </List.Item>
                            ))}
                        </List>
                    </Collapse>
                )}

                {receivableWorkflows.length > 0 && (
                    <Collapse
                        name={messages.receivableGroup}
                        spacing='0 24 0 52'
                        localStorageKey={`${company.id}-receivable`}
                        data-qa={qa('list-group-item-receivable')}
                        radius='small'
                    >
                        <List divider removeLastDivider>
                            {receivableWorkflows.map((workflowItemProps) => (
                                <List.Item key={workflowItemProps.workflow.integrationCode}>
                                    <WorkflowListItem
                                        data-qa={qa(`list-item-${workflowItemProps.workflow.integrationCode}`)}
                                        {...workflowItemProps}
                                    />
                                </List.Item>
                            ))}
                        </List>
                    </Collapse>
                )}

                {otherWorkflows.length > 0 && (
                    <Collapse
                        name={messages.otherGroup}
                        spacing='0 24 0 52'
                        localStorageKey={`${company.id}-other`}
                        data-qa={qa('list-group-item-other')}
                        radius='small'
                    >
                        <List divider removeLastDivider>
                            {otherWorkflows.map((workflowItemProps) => (
                                <List.Item key={workflowItemProps.workflow.integrationCode}>
                                    <WorkflowListItem
                                        data-qa={qa(`list-item-${workflowItemProps.workflow.integrationCode}`)}
                                        {...workflowItemProps}
                                    />
                                </List.Item>
                            ))}
                        </List>
                    </Collapse>
                )}
            </Flex>

            <Spacing height={16} />
        </>
    );
});

NetSuiteWorkflowList.displayName = 'NetSuiteWorkflowList';
