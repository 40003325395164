import { ExtractComponentProp } from '@approvalmax/types';
import { Avatar, Box, Button, Flex, Popup, Text, TextField } from '@approvalmax/ui/src/components';
import { errorHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCompanyBasedOnUrl } from 'shared/hooks/useCompanyBasedOnUrl';

import { inviteUsers } from '../../../../actions';
import { getActiveInviteUsers } from '../../../../selectors/pageSelectors';
import { messages } from '../../InviteUsersPopup.messages';
import { UsersList } from '../UsersList/UsersList';

export const Content = memo(() => {
    const dispatch = useDispatch();
    const popupData = useSelector(getActiveInviteUsers);
    const company = useCompanyBasedOnUrl();
    const profile = useSelector(selectors.profile.getProfileUser);
    const allUsers = useSelector(selectors.user.getUsers);
    const [checkedUsers, setCheckedUsers] = useState<string[]>([]);
    const [rolesMap, setRolesMap] = useState(
        (popupData?.users || []).reduce<Record<string, domain.CompanyUserRole>>((acc, userId) => {
            const role = selectors.company.getCompanyUserRole(company, userId);

            acc[userId] = role === domain.CompanyUserRole.None ? domain.CompanyUserRole.Participant : role;

            return acc;
        }, {})
    );

    const [commentText, setCommentText] = useState('');

    const enrichedUsers = useMemo(
        () =>
            (popupData?.users || []).map((userId) => ({
                id: userId,
                role: rolesMap[userId],
            })),
        [popupData?.users, rolesMap]
    );

    useEffect(() => {
        if (!popupData) return;

        setCheckedUsers(popupData.preselected.length > 0 ? popupData.preselected : popupData.users);
    }, [popupData, setCheckedUsers]);

    const doInviteUsers = () => {
        const users = checkedUsers.map((id) => {
            const user = allUsers.find((user) => user.id === id);

            if (!user) {
                throw errorHelpers.throwNotFoundError('User not found.');
            }

            return user;
        });

        dispatch(
            inviteUsers({
                company,
                invitationMessage: commentText,
                users,
                userEmailsWithRoles: rolesMap,
            })
        );
    };

    const handleChangeRole = useCallback<ExtractComponentProp<typeof UsersList, 'onChangeRole'>>((userId, role) => {
        setRolesMap((prevState) => ({
            ...prevState,
            [userId]: role,
        }));
    }, []);

    return (
        <>
            <Popup.Header
                title={messages.title({ companyName: company.displayName })}
                actions={
                    <Box width='max-content'>
                        <Button
                            size='medium'
                            disabled={checkedUsers.length === 0}
                            onClick={doInviteUsers}
                            color='blue80'
                        >
                            {messages.inviteUsers}
                        </Button>
                    </Box>
                }
            />

            <Popup.Body>
                <Flex direction='column' spacing='24'>
                    <Text font='body' fontSize='medium'>
                        {company.flags.isTrialLicense
                            ? messages.descriptionTextNoInviteMessage
                            : messages.descriptionText}
                    </Text>

                    {!company.flags.isTrialLicense && (
                        <Flex>
                            <Flex shrink={0}>
                                <Avatar
                                    alt={`${profile.firstName} ${profile.lastName}`}
                                    src={profile.avatar}
                                    size={32}
                                    color='blue10'
                                />
                            </Flex>

                            <Flex grow={1}>
                                <TextField
                                    onChange={setCommentText}
                                    name={commentText}
                                    multiline
                                    maxLength={255}
                                    initFocus
                                    placeholder={messages.addComment}
                                />
                            </Flex>
                        </Flex>
                    )}

                    <UsersList
                        users={enrichedUsers}
                        checkedUsers={checkedUsers}
                        setCheckedUsers={setCheckedUsers}
                        onChangeRole={handleChangeRole}
                    />
                </Flex>
            </Popup.Body>
        </>
    );
});

Content.displayName = 'Content';
