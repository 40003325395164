import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { requestsReportsApiPaths } from '../paths';
import { CreateManualJournalFromPurchaseOrder } from './useCreateManualJournalFromPurchaseOrder.types';

export const useCreateManualJournalFromPurchaseOrder = (
    mutationOptions?: RequestMutationOptions<CreateManualJournalFromPurchaseOrder>
) => {
    return useMutate(requestsReportsApiPaths.createManualJournalFromPurchaseOrder, {
        mutationOptions,
        apiVersion: 'v1',
        method: 'post',
        mapToCamelCase: true,
    });
};
