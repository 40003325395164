import { Checkbox, TransparentButton } from '@approvalmax/ui';
import { useSelector } from 'modules/react-redux';
import { FC, memo, useCallback } from 'react';
import { getGrnStatusText } from 'shared/helpers/getGrnStatusText/getGrnStatusText';
import { getPath, Path } from 'urlBuilder';

import { getActiveRequest } from '../../../../../../../../../../../selectors/pageSelectors';
import { useChangeMatchingPopupContext } from '../../../../ChangeMatchingPopupContext';
import { useAmountTypeLabel, useBillRemaining } from './ChangeMatchingPopupItem.hooks';
import { messages } from './ChangeMatchingPopupItem.messages';
import {
    Amount,
    CheckboxContainer,
    Container,
    Content,
    GreyText,
    InfoText,
    InfoTextReference,
    Line,
    LineReference,
    LockedIcon,
    StyledLink,
} from './ChangeMatchingPopupItem.styles';
import { ChangeMatchingPopupItemProps } from './ChangeMatchingPopupItem.types';
import AdditionalInformation from './components/AdditionalInformation/AdditionalInformation';

const ChangeMatchingPopupItem: FC<ChangeMatchingPopupItemProps> = (props) => {
    const { totalAmount, isBilled, id, name, currency, date, requester, companyId, reference, grnStatus } = props;

    const {
        getAllocatedAmountById,
        onChangeAllocatedAmount,
        onSelectPO,
        getIsMatchedById,
        getIsWarningById,
        billId,
        matchingData: { totalAllocatedAmountToBilledPOs, totalAmount: billTotalAmount },
        totalAllocatedAmount,
    } = useChangeMatchingPopupContext();

    const { billMatchingSettings, xeroPurchaseOrderSettings } = useSelector(getActiveRequest).company;
    const billRemaining = useBillRemaining(billTotalAmount, totalAllocatedAmount, totalAllocatedAmountToBilledPOs);
    const amount = useAmountTypeLabel(billMatchingSettings?.amountType, totalAmount, currency);

    const isMatched = getIsMatchedById(id);
    const allocatedAmount = getAllocatedAmountById(id);
    const isWarning = getIsWarningById(id);
    const grnStatusText = xeroPurchaseOrderSettings?.isGrnEnabled ? getGrnStatusText(grnStatus) : null;

    const onChange = useCallback(() => {
        onSelectPO(id, !isMatched);
    }, [id, isMatched, onSelectPO]);

    const handleChangeAllocatedAmount = useCallback(
        (val: number) => {
            onChangeAllocatedAmount(id, val);
        },
        [id, onChangeAllocatedAmount]
    );

    return (
        <Container>
            <CheckboxContainer>
                <TransparentButton disabled={isBilled} execute={onChange}>
                    {isBilled ? <LockedIcon /> : <Checkbox checked={isMatched} />}
                </TransparentButton>
            </CheckboxContainer>

            <Content>
                <Line>
                    <StyledLink href={getPath(Path.request, id, companyId)} targetBlank>
                        {name}
                    </StyledLink>

                    <Amount isBilled={isBilled}>{amount}</Amount>
                </Line>

                <Line>
                    <GreyText>{messages.date}</GreyText>

                    <InfoText>{date}</InfoText>

                    <GreyText>{messages.requester}</GreyText>

                    <InfoText>{requester.displayName}</InfoText>

                    {grnStatusText && (
                        <>
                            <GreyText>{messages.grnStatusLabel}</GreyText>

                            <InfoText>{grnStatusText}</InfoText>
                        </>
                    )}
                </Line>

                {reference && (
                    <LineReference>
                        <GreyText>{messages.reference}</GreyText>

                        <InfoTextReference title={reference}>{reference}</InfoTextReference>
                    </LineReference>
                )}

                <AdditionalInformation
                    onChangeAllocatedAmount={handleChangeAllocatedAmount}
                    isMatched={isMatched}
                    allocatedAmount={allocatedAmount}
                    poId={id}
                    currency={currency}
                    totalAmount={totalAmount}
                    billId={billId}
                    billRemaining={billRemaining}
                    isBilled={isBilled}
                    warning={isWarning}
                />
            </Content>
        </Container>
    );
};

export default memo(ChangeMatchingPopupItem);
