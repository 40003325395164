import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.NetSuiteRequestCard.RequestCardJournalEntry', {
    referenceFieldText: 'Reference',
    transactionNumberFieldText: 'Transaction number',
    transactionDateFieldText: 'Date',
    postingPeriodFieldText: 'Posting period',
    reversalDateFieldText: 'Reversal date',
    reversalDeferFieldText: 'Reversal defer',
    departmentFieldText: 'Department',
    classFieldText: 'Class',
    locationFieldText: 'Location',
    memoFieldText: 'Memo',
    reversalDeferPositiveValue: 'Yes',
});
