import { Button, Flex, Popup, Spacing, Text } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { useDispatch } from 'modules/react-redux';
import { memo, useCallback } from 'react';
import bemFactory from 'react-bem-factory';
import { useSelector } from 'react-redux';

import { closeActiveVersionConflictPopup, saveTemplate } from '../../actions';
import { getActiveTemplate, getActiveVersionConflict } from '../../selectors/pageSelectors';
import { messages } from './VersionConflictPopup.messages';

const qa = bemFactory.qa('wfc-settings-popup');

const VersionConflictPopup = memo(() => {
    const dispatch = useDispatch();
    const popupData = useSelector(getActiveVersionConflict);
    const template = useSelector(getActiveTemplate)!;
    const expandedTemplate = selectors.template.expandTemplate(template);

    const closePopup = useCallback(() => dispatch(closeActiveVersionConflictPopup()), [dispatch]);
    const onToggle = useCallback((open: boolean) => !open && closePopup(), [closePopup]);

    const onOverwriteButtonClick = useCallback(() => {
        const enabled = popupData?.workflowIsJustDeactivated
            ? false
            : popupData?.workflowIsJustActivated || expandedTemplate.enabled;

        dispatch(
            saveTemplate(
                {
                    ...expandedTemplate,
                    enabled,
                    comment: popupData?.newTemplateComment || '',
                },
                { forceOverwrite: true }
            )
        );
        closePopup();
    }, [expandedTemplate, popupData, dispatch, closePopup]);

    return (
        <Popup closable={false} open={popupData?.isOpen} onToggle={onToggle} size='small'>
            <Popup.Header title={messages.title} />

            <Popup.Body spacing='32'>
                <Text font='body' fontSize='medium'>
                    {messages.description}
                </Text>

                <Spacing height={32} />

                <Flex spacing='32' justifyContent='end'>
                    <Button
                        onClick={onOverwriteButtonClick}
                        color='red40'
                        size='large'
                        data-qa={qa('overwrite-button')}
                    >
                        {messages.buttonOverwrite}
                    </Button>

                    <Button onClick={closePopup} color='blue80' size='large' data-qa={qa('cancel-button')}>
                        {messages.buttonCancel}
                    </Button>
                </Flex>
            </Popup.Body>
        </Popup>
    );
});

export default VersionConflictPopup;
