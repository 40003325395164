import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { companiesApiPaths } from '../paths';
import { ReplacementOffboardParticipantWithAllRequests } from './useReplacementOffboardParticipantWithAllRequests.types';

export const useReplacementOffboardParticipantWithAllRequests = (
    mutationOptions?: RequestMutationOptions<ReplacementOffboardParticipantWithAllRequests>
) => {
    return useMutate(companiesApiPaths.offboardParticipantWithAllRequestsReplacement, {
        mutationOptions,
        apiVersion: 'v1',
    });
};
