import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { matchingApiPaths } from '../paths';
import { GetMatchingInfoV2 } from './useGetMatchingInfoV2.types';

export const useGetMatchingInfoV2 = (
    params?: RequestParams<GetMatchingInfoV2>,
    queryOptions?: RequestQueryOptions<GetMatchingInfoV2>
) =>
    useGet(matchingApiPaths.getMatchingInfoV2, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetMatchingInfoV2['params']['query']>(
                { companyId: params?.query?.companyId },
                queryOptions
            ),
        },
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
