import { defineMessages } from 'react-intl';

const i18nPrefix = 'requestList/components/card/Toolbar/ContactInfo';

export const messages = defineMessages({
    accountNumber: {
        id: `${i18nPrefix}.accountNumber`,
        defaultMessage: `Account Number: {accountNumber}`,
    },
    taxNumber: {
        id: `${i18nPrefix}.taxNumber`,
        defaultMessage: `Tax ID: {taxNumber}`,
    },
});
