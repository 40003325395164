import { PayAmLogo } from '@approvalmax/ui';
import { useMemo } from 'react';
import bemFactory from 'react-bem-factory';
import { getPath, Path } from 'urlBuilder';

import { messages } from './PayMenu.messages';
import { PayMenuProps } from './PayMenu.types';

const qa = bemFactory.qa('payDropdown');

export const usePayLinkItems = (company: PayMenuProps['company']) => {
    const isAdvancedUser = company?.flags.isAuditor || company?.flags.isManager || company?.flags.isWorkflowManager;

    return useMemo(
        () => [
            {
                id: 'pay',
                name: messages.payMenu,
                startIcon: PayAmLogo,
                dataQa: qa(),
                subItems: [
                    {
                        id: Path.wallets,
                        name: messages.wallets,
                        available: isAdvancedUser,
                        to: getPath(Path.wallets, company.id),
                        dataQa: qa('navigationItem'),
                        dataQaName: 'wallets',
                    },
                ],
            },
        ],
        [company.id, isAdvancedUser]
    );
};
