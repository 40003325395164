import { RequestMutationOptions } from '@approvalmax/data';
import { routerHelpers } from '@approvalmax/utils';
import { useQueryClient } from '@tanstack/react-query';
import { useMutate } from 'shared/data';

import { delegationsApiPaths } from '../paths';
import { CreateDelegations } from './useCreateDelegations.types';

export const useCreateDelegations = (mutationOptions?: RequestMutationOptions<CreateDelegations>) => {
    const queryClient = useQueryClient();

    return useMutate(delegationsApiPaths.delegations, {
        mutationOptions: {
            ...mutationOptions,
            onSuccess: (_data, { body }) => {
                (body || []).forEach(({ companyId }) => {
                    void queryClient.invalidateQueries([
                        routerHelpers.pathToUrl(delegationsApiPaths.companyDelegations, {
                            companyId,
                        }),
                    ]);
                });
            },
        },
        apiVersion: 'v2',
    });
};
