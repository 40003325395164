import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data/api';

import { requestsApiPaths } from '../paths';
import { GetNetSuiteBillPaymentBill } from './useGetNetSuiteBillPaymentBill.types';

export const useGetNetSuiteBillPaymentBill = (
    params?: RequestParams<GetNetSuiteBillPaymentBill>,
    queryOptions?: RequestQueryOptions<GetNetSuiteBillPaymentBill>
) => {
    return useGet(requestsApiPaths.netSuiteBillPaymentBill, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetNetSuiteBillPaymentBill['params']['query']>(
                {
                    payeeId: params?.query?.payeeId,
                    accountId: params?.query?.accountId,
                    currencyId: params?.query?.currencyId,
                },
                queryOptions
            ),
        },
        apiVersion: 'v2',
    });
};
