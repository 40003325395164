import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { companiesApiPaths } from '../paths';
import { GetQuantityOfAllOpenRequestsWithParticipantDecisionRequired } from './useGetQuantityOfAllOpenRequestsWithParticipantDecisionRequired.types';

export const useGetQuantityOfAllOpenRequestsWithParticipantDecisionRequired = (
    params?: RequestParams<GetQuantityOfAllOpenRequestsWithParticipantDecisionRequired>,
    queryOptions?: RequestQueryOptions<GetQuantityOfAllOpenRequestsWithParticipantDecisionRequired>
) => {
    return useGet(companiesApiPaths.getQuantityOfAllOpenRequestsWithParticipantDecisionRequired, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetQuantityOfAllOpenRequestsWithParticipantDecisionRequired['params']['query']>(
                {
                    userId: params?.query?.userId,
                    companyId: params?.query?.companyId,
                },
                queryOptions
            ),
        },
        apiVersion: 'v1',
    });
};
