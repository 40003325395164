import FieldEntry from 'pages/requestList/components/FieldEntry';
import styled from 'styled-components';

export const FieldSection = styled.div`
    display: flex;
    margin-bottom: 30px;
`;

export const NameField = styled(FieldEntry)`
    width: 25%;
`;

export const EmailField = styled(FieldEntry)`
    width: 75%;
`;
