import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { integrationApiPaths } from '../paths';
import { GetNetSuiteVendorCategories } from './useGetNetSuiteVendorCategories.types';

export const useGetNetSuiteVendorCategories = (
    params?: RequestParams<GetNetSuiteVendorCategories>,
    queryOptions?: RequestQueryOptions<GetNetSuiteVendorCategories>
) => {
    return useGet(integrationApiPaths.getNetSuiteVendorCategories, {
        params,
        queryOptions: {
            enabled: enabledByParams<GetNetSuiteVendorCategories['params']['query']>(
                {
                    companyId: params?.query?.companyId,
                },
                queryOptions
            ),
            ...queryOptions,
        },
        apiVersion: 'v1',
        method: 'get',
        mapToCamelCase: true,
    });
};
