import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { store } from 'modules/store';
import moment from 'moment';

import { initTitorial } from './actions';
import { defineFunctions } from './helpers';

export const chameleonService = {
    init() {
        const state = store.getState();
        const me = selectors.profile.getProfileUser(state);
        const profile = selectors.profile.getProfile(state);
        const isAdmin = selectors.profile.isManagerAtAnyOrganization(state);

        if (!profile.isTrialUser || !isAdmin) {
            return;
        }

        const integrations = selectors.integration.getIntegrations(state);

        const has_xero = integrations.some((item) => item.integrationType === domain.IntegrationType.Xero);
        const has_qbo = integrations.some((item) => item.integrationType === domain.IntegrationType.QBooks);

        defineFunctions();
        store.dispatch(initTitorial());

        const companies = selectors.company.getCompanies(state);

        const expirationDate = companies
            .reduce((acc, company) => {
                const expires = moment.utc(company.licenseExpires!);

                return expires.isBefore(acc) ? expires : acc;
            }, moment.utc())
            .toISOString();

        const isOwner = companies.some((l) => l.author === me.id);

        const hasPaidOrg = companies.some((company) => company.flags.isPaid);

        (function (t: any, n: any, o: any) {
            let a = 'chmln',
                e = 'adminPreview',
                c = 'setup identify alias track clear set show on off custom help _data'.split(' ');

            if ((n[a] || (n[a] = {}), n[a][e] && (n[a][e] = !1), !n[a].root)) {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                (n[a].accountToken = o), (n[a].location = n.location.href.toString()), (n[a].now = new Date());

                for (let s = 0; s < c.length; s++) {
                    (function () {
                        let t = (n[a][c[s] + '_a'] = []);

                        n[a][c[s]] = function () {
                            // eslint-disable-next-line prefer-rest-params
                            t.push(arguments as never);
                        };
                    })();
                }

                let i = t.createElement('script');

                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                (i.src = 'https://fast.trychameleon.com/messo/' + o + '/messo.min.js'),
                    (i.async = !0),
                    t.head.appendChild(i);

                i.onload = function () {
                    window.chameleon.identifyData = {
                        created: profile.createdDate,
                        name: me.firstName,
                        role: isOwner ? 'Owner' : 'Administrator',

                        latest_activated_workflow_name: window.chameleon.get_latest_activated_workflow_name(),
                        latest_activated_workflow_gl: window.chameleon.get_latest_activated_workflow_gl(),
                        latest_activated_workflow_creation_type:
                            window.chameleon.get_latest_activated_workflow_creation_type(),
                        server_url: window.location.origin,
                        oldest_org: window.chameleon.oldest_org()?.id,
                        org_id: window.chameleon.get_org_id(),

                        company: {
                            uid: profile.account?.accountId,
                            created: profile.createdDate,
                            trial_ends: expirationDate,
                        },
                        has_xero,
                        has_qbo,
                        lifecycle: profile.isTrialUser ? 'trial' : 'paid',
                        ['company.created_date']: window.chameleon.oldest_org()?.createdDate,
                        has_paid_org: hasPaidOrg,

                        ...window.chameleon.identifyData,
                    };

                    window.chameleon.profilId = profile.id;
                    window.chameleon.identify = () =>
                        window.chmln.identify(window.chameleon.profilId, window.chameleon.identifyData);
                    window.chameleon.identify();
                    window.chameleon.show_workflow_onboarding_checklist();
                };
            }
        })(document, window, 'SqDDCpIqymNZ1HvPkwPD4eHsKkHyRSy8IePygERyGoYLUu-1JVDOw-Cg9Sixv9du5Pj5Yv');
    },
};
