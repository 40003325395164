import { Mods, mods } from '@approvalmax/theme';
import { font, LoadingSpinner, TransparentButton, uAbsolute, uFlexCenter, uTextEllipsis } from '@approvalmax/ui';
import { StyledMods } from '@styled-kit/mods';
import { AttachmentMiniIcon, BubbleCloseIcon, DragNDropIcon } from 'modules/sprites';
import styled from 'styled-components';

export const Root = styled.span`
    border-top: 1px solid #e4e4e4;
    padding: 6px 60px 8px 60px;
    position: relative;
    display: block;
`;

export const MainRow = styled.div`
    display: flex;
`;

export const MainColumn = styled.div`
    flex: 1;
    overflow: hidden;
`;

export const AttachButton = styled.div<StyledMods<Mods<'active' | 'disabled'>>>`
    margin-left: 15px;
    flex: none;
    width: 26px;
    height: 26px;
    fill: #4f8a5f;
    cursor: pointer;
    ${uFlexCenter};

    ${mods.disabled.true`
        fill: #a8a8a8;
        cursor: default;

    `}

    ${mods.active.false`
        fill: #a8a8a8;
    `}
`;

export const SendButton = styled.div<StyledMods<Mods<'disabled'>>>`
    margin-left: 15px;
    flex: none;
    width: 26px;
    height: 26px;
    fill: #4f8a5f;
    ${uFlexCenter};
    cursor: pointer;

    ${mods.disabled.true`
        fill: #a8a8a8;
        cursor: default;
    `}
`;

export const DragContent = styled.div`
    flex: 1;
    ${uFlexCenter};
    flex-flow: column;
    border: 2px dashed #a0a1a1;
    border-radius: 5px;
`;

export const DragOverlay = styled.div<StyledMods<Mods<'active' | 'hide'>>>`
    padding: 30px 60px;
    background: #fff;
    z-index: 10;
    user-select: none;
    display: flex;
    border-top: 1px solid #e4e4e4;
    color: #a0a1a1;

    ${uAbsolute({ left: 0, right: 0, bottom: 0, height: 200 })}

    ${mods.hide.true`
        display: none;
    `}

    ${mods.active.true`
        color: #4c8a5c;

        ${DragContent} {
            border-color: #4c8a5c;
        }
    `}
`;

export const DragIcon = styled(DragNDropIcon)`
    fill: currentColor;
    margin-bottom: 5px;
`;

export const DragText = styled.div`
    ${font(14, null, 'semibold')};
`;

export const AttachmentsList = styled.div`
    display: flex;
    flex-flow: column;
    margin-top: 4px;

    & > :not(:last-child) {
        margin-bottom: 1px;
    }
`;

export const AttachmentItemIcon = styled(AttachmentMiniIcon)`
    fill: currentColor;
    color: #a0a1a1;
    margin-right: 5px;
    padding-top: 1px;
`;

export const AttachmentItemText = styled.div`
    ${font(12, '#000')};
    margin-right: 5px;
    flex: 1;
    ${uTextEllipsis};
`;

export const AttachmentItemDeleteButtonWrapper = styled(TransparentButton)`
    display: flex;
    align-items: center;
`;

export const AttachmentItemDeleteButton = styled(BubbleCloseIcon)`
    fill: currentColor;
    color: #cacccd;
    margin: 5px;
`;

export const AttachmentItem = styled.div`
    display: flex;
    align-items: center;
    height: 24px;
    border-radius: 4px;
    padding: 0 2px 0 6px;
    overflow: hidden;
    cursor: pointer;

    &:hover {
        background: #f3f3f3;

        ${AttachmentItemIcon} {
            color: #4c8a5c;
        }

        ${AttachmentItemText} {
            ${font(12, '#477753')};
        }

        ${AttachmentItemDeleteButton} {
            color: #384650;
        }
    }
`;

export const NewAttachmentItem = styled.div`
    display: flex;
    align-items: center;
    height: 24px;
    border-radius: 4px;
    padding: 0 2px 0 6px;
    overflow: hidden;
    cursor: default;
`;

export const NewAttachmentItemIcon = styled(AttachmentMiniIcon)`
    fill: currentColor;
    color: #a0a1a1;
    margin-right: 5px;
    padding-top: 1px;
`;

export const NewAttachmentItemText = styled.div`
    ${font(12, '#5e5c5c')};
    margin-right: 5px;
    flex: 1;
    ${uTextEllipsis};
`;

export const NewAttachmentItemLoadingIcon = styled(LoadingSpinner)`
    margin-right: 5px;
    height: 12px;
    width: 12px;
`;

export const NewAttachmentItemLoadingText = styled.div`
    ${font(12, '#6c6a6a')};
`;
