import type { CamelCasedPropertiesDeep } from 'type-fest';

import { RequestReportColumnsAnswer } from '../backend';
import { DayOfWeek } from './Profile';

export enum ReportType {
    Request = 'Request',
    LineItem = 'LineItem',
}

export enum ReportCode {
    Request = 'Request',
    AirwallexXeroBatchPaymentLine = 'AirwallexXeroBatchPaymentLine',
    XeroAmaxPayBatchPaymentLine = 'AmaxPayXeroBatchPaymentLine',
    DearPurchaseOrderLine = 'DearPurchaseOrderLine',
    NetSuiteBillLine = 'NetSuiteBillLine',
    NetSuitePurchaseOrderLine = 'NetSuitePurchaseOrderLine',
    NetSuiteSalesOrderLine = 'NetSuiteSalesOrderLine',
    QBooksBillInvoiceLine = 'QBooksBillInvoiceLine',
    QBooksExpenseLine = 'QBooksExpenseLine',
    QBooksPurchaseOrderLine = 'QBooksPurchaseOrderLine',
    QBooksSalesInvoiceLine = 'QBooksSalesInvoiceLine',
    QBooksJournalEntryLine = 'QBooksJournalEntryLine',
    XeroAPCreditNoteLine = 'XeroAPCreditNoteLine',
    XeroARCreditNoteLine = 'XeroARCreditNoteLine',
    XeroBillInvoiceLine = 'XeroBillInvoiceLine',
    XeroPurchaseOrderLine = 'XeroPurchaseOrderLine',
    XeroQuoteLine = 'XeroQuoteLine',
    XeroSalesInvoiceLine = 'XeroSalesInvoiceLine',
    XeroManualJournalLine = 'XeroManualJournalLine',
}

export type ReportSettings = CamelCasedPropertiesDeep<RequestReportColumnsAnswer> & {
    type: ReportCode;
} & Record<string, unknown>;

export type Report = {
    id: string;
    createdAt: string;
    lastRunAt: string | null;
    modifiedAt: string;
    name: string;
    settings: ReportSettings;
};

export type ReportQBooksSalesInvoiceCustomFieldColumn = ReportSettings['qBooksSalesInvoiceCustomFields'][0];
export type ReportQBooksPurchaseOrderCustomFieldColumn = ReportSettings['qBooksPurchaseOrderCustomFields'][0];

export interface ReportScheduleRule {
    name: string;
    emails: string[];
    validationErrors: ReportScheduleValidationErrors;
    configuration: ScheduleConfiguration;
}

export interface ReportScheduleValidationErrors {
    nameError: string;
    emailsError: string;
    weekError: string;
    longRuleName: boolean;
    emptyDay: boolean;
}

export interface ScheduleConfiguration {
    scheduleOptions: ScheduleOption;
    customSchedulePeriodicity?: CustomScheduleType;
    daysOfWeek?: DayOfWeek[];
    monthlyOptions?: MonthlyOption;
    dayOfMonth?: number;
    dayOfYear?: {
        month: number;
        day: number;
    };
    time: {
        hour: number;
        minute: number;
    };
}

export enum ScheduleOption {
    EveryDay = 'EveryDay',
    EveryWeekMonday = 'EveryWeekMonday',
    EveryMonthMondayOf4thWeek = 'EveryMonthMondayOf4thWeek',
    EveryMonthLastMonday = 'EveryMonthLastMonday',
    EveryYearDecember25th = 'EveryYearDecember25th',
    EveryWorkingDay = 'EveryWorkingDay',
    Custom = 'Custom',
}

export enum CustomScheduleType {
    Weekly = 'Weekly',
    Monthly = 'Monthly',
    Annually = 'Annually',
}

export enum MonthlyOption {
    Day25th = 'Day25th',
    CustomDay = 'CustomDay',
    MondayOf4thWeek = 'MondayOf4thWeek',
    FridayOf4thWeek = 'FridayOf4thWeek',
}

export type XeroAccrualsJournalCreationRemainingBalanceType = 'Net' | 'Gross';

export type XeroAccrualsJournalCreationUnknownAmountHandlingType = 'AsSingleLine' | 'Exclude';
