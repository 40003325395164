import { z, ZodObject } from 'zod';

import {
    BackupMethodOptions,
    fieldNameBackupMethod,
    fieldNameCodesAreSaved,
    fieldNameEmail,
    fieldNameEmailVerificationCode,
    fieldNameSetupCode,
    fieldNameSetupVerificationCode,
    fieldNameStateId,
} from './TwoFaEnablingWizard.constants';
import { messages } from './TwoFaEnablingWizard.messages';
import { WizardStep } from './TwoFaEnablingWizard.types';

export const schema: Record<WizardStep, ZodObject<any>> = {
    codeScan: z.object({
        [fieldNameStateId]: z.string().trim().min(1, { message: messages.errorStateIdEmpty }),
        [fieldNameSetupCode]: z.string().trim().min(1, { message: messages.errorSetupCodeEmpty }),
    }),
    codeVerify: z.object({
        [fieldNameSetupVerificationCode]: z
            .string()
            .trim()
            .min(6, { message: messages.errorSetupVerificationCodeEmpty }),
    }),
    backupMethods: z.object({
        [fieldNameBackupMethod]: z.nativeEnum(BackupMethodOptions, { message: messages.errorBackupMethodEmpty }),
    }),
    backupCodes: z.object({
        [fieldNameCodesAreSaved]: z.boolean().refine((val) => val, {
            message: messages.errorCodesAreSaved,
        }),
    }),
    backupEmailEnter: z.object({
        [fieldNameEmail]: z.string().trim().min(1, messages.errorEmailEmpty).email(messages.errorEmailInvalid),
        [fieldNameEmailVerificationCode]: z.any(),
    }),
    backupEmailVerify: z.object({
        [fieldNameEmail]: z.string().trim().min(1, messages.errorEmailEmpty).email(messages.errorEmailInvalid),
        [fieldNameEmailVerificationCode]: z.string().min(3, messages.errorEmailVerificationCodeRequired),
    }),
};
