import { HelpCircleIcon } from '@approvalmax/ui';
import { Alert, Box, Button, Flex, Popup, toast } from '@approvalmax/ui/src/components';
import { useSelector } from 'modules/react-redux';
import { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { applyMatrix, discardOpenEditingMatrix } from '../../../../actions';
import { useMatrixData } from '../../../../hooks/matrix';
import { getActiveEditingMatrix, getActiveMatrix } from '../../../../selectors/pageSelectors';
import { MatrixType } from '../../../../types/matrix';
import { getIsValidMatrix } from '../../../../utils/helpers';
import CopyRulePopup from '../CopyRulePopup/CopyRulePopup';
import ErrorScreen from '../ErrorScreen/ErrorScreen';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import Matrix from '../Matrix/Matrix';
import { messages } from './EditingMatrixPopupContent.messages';

export const EditingMatrixPopupContent = memo(() => {
    const dispatch = useDispatch();
    const matrix = useSelector(getActiveMatrix)!;
    const editingMatrix = useSelector(getActiveEditingMatrix)!;
    const {
        definition,
        company,
        team,
        template,
        integrationType,
        readonly,
        step,
        cellFields,
        fields,
        submitters,
        loaded,
        loadedNoError,
        loadedWithError,
        integrationErrorPreventsWork,
        errorMessage,
    } = useMatrixData(editingMatrix);

    const applyAndClose = useCallback(() => {
        if (readonly || !editingMatrix) {
            dispatch(discardOpenEditingMatrix());

            return;
        }

        const isValidMatrix = getIsValidMatrix(editingMatrix);

        if (isValidMatrix) {
            dispatch(applyMatrix(editingMatrix, fields));
        } else {
            toast.error(messages.invalidConditions);
        }
    }, [dispatch, editingMatrix, fields, readonly]);

    if (!editingMatrix || matrix.type !== MatrixType.Approval || definition.columns.length === 0) {
        return null;
    }

    return (
        <>
            <Popup.Header
                title={messages.editingMatrixForTheStep({ step: step?.name || '' })}
                actions={
                    <Button color='blue10' disabled={readonly} onClick={applyAndClose} size='medium'>
                        {messages.save}
                    </Button>
                }
            />

            <Popup.Body spacing='0'>
                <Flex direction='column' grow={1} width='100%' height={600} wrap={false} spacing='0'>
                    <Box spacing='0 40 16'>
                        <Alert
                            alignItems='center'
                            size='small'
                            color='midnight20'
                            startIcon={<HelpCircleIcon />}
                            closable
                        >
                            {messages.alertMessage}
                        </Alert>
                    </Box>

                    {loadedNoError && (
                        <Matrix
                            definition={definition}
                            company={company}
                            team={team}
                            template={template}
                            integrationType={integrationType}
                            fields={fields}
                            readonly={readonly}
                            submitters={submitters}
                            cellFields={cellFields}
                            matrix={editingMatrix}
                        />
                    )}

                    {!loaded && <LoadingScreen />}

                    {loadedWithError && (
                        <ErrorScreen
                            integrationErrorPreventsWork={integrationErrorPreventsWork}
                            integrationType={integrationType}
                            errorMessage={errorMessage}
                        />
                    )}
                </Flex>
            </Popup.Body>

            <CopyRulePopup
                matrix={editingMatrix}
                cellFields={cellFields}
                steps={template.steps}
                company={company}
                template={template}
                team={team}
                readonly={readonly}
            />
        </>
    );
});

EditingMatrixPopupContent.displayName = 'EditingMatrixPopupContent';
