import { FC, memo, useState } from 'react';
import bemFactory from 'react-bem-factory';
import { useRecoilValue } from 'recoil';

import { Breadcrumbs, companyHeaderState, displayActionsHeaderState, Flex, Spacing, Text } from '../../components';
import { CloseIcon } from '../../icons';
import { useResetHeaderStates } from './Header.hooks';
import { backHeaderState, breadcrumbsHeaderState, dividerHeaderState, slotHeaderState } from './Header.states';
import { CloseButton, Root } from './Header.styles';
import { HeaderProps } from './Header.types';

const qa = bemFactory.qa('page-nav-bar');

/**
 * Main Header component for the application
 */
const Header: FC<HeaderProps> = memo((props) => {
    const { companyName, actions, resetState, breadcrumbsLinkAs, ...restProps } = props;

    const hasDivider = useRecoilValue(dividerHeaderState);
    const onBack = useRecoilValue(backHeaderState);
    const breadcrumbs = useRecoilValue(breadcrumbsHeaderState);
    const slot = useRecoilValue(slotHeaderState);
    const hasCompany = useRecoilValue(companyHeaderState);
    const isDisplayActions = useRecoilValue(displayActionsHeaderState);

    useResetHeaderStates({ resetState });

    const [isBackLoading, setIsBackLoading] = useState(false);

    const handleBackClick = async () => {
        if (typeof onBack === 'function') {
            setIsBackLoading(true);

            try {
                await onBack();
            } finally {
                setIsBackLoading(false);
            }
        }
    };

    return (
        <Root $divider={hasDivider} {...restProps}>
            <Flex wrap={false} justifyContent='space-between' alignItems='center' grow={1} spacing='0 12'>
                {onBack && (
                    <Flex>
                        <CloseButton
                            onClick={handleBackClick}
                            disabled={isBackLoading}
                            icon
                            noPadding
                            data-qa={qa('close-button')}
                        >
                            <CloseIcon size={28} />
                        </CloseButton>
                    </Flex>
                )}

                {(hasCompany || !!breadcrumbs.length) && (
                    <Flex direction='column' spacing='4'>
                        {hasCompany && (
                            <>
                                {typeof companyName === 'string' ? (
                                    <Text font='label' fontSize='xsmall' color='midnight70' ellipsis={1}>
                                        {companyName}
                                    </Text>
                                ) : (
                                    companyName
                                )}
                            </>
                        )}

                        {breadcrumbs.length ? (
                            <Breadcrumbs items={breadcrumbs} as={breadcrumbsLinkAs} data-qa={qa('breadcrumbs')} />
                        ) : (
                            <Spacing height={20} />
                        )}
                    </Flex>
                )}

                <Flex wrap={false} alignItems='center' justifyContent='end' grow={1} shrink={0}>
                    {slot}

                    {isDisplayActions && actions}
                </Flex>
            </Flex>
        </Root>
    );
});

export default Header;
