import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { GetBillRequestsForAirwallexBatchPayment } from './useGetBillRequestsForAirwallexBatchPayment.types';

export const useGetBillRequestsForAirwallexBatchPayment = (
    params?: RequestParams<GetBillRequestsForAirwallexBatchPayment>,
    queryOptions?: RequestQueryOptions<GetBillRequestsForAirwallexBatchPayment>
) => {
    return useGet(requestsApiPaths.billRequestsForAirwallexBatchPayment, {
        params,
        queryOptions,
        apiVersion: 'v1',
    });
};
