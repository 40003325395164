import { FC, memo } from 'react';
import { useGetWorkflowVersionDocumentFields } from 'shared/data/v1';

import FieldsSection from '../../../../../components/FieldsSection';
import ActivitySection from '../../../../card/ActivitySection';
import AddCommentSection from '../../../../card/AddCommentSection/AddCommentSection';
import AttachmentsSection from '../../../../card/AttachmentsSection';
import Toolbar from '../../../../card/Toolbar/Toolbar';
import { WorkflowSection } from '../../../../card/WorkflowSection';
import {
    getSalesOrderFieldSettings,
    useDiscountRate,
    useDisplayCurrency,
    useGetExchangeInfo,
    useNetSuiteCustomFields,
} from '../../NetSuiteRequestCard.hooks';
import {
    CurrencySwitcherContainer,
    FieldsSectionWrap,
    Root,
    StyledField,
    StyledScrollableArea,
} from '../../NetSuiteRequestCard.styles';
import { getNetSuiteAmountText, isFieldVisible } from '../../NetSuiteRequestCard.utils';
import CurrencySwitcher from '../CurrencySwitcher/CurrencySwitcher';
import NetSuiteCustomFields from '../NetSuiteCustomFields/NetSuiteCustomFields';
import NetSuiteRequestFooter from '../NetSuiteRequestFooter/NetSuiteRequestFooter';
import NetSuiteSalesOrderFooterFields from '../NetSuiteSalesOrderFooterFields/NetSuiteSalesOrderFooterFields';
import NetSuiteSalesOrderItemLines from '../NetSuiteSalesOrderItemLines/NetSuiteSalesOrderItemLines';
import { messages } from './RequestCardSalesOrder.messages';
import { RequestCardSalesOrderProps } from './RequestCardSalesOrder.types';

export const RequestCardSalesOrder: FC<RequestCardSalesOrderProps> = memo((props) => {
    const { request, className } = props;

    const { data: companyCustomFields } = useNetSuiteCustomFields(request.companyId);

    const { data: workflowVersionDocumentFields } = useGetWorkflowVersionDocumentFields({
        query: {
            companyId: request.companyId,
            workflowVersionIds: [request.workflowVersionId],
        },
    });

    const { details } = request;

    const fieldSettings = getSalesOrderFieldSettings(workflowVersionDocumentFields?.data || []);
    const discountRate = useDiscountRate(details.discountRate, details.isDiscountRateAbsolute);

    const { displayCurrency, onDisplayCurrencyChange } = useDisplayCurrency();
    const { exchangeRate } = useGetExchangeInfo(request.companyId, request.details.exchangeRate, displayCurrency?.id);

    return (
        <Root className={className}>
            <Toolbar
                request={request}
                showVatRegistration={isFieldVisible(fieldSettings.vatRegistrationNumber)}
                amountText={getNetSuiteAmountText(
                    request.amount,
                    exchangeRate,
                    request.currency || '',
                    displayCurrency?.isoCode
                )}
            />

            <StyledScrollableArea scrollResetKey={request.id}>
                <FieldsSectionWrap>
                    {isFieldVisible(fieldSettings.transactionNumber) && (
                        <StyledField
                            title={messages.referenceField}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={details.reference}
                            hideIfEmpty
                        />
                    )}

                    {isFieldVisible(fieldSettings.paymentReference) && (
                        <StyledField
                            title={messages.paymentReferenceField}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={details.paymentReference}
                            hideIfEmpty
                        />
                    )}

                    {isFieldVisible(fieldSettings.transactionDate) && (
                        <StyledField
                            title={messages.transactionDateField}
                            valueType={FieldsSection.Field.ValueType.Date}
                            value={details.transactionDate}
                            hideIfEmpty
                        />
                    )}

                    {isFieldVisible(fieldSettings.startDate) && (
                        <StyledField
                            title={messages.startDateField}
                            valueType={FieldsSection.Field.ValueType.Date}
                            value={details.startDate}
                            hideIfEmpty
                        />
                    )}

                    {isFieldVisible(fieldSettings.endDate) && (
                        <StyledField
                            title={messages.endDateField}
                            valueType={FieldsSection.Field.ValueType.Date}
                            value={details.endDate}
                            hideIfEmpty
                        />
                    )}

                    {isFieldVisible(fieldSettings.class) && (
                        <StyledField
                            title={messages.classField}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={details.class}
                            hideIfEmpty
                        />
                    )}

                    {isFieldVisible(fieldSettings.department) && (
                        <StyledField
                            title={messages.departmentField}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={details.department}
                            hideIfEmpty
                        />
                    )}

                    {isFieldVisible(fieldSettings.location) && (
                        <StyledField
                            title={messages.location}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={details.location}
                            hideIfEmpty
                        />
                    )}

                    {isFieldVisible(fieldSettings.terms) && (
                        <StyledField
                            title={messages.terms}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={details.terms}
                            hideIfEmpty
                        />
                    )}

                    {isFieldVisible(fieldSettings.billingSchedule) && (
                        <StyledField
                            title={messages.billingSchedule}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={details.billingSchedule}
                            hideIfEmpty
                        />
                    )}

                    {isFieldVisible(fieldSettings.discountItem) && (
                        <StyledField
                            title={messages.discountItem}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={details.discountItem?.text}
                            hideIfEmpty
                        />
                    )}

                    {isFieldVisible(fieldSettings.discountRate) && (
                        <StyledField
                            title={messages.discountRate}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={discountRate}
                            hideIfEmpty
                        />
                    )}

                    {isFieldVisible(fieldSettings.memo) && (
                        <StyledField
                            title={messages.memoField}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={details.memo}
                            hideIfEmpty
                        />
                    )}

                    <NetSuiteCustomFields
                        customFields={details.customFields}
                        companyCustomFields={companyCustomFields?.Fields ?? []}
                    />
                </FieldsSectionWrap>

                <NetSuiteSalesOrderItemLines
                    itemLines={details.itemLines}
                    companyCustomFields={companyCustomFields?.Fields ?? []}
                    documentFields={workflowVersionDocumentFields?.data || []}
                    exchangeRate={
                        displayCurrency && displayCurrency?.isoCode !== request.currency && exchangeRate !== 1
                            ? exchangeRate
                            : null
                    }
                />

                {typeof exchangeRate === 'number' && (
                    <CurrencySwitcherContainer>
                        <CurrencySwitcher
                            request={request}
                            selectedCurrency={displayCurrency}
                            requestCurrency={request.details.currency}
                            onCurrencyChange={onDisplayCurrencyChange}
                            companyId={request.companyId}
                        />
                    </CurrencySwitcherContainer>
                )}

                <NetSuiteRequestFooter
                    request={request}
                    displayCurrency={displayCurrency}
                    exchangeRate={exchangeRate}
                />

                <NetSuiteSalesOrderFooterFields request={request} />

                <AttachmentsSection request={request} />

                <WorkflowSection request={request} />

                <ActivitySection request={request} />

                {!request.flags.status.isDraft && <AddCommentSection request={request} />}
            </StyledScrollableArea>
        </Root>
    );
});

RequestCardSalesOrder.displayName = 'RequestCardSalesOrder';
