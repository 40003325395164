import { MutateDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { UseImportManualJournalData, UseImportManualJournalResponse } from './useImportManualJournal.types';

export const useImportManualJournal = () => {
    return useMutateLegacy<MutateDataParams<UseImportManualJournalData>, UseImportManualJournalResponse>(
        requestsApiPaths.importManualJournal
    );
};
