import { getLockDateWarningText } from 'modules/common/selectors/requestSelectors.Xero';
import { FC } from 'react';
import bemFactory from 'react-bem-factory';

import FieldsSection from '../../../components/FieldsSection';
import { messages } from './XeroCreditNotePayableFields.messages';
import { XeroCreditNotePayableFieldsProps } from './XeroCreditNotePayableFields.types';

const qa = bemFactory.qa('reql-xero-fields-section');

const XeroCreditNotePayableFields: FC<XeroCreditNotePayableFieldsProps> = (props) => {
    const { request } = props;

    const details = request.details;
    const lockDateWarningText = getLockDateWarningText(request);

    return (
        <FieldsSection>
            <FieldsSection.Field
                qa={qa('date')}
                title={messages.cnPayDateFieldText}
                valueType={FieldsSection.Field.ValueType.Date}
                value={details.date}
                warningText={lockDateWarningText}
            />

            {details.reference ? (
                <FieldsSection.Field
                    qa={qa('reference')}
                    title={messages.cnPayReferenceFieldText}
                    value={details.reference}
                />
            ) : (
                <FieldsSection.Field.Empty />
            )}

            <FieldsSection.Field.Empty />

            <FieldsSection.Field.Empty />
        </FieldsSection>
    );
};

export default XeroCreditNotePayableFields;
