import { FC, memo } from 'react';

import { NetSuiteLineTable } from '../NetSuiteLineTable/NetSuiteLineTable';
import { useTableDefinitions } from './NetSuiteVendorSubsidiaryList.hooks';
import { NetSuiteVendorSubsidiaryListProps } from './NetSuiteVendorSubsidiaryList.types';

export const NetSuiteVendorSubsidiaryList: FC<NetSuiteVendorSubsidiaryListProps> = memo((props) => {
    const { subsidiaries } = props;

    const tableDef = useTableDefinitions();

    return subsidiaries.length > 0 ? <NetSuiteLineTable columnDefinitions={tableDef} lineItems={subsidiaries} /> : null;
});

NetSuiteVendorSubsidiaryList.displayName = 'NetSuiteVendorSubsidiaryList';
