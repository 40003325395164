import type { ApiError } from '@approvalmax/types';
import { Link, Popup } from '@approvalmax/ui';
import { useQuery } from '@tanstack/react-query';
import { domain } from 'modules/data';
import { memo, useCallback, useEffect } from 'react';
import { api } from 'services/api';

import { Step } from '../CompleteAirwallexPaymentPopup.types';
import { columnDefinitions } from './BalanceStep.config';
import { messages } from './BalanceStep.messages';
import {
    ConfirmButton,
    Content,
    Description,
    StyledLineItemsTable,
    StyledSkeleton,
    Subtitle,
} from './BalanceStep.styles';
import { BalanceStepProps } from './BalanceStep.types';

const BalanceStep = memo<BalanceStepProps>((props) => {
    const { setCurrentStep, request, onRequestClose } = props;

    const { companyId, id: requestId } = request;

    const {
        data = [],
        isInitialLoading,
        error,
    } = useQuery<domain.RequestCurrentBalance[], ApiError>(
        ['request-current-balance', companyId, requestId],
        async () => {
            const response = await api.requests.getCurrentBalances({
                companyId,
                requestId,
            });

            return response.balances || [];
        }
    );

    useEffect(() => {
        if (error) {
            onRequestClose();
        }
    }, [error, onRequestClose]);

    const goToTwoFaStep = useCallback(() => setCurrentStep(Step.TwoFa), [setCurrentStep]);

    return (
        <Popup.DefaultContent title={request.displayName}>
            <Content>
                <Subtitle>Balances</Subtitle>

                {isInitialLoading ? (
                    <StyledSkeleton count={3} height={20} />
                ) : (
                    <>
                        <StyledLineItemsTable columnDefinitions={columnDefinitions} lineItems={data} />

                        {data.length === 0 && !error && messages.noBalances}
                    </>
                )}

                <Description>
                    {messages.description({
                        br: <br />,
                        link: (
                            <Link href='https://openexchangerates.org/' targetBlank>
                                OpenExchangeRates.org
                            </Link>
                        ),
                    })}
                </Description>

                <ConfirmButton execute={goToTwoFaStep} disabled={isInitialLoading || Boolean(error)}>
                    {messages.confirmButton}
                </ConfirmButton>
            </Content>
        </Popup.DefaultContent>
    );
});

export default BalanceStep;
