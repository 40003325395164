import { Reference } from '@approvalmax/types';
import { arrayHelpers, errorHelpers, intl } from '@approvalmax/utils';
import capitalize from 'lodash/capitalize';
import lowerCase from 'lodash/lowerCase';
import { domain } from 'modules/data';
import { AsyncDataProvider, DataItem, PlainDataProvider } from 'modules/data-providers';
import { PropsWithChildren, PureComponent } from 'react';
import { defineMessages } from 'react-intl';
import { createSelector } from 'reselect';
import { api } from 'services/api';

import { commonConstants } from '../constants';
import { ExpandedCompanyUser } from '../selectors/types';

const pageSizeMap: { [systemPurpose: string]: number } = {};
const defaultPageSize = 100;

const messages = defineMessages({
    fieldEmptyValueText: {
        id: 'common.field.fieldEmptyValueText',
        defaultMessage: 'Empty',
    },
    fieldAnyValueText: {
        id: 'common.field.fieldAnyValueText',
        defaultMessage: 'Any value',
    },
    fieldNotEmptyValueText: {
        id: 'common.field.fieldNotEmptyValueText',
        defaultMessage: 'Not empty',
    },
});

export type ComponentNameProps = PropsWithChildren<{
    field: domain.Field;
    templateSubmitters?: any[];
    staticValues?: Reference[];
}>;

interface FieldDataProviderProps extends Pick<ComponentNameProps, 'field'>, PropsWithChildren {
    integrationCode?: domain.IntegrationCode | null;
    templateSubmitters?: any[];
    staticValues?: Reference[];
}

class FieldDataProvider extends PureComponent<FieldDataProviderProps> {
    public static EmptyValue: Reference = {
        id: commonConstants.EMPTY_VALUE_ID,
        text: intl.formatMessage(messages.fieldEmptyValueText),
    };
    public static NotEmptyValue: Reference = {
        id: commonConstants.NOT_EMPTY_VALUE_ID,
        text: intl.formatMessage(messages.fieldNotEmptyValueText),
    };
    public static AnyValue: Reference = {
        id: commonConstants.ANY_VALUE_ID,
        text: intl.formatMessage(messages.fieldAnyValueText),
    };

    static defaultProps = {
        staticValues: [],
    };

    private _getPlainValues = createSelector(
        (props: FieldDataProviderProps) => props.field,
        (props: FieldDataProviderProps) => props.templateSubmitters,
        (props: FieldDataProviderProps) => props.staticValues || arrayHelpers.emptyArray(),
        (field, templateSubmitters, staticValues) => {
            let values: Reference[] | undefined;

            switch (field.systemPurpose) {
                case domain.FieldSystemPurpose.General:
                    values = field.exactValues;
                    break;

                case domain.FieldSystemPurpose.Requester:
                    if (!templateSubmitters) {
                        throw errorHelpers.invalidOperationError(
                            'Insufficient data: pass the `templateSubmitters` property.'
                        );
                    }

                    values = templateSubmitters.map((s) => ({
                        ...s,
                        text: s.displayName,
                    }));
                    break;
            }

            if (!values) {
                throw errorHelpers.notSupportedError(
                    'FieldDataProvider is trying to render plain values for a not supported field.'
                );
            }

            if (staticValues.length > 0) {
                const resultValues = arrayHelpers.cloneImmutableArray(values);
                const newStaticValues = arrayHelpers.cloneImmutableArray(staticValues);

                newStaticValues.forEach((sv) => {
                    const index = resultValues.findIndex((v) => v.id === sv.id);

                    if (index !== -1) {
                        resultValues.splice(index, 1);
                    }
                });

                return staticValues.concat(resultValues);
            }

            return values;
        }
    );

    public render() {
        const field = this.props.field;

        switch (field.systemPurpose) {
            case domain.FieldSystemPurpose.General:
                return this._renderPlainDataProvider();

            case domain.FieldSystemPurpose.Requester:
                return this._renderRequesterDataProvider();

            case domain.FieldSystemPurpose.AirwallexBatchPaymentStatus:
            case domain.FieldSystemPurpose.AirwallexBeneficiary:
            case domain.FieldSystemPurpose.AirwallexCurrency:
            case domain.FieldSystemPurpose.AirwallexFeeAmount:
            case domain.FieldSystemPurpose.AirwallexPaymentFee:
            case domain.FieldSystemPurpose.AirwallexPaymentItemStatus:
            case domain.FieldSystemPurpose.AirwallexPaymentMethod:
            case domain.FieldSystemPurpose.AirwallexPaymentPurpose:
            case domain.FieldSystemPurpose.AirwallexSourceAmount:
            case domain.FieldSystemPurpose.AmaxPayBankAccount:
            case domain.FieldSystemPurpose.DearAccount:
            case domain.FieldSystemPurpose.DearBrand:
            case domain.FieldSystemPurpose.DearLocation:
            case domain.FieldSystemPurpose.DearProduct:
            case domain.FieldSystemPurpose.DearProductCategory:
            case domain.FieldSystemPurpose.DearProductFamily:
            case domain.FieldSystemPurpose.DearSupplier:
            case domain.FieldSystemPurpose.NetSuiteAccount:
            case domain.FieldSystemPurpose.NetSuiteClass:
            case domain.FieldSystemPurpose.NetSuiteCurrency:
            case domain.FieldSystemPurpose.NetSuiteCustom:
            case domain.FieldSystemPurpose.NetSuiteCustomer:
            case domain.FieldSystemPurpose.NetSuiteDepartment:
            case domain.FieldSystemPurpose.NetSuiteEmployee:
            case domain.FieldSystemPurpose.NetSuiteExpenseCategory:
            case domain.FieldSystemPurpose.NetSuiteItem:
            case domain.FieldSystemPurpose.NetSuiteLocation:
            case domain.FieldSystemPurpose.NetSuitePayee:
            case domain.FieldSystemPurpose.NetSuiteSubsidiary:
            case domain.FieldSystemPurpose.NetSuiteTaxCode:
            case domain.FieldSystemPurpose.NetSuiteVendor:
            case domain.FieldSystemPurpose.QBooksAccount:
            case domain.FieldSystemPurpose.QBooksClass:
            case domain.FieldSystemPurpose.QBooksCurrency:
            case domain.FieldSystemPurpose.QBooksCustomer:
            case domain.FieldSystemPurpose.QBooksDepartment:
            case domain.FieldSystemPurpose.QBooksDocumentStatus:
            case domain.FieldSystemPurpose.QBooksEmployee:
            case domain.FieldSystemPurpose.QBooksPayeeCustomer:
            case domain.FieldSystemPurpose.QBooksPayeeEmployee:
            case domain.FieldSystemPurpose.QBooksPayeeVendor:
            case domain.FieldSystemPurpose.QBooksPaymentAccount:
            case domain.FieldSystemPurpose.QBooksPaymentMethod:
            case domain.FieldSystemPurpose.QBooksPaymentType:
            case domain.FieldSystemPurpose.QBooksPoItem:
            case domain.FieldSystemPurpose.QBooksSalesItem:
            case domain.FieldSystemPurpose.QBooksTax:
            case domain.FieldSystemPurpose.QBooksVendor:
            case domain.FieldSystemPurpose.XeroAccount:
            case domain.FieldSystemPurpose.XeroBankAccount:
            case domain.FieldSystemPurpose.XeroBranding:
            case domain.FieldSystemPurpose.XeroContactDefaultCurrency:
            case domain.FieldSystemPurpose.XeroContactPurchaseAccount:
            case domain.FieldSystemPurpose.XeroContactPurchaseTax:
            case domain.FieldSystemPurpose.XeroContactPurchaseTrackingCategories:
            case domain.FieldSystemPurpose.XeroContactSalesAccount:
            case domain.FieldSystemPurpose.XeroContactSalesTax:
            case domain.FieldSystemPurpose.XeroContactSalesTrackingCategories:
            case domain.FieldSystemPurpose.XeroCurrency:
            case domain.FieldSystemPurpose.XeroCustomer:
            case domain.FieldSystemPurpose.XeroItem:
            case domain.FieldSystemPurpose.XeroLineAmountType:
            case domain.FieldSystemPurpose.XeroSupplier:
            case domain.FieldSystemPurpose.XeroTax:
            case domain.FieldSystemPurpose.XeroTracking:
                return this._renderAsyncDataProvider();

            default:
                throw errorHelpers.notSupportedError();
        }
    }

    private _renderPlainDataProvider() {
        return (
            <PlainDataProvider items={this._getPlainValues(this.props)} filterAttribute='text'>
                {this.props.children}
            </PlainDataProvider>
        );
    }

    private _renderRequesterDataProvider() {
        return (
            <PlainDataProvider items={this._getPlainValues(this.props)} filterFn={this._filterUserItem}>
                {this.props.children}
            </PlainDataProvider>
        );
    }

    private _filterUserItem = (user: ExpandedCompanyUser, filterText: string) => {
        return user.displayName.toUpperCase().includes(filterText) || user.userEmail.toUpperCase().includes(filterText);
    };

    private _renderAsyncDataProvider() {
        const { field, staticValues, integrationCode, children } = this.props;
        const fieldId = field.id;
        const systemPurpose = field.systemPurpose;

        const pageSize = pageSizeMap[systemPurpose] || defaultPageSize;

        return (
            <AsyncDataProvider
                cacheStorageId={`fields.${integrationCode}.${fieldId}`}
                pageSize={pageSize}
                onLoad={this._onLoad}
                staticValues={staticValues}
            >
                {children}
            </AsyncDataProvider>
        );
    }

    private _onLoad = (filterText: string, startFrom: number, count: number): Promise<DataItem[]> => {
        const { field, integrationCode } = this.props;
        const fieldId = field.id;
        const companyId = field.companyId;

        return api.companies
            .selectFields({
                withValues: true,
                fieldsId: [fieldId],
                companyId,
                integrationCode,
                query: filterText,
                startFrom,
                rowNum: count,
                allFields: integrationCode === undefined,
            })
            .then((result) =>
                (result.Fields[0]?.ExactValues || []).map((x: any) => {
                    if (
                        field.systemPurpose === domain.FieldSystemPurpose.AirwallexPaymentPurpose ||
                        field.systemPurpose === domain.FieldSystemPurpose.AirwallexBatchPaymentStatus
                    ) {
                        return {
                            id: x.FieldValueId,
                            text: capitalize(lowerCase(x.Value)),
                        };
                    }

                    return {
                        id: x.FieldValueId,
                        text: x.Value,
                    };
                })
            );
    };
}

export default FieldDataProvider;
