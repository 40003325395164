import moment from 'moment';
import { z } from 'zod';

import { messages } from './MyDelegatesPopup.messages';

export const schema = z.object({
    delegates: z.array(
        z
            .object({
                delegateId: z.nullable(z.string()),
                dateFrom: z.nullable(z.date()),
                dateTo: z.nullable(z.date()),
            })
            .passthrough()
            .refine(
                (value) => {
                    return value.delegateId ? Boolean(value.dateFrom) : true;
                },
                {
                    message: messages.required,
                    path: ['dateFrom'],
                }
            )
            .refine(
                (value) =>
                    !(
                        value.dateFrom &&
                        value.dateTo &&
                        (moment(value.dateFrom).isAfter(value.dateTo) || moment(value.dateFrom).isSame(value.dateTo))
                    ),
                { message: messages.dateFromCantBeAfterDateTo, path: ['dateFrom'] }
            )
    ),
});
