import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { ocrApiPaths } from '../paths';
import { GetOcrStatus } from './useGetOcrStatus.types';

export const useGetOcrStatus = (
    params?: RequestParams<GetOcrStatus>,
    queryOptions?: RequestQueryOptions<GetOcrStatus>
) => {
    return useGet(ocrApiPaths.status, {
        params,
        queryOptions,
        apiVersion: 'v2',
    });
};
