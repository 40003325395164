import { Button } from '@approvalmax/ui';
import styled from 'styled-components';

export const ClosedStatusButton = styled(Button)`
    color: #477753;

    svg {
        margin-right: 6px;
    }
`;
