import { Guid } from '@approvalmax/types';
import { produce } from 'immer';
import { selectors } from 'modules/common';

import {
    Action,
    APPLY_MY_DELEGATES_RESPONSE,
    CANCEL_ACTIVE_POPUP,
    OPEN_CONSENT_TO_START_TRIAL_POPUP,
    OPEN_CONTACT_SALES_POPUP,
    OPEN_CONTACT_SUPPORT_POPUP,
    OPEN_TRIAL_PERIOD_STARTED_POPUP,
    OPEN_TWO_FA_SOFT_ENFORCEMENT_POPUP,
    SET_DELEGATE,
    SET_DELEGATE_FROM_DATE,
    SET_DELEGATE_TO_DATE,
    SHOW_MY_DELEGATES_POPUP,
    SHOW_PENDING_PRACTICE_INVITATION_POPUP,
    SHOW_PROFILE_POPUP,
    StartTrialPopupOrigin,
} from '../actions';
import { PendingPracticeInvitationPopupData } from '../containers/pendingPracticeInvitationPopup/PendingPracticeInvitationPopup';

export interface MyCompanyDelegate {
    companyId: Guid;
    delegateUserProfileId: Guid | undefined;
    delegateId: string | null;
    delegateFrom: string | null;
    delegateTo: string | null;
}

export interface OptimisticSetDelegate {
    me: selectors.types.ExpandedUser;
    delegates: MyCompanyDelegate[];
}

export interface MyDelegatesPopupData {
    loading: boolean;
    modified: boolean;
    delegates: MyCompanyDelegate[];
}

export type ProfilePopupData = Record<string, any>;

export interface ConsentToStartTrialPeriodPopupData {
    company: selectors.types.ExpandedCompany;
    origin: StartTrialPopupOrigin;
}

export interface TrialPeriodStartedPopupData {}

export interface ContactSalesData {}

export interface ContactSupportData {}

export interface TwoFaSoftEnforcementData {}

export interface ProfileModuleData {
    profilePopup?: ProfilePopupData;
    myDelegatesPopup?: MyDelegatesPopupData;
    pendingPracticeInvitationPopup?: PendingPracticeInvitationPopupData;
    consentToStartTrialPeriodPopup?: ConsentToStartTrialPeriodPopupData;
    trialPeriodStartedPopup?: TrialPeriodStartedPopupData;
    contactSalesPopup?: ContactSalesData;
    contactSupportPopup?: ContactSupportData;
    twoFaSoftEnforcementPopup?: TwoFaSoftEnforcementData;
}

const INITIAL_STATE: ProfileModuleData = {};

export default produce((draft: ProfileModuleData, action: Action): ProfileModuleData => {
    switch (action.type) {
        case SHOW_PENDING_PRACTICE_INVITATION_POPUP: {
            return {
                pendingPracticeInvitationPopup: {},
            };
        }

        case SHOW_MY_DELEGATES_POPUP:
            return {
                myDelegatesPopup: {
                    loading: false,
                    modified: false,
                    delegates: action.delegates,
                },
            };

        case SHOW_PROFILE_POPUP:
            return {
                profilePopup: {},
            };

            return draft;

        case CANCEL_ACTIVE_POPUP:
            return {};

        case SET_DELEGATE:
            draft.myDelegatesPopup!.modified = true;

            for (let delegate of draft.myDelegatesPopup!.delegates) {
                if (delegate.companyId === action.payload.companyId) {
                    delegate.delegateId = action.payload.delegateId;
                }
            }

            return draft;

        case SET_DELEGATE_FROM_DATE:
            draft.myDelegatesPopup!.modified = true;

            for (let delegate of draft.myDelegatesPopup!.delegates) {
                if (delegate.companyId === action.payload.companyId) {
                    delegate.delegateFrom = action.payload.delegateFrom;
                }
            }

            return draft;

        case SET_DELEGATE_TO_DATE:
            draft.myDelegatesPopup!.modified = true;

            for (let delegate of draft.myDelegatesPopup!.delegates) {
                if (delegate.companyId === action.payload.companyId) {
                    delegate.delegateTo = action.payload.delegateTo;
                }
            }

            return draft;

        case APPLY_MY_DELEGATES_RESPONSE:
            return {};

        case OPEN_CONSENT_TO_START_TRIAL_POPUP:
            return {
                consentToStartTrialPeriodPopup: {
                    company: action.payload.company,
                    origin: action.payload.origin,
                },
            };

        case OPEN_TRIAL_PERIOD_STARTED_POPUP:
            return {
                trialPeriodStartedPopup: {},
            };

        case OPEN_CONTACT_SALES_POPUP:
            return {
                contactSalesPopup: {},
            };

        case OPEN_CONTACT_SUPPORT_POPUP:
            return {
                contactSupportPopup: {},
            };

        case OPEN_TWO_FA_SOFT_ENFORCEMENT_POPUP:
            return {
                twoFaSoftEnforcementPopup: {},
            };

        default:
            return draft;
    }
}, INITIAL_STATE);
