import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data/api';

import { slackApiPaths } from '../paths';
import { CompleteSlackConnect } from './useCompleteSlackConnect.types';

export const useCompleteSlackConnect = (mutationOptions?: RequestMutationOptions<CompleteSlackConnect>) => {
    return useMutate(slackApiPaths.authCompleteConnect, {
        mutationOptions,
        apiSource: 'slack',
        apiVersion: 'v1',
    });
};
