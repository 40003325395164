import { Mods, mods } from '@approvalmax/theme';
import styled from 'styled-components';

export const Root = styled.div<Mods<'disabled'>>`
    display: flex;
    flex-flow: column;
    width: 100%;
    flex-grow: 1;
    overflow: auto;

    & > :not(:last-child) {
        margin-bottom: 10px;
    }

    ${mods.disabled.true`
        opacity: 0.6;
        pointer-events: none;
    `}
`;
