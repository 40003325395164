import styled from 'styled-components';

import ContactFieldEntryWrapper from '../../../../../components/FieldEntryWrapper/FieldEntryWrapper';
import { OrderCustomMod } from './MainInformationSection.types';

export const Root = styled.div``;

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const FieldOrdered = styled(ContactFieldEntryWrapper)<OrderCustomMod>`
    min-width: 20%;
    order: ${({ order }) => order};
`;
