import { Popup, usePopupContext } from '@approvalmax/ui';
import { mentionHelpers, Text, toast } from '@approvalmax/ui/src/components';
import { GlobalLoadingBar } from 'modules/page';
import { TemplateRequestListFilter } from 'pages/requestList/config';
import { useSelectedFilter } from 'pages/requestList/hooks';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import bemFactory from 'react-bem-factory';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { api } from 'services/api';
import { useCompanyMentionItems } from 'shared/hooks/useCompanyMentionItems';
import { getPath, Path } from 'urlBuilder';

import { reloadRequest } from '../../../actions';
import { addAsWatcherPopupState } from '../../../components/AddAsWatchersPopup/AddAsWatchersPopup.states';
import { getMentionedUsersWithoutAccessToRequest } from '../../../utils/getUsersWithoutAccessToRequest';
import { messages } from './XeroMarkAsBilledPopup.messages';
import { CommentEditor, Content, Description, Root } from './XeroMarkAsBilledPopup.styles';
import { XeroMarkAsBilledPopupProps } from './XeroMarkAsBilledPopup.types';

const qa = bemFactory.qa('reql-xero-mark-as-billed-popup');

export const XeroMarkAsBilledPopup = memo<XeroMarkAsBilledPopupProps>((props) => {
    const { request } = props;
    const dispatch = useDispatch();
    const setWatchersPopup = useSetRecoilState(addAsWatcherPopupState);

    const { onRequestClose } = usePopupContext();
    const selectedFilter = useSelectedFilter();

    const [isLoading, setIsLoading] = useState(false);
    const [commentText, setCommentText] = useState('');

    const { handleGetMentionItems } = useCompanyMentionItems(request.company);
    const { mentionedUserIds } = useMemo(() => mentionHelpers.prepareCommentPayload(commentText), [commentText]);

    const isMarkedAsBilled = request.details.isBilled;

    const popupTitle = isMarkedAsBilled ? messages.unmarkPopupTitle : messages.markPopupTitle;
    const buttonText = isMarkedAsBilled ? messages.unmarkButtonText : messages.markButtonText;
    const buttonTitle = isMarkedAsBilled ? messages.unmarkButtonTitle : messages.markButtonTitle;
    const description = isMarkedAsBilled ? messages.unmarkDescription : messages.markDescription;
    const successToastText = isMarkedAsBilled ? messages.unmarkedSuccessToastText : messages.markedSuccessToastText;

    const handleDoneMarkAsBilled = useCallback(async () => {
        setIsLoading(true);

        try {
            await api.requests.markAsBilled({
                requestId: request.id,
                commentText,
                isBilled: !isMarkedAsBilled,
                companyId: request.companyId,
                mentionedUserIds,
            });
            await dispatch(reloadRequest(request.id, request.companyId));

            if (
                selectedFilter === TemplateRequestListFilter.Billed ||
                selectedFilter === TemplateRequestListFilter.NotBilled
            ) {
                toast.success(
                    <>
                        <Text font='body'>{successToastText}</Text>

                        <Link to={getPath(Path.request, request.id, request.companyId)}>
                            {messages.goTo({ displayName: request.displayName })}
                        </Link>
                    </>
                );
            } else {
                toast.success(successToastText);
            }

            onRequestClose();
        } catch {
            setIsLoading(false);
        }
    }, [
        commentText,
        dispatch,
        isMarkedAsBilled,
        mentionedUserIds,
        onRequestClose,
        request.companyId,
        request.displayName,
        request.id,
        selectedFilter,
        successToastText,
    ]);

    const handleSubmit = useCallback(() => {
        const usersWithoutAccess = getMentionedUsersWithoutAccessToRequest(request, mentionedUserIds);

        if (usersWithoutAccess.length) {
            setWatchersPopup((state) => ({ ...state, usersWithoutAccess }));
        } else {
            handleDoneMarkAsBilled();
        }
    }, [request, mentionedUserIds, setWatchersPopup, handleDoneMarkAsBilled]);

    useEffect(() => {
        setWatchersPopup((state) => ({ ...state, onSubmit: handleDoneMarkAsBilled }));
    }, [handleDoneMarkAsBilled, setWatchersPopup]);

    return (
        <Root
            qa={qa()}
            title={popupTitle}
            buttons={
                <Popup.DefaultContent.Button
                    qa={qa('done-button')}
                    disabled={isLoading}
                    execute={handleSubmit}
                    title={buttonTitle}
                >
                    {buttonText}
                </Popup.DefaultContent.Button>
            }
        >
            <Content>
                <GlobalLoadingBar isLoading={isLoading} />

                <Description>{description}</Description>

                <CommentEditor
                    focusOnMount
                    qa={qa('comment-editor')}
                    value={commentText}
                    onChange={setCommentText}
                    allowMention
                    mentionItems={handleGetMentionItems}
                />
            </Content>
        </Root>
    );
});
