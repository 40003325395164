import './xeroPendingStatusToolbarSection.scss';

import { ExternalLink } from '@approvalmax/ui';
import { intl } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { getLockDateInEffect } from 'modules/common/selectors/requestSelectors.Xero';
import { domain } from 'modules/data';
import { NotSyncedIcon } from 'modules/sprites';
import moment from 'moment';
import { FC, memo, useCallback, useMemo } from 'react';
import bemFactory from 'react-bem-factory';
import { defineMessages, FormattedMessage } from 'react-intl';

const i18nPrefix = 'requestList/containers/xero/XeroPendingStatusToolbarSection';
const messages = defineMessages({
    validationErrorText_lockDateInEffect: {
        id: `${i18nPrefix}.validationErrorText_lockDateInEffect`,
        defaultMessage: 'document date cannot be before the lock date, currently set at {lockDate}',
    },
});

const DEFAULT_URL_IN_LOCK_MSG = 'https://go.xero.com/Setup/FinancialSettings.aspx';
const bem = bemFactory.block('reql-xero-pending-status-toolbar-section');
const qa = bemFactory.qa('reql-xero-pending-status-toolbar-section');

interface XeroPendingStatusToolbarSectionProps {
    className?: string;
    request:
        | selectors.types.XeroBillExpandedRequest
        | selectors.types.XeroInvoiceExpandedRequest
        | selectors.types.XeroBillBatchPaymentExpandedRequest
        | selectors.types.XeroCreditNotesPayableExpandedRequest
        | selectors.types.XeroCreditNotesReceivableExpandedRequest
        | selectors.types.XeroManualJournalExpandedRequest
        | selectors.types.XeroQuoteExpandedRequest;
}

const XeroPendingStatusToolbarSection: FC<XeroPendingStatusToolbarSectionProps> = (props) => {
    const { className, request } = props;

    const getPendingStatusReason = useCallback(() => {
        const details = request.details;
        const lockDateInEffect = getLockDateInEffect(request.details);

        function formatIssues(issues: string[]) {
            if (isManager) {
                if (request.origin === domain.RequestOrigin.ApprovalMax && !details.url) {
                    return (
                        <FormattedMessage
                            id={`${i18nPrefix}.requestAMBlocked_ValidationError_Manager`}
                            defaultMessage={
                                'This request cannot be created in Xero upon final approval due to the following issue: ' +
                                '{issues}.{br}Please update the date of the request or update the lock date in {xeroLink}'
                            }
                            values={{
                                br: <br />,
                                issues: issues.join(', '),
                                xeroLink: (
                                    <ExternalLink href={DEFAULT_URL_IN_LOCK_MSG!}>
                                        {request.company.integration!.displayName}
                                    </ExternalLink>
                                ),
                            }}
                        />
                    );
                } else {
                    return (
                        <FormattedMessage
                            id={`${i18nPrefix}.requestBlocked_ValidationError_Manager`}
                            defaultMessage={
                                'This request cannot be approved in Xero due to the following' +
                                ' issue: {issues}.{br}Please change the document date in {xeroLink} or update the lock date in {xeroLinkToEditFinancialSettings}.'
                            }
                            values={{
                                issues: issues.join(', '),
                                br: <br />,
                                xeroLink: (
                                    <ExternalLink href={details.editUrl!}>
                                        {request.company.integration!.displayName}
                                    </ExternalLink>
                                ),
                                xeroLinkToEditFinancialSettings: (
                                    <ExternalLink href={DEFAULT_URL_IN_LOCK_MSG}>
                                        {request.company.integration!.displayName}
                                    </ExternalLink>
                                ),
                            }}
                        />
                    );
                }
            } else {
                return (
                    <FormattedMessage
                        id={`${i18nPrefix}.requestBlocked_ValidationError_User`}
                        defaultMessage={
                            'This request can not be approved in Xero due to the following ' +
                            'issue: {issues}.{br}Organisation administrators have been notified about this.'
                        }
                        values={{
                            issues: issues.join(', '),
                            br: <br />,
                        }}
                    />
                );
            }
        }

        const isManager = request.company.flags.isManager;
        const hasIssue =
            lockDateInEffect &&
            details.lockDatePolicy === domain.TemplateSettingsLockDatePolicy.LockApproval &&
            [
                domain.RequestStatusV2.Rejected,
                domain.RequestStatusV2.OnApproval,
                domain.RequestStatusV2.OnHold,
            ].includes(request.statusV2);

        if (hasIssue) {
            const issueText = intl.formatMessage(messages.validationErrorText_lockDateInEffect, {
                lockDate: moment(details.lockDate!).format('LL'),
            });

            return formatIssues([issueText]);
        }

        return null;
    }, [
        request.company.flags.isManager,
        request.company.integration,
        request.details,
        request.origin,
        request.statusV2,
    ]);

    const reason = useMemo(() => getPendingStatusReason(), [getPendingStatusReason]);

    if (!reason) {
        return null;
    }

    return (
        <div className={bem.add(className)()} data-qa={qa()}>
            <NotSyncedIcon className={bem('icon')} width={21} height={22} />

            <div className={bem('text')} data-qa={qa('text')}>
                {reason}
            </div>
        </div>
    );
};

export default memo(XeroPendingStatusToolbarSection);
