import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { companiesApiPaths } from '../paths';
import { PullIntegrations } from './usePullIntegrations.types';

export const usePullIntegrations = (mutationOptions?: RequestMutationOptions<PullIntegrations>) => {
    return useMutate(companiesApiPaths.pullIntegrations, {
        mutationOptions,
        apiVersion: 'v1',
    });
};
