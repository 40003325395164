import { FC, memo, useCallback } from 'react';

import { WarningImage } from '../../images';
import Button from '../Button/Button';
import { Flex } from '../Flex/Flex';
import Popup from '../Popup/Popup';
import { Text } from '../Text/Text';
import { messages } from './ConfirmationPopup.messages';
import type { ConfirmationPopupProps } from './ConfirmationPopup.types';

export const ConfirmationPopup: FC<ConfirmationPopupProps> = memo((props) => {
    const {
        confirmMessage,
        confirmButtonMessage,
        confirmButtonColor = 'red40',
        cancelButtonMessage,
        cancelButtonColor = 'midnight80',
        onToggle,
        onConfirm,
        confirmProgress,
        Icon = WarningImage,
        preventAutoClose,
        ...restProps
    } = props;

    const handleCancel = useCallback(() => {
        onToggle?.(false);
    }, [onToggle]);

    return (
        <Popup
            size='xsmall'
            preventAutoClose={confirmProgress || preventAutoClose}
            qa='confirmation'
            {...restProps}
            onToggle={onToggle}
        >
            <Popup.Body spacing='16 32 32 32'>
                <Flex direction='column' spacing='24' alignItems='center'>
                    <Icon />

                    <Text font='headline' fontSize='small' fontWeight='medium' textAlign='center'>
                        {confirmMessage ?? messages.areYouSure}
                    </Text>

                    <Flex spacing='24' justifyContent='center'>
                        <Button
                            color={cancelButtonColor}
                            size='large'
                            disabled={confirmProgress}
                            onClick={handleCancel}
                        >
                            {cancelButtonMessage ?? messages.cancel}
                        </Button>

                        <Button color={confirmButtonColor} size='large' progress={confirmProgress} onClick={onConfirm}>
                            {confirmButtonMessage ?? messages.confirm}
                        </Button>
                    </Flex>
                </Flex>
            </Popup.Body>
        </Popup>
    );
});

ConfirmationPopup.displayName = 'ConfirmationPopup';
