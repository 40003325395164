import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestFormPage.SplitViewWrapper.OcrUploadPanel', {
    confirmButtonTitle: 'Confirm',
    cancelButtonTitle: 'Cancel',
    description: 'Confirm uploading file',
    successText: 'Your data has been extracted',
    errorText: 'ApprovalMax Capture has failed to recognize your file',
    errorButtonText: 'Let`s try again',
    ocrDocumentIsEmptyButtonText: 'Let`s try again',
    ocrDocumentIsEmptyText: 'No data has been extracted from {fileName}. Please upload another file.',
});
