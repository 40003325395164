import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.Toolbar.PayWithAmax.PaymentPopupContent', {
    confirmPayment: 'Securely log in to your banking app and confirm the payment',
    loginOnDesktop: 'Log in on desktop',
    or: 'or',
    paymentTotal: 'Payment total:',
    paymentDate: 'Payment date:',
    payeeName: 'Payee name:',
    payeeAccountNumber: 'Payee account number:',
    payeeSortCode: 'Payee sort code:',
    returnTo: 'Return to ApprovalMax to view updated payment status',
    securelyLogin: 'Scan the QR code to use the mobile app or select log in via desktop',
    weHavePartnered: 'We have partnered with Yapily Connect to access your bank data at {bankName}.',
    termsAndConditions: 'Terms and Conditions',
    privacyNotice: 'Privacy Notice',
    byUsingYouAgree:
        'By using the service, you agree to Yapily Connect accessing your bank data, the {termsAndConditions} and {privacyNotice}',
});
