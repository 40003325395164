import { LazyGetDataParams, QueryOptions } from '@approvalmax/data';
import { useGetLazyLegacy } from 'shared/data';

import { requestsApiPaths } from '../paths';

export const useGetLazyAttachment = (queryOptions?: QueryOptions<Blob>) =>
    useGetLazyLegacy<LazyGetDataParams, Blob>(requestsApiPaths.getAttachment, {
        method: 'get',
        apiVersion: 'v1',
        responseType: 'blob',
        queryOptions,
    });
