import { mixins, mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled, { css } from 'styled-components';

import { Box } from '../Box/Box';
import { DropdownProps } from './Dropdown.types';

export const Arrow = styled.div<StyledMods<Pick<DropdownProps, 'placement' | 'color'>>>`
    position: absolute;

    ${mods(['placement', 'color'] as const)(({ placement, color }) => {
        switch (placement.replace(/-.*/, '')) {
            case 'bottom':
                return css`
                    bottom: 100%;
                    ${mixins.arrow.top(8, color)};
                `;

            case 'left':
                return css`
                    left: 100%;
                    ${mixins.arrow.right(8, color)};
                `;

            case 'right':
                return css`
                    right: 100%;
                    ${mixins.arrow.left(8, color)};
                `;

            default:
                return css`
                    top: 100%;
                    ${mixins.arrow.bottom(8, color)};
                `;
        }
    })};
`;

export const Inner = styled(Box)<StyledMods<Pick<DropdownProps, 'maxHeight' | 'maxWidth'>>>`
    ${mods('maxHeight')(
        (value) =>
            value &&
            css`
                max-height: ${value}px;
                overflow-y: auto;
                -webkit-overflow-scrolling: auto;
            `
    )};

    ${mixins.whByModValue('maxWidth', 'max-width')};
`;

export const Content = styled.div<StyledMods<Pick<DropdownProps, 'open' | 'color'>>>`
    background-color: ${theme.color.white100};
    width: max-content;
    box-shadow: ${theme.shadow.small};
    border-radius: ${theme.radius.xsmall};
    z-index: 1000;

    ${mods.color(mixins.colorTextWithBg)};

    ${mods.open.false`
        display: none;
    `};
`;
