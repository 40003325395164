import { font } from 'modules/styles';
import styled from 'styled-components/macro';

export const Root = styled.div`
    margin-bottom: 20px;

    ${font(11, '#5e5c5c')}
`;

export const LoaderContainer = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
