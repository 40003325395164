import { useGetLegacy } from 'shared/data/api';

import { paymentServicesApiPaths } from '../paths';
import {
    UseAmaxPayOpenBankingBanksOptions,
    UseAmaxPayOpenBankingBanksRequestParams,
    UseAmaxPayOpenBankingBanksRequestPathParams,
    UseAmaxPayOpenBankingBanksResponse,
} from './useAmaxPayOpenBankingBanks.types';

export const useAmaxPayOpenBankingBanks = (
    options: Omit<UseAmaxPayOpenBankingBanksOptions, 'params' | 'pathParams' | 'method'> &
        Pick<NonNullable<UseAmaxPayOpenBankingBanksOptions>, 'queryOptions'> & {
            params?: UseAmaxPayOpenBankingBanksRequestParams;
        } & { pathParams: UseAmaxPayOpenBankingBanksRequestPathParams }
) =>
    useGetLegacy<UseAmaxPayOpenBankingBanksResponse>(paymentServicesApiPaths.getAmaxPayOpenBankingBanks, {
        pathParams: options.pathParams,
        queryParams: options.params,
        queryOptions: options.queryOptions,
        method: 'get',
    });
