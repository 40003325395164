import { MatchingIcon, ShowMatchingIcon } from 'modules/sprites';
import styled from 'styled-components';

export const StyledShowMatchingIcon = styled(ShowMatchingIcon)`
    vertical-align: middle;
`;

export const StyledMatchingIcon = styled(MatchingIcon)`
    vertical-align: middle;
`;
