import { Dropdown } from '@approvalmax/ui';
import { hooks } from '@approvalmax/utils';
import { memo, MouseEventHandler, PropsWithChildren, useCallback } from 'react';

import { messages } from './Disclaimer.messages';
import { Panel, Root, StyledInfoIcon } from './Disclaimer.styles';

const Disclaimer = memo<PropsWithChildren>((props) => {
    const { children } = props;
    const [isOpen, toogleIsOpen] = hooks.useToggle(false);

    const handleOpen = useCallback<MouseEventHandler<SVGSVGElement>>(
        (e) => {
            e.stopPropagation();
            toogleIsOpen();
        },
        [toogleIsOpen]
    );

    const handleClose = useCallback(() => {
        toogleIsOpen(false);
    }, [toogleIsOpen]);

    return (
        <Dropdown
            button={(ref) => (
                <Root ref={ref}>
                    <StyledInfoIcon onClick={handleOpen} />

                    {children}
                </Root>
            )}
            isOpen={isOpen}
            onRequestClose={handleClose}
        >
            <Panel>{messages.text({ br: <br /> })}</Panel>
        </Dropdown>
    );
});

export default Disclaimer;
