import { HelpCircleIcon } from '@approvalmax/ui';
import { Alert, Button, Flex, Popup, Spacing, toast } from '@approvalmax/ui/src/components';
import { RequestListFilter } from 'pages/requestList/config';
import { FC, memo, useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { routingService } from 'services/routing';
import { OcrFileContentType, useUploadOcrDocument } from 'shared/data/v2';
import { getPath, Path } from 'urlBuilder';

import { AdditionalOptions } from './AdditionalOptions/AdditionalOptions';
import { messages } from './BulkCreationPopupContent.messages';
import { bulkCreationPopupContentState } from './BulkCreationPopupContent.states';
import { AttachmentWithInnerId, BulkCreationPopupContentProps } from './BulkCreationPopupContent.types';
import { FileContentType } from './FileContentType/FileContentType';
import { UploadFiles } from './UploadFiles/UploadFiles';

export const BulkCreationPopupContent: FC<BulkCreationPopupContentProps> = memo((props) => {
    const { closePopup } = props;

    const bulkCreationPopupContent = useRecoilValue(bulkCreationPopupContentState);

    const [attachments, setAttachments] = useState<AttachmentWithInnerId[]>([]);
    const [editMode, setEditMode] = useState(false);

    const { mutate, isLoading } = useUploadOcrDocument({
        onSuccess: () => {
            routingService.push(getPath(Path.requestMyList, RequestListFilter.MyOcrRequests));
            toast.success(messages.bulkUploadSuccess({ numberBills: attachments.length }));
        },
        onSettled: () => {
            closePopup();
        },
    });

    const [fileContentType, setFileContentType] = useState(OcrFileContentType.DocumentPerFile);

    const onUpload = useCallback(() => {
        if (!bulkCreationPopupContent.templateIntegrationCode) return;

        mutate({
            params: {
                path: {
                    companyId: bulkCreationPopupContent.companyId,
                    documentType: bulkCreationPopupContent.templateIntegrationCode,
                },
            },
            body: {
                fileUploadOptions: { fileContentType },
                fileRecordIds: attachments.map((attachment) => attachment.id),
                useSingleLine: false,
            },
        });
    }, [attachments, bulkCreationPopupContent, fileContentType, mutate]);

    return (
        <>
            <Popup.Header
                title={messages.title}
                progress={isLoading}
                actions={
                    <Button
                        disabled={editMode || isLoading || attachments.length === 0}
                        color='blue80'
                        type='submit'
                        title={messages.uploadButtonTitle}
                        onClick={onUpload}
                    >
                        {messages.uploadButtonTitle}
                    </Button>
                }
            />

            <Popup.Body>
                {attachments.length > 1 && (
                    <>
                        <Alert startIcon={<HelpCircleIcon />} size='small' color='midnight20' closable>
                            {messages.multipleAttachmentsWarning}
                        </Alert>

                        <Spacing height={16} />
                    </>
                )}

                <Flex container spacing='16'>
                    <FileContentType
                        disabled={isLoading}
                        fileContentType={fileContentType}
                        setFileContentType={setFileContentType}
                    />

                    <AdditionalOptions disabled={isLoading} editMode={editMode} setEditMode={setEditMode} />
                </Flex>

                <UploadFiles
                    disabled={isLoading}
                    attachments={attachments}
                    setAttachments={setAttachments}
                    fileContentType={fileContentType}
                />
            </Popup.Body>
        </>
    );
});

BulkCreationPopupContent.displayName = 'BulkCreationPopupContent';
