import { intl } from '@approvalmax/utils';
import { FC, memo } from 'react';
import styled from 'styled-components';

import ColorBar from '../ColorBar/ColorBar';

const i18nPrefix = 'requestList/containers/xero/XeroMatchingV2Section/components/ColorBarSection/ColorBarSection';

const Container = styled.div`
    width: 100%;
`;

const BarContainer = styled.div`
    width: 100%;
`;

const OverflowText = styled.div`
    width: 100%;
    white-space: pre;
    color: #687883;
    font-size: 12px;
    font-weight: 600;
    color: #a3341a;
`;

interface ColorBarSectionProps {
    blackBarValue?: number;
    darkGreenBarValue?: number;
    lightGreenBarValue?: number;
    totalAmount?: number;
    isOverflowed: boolean;
    currency: string;
}

const ColorBarSection: FC<ColorBarSectionProps> = (props) => {
    const {
        totalAmount = 0,
        blackBarValue = 0,
        darkGreenBarValue = 0,
        lightGreenBarValue = 0,
        isOverflowed,
        currency,
    } = props;

    return (
        <>
            <Container>
                {isOverflowed && (
                    <OverflowText>
                        {intl.formatMessage(
                            {
                                id: `${i18nPrefix}.overflowedAmountText`,
                                defaultMessage:
                                    'The total of all Bills ({amount}) exceeds the Purchase Order amount ({total})',
                            },
                            {
                                amount: intl.formatCurrency(
                                    blackBarValue + darkGreenBarValue + lightGreenBarValue,
                                    currency
                                ),
                                total: intl.formatCurrency(totalAmount, currency),
                            }
                        )}
                    </OverflowText>
                )}

                <BarContainer>
                    <ColorBar
                        blackBarValue={blackBarValue}
                        darkGreenBarValue={darkGreenBarValue}
                        lightGreenBarValue={lightGreenBarValue}
                        totalValue={totalAmount}
                        isOverflowed={isOverflowed}
                        currency={currency}
                    />
                </BarContainer>
            </Container>
        </>
    );
};

export default memo(ColorBarSection);
