import { Route } from 'modules/page';
import { lazy } from 'react';

const CreateRequestPage = lazy(() => import(/* webpackChunkName: "manager-pages" */ './pageCreate'));
const EditRequestPage = lazy(() => import(/* webpackChunkName: "manager-pages" */ './pageEdit'));

const routes: Route[] = [
    {
        path: '/:companyId/requests/:requestId/edit',
        component: EditRequestPage,
    },
    {
        path: '/:companyId/requests/create',
        component: CreateRequestPage,
    },
];

export default routes;
