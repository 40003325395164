import { Flex, NumberField, Text } from '@approvalmax/ui/src/components';
import { FC, memo } from 'react';

import { useValues } from './SelectorDeadline.hooks';
import { messages } from './SelectorDeadline.messages';
import { Title } from './SelectorDeadline.styles';
import { SelectorDeadlineProps } from './SelectorDeadline.types';

const SelectorDeadline: FC<SelectorDeadlineProps> = memo((props) => {
    const { value, onChange, isError, readonly } = props;

    const { days, hours, onDaysChange, onHoursChange } = useValues(value, onChange);

    return (
        <Flex>
            <Title font='label' fontSize='xsmall' color='midnight70'>
                {messages.title}
            </Title>

            <Flex wrap={false} spacing='16'>
                <Flex wrap={false} spacing='2' alignItems='center'>
                    <NumberField
                        onChange={onDaysChange}
                        value={days}
                        size='small'
                        clearable={false}
                        invalid={isError}
                        width={50}
                        disabled={readonly}
                        min={0}
                        max={99}
                    />

                    <Text font='label' fontSize='small' color='midnight70'>
                        {messages.days({ count: days })}
                    </Text>
                </Flex>

                <Flex wrap={false} spacing='2' alignItems='center'>
                    <NumberField
                        onChange={onHoursChange}
                        value={hours}
                        size='small'
                        clearable={false}
                        invalid={isError}
                        width={50}
                        disabled={readonly}
                        min={0}
                        max={23}
                    />

                    <Text font='label' fontSize='small' color='midnight70'>
                        {messages.hours({ count: hours })}
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    );
});

export default SelectorDeadline;
