import { errorHelpers, mathService, numberHelpers, stringHelpers } from '@approvalmax/utils';
import uniqWith from 'lodash/uniqWith';
import { constants } from 'modules/common';
import { backend, domain, du, State } from 'modules/data';
import { emailToSupplierHelpers } from 'shared/helpers';

import { EditableQBooksCustomer, EditableQBooksVendor } from '../../data/qbooks/EditableQBooksCounterparty';
import { isNewQBooksAccountLineItem } from '../../data/qbooks/QBooksAccountLineItem';
import { isNewQBooksLineItem } from '../../data/qbooks/QBooksLineItem';
import { getContext, getRequest } from '../pageSelectors';
import { ExpandedQBooksAccountLineItem } from '../types/ExpandedQBooksAccountLineItem';
import { ExpandedQBooksLineItem } from '../types/ExpandedQBooksLineItem';
import { getQBooksAccountLineItems, getQBooksAccountLineItemsSummary } from './accountLineItemSelectors';
import { getQBooksLineItems, getQBooksLineItemsSummary, mergeSummaries } from './lineItemSelectors';
import { getQBooksContext } from './qbooksSelectors';

function toServerString(
    value: number | null | undefined,
    precision: number = constants.qBooksConstants.PRECISION_TO_SERVER_STRING
) {
    if (value == null) {
        return '';
    }

    return numberHelpers.toString(value, precision);
}

const getQBooksLineItemTransfer = (
    state: State,
    li: ExpandedQBooksLineItem
): backend.Transfer<
    | backend.transfers.IntegrationQBooksQBooksBillInvoiceItemBasedLineTransfer
    | backend.transfers.IntegrationQBooksQBooksExpenseInvoiceItemBasedLineTransfer
    | backend.transfers.IntegrationQBooksSalesInvoiceItemBasedLineTransfer
> => {
    const id = isNewQBooksLineItem(li) ? '' : li.id || '';
    const qBooksContext = getQBooksContext(state);

    return {
        id,
        description: li.description?.trim() || '',
        taxCode: du.getReferenceId(li.taxCode) || '',
        classId: du.getReferenceId(li.class) || '',
        customerId: du.getReferenceId(li.customer) || '',
        matchedLineItemId: li.matchedLineItemId,
        amount: toServerString(li.computedAmount),
        itemId: du.getReferenceId(li.item) || '',
        qty: toServerString(li.qty),
        unitPrice: toServerString(li.unitPrice),
        unitPriceGross: toServerString(li.unitPriceGross),
        isBillable: li.isBillable,
        isTaxable: li.isTaxable,
        serviceDate: qBooksContext.salesFormOptionalFields?.serviceDateEnabled ? li.serviceDate : '',
    };
};

function getQBooksAccountLineItemTransfer(
    li: ExpandedQBooksAccountLineItem
): backend.Transfer<
    | backend.transfers.IntegrationQBooksQBooksBillInvoiceAccountBasedLineTransfer
    | backend.transfers.IntegrationQBooksQBooksExpenseInvoiceAccountBasedLineTransfer
> {
    const id = isNewQBooksAccountLineItem(li) ? '' : li.id || '';

    return {
        id,
        description: li.description || '',
        taxCode: du.getReferenceId(li.taxCode) || '',
        classId: du.getReferenceId(li.class) || '',
        customerId: du.getReferenceId(li.customer) || '',
        matchedLineItemId: li.matchedLineItemId,
        amount: toServerString(li.amount),
        accountId: du.getReferenceId(li.account) || '',
        isBillable: li.isBillable,
        isTaxable: li.isTaxable,
    };
}

function getQBooksPurchaseOrderDetailsTransfer(
    state: State,
    request: domain.QBooksPoRequest
): backend.Transfer<backend.transfers.IntegrationQBooksQBooksPurchaseOrderTransfer> {
    const details = request.details;
    const allCustomFields = getContext(state).fields.filter(
        (f) => f.systemPurpose === domain.FieldSystemPurpose.QBooksCustom
    );

    const summary = mergeSummaries(
        getQBooksLineItemsSummary(state, request),
        getQBooksAccountLineItemsSummary(state, request)
    );

    const qbooksContext = getQBooksContext(state);

    return {
        date: details.date || '',
        memo: details.memo || '',
        vendorId: du.getReferenceId(details.vendor) || '',
        mailingAddress: details.mailingAddress,
        lineItems: getQBooksLineItems(state, request)
            .filter((li) => !li.empty)
            .map((li) => getQBooksLineItemTransfer(state, li)),
        accountBasedLines: getQBooksAccountLineItems(state, request)
            .filter((li) => !li.empty)
            .map(getQBooksAccountLineItemTransfer),
        lineAmountType: details.lineAmountType || domain.LineAmountType.NoTax,
        subTotal: summary.subtotalAmount,
        totalTax: mathService.subtract(summary.totalAmount, summary.subtotalAmount),
        taxComponents: summary.taxComponents.map((x) => ({
            taxAmount: x.taxAmount,
            taxPercent: x.taxPercent,
            taxableAmount: x.taxableAmount,
            taxRateId: x.taxRateId,
            hidden: x.hidden,
        })),
        departmentId: qbooksContext.hasDepartmentFeature ? du.getReferenceId(details.department) || '' : '',
        vendorMessage: details.vendorMessage || '',
        shippingAddress: details.shipping && details.shipping.address ? details.shipping.address : '',
        shippingMethod: details.shipping && details.shipping.method ? details.shipping.method : '',
        customFields: allCustomFields.map((cf): backend.transfers.QBooksCustomFieldTransfer => {
            const valueField = details.customFields.find((x) => x.field.id === cf.id);

            return {
                id: cf.id,
                name: cf.text,
                value: valueField && valueField.value ? valueField.value : '',
            };
        }),
    };
}

const getQbooksEmailToContact = (
    contact: domain.QBooksContact | null,
    emailToContact: domain.EmailToSupplier | null,
    sendToSupplier?: boolean
): backend.transfers.EmailToContact | undefined => {
    const emailsToContactTo =
        emailToContact?.to
            .map((toEmailString) => toEmailString.split(','))
            .flat()
            .map((toEmail) => stringHelpers.parseEmail(toEmail))
            .filter((toEmail) => toEmail) || [];
    const emailTo = uniqWith(emailsToContactTo, (a, b) => a?.toLocaleLowerCase() === b?.toLocaleLowerCase()).join(',');

    const emailsToContactCC =
        emailToContact?.cc
            .map((ccEmailString) => ccEmailString.split(','))
            .flat()
            .map((ccEmail) => stringHelpers.parseEmail(ccEmail))
            .filter((ccEmail) => ccEmail && !emailTo.toLocaleLowerCase().includes(ccEmail.toLocaleLowerCase())) || [];

    const emailCC = uniqWith(emailsToContactCC, (a, b) => a?.toLocaleLowerCase() === b?.toLocaleLowerCase()).join(',');

    const contactPerson = contact?.contactPersons?.find((p) => Boolean(p.firstName)) || null;

    let subject = emailToSupplierHelpers.removeRootParagraph(emailToContact?.subject || '');

    subject = emailToSupplierHelpers.cleanupSubjectString(subject);

    let emailSubject = emailToSupplierHelpers
        .removeMentionExtensionTags(subject)
        .replaceAll('[CONTACTPERSONNAME]', contactPerson?.firstName || '');

    emailSubject = emailToSupplierHelpers.returnExpressions(emailSubject);

    const emailBody = emailToSupplierHelpers
        .removeMentionExtensionTags(emailToContact?.body || '')
        .replaceAll('[CONTACTPERSONNAME]', contactPerson?.firstName || '');

    return emailToContact
        ? {
              emailHasToBeSent: sendToSupplier ?? Boolean(emailToContact.emailHasToBeSent),
              emailTo,
              emailCC,
              emailFrom: emailToContact.from || '',
              emailReplyTo: emailToContact.replyTo || '',
              emailSubject,
              emailBody,
              attachments: emailToContact.attachments.map(({ id }) => id) || [],
          }
        : undefined;
};

const getQbooksEmailToContactTransfer = (request: domain.QBooksRequest) => {
    switch (request.integrationCode) {
        case domain.IntegrationCode.QBooksInvoice: {
            const { customer, emailToContact } = request.details;

            return getQbooksEmailToContact(customer, emailToContact);
        }

        case domain.IntegrationCode.QBooksPo: {
            const { vendor, emailToSupplier, sendToSupplier } = request.details;

            return getQbooksEmailToContact(vendor, emailToSupplier, sendToSupplier);
        }

        default: {
            return undefined;
        }
    }
};

const getQBooksSalesInvoiceDetailsTransfer = (
    state: State,
    request: domain.QBooksInvoiceRequest
): backend.Transfer<backend.transfers.IntegrationQBooksSalesInvoiceTransfer> => {
    const { details } = request;
    const { hasDepartmentFeature } = getQBooksContext(state);

    const allInvoiceCustomFields = getContext(state).fields.filter(
        ({ systemPurpose }) => systemPurpose === domain.FieldSystemPurpose.QBooksInvoiceCustom
    );

    const summary = getQBooksLineItemsSummary(state, request);

    const lineItems = getQBooksLineItems(state, request)
        .filter((li) => !li.empty)
        .map((li) => getQBooksLineItemTransfer(state, li));

    const customFields = allInvoiceCustomFields.map((customField): backend.transfers.QBooksCustomFieldTransfer => {
        const valueField = details.customFields.find(({ field }) => field.id === customField.id);

        return {
            id: customField.id,
            name: customField.text,
            value: valueField?.value ?? '',
        };
    });

    return {
        applyTaxAfterDiscount: details.applyTaxAfterDiscount,
        billingAddress: details.billingAddress,
        customFields,
        customerId: du.getReferenceId(details.customer) || '',
        customerMemo: details.customerMemo,
        date: details.date || '',
        departmentId: hasDepartmentFeature ? du.getReferenceId(details.department) || '' : undefined,
        deposit: details.deposit,
        dueDate: details.dueDate || '',
        lineAmountType: details.lineAmountType || domain.LineAmountType.NoTax,
        lineItems,
        memo: details.memo,
        number: details.number,
        shipping:
            details.lineAmountType === domain.LineAmountType.TaxInclusive
                ? details.shipping.priceGross
                : details.shipping.price,
        shippingAddress: details.shipping.address ?? '',
        shippingDate: details.shipping.date ?? '',
        shippingFromAddress: details.shipping.fromAddress ?? '',
        shippingMethod: details.shipping.method ?? '',
        shippingTaxCodeId: du.getReferenceId(details.shippingTaxCode),
        subTotal: summary.subtotalAmount,
        taxCodeId: details.useAutoTaxes ? undefined : du.getReferenceId(details.taxCode),
        taxComponents: summary.taxComponents,
        termId: du.getReferenceId(details.term),
        ...(details.discountType === domain.QBooksDiscountType.Percent
            ? { discount: details.discount }
            : { discountAmount: details.discount }),
        trackingNumber: details.shipping.trackingNumber || '',
        useAutoTaxes: details.useAutoTaxes,
    };
};

function getQBooksExpenseDetailsTransfer(state: State, request: domain.QBooksExpenseRequest): backend.Transfer<any> {
    const details = request.details;

    const summary = mergeSummaries(
        getQBooksLineItemsSummary(state, request),
        getQBooksAccountLineItemsSummary(state, request)
    );

    const qbooksContext = getQBooksContext(state);

    return {
        date: details.date || '',
        memo: details.memo || '',
        vendorId: du.getReferenceId(details.vendor) || '',
        mailingAddress: details.mailingAddress,
        lineItems: getQBooksLineItems(state, request)
            .filter((li) => !li.empty)
            .map((li) => getQBooksLineItemTransfer(state, li)),
        accountBasedLines: getQBooksAccountLineItems(state, request)
            .filter((li) => !li.empty)
            .map(getQBooksAccountLineItemTransfer),
        lineAmountType: details.lineAmountType || domain.LineAmountType.NoTax,
        subTotal: summary.subtotalAmount,
        totalTax: mathService.subtract(summary.totalAmount, summary.subtotalAmount),
        taxComponents: summary.taxComponents.map((x) => ({
            taxAmount: x.taxAmount,
            taxPercent: x.taxPercent,
            taxableAmount: x.taxableAmount,
            taxRateId: x.taxRateId,
            hidden: x.hidden,
        })),
        departmentId: qbooksContext.hasDepartmentFeature ? du.getReferenceId(details.department) || '' : '',
        expenseNumber: details.expenseNumber,
        paymentAccountId: du.getReferenceId(details.paymentAccount) || '',
        paymentMethodId: du.getReferenceId(details.paymentMethod) || '',
        paymentType: du.getReferenceId(details.paymentType) || '',
        payee: details.payeeType
            ? {
                  id: du.getReferenceId(details.payee) || '',
                  type: du.getReferenceId(details.payeeType),
              }
            : null,
    };
}

function getQBooksBillDetailsTransfer(state: State, request: domain.QBooksBillRequest): backend.Transfer<any> {
    const details = request.details;

    const summary = mergeSummaries(
        getQBooksLineItemsSummary(state, request),
        getQBooksAccountLineItemsSummary(state, request)
    );

    const qbooksContext = getQBooksContext(state);

    return {
        date: details.date || '',
        memo: details.memo || '',
        vendorId: du.getReferenceId(details.vendor) || '',
        mailingAddress: details.mailingAddress,
        lineItems: getQBooksLineItems(state, request)
            .filter((li) => !li.empty)
            .map((li) => getQBooksLineItemTransfer(state, li)),
        accountBasedLines: getQBooksAccountLineItems(state, request)
            .filter((li) => !li.empty)
            .map(getQBooksAccountLineItemTransfer),
        lineAmountType: details.lineAmountType || domain.LineAmountType.NoTax,
        subTotal: summary.subtotalAmount,
        totalTax: mathService.subtract(summary.totalAmount, summary.subtotalAmount),
        taxComponents: summary.taxComponents.map((x) => ({
            taxAmount: x.taxAmount,
            taxPercent: x.taxPercent,
            taxableAmount: x.taxableAmount,
            taxRateId: x.taxRateId,
            hidden: x.hidden,
        })),
        departmentId: qbooksContext.hasDepartmentFeature ? du.getReferenceId(details.department) || '' : '',
        dueDate: details.dueDate,
        billInvoiceNumber: details.number,
        termId: du.getReferenceId(details.term) || '',
    };
}

function getQBooksVendorDetailsTransfer(
    state: State,
    request: domain.QBooksVendorRequest
): backend.Transfer<backend.transfers.IntegrationQBooksQBooksVendorTransfer> {
    return {
        ...request.details,
        displayName: request.details.displayName?.trim().replace(/\s+/g, ' ') || '',
        currencyCode: request.details.currencyCode?.id,
    };
}

export function getQBooksRequestTransfer(
    state: State,
    request: domain.QBooksRequest
): backend.Transfer<backend.transfers.RequestEditTransfer> {
    let result: backend.Transfer<
        backend.transfers.RequestEditTransfer & {
            templateVersion: number;
        }
    > = {
        // TODO: Remove backend typing stub when backend is ready
        requestId: request.id,
        currency: request.currency,
        exchangeRate: request.exchangeRate,
        exchangeRateDate: request.exchangeRateDate,
        exchangeRateSource: request.exchangeRateSource,
        attachments: request.attachments.map((a) => a.id),
        requestNote: request.requestNote.value,
        templateVersion: getContext(state).templateVersion,
        companyId: request.companyId,
    };

    switch (request.integrationCode) {
        case domain.IntegrationCode.QBooksPo: {
            const summary = mergeSummaries(
                getQBooksLineItemsSummary(state, request),
                getQBooksAccountLineItemsSummary(state, request)
            );

            result.qBooksPurchaseOrderDetails = getQBooksPurchaseOrderDetailsTransfer(state, request);
            result.amount = summary.totalAmount;
            result.emailToContact = getQbooksEmailToContactTransfer(request);
            break;
        }

        case domain.IntegrationCode.QBooksInvoice: {
            const summary = getQBooksLineItemsSummary(state, request);

            result.qBooksSalesInvoiceDetails = getQBooksSalesInvoiceDetailsTransfer(state, request);
            result.amount = summary.totalAmount;
            result.emailToContact = getQbooksEmailToContactTransfer(request);
            break;
        }

        case domain.IntegrationCode.QBooksBill: {
            const summary = mergeSummaries(
                getQBooksLineItemsSummary(state, request),
                getQBooksAccountLineItemsSummary(state, request)
            );

            result.qBooksBillInvoiceDetails = getQBooksBillDetailsTransfer(state, request);
            result.amount = summary.totalAmount;
            break;
        }

        case domain.IntegrationCode.QBooksExpense: {
            const summary = mergeSummaries(
                getQBooksLineItemsSummary(state, request),
                getQBooksAccountLineItemsSummary(state, request)
            );

            result.qBooksExpenseDetails = getQBooksExpenseDetailsTransfer(state, request);
            result.amount = summary.totalAmount;
            break;
        }

        case domain.IntegrationCode.QBooksVendor:
            result.qBooksVendorDetails = getQBooksVendorDetailsTransfer(state, request);
            break;

        default:
            throw errorHelpers.notSupportedError();
    }

    return result;
}

export function getQBooksRequestVendorTransfer(
    state: State,
    request: domain.QBooksVendorRequest,
    commentText: string
): backend.Transfer<backend.transfers.RequestEditTransfer> {
    let result: backend.Transfer<
        backend.transfers.RequestEditTransfer & {
            templateVersion: number;
        }
    > = {
        requestId: request.id,
        currency: request.currency,
        attachments: request.attachments.map((a) => a.id),
        requestNote: request.requestNote.value,
        templateVersion: getContext(state).templateVersion,
        commentText,
        companyId: request.companyId,
    };

    (result as any).qBooksVendorDetails = getQBooksVendorDetailsTransfer(state, request);

    return result;
}

export function getEditableQBooksVendorTransfer(
    state: State,
    vendor: EditableQBooksVendor
): backend.QBooksCompanyIntegrationsCommandsCreateVendor {
    return {
        CompanyId: getRequest(state).companyId,
        Vendor: {
            Title: vendor.title,
            FirstName: vendor.givenName,
            MiddleName: vendor.middleName,
            LastName: vendor.familyName,
            Suffix: vendor.suffix,
            DisplayName: vendor.displayName,
            PrintOnCheckName: null,
            CompanyName: vendor.companyName,
            Address: {
                Street: vendor.billingAddress.street,
                City: vendor.billingAddress.city,
                Region: vendor.billingAddress.state,
                PostCode: vendor.billingAddress.postalCode,
                Country: vendor.billingAddress.country,
            },
            Notes: null,
            EmailAddress: vendor.email,
            Phone: vendor.primaryPhone,
            Mobile: vendor.mobilePhone,
            Fax: vendor.fax,
            Other: null,
            WebSite: vendor.website,
            BillingRate: null,
            TermId: null,
            OpeningBalance: null,
            OpeningBalanceDate: null,
            AccountNumber: vendor.acctNum,
            BusinessNumber: null,
            Vendor1099: null,
            CurrencyCode: vendor.currency?.id,
        },
    };
}

export function getEditableQBooksCustomerTransfer(
    state: State,
    customer: EditableQBooksCustomer
): backend.QBooksCompanyIntegrationsCommandsCreateCustomer {
    return {
        CompanyId: getRequest(state).companyId,
        Customer: {
            Title: customer.title,
            FirstName: customer.givenName,
            MiddleName: customer.middleName,
            LastName: customer.familyName,
            Suffix: customer.suffix,
            DisplayName: customer.displayName,
            PrintOnCheckName: null,
            CompanyName: customer.companyName,
            BillingAddress: {
                Street: customer.billingAddress.street,
                City: customer.billingAddress.city,
                Region: customer.billingAddress.state,
                PostCode: customer.billingAddress.postalCode,
                Country: customer.billingAddress.country,
            },
            ShippingAddress: {
                Street: customer.shippingAddress.street,
                City: customer.shippingAddress.city,
                Region: customer.shippingAddress.state,
                PostCode: customer.shippingAddress.postalCode,
                Country: customer.shippingAddress.country,
            },
            Notes: null,
            EmailAddress: customer.email,
            Phone: customer.primaryPhone,
            Mobile: customer.mobilePhone,
            Fax: customer.fax,
            Other: null,
            WebSite: customer.website,
            TermId: null,
            OpeningBalance: null,
            OpeningBalanceDate: null,
            BusinessNumber: null,
            CurrencyCode: customer.currency?.id,
        },
    };
}
