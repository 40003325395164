import { AddIcon, ChevronRightIcon } from '@approvalmax/ui';
import { Button, Flex } from '@approvalmax/ui/src/components';
import { FC, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { amplitudeService } from 'services/amplitude';

import { addStep } from '../../actions';
import { getActiveTemplate, isPageReadonly } from '../../selectors/pageSelectors';
import { messages } from './AddNewStepButton.messages';
import { AddNewStepButtonProps } from './AddNewStepButton.types';

const AddNewStepButton: FC<AddNewStepButtonProps> = memo((props) => {
    const { hideArrow } = props;
    const dispatch = useDispatch();
    const readonly = useSelector(isPageReadonly);
    const template = useSelector(getActiveTemplate);

    const handleAddStep = useCallback(() => {
        dispatch(addStep());

        amplitudeService.sendData('workflow: add or remove step', {
            workflow: template?.integrationCode?.toLocaleLowerCase() || 'standalone',
            'action type': 'add',
        });
    }, [dispatch, template?.integrationCode]);

    if (readonly) {
        return null;
    }

    return (
        <>
            <div>
                <Button
                    startIcon={<AddIcon size={12} />}
                    size='small'
                    color='blue80'
                    outline
                    onClick={handleAddStep}
                    title={messages.title}
                    data-starter-guide='wf_set_up_add_steps'
                >
                    {messages.step}
                </Button>
            </div>

            {!hideArrow && (
                <Flex height={28} alignItems='center'>
                    <ChevronRightIcon size={16} color='midnight60' />
                </Flex>
            )}
        </>
    );
});

export default AddNewStepButton;
