import './integrationErrorPopup.scss';

import { Link, Popup, TextButton } from '@approvalmax/ui';
import { selectors } from 'modules/common';
import { State } from 'modules/data';
import { ConnectedProps } from 'modules/react-redux';
import { BigWarnIcon } from 'modules/sprites';
import React from 'react';
import bemFactory from 'react-bem-factory';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { getPath, Path } from 'urlBuilder';

import { cancelActivePopup } from '../actions';
import { IntegrationErrorPopupData } from '../reducers/module/activePopup/integrationErrorPopupReducer';
import { getActivePopup } from '../selectors/moduleSelectors';

const i18nPrefix = 'requestForm/containers/IntegrationErrorPopup';

function mapStateToProps(state: State) {
    const companyId = getActivePopup<IntegrationErrorPopupData>(state).companyId;
    const company = selectors.company.getCompanyById(state, companyId);

    return {
        company,
    };
}

const mapDispatchToProps = { cancelActivePopup };

interface OwnProps {
    className?: string;
}

type Props = ConnectedProps<OwnProps, typeof mapStateToProps, typeof mapDispatchToProps>;

class IntegrationErrorPopup extends React.Component<Props> {
    public render() {
        const { className, company } = this.props;
        const bem = bemFactory.block('reqf-integration-error-popup');
        const qa = bemFactory.qa('reqf-integration-error-popup');

        const isManager = company.flags.isManager;
        const isConnected = company.flags.hasActiveIntegration;

        let title;
        let description;

        if (isConnected) {
            title = (
                <FormattedMessage
                    id={`${i18nPrefix}.connectedTitle`}
                    defaultMessage='{integrationName} connection error'
                    values={{ integrationName: company.integration!.displayName }}
                />
            );

            if (isManager) {
                description = (
                    <FormattedMessage
                        id={`${i18nPrefix}.connectedManagerDescription`}
                        defaultMessage={
                            'Request can not be created due to an error. ' +
                            'Sorry for the inconvenience. Please try again later. ' +
                            'You are welcome to <supportlink>' +
                            'contact ApprovalMax Support</supportlink> for assistance.'
                        }
                        values={{
                            supportlink: (chunks: any) => <a href='mailto:support@approvalmax.com'>{chunks}</a>,
                        }}
                    />
                );
            } else {
                description = (
                    <FormattedMessage
                        id={`${i18nPrefix}.connectedUserDescription`}
                        defaultMessage={
                            'Request can not be created due to an error. ' +
                            'Sorry for the inconvenience. Please try again later.'
                        }
                    />
                );
            }
        } else {
            title = (
                <FormattedMessage
                    id={`${i18nPrefix}.disconnectedTitle`}
                    defaultMessage='Disconnected from {integrationName}'
                    values={{ integrationName: company.integration!.displayName }}
                />
            );

            if (isManager) {
                description = (
                    <FormattedMessage
                        id={`${i18nPrefix}.disconnectedManagerDescription`}
                        defaultMessage={
                            'Request can not be created since {integrationName} connection has been disabled. ' +
                            'Please {link}.'
                        }
                        values={{
                            integrationName: company.integration!.displayName,
                            link: (
                                <Link
                                    href={getPath(Path.companyWorkflows, company.id)}
                                    onClick={this.props.cancelActivePopup}
                                >
                                    <FormattedMessage
                                        id={`${i18nPrefix}.reconnectLinkText`}
                                        defaultMessage='reconnect to {integrationName}'
                                        values={{
                                            integrationName: company.integration!.displayName,
                                        }}
                                    />
                                </Link>
                            ),
                        }}
                    />
                );
            } else {
                description = (
                    <FormattedMessage
                        id={`${i18nPrefix}.disconnectedUserDescription`}
                        defaultMessage={
                            'Request can not be created since {integrationName} connection has been disabled. ' +
                            'Company administrator has been informed about this. Sorry for the inconvenience.'
                        }
                        values={{
                            integrationName: company.integration!.displayName,
                        }}
                    />
                );
            }
        }

        return (
            <Popup.EmptyContent className={bem.add(className)()} qa={qa()}>
                <BigWarnIcon className={bem('icon')} width={70} height={60} />

                <div className={bem('title')}>{title}</div>

                <div className={bem('description')}>{description}</div>

                <div className={bem('close-button-wrp')}>
                    <TextButton execute={this.props.cancelActivePopup} qa={qa('close-button')}>
                        <FormattedMessage id={`${i18nPrefix}.closePopup`} defaultMessage='Close' />
                    </TextButton>
                </div>
            </Popup.EmptyContent>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationErrorPopup);
