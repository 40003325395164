import { Flex, Text } from '@approvalmax/ui/src/components';
import { intl, mathService } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { PriceCheckIndicator } from 'modules/request';
import { DoneIcon } from 'modules/sprites';
import moment from 'moment';

import { PriceIndicator } from '../../NetSuiteRequestCard.styles';
import { messages } from './NetSuiteExpenses.messages';
import { ExpensesColumnDefinition, ExpensesFieldSettings } from './NetSuiteExpenses.types';

export const getColumnDefs = (
    integrationCode: domain.IntegrationCode,
    fieldSettings: ExpensesFieldSettings,
    exchangeRate?: number | null
): ExpensesColumnDefinition[] => {
    const columnDef: ExpensesColumnDefinition[] = [
        {
            id: 'category',
            name: messages.categoryColumnName,
            value: (li) => (li.category ? li.category.text : null),
            minWidth: 160,
            isHidden: fieldSettings.category === domain.DocumentFieldState.Hidden,
        },
        {
            id: 'account',
            name: messages.accountColumnName,
            value: (li) => (li.account ? li.account.text : null),
            minWidth: 150,
        },
        {
            id: 'amount',
            name: messages.amountColumnName,
            value: (li) => {
                let amount = li.amount || 0;

                if (typeof exchangeRate === 'number') {
                    amount = mathService.multiply(amount, exchangeRate);
                    amount = mathService.round(amount, 2);
                }

                return intl.formatNumber(amount, 'auto');
            },
            alignRight: true,
            cell: (value, li) => {
                let amount = typeof li.amount === 'number' ? li.amount : undefined;
                let defaultRate = typeof li.defaultRate === 'number' ? li.defaultRate : undefined;

                if (typeof exchangeRate === 'number') {
                    amount =
                        typeof amount === 'number'
                            ? mathService.round(mathService.multiply(amount, exchangeRate), 2)
                            : undefined;
                    defaultRate =
                        typeof defaultRate === 'number'
                            ? mathService.round(mathService.multiply(defaultRate, exchangeRate), 2)
                            : undefined;
                }

                return (
                    <PriceCheckIndicator
                        actualPrice={amount}
                        expectedPrice={defaultRate}
                        integrationType={domain.IntegrationType.NetSuite}
                        integrationCode={integrationCode}
                    >
                        {({ warningLevel }) => (
                            <Flex alignItems='center' justifyContent='end' spacing='0' wrap={false}>
                                {value}

                                {warningLevel !== PriceCheckIndicator.WarningLevel.None && (
                                    <PriceIndicator warningLevel={warningLevel} />
                                )}
                            </Flex>
                        )}
                    </PriceCheckIndicator>
                );
            },
        },
        {
            id: 'taxCode',
            name: messages.taxCodeColumnName,
            value: (li) => (li.taxCode ? li.taxCode.text : null),
            minWidth: 100,
            cell: (value) => (
                <Text font='body' wrap={false}>
                    {value}
                </Text>
            ),
            isHidden: fieldSettings.taxCode === domain.DocumentFieldState.Hidden,
        },
        {
            id: 'taxRate',
            name: messages.taxRateColumnName,
            value: (li) =>
                typeof li.taxRate === 'number'
                    ? intl.formatNumber(mathService.round(li.taxRate, 2) || 0, 'auto')
                    : li.taxRate,
            alignRight: true,
            cell: (value) => (
                <Text font='body' wrap={false}>
                    {value}%
                </Text>
            ),
            isHidden: fieldSettings.taxRate === domain.DocumentFieldState.Hidden,
        },
        {
            id: 'taxAmount',
            name: messages.taxAmountColumnName,
            value: (li) => {
                let taxAmount = li.taxAmount || 0;

                if (typeof exchangeRate === 'number') {
                    taxAmount = mathService.multiply(taxAmount, exchangeRate);
                    taxAmount = mathService.round(taxAmount, 2);
                }

                return intl.formatNumber(taxAmount || 0, 'auto');
            },
            alignRight: true,
            cell: (value) => (
                <Text font='body' wrap={false}>
                    {value}
                </Text>
            ),
            isHidden: fieldSettings.taxAmount === domain.DocumentFieldState.Hidden,
        },
        {
            id: 'memo',
            name: messages.memoColumnName,
            value: (li) => li.memo,
            minWidth: 115,
            isHidden: fieldSettings.memo === domain.DocumentFieldState.Hidden,
        },
        {
            id: 'department',
            name: messages.departmentColumnName,
            value: (li) => (li.department ? li.department.text : null),
            minWidth: 150,
            isHidden: fieldSettings.department === domain.DocumentFieldState.Hidden,
        },
        {
            id: 'class',
            name: messages.classColumnName,
            value: (li) => (li.class ? li.class.text : null),
            minWidth: 100,
            isHidden: fieldSettings.class === domain.DocumentFieldState.Hidden,
        },
        {
            id: 'location',
            name: messages.locationColumnName,
            value: (li) => (li.location ? li.location.text : null),
            minWidth: 100,
            isHidden: fieldSettings.location === domain.DocumentFieldState.Hidden,
        },
        {
            id: 'customer',
            name: messages.customerColumnName,
            value: (li) => (li.customer ? li.customer.text : null),
            minWidth: 100,
            isHidden: fieldSettings.customer === domain.DocumentFieldState.Hidden,
        },
        {
            id: 'isBillable',
            name: messages.billableColumnName,
            value: (li) => {
                return li.isBillable ? messages.billableColumnTooltip : null;
            },
            width: 50,
            cell: (val) => val && <DoneIcon width={13} height={10} />,
            isHidden: fieldSettings.isBillable === domain.DocumentFieldState.Hidden,
        },
        {
            id: 'amortizationSchedule',
            name: messages.amortizationSchedule,
            value: (li) => (li.amortizationSchedule ? li.amortizationSchedule.text : null),
            minWidth: 100,
            isHidden: fieldSettings.amortizationSchedule === domain.DocumentFieldState.Hidden,
        },
        {
            id: 'amortizationStartDate',
            name: messages.amortizationStartDate,
            value: (li) => (li.amortizationStartDate ? moment.utc(li.amortizationStartDate).format('ll') : null),
            minWidth: 100,
            isHidden: fieldSettings.amortizationStartDate === domain.DocumentFieldState.Hidden,
        },
        {
            id: 'amortizationEndDate',
            name: messages.amortizationEndDate,
            value: (li) => (li.amortizationEndDate ? moment.utc(li.amortizationEndDate).format('ll') : null),
            minWidth: 100,
            isHidden: fieldSettings.amortizationEndDate === domain.DocumentFieldState.Hidden,
        },
        {
            id: 'amortizationResidual',
            name: messages.amortizationResidual,
            value: (li) => {
                if (typeof li.amortizationResidual === 'number') {
                    return intl.formatNumber(li.amortizationResidual, 'auto');
                }

                return null;
            },
            alignRight: true,
            cell: (value) => (
                <Text font='body' wrap={false}>
                    {value}
                </Text>
            ),
            minWidth: 100,
            isHidden: fieldSettings.amortizationSchedule === domain.DocumentFieldState.Hidden,
        },
    ];

    return columnDef.filter((col) => !col.isHidden);
};
