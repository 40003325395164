import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.NetSuiteRequestCard.RequestCardVendor', {
    vendorIdFieldText: 'Vendor id',
    typeFieldText: 'Type',
    companyNameFieldText: 'Company name',
    webAddressFieldText: 'Web address',
    salutationFieldText: 'Salutation',
    firstNameFieldText: 'First name',
    middleNameFieldText: 'Middle name',
    lastNameFieldText: 'Last name',
    jobTitleFieldText: 'Job Title',
    categoryFieldText: 'Category',
    commentsFieldText: 'Comments',
});
