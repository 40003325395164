import { font } from '@approvalmax/ui';
import styled from 'styled-components';

export const Root = styled.div`
    height: 130px;
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid #dfdfdf;
`;

export const Header = styled.div`
    ${font(14, '#000000', 'semibold')}
    margin-bottom: 20px;
    text-transform: uppercase;
`;

export const Name = styled.div`
    ${font(11, '#384650', 'semibold')}
    margin-bottom: 3px;
`;

export const Value = styled.div`
    ${font(13, '#000')}
`;
