import { errorHelpers } from '@approvalmax/utils';
import { useRouteMatch } from 'react-router-dom';

export const useCompanyIdBasedOnUrl = () => {
    const match = useRouteMatch<{ companyId: string }>('/:companyId');

    if (!match?.params.companyId) {
        throw errorHelpers.notFoundError(`Company id not found in URL`);
    }

    return { companyId: match.params.companyId };
};
