import { TransparentButton } from '@approvalmax/ui';
import { DropdownTriangleIcon } from 'modules/sprites';
import { font } from 'modules/styles';
import { FC, PropsWithChildren, useState } from 'react';
import styled, { css } from 'styled-components';
import { VelocityTransitionGroup } from 'velocity-react';

const ToggleButton = styled(TransparentButton)`
    display: flex;
    align-items: center;
    padding: 10px 0 20px;
    fill: #477753;
    transition: fill 500ms ease-in-out;
    cursor: pointer;
`;

const StyledIconWrapper = styled.div<{ expanded: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;

    svg {
        transform: rotate(180deg);
        transition: transform 300ms ease-in-out;
    }

    ${(props) =>
        props.expanded &&
        css`
            svg {
                transform: rotate(0);
            }
        `}
`;

const SectionTitle = styled.span`
    margin-right: 5px;
    text-transform: uppercase;
    ${font(14, '#000', 'semibold')}
`;

const ChildrenContainer = styled.div`
    padding: 0 60px;
`;

interface CollapsibleSectionProps extends PropsWithChildren {
    defaultExpanded?: boolean;
    title: string;
}

const CollapsibleSection: FC<CollapsibleSectionProps> = (props) => {
    const { defaultExpanded = false, children, title } = props;

    const [expanded, setExpanded] = useState(defaultExpanded);

    const onToggleExpanded = () => {
        setExpanded((prevState) => !prevState);
    };

    return (
        <div>
            <ToggleButton execute={onToggleExpanded}>
                <StyledIconWrapper expanded={expanded}>
                    <DropdownTriangleIcon />
                </StyledIconWrapper>

                <SectionTitle>{title}</SectionTitle>
            </ToggleButton>

            <VelocityTransitionGroup
                enter={{ animation: 'slideDown', duration: 300 }}
                leave={{ animation: 'slideUp', duration: 300 }}
            >
                {expanded ? <ChildrenContainer>{children}</ChildrenContainer> : undefined}
            </VelocityTransitionGroup>
        </div>
    );
};

export default CollapsibleSection;
