import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import {
    canShowNextBillPromo,
    canShowNextPOPromo,
    closeBillToPoMatchingBill,
    closeBillToPoMatchingPO,
    closeGoodsReceivedNotePO,
    closeXeroBudgetCheckingBill,
    closeXeroBudgetCheckingPO,
    isApprovedPO,
    isBillToPoMatchingBillClosed,
    isBillToPoMatchingPOClosed,
    isEnabledBillAndPOWorkflows,
    isGoodsReceivedNotePOClosed,
    isUnprocessedBill,
    isXeroBudgetCheckingBillClosed,
    isXeroBudgetCheckingPOClosed,
} from 'pages/requestList/utils/featurePromotions';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { isAllFeaturesTrialStartAllowed, isPayOrCapturePromotionsAllowed } from 'shared/helpers';
import { useAddonPromotions } from 'shared/hooks/useAddonPromotions';
import { AddonPromotionType, AftPromotionType, pageVisiblePromotionsState } from 'shared/states';

export const useGetNextPromotions = () => {
    const { canShowPromotion } = useAddonPromotions();

    const getNextPromotion = useCallback(
        (
            request: selectors.types.ExpandedRequest,
            company: selectors.types.ExpandedCompany,
            workflows: selectors.types.ExpandedTemplate[]
        ) => {
            if (request.integrationCode === domain.IntegrationCode.XeroBill) {
                const isLastApprovalStep =
                    request.activeStep && request.steps[request.steps.length - 1]?.id === request.activeStep.id;
                const isApproved = request.statusV2 === domain.RequestStatusV2.Approved;

                if (
                    isPayOrCapturePromotionsAllowed(company, AddonPromotionType.Pay) &&
                    canShowPromotion(AddonPromotionType.Pay) &&
                    (isLastApprovalStep || isApproved)
                ) {
                    return AddonPromotionType.Pay;
                }

                const notUploadedViaCapture = request.attachments.every((attachment) => !attachment.ocrStatus);

                if (
                    isPayOrCapturePromotionsAllowed(company, AddonPromotionType.Capture) &&
                    canShowPromotion(AddonPromotionType.Capture) &&
                    notUploadedViaCapture &&
                    company.flags.isManager
                ) {
                    return AddonPromotionType.Capture;
                }
            }

            if (!isAllFeaturesTrialStartAllowed(company, domain.IntegrationType.Xero)) {
                return null;
            }

            if (request.integrationCode === domain.IntegrationCode.XeroBill) {
                if (!canShowNextBillPromo()) {
                    return null;
                }

                if (isUnprocessedBill(request) && isEnabledBillAndPOWorkflows(workflows, domain.IntegrationType.Xero)) {
                    if (!isBillToPoMatchingBillClosed()) {
                        return AftPromotionType.BillToPoMatchingBill;
                    } else if (!isXeroBudgetCheckingBillClosed()) {
                        return AftPromotionType.XeroBudgetChecking;
                    }
                } else {
                    if (!isXeroBudgetCheckingBillClosed()) {
                        return AftPromotionType.XeroBudgetChecking;
                    }
                }
            } else if (request.integrationCode === domain.IntegrationCode.XeroPo) {
                if (!canShowNextPOPromo()) {
                    return null;
                }

                if (!isApprovedPO(request)) {
                    if (!isXeroBudgetCheckingPOClosed()) {
                        return AftPromotionType.XeroBudgetChecking;
                    }
                } else {
                    if (
                        isEnabledBillAndPOWorkflows(workflows, domain.IntegrationType.Xero) &&
                        !isBillToPoMatchingPOClosed()
                    ) {
                        return AftPromotionType.BillToPoMatchingPO;
                    }

                    if (!isXeroBudgetCheckingPOClosed()) {
                        return AftPromotionType.XeroBudgetChecking;
                    }

                    if (!isGoodsReceivedNotePOClosed()) {
                        return AftPromotionType.GoodsReceivedNote;
                    }
                }
            }

            return null;
        },
        [canShowPromotion]
    );

    return getNextPromotion;
};

export const useClosePromotion = (request: selectors.types.ExpandedRequest) => {
    const [pageVisiblePromotions, setPageVisiblePromotions] = useRecoilState(pageVisiblePromotionsState);
    const { closePromotion } = useAddonPromotions();

    const handleClosePromotion = useCallback(
        (promotion: AftPromotionType | AddonPromotionType) => {
            if (!pageVisiblePromotions) {
                return;
            }

            switch (promotion) {
                case AddonPromotionType.Capture:
                case AddonPromotionType.Pay:
                    closePromotion(promotion);

                    break;

                case AftPromotionType.BillToPoMatchingBill:
                    closeBillToPoMatchingBill();

                    break;

                case AftPromotionType.BillToPoMatchingPO:
                    closeBillToPoMatchingPO();
                    break;

                case AftPromotionType.XeroBudgetChecking:
                    if (request.integrationCode === domain.IntegrationCode.XeroBill) {
                        closeXeroBudgetCheckingBill();
                    } else if (request.integrationCode === domain.IntegrationCode.XeroPo) {
                        closeXeroBudgetCheckingPO();
                    }

                    break;

                case AftPromotionType.GoodsReceivedNote:
                    closeGoodsReceivedNotePO();
                    break;
            }

            setPageVisiblePromotions(null);
        },
        [pageVisiblePromotions, setPageVisiblePromotions, closePromotion, request.integrationCode]
    );

    return handleClosePromotion;
};
