import { TryPayOrCapturePopupOrigin } from './TryPayOrCapturePopupContent.types';

export const getOriginForAmplitude = (origin: TryPayOrCapturePopupOrigin) => {
    switch (origin) {
        case TryPayOrCapturePopupOrigin.CreateNewPopup:
            return 'create new pop-up';
        case TryPayOrCapturePopupOrigin.EditForm:
            return 'edit form';
        case TryPayOrCapturePopupOrigin.ForceOpen:
            return 'force open capture/pay';
        case TryPayOrCapturePopupOrigin.SideNavigation:
            return 'side navigation';
        case TryPayOrCapturePopupOrigin.ViewForm:
            return 'view form';
        case TryPayOrCapturePopupOrigin.Workflow:
            return 'workflow';
    }
};
