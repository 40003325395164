import { errorHelpers } from '@approvalmax/utils';
import { stateTree } from 'modules/data';

type State = stateTree.State;

export function getToastNotifications(state: State) {
    return state.ui.toasts;
}

export function hasGlobalLoading(state: State) {
    return state.ui.showGlobalLoadingCounter > 0;
}

export function isBlocked(state: State) {
    return state.ui.isBlockedCounter > 0;
}

export function isOperationInProgress(state: State, ...operationIds: string[]) {
    if (operationIds.length === 0) {
        return state.ui.asyncOperationsInProgress.length > 0;
    }

    return operationIds.some((id) => state.ui.asyncOperationsInProgress.includes(id));
}

export function getPopupId(state: State): string | null {
    return state.ui.popup ? state.ui.popup.id : null;
}

export function getPopupData<T>(state: State): T {
    if (!state.ui.popup) {
        throw errorHelpers.invalidOperationError();
    }

    return state.ui.popup.data;
}

export function isValidPhone(phone: string) {
    // TODO: after testing move this logic to validators
    const regExp = new RegExp('^[-0-9.,+()" "]*$');

    return regExp.test(phone);
}

// TODO: after testing move this logic to validators
const WEBSITE_FRAGMENT =
    '((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,10}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)';
const websiteRegex = new RegExp(`^${WEBSITE_FRAGMENT}$`, 'i');

export function isValidWebsite(possibleWebsite: string | null | undefined): boolean {
    if (!possibleWebsite) return false;

    return websiteRegex.test(possibleWebsite);
}

export function getDisplayAddress(street?: string, postCode?: string): string {
    if (street && postCode) {
        return `${postCode} - ${street}`;
    } else {
        return postCode || street || '';
    }
}

export function isWorkflowWizardPreview(state: State) {
    return Boolean(state.ui.isWorkflowWizardPreview);
}

export function getTryOpenWorkflowWizardCount(state: State) {
    return state.ui.tryOpenWorkflowWizardCount;
}
