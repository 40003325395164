import { Button, Flex } from '@approvalmax/ui/src/components';
import { memo } from 'react';
import { useTwoFaWizardController } from 'shared/components';

import { messages } from './SoftEnforcementActions.messages';

export const SoftEnforcementActions = memo(() => {
    const { wizardController } = useTwoFaWizardController();

    return (
        <>
            {wizardController.onNext && !wizardController.onPrev && (
                <Button size='medium' color='blue80' onClick={wizardController.onNext}>
                    {messages.buttonNext}
                </Button>
            )}

            {wizardController.onNext && wizardController.onPrev && (
                <Flex direction='row' spacing='16'>
                    <Button size='medium' color='blue10' onClick={wizardController.onPrev}>
                        {messages.buttonPrev}
                    </Button>

                    <Button size='medium' color='blue80' onClick={wizardController.onNext}>
                        {messages.buttonNext}
                    </Button>
                </Flex>
            )}
        </>
    );
});

SoftEnforcementActions.displayName = 'SoftEnforcementActions';
