import type { PascalCaseToCamelCase } from '@approvalmax/types';
import { intl } from '@approvalmax/utils';
import { backend, schemas } from 'modules/data';
import moment from 'moment';

export const mapMatchingInfo = (data: PascalCaseToCamelCase<backend.QBOMatchingInfo>) => {
    return {
        name: data.friendlyName,
        date: moment.utc(data.date).format('ll'),
        amount: data.currency ? intl.formatCurrency(data.totalAmount, data.currency) : String(data.totalAmount),
        id: data.id,
        currency: data.currency,
        accountLineItem: data.accountBasedLine && schemas.mapAccountLineItem(data.accountBasedLine),
        lineItem: data.itemBasedLine && schemas.mapLineItem(data.itemBasedLine),
    };
};

export const isMatchingInfoFilled = <T>(value: T[] | undefined): value is T[] => Boolean(value?.length);
