import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { companiesApiPaths } from '../paths';
import { GetXeroCounterpartyPaymentDetailsHistory } from './useGetXeroCounterpartyPaymentDetailsHistory.types';

export const useGetXeroCounterpartyPaymentDetailsHistory = (
    params?: RequestParams<GetXeroCounterpartyPaymentDetailsHistory>,
    queryOptions?: RequestQueryOptions<GetXeroCounterpartyPaymentDetailsHistory>
) => {
    return useGet(companiesApiPaths.xeroCounterpartyPaymentDetailsHistory, {
        params,
        queryOptions,
        apiVersion: 'v2',
    });
};
