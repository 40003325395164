import { LoadingSpinner } from '@approvalmax/ui';
import { Box } from '@approvalmax/ui/src/components';
import { intl } from '@approvalmax/utils';
import FieldsSection from 'pages/requestList/components/FieldsSection';
import { SectionContainer } from 'pages/requestList/components/SectionContainer/SectionContainer';
import { memo } from 'react';
import BemFactory from 'react-bem-factory';
import { useGetWorkflowVersionDocumentFields } from 'shared/data/v1';
import { useNetSuiteVendorFieldSettings } from 'shared/hooks';

import { isFieldVisible } from '../../NetSuiteRequestCard.utils';
import { NetSuiteVendorAddressList } from '../NetSuiteVendorAddressList/NetSuiteVendorAddressList';
import { NetSuiteVendorSubsidiaryList } from '../NetSuiteVendorSubsidiaryList/NetSuiteVendorSubsidiaryList';
import { messages } from './NetSuiteVendorFooterFields.messages';
import { FieldsSectionWrap, StyledField, StyledSectionContainer } from './NetSuiteVendorFooterFields.styles';
import { NetSuiteVendorFooterFieldsProps } from './NetSuiteVendorFooterFields.types';

const qa = BemFactory.qa('netsuite-vendor-fields-section');

export const NetSuiteVendorFooterFields = memo<NetSuiteVendorFooterFieldsProps>((props) => {
    const { request } = props;

    const { data: workflowVersionDocumentFields, isFetching: isFetchingWorkflowVersionDocumentFields } =
        useGetWorkflowVersionDocumentFields({
            query: {
                companyId: request.companyId,
                workflowVersionIds: [request.workflowVersionId],
            },
        });

    const fieldSettings = useNetSuiteVendorFieldSettings(workflowVersionDocumentFields?.data || []);

    const { details } = request;

    const hasContactInformationSection =
        (isFieldVisible(fieldSettings.email) && details.email) ||
        (isFieldVisible(fieldSettings.phone) && details.phone) ||
        (isFieldVisible(fieldSettings.altPhone) && details.altPhone) ||
        (isFieldVisible(fieldSettings.fax) && details.fax);

    const hasFinancialInformationSection =
        (isFieldVisible(fieldSettings.legalName) && details.legalName) ||
        (isFieldVisible(fieldSettings.accountNumber) && details.accountNumber) ||
        (isFieldVisible(fieldSettings.defaultExpenseAccount) && details.defaultExpenseAccount) ||
        (isFieldVisible(fieldSettings.defaultPayablesAccount) && details.defaultPayablesAccount) ||
        (isFieldVisible(fieldSettings.terms) && details.terms) ||
        (isFieldVisible(fieldSettings.creditLimit) && details.creditLimit) ||
        (isFieldVisible(fieldSettings.incoterm) && details.incoterm) ||
        (isFieldVisible(fieldSettings.taxNumber) && details.taxNumber) ||
        (isFieldVisible(fieldSettings.is1099Eligible) && details.is1099Eligible) ||
        (isFieldVisible(fieldSettings.isJobResource) && details.isJobResource);

    const hasSubsidiariesSection = details.primarySubsidiary || details.subsidiaries;

    const hasCurrenciesSection = details.primaryCurrency || details.currencies;

    return (
        <>
            {hasContactInformationSection && (
                <SectionContainer title={messages.contactInformationSection} noPadding withHeaderPadding>
                    {isFetchingWorkflowVersionDocumentFields ? (
                        <Box spacing='20'>
                            <LoadingSpinner />
                        </Box>
                    ) : (
                        <FieldsSectionWrap>
                            {isFieldVisible(fieldSettings.email) && (
                                <StyledField
                                    qa={qa('email')}
                                    title={messages.emailFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.email}
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.phone) && (
                                <StyledField
                                    qa={qa('phone')}
                                    title={messages.phoneFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.phone}
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.altPhone) && (
                                <StyledField
                                    qa={qa('alt-phone')}
                                    title={messages.altPhoneFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.altPhone}
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.fax) && (
                                <StyledField
                                    qa={qa('fax')}
                                    title={messages.faxFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.fax}
                                    hideIfEmpty
                                />
                            )}
                        </FieldsSectionWrap>
                    )}
                </SectionContainer>
            )}

            {hasFinancialInformationSection && (
                <SectionContainer title={messages.financialInformationSection} noPadding withHeaderPadding>
                    {isFetchingWorkflowVersionDocumentFields ? (
                        <Box spacing='20'>
                            <LoadingSpinner />
                        </Box>
                    ) : (
                        <FieldsSectionWrap>
                            {isFieldVisible(fieldSettings.legalName) && (
                                <StyledField
                                    qa={qa('legal-name')}
                                    title={messages.legalNameFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.legalName}
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.accountNumber) && (
                                <StyledField
                                    qa={qa('account-number')}
                                    title={messages.accountNumberFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.accountNumber}
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.defaultExpenseAccount) && (
                                <StyledField
                                    qa={qa('default-expense-account')}
                                    title={messages.defaultExpenseAccountFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.defaultExpenseAccount}
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.defaultPayablesAccount) && (
                                <StyledField
                                    qa={qa('default-payables-account')}
                                    title={messages.defaultPayablesAccountFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.defaultPayablesAccount}
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.terms) && (
                                <StyledField
                                    qa={qa('terms')}
                                    title={messages.termsFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.terms}
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.creditLimit) && (
                                <StyledField
                                    qa={qa('credit-limit')}
                                    title={messages.creditLimitFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.creditLimit ? intl.formatNumber(details.creditLimit, 'auto') : null}
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.incoterm) && (
                                <StyledField
                                    qa={qa('incoterm')}
                                    title={messages.incotermFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.incoterm}
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.taxNumber) && (
                                <StyledField
                                    qa={qa('tax-number')}
                                    title={messages.taxNumberFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.taxNumber}
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.is1099Eligible) && (
                                <StyledField
                                    qa={qa('is-1099-eligible')}
                                    title={messages.is1099EligibleFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.is1099Eligible ? messages.positiveValue : null}
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.isJobResource) && (
                                <StyledField
                                    qa={qa('is-job-resource')}
                                    title={messages.isJobResourceFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.isJobResource ? messages.positiveValue : null}
                                    hideIfEmpty
                                />
                            )}
                        </FieldsSectionWrap>
                    )}
                </SectionContainer>
            )}

            {hasSubsidiariesSection && (
                <StyledSectionContainer title={messages.subsidiariesSection} noPadding withHeaderPadding>
                    {isFetchingWorkflowVersionDocumentFields ? (
                        <Box spacing='20'>
                            <LoadingSpinner />
                        </Box>
                    ) : (
                        <>
                            <FieldsSectionWrap>
                                <StyledField
                                    qa={qa('primary-subsidiary')}
                                    title={messages.primarySubsidiaryFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.primarySubsidiary?.name}
                                    hideIfEmpty
                                />
                            </FieldsSectionWrap>

                            {isFieldVisible(fieldSettings.subsidiaries) && (
                                <NetSuiteVendorSubsidiaryList subsidiaries={details.subsidiaries} />
                            )}
                        </>
                    )}
                </StyledSectionContainer>
            )}

            {hasCurrenciesSection && (
                <SectionContainer title={messages.currenciesSection} noPadding withHeaderPadding>
                    {isFetchingWorkflowVersionDocumentFields ? (
                        <Box spacing='20'>
                            <LoadingSpinner />
                        </Box>
                    ) : (
                        <FieldsSectionWrap>
                            {isFieldVisible(fieldSettings.currency) && (
                                <StyledField
                                    qa={qa('primary-currency')}
                                    title={messages.primaryCurrencyFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.primaryCurrency}
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.currencies) && (
                                <StyledField
                                    qa={qa('currencies-list')}
                                    title={messages.currenciesListFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.currencies?.map((currency) => currency.text).join(', ')}
                                    hideIfEmpty
                                />
                            )}
                        </FieldsSectionWrap>
                    )}
                </SectionContainer>
            )}

            {Boolean(details.addressLines.length) && (
                <SectionContainer noPadding withHeaderPadding>
                    {isFetchingWorkflowVersionDocumentFields ? (
                        <Box spacing='20'>
                            <LoadingSpinner />
                        </Box>
                    ) : (
                        <NetSuiteVendorAddressList addressList={details.addressLines} fieldSettings={fieldSettings} />
                    )}
                </SectionContainer>
            )}
        </>
    );
});

NetSuiteVendorFooterFields.displayName = 'NetSuiteVendorFooterFields';
