import { addOffset } from './addOffset';

/**
 * We are accepting a Date withing a local TZ, and we have to shift the date
 * so that it look like a UTC date but in local TZ
 */
export const convertLocalToUTC = <Value extends Date | string | null | undefined>(
    value: Value
): Value extends null | undefined | '' ? undefined : Date => {
    if (value) {
        // we must use get `getTimezoneOffset` from value to ensure that
        // TZ reflects the one from date. TZ of `dateTime-local` input can
        // differ local TZ
        const offset = typeof value === 'string' ? new Date().getTimezoneOffset() : value.getTimezoneOffset();

        return addOffset(value, offset).toDate() as Value extends null | undefined | '' ? undefined : Date;
    }

    return undefined as Value extends null | undefined | '' ? undefined : Date;
};
