import { miscHelpers } from '@approvalmax/utils';
import { Component, ComponentType, createContext, FC, ReactNode } from 'react';
import { Subtract } from 'utility-types';

type Focusable = { focus: () => void; blur: () => void };

interface FocusableInjectedProps {
    registerFocusable: (focusable: Focusable | null | undefined) => void;
}

const FocusContext = createContext<FocusableInjectedProps>({
    registerFocusable: miscHelpers.noop,
});

export const FocusImplementer = FocusContext.Consumer;

export const withFocusable = <TWrappedProps extends FocusableInjectedProps>(
    WrappedComponent: ComponentType<TWrappedProps>
) => {
    type HocProps = Subtract<TWrappedProps, FocusableInjectedProps>;

    const FocusableHoc: FC<HocProps> = (props) => {
        return (
            <FocusContext.Consumer>
                {({ registerFocusable }) => (
                    <WrappedComponent {...(props as TWrappedProps)} registerFocusable={registerFocusable} />
                )}
            </FocusContext.Consumer>
        );
    };

    FocusableHoc.displayName = `withFocusable(${WrappedComponent.name})`;

    return FocusableHoc;
};

interface FocusEmitterProps {
    children: (options: { focus: () => void }) => ReactNode;
}

class FocusEmitter extends Component<FocusEmitterProps> {
    #focusable: Focusable | undefined | null;

    constructor(props: FocusEmitterProps) {
        super(props);
    }

    public render() {
        const { children } = this.props;

        return (
            <FocusContext.Provider value={{ registerFocusable: this.#registerFocusable }}>
                {children({ focus: this.#onFocus })}
            </FocusContext.Provider>
        );
    }

    #registerFocusable = (focusable: Focusable | null | undefined) => {
        this.#focusable = focusable;
    };

    #onFocus = () => {
        if (this.#focusable) {
            this.#focusable.focus();
        }
    };
}

export default FocusEmitter;
