import { useMemo } from 'react';

import { useLineCustomFieldsDefinition } from '../../NetSuiteRequestCard.hooks';
import { getColumnDefs } from './NetSuiteJournalEntryLines.config';
import { LinesFieldsSettings, NetSuiteJournalEntryLinesProps } from './NetSuiteJournalEntryLines.types';

export const useTableDefinitions = (
    itemLines: NetSuiteJournalEntryLinesProps['itemLines'],
    companyCustomFields: NetSuiteJournalEntryLinesProps['companyCustomFields'],
    fieldSettings: LinesFieldsSettings,
    exchangeRate?: number | null
) => {
    const customFieldsColumnDefinition = useLineCustomFieldsDefinition(itemLines, companyCustomFields);

    const resultColumnDefinition = useMemo(
        () => [...getColumnDefs(fieldSettings, exchangeRate), ...customFieldsColumnDefinition],
        [customFieldsColumnDefinition, fieldSettings, exchangeRate]
    );

    return resultColumnDefinition;
};
