import { Link } from '@approvalmax/ui';
import { errorHelpers, intl } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { DoneIcon } from 'modules/sprites';
import { font } from 'modules/styles';
import { LineItemsTable } from 'pages/requestList/components/lineItemsSection/LineItemsTable';
import { getActiveRequest } from 'pages/requestList/selectors/pageSelectors';
import { ColumnDefinition } from 'pages/requestList/selectors/types/ColumnDefinition';
import { FC, memo, useMemo } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { getPath, Path } from 'urlBuilder';

import { POTableItem } from '../../../../types';

const i18nPrefix =
    'requestList/containers/xero/XeroMatchingV2Section/components/PurchaseOrderView/components/PurchaseOrderMatchingTable/PurchaseOrderMatchingTable';

const messages = defineMessages({
    itemColumnName: {
        id: `${i18nPrefix}.itemColumnName`,
        defaultMessage: 'Name',
    },
    approvedColumnName: {
        id: `${i18nPrefix}.approvedColumnName`,
        defaultMessage: 'Approved',
    },
    matchedByColumnName: {
        id: `${i18nPrefix}.matchedByColumnName`,
        defaultMessage: 'Matched by',
    },
    allocatedAmountColumnName: {
        id: `${i18nPrefix}.allocatedAmountColumnName`,
        defaultMessage: 'Allocated amount to this PO',
    },
    totalAmountColumnNameWithGross: {
        id: `${i18nPrefix}.totalAmountColumnNameWithGross`,
        defaultMessage: 'Total amount (Gross)',
    },
    totalAmountColumnNameWithNet: {
        id: `${i18nPrefix}.totalAmountColumnNameWithNet`,
        defaultMessage: 'Total amount (Net)',
    },
});

const StyledLink = styled(Link)`
    ${font(12, '#477753', 'bold')}
`;

const StyledIconContainer = styled.div`
    display: flex;
    align-items: center;
`;

const getColumnDefinitions = (amountType: domain.MatchingAmountType | undefined, companyId: string) => {
    let totalAmountName;

    switch (amountType) {
        case domain.MatchingAmountType.Gross:
            totalAmountName = intl.formatMessage(messages.totalAmountColumnNameWithGross);
            break;

        case domain.MatchingAmountType.Net:
            totalAmountName = intl.formatMessage(messages.totalAmountColumnNameWithNet);
            break;

        default: {
            throw errorHelpers.assertNever(amountType ?? '');
        }
    }

    const columnDefinitions: ColumnDefinition<POTableItem>[] = [
        {
            id: 'name',
            name: intl.formatMessage(messages.itemColumnName),
            value: (li) => li.name,
            cell: (column, li) => <StyledLink href={getPath(Path.request, li.id, companyId)}>{li.name}</StyledLink>,
        },
        {
            id: 'approved',
            name: intl.formatMessage(messages.approvedColumnName),
            value: (li) => (li.approved ? 'Yes' : 'No'),
            cell: (column, li) =>
                li.approved ? (
                    <StyledIconContainer>
                        <DoneIcon width={13} height={10} />
                    </StyledIconContainer>
                ) : null,
        },
        {
            id: 'matched_by',
            name: intl.formatMessage(messages.matchedByColumnName),
            value: (li) => li.matchedBy,
        },
        {
            id: 'allocated_amount',
            name: intl.formatMessage(messages.allocatedAmountColumnName),
            value: (li) => intl.formatCurrency(li.allocatedAmount, li.currency),
            alignRight: true,
        },
        {
            id: 'total_amount',
            name: totalAmountName,
            value: (li) => intl.formatCurrency(li.totalAmount, li.currency),
            alignRight: true,
            width: 150,
        },
    ];

    return columnDefinitions;
};

const AmountContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const Total = styled.span`
    color: #777d81;
`;

const Amount = styled.span`
    width: 155px;
    text-align: right;
    font-weight: bold;
`;

interface PurchaseOrderMatchingTableProps {
    lineItems: POTableItem[];
    totalAllocatedAmount: number;
    totalAmount: number;
    currency: string;
    companyId: string;
}

const PurchaseOrderMatchingTable: FC<PurchaseOrderMatchingTableProps> = (props) => {
    const { lineItems, totalAllocatedAmount, totalAmount, currency, companyId } = props;

    const billMatchingSettings = useSelector(getActiveRequest).company.billMatchingSettings;

    const columnDefinitions = useMemo(
        () => getColumnDefinitions(billMatchingSettings?.amountType, companyId),
        [billMatchingSettings?.amountType, companyId]
    );

    return (
        <div>
            <LineItemsTable columnDefinitions={columnDefinitions} lineItems={lineItems} />

            <AmountContainer>
                <Total>
                    <FormattedMessage id={`${i18nPrefix}.totalAllocatedText`} defaultMessage='Total:' />
                </Total>

                <Amount>{intl.formatCurrency(totalAllocatedAmount, currency)}</Amount>

                <Amount>{intl.formatCurrency(totalAmount, currency)}</Amount>
            </AmountContainer>
        </div>
    );
};

export default memo(PurchaseOrderMatchingTable);
