import { PascalCaseToCamelCase } from '@approvalmax/types';
import { compareHelpers, errorHelpers } from '@approvalmax/utils';
import capitalize from 'lodash/capitalize';
import lowerCase from 'lodash/lowerCase';
import { domain } from 'modules/data';
import { ApiTypes } from 'shared/data/v2';
import { emailToSupplierHelpers } from 'shared/helpers';

import * as backend from '../../backend';
import { getBeautifiedEntity } from '../../utils';
import { IntegrationCode } from '../Integration';
import { LineAmountType, RequestAttachment, XeroAmaxPayBatchPaymentDetails, XeroRequestDetails } from '../Request';
import {
    XeroAccountClass,
    XeroAirwallexBatchPaymentDetails,
    XeroBillBatchPaymentDetails,
    XeroBillDetails,
    XeroContactDetails,
    XeroCreditNoteDetails,
    XeroInvoiceDetails,
    XeroLineItem,
    XeroManualJournalDetails,
    XeroManualJournalLine,
    XeroPurchaseOrderDetails,
    XeroQuoteDetails,
    XeroRequestSpecifics,
} from '../Request.Xero';
import { mapBeneficiary } from './airwallexSchema';
import { mapAttachment } from './requestSchema';
import { messages } from './requestSchema.Xero.messages';
import { mapReference } from './utils';

const mapTrackingCategories = (t: any) => ({
    category: {
        id: t.CategoryId,
        text: t.CategoryName,
    },
    value: {
        id: t.OptionId,
        text: t.OptionName,
    },
});

export function mapLineItem(
    value: backend.IntegrationsXeroLineItemAnswer,
    integrationCode?: domain.IntegrationCode
): XeroLineItem {
    let itemDetails;

    if (!value.CatalogUnitPrice) {
        itemDetails = {};
    } else if (
        integrationCode === domain.IntegrationCode.XeroQuote ||
        integrationCode === domain.IntegrationCode.XeroInvoice
    ) {
        itemDetails = {
            salesDetails: {
                description: '',
                unitPrice: value.CatalogUnitPrice,
            },
        };
    } else {
        itemDetails = {
            purchaseDetails: {
                description: '',
                unitPrice: value.CatalogUnitPrice,
            },
        };
    }

    return {
        id: value.LineItemId!,
        description: value.Description || '',
        qty: value.Qty,
        amount: value.Amount,
        unitPrice: value.UnitPrice,
        discount: value.Discount,
        discountAmount: value.DiscountAmount,
        discountType: value.DiscountType,
        isInventory: value.IsInventory || false,
        tracking: value.Tracking.map(mapTrackingCategories),
        account: value.AccountId
            ? {
                  id: value.AccountId,
                  text: value.Account,
              }
            : undefined,
        item: value.ItemId
            ? {
                  id: value.ItemId,
                  text: value.Item,
                  code: value.ItemCode,
                  ...itemDetails,
              }
            : undefined,
        tax: value.TaxRateString
            ? {
                  id: value.TaxCode,
                  rate: value.TaxRate!,
                  rateEffective: value.TaxRateEffective || value.TaxRate!,
                  text: value.TaxRateString,
                  applicableToAccountClasses: [
                      XeroAccountClass.Asset,
                      XeroAccountClass.Equity,
                      XeroAccountClass.Expense,
                      XeroAccountClass.Liability,
                      XeroAccountClass.Revenue,
                  ],
              }
            : undefined,
        taxAmount: value.TaxAmount,
        cisRate: value.CisRate,
        cisTaxAmount: value.CisTaxAmount,
    };
}

function mapSharedDetails(value: any, integrationCode?: domain.IntegrationCode) {
    const lockDate = value.lockDate || value.LockDate;
    const companyLockDate = value.companyLockDate || value.CompanyLockDate;
    const templateLockDatePolicy = value.workflowLockDatePolicy || value.TemplateLockDatePolicy;

    return {
        contact: mapReference(value.Contact),
        date: value.Date || null,
        lockDate: lockDate || companyLockDate || null,
        lockDatePolicy: templateLockDatePolicy || null,
        status: value.Status || null,
        brandingTheme: value.BrandingThemeId
            ? {
                  id: value.BrandingThemeId,
                  text: value.BrandingTheme,
              }
            : null,
        url: value.Url || value.url || null,
        editUrl: value.EditUrl || value.editUrl || null,
        invoiceTaxes: (value.InvoiceTaxes || []).map((x: any) => ({
            taxRatePercent: x.TaxRatePercent,
            taxValue: x.TaxValue,
            taxRateString: x.TaxRateString,
        })),
        lineAmountType: value.LineAmountType || LineAmountType.TaxExclusive,
        lineItems: (value.LineItems || []).map((lineItem: backend.IntegrationsXeroLineItemAnswer) =>
            mapLineItem(lineItem, integrationCode)
        ),
        subTotal: value.SubTotal,
        integrationEnabled: value.IntegrationEnabled,
        isStatusPushPending: value.IsStatusPushPending || value.isStatusPushPending || false,
    };
}

function mapPurchaseOrder(
    value: backend.IntegrationsXeroPurchaseOrderAnswer,
    emailToContact?: backend.EmailToContact
): XeroPurchaseOrderDetails {
    if (!value) {
        throw errorHelpers.formatError('[requestSchema.Xero] Purchase Order details is undefined.');
    }

    return {
        ...mapSharedDetails(value),
        integrationCode: domain.IntegrationCode.XeroPo,
        integrationType: domain.IntegrationType.Xero,
        id: value.PurchaseOrderId,
        number: value.PurchaseOrderNumber,
        reference: value.PurchaseOrderReference || '',
        isBilled: value.IsBilled || false,
        deliveryDate: value.DeliveryDate || null,
        delivery: {
            address: value.DeliveryAddress || '',
            attentionTo: value.AttentionTo || '',
            phone: value.Phone || '',
            instructions: value.DeliveryInstructions || '',
        },
        sendToSupplier: emailToContact?.EmailHasToBeSent || false,
        emailToSupplier: {
            emailHasToBeSent: emailToContact?.EmailHasToBeSent || false,
            from: emailToContact?.EmailFrom || '',
            replyTo: emailToContact?.EmailReplyTo || '',
            to: (emailToContact?.EmailTo || '').split(',').filter(Boolean),
            cc: (emailToContact?.EmailCC || '').split(',').filter(Boolean),
            subject: emailToSupplierHelpers.escapeExpressions(emailToContact?.EmailSubject || ''),
            body: emailToContact?.EmailBody || '',
            attachments: (emailToContact?.Attachments || [])
                .map((attachment) => mapAttachment(attachment))
                .sort(compareHelpers.comparatorFor<RequestAttachment>(compareHelpers.stringComparator2Asc, 'name')),
        },
        grnStatus: value.GrnStatus || null,
    };
}

const mapXeroInvoiceMatchedPO = (value?: backend.XeroInvoiceMatchedPO): XeroBillDetails['matchedPurchaseOrder'] => {
    if (!value) return undefined;

    function mapAmount(val: { Value: number; Currency: string }) {
        return {
            value: val.Value,
            currency: val.Currency,
        };
    }

    return {
        id: value.RequestId,
        name: value.Name,
        overBudget: value.OverBudget,
        amount: mapAmount(value.Amount),
        matchedBills: value.MatchedBills.map((x) => ({
            id: x.RequestId,
            name: x.Name,
            amount: mapAmount(x.Amount),
        })),
        matchedBillsTotalAmount: mapAmount(value.MatchedBillsAmount),
    };
};

const mapAmountV2 = (amount: { value: number; currency: string }) => {
    return {
        value: amount.value,
        currency: amount.currency,
    };
};

const mapXeroInvoiceMatchedPOV2 = (
    value?: PascalCaseToCamelCase<backend.XeroInvoiceMatchedPO>
): XeroBillDetails['matchedPurchaseOrder'] => {
    if (!value) return undefined;

    return {
        id: value.requestId,
        name: value.name,
        overBudget: value.overBudget,
        amount: mapAmountV2(value.amount),
        matchedBills: value.matchedBills.map((bill) => ({
            id: bill.requestId,
            name: bill.name,
            amount: mapAmountV2(bill.amount),
        })),
        matchedBillsTotalAmount: mapAmountV2(value.matchedBillsAmount),
    };
};

function mapBill(value: backend.IntegrationsXeroInvoiceAnswer): XeroBillDetails {
    if (!value) {
        throw errorHelpers.formatError('[requestSchema.Xero] Bill details is undefined.');
    }

    const invoice: XeroBillDetails = {
        ...mapSharedDetails(value, IntegrationCode.XeroBill),
        integrationCode: IntegrationCode.XeroBill,
        integrationType: domain.IntegrationType.Xero,
        id: value.InvoiceId,
        number: value.InvoiceNumber,
        reference: value.InvoiceReference,
        type: value.InvoiceType,
        dueDate: value.DueDate,
        cisTaxAmount: value.CisTaxAmount,
        hasCisDeduction: value.HasCisDeduction,
        matchedPurchaseOrder: mapXeroInvoiceMatchedPO(value.MatchedPO),
        paymentDetails: value.PaymentDetails
            ? {
                  amount: value.PaymentDetails.Amount,
                  date: value.PaymentDetails.Date,
                  account: {
                      id: value.PaymentDetails.BankAccountId,
                      text: value.PaymentDetails.BankAccountName,
                      code: value.PaymentDetails.BankAccountCode,
                      currencyCode: value.PaymentDetails.BankAccountCurrencyCode,
                      number: value.PaymentDetails.BankAccountNumber,
                  },
              }
            : undefined,
    };

    return invoice;
}

export const mapXeroBillDetailsV2 = (
    value: PascalCaseToCamelCase<
        backend.IntegrationsXeroInvoiceAnswer | ApiTypes['IntegrationsXeroInvoiceAnswerCamelCased']
    >
): domain.XeroBillDetails => {
    return {
        ...mapSharedDetailsV2(value),
        integrationCode: domain.IntegrationCode.XeroBill,
        integrationType: domain.IntegrationType.Xero,
        status: value.status || null,
        lockDate: value.lockDate || value.companyLockDate || null,
        lockDatePolicy: value.templateLockDatePolicy,
        id: value.invoiceId,
        number: value.invoiceNumber || '',
        reference: value.invoiceReference || '',
        type: value.invoiceType || '',
        dueDate: value.dueDate || '',
        cisTaxAmount: value.cisTaxAmount,
        hasCisDeduction: value.hasCisDeduction,
        // TODO: will be fixed in the api schema https://approvalmax.slack.com/archives/C03PACM9P62/p1724955426754209
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        matchedPurchaseOrder: mapXeroBillMatchedPOV2(value.matchedPO),
        paymentDetails: value.paymentDetails
            ? {
                  amount: value.paymentDetails.amount,
                  date: value.paymentDetails.date || '',
                  account: {
                      id: value.paymentDetails.bankAccountId || '',
                      text: value.paymentDetails.bankAccountName || '',
                      code: value.paymentDetails.bankAccountCode || '',
                      currencyCode: value.paymentDetails.bankAccountCurrencyCode || '',
                      number: value.paymentDetails.bankAccountNumber || '',
                  },
              }
            : undefined,
    };
};

export const mapXeroPurchaseOrderV2 = (
    value: PascalCaseToCamelCase<
        backend.IntegrationsXeroPurchaseOrderAnswer | ApiTypes['IntegrationsXeroPurchaseOrderAnswerCamelCased']
    >,
    emailToContact?: PascalCaseToCamelCase<
        backend.EmailToContact | ApiTypes['V2RequestsRequestAnswerCamelCased']['emailToContact']
    >
): XeroPurchaseOrderDetails => {
    return {
        ...mapSharedDetailsV2(value),
        integrationCode: domain.IntegrationCode.XeroPo,
        integrationType: domain.IntegrationType.Xero,
        status: value.status || null,
        lockDate: null,
        lockDatePolicy: domain.TemplateSettingsLockDatePolicy.LockApproval,
        id: value.purchaseOrderId,
        number: value.purchaseOrderNumber || '',
        reference: value.purchaseOrderReference || '',
        isBilled: value.isBilled || false,
        deliveryDate: value.deliveryDate || null,
        delivery: {
            address: value.deliveryAddress || '',
            attentionTo: value.attentionTo || '',
            phone: value.phone || '',
            instructions: value.deliveryInstructions || '',
        },
        sendToSupplier: emailToContact?.emailHasToBeSent || false,
        emailToSupplier: {
            emailHasToBeSent: emailToContact?.emailHasToBeSent || false,
            from: emailToContact?.emailFrom || '',
            replyTo: emailToContact?.emailReplyTo || '',
            to: (emailToContact?.emailTo || '').split(',').filter(Boolean),
            cc: (emailToContact?.emailCC || '').split(',').filter(Boolean),
            subject: emailToSupplierHelpers.escapeExpressions(emailToContact?.emailSubject || ''),
            body: emailToContact?.emailBody || '',
            attachments: (emailToContact?.attachments || [])
                .map((attachment) => mapAttachment(attachment))
                .sort(compareHelpers.comparatorFor<RequestAttachment>(compareHelpers.stringComparator2Asc, 'name')),
        },
        grnStatus: value.grnStatus || null,
    };
};

const mapSharedDetailsV2 = (
    value: PascalCaseToCamelCase<
        | backend.IntegrationsXeroInvoiceAnswer
        | backend.IntegrationsXeroPurchaseOrderAnswer
        | backend.IntegrationsXeroCreditNoteAnswer
        | backend.IntegrationsXeroQuoteAnswer
        | ApiTypes['IntegrationsXeroInvoiceAnswer']
        | ApiTypes['IntegrationsXeroPurchaseOrderAnswer']
        | ApiTypes['IntegrationsXeroCreditNoteAnswer']
        | ApiTypes['IntegrationsXeroQuoteAnswer']
    >
) => {
    return {
        contact: mapReference(value.contact),
        date: value.date || '',
        brandingTheme: mapReference({
            id: value.brandingThemeId,
            name: value.brandingTheme,
        }),
        url: value.url || null,
        editUrl: value.editUrl || null,
        invoiceTaxes:
            value.invoiceTaxes?.map((tax) => {
                return {
                    ...tax,
                    taxRateString: tax.taxRateString || '',
                };
            }) || [],
        lineAmountType: (value.lineAmountType as domain.LineAmountType) || domain.LineAmountType.TaxExclusive,
        lineItems: (value.lineItems || []).map(mapLineItemV2),
        subTotal: value.subTotal ?? 0,
        integrationEnabled: value.integrationEnabled,
        isStatusPushPending: value.isStatusPushPending || false,
    };
};

const mapTrackingCategoriesV2 = (
    tracking: PascalCaseToCamelCase<backend.IntegrationsXeroLineItemAnswer>['tracking'][0]
) => ({
    category: {
        id: tracking.categoryId,
        text: tracking.categoryName,
    },
    value: {
        id: tracking.optionId,
        text: tracking.optionName,
    },
});

const mapLineItemV2 = (
    lineItem: PascalCaseToCamelCase<
        | backend.IntegrationsXeroLineItemAnswer
        | ApiTypes['IntegrationsXeroLineItemWithCisDeductionAnswer']
        | ApiTypes['IntegrationsXeroLineItemAnswer']
    >
): domain.XeroLineItem => {
    const itemDetails = lineItem.catalogUnitPrice
        ? {
              salesDetails: {
                  description: '',
                  unitPrice: lineItem.catalogUnitPrice,
              },
          }
        : {};

    return {
        id: lineItem.lineItemId!,
        description: lineItem.description || '',
        qty: lineItem.qty,
        amount: lineItem.amount,
        unitPrice: lineItem.unitPrice,
        discount: lineItem.discount,
        discountAmount: lineItem.discountAmount,
        discountType: lineItem.discountType,
        isInventory: lineItem.isInventory || false,
        tracking: (lineItem?.tracking || []).map(mapTrackingCategoriesV2),
        account: lineItem.accountId
            ? {
                  id: lineItem.accountId,
                  text: lineItem.account || '',
              }
            : undefined,
        item: lineItem.itemId
            ? {
                  id: lineItem.itemId,
                  text: lineItem.item || '',
                  code: lineItem.itemCode || '',
                  ...itemDetails,
              }
            : undefined,
        tax:
            lineItem.taxRateString && lineItem.taxCode
                ? {
                      id: lineItem.taxCode,
                      rate: lineItem.taxRate!,
                      rateEffective: lineItem.taxRateEffective || lineItem.taxRate!,
                      text: lineItem.taxRateString,
                      applicableToAccountClasses: [
                          domain.XeroAccountClass.Asset,
                          domain.XeroAccountClass.Equity,
                          domain.XeroAccountClass.Expense,
                          domain.XeroAccountClass.Liability,
                          domain.XeroAccountClass.Revenue,
                      ],
                  }
                : undefined,
        taxAmount: lineItem.taxAmount,
        cisRate: 'cisRate' in lineItem ? lineItem.cisRate : null,
        cisTaxAmount: 'cisTaxAmount' in lineItem ? lineItem.cisTaxAmount : null,
    };
};

const mapXeroBillMatchedPOV2 = (value?: PascalCaseToCamelCase<backend.XeroInvoiceMatchedPO>) => {
    if (!value) return undefined;

    return {
        id: value.requestId,
        name: value.name,
        overBudget: value.overBudget,
        amount: value.amount,
        matchedBills: value.matchedBills.map(({ requestId, name, amount }) => ({
            id: requestId,
            name,
            amount,
        })),
        matchedBillsTotalAmount: value.matchedBillsAmount,
    };
};

function mapInvoice(
    value: backend.IntegrationsXeroInvoiceAnswer,
    emailToContact?: backend.EmailToContact
): XeroInvoiceDetails {
    if (!value) {
        throw errorHelpers.formatError('[requestSchema.Xero] Invoice details is undefined.');
    }

    const invoice: XeroInvoiceDetails = {
        ...mapSharedDetails(value, IntegrationCode.XeroInvoice),
        integrationCode: IntegrationCode.XeroInvoice,
        integrationType: domain.IntegrationType.Xero,
        id: value.InvoiceId,
        number: value.InvoiceNumber,
        reference: value.InvoiceReference,
        type: value.InvoiceType,
        dueDate: value.DueDate,
        cisTaxAmount: value.CisTaxAmount,
        hasCisDeduction: value.HasCisDeduction,
        matchedPurchaseOrder: mapXeroInvoiceMatchedPO(value.MatchedPO),
        paymentDetails: value.PaymentDetails
            ? {
                  amount: value.PaymentDetails.Amount,
                  date: value.PaymentDetails.Date,
                  account: {
                      id: value.PaymentDetails.BankAccountId,
                      text: value.PaymentDetails.BankAccountName,
                      code: value.PaymentDetails.BankAccountCode,
                      currencyCode: value.PaymentDetails.BankAccountCurrencyCode,
                      number: value.PaymentDetails.BankAccountNumber,
                  },
              }
            : undefined,
        sendToSupplier: emailToContact?.EmailHasToBeSent || false,
        emailToSupplier: {
            from: emailToContact?.EmailFrom || '',
            replyTo: emailToContact?.EmailReplyTo || '',
            to: (emailToContact?.EmailTo || '').split(',').filter(Boolean),
            cc: (emailToContact?.EmailCC || '').split(',').filter(Boolean),
            subject: emailToSupplierHelpers.escapeExpressions(emailToContact?.EmailSubject || ''),
            body: emailToContact?.EmailBody || '',
            emailHasToBeSent: emailToContact?.EmailHasToBeSent || false,
            attachments: (emailToContact?.Attachments || [])
                .map((attachment) => mapAttachment(attachment))
                .sort(compareHelpers.comparatorFor<RequestAttachment>(compareHelpers.stringComparator2Asc, 'name')),
        },
    };

    return invoice;
}

export const mapXeroInvoiceV2 = (
    value: PascalCaseToCamelCase<backend.IntegrationsXeroInvoiceAnswer | ApiTypes['IntegrationsXeroInvoiceAnswer']>,
    emailToContact?: PascalCaseToCamelCase<backend.EmailToContact>
): XeroInvoiceDetails => {
    if (!value) {
        throw errorHelpers.formatError('[requestSchema.Xero] Invoice details is undefined.');
    }

    const invoice: XeroInvoiceDetails = {
        ...mapSharedDetailsV2(value),
        integrationCode: IntegrationCode.XeroInvoice,
        integrationType: domain.IntegrationType.Xero,
        status: value.status || null,
        lockDate: value.lockDate || value.companyLockDate || null,
        lockDatePolicy: value.templateLockDatePolicy,
        id: value.invoiceId,
        number: value.invoiceNumber || '',
        reference: value.invoiceReference || '',
        type: value.invoiceType || '',
        dueDate: value.dueDate || '',
        cisTaxAmount: value.cisTaxAmount,
        hasCisDeduction: value.hasCisDeduction,
        // TODO: will be fixed in the api schema https://approvalmax.slack.com/archives/C03PACM9P62/p1724955426754209
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        matchedPurchaseOrder: mapXeroInvoiceMatchedPOV2(value.matchedPO),
        paymentDetails: value.paymentDetails
            ? {
                  amount: value.paymentDetails.amount,
                  date: value.paymentDetails.date || '',
                  account: {
                      id: value.paymentDetails.bankAccountId || '',
                      text: value.paymentDetails.bankAccountName || '',
                      code: value.paymentDetails.bankAccountCode || '',
                      currencyCode: value.paymentDetails.bankAccountCurrencyCode || '',
                      number: value.paymentDetails.bankAccountNumber || '',
                  },
              }
            : undefined,
        sendToSupplier: emailToContact?.emailHasToBeSent || false,
        emailToSupplier: {
            from: emailToContact?.emailFrom || '',
            replyTo: emailToContact?.emailReplyTo || '',
            to: (emailToContact?.emailTo || '').split(',').filter(Boolean),
            cc: (emailToContact?.emailCC || '').split(',').filter(Boolean),
            subject: emailToSupplierHelpers.escapeExpressions(emailToContact?.emailSubject || ''),
            body: emailToContact?.emailBody || '',
            emailHasToBeSent: emailToContact?.emailHasToBeSent || false,
            attachments: (emailToContact?.attachments || [])
                .map((attachment) => mapAttachment(attachment))
                .sort(compareHelpers.comparatorFor<RequestAttachment>(compareHelpers.stringComparator2Asc, 'name')),
        },
    };

    return invoice;
};

function mapXeroQuoteDetails(
    value: backend.IntegrationsXeroQuoteAnswer,
    emailToContact?: backend.EmailToContact
): XeroQuoteDetails {
    if (!value) {
        throw errorHelpers.formatError('[requestSchema.Xero] Invoice details is undefined.');
    }

    return {
        ...mapSharedDetails(value, domain.IntegrationCode.XeroQuote),
        integrationCode: domain.IntegrationCode.XeroQuote,
        integrationType: domain.IntegrationType.Xero,
        id: value.QuoteId,
        number: value.QuoteNumber,
        reference: value.QuoteReference,
        expiryDate: value.ExpiryDate,
        title: value.Title || '',
        summary: value.Summary || '',
        terms: value.Terms || '',
        sendToSupplier: emailToContact?.EmailHasToBeSent || false,
        quoteStatus: value.Status || null,
        isAccepted: value.IsAccepted,
        emailToSupplier: {
            emailHasToBeSent: emailToContact?.EmailHasToBeSent || false,
            from: emailToContact?.EmailFrom || '',
            replyTo: emailToContact?.EmailReplyTo || '',
            to: (emailToContact?.EmailTo || '').split(',').filter(Boolean),
            cc: (emailToContact?.EmailCC || '').split(',').filter(Boolean),
            subject: emailToSupplierHelpers.escapeExpressions(emailToContact?.EmailSubject || ''),
            body: emailToContact?.EmailBody || '',
            attachments: (emailToContact?.Attachments || [])
                .map((attachment) => mapAttachment(attachment))
                .sort(compareHelpers.comparatorFor<RequestAttachment>(compareHelpers.stringComparator2Asc, 'name')),
        },
    };
}

export const mapXeroQuoteDetailsV2 = (
    value: PascalCaseToCamelCase<backend.IntegrationsXeroQuoteAnswer | ApiTypes['IntegrationsXeroQuoteAnswer']>,
    emailToContact?: PascalCaseToCamelCase<backend.EmailToContact>
): XeroQuoteDetails => {
    if (!value) {
        throw errorHelpers.formatError('[requestSchema.Xero] Invoice details is undefined.');
    }

    return {
        ...mapSharedDetailsV2(value),
        integrationCode: domain.IntegrationCode.XeroQuote,
        integrationType: domain.IntegrationType.Xero,
        status: value.status ?? null,
        lockDate: null,
        lockDatePolicy: domain.TemplateSettingsLockDatePolicy.LockApproval,
        id: value.quoteId,
        number: value.quoteNumber || '',
        reference: value.quoteReference || '',
        expiryDate: value.expiryDate || '',
        title: value.title ?? '',
        summary: value.summary ?? '',
        terms: value.terms ?? '',
        sendToSupplier: emailToContact?.emailHasToBeSent ?? false,
        quoteStatus: value.status ?? null,
        isAccepted: value.isAccepted ?? null,
        emailToSupplier: {
            emailHasToBeSent: emailToContact?.emailHasToBeSent ?? false,
            from: emailToContact?.emailFrom ?? '',
            replyTo: emailToContact?.emailReplyTo ?? '',
            to: (emailToContact?.emailTo ?? '').split(',').filter(Boolean),
            cc: (emailToContact?.emailCC ?? '').split(',').filter(Boolean),
            subject: emailToSupplierHelpers.escapeExpressions(emailToContact?.emailSubject ?? ''),
            body: emailToContact?.emailBody ?? '',
            attachments: (emailToContact?.attachments ?? [])
                .map((attachment) => mapAttachment(attachment))
                .sort(compareHelpers.comparatorFor<RequestAttachment>(compareHelpers.stringComparator2Asc, 'name')),
        },
    };
};

function mapCreditNote(
    value: backend.IntegrationsXeroCreditNoteAnswer,
    integrationCode: IntegrationCode.XeroCreditNotesPayable | IntegrationCode.XeroCreditNotesReceivable
): XeroCreditNoteDetails {
    if (!value) {
        throw errorHelpers.formatError('[requestSchema.Xero] Credit Note details is undefined.');
    }

    return {
        ...mapSharedDetails(value),
        integrationCode,
        integrationType: domain.IntegrationType.Xero,
        id: value.CreditNoteId,
        number: value.CreditNoteNumber,
        reference: value.CreditNoteReference,
        type: value.CreditNoteType,
        remainingCredit: value.RemainingCredit,
        cisTaxAmount: value.CisTaxAmount,
        hasCisDeduction: value.HasCisDeduction,
    };
}

export const mapCreditNoteV2 = (
    value: PascalCaseToCamelCase<
        backend.IntegrationsXeroCreditNoteAnswer | ApiTypes['IntegrationsXeroCreditNoteAnswer']
    >,
    integrationCode: IntegrationCode.XeroCreditNotesPayable | IntegrationCode.XeroCreditNotesReceivable
): XeroCreditNoteDetails => {
    if (!value) {
        throw errorHelpers.formatError('[requestSchema.Xero] Credit Note details is undefined.');
    }

    return {
        ...mapSharedDetailsV2(value),
        integrationCode,
        integrationType: domain.IntegrationType.Xero,
        status: value.status || null,
        lockDate: value.lockDate || value.companyLockDate || null,
        lockDatePolicy: value.templateLockDatePolicy,
        id: value.creditNoteId,
        number: value.creditNoteNumber || '',
        reference: value.creditNoteReference || '',
        type: value.creditNoteType || '',
        remainingCredit: value.remainingCredit,
        cisTaxAmount: value.cisTaxAmount,
        hasCisDeduction: value.hasCisDeduction,
    };
};

export const mapXeroContactDetails = (
    value:
        | backend.transfers.IntegrationXeroContactTransfer
        | domain.XeroContact
        | ApiTypes['IntegrationsXeroContactAnswerCamelCased']
): XeroContactDetails => {
    if (!value) {
        throw errorHelpers.formatError('[requestSchema.Xero] Contact details is undefined.');
    }

    const beautifiedEntity = getBeautifiedEntity(value) as backend.transfers.IntegrationXeroContactTransfer;

    return {
        integrationCode: domain.IntegrationCode.XeroContact,
        integrationType: domain.IntegrationType.Xero,
        accountNumber: beautifiedEntity.accountNumber!,
        name: beautifiedEntity.name,
        firstName: beautifiedEntity.firstName,
        lastName: beautifiedEntity.lastName,
        emailAddress: beautifiedEntity.emailAddress,
        // @ts-expect-error expected string | null, got string | undefined
        persons: (beautifiedEntity.persons || []).map((person) => getBeautifiedEntity(person)),
        salesTrackingCategories: beautifiedEntity.salesTrackingCategories?.map(mapTrackingCategories) || [],
        purchasesTrackingCategories: beautifiedEntity.purchaseTrackingCategories?.map(mapTrackingCategories) || [],
        postalAddress: getBeautifiedEntity(beautifiedEntity.postalAddress || {}),
        mailingAddress: getBeautifiedEntity(beautifiedEntity.mailingAddress || {}),
        taxNumber: beautifiedEntity.taxNumber,
        companyNumber: beautifiedEntity.companyNumber,
        salesTax: beautifiedEntity.salesTax,
        purchaseTax: beautifiedEntity.purchaseTax,
        salesAccount: beautifiedEntity.salesAccount,
        purchasesAccount: beautifiedEntity.purchaseAccount,
        bankAccountDetails: beautifiedEntity.bankAccountDetails,
        skypeName: beautifiedEntity.skypeName,

        phoneNumber: getBeautifiedEntity(beautifiedEntity.phoneNumber || {}),
        fax: getBeautifiedEntity(beautifiedEntity.fax || {}),
        directDial: getBeautifiedEntity(beautifiedEntity.directDial || {}),
        mobile: getBeautifiedEntity(beautifiedEntity.mobile || {}),
        defaultCurrency: beautifiedEntity.defaultCurrency,
        customer: beautifiedEntity.isCustomer,
        supplier: beautifiedEntity.isSupplier,
        isStatusPushPending: beautifiedEntity.isStatusPushPending,
        url: beautifiedEntity.url,
    };
};

const mapAmaxPayBatchPaymentDetails = (
    value: backend.IntegrationXeroAmaxPayBatchPaymentAnswer
): XeroAmaxPayBatchPaymentDetails => {
    return {
        ...mapSharedDetails(value),
        integrationCode: domain.IntegrationCode.XeroAmaxPayBatchPayment,
        integrationType: domain.IntegrationType.Xero,
        number: '',
        reference: '',
        id: value.amaxPayXeroBatchPaymentId,
        status: value.status,
        isMergedBySupplier: value.isMergedBySupplier,
        referencesForMergedItems: value.isMergedBySupplier
            ? value.items.reduce<Record<string, string>>((acc, item) => {
                  if (item.contact && item.paymentDetails.id && item.paymentReference) {
                      acc[`${item.contact.id},${item.paymentDetails.id}`] = item.paymentReference;
                  }

                  return acc;
              }, {})
            : {},
        items: value.items.map(
            ({ paymentDetails, paymentReference, amountDue, attachments, contact, amaxPayBankAccount, ...item }) => ({
                ...item,
                paymentReference: paymentReference || '',
                amountDue: amountDue || 0,
                amountOnApproval: item.amountOnApproval || 0,
                amountInProcessing: item.amountInProcessing || 0,
                amountAwaitingPayment: item.amountAwaitingPayment || 0,
                attachments: (attachments || [])
                    .map(mapAttachment)
                    .sort(compareHelpers.comparatorFor<RequestAttachment>(compareHelpers.stringComparator2Asc, 'name')),
                contact: contact
                    ? {
                          id: contact.id,
                          text: contact.name,
                      }
                    : null,
                amaxPayBankAccount: amaxPayBankAccount
                    ? {
                          id: amaxPayBankAccount.id,
                          text: amaxPayBankAccount.name,
                      }
                    : null,
                paymentDetails: paymentDetails
                    ? {
                          ...paymentDetails,
                          bankAccount: {
                              name: paymentDetails.name,
                              sortCode: '',
                              accountNumber: '',
                              country: '',
                              currency: '',
                          },
                      }
                    : null,
                paymentDetailsPaymentStatus:
                    item.paymentDetailsPaymentStatus ?? domain.PaymentDetailsPaymentStatus.NotChecked,
                paymentStatus: item.paymentStatus ?? domain.AmaxPayPaymentStatus.ReadyToPay,
                scheduledDate: item.scheduledDate,
            })
        ),
    };
};

export const mapAirwallexBatchPaymentDetails = (
    value:
        | backend.IntegrationXeroAirwallexBatchPaymentAnswer
        | ApiTypes['IntegrationsAirwallexXeroAirwallexBatchPaymentAnswerCamelCased']
): XeroAirwallexBatchPaymentDetails => {
    return {
        ...mapSharedDetails(value),
        integrationCode: domain.IntegrationCode.XeroAirwallexBatchPayment,
        integrationType: domain.IntegrationType.Xero,
        number: '',
        reference: '',
        id: value.airwallexXeroBatchPaymentId,
        paymentStatus: value.status || null,
        paymentProcessingDetails: value.paymentProcessingDetails,
        scheduledPaymentDate: value.scheduledPaymentDate,
        scheduledPaymentFailed: value.scheduledPaymentFailed,
        items: value.items.map((item) => ({
            xeroBillInvoiceRequestId: item.xeroBillInvoiceRequestId || '',
            amount: item.amount || 0,
            beneficiary: item.beneficiary ? mapBeneficiary(item.beneficiary) : null,
            contact: item.contact
                ? {
                      id: item.contact.id,
                      text: item.contact.name || '',
                  }
                : null,
            paymentReference: item.paymentReference || '',
            sourceCurrency: item.sourceCurrency
                ? {
                      id: item.sourceCurrency,
                      text: item.sourceCurrency,
                  }
                : null,
            paymentMethod: item.beneficiary?.paymentMethod
                ? {
                      id: item.beneficiary.paymentMethod,
                      text: item.beneficiary.paymentMethod,
                  }
                : null,
            paymentPurpose: item.paymentPurpose
                ? {
                      id: item.paymentPurpose,
                      text:
                          item.paymentPurpose === 'wages_salary'
                              ? messages.wagesOrSalary
                              : capitalize(lowerCase(item.paymentPurpose)),
                  }
                : null,
            amountDue: item.amountDue || 0,
            date: item.date || '',
            dueDate: item.dueDate || '',
            friendlyName: item.friendlyName,
            currency: item.currency || '',
            bankAccount: item.bankAccount
                ? {
                      // @ts-expect-error expected PascalCase, got camelCase from the API schema
                      id: item.bankAccount.Id,
                      // @ts-expect-error expected PascalCase, got camelCase from the API schema
                      text: item.bankAccount.Code
                          ? // @ts-expect-error expected PascalCase, got camelCase from the API schema
                            `${item.bankAccount.Code} - ${item.bankAccount.Name}`
                          : // @ts-expect-error expected PascalCase, got camelCase from the API schema
                            item.bankAccount.Name,
                  }
                : null,
            paymentFee: item.paymentFee
                ? {
                      id: item.paymentFee,
                      text: item.paymentFee,
                  }
                : null,
            amountOnApproval: item.amountOnApproval || 0,
            amountInProcessing: item.amountInProcessing || 0,
            amountAwaitingPayment: item.amountAwaitingPayment || 0,
            attachments: (item.attachments || [])
                .map(mapAttachment)
                .sort(compareHelpers.comparatorFor<RequestAttachment>(compareHelpers.stringComparator2Asc, 'name')),
            sourceAmount: item.sourceAmount,
            feeAmount: item.feeAmount,
            status: item.status,
            errorMessage: item.errorMessage,
            statusUpdatedAt: item.statusUpdatedAt,
            xeroUrl: item.xeroUrl,
            airwallexUrl: item.airwallexUrl,
            beneficiaryPaymentStatus: item.beneficiaryPaymentStatus || null,
            beneficiaryDiscrepancy: item.beneficiaryDiscrepancy
                ? {
                      ...item.beneficiaryDiscrepancy,
                      // TODO: will be fixed in the api schema https://approvalmax.slack.com/archives/C03PACM9P62/p1724958877546179
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-expect-error
                      isBeneficiaryDeleted: item.beneficiaryDiscrepancy.isBeneficiaryDeleted || false,
                      beneficiaryName: item.beneficiaryDiscrepancy.beneficiaryName || '',
                      lastPaymentProperties: item.beneficiaryDiscrepancy.lastPaymentProperties || [],
                  }
                : null,
        })),
    };
};

const mapBillBatchPaymentDetails = (
    value: backend.IntegrationXeroBillBatchPaymentAnswer
): XeroBillBatchPaymentDetails => {
    const bankAccount = value.bankAccount && {
        id: value.bankAccount.Id,
        text: value.bankAccount.Name,
    };

    return {
        ...mapSharedDetails(value),
        integrationCode: domain.IntegrationCode.XeroBillBatchPayment,
        integrationType: domain.IntegrationType.Xero,
        paymentDate: value.paymentDate,
        narrative: value.narrative,
        details: value.details,
        id: value.xeroBillBatchPaymentId,
        workflowIsSupplierBankAccountRequired: value.workflowIsSupplierBankAccountRequired,
        bankAccount,
        reference: value.reference || '',
        code: value.code || '',
        particulars: value.particulars || '',
        number: '',
        items: value.items.map((item) => ({
            xeroBillInvoiceRequestId: item.xeroBillInvoiceRequestId,
            friendlyName: item.friendlyName,
            date: item.date,
            dueDate: item.dueDate,
            amountDue: item.amountDue,
            note: item.note,
            currency: item.currency,
            bankAccount: item.bankAccountNumber,
            contact: {
                id: item.contact.Id,
                text: item.contact.Name,
            },
            details: item.details,
            amountOnApproval: item.amountOnApproval || 0,
            amountAwaitingPayment: item.amountAwaitingPayment || 0,
            amountInProcessing: item.amountInProcessing || 0,
            amount: item.amount || 0,
            reference: item.reference,
            code: item.code,
            particulars: item.particulars,
            xeroBillInvoiceReference: item.xeroBillInvoiceReference,
            defaultBankAccount: item.defaultBankAccountNumber || null,
            attachments: (item.attachments || [])
                .map(mapAttachment)
                .sort(compareHelpers.comparatorFor<RequestAttachment>(compareHelpers.stringComparator2Asc, 'name')),
            outstandingBalance: item.outstandingBalance,
            overdueBalance: item.overdueBalance,
        })),
    };
};

export const mapXeroBillBatchPaymentDetailsV2 = (
    value: PascalCaseToCamelCase<
        backend.IntegrationXeroBillBatchPaymentAnswer | ApiTypes['IntegrationsXeroBillBatchPaymentAnswerCamelCased']
    >
): XeroBillBatchPaymentDetails => {
    const bankAccount = value.bankAccount && {
        id: value.bankAccount.id,
        text: value.bankAccount.name,
    };

    return {
        integrationCode: domain.IntegrationCode.XeroBillBatchPayment,
        integrationType: domain.IntegrationType.Xero,

        contact: null,
        date: '',
        status: null,
        brandingTheme: null,
        url: value.url ?? null,
        editUrl: value.editUrl ?? null,
        invoiceTaxes: [],
        lineAmountType: domain.LineAmountType.TaxExclusive,
        lineItems: [],
        subTotal: 0,
        integrationEnabled: true,
        isStatusPushPending: value.isStatusPushPending || false,
        lockDate: value.lockDate ?? null,
        lockDatePolicy: value.workflowLockDatePolicy ?? domain.TemplateSettingsLockDatePolicy.LockApproval,

        paymentDate: value.paymentDate || '',
        narrative: value.narrative || '',
        details: value.details || '',
        id: value.xeroBillBatchPaymentId,
        workflowIsSupplierBankAccountRequired: value.workflowIsSupplierBankAccountRequired,
        bankAccount: bankAccount || null,
        reference: value.reference || '',
        code: value.code || '',
        particulars: value.particulars || '',
        number: '',
        items: value.items.map((item) => ({
            xeroBillInvoiceRequestId: item.xeroBillInvoiceRequestId,
            friendlyName: item.friendlyName,
            date: item.date || '',
            dueDate: item.dueDate || '',
            amountDue: item.amountDue || 0,
            note: item.note || '',
            currency: item.currency || '',
            bankAccount: item.bankAccountNumber || '',
            contact: {
                id: item.contact?.id || '',
                text: item.contact?.name || '',
            },
            details: item.details || '',
            amountOnApproval: item.amountOnApproval || 0,
            amountAwaitingPayment: item.amountAwaitingPayment || 0,
            amountInProcessing: item.amountInProcessing || 0,
            amount: item.amount || 0,
            reference: item.reference || '',
            code: item.code || '',
            particulars: item.particulars || '',
            xeroBillInvoiceReference: item.xeroBillInvoiceReference || '',
            defaultBankAccount: item.defaultBankAccountNumber || null,
            attachments: (item.attachments || []).map(mapAttachment),
            // TODO: will be fixed in the api schema https://approvalmax.slack.com/archives/C03PACM9P62/p1724957823059389
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            outstandingBalance: item.outstandingBalance,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            overdueBalance: item.overdueBalance,
        })),
    };
};

const mapManualJournalLine = (line: backend.XeroManualJournalLine): XeroManualJournalLine => ({
    id: line.LineItemId,
    account: line.AccountId
        ? {
              id: line.AccountId,
              text: line.Account,
          }
        : undefined,
    amount: line.Amount,
    amountType: line.AmountSide,
    description: line.Description,
    tracking: (line.Tracking || []).map(mapTrackingCategories),
    tax: line.TaxRateString
        ? {
              id: line.TaxCode,
              rate: line.TaxRate,
              rateEffective: line.TaxRateEffective || line.TaxRate,
              text: line.TaxRateString,
              applicableToAccountClasses: [
                  XeroAccountClass.Asset,
                  XeroAccountClass.Equity,
                  XeroAccountClass.Expense,
                  XeroAccountClass.Liability,
                  XeroAccountClass.Revenue,
              ],
          }
        : undefined,
    taxAmount: line.TaxAmount,
    isInventory: line.IsInventory ?? false,
});

const mapManualJournalLineV2 = (
    line: PascalCaseToCamelCase<
        backend.XeroManualJournalLine | ApiTypes['IntegrationsXeroManualJournalLineItemAnswerCamelCased']
    >
): XeroManualJournalLine => ({
    id: line.lineItemId || '',
    account: line.accountId
        ? {
              id: line.accountId,
              text: line.account || '',
          }
        : undefined,
    amount: line.amount,
    amountType: line.amountSide || null,
    description: line.description || '',
    tracking: (line.tracking || []).map(mapTrackingCategoriesV2),
    tax: line.taxRateString
        ? {
              id: line.taxCode || '',
              rate: line.taxRate || 0,
              rateEffective: line.taxRateEffective || line.taxRate || 0,
              text: line.taxRateString,
              applicableToAccountClasses: [
                  XeroAccountClass.Asset,
                  XeroAccountClass.Equity,
                  XeroAccountClass.Expense,
                  XeroAccountClass.Liability,
                  XeroAccountClass.Revenue,
              ],
          }
        : undefined,
    taxAmount: line.taxAmount,
    // TODO: will be fixed in the api schema https://approvalmax.slack.com/archives/C03PACM9P62/p1724962137702429
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    isInventory: line.isInventory ?? false,
});

const mapManualJournalDetails = (value: backend.IntegrationsXeroManualJournalAnswer): XeroManualJournalDetails => {
    const lockDate = value.LockDate;
    const companyLockDate = value.CompanyLockDate;
    const templateLockDatePolicy = value.TemplateLockDatePolicy;

    return {
        integrationCode: domain.IntegrationCode.XeroManualJournal,
        integrationType: domain.IntegrationType.Xero,
        date: value.Date || null,
        lockDate: lockDate || companyLockDate || null,
        lockDatePolicy: templateLockDatePolicy || null,
        narration: value.Narration || '',
        showOnCashBasisReports: typeof value.ShowOnCashBasisReports === 'boolean' ? value.ShowOnCashBasisReports : true,
        lineAmountType: value.LineAmountType || LineAmountType.NoTax,
        lineItems: (value.LineItems || []).map(mapManualJournalLine),
        url: value.Url || null,
        editUrl: value.EditUrl || null,
        isStatusPushPending: value.IsStatusPushPending || false,
    };
};

export const mapXeroManualJournalDetailsV2 = (
    value: PascalCaseToCamelCase<
        backend.IntegrationsXeroManualJournalAnswer | ApiTypes['IntegrationsXeroManualJournalAnswerCamelCased']
    >
): XeroManualJournalDetails => {
    const lockDate = value.lockDate;
    const companyLockDate = value.companyLockDate;
    const templateLockDatePolicy = value.templateLockDatePolicy;

    return {
        integrationCode: domain.IntegrationCode.XeroManualJournal,
        integrationType: domain.IntegrationType.Xero,
        date: value.date || null,
        lockDate: lockDate || companyLockDate || null,
        lockDatePolicy: templateLockDatePolicy || null,
        narration: value.narration || '',
        showOnCashBasisReports: typeof value.showOnCashBasisReports === 'boolean' ? value.showOnCashBasisReports : true,
        lineAmountType: (value.lineAmountType as LineAmountType) || LineAmountType.NoTax,
        lineItems: (value.lineItems || []).map(mapManualJournalLineV2),
        url: value.url || null,
        editUrl: value.editUrl || null,
        isStatusPushPending: value.isStatusPushPending || false,
    };
};

export const mapXeroDetails = (request: backend.RequestAnswer): XeroRequestDetails => {
    switch (request.IntegrationCode) {
        case IntegrationCode.XeroPo:
            return mapPurchaseOrder(request.XeroPurchaseOrderDetails, request.EmailToContact);

        case IntegrationCode.XeroQuote:
            return mapXeroQuoteDetails(request.XeroQuoteDetails, request.EmailToContact);

        case IntegrationCode.XeroBill:
            return mapBill(request.XeroInvoiceDetails);

        case IntegrationCode.XeroInvoice:
            return mapInvoice(request.XeroSalesInvoiceDetails, request.EmailToContact);

        case IntegrationCode.XeroCreditNotesPayable:
            return mapCreditNote(request.XeroCreditNoteDetails, IntegrationCode.XeroCreditNotesPayable);

        case IntegrationCode.XeroCreditNotesReceivable:
            return mapCreditNote(request.XeroCreditNoteDetails, IntegrationCode.XeroCreditNotesReceivable);

        case IntegrationCode.XeroContact:
            return mapXeroContactDetails(request.XeroContactDetails);

        case IntegrationCode.XeroBillBatchPayment:
            return mapBillBatchPaymentDetails(request.XeroBillBatchPaymentDetails);

        case IntegrationCode.XeroAirwallexBatchPayment:
            return mapAirwallexBatchPaymentDetails(request.AirwallexXeroBatchPaymentDetails);

        case IntegrationCode.XeroManualJournal:
            return mapManualJournalDetails(request.XeroManualJournalDetails);

        default:
            throw errorHelpers.notSupportedError();
    }
};

export const mapXeroRequestSpecifics = (request: backend.RequestAnswer): XeroRequestSpecifics => {
    switch (request.IntegrationCode) {
        case IntegrationCode.XeroPo:
            return {
                details: mapPurchaseOrder(request.XeroPurchaseOrderDetails, request.EmailToContact),
                integrationCode: request.IntegrationCode,
                integrationType: domain.IntegrationType.Xero,
            };

        case IntegrationCode.XeroQuote:
            return {
                details: mapXeroQuoteDetails(request.XeroQuoteDetails, request.EmailToContact),
                integrationCode: request.IntegrationCode,
                integrationType: domain.IntegrationType.Xero,
            };

        case IntegrationCode.XeroBill:
            return {
                details: mapBill(request.XeroInvoiceDetails),
                integrationCode: request.IntegrationCode,
                integrationType: domain.IntegrationType.Xero,
            };

        case IntegrationCode.XeroInvoice:
            return {
                details: mapInvoice(request.XeroSalesInvoiceDetails, request.EmailToContact),
                integrationCode: request.IntegrationCode,
                integrationType: domain.IntegrationType.Xero,
            };

        case IntegrationCode.XeroCreditNotesPayable:
            return {
                details: mapCreditNote(request.XeroCreditNoteDetails, IntegrationCode.XeroCreditNotesPayable),
                integrationCode: request.IntegrationCode,
                integrationType: domain.IntegrationType.Xero,
            };

        case IntegrationCode.XeroCreditNotesReceivable:
            return {
                details: mapCreditNote(request.XeroCreditNoteDetails, IntegrationCode.XeroCreditNotesReceivable),
                integrationCode: request.IntegrationCode,
                integrationType: domain.IntegrationType.Xero,
            };

        case IntegrationCode.XeroContact:
            return {
                details: mapXeroContactDetails(request.XeroContactDetails),
                integrationCode: request.IntegrationCode,
                integrationType: domain.IntegrationType.Xero,
            };

        case IntegrationCode.XeroAmaxPayBatchPayment:
            return {
                details: mapAmaxPayBatchPaymentDetails(request.AmaxPayXeroBatchPaymentDetails),
                integrationCode: request.IntegrationCode,
                integrationType: domain.IntegrationType.Xero,
            };

        case IntegrationCode.XeroBillBatchPayment:
            return {
                details: mapBillBatchPaymentDetails(request.XeroBillBatchPaymentDetails),
                integrationCode: request.IntegrationCode,
                integrationType: domain.IntegrationType.Xero,
            };

        case IntegrationCode.XeroAirwallexBatchPayment:
            return {
                details: mapAirwallexBatchPaymentDetails(request.AirwallexXeroBatchPaymentDetails),
                integrationCode: request.IntegrationCode,
                integrationType: domain.IntegrationType.Xero,
            };

        case IntegrationCode.XeroManualJournal:
            return {
                details: mapManualJournalDetails(request.XeroManualJournalDetails),
                integrationCode: request.IntegrationCode,
                integrationType: domain.IntegrationType.Xero,
            };

        default:
            throw errorHelpers.notSupportedError();
    }
};
