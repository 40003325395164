import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { profileApiPaths } from '../paths';
import { VerifyResetPasswordTFACode } from './useVerifyResetPasswordTFACode.types';

export const useVerifyResetPasswordTFACode = (mutationOptions?: RequestMutationOptions<VerifyResetPasswordTFACode>) => {
    return useMutate(profileApiPaths.verifyResetPasswordTFACode, {
        mutationOptions,
        apiVersion: 'v1',
    });
};
