import { font, Popup } from '@approvalmax/ui';
import { intl } from '@approvalmax/utils';
import ImageXeroMatchingEmptyState from 'modules/components/ImageIcons/ImageXeroMatchingEmptyState';
import { defineMessages } from 'react-intl';
import styled from 'styled-components';

const i18nPrefix =
    'requestList/containers/xero/XeroMatchingV2Section/components/BillView/components/ChangeMatchingPopup/components/ChangeMatchingPopupEmpty/ChangeMatchingPopupEmpty';

const messages = defineMessages({
    emptyStateTitle: {
        id: `${i18nPrefix}.emptyStateTitle`,
        defaultMessage: 'Choose PO to match with',
    },
    emptyStateText: {
        id: `${i18nPrefix}.emptyStateText`,
        defaultMessage: 'There are no Purchase Orders available for matching',
    },
});

const DefaultContent = styled(Popup.DefaultContent)`
    width: 800px;
    min-width: 700px;
    min-height: 400px;
    height: 70%;
`;

const EmptyContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
`;

const EmptyText = styled.span`
    margin-top: 25px;
    ${font(16, '#a0a1a1', 'bold')}
`;

const ChangeMatchingPopupEmpty = () => {
    const emptyStateTitle = intl.formatMessage(messages.emptyStateTitle);
    const emptyStateText = intl.formatMessage(messages.emptyStateText);

    return (
        <DefaultContent title={emptyStateTitle}>
            <EmptyContainer>
                <ImageXeroMatchingEmptyState />

                <EmptyText>{emptyStateText}</EmptyText>
            </EmptyContainer>
        </DefaultContent>
    );
};

export default ChangeMatchingPopupEmpty;
