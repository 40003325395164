import './xeroReceiptBankPaymentDetailsSection.scss';

import { Field } from '@approvalmax/ui';
import { intl } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { AlertSmallIcon } from 'modules/sprites';
import moment from 'moment';
import { FC, memo } from 'react';
import bemFactory from 'react-bem-factory';
import { FormattedMessage } from 'react-intl';

import SectionContainer from '../../components/SectionContainer';

const i18nPrefix = 'requestList/containers/xero/XeroReceiptBankPaymentDetailsSection';

const bem = bemFactory.block('reql-xero-receipt-bank-payment-details-section');

interface XeroReceiptBankPaymentDetailsSectionProps {
    className?: string;
    paymentDetails: domain.XeroInvoicePaymentDetails;
}

const XeroReceiptBankPaymentDetailsSection: FC<XeroReceiptBankPaymentDetailsSectionProps> = (props) => {
    const { className, paymentDetails: d } = props;

    return (
        <div className={bem.add(className)()}>
            <SectionContainer
                noPadding
                text={<FormattedMessage id={`${i18nPrefix}.sectionHeaderText`} defaultMessage='Payment details' />}
            >
                <div className={bem('warning')}>
                    <AlertSmallIcon />

                    <div className={bem('warning-text')}>
                        <FormattedMessage
                            id={`${i18nPrefix}.warningText`}
                            defaultMessage='This bill was published as paid which means that this request would be created as “Paid” in Xero and respective Bank Transaction would be created as well'
                        />
                    </div>
                </div>

                <div className={bem('fields')}>
                    <Field
                        className={bem('field-date')}
                        title={intl.formatMessage({
                            id: `${i18nPrefix}.dateField`,
                            defaultMessage: 'Date',
                        })}
                    >
                        <div className={bem('field-value')}>{moment(d.date).format('ll')}</div>
                    </Field>

                    <Field
                        className={bem('field-account')}
                        title={intl.formatMessage({
                            id: `${i18nPrefix}.accountField`,
                            defaultMessage: 'Account',
                        })}
                    >
                        <div className={bem('field-value')}>{`${d.account.text}: ${d.account.number}`}</div>
                    </Field>

                    <Field
                        className={bem('field-amount')}
                        title={intl.formatMessage({
                            id: `${i18nPrefix}.amountField`,
                            defaultMessage: 'Amount',
                        })}
                    >
                        <div className={bem('field-value')}>
                            <b>{intl.formatNumber(d.amount)}</b>
                        </div>
                    </Field>
                </div>
            </SectionContainer>
        </div>
    );
};

export default memo(XeroReceiptBankPaymentDetailsSection);
