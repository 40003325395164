import { toast } from '@approvalmax/ui/src/components';
import { actions as automationActions } from 'modules/automation';
import { actions } from 'modules/common';
import { openTrialPeriodStartedPopup } from 'modules/profile/actions';
import { useDispatch } from 'modules/react-redux';
import { useUserProfile } from 'modules/utils';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useActivateTrialOfAllFeatures } from 'shared/data';
import { useGetCompanies, useGetUserContextRaw } from 'shared/data/v1';

import { checkActivateTrialInterval, maxReRequestsWaitActivate } from './useTryAllFeatures.constants';
import { checkIsTrialActive } from './useTryAllFeatures.helpers';
import { messages } from './useTryAllFeatures.messages';

export const useTryAllFeatures = (trialActiveCallback?: VoidFunction) => {
    const { mutate: mutateActivateTrialOfAllFeatures, isLoading: isLoadingActivateTrial } =
        useActivateTrialOfAllFeatures({
            onSuccess(_data, variables) {
                variables.body?.organisationId && setCompanyId(variables.body?.organisationId);
            },
        });
    const profile = useUserProfile();
    const [companyId, setCompanyId] = useState('');

    const [isTrialActive, setIsTrialActive] = useState(false);

    const pollingCountRef = useRef(0);

    const {
        data: companies,
        isSuccess: isSuccessGetCompanies,
        isError: isErrorGetCompanies,
        fetchStatus: fetchStatusGetCompanies,
    } = useGetCompanies(
        { query: { companyId } },
        {
            enabled: !!companyId,
            refetchInterval: (data) => {
                if (!data || checkIsTrialActive(data)) {
                    return false;
                }

                return checkActivateTrialInterval;
            },
        }
    );

    useEffect(() => {
        if (companyId && isSuccessGetCompanies && fetchStatusGetCompanies === 'idle') {
            if (checkIsTrialActive(companies)) {
                setIsTrialActive(true);
            } else {
                pollingCountRef.current++;

                if (pollingCountRef.current > maxReRequestsWaitActivate) {
                    toast.error(messages.trialActivationTimeoutError);

                    setCompanyId('');
                    pollingCountRef.current = 0;
                }
            }
        }
    }, [companyId, companies, fetchStatusGetCompanies, isSuccessGetCompanies]);

    useEffect(() => {
        if (companyId && isErrorGetCompanies) {
            setCompanyId('');
            pollingCountRef.current = 0;
        }
    }, [companyId, isErrorGetCompanies]);

    const dispatch = useDispatch();

    const {
        data: userContext,
        isSuccess: isSuccessGetUserContext,
        isError: isErrorGetUserContext,
        fetchStatus: fetchStatusGetUserContext,
    } = useGetUserContextRaw(undefined, {
        enabled: isTrialActive,
    });

    useEffect(() => {
        if (isTrialActive && isSuccessGetUserContext && fetchStatusGetUserContext === 'idle') {
            dispatch(actions.loadInitialAppData({ context: userContext }));

            if (companyId) {
                dispatch(automationActions.loadCompanyTemplates({ companyId }));
            }

            trialActiveCallback?.();

            dispatch(openTrialPeriodStartedPopup());
        }
    }, [
        companyId,
        userContext,
        dispatch,
        fetchStatusGetUserContext,
        isSuccessGetUserContext,
        isTrialActive,
        trialActiveCallback,
    ]);

    useEffect(() => {
        if (isTrialActive && isErrorGetUserContext) {
            setCompanyId('');
            pollingCountRef.current = 0;
            setIsTrialActive(false);
        }
    }, [isTrialActive, isErrorGetUserContext]);

    const handleStartTrial = useCallback(
        (companyId: string) => {
            mutateActivateTrialOfAllFeatures({
                body: {
                    organisationId: companyId,
                    accountId: profile.account?.accountId,
                },
                params: {
                    query: profile.account?.accountId
                        ? {
                              accountId: profile.account?.accountId,
                          }
                        : undefined,
                },
            });
        },
        [mutateActivateTrialOfAllFeatures, profile.account?.accountId]
    );

    return { handleStartTrial, isLoadingActivateAllFeature: isLoadingActivateTrial || !!companyId };
};
