import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { reloadRequest } from '../actions';

export const useReloadRequestCard = () => {
    const dispatch = useDispatch();

    return useCallback(
        (companyId: string, requestId: string) => {
            dispatch(reloadRequest(requestId, companyId));
        },
        [dispatch]
    );
};
