import { oDropdownPanel } from '@approvalmax/ui';
import styled from 'styled-components';

export const Root = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px;
`;

export const Panel = styled.div`
    ${oDropdownPanel()}
    padding: 15px;
    font-size: 12px;
`;
