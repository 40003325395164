import { miscHelpers } from '@approvalmax/utils';
import { GetFido2AssertionOptions } from 'shared/data/v2';

export const getCredentials = async (assertionOptions: GetFido2AssertionOptions['response']) => {
    const publicKey: PublicKeyCredentialRequestOptions = {
        rpId: assertionOptions.relayingPartyId,
        challenge: miscHelpers.base64ToBytes(assertionOptions.challenge),
        userVerification: assertionOptions.userVerification as UserVerificationRequirement,
        allowCredentials: (assertionOptions.allowCredentials || []).map((credential) => ({
            ...credential,
            transports: credential.transports as AuthenticatorTransport[],
            type: (credential.type || 'public-key') as PublicKeyCredentialType,
            id: miscHelpers.base64ToBytes(credential.id),
        })),
    };

    const credentials = (await navigator.credentials.get({
        publicKey,
    })) as PublicKeyCredential | null;

    if (!credentials) {
        throw new Error('No credentials found');
    }

    return credentials;
};

export const getAssertionTranfer = (
    credentials: PublicKeyCredential,
    assertionOptions: GetFido2AssertionOptions['response']
) => {
    const response = credentials.response as AuthenticatorAssertionResponse;

    return {
        id: credentials.id,
        type: credentials.type,
        optionsId: assertionOptions.id,
        rawId: miscHelpers.arrayBufferToBase64(credentials.rawId),
        response: {
            authenticatorData: miscHelpers.arrayBufferToBase64(response.authenticatorData),
            clientDataJson: miscHelpers.arrayBufferToBase64(credentials.response.clientDataJSON),
            signature: miscHelpers.arrayBufferToBase64(response.signature),
        },
    };
};
