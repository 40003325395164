const amaxPayOpenBankingBankAccounts = 'paymentservices/companies/:companyId/amaxPay/openBanking/bankAccounts';

export const paymentServicesApiPaths = {
    disconnectFromAirwallex: 'paymentservices/airwallex/disconnect',
    connectToAirwallex: 'paymentservices/airwallex/enable',
    finishConnectionToAirwallex: 'paymentservices/airwallex/finishIntegration',
    connectToReceiptBank: 'receiptBank/connect',
    disconnectFromReceiptBank: 'receiptBank/disconnect',
    getBillLinkedToBatchPayment: 'paymentservices/airwallex/xero/getBillLinkedToBatchPayment',

    amaxPayOpenBankingBankAccounts,
    crudAmaxPayOpenBankingBankAccounts: `${amaxPayOpenBankingBankAccounts}/:bankAccountId`,
    reconnectAmaxPayOpenBankingBankAccounts: `${amaxPayOpenBankingBankAccounts}/:bankAccountId/reconnect`,
    amaxPayOpenBankingBankAccountMatchXeroBankAccount: `${amaxPayOpenBankingBankAccounts}/:bankAccountId/matchToXeroBankAccount`,
    getAmaxPayOpenBankingBanks: 'paymentservices/companies/:companyId/amaxPay/openBanking/banks',
    getAmaxPayConsent: 'paymentservices/companies/:companyId/amaxPay/openBanking/consents/:consentId',
};
