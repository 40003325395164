import { domain } from 'modules/data';

export interface TotalLine {
    friendlyName: string;
    amount: number;
    amountDue: number;
    currency: string;
    dueDate: string;
    scheduledDate?: string;
    paymentReference: string;
    paymentStatus?: domain.AmaxPayPaymentStatus;
    statusUpdatedAt?: string;
    paymentDetailsPaymentStatus?: domain.PaymentDetailsPaymentStatus;
    bankAccountName: string;
    amaxPayBankAccountId: string;
    xeroUrl?: string;
    id: string;
    billRequestIdList: string[];
    isTotalLine: true;
    oneOfBillRequestsId: string;
}

export const isTotalLine = (item: domain.AmaxPayPaymentItem | TotalLine | undefined): item is TotalLine =>
    Boolean(item && 'isTotalLine' in item && item.isTotalLine);
