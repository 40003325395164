import { intl } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { ColumnDefinition } from 'pages/requestList/selectors/types/ColumnDefinition';

import { messages } from './NetSuiteVendorSubsidiaryList.messages';

export const getColumnDefs = (): ColumnDefinition<domain.NetSuiteSubsidiary>[] => [
    {
        id: 'subsidiary',
        name: messages.subsidiaryColumnName,
        value: ({ subsidiary }) => subsidiary?.name || null,
    },
    {
        id: 'credit-limit',
        name: messages.creditLimitColumnName,
        value: ({ creditLimit }) => intl.formatNumber(creditLimit || 0, 'auto'),
    },
    {
        id: 'tax-code',
        name: messages.taxCodeColumnName,
        value: ({ taxCode }) => taxCode?.text || null,
    },
];
