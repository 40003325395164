import { useMemo } from 'react';

import { getColumnDefs } from './NetSuiteVendorAddressList.config';
import { AddressListFieldSettings } from './NetSuiteVendorAddressList.types';

export const useTableDefinitions = (fieldSettings: AddressListFieldSettings) => {
    const addressListTableDefinition = useMemo(() => getColumnDefs(fieldSettings), [fieldSettings]);

    return addressListTableDefinition;
};
