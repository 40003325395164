import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages(
    'pages.RequestList.XeroMatchingV2Section.BillView.AddMatchedPOsRequestersPopup',
    {
        title: 'Add PO requesters as Approvers',
        addButtonTitle: 'Add',
        checkboxColumnTitle: 'Requester',
    }
);
