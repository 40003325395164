import './userListFilter.scss';

import { DropdownEditor } from '@approvalmax/ui';
import { intl, typeGuardHelpers } from '@approvalmax/utils';
import { selectors, ui } from 'modules/common';
import { PlainDataProvider } from 'modules/data-providers';
import { FilterType } from 'pages/reports/data/filters';
import React from 'react';
import bemFactory from 'react-bem-factory';
import { defineMessages } from 'react-intl';

import * as userListFilter from '../../data/filters/userListFilter';

const i18nPrefix = 'reports.filters.UserListFilter';
const messages = defineMessages({
    placeholder: {
        id: `${i18nPrefix}.placeholder`,
        defaultMessage: 'Any value',
    },
});

interface Props {
    filter: userListFilter.UserListFilter | userListFilter.UserListByIdFilter;
    options: selectors.types.ExpandedCompanyUser[];
    onFilterChange(filter: userListFilter.UserListFilter | userListFilter.UserListByIdFilter): void;
}

class UserListFilter extends React.Component<Props> {
    public render() {
        const { filter, options } = this.props;
        const bem = bemFactory.block('rpt-user-list-filter');

        return (
            <div className={bem()}>
                <PlainDataProvider items={options} filterAttribute='displayName'>
                    <DropdownEditor
                        multiple
                        displayAttribute='displayName'
                        theme='matrix'
                        placeholder={intl.formatMessage(messages.placeholder)}
                        value={
                            filter.type === FilterType.UserList
                                ? filter.emails
                                      .map((userEmail) => options.find((u) => u.userEmail === userEmail))
                                      .filter(typeGuardHelpers.isTruthy)
                                : filter.ids
                                      .map((id) => options.find((user) => user.databaseId === id))
                                      .filter(typeGuardHelpers.isTruthy)
                        }
                        onChange={this._onChange}
                        listItem={ui.CompanyUserListItem}
                    />
                </PlainDataProvider>
            </div>
        );
    }

    private _onChange = (value: selectors.types.ExpandedCompanyUser[]) => {
        if (this.props.filter.type === FilterType.UserList) {
            this.props.onFilterChange({
                ...this.props.filter,
                emails: value.map((user) => user.userEmail),
            });
        } else {
            this.props.onFilterChange({
                ...this.props.filter,
                ids: value.map((user) => user.databaseId ?? ''),
            });
        }
    };
}

export default UserListFilter;
