import { Flex, Text } from '@approvalmax/ui/src/components';
import { FC, memo, useCallback } from 'react';

import { messages } from './CollapseAllButton.messages';
import { CollapseButton, Icon } from './CollapseAllButton.styles';
import { CollapseAllButtonProps } from './CollapseAllButton.types';

const CollapseAllButton: FC<CollapseAllButtonProps> = memo((props) => {
    const { collapsed, onCollapse } = props;

    const handleCollapse = useCallback(() => {
        onCollapse(!collapsed);
    }, [onCollapse, collapsed]);

    return (
        <CollapseButton onClick={handleCollapse} uppercase={false} noPadding $collapsed={collapsed}>
            <Flex spacing='4' alignItems='center'>
                <Icon color='blue80' size={12} />

                <Text font='label' fontSize='small' color='blue80'>
                    {collapsed ? messages.expandAll : messages.collapseAll}
                </Text>
            </Flex>
        </CollapseButton>
    );
});

export default CollapseAllButton;
