import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { authApiPaths } from '../paths';
import { Register } from './useRegister.types';

export const useRegister = (mutationOptions?: RequestMutationOptions<Register>) => {
    return useMutate(authApiPaths.register, {
        mutationOptions,
        apiVersion: 'v1',
    });
};
