import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.Toolbar.PayWithAmax.PaymentSummaryPopupContent', {
    paymentSummary: 'Payment summary',
    total: 'Total',
    totalPaid: 'Total paid',
    remaining: 'Remaining',
    pay: 'Pay {amount}',
    order: 'Order',
    bill: 'Bill',
    dueDate: 'Due date',
    amountDue: 'Amount due',
    amount: 'Amount',
    status: 'Status',
    ready: 'Ready',
    payInfo: `You are paying {amount}\nfor the {friendlyName}`,
});
