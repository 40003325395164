import { ValueOf } from '@approvalmax/types';
import { mathService } from '@approvalmax/utils';
import { domain } from 'modules/data';

export const calculateAmounts = (
    lines: domain.XeroManualJournalLine[],
    lineAmountType: ValueOf<domain.LineAmountType>
) => {
    const result: {
        debitSubTotal: number;
        creditSubTotal: number;
        debitTotal: number;
        creditTotal: number;
        taxes: Record<
            string,
            {
                taxName: string;
                debit: number;
                credit: number;
                taxableDebitAmount: number;
                taxableCreditAmount: number;
                taxCodeId: string;
                taxPercent: number;
            }
        >;
        debitTotalTax: number;
        creditTotalTax: number;
    } = {
        debitSubTotal: 0,
        creditSubTotal: 0,
        debitTotal: 0,
        creditTotal: 0,
        taxes: {},
        debitTotalTax: 0,
        creditTotalTax: 0,
    };

    lines.forEach((line) => {
        const lineAmount = mathService.round(line.amount || 0, 2);

        if (lineAmountType === domain.LineAmountType.NoTax || !line.tax) {
            if (line.amountType === domain.XeroManualJournalLineAmountType.Debit) {
                result.debitSubTotal = mathService.add(result.debitSubTotal, lineAmount || 0);
                result.debitTotal = mathService.add(result.debitTotal, lineAmount || 0);
            }

            if (line.amountType === domain.XeroManualJournalLineAmountType.Credit) {
                result.creditSubTotal = mathService.add(result.creditSubTotal, lineAmount || 0);
                result.creditTotal = mathService.add(result.creditTotal, lineAmount || 0);
            }

            return;
        }

        if (line.tax) {
            const taxAmount = line.taxAmount ?? 0;

            const taxKey = line.tax.id;

            if (!result.taxes[taxKey]) {
                result.taxes[taxKey] = {
                    debit: 0,
                    credit: 0,
                    taxableDebitAmount: 0,
                    taxableCreditAmount: 0,
                    taxName: line.tax.text,
                    taxCodeId: line.tax.id,
                    taxPercent: line.tax.rate,
                };
            }

            if (line.amountType === domain.XeroManualJournalLineAmountType.Debit) {
                result.taxes[taxKey].debit = mathService.round(
                    mathService.add(result.taxes[taxKey].debit, taxAmount),
                    2
                );

                result.taxes[taxKey].taxableDebitAmount = mathService.round(
                    mathService.add(result.taxes[taxKey].taxableDebitAmount, lineAmount),
                    2
                );

                result.debitSubTotal = mathService.add(result.debitSubTotal, lineAmount);

                result.debitTotal = mathService.add(result.debitTotal, lineAmount);

                if (lineAmountType === domain.LineAmountType.TaxExclusive) {
                    result.debitTotal = mathService.add(result.debitTotal, taxAmount);
                }
            }

            if (line.amountType === domain.XeroManualJournalLineAmountType.Credit) {
                result.taxes[taxKey].credit = mathService.round(
                    mathService.add(result.taxes[taxKey].credit, taxAmount),
                    2
                );

                result.taxes[taxKey].taxableCreditAmount = mathService.round(
                    mathService.add(result.taxes[taxKey].taxableCreditAmount, lineAmount),
                    2
                );

                result.creditSubTotal = mathService.add(result.creditSubTotal, lineAmount);

                result.creditTotal = mathService.add(result.creditTotal, lineAmount);

                if (lineAmountType === domain.LineAmountType.TaxExclusive) {
                    result.creditTotal = mathService.add(result.creditTotal, taxAmount);
                }
            }
        }
    });

    result.debitTotalTax = Object.values(result.taxes).reduce((acc, taxValue) => {
        return mathService.add(acc, taxValue.debit);
    }, 0);

    result.creditTotalTax = Object.values(result.taxes).reduce((acc, taxValue) => {
        return mathService.add(acc, taxValue.credit);
    }, 0);

    result.debitSubTotal = mathService.round(result.debitSubTotal, 2);
    result.debitTotal = mathService.round(result.debitTotal, 2);
    result.creditSubTotal = mathService.round(result.creditSubTotal, 2);
    result.creditTotal = mathService.round(result.creditTotal, 2);
    result.debitTotalTax = mathService.round(result.debitTotalTax, 2);
    result.creditTotalTax = mathService.round(result.creditTotalTax, 2);

    return result;
};
