import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.NetSuiteRequestCard.NetSuiteVendorAddressList', {
    sectionHeaderText: 'Addresses',
    defaultShippingColumnName: 'Default shipping',
    defaultShippingTooltip: 'Default shipping',
    defaultBillingColumnName: 'Default billing',
    defaultBillingTooltip: 'Default billing',
    labelColumnName: 'Label',
    countryColumnName: 'Country',
    attentionColumnName: 'Attention',
    addresseeColumnName: 'Addressee',
    phoneColumnName: 'Phone',
    primaryAddressColumnName: 'Address 1',
    secondaryAddressColumnName: 'Address 2',
    cityColumnName: 'City',
    stateColumnName: 'State',
    zipColumnName: 'Zip',
});
