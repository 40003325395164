import { Guid } from '@approvalmax/types';
import { errorHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';

import { CompanyRequestListFilter, RequestListFilter, TemplateRequestListFilter } from '../config';
import { getSearchParams } from './pageSelectors';

export function getComputedFilter(
    request: domain.Request,
    me: Guid,
    activeFilter: RequestListFilter | CompanyRequestListFilter | TemplateRequestListFilter | null
): RequestListFilter | CompanyRequestListFilter | TemplateRequestListFilter | null {
    // Note: we cannot use the expanded request model here as the data is not yet loaded into
    // the store in some cases (e.g. when request is loaded via direct url)

    const isActiveApprover = selectors.request.isActiveApprover(request, me);
    const isActiveReviewer = selectors.request.canReview(request, me);
    const isAuthor = request.authorId === me;
    const requestStatusV2 = request.statusV2;

    if (activeFilter) {
        // If request is open + I'm author => MyOpen filter is OK
        if (
            activeFilter === CompanyRequestListFilter.CompanyMyOpen &&
            isAuthor &&
            requestStatusV2 === domain.RequestStatusV2.OnApproval
        ) {
            return activeFilter;
        }

        // If request requires my approval => DecisionRequired filter is OK
        if (
            (activeFilter === RequestListFilter.MyDecisionRequired ||
                activeFilter === CompanyRequestListFilter.CompanyMyDecisionRequired) &&
            isActiveApprover
        ) {
            return activeFilter;
        }

        if (
            (activeFilter === RequestListFilter.MyReviewRequired ||
                activeFilter === CompanyRequestListFilter.CompanyMyReviewRequired) &&
            isActiveReviewer
        ) {
            return activeFilter;
        }

        // If request isn't draft => AllRequests filter is OK
        if (activeFilter === CompanyRequestListFilter.CompanyAll && request.statusV2 !== domain.RequestStatusV2.Draft) {
            return activeFilter;
        }

        // If request is open => CompanyAllOpen filter is OK
        if (
            activeFilter === CompanyRequestListFilter.CompanyAllOpen &&
            requestStatusV2 === domain.RequestStatusV2.OnApproval
        ) {
            return activeFilter;
        }

        if (
            activeFilter === CompanyRequestListFilter.CompanyAllOnHold &&
            requestStatusV2 === domain.RequestStatusV2.OnHold
        ) {
            return activeFilter;
        }
    }

    if (isActiveApprover) {
        return RequestListFilter.MyDecisionRequired;
    }

    if (isActiveReviewer) {
        return RequestListFilter.MyReviewRequired;
    }

    if (isAuthor && request.statusV2 === domain.RequestStatusV2.OnApproval) {
        return RequestListFilter.MyOnlyOpen;
    }

    switch (request.statusV2) {
        case domain.RequestStatusV2.Draft:
            return RequestListFilter.MyDraft;

        case domain.RequestStatusV2.OnReview:
            return TemplateRequestListFilter.OnReview;

        case domain.RequestStatusV2.OnApproval:
            return TemplateRequestListFilter.OnApproval;

        case domain.RequestStatusV2.OnHold:
            return TemplateRequestListFilter.OnHold;

        case domain.RequestStatusV2.Cancelled:
            return TemplateRequestListFilter.Cancelled;

        case domain.RequestStatusV2.Rejected:
            return TemplateRequestListFilter.Rejected;

        case domain.RequestStatusV2.Approved: {
            const xeroStatus =
                request.integrationType === domain.IntegrationType.Xero &&
                request.integrationCode !== domain.IntegrationCode.XeroContact &&
                request.integrationCode !== domain.IntegrationCode.XeroManualJournal &&
                request.details.status;

            const netSuiteStatus =
                (request.integrationCode === domain.IntegrationCode.NetSuiteBill ||
                    request.integrationCode === domain.IntegrationCode.NetSuitePO) &&
                request.details.status;

            const isPaid =
                xeroStatus === domain.XeroObjectStatus.Paid || netSuiteStatus === domain.NetSuiteObjectStatus.Paid;

            const isBilled = request.integrationCode === domain.IntegrationCode.XeroPo && request.details.isBilled;

            if (isPaid) {
                return TemplateRequestListFilter.Paid;
            } else if (isBilled) {
                return TemplateRequestListFilter.Billed;
            }

            return TemplateRequestListFilter.Approved;
        }

        default:
            throw errorHelpers.assertNever(request.statusV2);
    }
}

export function matchesListFilter(
    state: State,
    request: domain.Request,
    filter: RequestListFilter | CompanyRequestListFilter | TemplateRequestListFilter | null,
    companyId: string | null
) {
    const searchParams = getSearchParams(state);

    if (searchParams) {
        const requestResolutionMismatch =
            searchParams.requestStatusV2 && request.statusV2 !== searchParams.requestStatusV2;

        return !requestResolutionMismatch;
    }

    switch (filter) {
        case CompanyRequestListFilter.CompanyAll:
        case RequestListFilter.MyOnlyOpen:
            return true;

        case RequestListFilter.MyReadyToPay:
            return (
                request.statusV2 === domain.RequestStatusV2.Approved &&
                ((request.integrationCode === domain.IntegrationCode.XeroAirwallexBatchPayment &&
                    request.details.paymentStatus === domain.AirwallexPaymentStatus.AwaitingPayment) ||
                    (request.integrationCode === domain.IntegrationCode.XeroAmaxPayBatchPayment &&
                        request.details.items.some(
                            (item) => item.paymentStatus === domain.AmaxPayPaymentStatus.ReadyToPay
                        )))
            );

        case TemplateRequestListFilter.OnReview:
            return request.statusV2 === domain.RequestStatusV2.OnReview;

        case CompanyRequestListFilter.CompanyAllOpen:
        case TemplateRequestListFilter.OnApproval:
            return request.statusV2 === domain.RequestStatusV2.OnApproval;

        case CompanyRequestListFilter.CompanyAllOnHold:
        case TemplateRequestListFilter.OnHold:
            return request.statusV2 === domain.RequestStatusV2.OnHold;

        case TemplateRequestListFilter.Approved:
            return request.statusV2 === domain.RequestStatusV2.Approved;

        case TemplateRequestListFilter.Rejected:
            return request.statusV2 === domain.RequestStatusV2.Rejected;

        case TemplateRequestListFilter.Cancelled:
            return request.statusV2 === domain.RequestStatusV2.Cancelled;

        case TemplateRequestListFilter.Paid: {
            const xeroStatus =
                request.integrationType === domain.IntegrationType.Xero &&
                request.integrationCode !== domain.IntegrationCode.XeroContact &&
                request.integrationCode !== domain.IntegrationCode.XeroManualJournal &&
                request.details.status;

            const isAirwallexPaid =
                request.integrationCode === domain.IntegrationCode.XeroAirwallexBatchPayment &&
                request.details.paymentStatus === domain.AirwallexPaymentStatus.Paid;

            const isAmaxPayPaid =
                request.integrationCode === domain.IntegrationCode.XeroAmaxPayBatchPayment &&
                request.details.status === domain.AmaxPayXeroBatchPaymentStatus.Paid;

            const isNetSuiteBillPaid =
                request.integrationCode === domain.IntegrationCode.NetSuiteBill &&
                request.details.status === domain.NetSuiteObjectStatus.Paid;

            const isNetSuiteExpenseReportPaid =
                request.integrationCode === domain.IntegrationCode.NetSuiteExpenseReport &&
                request.details.status === domain.NetSuiteExpenseReportStatus.PaidInFull;

            return (
                xeroStatus === domain.XeroObjectStatus.Paid ||
                isAirwallexPaid ||
                isAmaxPayPaid ||
                isNetSuiteBillPaid ||
                isNetSuiteExpenseReportPaid
            );
        }

        case TemplateRequestListFilter.Billed: {
            return (
                (request.integrationCode === domain.IntegrationCode.XeroPo && request.details.isBilled) ||
                (request.integrationCode === domain.IntegrationCode.DearPo &&
                    request.details.purchaseStatus === domain.DearPurchaseStatus.Invoiced) ||
                (request.integrationCode === domain.IntegrationCode.NetSuitePO &&
                    request.details.status === domain.NetSuiteObjectStatus.FullyBilled)
            );
        }

        case TemplateRequestListFilter.NotBilled: {
            return (
                request.integrationCode === domain.IntegrationCode.XeroPo &&
                request.statusV2 === domain.RequestStatusV2.Approved &&
                !request.details.isBilled
            );
        }

        case TemplateRequestListFilter.GrnNotReceived: {
            return (
                (request.details.integrationCode === domain.IntegrationCode.XeroPo ||
                    request.details.integrationCode === domain.IntegrationCode.QBooksPo) &&
                request.details.grnStatus === domain.GoodsReceivedNotesStatus.NotReceived
            );
        }

        case TemplateRequestListFilter.GrnPartiallyReceived: {
            return (
                (request.details.integrationCode === domain.IntegrationCode.XeroPo ||
                    request.details.integrationCode === domain.IntegrationCode.QBooksPo) &&
                request.details.grnStatus === domain.GoodsReceivedNotesStatus.PartiallyReceived
            );
        }

        case TemplateRequestListFilter.GrnFullyReceived: {
            return (
                (request.details.integrationCode === domain.IntegrationCode.XeroPo ||
                    request.details.integrationCode === domain.IntegrationCode.QBooksPo) &&
                request.details.grnStatus === domain.GoodsReceivedNotesStatus.FullyReceived
            );
        }

        case TemplateRequestListFilter.AwaitingPayment: {
            const isAirwallexPaid =
                request.integrationCode === domain.IntegrationCode.XeroAirwallexBatchPayment &&
                request.details.paymentStatus === domain.AirwallexPaymentStatus.AwaitingPayment;

            const isAmaxPayPaid =
                request.integrationCode === domain.IntegrationCode.XeroAmaxPayBatchPayment &&
                request.details.status === domain.AmaxPayXeroBatchPaymentStatus.AwaitingPayment;

            return isAirwallexPaid || isAmaxPayPaid;
        }

        case TemplateRequestListFilter.PartiallyPaid: {
            const isAirwallexPartiallyPaid =
                request.integrationCode === domain.IntegrationCode.XeroAirwallexBatchPayment &&
                request.details.paymentStatus === domain.AirwallexPaymentStatus.PartiallyPaid;

            const isAmaxPayPartiallyPaid =
                request.integrationCode === domain.IntegrationCode.XeroAmaxPayBatchPayment &&
                request.details.status === domain.AmaxPayXeroBatchPaymentStatus.PartiallyPaid;

            return isAirwallexPartiallyPaid || isAmaxPayPartiallyPaid;
        }

        case TemplateRequestListFilter.Failed: {
            const isAirwallexFailed =
                request.integrationCode === domain.IntegrationCode.XeroAirwallexBatchPayment &&
                request.details.paymentStatus === domain.AirwallexPaymentStatus.Failed;
            const isAmaxPayFailed =
                request.integrationCode === domain.IntegrationCode.XeroAmaxPayBatchPayment &&
                request.details.status === domain.AmaxPayXeroBatchPaymentStatus.Failed;

            return isAirwallexFailed || isAmaxPayFailed;
        }

        case TemplateRequestListFilter.Processing: {
            const isAirwallexProcessing =
                request.integrationCode === domain.IntegrationCode.XeroAirwallexBatchPayment &&
                request.details.paymentStatus === domain.AirwallexPaymentStatus.Processing;
            const isAmaxPayProcessing =
                request.integrationCode === domain.IntegrationCode.XeroAmaxPayBatchPayment &&
                request.details.status === domain.AmaxPayXeroBatchPaymentStatus.Processing;

            return isAirwallexProcessing || isAmaxPayProcessing;
        }

        case TemplateRequestListFilter.Accepted: {
            return (
                request.integrationCode === domain.IntegrationCode.XeroQuote &&
                request.details.quoteStatus === domain.XeroQuoteStatus.Accepted
            );
        }

        case TemplateRequestListFilter.Declined: {
            return (
                request.integrationCode === domain.IntegrationCode.XeroQuote &&
                request.details.quoteStatus === domain.XeroQuoteStatus.Declined
            );
        }

        case TemplateRequestListFilter.Credited: {
            return (
                request.integrationCode === domain.IntegrationCode.NetSuiteVRA &&
                request.details.status === domain.NetSuiteVRAStatus.Credited
            );
        }

        default: {
            const sameCompanyOrGlobalFilter = !companyId || companyId === request.companyId;
            const computedFilter = getComputedFilter(
                request,
                selectors.profile.getProfileUser(state).id,
                sameCompanyOrGlobalFilter ? filter : null
            );

            return filter === TemplateRequestListFilter.AllByType || computedFilter === filter;
        }
    }
}

export function getRequestReviewStepCommands(
    request: selectors.types.ExpandedRequest,
    step: domain.RequestReviewStep,
    me: Guid
) {
    const isManager = request.company.managers.includes(me);
    const hideButtonsForExternalApprovers =
        !isManager && request.company.betaFeatures.includes(domain.CompanyBetaFeature.HideButtonsExternalApproversV1);
    const hideReassignButtonForApprovers =
        !isManager && request.company.betaFeatures.includes(domain.CompanyBetaFeature.HideReassignForApprovers);

    const participantMe = step.reviewers.find((reviewer) => reviewer.id === me);

    const canEditReviewers =
        !step.isCompleted &&
        request.statusV2 === domain.RequestStatusV2.OnReview &&
        Boolean(participantMe || isManager) &&
        !hideButtonsForExternalApprovers &&
        request.resolutionOrigin !== domain.RequestResolutionOrigin.EnforcedByAdmin;

    const canReassignRequest =
        participantMe &&
        !step.isCompleted &&
        request.statusV2 === domain.RequestStatusV2.OnReview &&
        !(hideButtonsForExternalApprovers || hideReassignButtonForApprovers) &&
        request.resolutionOrigin !== domain.RequestResolutionOrigin.EnforcedByAdmin;

    const hideActionsForAdvancedFeatures = selectors.request.getHideActionsForAdvancedFeatures(
        request.company,
        request.integrationCode
    );

    return {
        editParticipants: {
            hidden: !canEditReviewers || hideActionsForAdvancedFeatures,
            disabled: false,
        },
        reassignRequest: {
            hidden: !canReassignRequest || hideActionsForAdvancedFeatures,
            disabled: false,
        },
    };
}

export function getRequestStepCommands(request: selectors.types.ExpandedRequest, step: domain.RequestStep, me: Guid) {
    const isManager = request.company.managers.includes(me);
    const hideButtonsForExternalApprovers =
        !isManager && request.company.betaFeatures.includes(domain.CompanyBetaFeature.HideButtonsExternalApproversV1);
    const hideReassignButtonForApprovers =
        !isManager && request.company.betaFeatures.includes(domain.CompanyBetaFeature.HideReassignForApprovers);

    const participantMe = step.participants.find((p) => p.userId === me);

    const managerAndStepEditDependence =
        step.type !== domain.TemplateStepType.AnyOfDecisionStep || request.company.flags.isManager;

    const canEditParticipants =
        step.state !== domain.RequestStepState.Done &&
        (request.statusV2 === domain.RequestStatusV2.OnApproval ||
            request.statusV2 === domain.RequestStatusV2.OnHold) &&
        Boolean(participantMe || isManager || request.flags.isAuthor) &&
        !hideButtonsForExternalApprovers &&
        managerAndStepEditDependence &&
        request.resolutionOrigin !== domain.RequestResolutionOrigin.EnforcedByAdmin;

    const canReassignRequest =
        participantMe &&
        participantMe.decision === domain.RequestStepParticipantDecision.NoResponse &&
        step.state === domain.RequestStepState.Active &&
        (request.statusV2 === domain.RequestStatusV2.OnApproval ||
            request.statusV2 === domain.RequestStatusV2.OnHold) &&
        !(hideButtonsForExternalApprovers || hideReassignButtonForApprovers) &&
        request.resolutionOrigin !== domain.RequestResolutionOrigin.EnforcedByAdmin;

    const hideActionsForAdvancedFeatures = selectors.request.getHideActionsForAdvancedFeatures(
        request.company,
        request.integrationCode
    );

    return {
        editParticipants: {
            hidden: !canEditParticipants || hideActionsForAdvancedFeatures,
            disabled: false,
        },
        reassignRequest: {
            hidden: !canReassignRequest || hideActionsForAdvancedFeatures,
            disabled: false,
        },
    };
}

export function isStepParticipantLocked(
    me: Guid,
    request: selectors.types.ExpandedRequest,
    participant: domain.RequestStepParticipant | domain.RequestReviewer
) {
    const manuallyAddedByMe =
        participant.source === domain.RequestStepParticipantOrigin.Manually && participant.addedBy === me;

    return !request.company.flags.isManager && !manuallyAddedByMe;
}

export function getAllRequestAttachments(
    state: State,
    request: domain.Request,
    options?: {
        xeroBatchPaymentInvoiceId?: Guid;
    }
): domain.RequestAttachment[] {
    const integrationType = domain.getIntegrationTypeByCode(request.integrationCode);

    switch (integrationType) {
        case domain.IntegrationType.Xero:
            if (
                request.integrationCode === domain.IntegrationCode.XeroPo ||
                request.integrationCode === domain.IntegrationCode.XeroQuote ||
                request.integrationCode === domain.IntegrationCode.XeroInvoice
            ) {
                const poDetails = request.details;
                const supplierAttachments = (poDetails.emailToSupplier && poDetails.emailToSupplier.attachments) || [];

                return request.attachments.concat(supplierAttachments);
            }

            if (
                request.integrationCode === domain.IntegrationCode.XeroBillBatchPayment ||
                request.integrationCode === domain.IntegrationCode.XeroAmaxPayBatchPayment ||
                request.integrationCode === domain.IntegrationCode.XeroAirwallexBatchPayment
            ) {
                const bpDetails = request.details;

                const currentItem = bpDetails.items.find(
                    (item) => item.xeroBillInvoiceRequestId === options?.xeroBatchPaymentInvoiceId
                );

                return currentItem?.attachments || [];
            }

            return request.attachments;

        case domain.IntegrationType.QBooks:
            if (request.integrationCode === domain.IntegrationCode.QBooksPo) {
                const poDetails = request.details;
                const vendorAttachments = (poDetails.emailToSupplier && poDetails.emailToSupplier.attachments) || [];

                return request.attachments.concat(vendorAttachments);
            }

            return request.attachments;

        case domain.IntegrationType.NetSuite: {
            if (request.integrationCode === domain.IntegrationCode.NetSuitePO) {
                const poDetails = request.details;
                const supplierAttachments = (poDetails.emailToSupplier && poDetails.emailToSupplier.attachments) || [];

                return request.attachments.concat(supplierAttachments);
            }

            return request.attachments;
        }

        case domain.IntegrationType.Dear:
            return request.attachments;

        case domain.IntegrationType.None:
            return request.attachments;

        default:
            throw errorHelpers.assertNever(integrationType);
    }
}

export function getNextAvailableRequest(requestList: Guid[], activeRequestId: Guid) {
    const list = [...requestList];
    const index = list.indexOf(activeRequestId);

    if (index === -1) {
        return list[0] || null;
    }

    list.splice(index, 1);

    return list[index] || list[list.length - 1] || null;
}

export function getShowRequestAmount(request: domain.Request): boolean {
    switch (request.integrationCode) {
        case domain.IntegrationCode.XeroContact:
        case domain.IntegrationCode.QBooksVendor:
        case domain.IntegrationCode.NetSuiteVendor:
            return false;

        default:
            return true;
    }
}

export const getIsManager = (userId: Guid, userEmail: string, company: selectors.types.ExpandedCompany) =>
    !!company.managers.find((managerId) => managerId === userId || managerId === userEmail);

export const getIsAuditor = (userId: Guid, userEmail: string, company: selectors.types.ExpandedCompany) =>
    !!company.auditors.find((auditorId) => auditorId === userId || auditorId === userEmail);

export const getIsAuthor = (userId: Guid, request: selectors.types.ExpandedRequest) =>
    request.author.databaseId === userId;

export const getIsWatcher = (userId: Guid, request: selectors.types.ExpandedRequest) =>
    !!request.watchers.find((watcherData) => watcherData.watcher.databaseId === userId);

export const getIsPayer = (userId: Guid, request: selectors.types.ExpandedRequest) =>
    !!request.payers.find((payer) => payer.databaseId === userId);

export const getIsParticipant = (userId: Guid, userEmail: string, request: selectors.types.ExpandedRequest) =>
    !!request.steps.find((step) =>
        step.participants.find((participant) => participant.userId === userId || participant.userId === userEmail)
    );

export const getIsReviewer = (userId: Guid, userEmail: string, request: selectors.types.ExpandedRequest) =>
    !!request.reviewStep?.reviewers.find((reviewer) => reviewer.id === userId || reviewer.userEmail === userEmail);

export const checkAccessToRequest = (
    userId: Guid,
    userEmail: string,
    company: selectors.types.ExpandedCompany,
    request: selectors.types.ExpandedRequest
) => {
    return (
        getIsManager(userId, userEmail, company) ||
        getIsAuditor(userId, userEmail, company) ||
        getIsParticipant(userId, userEmail, request) ||
        getIsAuthor(userId, request) ||
        getIsWatcher(userId, request) ||
        getIsPayer(userId, request) ||
        getIsReviewer(userId, userEmail, request)
    );
};

export const checkAccessOnlyAsWatcher = (
    userId: Guid,
    userEmail: string,
    company: selectors.types.ExpandedCompany,
    request: selectors.types.ExpandedRequest
) => {
    return (
        getIsWatcher(userId, request) &&
        !getIsManager(userId, userEmail, company) &&
        !getIsAuditor(userId, userEmail, company) &&
        !getIsParticipant(userId, userEmail, request) &&
        !getIsAuthor(userId, request) &&
        !getIsPayer(userId, request) &&
        !getIsReviewer(userId, userEmail, request)
    );
};

export const checkAccessAsActor = (
    userId: Guid,
    userEmail: string,
    company: selectors.types.ExpandedCompany,
    request: selectors.types.ExpandedRequest
) => {
    return (
        getIsManager(userId, userEmail, company) ||
        getIsParticipant(userId, userEmail, request) ||
        getIsAuthor(userId, request) ||
        getIsPayer(userId, request)
    );
};
