import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestForm.NetSuiteRequestForm', {
    mandatoryFieldsError: 'Please fill all mandatory fields.',
    taxAmountExceedError: 'Tax amount cannot exceed {maxValue}.',
    expenseLineTaxAmountExceedError:
        'Expense line tax amount cannot exceed {maxValue} or be less than -{maxValue} and cannot contain more than {maxDigitsCount} digits',
    expenseOrItemLineAmortizationDateError: 'Amortization End Date should be greater than Amortization Start Date',
    itemLineAmountExceedError: 'Item line amount cannot exceed {maxValue} or be less than -{maxValue}',
    itemLineTaxAmountExceedError:
        'Item line tax amount cannot exceed {maxValue} or be less than -{maxValue} and cannot contain more than {maxDigitsCount} digits',
    inputDefaultPlaceholder: 'Enter value',
    selectDefaulPlaceholder: 'Select value',
    emailError: 'Email address is invalid.',
    phoneError: 'Phone number is invalid.',
    hyperLinkError: 'Hyperlink is invalid.',
});
