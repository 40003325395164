import { z } from 'zod';

import { fieldNames } from './NetSuiteIntegrationPopup.config';
import { messages } from './NetSuiteIntegrationPopup.messages';
import { FormFieldId } from './NetSuiteIntegrationPopup.types';

const checkAlphaNumeric = (value: string) => !value.length || /^[a-zA-Z0-9]+$/.test(value);

const checkRequired = (value: string) => value.trim().length > 0;

export const schema = z
    .object({
        [FormFieldId.isSuiteApp]: z.boolean(),
        [FormFieldId.accountId]: z
            .custom<string>(
                (value) => !value.length || /^[A-Z0-9_]+$/.test(value),
                messages.accountIdWarning({ fieldName: fieldNames[FormFieldId.accountId] }),
                false
            )
            .refine(checkRequired, messages.requiredWarning({ fieldName: fieldNames[FormFieldId.accountId] })),
        [FormFieldId.consumerKey]: z.custom<string>(
            checkAlphaNumeric,
            messages.alphaNumericWarning({ fieldName: fieldNames[FormFieldId.consumerKey] }),
            false
        ),
        [FormFieldId.consumerSecret]: z.custom<string>(
            checkAlphaNumeric,
            messages.alphaNumericWarning({ fieldName: fieldNames[FormFieldId.consumerSecret] }),
            false
        ),
        [FormFieldId.tokenId]: z
            .custom<string>(
                checkAlphaNumeric,
                messages.alphaNumericWarning({ fieldName: fieldNames[FormFieldId.tokenId] }),
                false
            )
            .refine(checkRequired, messages.requiredWarning({ fieldName: fieldNames[FormFieldId.tokenId] })),
        [FormFieldId.tokenSecret]: z
            .custom<string>(
                checkAlphaNumeric,
                messages.alphaNumericWarning({ fieldName: fieldNames[FormFieldId.tokenSecret] }),
                false
            )
            .refine(checkRequired, messages.requiredWarning({ fieldName: fieldNames[FormFieldId.tokenSecret] })),
        [FormFieldId.subsidiaryId]: z
            .custom<string>(
                (value) => !value.length || /^[0-9]+$/.test(value),
                messages.subsidiaryIdWarning({ fieldName: fieldNames[FormFieldId.subsidiaryId] }),
                false
            )
            .refine(checkRequired, messages.requiredWarning({ fieldName: fieldNames[FormFieldId.subsidiaryId] })),
        [FormFieldId.timeZone]: z
            .string({ required_error: messages.requiredWarning({ fieldName: fieldNames[FormFieldId.timeZone] }) })
            .trim()
            .min(1, messages.requiredWarning({ fieldName: fieldNames[FormFieldId.timeZone] })),
    })
    .refine(({ isSuiteApp, consumerKey }) => (isSuiteApp ? true : checkRequired(consumerKey)), {
        message: messages.requiredWarning({ fieldName: fieldNames[FormFieldId.consumerKey] }),
        path: [FormFieldId.consumerKey],
    })
    .refine(({ isSuiteApp, consumerSecret }) => (isSuiteApp ? true : checkRequired(consumerSecret)), {
        message: messages.requiredWarning({ fieldName: fieldNames[FormFieldId.consumerSecret] }),
        path: [FormFieldId.consumerSecret],
    });
