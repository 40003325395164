import { mixins, Mods, mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

import { BaseItem, ColumnDefinition } from '../../Table.types';

export const Root = styled.tr<
    StyledMods<
        Mods<
            'clickable' | 'checked' | 'bordered' | 'invalid' | 'sticky' | 'divider' | 'rounded' | 'disabled' | 'hover'
        > & {
            transform?: string;
            transition?: string;
            isDragging?: boolean;
        }
    >
>`
    transform: ${({ $transform }) => $transform};
    transition: ${({ $transition }) => $transition};

    ${mods.clickable.true`
        cursor: pointer;
    `};

    ${mods.checked.true`
        background-color: ${theme.color.blue10};
    `};

    ${mods.invalid.true`
        background-color: ${theme.color.red40};
    `};

    ${mods.bordered.false`
        border-bottom: 1px solid ${theme.color.midnight50};
    `};

    ${mods.bordered.true`
        border: 1px solid ${theme.color.midnight50};
    `};

    ${mods.divider.false`
         border-bottom-color: transparent;
    `};

    ${mods.rounded.true`
        & td:nth-child(2)  {
            border-top-left-radius: ${theme.radius.xsmall};
            border-bottom-left-radius: ${theme.radius.xsmall};
        }
        & td:last-child {
            border-top-right-radius: ${theme.radius.xsmall};
            border-bottom-right-radius: ${theme.radius.xsmall};
        }
    `};

    ${mods('isDragging', true)`
        cursor: grab;
    `};

    // use td for sticky column

    ${mods.disabled.true`
        td {
            background-color: ${theme.color.midnight20};
        }
    `};

    ${mods.hover.true`
        &:hover td {
            background-color: ${theme.color.silver80};
        }
    `};

    ${mods.sticky.true`
        /* make the last row sticky to bottom */

        position: sticky;
        bottom: 0px;

        /*  add the top line by the box-shadow, because border doesn't work with sticky */
        box-shadow: 0 -2px 0 -1px ${theme.color.black100};

        background-color: ${theme.color.white100};
        border-bottom: none;
    `};
`;

export const StyledCell = styled.td<
    StyledMods<Mods<'hide'> & { spacing?: string } & Pick<ColumnDefinition<BaseItem>, 'textAlign'>>
>`
    padding: ${({ $spacing }) => $spacing};
    ${mods.textAlign(mixins.textAlign)};

    ${mods.hide.true`
        display: none;
    `};
`;

export const StyledCellDragHandler = styled.td<
    StyledMods<
        Mods<'hide'> &
            Pick<ColumnDefinition<BaseItem>, 'textAlign'> & {
                spacing?: string;
                isDragging?: boolean;
            }
    >
>`
    width: 29px;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-bottom: 1px solid transparent;
    padding: ${({ $spacing }) => $spacing};
    cursor: pointer;
    color: ${mods.textAlign(mixins.textAlign)};
    opacity: 0.001;

    ${Root}:hover & {
        background-color: transparent;
        opacity: 1;
    }

    ${mods('isDragging', true)`
        cursor: grab;
    `}

    ${mods.hide.true`
        display: none;
    `};
`;
