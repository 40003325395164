import { Box, Button, DatePicker, Form, Grid, Popup, Text } from '@approvalmax/ui/src/components';
import moment from 'moment';
import { memo, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { getMaxDate } from '../../PayWithAmax.helpers';
import { FormState } from '../PaymentSummaryPopupContent/PaymentSummaryPopupContent.types';
import { messages } from './SchedulePaymentPopup.messages';
import { SchedulePaymentPopupProps } from './SchedulePaymentPopup.types';

export const SchedulePaymentPopup = memo<SchedulePaymentPopupProps>((props) => {
    const { isLoading, onPay } = props;

    const form = useFormContext<FormState>();
    const scheduledDate = form.watch('scheduledDate');

    const dateLimits = useMemo(() => {
        const tomorrow = moment().add(1, 'days').toDate();
        const oneMonthLater = getMaxDate();

        return { tomorrow, oneMonthLater };
    }, []);

    return (
        <>
            <Form.Part>
                <Popup.Header
                    title={messages.title}
                    actions={
                        <Button
                            color='blue80'
                            title={messages.create}
                            onClick={onPay}
                            progress={isLoading}
                            disabled={!scheduledDate}
                        >
                            {messages.create}
                        </Button>
                    }
                />

                <Popup.Body spacing='32'>
                    <Grid gap={16}>
                        <Text font='headline'>{messages.selectPaymentDate}</Text>

                        <Box width={180}>
                            <DatePicker
                                minDate={dateLimits.tomorrow}
                                maxDate={dateLimits.oneMonthLater}
                                name='scheduledDate'
                                width='auto'
                            />
                        </Box>
                    </Grid>
                </Popup.Body>
            </Form.Part>
        </>
    );
});

SchedulePaymentPopup.displayName = 'SchedulePaymentPopup';
