import { isTotalLine, TotalLine } from 'pages/requestList/types';

import { AmaxPayPaymentItemWithId, FormState } from './PaymentSummaryPopupContent.types';

const isItemSelectedAndNotTotalItem = (
    item: TotalLine | AmaxPayPaymentItemWithId,
    selectedLineId: string
): item is AmaxPayPaymentItemWithId =>
    !isTotalLine(item) && `${item.contact?.id},${item.paymentDetails?.id}` === selectedLineId;

export const getPaymentData = (items: (TotalLine | AmaxPayPaymentItemWithId)[], data: FormState) => {
    const selectedLine = items.find((item) => item.id === data.selectedLineId);

    if (!data.selectedLineId || !selectedLine) {
        return;
    }

    const billIdList: string[] =
        selectedLine && isTotalLine(selectedLine)
            ? items.filter((item) => isItemSelectedAndNotTotalItem(item, selectedLine.id)).map((item) => item.id)
            : [data.selectedLineId];

    const item = isTotalLine(selectedLine)
        ? items.find((item) => isItemSelectedAndNotTotalItem(item, selectedLine.id))
        : selectedLine;

    const payeeName = item?.contact?.text ?? '';
    const paymentDetailsId = item?.paymentDetails?.id;
    const billInvoiceRequestIds = isTotalLine(selectedLine) ? billIdList : [data.selectedLineId];

    return { payeeName, paymentDetailsId, billInvoiceRequestIds };
};
