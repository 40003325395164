import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.XeroAmaxPayBatchPaymentRequestCard', {
    title: 'Payment run bills',
    billColumn: 'Bill',
    dueDateColumn: 'Due date',
    amountColumn: 'Amount',
    amountDueColumn: 'Amount due',
    paymentDetailsColumn: 'Payment details',
    refColumn: 'Ref.',
    paymentStatusColumn: 'Payment status',
    attachmentColumn: 'Attach',
    xeroLink: 'Xero link',
    openInXero: 'Open in Xero',
    total: 'Total',
    totalPaid: 'Total paid',
    remaining: 'Remaining',
    paymentDetailsChanged: 'Payment details have changed',
    billsTotalName: 'Bills from {contactName}',
});
