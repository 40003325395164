import { FC } from 'react';

import { Content, Header, Wrap } from './SectionContainer.styles';
import { SectionContainerProps } from './SectionContainer.types';

export const SectionContainer: FC<SectionContainerProps> = (props) => {
    const { className, children, title, noPadding, withHeaderPadding = false, qa } = props;

    return (
        <Wrap className={className} noPadding={noPadding} data-qa={qa}>
            {title && <Header $withPadding={withHeaderPadding}>{title}</Header>}

            <Content>{children}</Content>
        </Wrap>
    );
};

SectionContainer.displayName = 'SectionContainer';
