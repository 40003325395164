import { Cin7Logo, QBooksLogo } from '@approvalmax/ui';
import { Box, Button, Flex, Link, Popup, Text } from '@approvalmax/ui/src/components';
import { domain } from 'modules/data';
import { OracleNetsuiteLogo, XeroIcon } from 'modules/sprites';
import { memo, useCallback, useMemo } from 'react';
import bemFactory from 'react-bem-factory';

import { DetailsBlock } from '../DetailsBlock/DetailsBlock';
import { xeroProTipLink } from './Content.constants';
import { useSelectIntegrationType } from './Content.hooks';
import { messages } from './Content.messages';
import { ButtonBox } from './Content.styles';
import { ContentProps } from './Content.types';

const qa = bemFactory.qa('first-start-new-organisation-popup');

export const Content = memo<ContentProps>(({ onNextClick }) => {
    const { integrationType, onSelectXero, onSelectQbooks, onSelectNetSuite, onSelectDear, onSelectStandAlone } =
        useSelectIntegrationType();

    const organisationButtons = useMemo(
        () => [
            {
                id: 'xero',
                message: messages.xeroButton,
                logo: XeroIcon,
                onSelect: onSelectXero,
                type: domain.IntegrationType.Xero,
            },
            {
                id: 'qbo',
                message: messages.qboButton,
                logo: QBooksLogo,
                onSelect: onSelectQbooks,
                type: domain.IntegrationType.QBooks,
            },
            {
                id: 'netSuite',
                message: messages.netSuiteButton,
                logo: OracleNetsuiteLogo,
                onSelect: onSelectNetSuite,
                type: domain.IntegrationType.NetSuite,
            },
            {
                id: 'cin7',
                message: messages.dearButton,
                logo: Cin7Logo,
                onSelect: onSelectDear,
                type: domain.IntegrationType.Dear,
            },
            {
                id: 'connectLater',
                message: messages.connectLaterButton,
                logo: null,
                onSelect: onSelectStandAlone,
                type: domain.IntegrationType.None,
            },
        ],
        [onSelectDear, onSelectNetSuite, onSelectQbooks, onSelectStandAlone, onSelectXero]
    );

    const handleNext = useCallback(() => {
        onNextClick(integrationType);
    }, [integrationType, onNextClick]);

    return (
        <>
            <Popup.Header data-qa={qa()} title={messages.title} />

            <Popup.Body>
                <Flex direction='column' spacing='40'>
                    <Flex direction='column' spacing='32'>
                        <Text font='label' fontSize='large' fontWeight='medium' color='midnight100'>
                            {messages.contentTitle}
                        </Text>

                        <Flex container justifyContent='center'>
                            {organisationButtons.map(({ logo: Logo, ...organisation }) => (
                                <Flex size={4} grow={1} key={organisation.id}>
                                    <ButtonBox
                                        $active={organisation.type === integrationType}
                                        onClick={organisation.onSelect}
                                    >
                                        <Box spacing='20 16' height='100%'>
                                            <Flex
                                                direction='column'
                                                spacing='8'
                                                alignItems='center'
                                                justifyContent='center'
                                                height='100%'
                                            >
                                                <Text font='body' textAlign='center'>
                                                    {organisation.message}
                                                </Text>

                                                {Logo && <Logo size={32} />}
                                            </Flex>
                                        </Box>
                                    </ButtonBox>
                                </Flex>
                            ))}
                        </Flex>

                        {
                            {
                                [domain.IntegrationType.Xero]: (
                                    <DetailsBlock title={messages.xeroTitle} list={messages.xeroDetailsText} />
                                ),
                                [domain.IntegrationType.Dear]: (
                                    <DetailsBlock title={messages.dearTitle} list={messages.dearDetailsText} />
                                ),
                                [domain.IntegrationType.NetSuite]: (
                                    <DetailsBlock title={messages.netSuiteTitle} list={messages.netSuiteDetailsText} />
                                ),
                                [domain.IntegrationType.QBooks]: (
                                    <DetailsBlock title={messages.qboTitle} list={messages.qboDetailsText} />
                                ),
                                [domain.IntegrationType.None]: (
                                    <DetailsBlock
                                        title={messages.connectLaterTitle}
                                        list={messages.connectLaterDetailsText}
                                    />
                                ),
                            }[integrationType]
                        }
                    </Flex>

                    <Flex container justifyContent='end'>
                        <Flex size={5}>
                            <Button color='green80' size='large' block onClick={handleNext}>
                                {messages.nextButton}
                            </Button>
                        </Flex>
                    </Flex>

                    {integrationType === domain.IntegrationType.Xero && (
                        <Box spacing='16 24' radius='small' color='midnight20'>
                            <Flex container direction='column' spacing='8'>
                                <Text font='label' fontSize='medium' fontWeight='medium' color='midnight100'>
                                    {messages.xeroProTip}
                                </Text>

                                <Text font='body' color='midnight100'>
                                    {messages.xeroProTipText({
                                        learnmore: (text: string) => (
                                            <Link font='body' href={xeroProTipLink} external>
                                                {text}
                                            </Link>
                                        ),
                                    })}
                                </Text>
                            </Flex>
                        </Box>
                    )}
                </Flex>
            </Popup.Body>
        </>
    );
});

Content.displayName = 'Content';
