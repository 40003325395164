import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet, useGetLazy } from 'shared/data';

import { authApiPaths } from '../paths';
import { GetFido2AssertionOptions } from './useGetFido2AssertionOptions.types';

export const useGetFido2AssertionOptions = (
    params?: RequestParams<GetFido2AssertionOptions>,
    queryOptions?: RequestQueryOptions<GetFido2AssertionOptions>
) => {
    return useGet(authApiPaths.fido2AssertionOptions, {
        params,
        queryOptions,
        apiVersion: 'v2',
    });
};

export const useGetLazyFido2AssertionOptions = (queryOptions?: RequestQueryOptions<GetFido2AssertionOptions>) => {
    return useGetLazy(authApiPaths.fido2AssertionOptions, {
        queryOptions,
        apiVersion: 'v2',
    });
};
