import { Button, Checkbox, DateTimeEditor, NumberEditor, TextEditor } from '@approvalmax/ui';
import { intl } from '@approvalmax/utils';
import { domain } from 'modules/data';
import React, { FC, memo, useCallback, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';

import CollapsibleSection from './components/CollapsibleSection/CollapsibleSection';

const i18nPrefix =
    'requestList/containers/xero/XeroMatchingV2Section/components/BillView/components/ChangeMatchingPopup/components/ChangeMatchingPopupFilter/ChangeMatchingPopupFilter';

const messages = defineMessages({
    sectionTitle: {
        id: `${i18nPrefix}.sectionTitle`,
        defaultMessage: 'Filter',
    },
    enterTheAmount: {
        id: `${i18nPrefix}.enterTheAmount`,
        defaultMessage: 'Enter the amount',
    },
    enterTheDate: {
        id: `${i18nPrefix}.enterTheDate`,
        defaultMessage: 'Enter the date',
    },
    enterNumber: {
        id: `${i18nPrefix}.enterNumber`,
        defaultMessage: 'Enter the number',
    },
    clearFilterValues: {
        id: `${i18nPrefix}.clearFilterValues`,
        defaultMessage: 'Clear filter values',
    },
    applyFilter: {
        id: `${i18nPrefix}.applyFilter`,
        defaultMessage: 'Apply filter',
    },
});

const DefaultContent = styled.div`
    border-bottom: 1px solid #cacccd;
`;

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

interface RowProps {
    marginBottom?: number;
}

const Row = styled.div<RowProps>`
    display: flex;
    line-height: 30px;
    margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom + 'px' : '0')};
`;

interface StyledNumberEditorProps {
    withMargin?: boolean;
}

const StyledNumberEditor = styled(NumberEditor)<StyledNumberEditorProps>`
    width: 120px;
    height: 30px;
    margin: ${({ withMargin }) => (withMargin ? '0 10px' : 'auto')};
`;

const StyledCheckbox = styled(Checkbox)`
    margin: 0 8px;
`;

const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

const StyledTextEditor = styled(TextEditor)`
    width: 120px;
    height: 30px;
    margin: 0 8px;
`;

const StyledDateTimeEditor = styled(DateTimeEditor)`
    width: 120px;
    margin: 0 8px;
`;

interface FilterFieldTitleProps {
    marginLeft?: number;
}

const FilterFieldTitle = styled.div<FilterFieldTitleProps>`
    margin-bottom: 6px;
    margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft + 'px' : 0)};
    font-size: 12px;
    line-height: 1;
    color: #5e5c5c;
    font-weight: 600;
`;

const ClearButton = styled(Button)`
    width: 66px;
    margin-left: 150px;
    padding: 0 15px;
`;

const FilterButton = styled(Button)`
    width: 96px;
    margin-left: 15px;
    padding: 0 30px;
`;

interface ChangeMatchingPopupFilterProps {
    appliedPOFilter: domain.XeroMatchingV2POFilter;
    setAppliedPOFilter: React.Dispatch<React.SetStateAction<domain.XeroMatchingV2POFilter>>;
}

const ChangeMatchingPopupFilter: FC<ChangeMatchingPopupFilterProps> = (props) => {
    const { appliedPOFilter, setAppliedPOFilter } = props;

    const [POFilter, setPOFilter] = useState<domain.XeroMatchingV2POFilter>(appliedPOFilter);

    const sectionTitle = intl.formatMessage(messages.sectionTitle);

    const createSetFilterHandler = useCallback(
        (key: keyof domain.XeroMatchingV2POFilter) => (value: string | number | null) => {
            setPOFilter((prevState: domain.XeroMatchingV2POFilter) => ({
                ...prevState,
                [key]: value,
            }));
        },
        [setPOFilter]
    );

    const onlyMatchedCheckboxHandler = useCallback(() => {
        setPOFilter((prevState: domain.XeroMatchingV2POFilter) => ({
            ...prevState,
            onlyMatched: !prevState.onlyMatched,
        }));
    }, []);

    const clearFilter = useCallback(() => {
        setPOFilter({});
        setAppliedPOFilter({});
    }, [setAppliedPOFilter, setPOFilter]);

    const applyFilter = useCallback(() => setAppliedPOFilter({ ...POFilter }), [setAppliedPOFilter, POFilter]);

    const filterValues = Object.values(appliedPOFilter);

    let hasAppliedFilter =
        Boolean(filterValues.length) &&
        filterValues.some((filterValue) => filterValue !== undefined && filterValue !== null && filterValue !== false);

    return (
        <DefaultContent>
            <CollapsibleSection title={sectionTitle} hasAppliedFilter={hasAppliedFilter}>
                <Container>
                    <Row>
                        <FilterFieldTitle>
                            <FormattedMessage id={`${i18nPrefix}.amountGross`} defaultMessage='Amount (Gross)' />
                        </FilterFieldTitle>

                        <FilterFieldTitle marginLeft={250}>
                            <FormattedMessage id={`${i18nPrefix}.PONumber`} defaultMessage='PO number' />
                        </FilterFieldTitle>
                    </Row>

                    <Row marginBottom={20}>
                        <div>
                            <FormattedMessage id={`${i18nPrefix}.amountFromText`} defaultMessage='From' />
                        </div>

                        <StyledNumberEditor
                            value={POFilter.amountGreaterEquals || null}
                            min={1}
                            max={POFilter.amountLessEquals || 99999999999}
                            placeholder={intl.formatMessage(messages.enterTheAmount)}
                            onChange={createSetFilterHandler('amountGreaterEquals')}
                            withMargin
                        />

                        <div>
                            <FormattedMessage id={`${i18nPrefix}.amountToText`} defaultMessage='To' />
                        </div>

                        <StyledNumberEditor
                            value={POFilter.amountLessEquals || null}
                            min={POFilter.amountGreaterEquals || 1}
                            placeholder={intl.formatMessage(messages.enterTheAmount)}
                            onChange={createSetFilterHandler('amountLessEquals')}
                            withMargin
                        />

                        <StyledTextEditor
                            value={POFilter.number || ''}
                            onChange={createSetFilterHandler('number')}
                            placeholder={intl.formatMessage(messages.enterNumber)}
                        />

                        <CheckboxContainer onClick={onlyMatchedCheckboxHandler}>
                            <StyledCheckbox checked={POFilter.onlyMatched} />

                            <FormattedMessage
                                id={`${i18nPrefix}.showOnlyMatched`}
                                defaultMessage='Show only Matched POs'
                            />
                        </CheckboxContainer>
                    </Row>

                    <Row>
                        <FilterFieldTitle>
                            <FormattedMessage id={`${i18nPrefix}.dateRange`} defaultMessage='Date range' />
                        </FilterFieldTitle>
                    </Row>

                    <Row marginBottom={20}>
                        <div>
                            <FormattedMessage id={`${i18nPrefix}.dateFromText`} defaultMessage='From' />
                        </div>

                        <StyledDateTimeEditor
                            placeholder={intl.formatMessage(messages.enterTheDate)}
                            value={POFilter.dateGreaterEquals || null}
                            onChange={createSetFilterHandler('dateGreaterEquals')}
                        />

                        <div>
                            <FormattedMessage id={`${i18nPrefix}.dateToText`} defaultMessage='To' />
                        </div>

                        <StyledDateTimeEditor
                            placeholder={intl.formatMessage(messages.enterTheDate)}
                            value={POFilter.dateLessEquals || null}
                            onChange={createSetFilterHandler('dateLessEquals')}
                        />

                        <ClearButton
                            title={intl.formatMessage(messages.clearFilterValues)}
                            preset='small'
                            execute={clearFilter}
                            colorTheme='grey'
                        >
                            <FormattedMessage id={`${i18nPrefix}.clearButtonText`} defaultMessage='CLEAR' />
                        </ClearButton>

                        <FilterButton
                            title={intl.formatMessage(messages.applyFilter)}
                            preset='small'
                            execute={applyFilter}
                        >
                            <FormattedMessage id={`${i18nPrefix}.filterButtonText`} defaultMessage='FILTER' />
                        </FilterButton>
                    </Row>
                </Container>
            </CollapsibleSection>
        </DefaultContent>
    );
};

export default memo(ChangeMatchingPopupFilter);
