import { FC, memo } from 'react';
import bemFactory from 'react-bem-factory';

import FieldsSection from '../../../components/FieldsSection';
import { messages } from './XeroPurchaseOrderFields.messages';
import { XeroPurchaseOrderFieldsProps } from './XeroPurchaseOrderFields.types';

const qa = bemFactory.qa('reql-xero-fields-section');

const XeroPurchaseOrderFields: FC<XeroPurchaseOrderFieldsProps> = memo((props) => {
    const { request } = props;

    const details = request.details;

    const shouldAppendEmptyField = !details.deliveryDate || !details.reference || !details.brandingTheme;

    return (
        <FieldsSection>
            <FieldsSection.Field
                qa={qa('date')}
                title={messages.poDateFieldText}
                valueType={FieldsSection.Field.ValueType.Date}
                value={details.date}
            />

            {details.deliveryDate && (
                <FieldsSection.Field
                    qa={qa('deliveryDate')}
                    title={messages.poDeliveryDateFieldText}
                    valueType={FieldsSection.Field.ValueType.Date}
                    value={details.deliveryDate}
                />
            )}

            <FieldsSection.Field
                qa={qa('number')}
                title={messages.poNumberFieldText}
                value={details.number || messages.emptyPurchaseOrderNumber}
            />

            {details.reference && (
                <FieldsSection.Field
                    qa={qa('reference')}
                    title={messages.poReferenceFieldText}
                    value={details.reference}
                />
            )}

            {details.brandingTheme && (
                <FieldsSection.Field
                    qa={qa('brandingTheme')}
                    title={messages.poBrandingThemeFieldText}
                    value={details.brandingTheme}
                />
            )}

            {shouldAppendEmptyField && <FieldsSection.Field.Empty />}
        </FieldsSection>
    );
});

export default XeroPurchaseOrderFields;
