import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { authApiPaths } from '../paths';
import { UpdateFido2CredentialLabel } from './useUpdateFido2CredentialLabel.types';

export const useUpdateFido2CredentialLabel = (mutationOptions?: RequestMutationOptions<UpdateFido2CredentialLabel>) => {
    return useMutate(authApiPaths.fido2CredentialsLabel, {
        mutationOptions,
        method: 'patch',
        apiVersion: 'v2',
    });
};
