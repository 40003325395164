import styled from 'styled-components';

import imageSrc from './resources/xero-matching-empty-state.png';

const ImageXeroMatchingEmptyState = styled.div`
    background: url(${imageSrc}) no-repeat center;
    background-size: 100%;
    width: 322px;
    height: 240px;
`;

export default ImageXeroMatchingEmptyState;
