import { RequestMutationOptions } from '@approvalmax/data';

import { useMutate } from '../../../api';
import { slackApiPaths } from '../paths';
import { CompleteSlackInstall } from './useCompleteSlackInstall.types';

export const useCompleteSlackInstall = (mutationOptions?: RequestMutationOptions<CompleteSlackInstall>) => {
    return useMutate(slackApiPaths.authCompleteInstall, {
        mutationOptions,
        apiSource: 'slack',
        apiVersion: 'v1',
    });
};
