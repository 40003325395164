import { StyledSection, StyledTable } from './NetSuiteLineTable.styles';
import { NetSuiteLineTableProps } from './NetSuiteLineTable.types';

export const NetSuiteLineTable = <T,>(props: NetSuiteLineTableProps<T>) => {
    const { title, columnDefinitions, lineItems } = props;

    return (
        <StyledSection title={title}>
            <StyledTable columnDefinitions={columnDefinitions} lineItems={lineItems} />
        </StyledSection>
    );
};

NetSuiteLineTable.displayName = 'NetSuiteLineTable';
