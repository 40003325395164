import { enabledByParams, LazyGetDataParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { PascalCaseToCamelCase } from '@approvalmax/types';
import { useGet, useGetLazy } from 'shared/data';

import { integrationApiPaths } from '../paths';
import { GetLazyNetSuiteTaxCodes } from './useGetLazyNetSuiteTaxCodes.types';

export const useGetNetSuiteDictionariesTaxCodes = (
    params?: RequestParams<GetLazyNetSuiteTaxCodes>,
    queryOptions?: RequestQueryOptions<GetLazyNetSuiteTaxCodes>
) => {
    return useGet(integrationApiPaths.getNetSuiteDictionariesTaxCodes, {
        params,
        queryOptions: {
            enabled: enabledByParams<GetLazyNetSuiteTaxCodes['params']['query']>(
                {
                    companyId: params?.query?.companyId,
                },
                queryOptions
            ),
            ...queryOptions,
        },
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};

export const useLazyGetNetSuiteDictionariesTaxCodes = (queryOptions?: RequestQueryOptions<GetLazyNetSuiteTaxCodes>) => {
    return useGetLazy<
        LazyGetDataParams<{}, GetLazyNetSuiteTaxCodes['params']['query']>,
        PascalCaseToCamelCase<GetLazyNetSuiteTaxCodes['response']>
    >(integrationApiPaths.getNetSuiteDictionariesTaxCodes, {
        queryOptions,
        method: 'get',
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};
