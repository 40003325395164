import { Box, DatePicker, Radio, Spacing, Text } from '@approvalmax/ui/src/components';
import { hooks } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { FC, memo, useCallback, useEffect } from 'react';

import { SettingsSection } from '../SettingsSection/SettingsSection';
import SettingsSubsection from '../SettingsSubsection/SettingsSubsection';
import { messages } from './SectionQboFraudBypassing.messages';
import { ApprovalDisregardOption, SectionQboFraudBypassingProps } from './SectionQboFraudBypassing.types';

const SectionQboFraudBypassing: FC<SectionQboFraudBypassingProps> = memo((props) => {
    const {
        template,
        settings,
        savedTemplateSettings,
        effectiveDateLimits,
        checkValidFraudBypassing,
        onChangeApprovalDisregardEffectiveDate,
        onChangeApprovalDisregardEnabled,
        readonly,
        qa,
    } = props;

    const prevSettings = hooks.usePrevious(settings);

    // set firstDateOfMonth if no date on ComponentDidMount
    useEffect(() => {
        if (!prevSettings && settings && !settings.effectiveDate) {
            const date = new Date();

            date.setDate(1);

            onChangeApprovalDisregardEffectiveDate(date);
        }
    }, [onChangeApprovalDisregardEffectiveDate, prevSettings, settings]);

    const handleChangeApprovalDisregardEnabled = useCallback(
        (selectedValue: ApprovalDisregardOption) => {
            switch (selectedValue) {
                case ApprovalDisregardOption.Pull:
                    onChangeApprovalDisregardEnabled(true);
                    break;

                case ApprovalDisregardOption.DoNotPull:
                    onChangeApprovalDisregardEnabled(false);
                    break;
            }
        },
        [onChangeApprovalDisregardEnabled]
    );

    if (!settings) {
        return null;
    }

    const dateTimeDisabled =
        !settings.enabled ||
        Boolean(
            savedTemplateSettings.qBooksApprovalDisregardDetectionSettings &&
                savedTemplateSettings.qBooksApprovalDisregardDetectionSettings.effectiveDate
        );

    return (
        <SettingsSection
            title={messages.title}
            learnMoreLink='https://support.approvalmax.com/portal/en/kb/articles/what-is-the-pulling-from-connected-organisation-feature'
        >
            <SettingsSubsection subtitle={messages.subtitle}>
                <Radio.Group
                    disabled={readonly}
                    value={settings.enabled ? ApprovalDisregardOption.Pull : ApprovalDisregardOption.DoNotPull}
                    onChange={handleChangeApprovalDisregardEnabled}
                    name='approvalDisregardEnabled'
                    block
                    data-qa={qa('QBooksFraudDetectionBypassing-enabled')}
                >
                    <Radio value={ApprovalDisregardOption.DoNotPull}>{messages.optionDoNotPull}</Radio>

                    <Radio value={ApprovalDisregardOption.Pull}>{messages.optionPull}</Radio>
                </Radio.Group>

                <Box spacing='0 0 0 28'>
                    <Text font='body' color='midnight70' fontSize='medium'>
                        {messages.dateDescription({
                            templateName: template.displayName,
                        })}
                    </Text>

                    <Spacing height={12} />

                    <Box width={150}>
                        <DatePicker
                            value={settings.effectiveDate ? new Date(settings.effectiveDate) : undefined}
                            invalid={!checkValidFraudBypassing(domain.IntegrationType.QBooks)}
                            minDate={effectiveDateLimits?.minDate ? new Date(effectiveDateLimits.minDate) : undefined}
                            maxDate={effectiveDateLimits?.maxDate ? new Date(effectiveDateLimits.maxDate) : undefined}
                            onChange={onChangeApprovalDisregardEffectiveDate}
                            disabled={dateTimeDisabled || readonly}
                            placeholder={messages.datePlaceholder}
                            data-qa={qa('QBooksFraudDetectionBypassing-effective-date')}
                        />
                    </Box>
                </Box>
            </SettingsSubsection>
        </SettingsSection>
    );
});

export default SectionQboFraudBypassing;
