import { QueryOptions } from '@approvalmax/data';
import { useGetLegacy } from 'shared/data';

import { twoFaApiPaths } from '../paths';
import { UseGetTFAEnforcementInformationResponse } from './useGetTFAEnforcementInformation.types';

export const useGetTFAEnforcementInformation = (
    queryOptions?: QueryOptions<UseGetTFAEnforcementInformationResponse>
) => {
    return useGetLegacy<UseGetTFAEnforcementInformationResponse>(twoFaApiPaths.getTFAEnforcementInformation, {
        method: 'get',
        queryOptions,
    });
};
