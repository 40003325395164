import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { companiesApiPaths } from '../paths';
import { GetCompanies } from './useGetCompanies.types';

export const useGetCompanies = (
    params: RequestParams<GetCompanies>,
    queryOptions?: RequestQueryOptions<GetCompanies<true>>
) => {
    return useGet(companiesApiPaths.select, {
        params,
        queryOptions,
        mapToCamelCase: true,
    });
};

export const useGetCompaniesRaw = (
    params: RequestParams<GetCompanies>,
    queryOptions?: RequestQueryOptions<GetCompanies>
) => {
    return useGet(companiesApiPaths.select, {
        params,
        queryOptions,
    });
};
