import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { profileApiPaths } from '../paths';
import { SendResetPasswordTFAEmailCode } from './useSendResetPasswordTFAEmailCode.types';

export const useSendResetPasswordTFAEmailCode = (
    mutationOptions?: RequestMutationOptions<SendResetPasswordTFAEmailCode>
) => {
    return useMutate(profileApiPaths.sendResetPasswordTFAEmailCode, {
        mutationOptions,
        apiVersion: 'v1',
    });
};
