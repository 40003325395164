import { TextButton } from '@approvalmax/ui';
import { DropdownTriangleIcon } from 'modules/sprites';
import styled from 'styled-components';

export const CloneButton = styled(TextButton)`
    display: flex;
    align-items: center;
`;

export const TringleIcon = styled(DropdownTriangleIcon)`
    margin: 6px;
    transform: rotate(180deg);
    fill: #4c8b5d;
`;
