import { hooks } from '@approvalmax/utils';
import { backend } from 'modules/data';
import { useCallback, useState } from 'react';
import { useGetContacts } from 'shared/data/v1';

import { UseContactsParams } from './SuppliersSelect.types';

export const useContacts = (params: UseContactsParams) => {
    const { requestId, companyId } = params;

    const [enabled, setEnabled] = useState(false);
    const [query, setQuery] = useState('');
    const queryDebounced = hooks.useDebounce(query, 300);

    const { data, isFetching } = useGetContacts(
        {
            query: {
                requestId,
                companyId,
                ruleType: backend.EditRoleForRequest.Submitter,
                query: queryDebounced,
                startFrom: 0,
                rowNum: 116, // famous mysterious 116
            },
        },
        {
            enabled,
        }
    );

    const trigger = useCallback(() => {
        setEnabled(true);
    }, []);

    return {
        contacts: data?.contacts ?? [],
        isFetching,
        trigger,
        setQuery,
    };
};
