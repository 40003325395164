import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { matchingApiPaths } from '../paths';
import type { DeleteMatching } from './useDeleteMatching.types';

export const useDeleteMatching = (mutationOptions?: RequestMutationOptions<DeleteMatching>) =>
    useMutate(matchingApiPaths.deleteMatching, {
        mutationOptions,
        apiVersion: 'v1',
        method: 'post',
    });
