import { du } from 'modules/data';
import { AsyncDataProvider } from 'modules/data-providers';
import { useSelector } from 'modules/react-redux';
import { memo, useCallback } from 'react';
import { api } from 'services/api';

import { getRequest } from '../../../../selectors/pageSelectors';
import { NetSuiteCustomReferenceDataProviderProps } from './NetSuiteCustomReferenceDataProvider.types';

const NetSuiteCustomReferenceDataProvider = memo<NetSuiteCustomReferenceDataProviderProps>((props) => {
    const { fieldId, sublistType, children } = props;

    const request = useSelector(getRequest);

    const requestId = request.id;

    const companyId = request.companyId;

    const onLoad = useCallback(
        async (filterText: string | null, startFrom: number, count: number) => {
            const response = await api.integration.getNetSuiteCustomRecords({
                requestId,
                fieldId,
                query: filterText || '',
                startFrom,
                rowNum: count,
                companyId,
                sublistType,
            });

            return response.Values.map(du.parseServerReference);
        },
        [companyId, fieldId, requestId, sublistType]
    );

    return (
        <AsyncDataProvider
            cacheStorageId={`requestOptions.netSuiteCustomRecords.${requestId}.${fieldId}`}
            pageSize={100}
            onLoad={onLoad}
        >
            {children}
        </AsyncDataProvider>
    );
});

export default NetSuiteCustomReferenceDataProvider;
