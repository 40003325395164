import { browserHelpers, dateTimeHelpers } from '@approvalmax/utils';
import { FocusEvent, KeyboardEventHandler, MouseEventHandler, useCallback } from 'react';
import type { CalendarProps } from 'react-calendar';
import { useToggle } from 'react-use';

import { textFieldClearButtonName } from '../TextField/TextField.constants';
import type { HTMLTextFieldElement } from '../TextField/TextField.types';
import {
    formatTextFieldValue,
    formatTextFieldValueToHumanReadable,
    normalizeValue,
    parseEnteredTextFieldValue,
} from './DatePicker.helpers';
import type { DatePickerProps } from './DatePicker.types';

const isSafari = browserHelpers.isSafari();

export const useTextFieldValue = (
    props: Pick<ReturnType<typeof useDatePickerValue>, 'changeDatePickerValue' | 'datePickerValue'> &
        Required<Pick<DatePickerProps, 'view'>> & { isHumanReadable: boolean }
) => {
    const { datePickerValue, changeDatePickerValue, view, isHumanReadable } = props;

    const textFieldValue = isHumanReadable
        ? formatTextFieldValueToHumanReadable(datePickerValue)
        : formatTextFieldValue(datePickerValue, view);

    const handleTextFieldChange = useCallback(
        (enteredValue: string) => {
            const parsedTextFieldValue = parseEnteredTextFieldValue(enteredValue, view);
            const parsedDate = parsedTextFieldValue ? new Date(parsedTextFieldValue) : undefined;

            changeDatePickerValue(parsedDate);
        },
        [changeDatePickerValue, view]
    );

    return {
        textFieldValue,
        handleTextFieldChange,
    };
};

export const useDatePickerValue = (props: Pick<DatePickerProps, 'value' | 'onChange'>) => {
    const { value, onChange } = props;

    const datePickerValue = dateTimeHelpers.convertLocalToUTC(value);

    const changeDatePickerValue = useCallback(
        (changeValue: Parameters<typeof normalizeValue>[0]) => {
            const value = normalizeValue(changeValue);

            onChange?.(dateTimeHelpers.convertUTCToLocal(value));
        },
        [onChange]
    );

    return {
        datePickerValue,
        changeDatePickerValue,
    };
};

export const useCallbackActions = (
    props: Pick<DatePickerProps, 'onOpen' | 'onFocus' | 'onBlur' | 'onClear' | 'initOpen' | 'initFocus'> &
        Pick<ReturnType<typeof useDatePickerValue>, 'changeDatePickerValue'>
) => {
    const { changeDatePickerValue, onOpen, onFocus, onBlur, onClear, initOpen, initFocus } = props;

    const [open, toggleOpen] = useToggle(Boolean(initOpen));
    const [focus, toggleFocus] = useToggle(Boolean(initFocus));

    const handleOpen = useCallback<Required<DatePickerProps>['onOpen']>(
        (open, event) => {
            // prevent open a Picker on clearing
            if ((event?.target as Element | null | undefined)?.closest(`button[name="${textFieldClearButtonName}"]`)) {
                return;
            }

            toggleOpen(open);
            onOpen?.(open);
        },
        [onOpen, toggleOpen]
    );

    const handleFocus = useCallback<Required<DatePickerProps>['onFocus']>(
        (event) => {
            if (event.relatedTarget?.getAttribute('name') === textFieldClearButtonName) {
                event.target?.blur();

                return;
            }

            toggleOpen(true);
            setTimeout(() => toggleFocus(true), 0);
            onFocus?.(event);
        },
        [onFocus, toggleFocus, toggleOpen]
    );

    const handleClick = useCallback<Required<DatePickerProps>['onClick']>((event) => {
        // this prevents opening native Safari date picker
        if (isSafari) {
            event.preventDefault();
        }
    }, []);

    const handleKeyDown = useCallback<KeyboardEventHandler<HTMLTextFieldElement>>(
        (event) => {
            if (event.key === ' ') {
                // prevent open browser's calendar
                event.preventDefault();

                if (!open) {
                    handleOpen(true);
                }
            }
        },
        [handleOpen, open]
    );

    const handleIconClick = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
        toggleOpen(true);
        setTimeout(() => toggleFocus(true), 0);
        // change to FocusEvent<any> because can be called by html element as well as by SVG icon
        onFocus?.(event as unknown as FocusEvent<HTMLTextFieldElement>);
    }, [onFocus, toggleFocus, toggleOpen]);

    const handleBlur = useCallback<Required<DatePickerProps>['onFocus']>(
        (event) => {
            toggleFocus(false);
            onBlur?.(event);
        },
        [onBlur, toggleFocus]
    );

    const handleClear = useCallback<Required<DatePickerProps>['onClear']>(
        (event) => {
            changeDatePickerValue(undefined);
            onClear?.(event);
        },
        [changeDatePickerValue, onClear]
    );

    const handleChange: NonNullable<CalendarProps['onChange']> = (value) => {
        changeDatePickerValue(value);
        handleOpen(false);
    };

    return {
        open,
        focus,
        handleOpen,
        handleFocus,
        handleClick,
        handleChange,
        handleKeyDown,
        handleIconClick,
        handleBlur,
        handleClear,
    };
};
