import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { profileApiPaths } from '../paths';
import { StartResetPassword } from './useStartResetPassword.types';

export const useStartResetPassword = (mutationOptions?: RequestMutationOptions<StartResetPassword>) => {
    return useMutate(profileApiPaths.startResetPassword, {
        mutationOptions,
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};
