import { Button, Flex, Link, Text } from '@approvalmax/ui/src/components';
import { intl } from '@approvalmax/utils';
import { PaymentDetailsCell } from 'modules/components';
import AttachmentsDropdown from 'modules/components/Attachment/AttachmentsDropdown';
import { domain } from 'modules/data';
import moment from 'moment';
import { ColumnDefinition } from 'pages/requestList/selectors/types/ColumnDefinition';
import { isTotalLine, TotalLine } from 'pages/requestList/types';

import { MenuCell } from './components/MenuCell/MenuCell';
import { PaymentStatusCell } from './components/PaymentStatusCell/PaymentStatusCell';
import { columnWidth } from './XeroAmaxPayBatchPaymentRequestCard.constants';
import { messages } from './XeroAmaxPayBatchPaymentRequestCard.messages';
import { AmountDueWarning, StyledArrowReplayIcon } from './XeroAmaxPayBatchPaymentRequestCard.styles';
import { ColumnOptions } from './XeroAmaxPayBatchPaymentRequestCard.types';

export const getColumnDefinitions = ({
    companyId,
    requestId,
    bankAccountData,
    isRequestApproved,
    isPayer,
    onSelectAttachment,
    activeAttachmentId,
    onSelectBill,
    isMergedBySupplier,
}: ColumnOptions): ColumnDefinition<domain.AmaxPayPaymentItem | TotalLine>[] => [
    {
        id: 'bill',
        name: messages.billColumn,
        minWidth: columnWidth.bill,
        value: (li) => li.friendlyName,
        cell: (_val, li) =>
            isTotalLine(li) ? (
                <Text font='label' fontWeight='medium'>
                    {li.friendlyName}
                </Text>
            ) : (
                <Flex spacing='8' wrap={false} alignItems='center'>
                    {isMergedBySupplier && <StyledArrowReplayIcon width={12} height={12} color='midnight60' />}

                    <Button
                        color='transparent'
                        uppercase={false}
                        size='small'
                        onClick={() => onSelectBill(li.xeroBillInvoiceRequestId)}
                    >
                        <Flex spacing='8'>{li.friendlyName}</Flex>
                    </Button>
                </Flex>
            ),
    },
    {
        id: 'dueDate',
        name: messages.dueDateColumn,
        width: columnWidth.dueDate,
        minWidth: columnWidth.dueDate,
        value: (li) => (isTotalLine(li) ? '' : moment.utc(li.dueDate).format('ll')),
        cell: (value, li) => (
            <Text font='label' color={isMergedBySupplier && !isTotalLine(li) ? 'midnight70' : 'black100'}>
                {value}
            </Text>
        ),
    },
    {
        id: 'amountDue',
        name: messages.amountDueColumn,
        width: columnWidth.amountDue,
        minWidth: columnWidth.amountDue,
        value: (li) => intl.formatCurrency(li.amountDue, li.currency),
        cell: (value, li) => {
            if (isTotalLine(li)) {
                return (
                    <Text font='label' fontWeight='medium'>
                        {value}
                    </Text>
                );
            }

            if (li.paymentStatus !== domain.AmaxPayPaymentStatus.Paid && li.amountDue < li.amount) {
                return <AmountDueWarning>{value}</AmountDueWarning>;
            }

            return (
                <Text font='label' color={isMergedBySupplier && !isTotalLine(li) ? 'midnight70' : 'black100'}>
                    {value}
                </Text>
            );
        },
    },
    {
        id: 'amount',
        name: messages.amountColumn,
        width: columnWidth.amount,
        minWidth: columnWidth.amount,
        value: (li) => intl.formatCurrency(li.amount, li.currency),
        cell: (value, li) => (
            <Text
                font='label'
                fontWeight={isTotalLine(li) ? 'medium' : 'regular'}
                color={isMergedBySupplier && !isTotalLine(li) ? 'midnight70' : 'black100'}
            >
                {value}
            </Text>
        ),
    },
    {
        id: 'paymentDetails',
        name: messages.paymentDetailsColumn,
        hideTitle: true,
        width: columnWidth.paymentDetails,
        minWidth: columnWidth.paymentDetails,
        value: (li) => (isTotalLine(li) ? '' : li.paymentDetails?.id ?? null),
        cell: (_val, li) => {
            if (isTotalLine(li)) {
                return (
                    <PaymentDetailsCell
                        total
                        status={li.paymentDetailsPaymentStatus ?? domain.PaymentDetailsPaymentStatus.NotChecked}
                        bankName={li.bankAccountName}
                        companyId={companyId}
                        requestId={requestId}
                        billRequestId={li.oneOfBillRequestsId}
                    />
                );
            }

            if (!li.paymentDetails) return null;

            return isMergedBySupplier ? (
                <Text font='label' fontWeight='regular' color='midnight70'>
                    {li.paymentDetails.bankAccount.name}
                </Text>
            ) : (
                <PaymentDetailsCell
                    status={li.paymentDetailsPaymentStatus ?? domain.PaymentDetailsPaymentStatus.NotChecked}
                    bankName={li.paymentDetails.bankAccount.name}
                    companyId={companyId}
                    requestId={requestId}
                    billRequestId={li.xeroBillInvoiceRequestId}
                />
            );
        },
    },
    {
        id: 'ref',
        name: messages.refColumn,
        width: columnWidth.paymentReference,
        minWidth: columnWidth.paymentReference,
        value: (li) => li.paymentReference,
        cell: (value, li) => (
            <Text
                font='label'
                fontWeight={isTotalLine(li) ? 'medium' : 'regular'}
                color={isMergedBySupplier && !isTotalLine(li) ? 'midnight70' : 'black100'}
            >
                {value}
            </Text>
        ),
    },
    {
        id: 'paymentStatus',
        name: messages.paymentStatusColumn,
        hideTitle: true,
        width: columnWidth.paymentStatus,
        minWidth: columnWidth.paymentStatus,
        value: (li) => (isRequestApproved ? li.paymentStatus ?? null : null),
        cell: (value, li) => {
            const shouldRender = isMergedBySupplier ? isTotalLine(li) && value : value;

            return shouldRender ? (
                <PaymentStatusCell
                    paymentStatus={li.paymentStatus}
                    statusUpdatedAt={li.statusUpdatedAt}
                    scheduledDate={li.scheduledDate}
                />
            ) : null;
        },
    },
    {
        id: 'xeroLink',
        name: messages.xeroLink,
        hideTitle: true,
        width: columnWidth.xeroLink,
        minWidth: columnWidth.xeroLink,
        value: (li) => li.xeroUrl ?? null,
        cell: (value, li) => {
            if (isMergedBySupplier) {
                return (
                    isTotalLine(li) &&
                    value && (
                        <Link font='body' fontSize='small' external href={value}>
                            {messages.openInXero}
                        </Link>
                    )
                );
            }

            return (
                value && (
                    <Link font='body' fontSize='small' external href={value}>
                        {messages.openInXero}
                    </Link>
                )
            );
        },
    },
    {
        id: 'attachments',
        name: messages.attachmentColumn,
        width: columnWidth.attachments,
        minWidth: columnWidth.attachments,
        value: (li) =>
            !isTotalLine(li) && li.attachments && li.attachments?.length > 0 ? li.attachments.length.toString() : null,
        cell: (_val, li) =>
            !isTotalLine(li) && li.attachments && li.attachments.length > 0 ? (
                <AttachmentsDropdown
                    attachments={li.attachments}
                    activeAttachmentId={activeAttachmentId}
                    onSelectAttachment={(attachmentId: string) =>
                        onSelectAttachment(attachmentId, li.xeroBillInvoiceRequestId, li.friendlyName)
                    }
                />
            ) : null,
    },
    {
        id: 'menu',
        name: '',
        hideTitle: true,
        width: columnWidth.menu,
        minWidth: columnWidth.menu,
        value: () => (isRequestApproved ? 'menu' : null),
        cell: (value, li) =>
            value &&
            ((isMergedBySupplier && isTotalLine(li)) || !isMergedBySupplier) && (
                <MenuCell
                    companyId={companyId}
                    requestId={requestId}
                    billRequestIdList={isTotalLine(li) ? li.billRequestIdList : [li.xeroBillInvoiceRequestId]}
                    paymentStatus={li.paymentStatus ?? domain.AmaxPayPaymentStatus.ReadyToPay}
                    bankAccountData={bankAccountData}
                    amount={li.amount}
                    currency={li.currency}
                    isPayer={isPayer}
                />
            ),
    },
];
