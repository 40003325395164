import { Flex, Text } from '@approvalmax/ui/src/components';
import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';

import { PaymentDetailsFieldProps } from './PaymentDetailsField.types';

export const PaymentDetailsField = memo<PaymentDetailsFieldProps>((props) => {
    const { name, value, isOld, isNew } = props;

    const valueColor = isOld ? 'red100' : isNew ? 'green100' : 'midnight100';

    return (
        <Flex spacing='4' wrap={false}>
            <Text font='label' fontSize='small' color='midnight70' wrap={false}>
                {name}
            </Text>

            <Text
                font='label'
                fontSize='small'
                fontWeight={isOld || isNew ? 'bold' : 'regular'}
                color={valueColor}
                ellipsis={1}
                title={value}
            >
                {value ? value : <Skeleton width={40} />}
            </Text>
        </Flex>
    );
});

PaymentDetailsField.displayName = 'PaymentDetailsField';
