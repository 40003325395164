import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { matchingApiPaths } from '../paths';
import { GetQBooksMatchingContext } from './useGetQBooksMatchingContext.types';

/**
 * @deprecated  use {@link useGetLineItemMatchingSuggestions} or {@link useGetAccountLineItemMatchingSuggestions} instead
 */
export const useGetQBooksMatchingContext = (
    params?: RequestParams<GetQBooksMatchingContext>,
    queryOptions?: RequestQueryOptions<GetQBooksMatchingContext>
) => {
    return useGet(matchingApiPaths.getQBooksMatchingContext, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetQBooksMatchingContext['params']['query']>(
                {
                    companyId: params?.query?.companyId,
                    lineItemId: params?.query?.lineItemId,
                },
                queryOptions
            ),
        },
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};
