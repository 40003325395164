import { toast } from '@approvalmax/ui/src/components';
import { domain, State } from 'modules/data';
import { clearCacheStorage } from 'modules/data-providers';
import { contentOcrNotificationState, ocrNotificationPopupOpenState } from 'modules/request';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { GetOcr } from 'shared/data/v2';
import { ocrDocumentIsEmpty } from 'shared/helpers';
import { useCheckOcrStatus } from 'shared/hooks/useCheckOcrStatus';
import { ContentSplitViewMode, contentSplitViewState } from 'shared/states';

import { loadRequestContext } from '../../../../actions';
import { updateNetSuiteBillDataState } from '../../../../containers/netsuite/NetSuiteBillRequestForm/NetSuiteBillRequestForm.states';
import useEditRequestPageData from '../../../../hooks/useEditRequestPageData';
import { getRequestEditMode } from '../../../../selectors/requestSelectors';
import { messages } from './OcrUploadPanel.messages';
import { ocrAttachmentIdState } from './OcrUploadPanel.states';

export const useWrappedCheckOcrStatus = (request: domain.Request, attachmentId?: string) => {
    const updateNetSuiteBillData = useRecoilValue(updateNetSuiteBillDataState);
    const dispatch = useDispatch();
    const setOcrAttachmentId = useSetRecoilState(ocrAttachmentIdState);
    const setContentSplitView = useSetRecoilState(contentSplitViewState);
    const setOcrNotificationPopupOpen = useSetRecoilState(ocrNotificationPopupOpenState);
    const setContentOcrNotification = useSetRecoilState(contentOcrNotificationState);
    const editMode = useSelector((state: State) => getRequestEditMode(state, request));

    const editRequestPageSuccessCallback = useCallback(
        (data: Awaited<ReturnType<typeof loadRequestContext>>) => {
            if (updateNetSuiteBillData && data?.request.integrationCode === domain.IntegrationCode.NetSuiteBill) {
                updateNetSuiteBillData(data.request);
            }
        },
        [updateNetSuiteBillData]
    );
    const { refetch: refetchEditRequestPageData } = useEditRequestPageData(false, editRequestPageSuccessCallback);

    const successCallback = useCallback(
        async (data: GetOcr['response']) => {
            if (ocrDocumentIsEmpty(data.document)) {
                const ocrAttachmentFileName = request.attachments.find(
                    (attachment) => attachment.id === attachmentId
                )?.name;

                setContentOcrNotification({
                    buttonText: messages.ocrDocumentIsEmptyButtonText,
                    description: messages.ocrDocumentIsEmptyText({
                        fileName: ocrAttachmentFileName || '',
                    }),
                });
                setOcrNotificationPopupOpen(true);
            } else {
                await refetchEditRequestPageData();
                setOcrAttachmentId(null);
                toast.success(messages.successText);
            }

            // Clear request suppliers in order to show ocr suggested suppliers
            dispatch(clearCacheStorage((id) => id === `requestOptions.supplier.${request.id}.${editMode}`));
        },
        [
            attachmentId,
            dispatch,
            editMode,
            refetchEditRequestPageData,
            request.attachments,
            request.id,
            setContentOcrNotification,
            setOcrAttachmentId,
            setOcrNotificationPopupOpen,
        ]
    );

    const errorCallback = useCallback(
        (errorMessage?: string) => {
            setOcrAttachmentId(null);
            setContentSplitView({ mode: ContentSplitViewMode.Empty });
            setContentOcrNotification({
                buttonText: messages.errorButtonText,
                description: errorMessage || messages.errorText,
            });
            setOcrNotificationPopupOpen(true);
        },
        [setContentOcrNotification, setContentSplitView, setOcrAttachmentId, setOcrNotificationPopupOpen]
    );

    return useCheckOcrStatus(request, attachmentId, successCallback, errorCallback);
};
