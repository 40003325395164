import { MutateDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data/api';

import { requestsApiPaths } from '../paths';
import {
    UseAirwallexCancelScheduledPaymentData,
    UseAirwallexCancelScheduledPaymentResponse,
} from './useAirwallexCancelScheduledPayment.types';

export const useAirwallexCancelScheduledPayment = () => {
    return useMutateLegacy<
        MutateDataParams<UseAirwallexCancelScheduledPaymentData>,
        UseAirwallexCancelScheduledPaymentResponse
    >(requestsApiPaths.airwallexCancelScheduledPayment);
};
