import { getGlobalObject } from './getGlobalObject';
import { isAndroid } from './isAndroid';
import { isBrowser } from './isBrowser';
import { isFirefox } from './isFirefox';
import { isIphone } from './isIphone';
import { isMobile } from './isMobile';
import { isSafari } from './isSafari';
import { isTablet } from './isTablet';

export const browserHelpers = {
    getGlobalObject,
    isAndroid,
    isBrowser,
    isIphone,
    isFirefox,
    isMobile,
    isTablet,
    isSafari,
};
