import { getLockDateWarningText } from 'modules/common/selectors/requestSelectors.Xero';
import { DoneIcon } from 'modules/sprites';
import { memo } from 'react';

import FieldsSection from '../../../components/FieldsSection';
import { messages } from './XeroManualJournalFields.messages';
import {
    BooleanValueWrapper,
    NarrationField,
    Root,
    ShowOnCashBasisReportsField,
    StyledField,
} from './XeroManualJournalFields.styles';
import { XeroManualJournalFieldsProps } from './XeroManualJournalFields.types';

const XeroManualJournalFields = memo<XeroManualJournalFieldsProps>((props) => {
    const { request } = props;

    const details = request.details;

    const lockDateWarningText = getLockDateWarningText(request);

    return (
        <Root>
            <NarrationField title={messages.narration} value={details.narration} />

            <StyledField
                title={messages.date}
                valueType={FieldsSection.Field.ValueType.Date}
                value={details.date}
                warningText={lockDateWarningText}
            />

            {details.showOnCashBasisReports && (
                <ShowOnCashBasisReportsField
                    title={messages.showOnCashBasisReports}
                    forceDisplayValue={
                        <BooleanValueWrapper>
                            <DoneIcon width={13} height={10} />
                        </BooleanValueWrapper>
                    }
                />
            )}
        </Root>
    );
});

export default XeroManualJournalFields;
