import { TransparentButton } from '@approvalmax/ui';
import { Box, Flex, Form, Grid, Text, useConfirmation } from '@approvalmax/ui/src/components';
import moment from 'moment';
import { memo, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDeleteFido2Credential, useUpdateFido2CredentialLabel } from 'shared/data/v2';

import { PasskeyEdit } from '../PasskeyEdit/PasskeyEdit';
import { messages } from './PasskeyItem.messages';
import { DeleteBtnText, EditBtnText } from './PasskeyItem.styles';
import { PasskeyItemProps } from './PasskeyItem.types';

export const PasskeyItem = memo<PasskeyItemProps>((props) => {
    const { id, label, createdDate, onItemChange } = props;

    const [showEditForm, setShowEditForm] = useState(false);

    const form = useForm<{ label: string }>();

    const { mutate: deleteCredential, isLoading: isDeleteLoading } = useDeleteFido2Credential();

    const onEditLabelSuccess = useCallback(() => {
        setShowEditForm(false);
        onItemChange();
    }, [onItemChange]);

    const { mutate: updateCredential, isLoading: isEditLoading } = useUpdateFido2CredentialLabel({
        onSuccess: onEditLabelSuccess,
    });

    const { confirmation } = useConfirmation({
        confirmMessage: messages.areYouSure,
        confirmButtonMessage: messages.yes,
    });

    const handleEdit = useCallback(() => {
        setShowEditForm(true);

        form.setValue('label', label);
    }, [form, label]);

    const handleDelete = useCallback(() => {
        confirmation()
            .then(() => {
                deleteCredential({ body: { credentialId: id } });
            })
            .catch(() => {});
    }, [deleteCredential, id, confirmation]);

    const handleEditSubmit = form.handleSubmit((data) => {
        if (!showEditForm) {
            return;
        }

        const { label } = data;

        updateCredential({
            body: {
                credentialId: id,
                newLabel: label,
            },
        });
    });

    const handleEditCancel = useCallback(() => {
        setShowEditForm(false);
    }, []);

    const isLoading = isDeleteLoading || isEditLoading;

    return (
        <Form form={form} onSubmit={handleEditSubmit}>
            <Box color='midnight20' spacing='12 16'>
                {showEditForm ? (
                    <PasskeyEdit onCancel={handleEditCancel} isLoading={isEditLoading} />
                ) : (
                    <Flex alignItems='start' justifyContent='space-between'>
                        <Grid gap={4}>
                            <Text color='black100' font='label' fontSize='medium'>
                                {label}
                            </Text>

                            <Text color='midnight60' font='body' fontSize='small'>
                                {messages.created({ createdDate: moment(createdDate).format('lll') })}
                            </Text>
                        </Grid>

                        <Flex spacing='16'>
                            <TransparentButton execute={handleEdit} disabled={isLoading}>
                                <EditBtnText>Edit</EditBtnText>
                            </TransparentButton>

                            <TransparentButton execute={handleDelete} disabled={isLoading}>
                                <DeleteBtnText>Delete</DeleteBtnText>
                            </TransparentButton>
                        </Flex>
                    </Flex>
                )}
            </Box>
        </Form>
    );
});

PasskeyItem.displayName = 'PasskeyItem';
