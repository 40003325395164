import { Grid, Label, Text } from '@approvalmax/ui/src/components';
import { intl } from '@approvalmax/utils';
import { memo } from 'react';
import { BankLogoImage } from 'shared/components';

import { messages } from './BankAccountInfo.messages';
import { BankAccountInfoProps } from './BankAccountInfo.types';

export const BankAccountInfo = memo<BankAccountInfoProps>((props) => {
    const { data, balance } = props;

    return (
        <Grid gridAutoRows='max-content' gap={12}>
            <Grid gridTemplateColumns='auto max-content'>
                <div>
                    <Label color='midnight70'>{messages.bankName}</Label>

                    <Text font='label' fontSize='small' color='midnight90' ellipsis={1}>
                        {data.bank.name}
                    </Text>
                </div>

                <BankLogoImage width={26} height={26} src={data.bank.iconUrl} alt={data.bank.fullName} />
            </Grid>

            <div>
                <Label color='midnight70'>{messages.bankAccountName}</Label>

                <Text font='label' fontSize='small' color='midnight90' ellipsis={1}>
                    {data.nickname}
                </Text>
            </div>

            {typeof balance === 'number' && (
                <div>
                    <Label color='midnight70'>{messages.balance}</Label>

                    <Text font='label' fontSize='small' color='midnight90'>
                        {intl.formatCurrency(balance, data.currencyCode)}
                    </Text>
                </div>
            )}

            <div>
                <Label color='midnight70'>{messages.accountNumber}</Label>

                <Text font='label' fontSize='small' color='midnight90'>
                    {data.identification.bankAccountNumber}
                </Text>
            </div>

            {data.identification.sortCode && (
                <div>
                    <Label color='midnight70'>{messages.sortCode}</Label>

                    <Text font='label' fontSize='small' color='midnight90'>
                        {data.identification.sortCode}
                    </Text>
                </div>
            )}
        </Grid>
    );
});

BankAccountInfo.displayName = 'BankAccountInfo';
