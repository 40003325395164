import { zodResolver } from '@hookform/resolvers/zod';
import { actions } from 'modules/common';
import { State } from 'modules/data';
import { useCallback } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import { useRecoilState } from 'recoil';
import { useFinishTFAEnablingWithBackupCodes, useStartTFAEnabling, useVerifyTFAEnablingEmailCode } from 'shared/data';

import {
    BackupMethodOptions,
    fieldNameBackupMethod,
    fieldNameCodesAreSaved,
    fieldNameEmail,
    fieldNameEmailVerificationCode,
    fieldNameSetupCode,
    fieldNameSetupVerificationCode,
    fieldNameStateId,
    steps,
} from './TwoFaEnablingWizard.constants';
import { messages } from './TwoFaEnablingWizard.messages';
import { schema } from './TwoFaEnablingWizard.schema';
import { twoFaWizardControllerState, twoFaWizardStepState } from './TwoFaEnablingWizard.states';
import { TwoFaEnablingWizardProps, TwoFaWizardForm } from './TwoFaEnablingWizard.types';

export const useTwoFaSetupWizard = (props: TwoFaEnablingWizardProps) => {
    const { onFinish } = props;
    const { wizardStep } = useTwoFaWizardStep();
    const enableGlobalProfileTwoFaSetting = useGlobalProfileEnableTwoFaSetting();

    const form = useForm<TwoFaWizardForm>({
        defaultValues: {
            [fieldNameStateId]: null,
            [fieldNameSetupCode]: null,
            [fieldNameSetupVerificationCode]: '',
            [fieldNameBackupMethod]: null,
            [fieldNameCodesAreSaved]: false,
            [fieldNameEmail]: '',
            [fieldNameEmailVerificationCode]: '',
        },
        resolver: zodResolver(schema[wizardStep]),
    });

    const { mutate: startSetup } = useStartTFAEnabling({
        onSuccess: (response) => {
            form.setValue(fieldNameSetupCode, response.code);
            form.setValue(fieldNameStateId, response.stateId);
        },
    });

    const { mutate: enableWithBackupCodes } = useFinishTFAEnablingWithBackupCodes({
        onSuccess: () => {
            enableGlobalProfileTwoFaSetting();
            onFinish();
        },
    });

    const { mutate: enableWithEmail } = useVerifyTFAEnablingEmailCode({
        onSuccess: () => {
            enableGlobalProfileTwoFaSetting();
            onFinish();
        },
        onError: () => {
            form.setError(fieldNameEmailVerificationCode, { message: messages.errorEmailVerificationCodeRequired });
        },
    });

    const handleSubmit = form.handleSubmit((data) => {
        const stateId = form.watch(fieldNameStateId);
        const backupMethod = form.watch(fieldNameBackupMethod);

        if (!stateId || !backupMethod) {
            return;
        }

        switch (backupMethod) {
            case BackupMethodOptions.backupCodes:
                enableWithBackupCodes({
                    body: {
                        stateId,
                    },
                });
                break;

            case BackupMethodOptions.backupEmailAddress:
                enableWithEmail({
                    body: {
                        stateId,
                        emailCode: data[fieldNameEmailVerificationCode]?.trim() || '',
                    },
                });
                break;
        }
    });

    useMount(() => {
        startSetup({ body: {} });
    });

    return {
        form,
        formStep: steps[wizardStep],
        handleSubmit,
    };
};

/**
 * This is used for displaying My Profile toggler in a correct state right after popup opened.
 * Better to use Recoil or something the same to get rid of this code
 *
 * @see AM-14443
 */
export const useGlobalProfileEnableTwoFaSetting = () => {
    const dispatch = useDispatch();
    const profile = useSelector((state: State) => state.profile);

    return useCallback(() => {
        if (!profile?.email) {
            return;
        }

        dispatch(actions.updateProfile(profile.email, {}, { is2faEnabled: true }));
    }, [dispatch, profile?.email]);
};

export const useTwoFaWizardStep = () => {
    const [wizardStep, setWizardStep] = useRecoilState(twoFaWizardStepState);

    return {
        wizardStep,
        setWizardStep,
    };
};

export const useTwoFaWizardController = () => {
    const [wizardController, setWizardController] = useRecoilState(twoFaWizardControllerState);

    return {
        wizardController,
        setWizardController,
    };
};

export const useTwoFaWizardFormContext = () => {
    return useFormContext<TwoFaWizardForm>();
};
