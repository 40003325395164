import { Flex } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { CacheManagement } from 'modules/components';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { SubscribeAfterTrialButton } from '..';
import { SubscribeAfterTrialButtonType } from '../SubscribeAfterTrialButton/SubscribeAfterTrialButton.types';
import { Help, NewRequest, Search, Sync, User } from './components';
import { cacheManagerPaths } from './UserActionsToolbar.constants';
import { useShowSubscribeAfterTrialButton } from './UserActionsToolbar.hooks';
import { showRequestCreationState } from './UserActionsToolbar.states';

const UserActionsToolbar = memo(() => {
    const showRequestCreation = useRecoilValue(showRequestCreationState);

    const profile = useSelector(selectors.profile.findProfile);

    const { showSubscribeAfterTrialButton, companyId } = useShowSubscribeAfterTrialButton();

    if (!profile) return null;

    return (
        <Flex>
            {showRequestCreation &&
                (showSubscribeAfterTrialButton ? (
                    <SubscribeAfterTrialButton
                        type={SubscribeAfterTrialButtonType.NewRequest}
                        companyId={companyId}
                        profileId={profile.id}
                    />
                ) : (
                    <NewRequest />
                ))}

            <Search />

            <Sync />

            <Route path={cacheManagerPaths} exact>
                <CacheManagement />
            </Route>

            <Help />

            <User />
        </Flex>
    );
});

export default UserActionsToolbar;
