import { theme } from '@approvalmax/theme';
import { ArrowReplayIcon } from '@approvalmax/ui';
import styled from 'styled-components';

export const AmountDueWarning = styled.span`
    color: ${theme.color.red100};
`;

export const StyledArrowReplayIcon = styled(ArrowReplayIcon)`
    flex-shrink: 0;
`;
