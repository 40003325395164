import { TransparentButton } from '@approvalmax/ui';
import styled from 'styled-components';

export const PutOnHoldButton = styled(TransparentButton)`
    height: 36px;
    width: 36px;
    text-align: center;
    color: #384650;

    &:hover {
        background: #e0e2e3;
    }
`;

export const TakeOffHoldButton = styled(TransparentButton)`
    height: 36px;
    width: 36px;
    text-align: center;
    color: #fff;
    border: 1px solid #4c8a5c;
    background-color: #4c8a5c;

    &:hover {
        background-color: #3c7048;
    }
`;

export const PopupContent = styled.div`
    padding: 10px 60px 40px 80px;
    border-top: 1px solid #ebeaea;
`;
