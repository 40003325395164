import { objectHelpers } from '@approvalmax/utils';
import { backend, domain } from 'modules/data';

export function parseQBooksAddress(value: backend.IntegrationsAddress): domain.QBooksAddress {
    const addressText = [
        value.AddressLine1,
        value.AddressLine2,
        value.AddressLine3,
        value.AddressLine4,
        value.City,
        value.Region,
        value.PostalCode,
        value.Country,
    ]
        .filter((x) => x && x.trim())
        .map((x) => x.trim())
        .join('\n');
    const type =
        value.AddressType === backend.AddressType.Postal
            ? domain.QBooksAddressType.Postal
            : domain.QBooksAddressType.Physical;

    return {
        id: type + addressText,
        text: addressText,
        type,
        address: objectHelpers.pascalCaseToCamelCase(value),
        attentionTo: value.AttentionTo || '',
    };
}
