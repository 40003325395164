import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.AmountInfo', {
    netAmountText: 'Amount',
    subtotal: 'Subtotal',
    discountAmountText: 'Discount',
    taxAmountText: 'TAX',
    shippingAmountText: 'Shipping',
    handlingAmountText: 'Handling',
    debitTotalText: 'Debit total',
    creditTotalText: 'Credit total',
    totalAmountText: 'TOTAL:',
});
