import { theme } from '@approvalmax/theme';
import { TransparentButton } from '@approvalmax/ui';
import { ReportReadyIcon } from 'modules/sprites';
import { font } from 'modules/styles';
import styled from 'styled-components';

export const BodyRightPrimaryRow = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: end;
    gap: 8px;
`;

export const PrimaryButtons = styled.div`
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
        margin-right: 5px;
    }

    &:empty {
        display: none;
    }
`;

export const OpenAuditReportButton = styled(TransparentButton)`
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
`;

export const OpenAuditReportButtonIcon = styled(ReportReadyIcon)`
    margin-right: 10px;
`;

export const OpenAuditReportButtonText = styled.div`
    ${font(13, '#477753', 'semibold')}
`;

export const ChangePOStatusButtonContainer = styled.div`
    margin-right: 15px;
`;

export const ActionButton = styled(TransparentButton)`
    display: flex;
    align-items: center;
    height: 36px;
    padding: 0 8px;
    color: #4c8a5c;

    &:not(:disabled):hover {
        background: #e0e2e3;
    }

    &:disabled {
        cursor: default;
        color: ${theme.color.midnight60};
    }
`;

export const ActionButtonText = styled.span`
    margin-left: 4px;
    ${font(10, 'inherit', 'bold')}
`;

export const VersionHistoryButton = styled(ActionButton)`
    margin-right: 4px;
`;
