import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { budgetsApiPaths } from '../paths';
import { GetXeroBudgets } from './useGetXeroBudgets.types';

export const useGetXeroBudgets = (
    params?: RequestParams<GetXeroBudgets>,
    queryOptions?: RequestQueryOptions<GetXeroBudgets>
) => {
    return useGet(budgetsApiPaths.xeroBudgets, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetXeroBudgets['params']['query']>(
                {
                    companyId: params?.query?.companyId,
                },
                queryOptions
            ),
        },
        apiVersion: 'v1',
    });
};
