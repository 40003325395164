import { Guid, Reference } from '@approvalmax/types';
import { CamelCasedPropertiesDeep } from 'type-fest';

import { IntegrationsNetSuiteInvoiceListItem, NetSuiteSubsidiaryEditionType } from '../backend';
import { EmailToSupplier, IntegrationCode, IntegrationType, RequestAttachment } from '.';
import { BaseRequest } from './RequestBase';
import { TemplateSettingsLockDatePolicy } from './TemplateSettings';

export enum NetSuiteObjectStatus {
    Pending = 'Pending Approval',
    Rejected = 'Rejected',
    Open = 'Open',
    Paid = 'Paid In Full',
    Cancelled = 'Cancelled',
    FullyBilled = 'Fully Billed',
}

export enum NetSuiteVRAStatus {
    Closed = 'Closed',
    PendingReturn = 'PendingReturn',
    Credited = 'Credited',
    PendingCredit = 'PendingCredit',
}

export enum NetSuiteExpenseReportStatus {
    Unknown = 'unknown',
    ApprovedByAccounting = 'approved-by-accounting',
    ApprovedOverriddenByAccounting = 'approved-overridden-by-accounting',
    InProgress = 'in-progress',
    InTransit = 'in-transit',
    PaidInFull = 'paid-in-full',
    PendingAccountingApproval = 'pending-accounting-approval',
    PendingSupervisorApproval = 'pending-supervisor-approval',
    RejectedByAccounting = 'rejected-by-accounting',
    RejectedBySupervisor = 'rejected-by-supervisor',
    RejectedOverriddenByAccounting = 'rejected-overridden-by-accounting',
    Voided = 'voided',
}

export enum NetSuiteExpenseReportApprovalStatus {
    Unknown = 'unknown',
    PendingApproval = 'pending-approval',
    Approved = 'approved',
    Rejected = 'rejected',
}

export enum NetSuiteVendorType {
    Individual = 'individual',
    Company = 'company',
    Unknown = 'unknown',
}

export enum NetSuitePayeeType {
    Vendor = 'vendor',
    Employee = 'employee',
}

export interface NetSuiteCurrency extends Reference {
    ISOCode: string | null;
    rateToCompanyCurrency: number | null;
}

export interface NetSuiteBillPaymentCreditListItem {
    reference: string | null;
    appliedTo: string | null;
    type: string | null;
    dateDue: string | null;
    total: number | null;
    lineNumber: number | null;
    transactionId: string | null;
    currencyName: string | null;
    paymentAmount: number | null;
}

export interface NetSuiteCustomField {
    companyFieldId: string;
    scriptId: string;
    internalId: string;
    fieldType: string | null;
    valueString: string | null;
    valueRef: Reference | null;
    valueRefs: Reference[] | null;
    valueBoolean: boolean | null;
    valueDate: string | null;
    valueDecimal: number | null;
    valueLong: number | null;
}

export interface BillingAddress {
    id: string;
    fullAddress: string | null;
}

export interface NetSuiteSharedDetails {
    date: string | null;
    dueDate: string | null;
    reference: string | null;
    address: BillingAddress | null;
    url: string | null;
    netAmount: number;
    taxAmount: number;
    totalAmount: number;
    currency: Reference | null;
    exchangeRate: number | null;
    vatRegistrationNumber: string | null;
    lockDate: string | null;
    lockDatePolicy: TemplateSettingsLockDatePolicy;
    status: NetSuiteObjectStatus | null;
    transactionDate: string | null;
    customFields?: NetSuiteCustomField[];
    number: string;
}

interface NetSuiteLine {
    amount: number | null;
    class: Reference | null;
    customer: Reference | null;
    department: Reference | null;
    grossAmount: number | null;
    isBillable: boolean | null;
    lineNumber: number;
    location: Reference | null;
    taxAmount: number | null;
    taxCode: Reference | null;
    taxRate: number | null;
    customFields?: NetSuiteCustomField[];
    amortizationStartDate?: string | null;
    amortizationEndDate?: string | null;
    amortizationResidual?: number | null;
    amortizationSchedule: Reference | null;
    orderDocId?: string | null;
    orderLineNumber?: number | null;
    checked?: boolean;
}

export interface NetSuiteExpenseLine extends NetSuiteLine {
    account: Reference | null;
    category: Reference | null;
    memo: string | null;
    defaultRate?: number | null;
    rates?: {
        currency: string;
        defaultRate: number | null;
    }[];
}

export interface NetSuiteExpenseLineExpenseReport {
    checked?: boolean;
    lineNumber: number;
    amount: number | null;
    category: Reference | null;
    class: Reference | null;
    currency: Reference | null;
    customFields?: NetSuiteCustomField[];
    customer: Reference | null;
    department: Reference | null;
    exchangeRate: number | null;
    expenseAccount: Reference | null;
    expenseDate: string | null;
    foreignAmount: number | null;
    grossAmount: number | null;
    attachment: RequestAttachment | null;
    isBillable: boolean;
    isCorporateCreditCard: boolean;
    isNonReimbursable: boolean;
    isReceipt: boolean;
    location: Reference | null;
    memo: string | null;
    quantity: number | null;
    refLineNumber: number | null;
    taxAmount: number | null;
    taxCode: Reference | null;
    taxRate: number | null;
    unitPrice: number | null;
}

export interface NetSuiteUnitItem extends Reference {
    catalogUnitPrice: number | null;
    isItemPrimaryPurchaseUnit: boolean;
}

export interface NetSuiteItemLine extends NetSuiteLine {
    description: string | null;
    item: Reference | null;
    quantity: number | null;
    unitPrice: number | null;
    units: Reference | null;
    vendorName: string | null;
    expectedReceiptDate?: string | null;
    isClosed: boolean | null;
    defaultRate?: number | null;
    unitList?: NetSuiteUnitItem[] | null;
}

export interface NetSuiteBillPaymentApplyListItem {
    lineNumber?: number | null;
    amountDue?: number | null;
    currencyName?: string | null;
    dateDue?: string | null;
    discountAmount?: number | null;
    discountAvailable?: number | null;
    discountDate?: string | null;
    originalAmount?: number | null;
    paymentAmount?: number | null;
    reference?: string | null;
    transactionId?: string | null;
    type?: string | null;
    friendlyName?: string | null;
    requestId?: string | null;
    url?: string | null;
    attachments?: RequestAttachment[] | null;
}

export interface NetSuiteVendorBillDetails extends NetSuiteSharedDetails {
    integrationCode: IntegrationCode.NetSuiteBill;
    integrationType: IntegrationType.NetSuite;
    memo: string | null;
    transactionNumber: string | null;
    vendor: Reference | null;
    isPaymentHold: boolean | null;
    discountAmount: number | null;
    discountDate: string | null;
    postingPeriod: Reference | null;
    terms: Reference | null;
    account: Reference | null;
    department: Reference | null;
    class: Reference | null;
    location: Reference | null;
    subsidiary: Reference | null;
    expenseLines: NetSuiteExpenseLine[];
    itemLines: NetSuiteItemLine[];
}

export interface NetSuitePurchaseOrderDetails extends NetSuiteSharedDetails {
    integrationCode: IntegrationCode.NetSuitePO;
    integrationType: IntegrationType.NetSuite;
    vendor: Reference | null;
    vendorReference: string | null;
    terms: Reference | null;
    memo: string | null;
    employee: Reference | null;
    class: Reference | null;
    location: Reference | null;
    department: Reference | null;
    incoterm: Reference | null;
    subsidiary: Reference | null;
    expenseLines: NetSuiteExpenseLine[];
    itemLines: NetSuiteItemLine[];
    emailToSupplier: EmailToSupplier | null;
}

export interface NetSuiteExpenseReportDetails {
    integrationCode: IntegrationCode.NetSuiteExpenseReport;
    integrationType: IntegrationType.NetSuite;

    account: Reference | null;
    accountForCorporateCardExpenses: Reference | null;
    advance: number | null;
    approvalStatus: NetSuiteExpenseReportApprovalStatus | null;
    class: Reference | null;
    createdDate: string | null;
    currency: Reference | null;
    customFields?: NetSuiteCustomField[];
    date: string | null;
    department: Reference | null;
    dueDate: string | null;
    employee: Reference | null;
    exchangeRate: number | null;
    expenseLines: NetSuiteExpenseLineExpenseReport[];
    internalId: string | null;
    location: Reference | null;
    memo: string | null;
    modifiedDate: string | null;
    postingPeriod: Reference | null;
    reimbursableAmount: number | null;
    status: NetSuiteExpenseReportStatus | null;
    subsidiary: Reference | null;
    taxAmount: number | null;
    totalAmount: number | null;
    transactionNumber: string | null;
    url: string | null;
    useMultiCurrency: boolean;
}

export interface NetSuiteSalesOrderItem {
    lineUniqueKey: number | null;
    lineNumber: number | null;
    item: Reference | null;
    quantityCommitted: number | null;
    quantityFulfilled: number | null;
    quantityBilled: number | null;
    quantityBackOrdered: number | null;
    quantity: number | null;
    units: Reference | null;
    description: string | null;
    serialNumbers: string | null;
    priceLevel: Reference | null;
    unitPrice: number | null;
    amount: number | null;
    taxCode: Reference | null;
    taxRate: number | null;
    taxAmount: number | null;
    department: Reference | null;
    class: Reference | null;
    location: Reference | null;
    orderPriority: number | null;
    revenueRecognitionSchedule: Reference | null;
    revenueRecognitionStartDate: string | null;
    revenueRecognitionEndDate: string | null;
    excludeFromRateRequest: boolean;
    isClosed: boolean;
    isBillable: boolean;
    customFields?: NetSuiteCustomField[];
}

export interface NetSuiteSalesOrderItemWithId extends NetSuiteSalesOrderItem {
    id: string;
}

export interface NetSuiteInvoiceListItem
    extends Omit<
        CamelCasedPropertiesDeep<IntegrationsNetSuiteInvoiceListItem>,
        'item' | 'units' | 'priceLevel' | 'taxCode' | 'department' | 'class' | 'location' | 'customFields'
    > {
    item: Reference | null;
    units: Reference | null;
    priceLevel: Reference | null;
    taxCode: Reference | null;
    department: Reference | null;
    class: Reference | null;
    location: Reference | null;
    customFields?: NetSuiteCustomField[];
}

export interface NetSuiteSalesOrderDetails {
    integrationCode: IntegrationCode.NetSuiteSalesOrder;
    integrationType: IntegrationType.NetSuite;

    // Sales Order Details
    customer: Reference | null;
    address: BillingAddress | null;
    vatRegistrationNumber: string | null;
    reference: string;
    paymentReference: string | null;
    transactionDate: string | null;
    startDate: string | null;
    endDate: string | null;
    class: Reference | null;
    department: Reference | null;
    location: Reference | null;
    terms: Reference | null;
    billingSchedule: Reference | null;
    discountItem: Reference | null;
    discountRate: number | null;
    isDiscountRateAbsolute: boolean;
    memo: string | null;
    approvalStatus: string | null;
    status: string | null;
    customFields?: NetSuiteCustomField[];

    // Sales Details
    salesRep: Reference | null;
    opportunity: Reference | null;
    salesEffectiveDate: string | null;
    excludeCommissions: boolean;
    partner: Reference | null;

    // Shipping Details
    shipDate: string | null;
    shipMethod: Reference | null;
    shipComplete: boolean;
    shippingAddress: BillingAddress | null;

    // Revenue Details
    revenueStatus: string | null;
    recognizedRevenue: number | null;
    deferredRevenue: number | null;

    // Payment Details
    paymentMethod: Reference | null;
    creditCardNumber: string | null;
    creditCardExpirationDate: string | null;
    creditCardCardholderName: string | null;
    creditCardStreet: string | null;
    creditCardZipCode: string | null;
    creditCardProcessor: Reference | null;
    creditCardApproved: boolean;
    pnRef: string | null;
    authCode: string | null;
    avsStreetMatch: string | null;
    avsZipMatch: string | null;

    discountTotal: number | null;
    subTotal: number | null;
    taxAmount: number | null;
    totalAmount: number | null;
    currency: Reference | null;
    exchangeRate: number | null;
    url: string | null;
    itemLines: NetSuiteSalesOrderItem[];
}

export interface NetSuiteVRADetails {
    integrationCode: IntegrationCode.NetSuiteVRA;
    integrationType: IntegrationType.NetSuite;

    number: string | null;
    transactionNumber: string | null;
    vendor: Reference | null;
    totalAmount: number | null;
    currency: Reference | null;
    exchangeRate: number | null;
    taxAmount: number | null;
    transactionDate: string;
    memo: string | null;
    status: string | null;
    address: BillingAddress | null;
    vatRegistrationNumber?: string | null;
    lockDatePolicy: TemplateSettingsLockDatePolicy | null;
    lockDate: string | null;
    url: string | null;
    netAmount: number | null;
    department: Reference | null;
    class: Reference | null;
    location: Reference | null;
    itemLines: NetSuiteItemLine[];
    expenseLines: NetSuiteExpenseLine[];
    customFields: NetSuiteCustomField[];
}

export interface NetSuiteBillPaymentDetails {
    integrationCode: IntegrationCode.NetSuiteBillPayment;
    integrationType: IntegrationType.NetSuite;

    applyList: NetSuiteBillPaymentApplyListItem[];
    creditList: NetSuiteBillPaymentCreditListItem[];
    totalAmount: number;
    taxAmount: null;
    payeeAddress: BillingAddress | null;
    currency: Reference | null;
    payee: Reference | null;
    account: Reference | null;
    payablesAccount: Reference | null;
    class: Reference | null;
    department: Reference | null;
    location: Reference | null;
    postingPeriod: Reference | null;
    amount: number | null;
    transactionNumber: string | null;
    transactionDate: string | null;
    approvalStatus: string | null;
    balance: number | null;
    checkNumber: string | null;
    createdDate: string | null;
    exchangeRate: number | null;
    memo: string | null;
    modifiedDate: string | null;
    printVoucher: boolean | null;
    status: string | null;
    toBePrinted: boolean | null;
    url: string | null;
    customFields?: NetSuiteCustomField[];
}

export interface NetSuiteRADetails {
    integrationCode: IntegrationCode.NetSuiteRA;
    integrationType: IntegrationType.NetSuite;
}

export interface NetSuiteInvoiceDetails {
    integrationCode: IntegrationCode.NetSuiteInvoice;
    integrationType: IntegrationType.NetSuite;

    taxAmount: number | null;
    totalAmount: number | null;
    billingAddress: BillingAddress | null;
    class: Reference | null;
    currency: Reference | null;
    department: Reference | null;
    discountItem: Reference | null;
    discountRate: number | null;
    isDiscountRateAbsolute: boolean | null;
    discountAmount: number | null;
    discountTotal: number | null;
    discountDate: string | null;
    dueDate: string | null;
    endDate: string | null;
    customer: Reference | null;
    exchangeRate: number | null;
    excludeCommissions: boolean | null;
    leadSource: Reference | null;
    location: Reference | null;
    memo: string | null;
    opportunity: Reference | null;
    transactionReference: string | null;
    partner: Reference | null;
    postingPeriod: Reference | null;
    salesEffectiveDate: string | null;
    shipDate: string | null;
    shipMethod: Reference | null;
    shippingAddress: BillingAddress | null;
    shippingCost: number | null;
    handlingCost: number | null;
    startDate: string | null;
    status: string | null;
    subTotal: number | null;
    terms: Reference | null;
    transactionNumber: string | null;
    vatRegistrationNumber: string | null;
    salesRep: Reference | null;
    transactionDate: string | null;
    subsidiary: Reference | null;
    companyId: string | null;
    date: string | null;
    url: string | null;
    itemList: NetSuiteInvoiceListItem[];
    expenseCostList: NetSuiteInvoiceListItem[];
    itemCostList: NetSuiteInvoiceListItem[];
    invoiceTimeList: NetSuiteInvoiceListItem[];
    customFields?: NetSuiteCustomField[];
}

export interface NetSuiteJournalEntryLine {
    lineNumber: number | null;
    account: Reference | null;
    debitAmount: number | null;
    creditAmount: number | null;
    taxCode: Reference | null;
    taxRate: number | null;
    taxAmount: number | null;
    grossAmount: number | null;
    memo: string | null;
    department: Reference | null;
    class: Reference | null;
    location: Reference | null;
    amortizationSchedule: Reference | null;
    amortizationStartDate: string | null;
    amortizationEndDate: string | null;
    amortizationResidual: number | null;
    customFields?: NetSuiteCustomField[];
}

export interface NetSuiteJournalEntryDetails {
    integrationCode: IntegrationCode.NetSuiteJournalEntry;
    integrationType: IntegrationType.NetSuite;

    reference: string | null;
    transactionNumber: string | null;
    currency: Reference | null;
    exchangeRate: number | null;
    transactionDate: string | null;
    postingPeriod: Reference | null;
    reversalDate: string | null;
    reversalDefer: boolean | null;
    department: Reference | null;
    class: Reference | null;
    location: Reference | null;
    memo: string | null;
    debitTotal: number | null;
    creditTotal: number | null;
    url: string | null;
    taxAmount: null;
    totalAmount: null;
    lines: NetSuiteJournalEntryLine[];
    customFields?: NetSuiteCustomField[];
}

export interface NetSuiteSubsidiaryItem {
    id: string;
    name: string;
    currencyInternalId?: string | null;
    edition?: NetSuiteSubsidiaryEditionType;
}

export interface NetSuiteSubsidiary {
    subsidiary: NetSuiteSubsidiaryItem | null;
    creditLimit: number | null;
    taxCode: Reference | null;
}

export interface NetSuiteVendorAddress {
    label: string | null;
    defaultBilling: boolean;
    defaultShipping: boolean;
    primaryAddress: string | null;
    secondaryAddress: string | null;
    additionalAddress: string | null;
    addressRepresentation: string | null;
    addressee: string | null;
    attention: string | null;
    city: string | null;
    country: Reference | null;
    zip: string | null;
    state: string | null;
    phone: string | null;
}

export interface NetSuiteVendorDetails {
    integrationType: IntegrationType.NetSuite;
    integrationCode: IntegrationCode.NetSuiteVendor;

    vendorId: string | null;
    type: NetSuiteVendorType | null;
    companyName?: string | null;
    webAddress?: string | null;
    salutation?: string | null;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    jobTitle?: string | null;
    category: Reference | null;
    comments: string | null;
    email: string | null;
    phone: string | null;
    altPhone: string | null;
    fax: string | null;
    legalName: string | null;
    accountNumber: string | null;
    defaultExpenseAccount: Reference | null;
    defaultPayablesAccount: Reference | null;
    terms: Reference | null;
    creditLimit: number | null;
    incoterm: Reference | null;
    taxNumber: string | null;
    is1099Eligible: boolean | null;
    isJobResource: boolean | null;
    primarySubsidiary: NetSuiteSubsidiaryItem | null;
    subsidiaries: NetSuiteSubsidiary[];
    primaryCurrency: Reference | null;
    currency: null;
    url: string | null;
    currencies: Reference[];
    addressLines: NetSuiteVendorAddress[];
    customFields?: NetSuiteCustomField[];
}

export type NetSuiteRequestDetails =
    | NetSuiteVendorBillDetails
    | NetSuitePurchaseOrderDetails
    | NetSuiteExpenseReportDetails
    | NetSuiteSalesOrderDetails
    | NetSuiteVRADetails
    | NetSuiteBillPaymentDetails
    | NetSuiteInvoiceDetails
    | NetSuiteJournalEntryDetails
    | NetSuiteVendorDetails;

export type NetSuiteBillRequestSpecifics = {
    integrationType: IntegrationType.NetSuite;
    integrationCode: IntegrationCode.NetSuiteBill;
    details: NetSuiteVendorBillDetails;
    ocrAttachment?: Guid;
};

export type NetSuiteBillRequest = BaseRequest & NetSuiteBillRequestSpecifics;

export type NetSuitePoRequestSpecifics = {
    integrationType: IntegrationType.NetSuite;
    integrationCode: IntegrationCode.NetSuitePO;
    details: NetSuitePurchaseOrderDetails;
};

export type NetSuitePoRequest = BaseRequest & NetSuitePoRequestSpecifics;

export type NetSuiteSalesOrderRequestSpecifics = {
    integrationType: IntegrationType.NetSuite;
    integrationCode: IntegrationCode.NetSuiteSalesOrder;
    details: NetSuiteSalesOrderDetails;
};

export type NetSuiteSalesOrderRequest = BaseRequest & NetSuiteSalesOrderRequestSpecifics;

export type NetSuiteExpenseReportRequestSpecifics = {
    integrationType: IntegrationType.NetSuite;
    integrationCode: IntegrationCode.NetSuiteExpenseReport;
    details: NetSuiteExpenseReportDetails;
};

export type NetSuiteExpenseReportRequest = BaseRequest & NetSuiteExpenseReportRequestSpecifics;

export type NetSuiteVRARequestSpecifics = {
    integrationType: IntegrationType.NetSuite;
    integrationCode: IntegrationCode.NetSuiteVRA;
    details: NetSuiteVRADetails;
};

export type NetSuiteVRARequest = BaseRequest & NetSuiteVRARequestSpecifics;

export type NetSuiteBillPaymentRequestSpecifics = {
    integrationType: IntegrationType.NetSuite;
    integrationCode: IntegrationCode.NetSuiteBillPayment;
    details: NetSuiteBillPaymentDetails;
};

export type NetSuiteBillPaymentRequest = BaseRequest & NetSuiteBillPaymentRequestSpecifics;

export type NetSuiteInvoiceRequestSpecifics = {
    integrationType: IntegrationType.NetSuite;
    integrationCode: IntegrationCode.NetSuiteInvoice;
    details: NetSuiteInvoiceDetails;
};

export type NetSuiteInvoiceRequest = BaseRequest & NetSuiteInvoiceRequestSpecifics;

export type NetSuiteRARequestSpecifics = {
    integrationType: IntegrationType.NetSuite;
    integrationCode: IntegrationCode.NetSuiteRA;
    details: NetSuiteRADetails;
};

export type NetSuiteRARequest = BaseRequest & NetSuiteRARequestSpecifics;

export type NetSuiteJournalEntryRequestSpecifics = {
    integrationType: IntegrationType.NetSuite;
    integrationCode: IntegrationCode.NetSuiteJournalEntry;
    details: NetSuiteJournalEntryDetails;
};

export type NetSuiteJournalEntryRequest = BaseRequest & NetSuiteJournalEntryRequestSpecifics;

export type NetSuiteVendorRequestSpecifics = {
    integrationType: IntegrationType.NetSuite;
    integrationCode: IntegrationCode.NetSuiteVendor;
    details: NetSuiteVendorDetails;
};

export type NetSuiteVendorRequest = BaseRequest & NetSuiteVendorRequestSpecifics;

export enum NetSuiteAccountTypeGroup {
    CreditCard = 'CreditCard',
    Expense = 'Expense',
    ExpenseExtended = 'ExpenseExtended',
    Payable = 'Payable',
    BillPayments = 'BillPayments',
}

export type NetSuiteRequestSpecifics =
    | NetSuiteBillRequestSpecifics
    | NetSuitePoRequestSpecifics
    | NetSuiteSalesOrderRequestSpecifics
    | NetSuiteExpenseReportRequestSpecifics
    | NetSuiteVRARequestSpecifics
    | NetSuiteBillPaymentRequestSpecifics
    | NetSuiteRARequestSpecifics
    | NetSuiteInvoiceRequestSpecifics
    | NetSuiteJournalEntryRequestSpecifics
    | NetSuiteVendorRequestSpecifics;

export type NetSuiteRequest =
    | NetSuiteBillRequest
    | NetSuitePoRequest
    | NetSuiteSalesOrderRequest
    | NetSuiteExpenseReportRequest
    | NetSuiteVRARequest
    | NetSuiteBillPaymentRequest
    | NetSuiteRARequest
    | NetSuiteInvoiceRequest
    | NetSuiteJournalEntryRequest
    | NetSuiteVendorRequest;
