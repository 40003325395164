import { Grid, openSplitViewState, Spacing, Text } from '@approvalmax/ui/src/components';
import { intl } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { BeneficiaryStatusLegend } from 'modules/request/components/xero/BeneficiaryStatusLegend/BeneficiaryStatusLegend';
import { useUserProfile } from 'modules/utils';
import { LineItemsTable } from 'pages/requestList/components/lineItemsSection/LineItemsTable';
import { SectionContainer } from 'pages/requestList/components/SectionContainer/SectionContainer';
import { memo, useCallback, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { AmaxPayBankAccountInfoCard, BankLogoImage, splitViewGoToBarSettingsState } from 'shared/components';
import { useGetAmaxPayXeroRequestDetails } from 'shared/data';
import { activeAttachmentIdState, ContentSplitViewMode, contentSplitViewState } from 'shared/states';

import { getColumnDefinitions } from './XeroAmaxPayBatchPaymentRequestCard.config';
import {
    useAmounts,
    useBankAccountData,
    useGroupedItemsWithSupplierTotals,
} from './XeroAmaxPayBatchPaymentRequestCard.hooks';
import { messages } from './XeroAmaxPayBatchPaymentRequestCard.messages';
import { XeroAmaxPayBatchPaymentRequestCardProps } from './XeroAmaxPayBatchPaymentRequestCard.types';

export const XeroAmaxPayBatchPaymentRequestCard = memo<XeroAmaxPayBatchPaymentRequestCardProps>((props) => {
    const {
        request: { id, companyId, details, statusV2, currency, payers },
    } = props;

    const { isMergedBySupplier } = details;

    const setOpenSplitView = useSetRecoilState(openSplitViewState);
    const setContentSplitView = useSetRecoilState(contentSplitViewState);
    const setSplitViewGoToBarSettings = useSetRecoilState(splitViewGoToBarSettingsState);
    const activeAttachmentId = useRecoilValue(activeAttachmentIdState);

    const profile = useUserProfile();

    const { data: requestDetails } = useGetAmaxPayXeroRequestDetails(
        {
            companyId,
            requestId: id,
        },
        {
            staleTime: 30 * 1000,
        }
    );

    const onSelectAttachment = useCallback(
        (attachmentId: string, xeroBatchPaymentInvoiceId: string, xeroBatchPaymentInvoiceName: string) => {
            setOpenSplitView(true);
            setContentSplitView({
                mode: ContentSplitViewMode.AttachmentsToBillLinkedToBatchPayment,
                attachmentId,
                billId: xeroBatchPaymentInvoiceId,
                billFriendlyName: xeroBatchPaymentInvoiceName,
            });
            setSplitViewGoToBarSettings({
                show: true,
                targetContent: {
                    mode: ContentSplitViewMode.BillLinkedToBatchPayment,
                    billId: xeroBatchPaymentInvoiceId,
                },
                targetContentName: xeroBatchPaymentInvoiceName,
            });
        },
        [setSplitViewGoToBarSettings, setContentSplitView, setOpenSplitView]
    );

    const isRequestApproved = statusV2 === domain.RequestStatusV2.Approved;

    const onSelectBill = useCallback(
        (xeroBatchPaymentInvoiceId: string) => {
            setContentSplitView({
                mode: ContentSplitViewMode.BillLinkedToBatchPayment,
                billId: xeroBatchPaymentInvoiceId,
            });
            setOpenSplitView(true);
        },
        [setContentSplitView, setOpenSplitView]
    );

    const bankAccountData = useBankAccountData(details.items, requestDetails);

    const isPayer = useMemo(() => payers.some((payer) => payer.userEmail === profile.email), [payers, profile]);

    const columnDefinitions = useMemo(
        () =>
            getColumnDefinitions({
                companyId,
                requestId: id,
                isRequestApproved,
                isPayer,
                bankAccountData,
                onSelectAttachment,
                activeAttachmentId,
                onSelectBill,
                isMergedBySupplier,
            }),
        [
            activeAttachmentId,
            bankAccountData,
            companyId,
            id,
            isMergedBySupplier,
            isPayer,
            isRequestApproved,
            onSelectAttachment,
            onSelectBill,
        ]
    );

    const amounts = useAmounts(details.items);

    const groupedItemsWithSupplierTotals = useGroupedItemsWithSupplierTotals(details.items, details.isMergedBySupplier);

    return (
        <SectionContainer title={messages.title}>
            {bankAccountData && (
                <AmaxPayBankAccountInfoCard
                    name={bankAccountData.nickname}
                    bankLogo={
                        <BankLogoImage
                            src={bankAccountData.bank.iconUrl}
                            alt={bankAccountData.bank.fullName}
                            width={36}
                            height={36}
                        />
                    }
                    currencyCode={bankAccountData.currencyCode}
                    bankAccountNumber={bankAccountData.identification.bankAccountNumber}
                    sortCode={bankAccountData.identification.sortCode}
                    iban={bankAccountData.identification.iban}
                    disconnected={!bankAccountData.isConnected}
                />
            )}

            <Spacing height={16} />

            <BeneficiaryStatusLegend />

            <LineItemsTable columnDefinitions={columnDefinitions} lineItems={groupedItemsWithSupplierTotals} />

            <Spacing height={8} />

            <Grid gridTemplateColumns='auto 180px' rowGap={4}>
                <Text font='label' fontSize='small' color='midnight70' textAlign='right'>
                    {messages.total}
                </Text>

                <Text font='label' fontSize='small' fontWeight='bold' color='midnight100' textAlign='right'>
                    {intl.formatCurrency(amounts.total, currency)}
                </Text>

                <Text font='label' fontSize='small' color='midnight70' textAlign='right'>
                    {messages.totalPaid}
                </Text>

                <Text font='label' fontSize='small' fontWeight='bold' color='midnight100' textAlign='right'>
                    {intl.formatCurrency(amounts.totalPaid, currency)}
                </Text>

                <Text font='label' fontSize='small' color='midnight70' textAlign='right'>
                    {messages.remaining}
                </Text>

                <Text font='label' fontSize='small' fontWeight='bold' color='midnight100' textAlign='right'>
                    {intl.formatCurrency(amounts.remaining, currency)}
                </Text>
            </Grid>
        </SectionContainer>
    );
});

XeroAmaxPayBatchPaymentRequestCard.displayName = 'XeroAmaxPayBatchPaymentRequestCard';
