import { intl } from '@approvalmax/utils';
import { LineItemsTable } from 'pages/requestList/components/lineItemsSection/LineItemsTable';
import { FC, memo, useMemo } from 'react';

import { getColumnDefinitions } from './BillMatchingTable.config';
import { messages } from './BillMatchingTable.messages';
import { Amount, AmountContainer, Total } from './BillMatchingTable.styles';
import { BillMatchingTableProps } from './BillMatchingTable.types';

const BillMatchingTable: FC<BillMatchingTableProps> = memo((props) => {
    const { request, lineItems, totalAmount, totalAllocatedAmountToThisBill, currency, companyId, isInSplitView } =
        props;

    const billMatchingSettings = request.company.billMatchingSettings;

    const isGrnEnabled = !!request.company.xeroPurchaseOrderSettings?.isGrnEnabled;

    const columnDefinitions = useMemo(
        () =>
            getColumnDefinitions({
                amountType: billMatchingSettings?.amountType,
                companyId,
                isInSplitView,
                isGrnEnabled,
            }),
        [billMatchingSettings?.amountType, companyId, isGrnEnabled, isInSplitView]
    );

    return (
        <div>
            <LineItemsTable columnDefinitions={columnDefinitions} lineItems={lineItems} />

            <AmountContainer>
                <Total>{messages.totalAllocatedText}</Total>

                <Amount>{intl.formatCurrency(totalAllocatedAmountToThisBill, currency)}</Amount>

                <Amount>{intl.formatCurrency(totalAmount, currency)}</Amount>
            </AmountContainer>
        </div>
    );
});

export default BillMatchingTable;
