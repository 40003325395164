import { SortOrder } from '@approvalmax/ui/src/components';
import { domain } from 'modules/data';

import { BillsListType } from './AddBillInvoicesPopup.types';

export const initialFilterData = {
    contactId: null,
    amountFrom: null,
    amountTo: null,
    dateFrom: null,
    dateTo: null,
    dueDateFrom: null,
    dueDateTo: null,
    plannedDateFrom: null,
    plannedDateTo: null,
    reference: '',
    excludeBillsWithZeroAmount: true,
    billsListType: BillsListType.List,
};

export const initialSortingConfig: domain.AddBillsToBatchSortingConfig = {
    sortBy: domain.AddBillsToBatchTableColumns.Date,
    sortOrder: SortOrder.asc,
};

export const initialSortingConfigBySupplier: domain.AddBillsToBatchSortingConfig = {
    sortBy: domain.AddBillsToBatchTableColumns.DueDate,
    sortOrder: SortOrder.asc,
};
