import { selectors } from 'modules/common';
import { domain } from 'modules/data';

import { messages } from './requestActivitySelectors.QBooks.messages';

export const isQBooksSystemEvent = (request: selectors.types.ExpandedRequest, event: domain.RequestHistoryEvent) => {
    if (!event.isSystem || request.integrationType !== domain.IntegrationType.QBooks) {
        return false;
    }

    switch (event.type) {
        case domain.RequestHistoryEventType.RejectedExternally:
        case domain.RequestHistoryEventType.QBooksStatusRejected:
        case domain.RequestHistoryEventType.AttachmentUploadFailed:
            return true;

        default:
            return false;
    }
};

export const getQBooksEventHeader = (request: selectors.types.ExpandedRequest, event: domain.RequestHistoryEvent) => {
    switch (event.type) {
        case domain.RequestHistoryEventType.PostApprovalChangeDetected:
            return messages.postApprovalChangeDetected;

        case domain.RequestHistoryEventType.RejectedExternally:
            return messages.rejectedExternally;

        case domain.RequestHistoryEventType.QBooksStatusRejected:
            return messages.statusRejected;

        case domain.RequestHistoryEventType.EmailToPartnerSent: {
            const templateName = selectors.template.getTemplateDisplayNameByCode(request.integrationCode);

            return request.integrationCode === domain.IntegrationCode.QBooksPo
                ? messages.emailToVendorSent({ templateName })
                : messages.emailToCustomerSent({ templateName });
        }

        default:
            return null;
    }
};
