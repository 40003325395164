import { FC, memo } from 'react';
import bemFactory from 'react-bem-factory';

import { useRequestDeadline } from '../../../../../hooks/useRequestDeadline';
import { DeadlineWarn } from './DeadlineText.styles';
import { DeadlineTextProps } from './DeadlineText.types';

const qa = bemFactory.qa('reql-toolbar-deadline-text');

const DeadlineText: FC<DeadlineTextProps> = memo((props) => {
    const { request } = props;
    const deadline = useRequestDeadline(request);

    if (!deadline) {
        return null;
    }

    return (
        <DeadlineWarn $warning={deadline.warning} data-qa={qa('deadline-warn')} title={deadline.title}>
            {deadline.text}
        </DeadlineWarn>
    );
});

export default DeadlineText;
