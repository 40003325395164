import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.AddBillInvoicesPopup', {
    popupTitle: 'Add bills',
    addButtonText: 'ADD',
    list: 'List',
    groupBySupplier: 'Group by Supplier',
    totalAmountOfSelectedBills: 'Total amount of selected Bills:',
    dateFromCantBeAfterDateTo: 'The "from" date must be earlier than the "to" date',
});
