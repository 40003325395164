import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { companiesApiPaths } from '../paths';
import { GetCompanyCurrencies } from './useGetCompanyCurrencies.types';

export const useGetCompanyCurrencies = (
    params?: RequestParams<GetCompanyCurrencies>,
    queryOptions?: RequestQueryOptions<GetCompanyCurrencies>
) => {
    return useGet(companiesApiPaths.currencies, {
        params,
        queryOptions,
        apiVersion: 'v2',
    });
};
