import { FC, memo } from 'react';
import styled from 'styled-components';

import BaseImage from './BaseImage';

const Sleep = styled(BaseImage)`
    background-position: 0 -537px;
`;

const ImageSleep: FC = () => {
    return <Sleep />;
};

export default memo(ImageSleep);
