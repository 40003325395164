import { Flex, Text } from '@approvalmax/ui/src/components';
import { memo } from 'react';
import { TwoFaEnablingWizard, useTwoFaWizardController } from 'shared/components';

import { SoftEnforcementWizardProps } from './SoftEnforcementWizard.types';

export const SoftEnforcementWizard = memo<SoftEnforcementWizardProps>((props) => {
    const { onFinish } = props;
    const { wizardController } = useTwoFaWizardController();

    return (
        <Flex direction='column' spacing='24'>
            {wizardController.title && (
                <Text font='headline' fontSize='small' fontWeight='regular'>
                    {wizardController.title}
                </Text>
            )}

            <TwoFaEnablingWizard onFinish={onFinish} />
        </Flex>
    );
});

SoftEnforcementWizard.displayName = 'SoftEnforcementWizard';
