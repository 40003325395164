import { useMemo } from 'react';

import { useLineCustomFieldsDefinition } from '../../NetSuiteRequestCard.hooks';
import { getColumnDefs } from './NetSuiteInvoiceItemList.config';
import { InvoiceItemListFieldSettings, NetSuiteInvoiceItemListProps } from './NetSuiteInvoiceItemList.types';

export const useTableDefinitions = (
    invoiceItemList: NetSuiteInvoiceItemListProps['invoiceItemList'],
    companyCustomFields: NetSuiteInvoiceItemListProps['companyCustomFields'],
    fieldSettings: InvoiceItemListFieldSettings,
    exchangeRate?: number | null
) => {
    const customFieldsColumnDefinition = useLineCustomFieldsDefinition(invoiceItemList, companyCustomFields);

    const resultColumnDefinition = useMemo(
        () => [...getColumnDefs(fieldSettings, exchangeRate), ...customFieldsColumnDefinition],
        [customFieldsColumnDefinition, fieldSettings, exchangeRate]
    );

    return resultColumnDefinition;
};
