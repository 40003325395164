import { Guid } from '@approvalmax/types';
import { openSplitViewState, RichEditorView } from '@approvalmax/ui/src/components';
import { constants } from 'modules/common';
import { backend, domain } from 'modules/data';
import { DoneIcon } from 'modules/sprites';
import { useExpenseLinesFieldSettings } from 'pages/requestForm/containers/netsuite/NetSuiteExpenseReportRequestForm/hooks/useExpenseLinesFieldSettings/useExpenseLinesFieldSettings';
import { useCallback, useMemo } from 'react';
import { useSetRecoilState } from 'recoil';
import { ContentSplitViewMode, contentSplitViewState } from 'shared/states';

import { LineItemCheckBoxValueWrapper } from '../../NetSuiteRequestCard.styles';
import { getCustomFieldValue, sortCustomFields } from '../../NetSuiteRequestCard.utils';
import { getColumnDefs } from './RequestCardExpenseReport.config';

const { netSuiteConstants } = constants;

export const useTableDefinitions = (
    expenseLines: domain.NetSuiteExpenseLineExpenseReport[] = [],
    companyCustomFields: backend.SelectNetSuiteCustomCompanyFieldsAnswer['Fields'] = [],
    fieldsSettingsExpenseLines: ReturnType<typeof useExpenseLinesFieldSettings>,
    exchangeRate?: number | null
) => {
    const setOpenSplitView = useSetRecoilState(openSplitViewState);
    const setContentSplitView = useSetRecoilState(contentSplitViewState);

    const onPreviewAttachment = useCallback(
        (attachmentId: Guid) => {
            setOpenSplitView(true);
            setContentSplitView({
                mode: ContentSplitViewMode.AttachmentsToExpenseLineItem,
                attachmentId,
            });
        },
        [setContentSplitView, setOpenSplitView]
    );

    return useMemo(() => {
        const def = getColumnDefs(onPreviewAttachment, fieldsSettingsExpenseLines, exchangeRate);

        const usedCustomFields: {
            [scriptId: string]: domain.NetSuiteCustomField & { name: string };
        } = {};

        expenseLines.forEach((line) => {
            line.customFields?.forEach((field) => {
                if (!usedCustomFields[field.scriptId]) {
                    const fieldReference = companyCustomFields.find(
                        (companyField) => companyField.NetSuiteField.Field.ScriptId === field.scriptId
                    );

                    if (fieldReference) {
                        usedCustomFields[field.scriptId] = {
                            ...field,
                            name: fieldReference.NetSuiteField.Field.Name,
                        };
                    }
                }
            });
        });

        sortCustomFields(Object.values(usedCustomFields), companyCustomFields).forEach((field) => {
            if (field.fieldType && netSuiteConstants.ignoredCustomFields.includes(field.fieldType)) {
                return;
            }

            def.push({
                id: `expenseCustomField-${field.scriptId}`,
                name: field.name,
                value: (li) => {
                    const customField = li.customFields?.find((liField) => liField.scriptId === field.scriptId);

                    if (!customField) {
                        return null;
                    }

                    const customFieldValue = getCustomFieldValue(customField, true);

                    if (typeof customFieldValue === 'boolean') {
                        return customFieldValue === false ? null : 'true';
                    }

                    return customFieldValue;
                },
                cell: (value) => {
                    if (field.fieldType === 'CheckBox') {
                        return value === 'true' ? (
                            <LineItemCheckBoxValueWrapper>
                                <DoneIcon width={13} height={10} />
                            </LineItemCheckBoxValueWrapper>
                        ) : null;
                    }

                    if (field.fieldType === 'RichText' || field.fieldType === 'Hyperlink') {
                        return <RichEditorView html={value || '-'} />;
                    }

                    return value;
                },
                minWidth: 120,
            });
        });

        return def;
    }, [onPreviewAttachment, fieldsSettingsExpenseLines, expenseLines, companyCustomFields, exchangeRate]);
};
