import { Guid } from '@approvalmax/types';
import { errorHelpers } from '@approvalmax/utils';
import { selectors, ui } from 'modules/common';
import { useSelector } from 'modules/react-redux';
import { FC, memo } from 'react';

import { getActiveRequestSafe } from '../../../selectors/pageSelectors';
import { getMemberOrUser } from '../../../selectors/userSelectors';
import { useHoverText } from './StepParticipantHover.hooks';
import { StepParticipantHoverProps } from './StepParticipantHover.types';

export const StepParticipantHover: FC<StepParticipantHoverProps> = memo((props) => {
    const { children, className, qa, participant, request } = props;

    const allUsers = useSelector(selectors.user.getUsers);
    const activeRequest = useSelector(getActiveRequestSafe);
    const currentRequest = request || activeRequest;

    if (!currentRequest) {
        throw errorHelpers.notFoundError();
    }

    const team = useSelector((state) => selectors.company.getCompanyTeam(state, currentRequest.company));
    const user = (userId: Guid) => getMemberOrUser(userId, team, allUsers);
    const { hoverText, title } = useHoverText(participant, currentRequest);
    const userId = 'userId' in participant ? participant.userId : participant.id;

    return (
        <ui.UserHover key={userId} user={user(userId)} title={title} noteText={hoverText} className={className} qa={qa}>
            {children}
        </ui.UserHover>
    );
});

StepParticipantHover.displayName = 'StepParticipantHover';
