import { MutateDataParams, MutationOptions } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { requestsApiPaths } from '../paths';
import {
    UseAmaxPayXeroRefreshData,
    UseAmaxPayXeroRefreshPathParams,
    UseAmaxPayXeroRefreshResponse,
} from './useAmaxPayXeroRefresh.types';

export const useAmaxPayXeroRefresh = (
    mutationOptions?: MutationOptions<
        UseAmaxPayXeroRefreshResponse,
        MutateDataParams<UseAmaxPayXeroRefreshData, UseAmaxPayXeroRefreshPathParams>
    >
) => {
    return useMutateLegacy<
        MutateDataParams<UseAmaxPayXeroRefreshData, UseAmaxPayXeroRefreshPathParams>,
        UseAmaxPayXeroRefreshResponse
    >(requestsApiPaths.amaxPayXeroRefresh, { mutationOptions });
};
