import { messages } from './NetSuiteSalesOrderFooterFields.messages';

export const getRevenueStatusText = (revenueStatus: string) => {
    switch (revenueStatus) {
        case '_pending':
            return messages.revenueStatusPending;

        case '_inProgress':
            return messages.revenueStatusInProgress;

        case '_completed':
            return messages.revenueStatusCompleted;

        case '_onRevCommitment':
            return messages.revenueStatusRevCommitment;

        default:
            return revenueStatus;
    }
};
