import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('requestList.xero.ContactRequestCard.RequestContactAddressSection', {
    sameAsPostalAddress: 'Same as Postal Address',
    country: 'Country',
    cityTown: 'City/town',
    stateRegion: 'State/region',
    address: 'Address',
    attention: 'Attention',
});
