import { selectors } from 'modules/common';

export interface CompleteAirwallexPaymentPopupProps {
    onRequestClose: () => void;
    request: selectors.types.ExpandedRequest;
}

export enum Step {
    Balance = 'Balance',
    TwoFa = 'TwoFa',
    Processing = 'Processing',
    Discrepancies = 'Discrepancies',
}

export interface UsePayProps {
    onRequestClose: () => void;
    setCurrentStep: (step: Step) => void;
    request: selectors.types.ExpandedRequest;
}
