import { intl } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { FC, memo } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import FieldEntry from '../../../../../components/FieldEntry';
import FieldEntryWrapper from '../../../../../components/FieldEntryWrapper/FieldEntryWrapper';
import SectionSeparator from '../../../../../components/SectionSeparator/SectionSeparator';
import SectionHeaderText from '../SectionHeaderText/SectionHeaderText';

const i18nPrefix = 'requestList/containers/xero/ContactRequestCard/components/TaxSection/TaxSection';

interface TaxSectionProps {
    contact: domain.XeroContact;
}

const Content = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const StyledFieldEntryWrapper = styled(FieldEntryWrapper)`
    width: 20%;
`;

const TaxSection: FC<TaxSectionProps> = (props) => {
    const { contact } = props;

    if (
        !contact.taxNumber &&
        !contact.salesTax &&
        !contact.purchaseTax &&
        !contact.companyNumber &&
        !contact.defaultCurrency
    ) {
        return null;
    }

    return (
        <>
            <div>
                <SectionHeaderText>
                    <FormattedMessage id={`${i18nPrefix}.tax`} defaultMessage='TAX' />
                </SectionHeaderText>

                <Content>
                    <StyledFieldEntryWrapper>
                        <FieldEntry
                            title={intl.formatMessage({
                                id: `${i18nPrefix}.taxId`,
                                defaultMessage: 'TAX ID Number',
                            })}
                            value={contact.taxNumber}
                        />
                    </StyledFieldEntryWrapper>

                    <StyledFieldEntryWrapper>
                        <FieldEntry
                            title={intl.formatMessage({
                                id: `${i18nPrefix}.defaultSalesTax`,
                                defaultMessage: 'Default sales tax',
                            })}
                            value={contact.salesTax}
                        />
                    </StyledFieldEntryWrapper>

                    <StyledFieldEntryWrapper>
                        <FieldEntry
                            title={intl.formatMessage({
                                id: `${i18nPrefix}.defaultPurchaseTax`,
                                defaultMessage: 'Default purchase tax',
                            })}
                            value={contact.purchaseTax}
                        />
                    </StyledFieldEntryWrapper>

                    <StyledFieldEntryWrapper>
                        <FieldEntry
                            title={intl.formatMessage({
                                id: `${i18nPrefix}.companyNumber`,
                                defaultMessage: 'Company Registration Number',
                            })}
                            value={contact.companyNumber}
                        />
                    </StyledFieldEntryWrapper>
                </Content>
            </div>

            <SectionSeparator />
        </>
    );
};

export default memo(TaxSection);
