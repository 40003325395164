import { ExtractComponentProp } from '@approvalmax/types';
import { CloseIcon } from '@approvalmax/ui';
import { Box, Button, Flex, Select } from '@approvalmax/ui/src/components';
import { memo, useCallback } from 'react';

import { useFetchItems } from './CurrencySwitcher.hooks';
import { messages } from './CurrencySwitcher.messages';
import { TextStyled } from './CurrencySwitcher.styles';
import { CurrencySwitcherProps } from './CurrencySwitcher.types';

const CurrencySwitcher = memo<CurrencySwitcherProps>((props) => {
    const { request, companyId, selectedCurrency, requestCurrency, onCurrencyChange } = props;

    const { items, trigger, isFetching } = useFetchItems(requestCurrency, request, companyId);

    const value = selectedCurrency ? selectedCurrency : requestCurrency;

    const handleCurrencyChange = useCallback<ExtractComponentProp<typeof Select, 'onChange'>>(
        (value) => {
            const currentCurrency = items.find((item) => item.id === value);

            if (currentCurrency) {
                onCurrencyChange(currentCurrency);
            }
        },
        [items, onCurrencyChange]
    );

    const onReset = useCallback(() => {
        const currency = items.find((item) => item.id === requestCurrency?.id);

        if (currency) {
            onCurrencyChange(currency);
        }
    }, [items, onCurrencyChange, requestCurrency?.id]);

    const resetButtonDisabled = isFetching || requestCurrency?.id === value?.id;

    return (
        <Flex alignItems='center' spacing='8'>
            <TextStyled>{messages.title}</TextStyled>

            <Box width={180}>
                <Select
                    value={value?.id}
                    onChange={handleCurrencyChange}
                    placeholder={messages.placeholder}
                    items={items}
                    onFocus={trigger}
                    progress={isFetching}
                    autocomplete
                    itemNameKey='currencyName'
                    size='small'
                    clearable={false}
                />
            </Box>

            <Button
                color='midnight40'
                startIcon={<CloseIcon />}
                size='small'
                disabled={resetButtonDisabled}
                onClick={onReset}
            >
                {messages.resetButtonTitle}
            </Button>
        </Flex>
    );
});

export default CurrencySwitcher;
