import { Box, Form, Grid, Popup, Radio, Table, Text } from '@approvalmax/ui/src/components';
import { intl } from '@approvalmax/utils';
import { memo } from 'react';
import { useGetAmaxPayXeroRequestDetails } from 'shared/data';

import { useBankAccountData } from '../../../../../../xero/XeroAmaxPayBatchPaymentRequestCard/XeroAmaxPayBatchPaymentRequestCard.hooks';
import { BankAccountInfo } from '../BankAccountInfo/BankAccountInfo';
import { BankAccountInfoLoading } from '../BankAccountInfo/BankAccountInfo.loading';
import { getTableColumns } from './PaymentSummaryPopupContent.config';
import { containerWidth } from './PaymentSummaryPopupContent.constants';
import { useAmounts, useItems } from './PaymentSummaryPopupContent.hooks';
import { messages } from './PaymentSummaryPopupContent.messages';
import { TableContainer } from './PaymentSummaryPopupContent.styles';
import { PaymentSummaryPopupContentProps } from './PaymentSummaryPopupContent.types';
import { PaymentSummarySelectedLine } from './PaymentSummarySelectedLine/PaymentSummarySelectedLine';

export const PaymentSummaryPopupContent = memo<PaymentSummaryPopupContentProps>((props) => {
    const { request, onPay, showSchedulePaymentPopup, isPayBillLoading, isScheduledPaymentAvailable } = props;

    const { data: requestDetails } = useGetAmaxPayXeroRequestDetails({
        companyId: request.companyId,
        requestId: request.id,
    });

    const { items } = useItems(request);

    const bankAccountData = useBankAccountData(items, requestDetails);

    const amounts = useAmounts(request.details.items);

    const tableColumns = getTableColumns(request.details.isMergedBySupplier);

    return (
        <>
            <Popup.Header title={request.displayName} progress={isPayBillLoading} />

            <Form.Part>
                <Popup.Body>
                    <Grid
                        gridTemplateColumns={`auto ${containerWidth}px`}
                        columnGap={24}
                        overflowY='hidden'
                        alignItems='flex-start'
                    >
                        <TableContainer overflowY='auto'>
                            <Radio.Group name='selectedLineId'>
                                <Table
                                    stickyHeader
                                    data={items}
                                    columns={tableColumns}
                                    headerColor='white100'
                                    width='100%'
                                />
                            </Radio.Group>
                        </TableContainer>

                        <Grid gap={12} padding='12 0 0 0'>
                            <Text color='midnight100' font='body' fontSize='small' fontWeight='medium'>
                                {messages.paymentSummary}
                            </Text>

                            <Box color='midnight20' spacing='12' radius='xsmall'>
                                {bankAccountData ? (
                                    <BankAccountInfo data={bankAccountData} balance={null} />
                                ) : (
                                    <BankAccountInfoLoading />
                                )}
                            </Box>

                            <Grid gridTemplateColumns='auto 140px' rowGap={4}>
                                <Text font='label' fontSize='small' color='midnight70' textAlign='right'>
                                    {messages.total}
                                </Text>

                                <Text
                                    font='label'
                                    fontSize='small'
                                    fontWeight='bold'
                                    color='midnight100'
                                    textAlign='right'
                                >
                                    {intl.formatCurrency(amounts.total, request.currency)}
                                </Text>

                                <Text font='label' fontSize='small' color='midnight70' textAlign='right'>
                                    {messages.totalPaid}
                                </Text>

                                <Text
                                    font='label'
                                    fontSize='small'
                                    fontWeight='bold'
                                    color='midnight100'
                                    textAlign='right'
                                >
                                    {intl.formatCurrency(amounts.totalPaid, request.currency)}
                                </Text>

                                <Text font='label' fontSize='small' color='midnight70' textAlign='right'>
                                    {messages.remaining}
                                </Text>

                                <Text
                                    font='label'
                                    fontSize='small'
                                    fontWeight='bold'
                                    color='midnight100'
                                    textAlign='right'
                                >
                                    {intl.formatCurrency(amounts.remaining, request.currency)}
                                </Text>
                            </Grid>

                            <PaymentSummarySelectedLine
                                isScheduledPaymentAvailable={isScheduledPaymentAvailable}
                                onPay={onPay}
                                disabled={isPayBillLoading}
                                items={items}
                                showSchedulePaymentPopup={showSchedulePaymentPopup}
                            />
                        </Grid>
                    </Grid>
                </Popup.Body>
            </Form.Part>
        </>
    );
});

PaymentSummaryPopupContent.displayName = 'PaymentSummaryPopupContent';
