import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.PushingConnectGLPopup.PopupContent', {
    buttonText: 'Connect',
    buttonNoIntegrationText: 'No integration is available for my accounting system',
    title: 'Connect your accounting system to unlock core ApprovalMax features',
    description: '16,000+ businesses are already using our platform to {useCase}',

    useCaseDefault: 'strengthen internal financial controls',

    useCaseStreamlineAuditProcesses: 'streamline audit processes',
    useCaseEstablishInternalFinancialControls: 'establish internal financial controls',
    useCaseAutomateApprovalWorkflows: 'automate approval workflows',
    useCaseSpeedupTheApprovalProcess: 'speed up the approval process',

    useCaseStreamlineAuditProcessesAndEstablishInternalFinancialControls:
        'streamline financial controls and audit processes',
    useCaseStreamlineAuditProcessesAndAutomateApprovalWorkflows: 'streamline financial controls and approvals',
    useCaseStreamlineAuditProcessesAndSpeedupTheApprovalProcess: 'speed up the approval and audit processes',
    useCaseEstablishInternalFinancialControlsAndAutomateApprovalWorkflows:
        'automate approvals and establish financial controls',
    useCaseEstablishInternalFinancialControlsAndSpeedupTheApprovalProcess:
        'speed up approvals and establish financial controls',
    useCaseAutomateApprovalWorkflowsAndSpeedupTheApprovalProcess: 'automate and speed up the approval process',

    useCaseStreamlineAuditProcessesAndEstablishInternalFinancialControlsAndAutomateApprovalWorkflows:
        'establish financial controls and streamline approvals and audits',
    useCaseStreamlineAuditProcessesAndEstablishInternalFinancialControlsAndSpeedupTheApprovalProcess:
        'establish financial controls, streamline audits, and automate approvals',
    useCaseStreamlineAuditProcessesAndAutomateApprovalWorkflowsAndSpeedupTheApprovalProcess:
        'streamline audits and speed up approvals with automation',
    useCaseEstablishInternalFinancialControlsAndAutomateApprovalWorkflowsAndSpeedupTheApprovalProcess:
        'establish financial controls and speed up approvals with automation',
});
