import { errorHelpers, intl } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { ExpandedXeroLineItem, expandXeroLineItem } from 'modules/common/selectors/requestSelectors.Xero';
import { domain, State } from 'modules/data';
import { PriceCheckIndicator } from 'modules/request';
import { calculateXeroLineItemExpectedPrice } from 'modules/utils';

import { ColumnDefinition } from './types/ColumnDefinition';
import { messages } from './xeroRequestSelectors.messages';
import { DescriptionCell, UnitPriceCell, UnitPriceIndicatorCell } from './xeroRequestSelectors.styles';

export function getXeroLineItemsTableDef(
    state: State,
    request: selectors.types.ExpandedRequest
): Array<ColumnDefinition<ExpandedXeroLineItem>> {
    if (request.integrationType !== domain.IntegrationType.Xero) {
        throw errorHelpers.formatError();
    }

    const details = request.details;

    return [
        {
            id: 'item',
            name: messages.itemColumnName,
            value: (li) => (li.item ? li.item.text : null),
        },
        {
            id: 'description',
            name: messages.descriptionColumnName,
            value: (li) => li.description,
            cell: (val) => <DescriptionCell>{val}</DescriptionCell>,
        },
        {
            id: 'qty',
            name: messages.qtyColumnName,
            value: (li) => (!li.isDescriptionOnly ? intl.formatNumber(li.qty || 0, 'auto') : null),
            alignRight: true,
        },
        {
            id: 'unitPrice',
            name: messages.unitPriceColumnName,
            value: (li) => (!li.isDescriptionOnly ? intl.formatNumber(li.unitPrice || 0, 'auto') : null),
            alignRight: true,
            cell: (val, li) => (
                <UnitPriceCell
                    actualPrice={li.unitPrice}
                    expectedPrice={calculateXeroLineItemExpectedPrice(request.integrationCode, li.item)}
                    disabled={
                        request.integrationCode !== domain.IntegrationCode.XeroBill &&
                        request.integrationCode !== domain.IntegrationCode.XeroPo &&
                        request.integrationCode !== domain.IntegrationCode.XeroQuote &&
                        request.integrationCode !== domain.IntegrationCode.XeroInvoice
                    }
                    integrationType={domain.IntegrationType.Xero}
                    integrationCode={request.integrationCode}
                >
                    {({ warningLevel }) => (
                        <>
                            {val}

                            {warningLevel !== PriceCheckIndicator.WarningLevel.None ? (
                                <UnitPriceIndicatorCell $warningLevel={warningLevel} />
                            ) : undefined}
                        </>
                    )}
                </UnitPriceCell>
            ),
        },

        ...(request.integrationCode === domain.IntegrationCode.XeroInvoice ||
        request.integrationCode === domain.IntegrationCode.XeroQuote
            ? [
                  {
                      id: 'discountInvoice',
                      name: messages.discountInvoiceColumnName,
                      value: (li) => {
                          if (li.discount && li.discountAmount) {
                              return `${li.discountAmount} (${intl.formatNumber(li.discount)}%)`;
                          } else if (li.discount) {
                              return `${intl.formatNumber(li.discount)}%`;
                          } else if (li.discountAmount) {
                              return intl.formatNumber(li.discountAmount);
                          } else return null;
                      },
                      alignRight: true,
                  } as ColumnDefinition<ExpandedXeroLineItem>,
              ]
            : [
                  {
                      id: 'discount',
                      name: messages.discountColumnName,
                      value: (li) => (li.discount ? intl.formatNumber(li.discount) : null),
                      alignRight: true,
                  } as ColumnDefinition<ExpandedXeroLineItem>,
              ]),
        {
            id: 'account',
            name: messages.accountColumnName,
            value: (li) => (li.account ? li.account.text : null),
        },
        {
            id: 'taxRate',
            name: messages.taxRateColumnName,
            value: (li) => (li.tax ? li.tax.text : null),
        },
        ...Object.values(
            'lineItems' in details &&
                details.lineItems.map(expandXeroLineItem).reduce(
                    (m, a) => {
                        a.tracking.forEach((t) => {
                            const categoryId = t.category.id;

                            if (!m[categoryId]) {
                                m[categoryId] = {
                                    id: categoryId,
                                    name: t.category.text,
                                    value: (li) => {
                                        const tracking = li.tracking.find((tc) => tc.category.id === categoryId);

                                        if (!tracking) {
                                            return null;
                                        }

                                        return tracking.value.text;
                                    },
                                };
                            }
                        });

                        return m;
                    },
                    {} as {
                        [categoryId: string]: ColumnDefinition<ExpandedXeroLineItem>;
                    }
                )
        ),
        {
            id: 'amount',
            name: messages.amountColumnName({
                currency: request.currency,
            }),
            value: (li) => (!li.isDescriptionOnly ? intl.formatNumber(li.amount || 0) : null),
            alignRight: true,
            alwaysVisible: true,
        },
    ];
}
