import { compareHelpers, errorHelpers, objectHelpers } from '@approvalmax/utils';
import { backend, du } from 'modules/data';
import { getBeautifiedEntity } from 'modules/data/utils';
import { emailToSupplierHelpers } from 'shared/helpers';

import { IntegrationCode, IntegrationType } from '../Integration';
import { NetSuiteExpenseLineExpenseReport, NetSuiteExpenseReportDetails, RequestAttachment } from '../Request';
import {
    NetSuiteBillPaymentApplyListItem,
    NetSuiteBillPaymentCreditListItem,
    NetSuiteBillPaymentDetails,
    NetSuiteCustomField,
    NetSuiteExpenseLine,
    NetSuiteInvoiceDetails,
    NetSuiteInvoiceListItem,
    NetSuiteItemLine,
    NetSuiteJournalEntryDetails,
    NetSuiteJournalEntryLine,
    NetSuitePurchaseOrderDetails,
    NetSuiteRequestDetails,
    NetSuiteRequestSpecifics,
    NetSuiteSalesOrderDetails,
    NetSuiteSalesOrderItem,
    NetSuiteSharedDetails,
    NetSuiteVendorBillDetails,
    NetSuiteVendorDetails,
    NetSuiteVRADetails,
} from '../Request.NetSuite';
import { mapAttachment } from './requestSchema';
import { mapReference } from './utils';

const mapCustomField = (customField: backend.IntegrationsNetSuiteCustomField): NetSuiteCustomField => {
    return {
        companyFieldId: customField.CompanyFieldId,
        scriptId: customField.ScriptId,
        internalId: customField.InternalId,
        fieldType: customField.FieldType || null,
        valueString: customField.ValueString || null,
        valueRef: customField.ValueRef ? du.parseServerReference(customField.ValueRef) : null,
        valueRefs: Array.isArray(customField.ValueRefs) ? customField.ValueRefs.map(du.parseServerReference) : null,
        valueBoolean: typeof customField.ValueBoolean === 'boolean' ? customField.ValueBoolean : null,
        valueDate: customField.ValueDate || null,
        valueDecimal: typeof customField.ValueDecimal === 'number' ? customField.ValueDecimal : null,
        valueLong: typeof customField.ValueLong === 'number' ? customField.ValueLong : null,
    };
};

const mapSharedDetails = (details: any): NetSuiteSharedDetails => {
    return {
        number: '',
        date: details.CreatedDate!,
        dueDate: details.DueDate || null,
        reference: details.Reference || null,
        address: details.Address
            ? {
                  id: details.Address.Id,
                  fullAddress: details.Address.FullAddress,
              }
            : null,
        url: details.Url || null,
        netAmount: details.NetAmount || 0,
        taxAmount: details.TaxAmount || 0,
        totalAmount: details.TotalAmount || 0,
        currency: mapReference(details.Currency),
        exchangeRate: details.ExchangeRate || null,
        vatRegistrationNumber: details.VatRegistrationNumber || '',
        lockDate: details.LockDate || details.CompanyLockDate || null,
        lockDatePolicy: details.TemplateLockDatePolicy,
        status: details.Status || null,
        transactionDate: details.TransactionDate || null,
        customFields: (details.CustomFields || []).map(mapCustomField),
    };
};

const mapExprenseLine = (line: backend.IntegrationsNetSuiteExpenseLine): NetSuiteExpenseLine => {
    return {
        account: mapReference(line.Account),
        amount: line.Amount,
        category: mapReference(line.Category),
        class: mapReference(line.Class),
        customer: mapReference(line.Customer),
        department: mapReference(line.Department),
        grossAmount: line.GrossAmount,
        isBillable: line.IsBillable,
        lineNumber: line.LineNumber,
        location: mapReference(line.Location),
        memo: line.Memo || null,
        taxAmount: line.TaxAmount,
        taxCode: mapReference(line.TaxCode),
        taxRate: line.TaxRate,
        customFields: (line.CustomFields || []).map(mapCustomField),
        defaultRate: line.CatalogAmount || null,
        amortizationStartDate: line.AmortizationStartDate || null,
        amortizationEndDate: line.AmortizationEndDate || null,
        amortizationResidual: typeof line.AmortizationResidual === 'number' ? line.AmortizationResidual : null,
        amortizationSchedule: mapReference(line.AmortizationSchedule),
        orderDocId: line.OrderDocId || null,
        orderLineNumber: line.OrderLineNumber || null,
    };
};

const mapExpenseReportExpenseLine = (line: any): NetSuiteExpenseLineExpenseReport => {
    return {
        amount: line.Amount,
        category: mapReference(line.Category),
        class: mapReference(line.Class),
        currency: mapReference(line.Currency),
        customFields: (line.CustomFields || []).map(mapCustomField),
        customer: mapReference(line.Customer),
        department: mapReference(line.Department),
        exchangeRate: line.ExchangeRate || null,
        expenseAccount: mapReference(line.ExpenseAccount),
        expenseDate: line.ExpenseDate || null,
        foreignAmount: line.ForeignAmount || null,
        grossAmount: line.GrossAmount || null,
        isBillable: line.IsBillable || false,
        isCorporateCreditCard: line.IsCorporateCreditCard || false,
        isNonReimbursable: line.IsNonReimbursable || false,
        isReceipt: line.IsReceipt || false,
        lineNumber: line.LineNumber,
        location: mapReference(line.Location),
        memo: line.Memo || null,
        quantity: line.Quantity || null,
        refLineNumber: line.RefLineNumber || null,
        taxAmount: line.TaxAmount || null,
        taxCode: mapReference(line.TaxCode),
        taxRate: line.TaxRate || null,
        unitPrice: line.UnitPrice || null,
        attachment: line.Attachment ? mapExpenseLineAttachment(line.Attachment) : null,
    };
};

const mapExpenseLineAttachment = (LineAttachment: any): RequestAttachment => ({
    id: LineAttachment.AttachmentId,
    name: LineAttachment.Name,
    size: LineAttachment.Size,
    createdDate: LineAttachment.CreatedDate,
    authorId: LineAttachment.AuthorId,
    externalId: LineAttachment.ExternalId,
    isNew: LineAttachment.isNew,
    attachmentType: LineAttachment.AttachmentType,
});

const mapItemLine = (line: any): NetSuiteItemLine => {
    return {
        amount: line.Amount,
        class: mapReference(line.Class),
        customer: mapReference(line.Customer),
        department: mapReference(line.Department),
        grossAmount: line.GrossAmount,
        isBillable: line.IsBillable,
        lineNumber: line.LineNumber,
        location: mapReference(line.Location),
        taxAmount: line.TaxAmount,
        taxCode: mapReference(line.TaxCode),
        taxRate: line.TaxRate,
        description: line.Description,
        item: mapReference(line.Item),
        quantity: line.Quantity,
        unitPrice: line.UnitPrice,
        units: mapReference(line.Units),
        vendorName: line.VendorName,
        expectedReceiptDate: line.ExpectedReceiptDate || null,
        isClosed: line.IsClosed || false,
        customFields: (line.CustomFields || []).map(mapCustomField),
        defaultRate: line.CatalogUnitPrice || null,
        amortizationStartDate: line.AmortizationStartDate || null,
        amortizationEndDate: line.AmortizationEndDate || null,
        amortizationResidual: typeof line.AmortizationResidual === 'number' ? line.AmortizationResidual : null,
        amortizationSchedule: mapReference(line.AmortizationSchedule),
        orderDocId: line.OrderDocId || null,
        orderLineNumber: line.OrderLineNumber || null,
    };
};

const mapBillDetails = (details?: backend.IntegrationsNetSuiteVendorBillDetailsAnswer): NetSuiteVendorBillDetails => {
    if (!details) {
        throw errorHelpers.formatError('[requestSchema.NetSuite] Bill details is undefined.');
    }

    const sharedDetails = mapSharedDetails(details);

    return {
        ...sharedDetails,
        integrationCode: IntegrationCode.NetSuiteBill,
        integrationType: IntegrationType.NetSuite,
        number: details.Reference || '',
        memo: details.Memo || null,
        transactionNumber: details.TransactionNumber || null,
        vendor: mapReference(details.Vendor),
        isPaymentHold: typeof details.IsPaymentHold === 'boolean' ? details.IsPaymentHold : null,
        discountAmount: typeof details.DiscountAmount === 'number' ? details.DiscountAmount : null,
        discountDate: details.DiscountDate || null,
        postingPeriod: mapReference(details.PostingPeriod),
        terms: mapReference(details.Terms),
        account: mapReference(details.Account),
        department: mapReference(details.Department),
        class: mapReference(details.Class),
        location: mapReference(details.Location),
        subsidiary: mapReference(details.Subsidiary),
        expenseLines: (details.ExpenseLines || []).map(mapExprenseLine),
        itemLines: (details.ItemLines || []).map(mapItemLine),
    };
};

const mapPurchaseOrderDetails = (
    details?: backend.IntegrationsNetSuitePurchaseOrderDetailsAnswer,
    emailToContact?: any
): NetSuitePurchaseOrderDetails => {
    if (!details) {
        throw errorHelpers.formatError('[requestSchema.NetSuite] Bill details is undefined.');
    }

    const sharedDetails = mapSharedDetails(details);

    return {
        ...sharedDetails,
        integrationCode: IntegrationCode.NetSuitePO,
        integrationType: IntegrationType.NetSuite,
        number: details.VendorReference || '',
        vendor: mapReference(details.Vendor),
        vendorReference: details.VendorReference || null,
        terms: mapReference(details.Terms),
        memo: details.Memo || null,
        employee: mapReference(details.Employee),
        class: mapReference(details.Class),
        location: mapReference(details.Location),
        department: mapReference(details.Department),
        incoterm: mapReference(details.Incoterm),
        subsidiary: mapReference(details.Subsidiary),
        expenseLines: (details.ExpenseLines || []).map(mapExprenseLine),
        itemLines: (details.ItemLines || []).map(mapItemLine),
        emailToSupplier: {
            emailHasToBeSent: emailToContact?.EmailHasToBeSent || false,
            from: emailToContact?.EmailFrom || '',
            replyTo: emailToContact?.EmailReplyTo || '',
            to: (emailToContact?.EmailTo || '').split(',').filter(Boolean),
            cc: (emailToContact?.EmailCC || '').split(',').filter(Boolean),
            subject: emailToSupplierHelpers.escapeExpressions(emailToContact?.EmailSubject || ''),
            body: emailToContact?.EmailBody || '',
            attachments: (emailToContact?.Attachments || [])
                .map((attachment: any) => mapAttachment(attachment))
                .sort(compareHelpers.comparatorFor<RequestAttachment>(compareHelpers.stringComparator2Asc, 'name')),
        },
    };
};

const mapExpenseReportDetails = (details: any): NetSuiteExpenseReportDetails => {
    if (!details) {
        throw errorHelpers.formatError('[requestSchema.NetSuite] Expense Report details is undefined.');
    }

    return {
        integrationCode: IntegrationCode.NetSuiteExpenseReport,
        integrationType: IntegrationType.NetSuite,

        account: mapReference(details.Account),
        accountForCorporateCardExpenses: mapReference(details.AccountForCorporateCardExpenses),
        advance: details.Advance || null,
        approvalStatus: details.ApprovalStatus || null,
        class: mapReference(details.Class),
        currency: mapReference(details.Currency),
        customFields: (details.CustomFields || []).map(mapCustomField),
        department: mapReference(details.Department),
        dueDate: details.DueDate || null,
        employee: mapReference(details.Employee),
        exchangeRate: details.ExchangeRate || null,
        expenseLines: (details.ExpenseLines || []).map(mapExpenseReportExpenseLine),
        internalId: details.InternalId,
        location: mapReference(details.Location),
        memo: details.Memo || null,
        postingPeriod: mapReference(details.PostingPeriod),
        reimbursableAmount: details.ReimbursableAmount || 0,
        status: details.Status,
        subsidiary: mapReference(details.Subsidiary),
        taxAmount: details.TaxAmount || 0,
        totalAmount: details.TotalAmount || 0,
        transactionNumber: details.TransactionNumber || null,
        url: details.Url || null,
        useMultiCurrency: details.UseMultiCurrency || false,
        date: details.Date,
        createdDate: details.CreatedDate,
        modifiedDate: details.ModifiedDate || null,
    };
};

const mapSalesOrderItem = (item: backend.IntegrationsNetSuiteSalesOrderItemLine): NetSuiteSalesOrderItem => {
    return {
        lineUniqueKey: item.LineUniqueKey,
        lineNumber: item.LineNumber,
        item: mapReference(item.Item),
        quantityCommitted: item.QuantityCommitted,
        quantityFulfilled: item.QuantityFulfilled,
        quantityBilled: item.QuantityBilled,
        quantityBackOrdered: item.QuantityBackOrdered,
        quantity: item.Quantity,
        units: mapReference(item.Units),
        description: item.Description || null,
        serialNumbers: item.SerialNumbers || null,
        unitPrice: item.UnitPrice,
        taxCode: mapReference(item.TaxCode),
        taxRate: item.TaxRate,
        taxAmount: item.TaxAmount,
        amount: item.Amount,
        class: mapReference(item.Class),
        department: mapReference(item.Department),
        location: mapReference(item.Location),
        priceLevel: mapReference(item.PriceLevel),
        orderPriority: item.OrderPriority,
        revenueRecognitionSchedule: mapReference(item.RevenueRecognitionSchedule),
        revenueRecognitionStartDate: item.RevenueRecognitionStartDate || null,
        revenueRecognitionEndDate: item.RevenueRecognitionEndDate || null,
        excludeFromRateRequest: item.ExcludeFromRateRequest || false,
        isClosed: item.IsClosed || false,
        isBillable: item.IsBillable || false,
        customFields: (item.CustomFields || []).map(mapCustomField),
    };
};

const mapSalesOrderDetails = (
    details?: backend.IntegrationsNetSuiteSalesOrderDetailsAnswer
): NetSuiteSalesOrderDetails => {
    if (!details) {
        throw errorHelpers.formatError('[requestSchema.NetSuite] Sales Order details is undefined.');
    }

    return {
        integrationCode: IntegrationCode.NetSuiteSalesOrder,
        integrationType: IntegrationType.NetSuite,

        reference: details.Reference || '',
        customer: mapReference(details.Customer),
        transactionDate: details.TransactionDate || null,
        startDate: details.StartDate || null,
        endDate: details.EndDate || null,
        paymentReference: details.PaymentReference || null,
        class: mapReference(details.Class),
        department: mapReference(details.Department),
        location: mapReference(details.Location),
        memo: details.Memo || null,
        approvalStatus: details.ApprovalStatus || null,
        status: details.Status || null,
        salesRep: mapReference(details.SalesRep),
        opportunity: mapReference(details.Opportunity),
        salesEffectiveDate: details.SalesEffectiveDate || null,
        excludeCommissions: details.ExcludeCommissions || false,
        partner: mapReference(details.Partner),
        discountItem: mapReference(details.DiscountItem),
        discountRate: details.DiscountRate,
        discountTotal: details.DiscountTotal || 0,
        isDiscountRateAbsolute: details.IsDiscountRateAbsolute || false,
        subTotal: details.Subtotal || 0,
        taxAmount: details.TaxTotal || 0,
        totalAmount: details.Total || 0,
        shipDate: details.ShipDate || null,
        shipMethod: mapReference(details.ShipMethod),
        shipComplete: details.ShipComplete || false,
        shippingAddress: details.ShippingAddress
            ? {
                  id: details.ShippingAddress.Id,
                  fullAddress: details.ShippingAddress.FullAddress,
              }
            : null,
        terms: mapReference(details.Terms),
        billingSchedule: mapReference(details.BillingSchedule),
        address: details.Address
            ? {
                  id: details.Address.Id,
                  fullAddress: details.Address.FullAddress,
              }
            : null,
        paymentMethod: mapReference(details.PaymentMethod),
        creditCardNumber: details.CreditCardNumber || null,
        creditCardExpirationDate: details.CreditCardExpirationDate || null,
        creditCardCardholderName: details.CreditCardCardholderName || null,
        creditCardStreet: details.CreditCardStreet || null,
        creditCardZipCode: details.CreditCardZipCode || null,
        creditCardProcessor: mapReference(details.CreditCardProcessor),
        creditCardApproved: details.CreditCardApproved || false,
        pnRef: details.PnRef || null,
        authCode: details.AuthCode || null,
        avsStreetMatch: details.AvsStreetMatch || null,
        avsZipMatch: details.AvsZipMatch || null,
        currency: mapReference(details.Currency),
        exchangeRate: details.ExchangeRate || null,
        vatRegistrationNumber: details.VatRegistrationNumber || null,
        revenueStatus: details.RevenueStatus || null,
        recognizedRevenue: details.RecognizedRevenue,
        deferredRevenue: details.DeferredRevenue,
        url: details.Url || null,
        itemLines: (details.ItemLines || []).map(mapSalesOrderItem),
        customFields: (details.CustomFields || []).map(mapCustomField),
    };
};

const mapVRADetails = (details?: backend.IntegrationsNetSuiteVRADetailsAnswer): NetSuiteVRADetails => {
    if (!details) {
        throw errorHelpers.formatError('[requestSchema.NetSuite] VRA details is undefined.');
    }

    return {
        integrationCode: IntegrationCode.NetSuiteVRA,
        integrationType: IntegrationType.NetSuite,

        number: details.TransactionNumber || null,
        address: details.BillingAddress
            ? {
                  id: details.BillingAddress.Id,
                  fullAddress: details.BillingAddress.FullAddress,
              }
            : null,
        url: details.Url || null,
        netAmount: details.NetAmount || 0,
        taxAmount: details.TaxAmount || 0,
        totalAmount: details.TotalAmount || 0,
        currency: mapReference(details.Currency),
        exchangeRate: details.ExchangeRate || null,
        lockDate: details.CompanyLockDate || null,
        lockDatePolicy: details.WorkflowLockDatePolicy,
        status: details.Status || null,
        transactionDate: details.TransactionDate,
        memo: details.Memo || null,
        transactionNumber: details.TransactionNumber || null,
        vendor: mapReference(details.Vendor),
        department: mapReference(details.Department),
        class: mapReference(details.Class),
        location: mapReference(details.Location),
        expenseLines: (details.ExpenseLines || []).map(mapExprenseLine),
        itemLines: (details.ItemLines || []).map(mapItemLine),
        customFields: (details.CustomFields || []).map(mapCustomField),
    };
};

const mapApplyLine = (line: backend.IntegrationsNetSuiteBillPaymentApplyListItem): NetSuiteBillPaymentApplyListItem => {
    return {
        ...getBeautifiedEntity(line),
        attachments: (line.Attachments || []).map(mapAttachment),
    };
};

const mapCreditLine = (
    line: backend.IntegrationsNetSuiteBillPaymentCreditListItem
): NetSuiteBillPaymentCreditListItem => {
    return getBeautifiedEntity(line);
};

const mapBillPaymentDetails = (
    details?: backend.IntegrationsNetSuiteBillPaymentDetailsAnswer
): NetSuiteBillPaymentDetails => {
    if (!details) {
        throw errorHelpers.formatError('[requestSchema.NetSuite] Bill Payment details is undefined.');
    }

    const sharedDetails = mapSharedDetails(details);

    return {
        ...sharedDetails,
        integrationCode: IntegrationCode.NetSuiteBillPayment,
        integrationType: IntegrationType.NetSuite,
        totalAmount: details.Amount || 0,
        taxAmount: null,
        memo: details.Memo || null,
        transactionNumber: details.TransactionNumber || null,
        transactionDate: details.TransactionDate || null,
        postingPeriod: mapReference(details.PostingPeriod),
        account: mapReference(details.Account),
        payablesAccount: mapReference(details.APAccount),
        department: mapReference(details.Department),
        class: mapReference(details.Class),
        location: mapReference(details.Location),
        payee: mapReference(details.Payee),
        payeeAddress: details.PayeeAddress
            ? {
                  id: details.PayeeAddress.Id,
                  fullAddress: details.PayeeAddress.AddressText,
              }
            : null,
        amount: details.Amount,
        approvalStatus: details.ApprovalStatus,
        balance: details.Balance,
        checkNumber: details.CheckNumber,
        createdDate: details.CreatedDate,
        modifiedDate: details.ModifiedDate,
        printVoucher: details.PrintVoucher,
        toBePrinted: details.ToBePrinted,
        applyList: (details.ApplyList || []).map(mapApplyLine),
        creditList: (details.CreditList || []).map(mapCreditLine),
        customFields: (details.CustomFields || []).map(mapCustomField),
    };
};

const mapInvoiceItemLine = (line: backend.IntegrationsNetSuiteInvoiceListItem): NetSuiteInvoiceListItem => ({
    ...getBeautifiedEntity(line),
    item: mapReference(line.Item),
    units: mapReference(line.Units),
    priceLevel: mapReference(line.PriceLevel),
    taxCode: mapReference(line.TaxCode),
    department: mapReference(line.Department),
    class: mapReference(line.Class),
    location: mapReference(line.Location),
    customFields: (line.CustomFields || []).map(mapCustomField),
});

const mapInvoiceDetails = (details?: backend.IntegrationsNetSuiteInvoiceDetailsAnswer): NetSuiteInvoiceDetails => {
    if (!details) {
        throw errorHelpers.formatError('[requestSchema.NetSuite] Invoice details is undefined.');
    }

    const sharedDetails = mapSharedDetails(details);

    return {
        ...sharedDetails,
        integrationCode: IntegrationCode.NetSuiteInvoice,
        integrationType: IntegrationType.NetSuite,
        taxAmount: details.TaxTotal,
        totalAmount: details.Total,
        billingAddress: details.BillingAddress
            ? {
                  id: details.BillingAddress.Id,
                  fullAddress: details.BillingAddress.FullAddress,
              }
            : null,
        class: mapReference(details.Class),
        department: mapReference(details.Department),
        discountItem: mapReference(details.DiscountItem),
        leadSource: mapReference(details.LeadSource),
        location: mapReference(details.Location),
        customer: mapReference(details.Customer),
        opportunity: mapReference(details.Opportunity),
        partner: mapReference(details.Partner),
        postingPeriod: mapReference(details.PostingPeriod),
        shipMethod: mapReference(details.ShipMethod),
        terms: mapReference(details.Terms),
        salesRep: mapReference(details.SalesRep),
        subsidiary: mapReference(details.Subsidiary),
        discountRate: details.DiscountRate,
        isDiscountRateAbsolute: details.IsDiscountRateAbsolute,
        discountAmount: details.DiscountAmount,
        discountTotal: details.DiscountTotal,
        discountDate: details.DiscountDate,
        dueDate: details.DueDate,
        endDate: details.EndDate,
        excludeCommissions: details.ExcludeCommissions,
        memo: details.Memo,
        transactionReference: details.TransactionReference,
        salesEffectiveDate: details.SalesEffectiveDate,
        shipDate: details.ShipDate,
        shippingAddress: details.ShippingAddress
            ? {
                  id: details.ShippingAddress.Id,
                  fullAddress: details.ShippingAddress.FullAddress,
              }
            : null,
        shippingCost: details.ShippingCost,
        handlingCost: details.HandlingCost,
        startDate: details.StartDate,
        subTotal: details.SubTotal,
        transactionNumber: details.TransactionNumber,
        companyId: details.CompanyId,
        itemList: (details.ItemList || []).map(mapInvoiceItemLine),
        expenseCostList: (details.ExpenseCostList || []).map(mapInvoiceItemLine),
        itemCostList: (details.ItemCostList || []).map(mapInvoiceItemLine),
        invoiceTimeList: (details.InvoiceTimeList || []).map(mapInvoiceItemLine),
    };
};

const mapJournalEntryLines = (line: backend.IntegrationsNetSuiteJournalEntryLine): NetSuiteJournalEntryLine => ({
    ...getBeautifiedEntity(line),
    account: mapReference(line.Account),
    taxCode: mapReference(line.TaxCode),
    department: mapReference(line.Department),
    class: mapReference(line.Class),
    location: mapReference(line.Location),
    amortizationSchedule: mapReference(line.AmortizationSchedule),
    customFields: (line.CustomFields || []).map(mapCustomField),
});

const mapJournalEntryDetails = (
    details?: backend.IntegrationsNetSuiteJournalEntryDetailsAnswer
): NetSuiteJournalEntryDetails => {
    if (!details) {
        throw errorHelpers.formatError('[requestSchema.NetSuite] Invoice details is undefined.');
    }

    const sharedDetails = mapSharedDetails(details);

    return {
        ...sharedDetails,
        integrationCode: IntegrationCode.NetSuiteJournalEntry,
        integrationType: IntegrationType.NetSuite,
        reference: details.Reference,
        transactionNumber: details.Reference,
        transactionDate: details.TransactionDate,
        postingPeriod: mapReference(details.PostingPeriod),
        reversalDate: details.ReversalDate,
        reversalDefer: details.ReversalDefer,
        department: mapReference(details.Department),
        class: mapReference(details.Class),
        location: mapReference(details.Location),
        memo: details.Memo,
        debitTotal: details.DebitTotal,
        creditTotal: details.CreditTotal,
        url: details.Url,
        taxAmount: null,
        totalAmount: null,
        lines: (details.Lines || []).map(mapJournalEntryLines),
    };
};

const mapVendorDetails = (details?: backend.IntegrationsNetSuiteVendorDetailsAnswer): NetSuiteVendorDetails => {
    if (!details) {
        throw errorHelpers.formatError('[requestSchema.NetSuite] Vendor details is undefined.');
    }

    const primarySubsidiary = details.Subsidiaries.find(({ IsPrimary }) => IsPrimary);

    return {
        ...objectHelpers.pascalCaseToCamelCase(details),
        integrationCode: IntegrationCode.NetSuiteVendor,
        integrationType: IntegrationType.NetSuite,
        url: details.Url,
        currency: null,
        category: mapReference(details.Category),
        defaultExpenseAccount: mapReference(details.DefaultExpenseAccount),
        defaultPayablesAccount: mapReference(details.DefaultPayablesAccount),
        terms: mapReference(details.Terms),
        incoterm: mapReference(details.Incoterm),
        primaryCurrency: mapReference(details.Currency),
        primarySubsidiary: primarySubsidiary
            ? {
                  id: primarySubsidiary.Subsidiary.Id,
                  name: primarySubsidiary.Subsidiary.Name,
                  currencyInternalId: details.Currency?.Id,
              }
            : null,
        subsidiaries: details.Subsidiaries.map((subsidiary) => ({
            ...objectHelpers.pascalCaseToCamelCase(subsidiary),
            taxCode: mapReference(subsidiary.TaxCode),
        })),
        currencies: details.Currencies.map(mapReference).filter((currency) => currency !== null),
        addressLines: details.AddressLines.map((address) => ({
            ...objectHelpers.pascalCaseToCamelCase(address),
            defaultBilling: Boolean(address.DefaultBilling),
            defaultShipping: Boolean(address.DefaultShipping),
            country: address.Country ? mapReference(address.Country) : null,
        })),
        customFields: (details.CustomFields || []).map(mapCustomField),
    };
};

export const mapNetSuiteDetails = (request: any): NetSuiteRequestDetails => {
    switch (request.IntegrationCode) {
        case IntegrationCode.NetSuiteBill:
            return mapBillDetails(request.NetSuiteVendorBillDetails);

        case IntegrationCode.NetSuitePO:
            return mapPurchaseOrderDetails(request.NetSuitePurchaseOrderDetails, request.EmailToContact);

        case IntegrationCode.NetSuiteExpenseReport:
            return mapExpenseReportDetails(request.NetSuiteExpenseReportDetails);

        case IntegrationCode.NetSuiteSalesOrder:
            return mapSalesOrderDetails(request.NetSuiteSalesOrderDetails);

        case IntegrationCode.NetSuiteVRA:
            return mapVRADetails(request.NetSuiteVendorReturnAuthorizationDetails);

        default:
            throw errorHelpers.notSupportedError();
    }
};

export const mapNetSuiteRequestSpecifics = (request: backend.RequestAnswer): NetSuiteRequestSpecifics => {
    switch (request.IntegrationCode) {
        case IntegrationCode.NetSuiteBill:
            return {
                details: mapBillDetails(request.NetSuiteVendorBillDetails),
                integrationType: IntegrationType.NetSuite,
                integrationCode: request.IntegrationCode,
            };

        case IntegrationCode.NetSuitePO:
            return {
                details: mapPurchaseOrderDetails(request.NetSuitePurchaseOrderDetails, request.EmailToContact),
                integrationType: IntegrationType.NetSuite,
                integrationCode: request.IntegrationCode,
            };

        case IntegrationCode.NetSuiteExpenseReport:
            return {
                details: mapExpenseReportDetails(request.NetSuiteExpenseReportDetails),
                integrationType: IntegrationType.NetSuite,
                integrationCode: request.IntegrationCode,
            };

        case IntegrationCode.NetSuiteSalesOrder:
            return {
                details: mapSalesOrderDetails(request.NetSuiteSalesOrderDetails),
                integrationType: IntegrationType.NetSuite,
                integrationCode: request.IntegrationCode,
            };

        case IntegrationCode.NetSuiteVRA:
            return {
                details: mapVRADetails(request.NetSuiteVendorReturnAuthorizationDetails),
                integrationType: IntegrationType.NetSuite,
                integrationCode: request.IntegrationCode,
            };

        case IntegrationCode.NetSuiteBillPayment:
            return {
                details: mapBillPaymentDetails(request.NetSuiteBillPaymentDetails),
                integrationType: IntegrationType.NetSuite,
                integrationCode: request.IntegrationCode,
            };

        case IntegrationCode.NetSuiteInvoice:
            return {
                details: mapInvoiceDetails(request.NetSuiteInvoiceDetails),
                integrationType: IntegrationType.NetSuite,
                integrationCode: request.IntegrationCode,
            };

        case IntegrationCode.NetSuiteJournalEntry:
            return {
                details: mapJournalEntryDetails(request.NetSuiteJournalEntryDetails),
                integrationType: IntegrationType.NetSuite,
                integrationCode: request.IntegrationCode,
            };

        case IntegrationCode.NetSuiteVendor:
            return {
                details: mapVendorDetails(request.NetSuiteVendorDetails),
                integrationType: IntegrationType.NetSuite,
                integrationCode: request.IntegrationCode,
            };

        default:
            throw errorHelpers.notSupportedError();
    }
};
