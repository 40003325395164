import { Box, Grid, Popup, Table, Text } from '@approvalmax/ui/src/components';
import { intl } from '@approvalmax/utils';
import { memo } from 'react';

import { BankAccountInfo } from '../../../../card/Toolbar/components/PayWithAmax/components/BankAccountInfo/BankAccountInfo';
import { BankAccountInfoLoading } from '../../../../card/Toolbar/components/PayWithAmax/components/BankAccountInfo/BankAccountInfo.loading';
import { tableColumns } from './TransactionDetailsPopupContent.config';
import { useTransactionHistory } from './TransactionDetailsPopupContent.hooks';
import { Loading } from './TransactionDetailsPopupContent.loading';
import { messages } from './TransactionDetailsPopupContent.messages';
import { TransactionDetailsPopupContentProps } from './TransactionDetailsPopupContent.types';

export const TransactionDetailsPopupContent = memo<TransactionDetailsPopupContentProps>((props) => {
    const { companyId, requestId, billRequestId, bankAccountData, amount, currency } = props;

    const { transactionHistory, friendlyName, isFetching } = useTransactionHistory({
        companyId,
        requestId,
        billRequestId,
    });

    const hasHistoryItems = transactionHistory.length > 0;

    return (
        <>
            <Popup.Header title={messages.transactionDetails} />

            <Popup.Body spacing='32'>
                <Grid gridTemplateColumns='auto 204px' columnGap={24} alignItems='flex-start'>
                    {isFetching ? (
                        <Loading />
                    ) : (
                        <Grid rowGap={12}>
                            <Text font='label' fontSize='large' fontWeight='medium'>
                                {friendlyName}
                            </Text>

                            {hasHistoryItems ? (
                                <Table
                                    data={transactionHistory}
                                    columns={tableColumns}
                                    headerColor='white100'
                                    width='100%'
                                />
                            ) : (
                                <Text color='midnight80' font='body' fontSize='large' fontWeight='regular'>
                                    {messages.emptyContent}
                                </Text>
                            )}
                        </Grid>
                    )}

                    <Grid gap={12} padding={hasHistoryItems ? '44 0 0 0' : '0'}>
                        <Text color='midnight100' font='body' fontSize='small' fontWeight='medium'>
                            {messages.paymentDetails}
                        </Text>

                        <Box color='midnight20' spacing='12' radius='xsmall'>
                            {bankAccountData ? (
                                <BankAccountInfo data={bankAccountData} balance={null} />
                            ) : (
                                <BankAccountInfoLoading />
                            )}
                        </Box>

                        <Grid gridTemplateColumns='max-content auto' columnGap={12}>
                            <Text color='midnight70' font='label' fontSize='medium'>
                                {messages.total}
                            </Text>

                            <Text
                                color='midnight100'
                                font='label'
                                fontSize='medium'
                                fontWeight='medium'
                                textAlign='right'
                            >
                                {intl.formatCurrency(amount, currency)}
                            </Text>
                        </Grid>
                    </Grid>
                </Grid>
            </Popup.Body>
        </>
    );
});

TransactionDetailsPopupContent.displayName = 'TransactionDetailsPopupContent';
