import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { integrationApiPaths } from '../paths';
import { CreateXeroBankAccounts } from './useCreateXeroBankAccounts.types';

export const useCreateXeroBankAccounts = (
    mutationOptions?: RequestMutationOptions<CreateXeroBankAccounts>,
    otherOptions?: { skipInvalidateQueries?: true }
) => {
    return useMutate(integrationApiPaths.xeroBankAccounts, {
        skipInvalidateQueries: otherOptions?.skipInvalidateQueries,
        mutationOptions,
        apiVersion: 'v1',
    });
};
