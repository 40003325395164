import { domain } from 'modules/data';

import { useBillLinkedToAirwallexBatchPayment } from '../../../hooks/useBillLinkedToAirwallexBatchPayment';
import { useBillLinkedToAmaxPayXeroBatchPayment } from '../../../hooks/useBillLinkedToAmaxPayXeroBatchPayment';
import { useBillLinkedToXeroBatchPayment } from '../../../hooks/useBillLinkedToXeroBatchPayment';

export const useBillByIntegration = ({
    companyId,
    billId,
    batchPaymentRequestId,
    integrationCode,
    enabled,
}: {
    companyId: string | undefined;
    billId: string | undefined;
    batchPaymentRequestId: string | undefined;
    integrationCode: domain.IntegrationCode | null | undefined;
    enabled: boolean;
}) => {
    const {
        request: airwallexRequest,
        requestDetails: airwallexRequestDetails,
        matchingData: airwallexMatchingData,
    } = useBillLinkedToAirwallexBatchPayment(
        {
            companyId: companyId ?? '',
            billId: billId ?? '',
            batchPaymentRequestId: batchPaymentRequestId ?? '',
        },
        { enabled: enabled && integrationCode === domain.IntegrationCode.XeroAirwallexBatchPayment }
    );

    const {
        request: amaxPayXeroRequest,
        requestDetails: amaxPayXeroRequestDetails,
        matchingData: amaxPayXeroMatchingData,
    } = useBillLinkedToAmaxPayXeroBatchPayment(
        {
            path: {
                companyId: companyId ?? '',
                billRequestId: billId ?? '',
                batchPaymentRequestId: batchPaymentRequestId ?? '',
            },
        },
        { enabled: enabled && integrationCode === domain.IntegrationCode.XeroAmaxPayBatchPayment }
    );
    const {
        request: xeroRequest,
        requestDetails: xeroRequestDetails,
        matchingData: xeroMatchingData,
    } = useBillLinkedToXeroBatchPayment(
        {
            path: {
                companyId: companyId ?? '',
                billRequestId: billId ?? '',
                batchPaymentRequestId: batchPaymentRequestId ?? '',
            },
        },
        { enabled: enabled && integrationCode === domain.IntegrationCode.XeroBillBatchPayment }
    );

    if (integrationCode === domain.IntegrationCode.XeroAirwallexBatchPayment) {
        return {
            request: airwallexRequest,
            requestDetails: airwallexRequestDetails,
            matchingData: airwallexMatchingData,
        };
    }

    if (integrationCode === domain.IntegrationCode.XeroAmaxPayBatchPayment) {
        return {
            request: amaxPayXeroRequest,
            requestDetails: amaxPayXeroRequestDetails,
            matchingData: amaxPayXeroMatchingData,
        };
    }

    return {
        request: xeroRequest,
        requestDetails: xeroRequestDetails,
        matchingData: xeroMatchingData,
    };
};
