import { mods } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import { PriceCheckIndicator, WarningLevel } from 'modules/request';
import styled from 'styled-components';

export const UnitPriceCell = styled(PriceCheckIndicator)`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const UnitPriceIndicatorCell = styled.div<StyledMods<{ warningLevel: WarningLevel }>>`
    margin-left: 3px;
    border-radius: 50%;
    width: 5px;
    height: 5px;
    ${mods('warningLevel', WarningLevel.Yellow)`
        background: #ffe688;
    `}
    ${mods('warningLevel', WarningLevel.Orange)`
        background: #f0984f;
    `}

    ${mods('warningLevel', WarningLevel.Red)`
        background: #cb4e30;
    `}
`;

export const DescriptionCell = styled.div`
    word-break: break-word;
    white-space: pre-line;
`;
