import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { integrationApiPaths } from '../paths';
import { FinishDearIntegration } from './useFinishDearIntegration.types';

export const useFinishDearIntegration = (mutationOptions?: RequestMutationOptions<FinishDearIntegration>) => {
    return useMutate(integrationApiPaths.finishDearIntegration, {
        mutationOptions,
        apiVersion: 'v1',
    });
};
