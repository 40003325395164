import { Button } from '@approvalmax/ui';
import { FC, memo } from 'react';

import { useResendQuote } from './ResendQuote.hooks';
import { messages } from './ResendQuote.messages';
import { Root } from './ResendQuote.styles';
import { ResendQuoteProps } from './ResendQuote.types';

const ResendQuote: FC<ResendQuoteProps> = memo((props) => {
    const { request, className } = props;

    const { isLoading, onResendQuote } = useResendQuote(request);

    return (
        <Root className={className}>
            <Button
                disabled={isLoading}
                colorTheme='brand'
                theme='primary'
                padding='narrow15'
                execute={onResendQuote}
                title={messages.resendButton}
            >
                {messages.resendButton}
            </Button>
        </Root>
    );
});

export default ResendQuote;
