import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { ocrApiPaths } from '../paths';
import { GetOcr } from './useGetOcr.types';

export const useGetOcr = (params?: RequestParams<GetOcr>, queryOptions?: RequestQueryOptions<GetOcr>) => {
    return useGet(ocrApiPaths.ocr, {
        params,
        queryOptions,
        apiVersion: 'v2',
    });
};
