import { SearchIcon } from '@approvalmax/ui';
import {
    BaseItem,
    Box,
    Button,
    Flex,
    Form,
    Grid,
    Popup,
    Table,
    TableCheckType,
    Text,
    TextField,
} from '@approvalmax/ui/src/components';
import { compareHelpers, miscHelpers } from '@approvalmax/utils';
import { constants, selectors } from 'modules/common';
import { domain } from 'modules/data';
import { asMutable } from 'modules/immutable';
import { useDispatch, useSelector } from 'modules/react-redux';
import { closeActivePopup, getNewRequestPopupData } from 'modules/request';
import { memo, useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { routingService } from 'services/routing';
import { BulkCreationBanner } from 'shared/components';
import { isOcrEnabledForTemplate } from 'shared/helpers';
import { getPath, Path } from 'urlBuilder';

import { TagBadge } from '../TagBadge/TagBadge';
import { checkboxColumnCover, columnsData, filterTextKey } from './Content.constants';
import { messages } from './Content.messages';
import { CompaniesData, FormValues } from './Content.types';

export const Content = memo(() => {
    const dispatch = useDispatch();
    const form = useForm<FormValues>();
    const filterText = form.watch(filterTextKey);
    const [selectedTemplateId, setSelectedTemplateId] = useState('');
    const [selectedCompanyId, setSelectedCompanyId] = useState('');

    const allCompanies = useSelector(selectors.company.getCompanies);
    const newRequestPopupData = useSelector(getNewRequestPopupData);
    const creatableTemplates = useSelector((state) => {
        return selectors.meta.getCreatableTemplatesByCompanyId(state, newRequestPopupData?.activeCompanyId);
    });
    const selectedTemplate = useSelector((state) =>
        selectedTemplateId ? selectors.template.getTemplateById(state, selectedTemplateId) : null
    );

    const filterTextFormat = filterText?.toLowerCase();

    const selectTemplate = useCallback((templateId: string, companyId: string) => {
        setSelectedTemplateId(templateId);
        setSelectedCompanyId(companyId);
    }, []);

    const createRequest = useCallback(
        (templateId?: string, companyId?: string) => {
            if (templateId && companyId) {
                selectTemplate(templateId, companyId);
            } else {
                templateId = selectedTemplateId;
                companyId = selectedCompanyId;
            }

            dispatch(closeActivePopup());
            routingService.push(getPath(Path.newRequest, templateId, companyId));
        },
        [dispatch, selectTemplate, selectedCompanyId, selectedTemplateId]
    );

    const companiesData = useMemo(
        () =>
            creatableTemplates.reduce<CompaniesData>((accumulator, template) => {
                const company = allCompanies.find((company) => company.id === template.companyId)!;

                miscHelpers.invariant(company, 'The company must exist in entities');

                const disconnectedFromAirwallex =
                    template.integrationCode &&
                    constants.airwallexConstants.AIRWALLEX_INTEGRATION_CODES.includes(template.integrationCode) &&
                    !company?.airwallexIntegration?.isConnected;

                accumulator[template.id] = {
                    id: company.id,
                    displayName: company.displayName,
                    isReadonly: company.isReadonly,
                    disconnectedFromAirwallex: !!disconnectedFromAirwallex,
                    betaFeatures: company.betaFeatures,
                    licenseFeatures: company.licenseFeatures,
                    licenseProductPlan: company.licenseProductPlan,
                    allFeaturesTrialStatus: company.allFeaturesTrialStatus,
                };

                return accumulator;
            }, {}),
        [allCompanies, creatableTemplates]
    );

    const data = useMemo(
        () =>
            asMutable(creatableTemplates)
                .filter((template) => {
                    if (!filterTextFormat) {
                        return true;
                    }

                    return (
                        template.displayName.toLowerCase().includes(filterTextFormat) ||
                        companiesData[template.id].displayName.toLowerCase().includes(filterTextFormat)
                    );
                })
                .sort((templateX, templateY) => {
                    if (templateX.companyId !== templateY.companyId) {
                        return compareHelpers.stringComparator2AscI(
                            companiesData[templateX.id].displayName,
                            companiesData[templateY.id].displayName
                        );
                    }

                    return compareHelpers.stringComparator2AscI(templateX.displayName, templateY.displayName);
                }),
        [creatableTemplates, companiesData, filterTextFormat]
    );

    const showBulkCreationBanner =
        selectedTemplate?.integrationCode &&
        selectedTemplateId &&
        isOcrEnabledForTemplate(companiesData[selectedTemplateId], selectedTemplate.integrationCode);

    const onCreateRequestButtonClick = useCallback(() => {
        createRequest();
    }, [createRequest]);

    const handleRowClick = useCallback(
        (row: BaseItem) => {
            selectTemplate(row.id, companiesData[row.id].id);
        },
        [selectTemplate, companiesData]
    );

    const allIntegrations = useSelector(selectors.integration.getIntegrations);

    const rowData = useMemo(
        () =>
            data.map((item) => {
                const disabledIntegration = allIntegrations.find(
                    (integration) =>
                        integration.companyId === item.companyId &&
                        integration.status !== domain.IntegrationStatus.Connected
                );

                const companyData = companiesData[item.id];

                const inactiveTemplate =
                    (disabledIntegration && item.integrationCode !== null) ||
                    companyData.isReadonly ||
                    companyData.disconnectedFromAirwallex;

                const showAmCaptureBadge =
                    item.integrationCode === domain.IntegrationCode.XeroBill &&
                    !disabledIntegration &&
                    !inactiveTemplate &&
                    (companiesData[item.id].betaFeatures.includes(domain.CompanyBetaFeature.XeroOcr) ||
                        companiesData[item.id].licenseFeatures.includes(domain.CompanyLicenseFeature.Capture));

                const integrationDisconnected =
                    (disabledIntegration && inactiveTemplate) || companyData.disconnectedFromAirwallex;

                return {
                    id: item.id,
                    type: (
                        <Text
                            font='body'
                            fontSize='small'
                            fontWeight='medium'
                            color={integrationDisconnected ? 'midnight60' : 'midnight100'}
                        >
                            {item.displayName}
                        </Text>
                    ),
                    company: (
                        <Text
                            font='body'
                            fontSize='small'
                            color={integrationDisconnected ? 'midnight60' : 'midnight70'}
                        >
                            {companiesData[item.id].displayName}
                        </Text>
                    ),

                    tag: (
                        <TagBadge
                            showAmCaptureBadge={showAmCaptureBadge}
                            integrationDisconnected={integrationDisconnected}
                        />
                    ),
                    integrationDisconnected,
                };
            }),
        [data, allIntegrations, companiesData]
    );

    const getIsDisabledRow = useCallback((item: (typeof rowData)[number]) => item.integrationDisconnected, []);

    return (
        <>
            <Popup.Header
                title={messages.title}
                actions={
                    <Button
                        color='blue80'
                        type='submit'
                        disabled={!selectedTemplateId}
                        onClick={onCreateRequestButtonClick}
                    >
                        {messages.headerButton}
                    </Button>
                }
            />

            <Popup.Body>
                <Grid
                    columnGap={showBulkCreationBanner ? 16 : 0}
                    gridTemplateColumns='auto max-content'
                    overflowY='hidden'
                >
                    <Flex direction='column' spacing='16 0' container>
                        <Form form={form}>
                            <TextField
                                placeholder={messages.searchPlaceholder}
                                startIcon={<SearchIcon />}
                                name='filterText'
                                size='small'
                            />
                        </Form>

                        <Box overflowY='auto' maxHeight='calc(100vh - 244px)' minHeight='200px'>
                            <Table
                                columns={columnsData}
                                data={rowData}
                                checkType={TableCheckType.radio}
                                checkedItems={[selectedTemplateId]}
                                hideHeader={true}
                                bordered={false}
                                divider={false}
                                rounded={true}
                                onRowClick={handleRowClick}
                                checkboxColumnCover={checkboxColumnCover}
                                getIsDisabledRow={getIsDisabledRow}
                                width='100%'
                            />
                        </Box>
                    </Flex>

                    {showBulkCreationBanner && (
                        <BulkCreationBanner
                            companyId={companiesData[selectedTemplateId].id}
                            templateIntegrationCode={selectedTemplate?.integrationCode}
                        />
                    )}
                </Grid>
            </Popup.Body>
        </>
    );
});

Content.displayName = 'Content';
