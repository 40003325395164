import FieldEntry from 'pages/requestList/components/FieldEntry';
import SectionContainer from 'pages/requestList/components/SectionContainer';
import { memo } from 'react';

import { messages } from './XeroPoDeliveryDetails.messages';
import { Column1, Column2, Column3, DetailsSection } from './XeroPoDeliveryDetails.styles';
import { XeroPoDeliveryDetailsProps } from './XeroPoDeliveryDetails.types';

const XeroPoDeliveryDetails = memo<XeroPoDeliveryDetailsProps>((props) => {
    const { details } = props;

    const { address, attentionTo, instructions, phone } = details.delivery;

    if (!address && !attentionTo && !instructions && !phone) {
        return null;
    }

    return (
        <SectionContainer text={messages.title}>
            <DetailsSection>
                <Column1>
                    <FieldEntry qa='delivery-address-field' title={messages.fieldAddress} value={address} />
                </Column1>

                <Column2>
                    <FieldEntry qa='delivery-attentionTo-field' title={messages.fieldAttentionTo} value={attentionTo} />

                    <FieldEntry qa='delivery-phone-field' title={messages.fieldPhone} value={phone} />
                </Column2>

                <Column3>
                    <FieldEntry
                        qa='delivery-instructions-field'
                        title={messages.fieldInstructions}
                        value={instructions}
                    />
                </Column3>
            </DetailsSection>
        </SectionContainer>
    );
});

export default XeroPoDeliveryDetails;
