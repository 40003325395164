import './cacheManagementPanel.scss';

import { TextButton } from '@approvalmax/ui';
import { arrayHelpers, compareHelpers, dateTimeHelpers } from '@approvalmax/utils';
import { useQueryClient } from '@tanstack/react-query';
import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import { XeroIcon } from 'modules/sprites';
import moment from 'moment';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import bemFactory from 'react-bem-factory';
import { useDispatch, useSelector } from 'react-redux';
import { useMount, useUnmount } from 'react-use';
import { companiesApiPaths } from 'shared/data/v1';

import { getIntegrationCacheStatus, updateIntegrationCache } from '../../../actions';
import { cacheTypeNames, lastSyncDateTimeFormat } from './CacheManagementPanel.constants';
import { messages } from './CacheManagementPanel.messages';
import { CacheManagementPanelProps } from './CacheManagementPanel.types';

const bem = bemFactory.block('wfc-cache-management-panel');
const qa = bemFactory.qa('wfc-cache-management-panel');

const CacheManagementPanel = memo<CacheManagementPanelProps>((props) => {
    const { companyId } = props;

    const queryClient = useQueryClient();
    const unmounted = useRef(false);
    const hasBackgroundChecker = useRef(false);
    const backgroundCheckerTimerIdRef = useRef<NodeJS.Timeout>();
    const dispatch = useDispatch();
    const company = useSelector((state: State) => selectors.company.getCompanyById(state, companyId));
    const integrationCacheStatus = useSelector(
        (state: State) =>
            company.integration && selectors.integration.getIntegrationCacheItems(state, company.integration)
    );
    const [shouldSyncCacheStatus, setShouldSyncCacheStatus] = useState(false);

    useUnmount(() => {
        unmounted.current = true;
        backgroundCheckerTimerIdRef.current && clearTimeout(backgroundCheckerTimerIdRef.current);
    });

    const invalidateSelectCompanyQuery = useCallback(() => {
        queryClient.invalidateQueries([companiesApiPaths.select, { companyId }]);
    }, [companyId, queryClient]);

    useMount(() => {
        if (!company.integrationId) {
            return;
        }

        dispatch(
            getIntegrationCacheStatus(companyId, company.integrationId, () => {
                invalidateSelectCompanyQuery();
                setShouldSyncCacheStatus(true);
            })
        );
    });

    const checkCacheStatus = useCallback(
        (force = false, timeout = 2000) => {
            // Checks the status every <timeout>ms. Stops the check if no rows are in progress
            if (hasBackgroundChecker.current) {
                return;
            }

            if (unmounted.current) {
                hasBackgroundChecker.current = false;

                return;
            }

            if (
                !force &&
                integrationCacheStatus &&
                !integrationCacheStatus.some((status) => status.loadingInProgress)
            ) {
                hasBackgroundChecker.current = false;

                return;
            }

            backgroundCheckerTimerIdRef.current = setTimeout(() => {
                if (!company.integrationId) {
                    return;
                }

                dispatch(
                    getIntegrationCacheStatus(companyId, company.integrationId, () => {
                        hasBackgroundChecker.current = false;
                        invalidateSelectCompanyQuery();
                        setShouldSyncCacheStatus(true);
                    })
                );
            }, timeout);

            hasBackgroundChecker.current = true;
        },
        [company, companyId, integrationCacheStatus, dispatch, invalidateSelectCompanyQuery]
    );

    useEffect(() => {
        if (shouldSyncCacheStatus) {
            setShouldSyncCacheStatus(false);

            checkCacheStatus();
        }
    }, [shouldSyncCacheStatus, checkCacheStatus]);

    const syncNow = useCallback(
        (cacheType: domain.IntegrationCacheType) => {
            if (!company.integrationId) {
                return;
            }

            dispatch(updateIntegrationCache(companyId, company.integrationId, [cacheType]));
            invalidateSelectCompanyQuery();
            checkCacheStatus(true, 1000);
        },
        [company, companyId, dispatch, invalidateSelectCompanyQuery, checkCacheStatus]
    );

    const syncAll = () => {
        if (!integrationCacheStatus || !integrationCacheStatus.length || !company.integrationId) {
            return;
        }

        const cacheTypes = integrationCacheStatus.map((status) => status.cacheType);

        dispatch(updateIntegrationCache(companyId, company.integrationId, cacheTypes));
        invalidateSelectCompanyQuery();
        checkCacheStatus(true, 1000);
    };

    const rows = useMemo(
        () =>
            arrayHelpers
                .arraySort(integrationCacheStatus || [], (a, b) =>
                    compareHelpers.stringComparator2AscI(cacheTypeNames[a.cacheType], cacheTypeNames[b.cacheType])
                )
                .map((status) => {
                    const inProgress = status.loadingInProgress;
                    const neverStarted = !status.lastStartDate;
                    const displayName = cacheTypeNames[status.cacheType];

                    return (
                        <div
                            className={bem('table-row', { 'in-progress': inProgress })}
                            key={status.cacheType}
                            data-qa={qa('row')}
                            data-qa-id={status.cacheType}
                            data-qa-name={displayName}
                        >
                            <div className={bem('table-cell')}>{displayName}</div>

                            <div className={bem('table-cell', 'last-sync')} data-qa={qa('last-sync')}>
                                {status.lastStartDate
                                    ? moment(status.lastStartDate).format(lastSyncDateTimeFormat)
                                    : '-'}
                            </div>

                            <div className={bem('table-cell')}>
                                {(neverStarted && '-') ||
                                    (inProgress && messages.inProgressText) ||
                                    dateTimeHelpers.formatDuration(
                                        dateTimeHelpers.toDuration(status.lastStartDate!, status.lastEndDate!)
                                    )}
                            </div>

                            <div className={bem('table-cell')}>
                                {inProgress ? (
                                    <div className={bem('cell-loading')} />
                                ) : (
                                    <TextButton execute={() => syncNow(status.cacheType)} qa={qa('sync-now-button')}>
                                        {messages.syncNowText}
                                    </TextButton>
                                )}
                            </div>
                        </div>
                    );
                }),
        [integrationCacheStatus, syncNow]
    );

    return (
        <div className={bem()} data-qa={qa()}>
            <div className={bem('header')}>
                <div className={bem('header-title')}>
                    <XeroIcon width={30} height={30} className={bem('header-title-logo')} />

                    <div>{messages.headerText}</div>
                </div>

                <div className={bem('header-button')}>
                    <TextButton
                        disabled={
                            !integrationCacheStatus ||
                            !integrationCacheStatus.length ||
                            integrationCacheStatus.some((status) => status.loadingInProgress)
                        }
                        execute={syncAll}
                    >
                        {messages.syncAll}
                    </TextButton>
                </div>
            </div>

            <div className={bem('table-header')}>
                <div className={bem('table-header-col')}>{messages.fieldCol}</div>

                <div className={bem('table-header-col', 'last-sync')}>{messages.lastSyncCol}</div>

                <div className={bem('table-header-col')}>{messages.syncDurationCol}</div>

                <div className={bem('table-header-col')} />
            </div>

            <div className={bem('table-body')}>{rows}</div>
        </div>
    );
});

export default CacheManagementPanel;
