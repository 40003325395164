import { ExternalLink } from '@approvalmax/ui';
import { Flex, Spacing, Text, Tooltip } from '@approvalmax/ui/src/components';
import { InfoIcon } from 'modules/sprites';
import { FC, memo } from 'react';

import { ConnectionButton } from '..';
import { messages } from './ConnectToOrganisationButton.messages';
import { ConnectToOrganisationButtonProps } from './ConnectToOrganisationButton.types';

const ConnectToOrganisationButton: FC<ConnectToOrganisationButtonProps> = memo((props) => {
    const { logo, systemName, titleSystemName, width, isLoading, onClick } = props;

    return (
        <ConnectionButton
            logo={logo}
            title={messages.connectToOrganisation({ systemName: titleSystemName || systemName })}
            width={width}
            alignLogoTop
            isLoading={isLoading}
            onClick={onClick}
        >
            <Text font='body' fontSize='small' spacing='20 0 0'>
                <Text font='body' fontWeight='bold'>
                    {messages.connectToOrgHeader({ systemName })}
                </Text>

                <Spacing height={8} />

                <Flex spacing='8' wrap={false} alignItems='start'>
                    <div onClick={(e) => e.stopPropagation()}>
                        <Tooltip
                            activator={
                                <Flex shrink={0}>
                                    <Spacing height={4} />

                                    <InfoIcon width={10} height={10} color='midnight70' />
                                </Flex>
                            }
                            width={400}
                            placement='top'
                        >
                            {messages.tooltip1}
                        </Tooltip>
                    </div>

                    <div>{messages.connectToOrgDescribe1({ systemName })}</div>
                </Flex>

                <Spacing height={8} />

                <Flex spacing='8' wrap={false} alignItems='start'>
                    <div onClick={(e) => e.stopPropagation()}>
                        <Tooltip
                            activator={
                                <Flex shrink={0}>
                                    <Spacing height={4} />

                                    <InfoIcon width={10} height={10} color='midnight70' />
                                </Flex>
                            }
                            width={400}
                        >
                            {messages.tooltip2({
                                trustCenterLink: (chunks: any) => (
                                    <ExternalLink href='https://security.approvalmax.com'>{chunks}</ExternalLink>
                                ),
                            })}
                        </Tooltip>
                    </div>

                    <div>{messages.connectToOrgDescribe2}</div>
                </Flex>
            </Text>
        </ConnectionButton>
    );
});

export default ConnectToOrganisationButton;
