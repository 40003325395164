import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.requestList.xero.XeroQuoteFields', {
    dateFieldText: 'Date',
    expiryDateFieldText: 'Expiry',
    quoteNumberFieldText: 'Quote number',
    referenceFieldText: 'Reference',
    titleFieldText: 'Title',
    summaryFieldText: 'Summary',
    termsFieldText: 'Terms',
    brandingThemeFieldText: 'Theme',
    emptyQuoteNumber: 'Allocated on approval',
});
