import { domain } from 'modules/data';
import { useMemo } from 'react';

import { UseCanCreatePayment } from './CreatePaymentFromBill.types';

export const useCanCreatePayment = (params: UseCanCreatePayment) => {
    const { request, workflows, userEmail } = params;

    const {
        details: { isStatusPushPending, contact, status: xeroObjectStatus },
        statusV2,
        currency,
    } = request;

    return useMemo(() => {
        const isApproved = statusV2 === domain.RequestStatusV2.Approved;

        const isAuthorised = xeroObjectStatus === domain.XeroObjectStatus.Authorised;

        // Kostyl - if has details.paymentDetails means that it's paid in Dext
        const isDextPaid = !!request.details.paymentDetails;

        const hasPositiveAmountDue = request.amount > 0;

        const amaxPayWorkflow = workflows.find(
            (workflow) => workflow.integrationCode === domain.IntegrationCode.XeroAmaxPayBatchPayment
        );

        const isAmaxPayWorkflowEnabled = !!amaxPayWorkflow?.enabled;

        const amaxPaySubmitter = amaxPayWorkflow?.submitterMatrix.find((submitter) => submitter.lineId === userEmail);

        const amaxPayXeroSupplierCondition = amaxPaySubmitter?.rules
            .flatMap((rule) => rule.conditions)
            .find((condition) => condition.fieldSystemPurpose === domain.FieldSystemPurpose.XeroSupplier);

        const conditionAllowsContact =
            (amaxPayXeroSupplierCondition?.conditionType === domain.ConditionType.ExactValuesCondition &&
                amaxPayXeroSupplierCondition.exactValues.some((exactValue) => exactValue.id === contact?.id)) ||
            (amaxPayXeroSupplierCondition?.conditionType === domain.ConditionType.NegativeExactValuesCondition &&
                amaxPayXeroSupplierCondition.exactValues.every((exactValue) => exactValue.id !== contact?.id));

        const amaxPaySubmitterCanCreateRequestWithSelectedContact =
            !amaxPayXeroSupplierCondition || conditionAllowsContact;

        // @see https://approvalmax.atlassian.net/browse/AM-18295
        const currencyAllowed = ['GBP'].includes(currency);

        return (
            isAmaxPayWorkflowEnabled &&
            isApproved &&
            isAuthorised &&
            !isDextPaid &&
            !isStatusPushPending &&
            amaxPaySubmitter &&
            amaxPaySubmitterCanCreateRequestWithSelectedContact &&
            hasPositiveAmountDue &&
            currencyAllowed
        );
    }, [
        contact?.id,
        currency,
        isStatusPushPending,
        request.amount,
        request.details.paymentDetails,
        statusV2,
        userEmail,
        workflows,
        xeroObjectStatus,
    ]);
};
