import { mods } from '@approvalmax/theme';
import { Button } from '@approvalmax/ui/src/components';
import { intl, mathService } from '@approvalmax/utils';
import { StyledMods } from '@styled-kit/mods';
import { selectors } from 'modules/common';
import AttachmentsDropdown from 'modules/components/Attachment/AttachmentsDropdown';
import { backend, domain } from 'modules/data';
import { AmountDueIndicator, BankAccountCheckIndicator } from 'modules/request';
import moment from 'moment';
import { ColumnDefinition } from 'pages/requestList/selectors/types/ColumnDefinition';
import { defineMessages } from 'react-intl';
import styled from 'styled-components';

const TextBlock = styled.div`
    max-width: 200px;
    white-space: break-spaces;
`;

const BankAccount = styled.div<StyledMods<{ hasWarning: boolean }>>`
    display: flex;
    align-items: center;
    max-width: 200px;
    word-break: break-word;

    ${mods('hasWarning', true)`
        font-weight: bold;
        color: #a3341a;
    `}
`;

const i18nPrefix =
    'requestList/containers/xero/BillBatchPaymentRequestCard/components/BillsInformationSection/tableConfig';

const messages = defineMessages({
    itemName: {
        id: `${i18nPrefix}.itemName`,
        defaultMessage: 'Items',
    },
    supplierName: {
        id: `${i18nPrefix}.supplierName`,
        defaultMessage: 'Supplier',
    },
    billsReferenceName: {
        id: `${i18nPrefix}.billsReferenceName`,
        defaultMessage: 'Bills Reference',
    },
    dateName: {
        id: `${i18nPrefix}.dateName`,
        defaultMessage: 'Date',
    },
    dueDateName: {
        id: `${i18nPrefix}.dueDateName`,
        defaultMessage: 'Due Date',
    },
    amountDueName: {
        id: `${i18nPrefix}.amountDueName`,
        defaultMessage: 'Amount Due',
    },
    payment: {
        id: `${i18nPrefix}.payment`,
        defaultMessage: 'Payment {currency}',
    },
    code: {
        id: `${i18nPrefix}.code`,
        defaultMessage: 'Code',
    },
    particulars: {
        id: `${i18nPrefix}.particulars`,
        defaultMessage: 'Particulars',
    },
    reference: {
        id: `${i18nPrefix}.reference`,
        defaultMessage: 'Reference',
    },
    details: {
        id: `${i18nPrefix}.details`,
        defaultMessage: 'Details',
    },
    bankAccountName: {
        id: `${i18nPrefix}.bankAccountName`,
        defaultMessage: 'Bank account',
    },
});

interface ColumnOptions {
    currency: string;
    countryCode: backend.OrganisationVersion;
    onSelectBill: (xeroBatchPaymentInvoiceId: string) => void;
    onSelectAttachment: (
        attachmentId: string,
        xeroBatchPaymentInvoiceId: string,
        xeroBatchPaymentInvoiceName: string
    ) => void;
    activeAttachmentId?: string;
}

export const getColumnDefinitions = ({
    currency,
    countryCode,
    onSelectBill,
    onSelectAttachment,
    activeAttachmentId,
}: ColumnOptions): ColumnDefinition<domain.PaymentItem>[] => {
    const columns: ColumnDefinition<domain.PaymentItem>[] = [
        {
            id: 'item',
            name: intl.formatMessage(messages.itemName),
            value: (li) => li.friendlyName,
            minWidth: 150,
            cell: (value, li) => (
                <Button
                    color='transparent'
                    uppercase={false}
                    size='small'
                    onClick={() => onSelectBill(li.xeroBillInvoiceRequestId)}
                >
                    {li.friendlyName}
                </Button>
            ),
        },
        {
            id: 'supplier',
            name: intl.formatMessage(messages.supplierName),
            minWidth: 150,
            value: (li) => li.contact.text,
            cell: (value) => <TextBlock>{value}</TextBlock>,
        },
        {
            id: 'billsReference',
            name: intl.formatMessage(messages.billsReferenceName),
            value: (li) => li.xeroBillInvoiceReference || '—',
            cell: (value) => <TextBlock>{value}</TextBlock>,
        },
        {
            id: 'date',
            name: intl.formatMessage(messages.dateName),
            minWidth: 80,
            value: (li) => moment.utc(li.date).format('ll'),
        },
        {
            id: 'dueDate',
            name: intl.formatMessage(messages.dueDateName),
            minWidth: 80,
            value: (li) => moment.utc(li.dueDate).format('ll'),
        },
        {
            id: 'amount',
            name: intl.formatMessage(messages.amountDueName),
            value: (li) =>
                intl.formatNumber(
                    mathService.subtract(
                        mathService.subtract(
                            mathService.subtract(li.amountDue, li.amountOnApproval),
                            li.amountAwaitingPayment
                        ),
                        li.amountInProcessing
                    )
                ),
            alignRight: true,
            hideTitle: true,
            cell: (val, li) => (
                <AmountDueIndicator
                    currency={li.currency}
                    amountOnApproval={li.amountOnApproval}
                    amountInProcessing={li.amountInProcessing}
                    amountAwaitingPayment={li.amountAwaitingPayment}
                >
                    {val}
                </AmountDueIndicator>
            ),
        },
        {
            id: 'payment',
            name: intl.formatMessage(messages.payment, { currency }),
            value: (li) => intl.formatNumber(li.amount) || '—',
            alignRight: true,
        },
    ];

    if (selectors.field.getSupportRegionalFieldByCountryCode(countryCode, domain.RegionalField.Details)) {
        columns.push({
            id: 'details',
            name: intl.formatMessage(messages.details),
            value: (li) => li.details || '—',
        });
    }

    if (selectors.field.getSupportRegionalFieldByCountryCode(countryCode, domain.RegionalField.Particulars)) {
        columns.push({
            id: 'particulars',
            name: intl.formatMessage(messages.particulars),
            value: (li) => li.particulars || '—',
        });
    }

    if (selectors.field.getSupportRegionalFieldByCountryCode(countryCode, domain.RegionalField.Code)) {
        columns.push({
            id: 'code',
            name: intl.formatMessage(messages.code),
            value: (li) => li.code || '—',
        });
    }

    if (selectors.field.getSupportRegionalFieldByCountryCode(countryCode, domain.RegionalField.Reference)) {
        columns.push({
            id: 'reference',
            name: intl.formatMessage(messages.reference),
            value: (li) => li.reference || '—',
        });
    }

    columns.push(
        {
            id: 'bankAccount',
            name: intl.formatMessage(messages.bankAccountName),
            minWidth: 100,
            value: (li) => li.bankAccount || '—',
            cell: (val, li) => (
                <BankAccountCheckIndicator
                    actualBankAccount={li.bankAccount || '—'}
                    expectedBankAccount={li.defaultBankAccount || '—'}
                >
                    {(hasWarning) => <BankAccount $hasWarning={hasWarning}>{val}</BankAccount>}
                </BankAccountCheckIndicator>
            ),
        },
        {
            id: 'attachments',
            name: '',
            value: (li) => String(li.attachments.length),
            cell: (val, li) =>
                li.attachments.length > 0 ? (
                    <AttachmentsDropdown
                        attachments={li.attachments}
                        activeAttachmentId={activeAttachmentId}
                        onSelectAttachment={(attachmentId: string) =>
                            onSelectAttachment(attachmentId, li.xeroBillInvoiceRequestId, li.friendlyName)
                        }
                    />
                ) : null,
        }
    );

    return columns;
};
