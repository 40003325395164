import './activitySection.scss';

import { Guid } from '@approvalmax/types';
import { TextButton } from '@approvalmax/ui';
import { domHelpers, errorHelpers, miscHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { useSelector } from 'modules/react-redux';
import { FC, memo, useEffect, useRef, useState } from 'react';
import bemFactory from 'react-bem-factory';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { deleteCommentAttachment } from '../../actions';
import ActivityItem from '../../components/activitySection/ActivityItem';
import SectionContainer from '../../components/SectionContainer';

const i18nPrefix = 'requestList/containers/card/ActivitySection';

interface ActivitySectionProps {
    className?: string;
    request: selectors.types.ExpandedRequest;
}

const ActivitySection: FC<ActivitySectionProps> = (props) => {
    const { className, request } = props;

    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState(false);

    const team = useSelector((state) => selectors.company.getCompanyTeam(state, request.company));
    const me = useSelector(selectors.profile.getProfileUser);
    const users = useSelector(selectors.user.getUsers);

    const activitySectionEl = useRef<any>();
    const scrollHeightBeforeExpand = useRef<number | null>(null);

    const getUser = (userId: Guid): selectors.types.ExpandedUser => {
        miscHelpers.invariant(userId, 'UserId cannot be falsy');

        const user = team.find((t) => t.id === userId) || users.find((u) => u.id === userId);

        if (!user) {
            throw errorHelpers.notFoundError(`Failed to find user ${userId}`);
        }

        return user;
    };

    const expandItems = () => {
        setExpanded(true);

        const scrollEl = domHelpers.getScrollParent(activitySectionEl.current);

        if (scrollEl) {
            scrollHeightBeforeExpand.current = scrollEl.scrollHeight;
        }
    };

    useEffect(() => {
        setExpanded(false);
    }, [request.id]);

    useEffect(() => {
        const scrollEl = domHelpers.getScrollParent(activitySectionEl.current);

        if (scrollEl && scrollHeightBeforeExpand.current) {
            const heightDiff = scrollEl.scrollHeight - scrollHeightBeforeExpand.current;

            scrollEl.scrollTop += heightDiff;
        }
    }, [expanded]);

    const bem = bemFactory.block('reql-activity-section');
    const qa = bemFactory.qa('reql-activity-section');

    const INITIAL_ITEMS_COUNT = 3;

    let historyEvents = request.history;

    if (!expanded) {
        historyEvents = historyEvents.slice(-INITIAL_ITEMS_COUNT);
    }

    const showExpandButton = request.history.length > INITIAL_ITEMS_COUNT && !expanded;

    return (
        <SectionContainer text={<FormattedMessage id={`${i18nPrefix}.headerText`} defaultMessage='AUDIT TRAIL' />}>
            <div className={bem.add(className)()} data-qa={qa()} ref={activitySectionEl}>
                {showExpandButton && (
                    <div className={bem('expand-button-wrp')}>
                        <TextButton
                            className={bem('expand-button')}
                            qa={qa('expand-button')}
                            size='small2'
                            execute={expandItems}
                        >
                            <FormattedMessage
                                id={`${i18nPrefix}.expandButtonText`}
                                defaultMessage={`{count, plural,
                            one {Show # event}
                            other {Show # events}
                        }`}
                                values={{
                                    count: request.history.length - INITIAL_ITEMS_COUNT,
                                }}
                            />
                        </TextButton>
                    </div>
                )}

                <div className={bem('items')} data-qa={qa('items')}>
                    {historyEvents.map((event) => (
                        <ActivityItem
                            key={event.id}
                            event={event}
                            request={request}
                            me={me}
                            getUser={getUser}
                            onRemoveCommentAttachment={(attachmentId: string) => {
                                dispatch(deleteCommentAttachment(attachmentId));
                            }}
                        />
                    ))}
                </div>
            </div>
        </SectionContainer>
    );
};

export default memo(ActivitySection);
