export const profileApiPaths = <const>{
    startResetPassword: 'profile/startResetPassword',
    finishResetPassword: 'profile/finishResetPassword',
    setPassword: 'profile/setPassword',
    updateProfile: 'profile/edit',
    verifyResetPasswordTFACode: 'profile/verifyResetPasswordTFACode',
    sendResetPasswordTFAEmailCode: 'profile/sendResetPasswordTFAEmailCode',
    sendLoginTFAEmailCode: 'auth/sendTFALoginEmailCode',
    finishTFALogin: 'auth/finishTFALogin',
};
