import { BackupCodes } from '@approvalmax/ui';
import { FC, memo, useCallback, useEffect } from 'react';
import bemFactory from 'react-bem-factory';
import { notificationService } from 'services/notification';
import { useAddTFAEnablingBackupCodes, useFinishTFAEnablingWithBackupCodes } from 'shared/data';

import { useTwoFaEnablingWizardContext } from '../../TwoFaEnablingWizard.context';
import AnotherMethodButton from '../AnotherMethodButton/AnotherMethodButton';
import ProgressFlow2fa from '../ProgressFlow2fa/ProgressFlow2fa';
import { messages } from './StepBackupCodes.messages';
import { Content, Root, Title } from './StepBackupCodes.styles';

const qa = bemFactory.qa('pro-two-fa-setup-backup-codes');

const StepBackupCodes: FC = memo(() => {
    const { context, setActiveStep, setSelectedBackupOption } = useTwoFaEnablingWizardContext();
    const { mutate: addBackupCodes, data, isLoading } = useAddTFAEnablingBackupCodes();
    const { mutate: finish } = useFinishTFAEnablingWithBackupCodes();

    useEffect(() => {
        if (context.stateId) {
            addBackupCodes({ body: { stateId: context.stateId } });
        }
    }, [addBackupCodes, context.stateId]);

    const submit = useCallback(() => {
        if (context.stateId) {
            finish(
                { body: { stateId: context.stateId } },
                {
                    onSuccess: () => {
                        setActiveStep('success');
                        setSelectedBackupOption('backupCode');
                    },
                }
            );
        }
    }, [context.stateId, finish, setActiveStep, setSelectedBackupOption]);

    return (
        <Root qa={qa()} title={messages.popupTitle}>
            <Content>
                <ProgressFlow2fa activeStepIndex={2} />

                <Title>{messages.title}</Title>

                <BackupCodes
                    isLoading={isLoading}
                    backupCodes={data?.backupCodes}
                    onDone={submit}
                    showInfoToast={notificationService.showInfoToast}
                />

                <AnotherMethodButton />
            </Content>
        </Root>
    );
});

export default StepBackupCodes;
