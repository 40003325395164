import { RequestMutationOptions } from '@approvalmax/data';
import { routerHelpers } from '@approvalmax/utils';
import { useQueryClient } from '@tanstack/react-query';
import { useMutate } from 'shared/data';

import { budgetsApiPaths } from '../paths';
import { SyncQBooksBudget } from './useSyncQBooksBudget.types';

export const useSyncQBooksBudget = (mutationOptions?: RequestMutationOptions<SyncQBooksBudget>) => {
    const queryClient = useQueryClient();

    return useMutate(budgetsApiPaths.qBooksBudgetSync, {
        mutationOptions: {
            ...mutationOptions,
            onSuccess: async (data, variables, context) => {
                const getBudgetsApiPath = routerHelpers.pathToUrl(budgetsApiPaths.qBooksBudgets, {
                    companyId: variables.params?.path?.companyId,
                });

                await queryClient.invalidateQueries([getBudgetsApiPath]);

                mutationOptions?.onSuccess?.(data, variables, context);
            },
        },
        apiVersion: 'v2',
    });
};
