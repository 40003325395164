import { WarningImage } from '@approvalmax/ui';
import { Button, Flex, Popup, Text } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import moment from 'moment';
import { FC, memo } from 'react';
import { useMount } from 'react-use';
import { amplitudeService } from 'services/amplitude';
import { AccountPath, getAccountUrl } from 'urlBuilder';

import { messages } from './AfterAllFeatureTrialPopupContent.messages';
import { AfterAllFeatureTrialPopupContentProps } from './AfterAllFeatureTrialPopupContent.types';

export const AfterAllFeatureTrialPopupContent: FC<AfterAllFeatureTrialPopupContentProps> = memo((props) => {
    const { company, isBeforeExpiration, upgradeEnabled } = props;

    const title = (isBeforeExpiration ? messages.titleBefore : messages.titleAfter)({
        name: company.displayName,
        date: moment.utc(company.allFeaturesTrialEndDate).format('ll'),
    });
    const description = (isBeforeExpiration ? messages.desciptionBefore : messages.descriptionAfter)({
        plan: <b>{selectors.subscription.getPlanShortName(company.licenseProductPlan)}</b>,
    });

    const upgradeLink = company.subscriptionId
        ? getAccountUrl(AccountPath.upgradeSubscription, {
              subscriptionId: company.subscriptionId,
              origin: 'wa_aft_expiration_popup',
          })
        : getAccountUrl(AccountPath.root, {
              origin: 'wa_aft_expiration_popup',
          });

    useMount(() => {
        amplitudeService.sendData('billing: land on aft expiration modal', {
            type: isBeforeExpiration ? '2_days_before_expiration' : 'after_expiration',
        });
    });

    return (
        <>
            <Popup.Header />

            <Popup.Body spacing='16 32 32'>
                <Flex direction='column' alignItems='center' spacing='16'>
                    <WarningImage width={100} height={100} />

                    <Text font='headline' fontSize='small' fontWeight='medium' textAlign='center' as='h3'>
                        {title}
                    </Text>

                    <Text font='body' fontSize='medium' textAlign='center' spacing='0 0 20'>
                        {description}
                    </Text>

                    {upgradeEnabled && (
                        <Button
                            size='large'
                            color='blue80'
                            as='a'
                            href={upgradeLink}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            {messages.upgrade}
                        </Button>
                    )}
                </Flex>
            </Popup.Body>
        </>
    );
});

AfterAllFeatureTrialPopupContent.displayName = 'AfterAllFeatureTrialPopupContent';
