import { miscHelpers } from '@approvalmax/utils';
import { domain } from 'modules/data';

export const displayPhoneNumber = (phone?: domain.EditableXeroContactPhone) => {
    if (phone) {
        return [phone.countryCode, phone.areaCode, phone.number].filter(miscHelpers.notEmptyFilter).join('-');
    }

    return null;
};
