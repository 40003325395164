import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { myAccountApiPaths } from '../paths';
import { TakeOverOrganisation } from './useTakeOverOrganisation.types';

export const useTakeOverOrganisation = (mutationOptions?: RequestMutationOptions<TakeOverOrganisation>) => {
    return useMutate(myAccountApiPaths.takeOverOrganisation, {
        mutationOptions,
        apiVersion: 'v1',
        method: 'post',
        apiSource: 'myAccount',
    });
};
