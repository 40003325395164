import { Box, Flex, Popup, Text } from '@approvalmax/ui/src/components';
import { memo, useCallback } from 'react';
import bemFactory from 'react-bem-factory';

import { TwoFaActionWizardProps } from '../../TwoFaActionWizard.types';
import { EnterCodeForm } from '../EnterCodeForm/EnterCodeForm';
import { messages } from './ScreenBackupCode.messages';

const qa = bemFactory.qa('pro-backup-code-popup');

export const ScreenBackupCode = memo<TwoFaActionWizardProps>((props) => {
    const {
        title,
        isLoading,
        onEnterBackupCode,
        isTrustThisDeviceEnabled,
        isTFABackupCodesEnabled,
        isTFAAlternativeEmailEnabled,
    } = props;

    const handleSubmit = useCallback(
        (code: string, isTrustedDevice: boolean) => {
            onEnterBackupCode?.(code, isTrustedDevice);
        },
        [onEnterBackupCode]
    );

    return (
        <>
            <Popup.Header title={title} />

            <Popup.Body qa={qa()}>
                <Flex direction='column' spacing='24'>
                    <Box maxWidth={450}>
                        <Text font='body' fontWeight='regular'>
                            {messages.description}
                        </Text>
                    </Box>

                    <EnterCodeForm
                        isLoading={isLoading}
                        fieldLabel={messages.codeFieldTitle}
                        fieldPlaceholder={messages.userCodePlaceholder}
                        isTrustedDeviceEnabled={isTrustThisDeviceEnabled}
                        isAlternativeMethodEnabled={isTFAAlternativeEmailEnabled || isTFABackupCodesEnabled}
                        onSubmit={handleSubmit}
                    />
                </Flex>
            </Popup.Body>
        </>
    );
});

ScreenBackupCode.displayName = 'ScreenBackupCode';
