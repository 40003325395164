import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useQueryClient } from '@tanstack/react-query';
import { useGet } from 'shared/data';

import { reportsApiPaths } from '../paths';
import { GetReportSchedule } from './useGetReportSchedule.types';

export const useGetReportSchedule = (
    params?: RequestParams<GetReportSchedule>,
    queryOptions?: RequestQueryOptions<GetReportSchedule>
) => {
    return useGet(reportsApiPaths.reportSchedule, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetReportSchedule['params']['query']>(
                {
                    reportId: params?.query?.reportId,
                    companyId: params?.query?.companyId,
                    type: params?.query?.type,
                },
                queryOptions
            ),
        },
        apiVersion: 'v1',
    });
};

export const useGetReportScheduleInvalidate = (params: GetReportSchedule['params']['query']) => {
    const queryClient = useQueryClient();

    return () => queryClient.invalidateQueries([reportsApiPaths.reportSchedule, params]);
};
