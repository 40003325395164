import { useSpring } from '@react-spring/web';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { useMeasure } from 'react-use';

import { openMenuSubItemsDuration } from './Item.constants';
import { ItemProps } from './Item.types';

export const useItem = (props: Pick<ItemProps, 'open' | 'onClick' | 'id' | 'onOpen'>) => {
    const { onClick, open, id, onOpen } = props;

    const controlled = open !== undefined && onOpen;
    const [isOpenSubItems, setIsOpenSubItems] = useState(Boolean(open));

    /**
     * Updating the opening status for an externally controlled menu item
     */
    useEffect(() => {
        if (!controlled) return;

        setIsOpenSubItems(Boolean(open));
    }, [controlled, open]);

    /**
     * Updating the opening state for uncontrolled menu item and call the callbacks
     */
    const handleClickLabel = useCallback(
        (event: MouseEvent<HTMLButtonElement>) => {
            if (!controlled) {
                setIsOpenSubItems(!isOpenSubItems);
            }

            onOpen?.(!isOpenSubItems, id);
            onClick?.(event, id);
        },
        [controlled, onOpen, isOpenSubItems, id, onClick]
    );

    return {
        isOpenSubItems,
        handleClickLabel,
    };
};

export const useAnimation = (isOpenSubItems: boolean) => {
    const [subItemsRef, { height }] = useMeasure<HTMLDivElement>();
    const subItemsStyles = useSpring({
        config: {
            duration: openMenuSubItemsDuration,
        },
        from: {
            height: 0,
            opacity: 0,
            y: 0,
        },
        to: {
            height: isOpenSubItems ? height : 0,
            opacity: Number(isOpenSubItems),
            y: isOpenSubItems ? 0 : -10,
        },
    });

    return {
        subItemsRef,
        subItemsStyles,
    };
};
