import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { GetNetSuiteBillPaymentBills } from './useGetNetSuiteBillPaymentBills.types';

export const useGetNetSuiteBillPaymentBills = (
    params?: RequestParams<GetNetSuiteBillPaymentBills>,
    queryOptions?: RequestQueryOptions<GetNetSuiteBillPaymentBills>
) => {
    return useGet(requestsApiPaths.netSuiteBillPaymentBills, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetNetSuiteBillPaymentBills['params']['query']>(
                {
                    accountId: params?.query?.accountId,
                    currencyId: params?.query?.currencyId,
                    payeeId: params?.query?.payeeId,
                    payeeType: params?.query?.payeeType,
                    pageSize: params?.query?.pageSize,
                    page: params?.query?.page,
                },
                queryOptions
            ),
        },
        apiVersion: 'v2',
    });
};
