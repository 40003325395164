import { dateTimeHelpers, domHelpers, errorHelpers } from '@approvalmax/utils';
import { forwardRef, ForwardRefExoticComponent, memo, MemoExoticComponent, RefAttributes } from 'react';

import { Button } from '../Button/Button';
import { Icon } from '../DatePicker/DatePicker.styles';
import { DigitalClockProps } from '../DigitalClock/DigitalClock.types';
import Divider from '../Divider/Divider';
import Dropdown from '../Dropdown/Dropdown';
import { Grid } from '../Grid/Grid';
import { TextField } from '../TextField/TextField';
import { Controller, PopupContent } from './components';
import { useCalendarValue, useCallbackActions, useTextFieldValue } from './DateTimePicker.hooks';
import { ChildrenComponents, DateTimePickerProps } from './DateTimePicker.types';

export const DateTimePicker = memo(
    forwardRef((props, ref) => {
        const {
            placement = 'bottom-start',
            onOpen,
            initOpen,
            value,
            onChange,
            onFocus,
            onBlur,
            onClear,
            initFocus,
            clearable = true,
            minDate: minDateProp,
            maxDate: maxDateProp,
            noUtc,
            qa: qaProp,
            ...restProps
        } = props;

        if (typeof onChange !== 'function') {
            throw errorHelpers.notSupportedError(
                'DateTimePicker: Component not suppose to be used as uncontrollable. `onChange` callback is required'
            );
        }

        const minDate = noUtc ? minDateProp : dateTimeHelpers.convertLocalToUTC(minDateProp);
        const maxDate = noUtc ? maxDateProp : dateTimeHelpers.convertLocalToUTC(maxDateProp);
        const qa = domHelpers.generateDataQa(qaProp, 'date-time-picker');

        const { calendarValue, changeDatePickerValue } = useCalendarValue({
            value,
            onChange,
            noUtc,
        });

        const {
            handleOpen,
            open,
            handleCalendarChange,
            handleChangeValue,
            handleFocus,
            handleClick,
            handleKeyDown,
            handleIconClick,
            focus,
            handleBlur,
            handleClear,
        } = useCallbackActions({
            onOpen,
            onFocus,
            onBlur,
            onClear,
            initOpen,
            initFocus,
            calendarValue,
            changeDatePickerValue,
        });

        const { textFieldValue, handleTextFieldChange } = useTextFieldValue({
            calendarValue,
            changeDatePickerValue,
            minDate,
            maxDate,
            isHumanReadable: !(focus || open),
        });

        const handleDigitClockChange: DigitalClockProps['onChange'] = (value, _, isLast) => {
            handleChangeValue(value);

            if (isLast) {
                handleOpen(false);
            }
        };

        return (
            <Dropdown
                activator={
                    <TextField
                        {...restProps}
                        value={textFieldValue}
                        onKeyDown={handleKeyDown}
                        onChange={handleTextFieldChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onClick={handleClick}
                        onClear={handleClear}
                        focus={focus}
                        clearable={clearable && Boolean(textFieldValue)}
                        type={focus || open ? 'datetime-local' : 'text'}
                        endIcon={
                            <Button noPadding tabIndex={-1} onClick={handleIconClick}>
                                <Icon color='midnight100' />
                            </Button>
                        }
                        qa={qa}
                        ref={ref}
                    />
                }
                placement={placement}
                open={open}
                onOpen={handleOpen}
                maxHeight={0}
                width='max-content'
                isClicking={false}
                floatingFocusManagerProps={{
                    initialFocus: -1,
                    returnFocus: false,
                    modal: false,
                }}
            >
                <Grid padding='16' data-qa={domHelpers.generateDataQa(qa, 'popup')}>
                    <PopupContent
                        calendarValue={calendarValue}
                        minDate={minDate}
                        maxDate={maxDate}
                        qa={qa}
                        onCalendarChange={handleCalendarChange}
                        onDigitalClockChange={handleDigitClockChange}
                    />

                    <Divider />

                    <Grid padding='12 0 0 0' justifyItems='end'>
                        <Button color='blue10' size='small' onClick={() => handleOpen(false)}>
                            OK
                        </Button>
                    </Grid>
                </Grid>
            </Dropdown>
        );
    })
) as MemoExoticComponent<ForwardRefExoticComponent<DateTimePickerProps & RefAttributes<HTMLInputElement>>> &
    ChildrenComponents;

DateTimePicker.displayName = 'DateTimePicker';
DateTimePicker.Controller = Controller;
