import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { authApiPaths } from '../paths';
import { Logout } from './useLogout.types';

export const useLogout = (mutationOptions?: RequestMutationOptions<Logout>) => {
    return useMutate(authApiPaths.logout, {
        mutationOptions,
        apiVersion: 'v1',
    });
};
