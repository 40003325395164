import { FC, memo } from 'react';

import { Root } from './AirwallexBatchPaymentRequestCard.styles';
import { AirwallexBatchPaymentRequestCardProps } from './AirwallexBatchPaymentRequestCard.types';
import BillsInformationSection from './components/BillsInformationSection/BillsInformationSection';

const AirwallexBatchPaymentRequestCard: FC<AirwallexBatchPaymentRequestCardProps> = (props) => {
    const { request } = props;
    const airwallexBatchPayment = request.details;

    return (
        <Root>
            <BillsInformationSection
                billBatchPayment={airwallexBatchPayment}
                currency={request.currency}
                request={request}
            />
        </Root>
    );
};

export default memo(AirwallexBatchPaymentRequestCard);
