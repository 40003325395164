import { Flex } from '@approvalmax/ui/src/components';
import { DoneIcon } from 'modules/sprites';

import { isFieldVisible } from '../../NetSuiteRequestCard.utils';
import { messages } from './NetSuiteVendorAddressList.messages';
import { AddressListColumnDefinition, AddressListFieldSettings } from './NetSuiteVendorAddressList.types';

export const getColumnDefs = (fieldSettings: AddressListFieldSettings) => {
    const columnDef: AddressListColumnDefinition[] = [
        {
            id: 'default-shipping',
            name: messages.defaultShippingColumnName,
            value: ({ defaultShipping }) => (defaultShipping ? messages.defaultShippingTooltip : null),
            cell: (value) =>
                value && (
                    <Flex alignItems='center' justifyContent='center'>
                        <DoneIcon width={13} height={10} />
                    </Flex>
                ),
        },
        {
            id: 'default-billing',
            name: messages.defaultBillingColumnName,
            value: ({ defaultBilling }) => (defaultBilling ? messages.defaultBillingTooltip : null),
            cell: (value) =>
                value && (
                    <Flex alignItems='center' justifyContent='center'>
                        <DoneIcon width={13} height={10} />
                    </Flex>
                ),
        },
        {
            id: 'label',
            name: messages.labelColumnName,
            value: ({ label }) => label,
        },
        {
            id: 'country',
            name: messages.countryColumnName,
            value: ({ country }) => (country ? country.text : null),
            isHidden: !isFieldVisible(fieldSettings.country),
        },
        {
            id: 'attention',
            name: messages.attentionColumnName,
            value: ({ attention }) => attention,
            isHidden: !isFieldVisible(fieldSettings.attention),
        },
        {
            id: 'addressee',
            name: messages.addresseeColumnName,
            value: ({ addressee }) => addressee,
            isHidden: !isFieldVisible(fieldSettings.addressee),
        },
        {
            id: 'phone',
            name: messages.phoneColumnName,
            value: ({ phone }) => phone,
            isHidden: !isFieldVisible(fieldSettings.phoneAddress),
        },
        {
            id: 'primary-address',
            name: messages.primaryAddressColumnName,
            value: ({ primaryAddress }) => primaryAddress,
            isHidden: !isFieldVisible(fieldSettings.primaryAddress),
        },
        {
            id: 'secondary-address',
            name: messages.secondaryAddressColumnName,
            value: ({ secondaryAddress }) => secondaryAddress,
            isHidden: !isFieldVisible(fieldSettings.secondaryAddress),
        },
        {
            id: 'state',
            name: messages.stateColumnName,
            value: ({ state }) => state,
            isHidden: !isFieldVisible(fieldSettings.state),
        },
        {
            id: 'city',
            name: messages.cityColumnName,
            value: ({ city }) => city,
            isHidden: !isFieldVisible(fieldSettings.city),
        },
        {
            id: 'zip',
            name: messages.zipColumnName,
            value: ({ zip }) => zip,
            isHidden: !isFieldVisible(fieldSettings.zip),
        },
    ];

    return columnDef.filter((column) => !column.isHidden);
};
