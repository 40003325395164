import { ChevronRightIcon } from '@approvalmax/ui';
import { Flex } from '@approvalmax/ui/src/components';
import { Fragment, memo } from 'react';
import { useSelector } from 'react-redux';

import { getActiveTemplate } from '../../selectors/pageSelectors';
import AddNewStepButton from '../AddNewStepButton/AddNewStepButton';
import ApprovalStep from '../ApprovalStep/ApprovalStep';
import DraggableStepList from '../DraggableStepList/DraggableStepList';
import { SingletonSteps } from '../SingletonSteps/SingletonSteps';
import StartStep from '../StandaloneStartStep/StandaloneStartStep';

const StandaloneWorkflowDetails = memo(() => {
    const template = useSelector(getActiveTemplate);

    if (!template) {
        return null;
    }

    return (
        <>
            <StartStep />

            <SingletonSteps />

            <DraggableStepList>
                {template.steps.map((step, index) => (
                    <Fragment key={step.id}>
                        {index === 0 && (
                            <Flex height={28} alignItems='center'>
                                <ChevronRightIcon size={16} color='midnight60' />
                            </Flex>
                        )}

                        <ApprovalStep stepIndex={index} index={index} step={step} />
                    </Fragment>
                ))}
            </DraggableStepList>

            <AddNewStepButton hideArrow />
        </>
    );
});

export default StandaloneWorkflowDetails;
