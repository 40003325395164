import { errorHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { store } from 'modules/store';
import Refiner from 'refiner-js';

export const refinerService = {
    init() {
        try {
            const state = store.getState();
            const profile = selectors.profile.getProfile(state);
            const integrations = selectors.integration.getIntegrations(state);
            const hasXeroIntegrationType = integrations.some(
                (item) => item.integrationType === domain.IntegrationType.Xero
            );
            const hasQBooksIntegrationType = integrations.some(
                (item) => item.integrationType === domain.IntegrationType.QBooks
            );

            const isManager = Object.values(state.entities.companies).some((company) =>
                company.managers.includes(profile.email)
            );

            if (isManager) {
                const projectId = window.ApprovalMax.refinerProjectId;

                Refiner('setProject', projectId);
                Refiner('identifyUser', {
                    email: profile.email,
                    firstName: profile.firstName,
                    name: profile.name || profile.email,
                    id: profile.id,
                    createdDate_at: profile.createdDate,
                    role: profile.account?.userRole,
                    account: {
                        accountId: profile.account?.accountId,
                        type: profile.account?.accountType,
                        lifecycle: profile.isTrialUser ? 'trial' : 'paid',
                        hasXeroIntegrationType,
                        hasQBooksIntegrationType,
                    },
                });
            }
        } catch (error) {
            errorHelpers.captureException(error);
        }
    },
};
