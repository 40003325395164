import styled from 'styled-components';

export const ToolbarBody = styled.div`
    display: grid;
    grid-template-columns: minmax(190px, max-content) 1fr;
    padding: 0 60px;
`;

export const ToolbarBodyLeft = styled.div`
    display: flex;
    flex-direction: column;
    z-index: 1;
`;

export const ToolbarBodyRight = styled.div`
    display: flex;
    flex-flow: column;
    align-items: flex-end;
`;

export const Container = styled.div`
    padding-bottom: 15px;
`;
