import { font } from '@approvalmax/ui';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    padding: 10px 0 0 0;
    flex-wrap: wrap;
`;

export const SecondaryActionsTitle = styled.div`
    ${font(12, '#777d81', 'semibold')}
    margin-right: 10px;
    margin-top: 1px;
    flex-shrink: 0;
`;

export const SecondaryActionsButtons = styled.div`
    display: flex;
    flex-flow: row wrap;
    align-items: center;
`;

export const SecondaryActionsSeparator = styled.div`
    height: 10px;
    width: 1px;
    background: #384650;
    margin: 0 10px;

    &:last-child {
        display: none;
    }
`;
