import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages(
    'requestList.xero.XeroMatchingV2Section.BillView.ChangeMatchingPopup.ChangeMatchingPopupBody.ChangeMatchingPopupItem',
    {
        gross: '{amount} (Gross)',
        net: '{amount} (Net)',
        date: 'Date:',
        requester: 'Requester:',
        reference: 'Reference:',
        grnStatusLabel: 'GRN Status:',
        statusNotReceived: 'Not received',
        statusPartiallyReceived: 'Partially received',
        statusFullyReceived: 'Fully received',
    }
);
