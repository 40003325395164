import { RequestMutationOptions } from '@approvalmax/data';
import { useQueryClient } from '@tanstack/react-query';
import { useMutate } from 'shared/data';

import { integrationApiPaths } from '../paths';
import { UpdateNetSuiteFilter } from './useUpdateNetSuiteFilter.types';

export const useUpdateNetSuiteFilter = (mutationOptions?: RequestMutationOptions<UpdateNetSuiteFilter>) => {
    const queryClient = useQueryClient();

    return useMutate(integrationApiPaths.updateNetSuiteFilter, {
        mutationOptions: {
            ...mutationOptions,
            onSuccess: async (response, variables, context) => {
                queryClient.invalidateQueries([
                    integrationApiPaths.getNetSuiteApiFilter,
                    { companyId: variables.body?.companyId },
                ]);

                mutationOptions?.onSuccess?.(response, variables, context);
            },
        },
        apiVersion: 'v1',
    });
};
