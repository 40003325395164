import { Popup } from '@approvalmax/ui';
import { State } from 'modules/data';
import { GlobalLoadingBar } from 'modules/page';
import { reloadRequest } from 'pages/requestList/actions';
import { MatchingPORequestersPopupData } from 'pages/requestList/reducers/page/activePopup/matchingPORequestersPopupReducer';
import { getActivePopup } from 'pages/requestList/selectors/pageSelectors';
import { FC, memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAddXeroMatchedPosRequestersToActiveStep } from 'shared/data/v1';

import { tableColumns } from './AddMatchedPOsRequestersPopup.column';
import { messages } from './AddMatchedPOsRequestersPopup.messages';
import { PopupContent, StyledDataTable, StyledDefaultContent } from './AddMatchedPOsRequestersPopup.styles';
import { AddMatchedPOsRequestersPopupProps } from './AddMatchedPOsRequestersPopup.types';

const AddMatchedPOsRequestersPopup: FC<AddMatchedPOsRequestersPopupProps> = memo((props) => {
    const { companyId, requestId, requestVersion, onClose } = props;
    const { mutate, isLoading } = useAddXeroMatchedPosRequestersToActiveStep();
    const { requesters } = useSelector((state: State) => getActivePopup<MatchingPORequestersPopupData>(state));
    const dispatch = useDispatch();
    const [checkedColumns, setCheckedColumns] = useState<string[]>([]);

    const onCheckedColumnChange = useCallback((columnIds: string[]) => {
        setCheckedColumns(columnIds);
    }, []);

    const isDisabled = isLoading || !checkedColumns.length;

    const onAddButtonClick = useCallback(() => {
        mutate(
            { body: { companyId, requestersIds: checkedColumns, requestVersion, requestId } },
            {
                onSuccess: () => {
                    dispatch(reloadRequest(requestId, companyId));
                    onClose();
                },
            }
        );
    }, [mutate, checkedColumns, companyId, dispatch, onClose, requestId, requestVersion]);

    return (
        <>
            <GlobalLoadingBar isLoading={isLoading} />

            <StyledDefaultContent
                title={messages.title}
                buttons={
                    <Popup.DefaultContent.Button execute={onAddButtonClick} disabled={isDisabled}>
                        {messages.addButtonTitle}
                    </Popup.DefaultContent.Button>
                }
            >
                <PopupContent>
                    <StyledDataTable
                        data={requesters}
                        columns={tableColumns}
                        meta={{ companyId }}
                        paddingBottom={40}
                        checkedItems={checkedColumns}
                        onCheckedItemsChange={onCheckedColumnChange}
                    />
                </PopupContent>
            </StyledDefaultContent>
        </>
    );
});

export default AddMatchedPOsRequestersPopup;
