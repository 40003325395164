import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { integrationApiPaths } from '../paths';
import { GetContactCisSettings } from './useGetContactCisSettings.types';

export const useGetContactCisSettings = (
    params?: RequestParams<GetContactCisSettings>,
    queryOptions?: RequestQueryOptions<GetContactCisSettings>
) => {
    return useGet(integrationApiPaths.getContactCisSettings, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetContactCisSettings['params']['query']>(
                {
                    contactId: params?.query?.contactId,
                    companyId: params?.query?.companyId,
                },
                queryOptions
            ),
        },
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};
