import { MutateDataParams, MutationOptions } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data/api';

import { requestsApiPaths } from '../paths';
import { UseDeleteRequestData, UseDeleteRequestResponse } from './useDeleteRequest.types';

export const useDeleteRequest = (
    mutationOptions?: MutationOptions<UseDeleteRequestResponse, MutateDataParams<UseDeleteRequestData>>
) => {
    return useMutateLegacy<MutateDataParams<UseDeleteRequestData>, UseDeleteRequestResponse>(requestsApiPaths.delete, {
        mutationOptions,
    });
};
