import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { GetRequestVersion } from './useGetRequestVersion.types';

export const useGetRequestVersion = (
    params?: RequestParams<GetRequestVersion>,
    queryOptions?: RequestQueryOptions<GetRequestVersion>
) => {
    return useGet(requestsApiPaths.requestVersion, {
        params,
        queryOptions,
        apiVersion: 'v2',
    });
};
