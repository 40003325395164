import { domain } from 'modules/data';

export const findDocumentFieldState = (documentFields: domain.DocumentField[], fieldSystemPurpose: string) => {
    const fieldSettings = documentFields?.find((field) => field.purpose === fieldSystemPurpose);

    if (fieldSettings) {
        return fieldSettings.state;
    }

    return domain.DocumentFieldState.Editable;
};
