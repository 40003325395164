import { QueryOptions } from '@approvalmax/data';
import { domain } from 'modules/data';
import { useGet } from 'shared/data';

import { twoFaApiPaths } from '../paths';
import { UseGetTFADefaultEnforcementTypeResponse } from './useGetTFADefaultEnforcementType.types';

export const useGetTFADefaultEnforcementType = (
    integrationType: domain.IntegrationType,
    queryOptions?: QueryOptions<UseGetTFADefaultEnforcementTypeResponse>
) => {
    return useGet<UseGetTFADefaultEnforcementTypeResponse>(twoFaApiPaths.defaultTwoFaEnforcementType, {
        apiVersion: 'v1',
        pathParams: {
            integrationType,
        },
        queryOptions: {
            retry: 0,
            ...queryOptions,
        },
        method: 'get',
    });
};
