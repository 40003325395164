import { subtractOffset } from './subtractOffset';

export const convertUTCToLocal = <Value extends Date | undefined>(
    value: Value
): Value extends Date ? Date : undefined => {
    // we must use get `getTimezoneOffset` from value to ensure that
    // TZ reflects the one from date. TZ of `dateTime-local` input can
    // differ local TZ
    return (value ? subtractOffset(value, value.getTimezoneOffset()).toDate() : undefined) as Value extends Date
        ? Date
        : undefined;
};
