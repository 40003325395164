import { Box, Link, Popup, Spacing, Text } from '@approvalmax/ui/src/components';
import { constants } from 'modules/common';
import { memo, useCallback } from 'react';
import bemFactory from 'react-bem-factory';

import { TwoFaActionWizardProps } from '../../TwoFaActionWizard.types';
import { EnterCodeForm } from '../EnterCodeForm/EnterCodeForm';
import { messages } from './ScreenAppCode.messages';

const qa = bemFactory.qa('pro-app-code-popup');

export const ScreenAppCode = memo<TwoFaActionWizardProps>((props) => {
    const {
        title,
        description,
        isLoading,
        isTFAAlternativeEmailEnabled,
        isTFABackupCodesEnabled,
        isTrustThisDeviceEnabled,
        onEnterAppCode,
    } = props;

    const handleSubmit = useCallback(
        (code: string, isTrustedDevice: boolean) => {
            onEnterAppCode(code, isTrustedDevice);
        },
        [onEnterAppCode]
    );

    return (
        <>
            <Popup.Header title={title} />

            <Popup.Body qa={qa()}>
                <Box maxWidth={450}>
                    <Text font='body' fontWeight='regular'>
                        {description || messages.description}
                    </Text>

                    {messages.supportText({
                        supportlink: (chunks) => (
                            <Link external font='body' href={constants.xeroConstants.XERO_TWO_FA_SUPPORT_LINK}>
                                {chunks}
                            </Link>
                        ),
                    })}
                </Box>

                <Spacing height={24} />

                <EnterCodeForm
                    isLoading={isLoading}
                    fieldLabel={messages.fieldCodeLabel}
                    fieldPlaceholder={messages.fieldCodePlaceholder}
                    isTrustedDeviceEnabled={isTrustThisDeviceEnabled}
                    isAlternativeMethodEnabled={isTFAAlternativeEmailEnabled || isTFABackupCodesEnabled}
                    onSubmit={handleSubmit}
                />
            </Popup.Body>
        </>
    );
});

ScreenAppCode.displayName = 'ScreenAppCode';
