import { useMemo } from 'react';

import { useLineCustomFieldsDefinition } from '../../NetSuiteRequestCard.hooks';
import { getColumnDefs } from './NetSuiteCreditList.config';
import { CreditListFieldSettings, NetSuiteCreditListProps } from './NetSuiteCreditList.types';

export const useTableDefinitions = (
    companyCustomFields: NetSuiteCreditListProps['companyCustomFields'],
    fieldSettings: CreditListFieldSettings,
    exchangeRate?: number | null
) => {
    const customFieldsColumnDefinition = useLineCustomFieldsDefinition([], companyCustomFields);

    const resultColumnDefinition = useMemo(
        () => [...getColumnDefs(fieldSettings, exchangeRate), ...customFieldsColumnDefinition],
        [customFieldsColumnDefinition, fieldSettings, exchangeRate]
    );

    return resultColumnDefinition;
};
