import { errorHelpers, intl } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { DoneIcon } from 'modules/sprites';
import { ColumnDefinition } from 'pages/requestList/selectors/types/ColumnDefinition';
import { XeroMatchingBillTableItem } from 'pages/requestList/selectors/types/matchingTypes';
import { getGrnStatusText } from 'shared/helpers/getGrnStatusText/getGrnStatusText';
import { getPath, Path } from 'urlBuilder';

import { messages } from './BillMatchingTable.messages';
import { StyledLink } from './BillMatchingTable.styles';
import { GetColumnDefinitionsParams } from './BillMatchingTable.types';

export const getColumnDefinitions = ({
    amountType,
    companyId,
    isInSplitView,
    isGrnEnabled,
}: GetColumnDefinitionsParams) => {
    let totalAmountName;

    switch (amountType) {
        case domain.MatchingAmountType.Gross:
            totalAmountName = messages.totalAmountColumnNameWithGross;
            break;

        case domain.MatchingAmountType.Net:
            totalAmountName = messages.totalAmountColumnNameWithNet;
            break;

        default: {
            throw errorHelpers.assertNever(amountType ?? '');
        }
    }

    const columnDefinitions: ColumnDefinition<XeroMatchingBillTableItem>[] = [
        {
            id: 'name',
            name: messages.itemColumnName,
            value: (li) => li.name,
            cell: (column, li) => (
                <StyledLink href={getPath(Path.request, li.id, companyId)} targetBlank={isInSplitView}>
                    {li.name}
                </StyledLink>
            ),
        },
        {
            id: 'billed',
            name: messages.billedColumnName,
            value: (li) => (li.billed ? 'Yes' : 'No'),
            cell: (column, li) => (li.billed ? <DoneIcon /> : null),
        },
        {
            id: 'grnStatus',
            name: messages.grnStatusColumnName,
            value: (li) => (isGrnEnabled ? li.grnStatus : null),
            cell: (column, li) => getGrnStatusText(li.grnStatus),
        },
        {
            id: 'matched_by',
            name: messages.matchedByColumnName,
            value: (li) => li.matchedBy,
        },
        {
            id: 'allocated_amount',
            name: messages.allocatedAmountColumnName,
            value: (li) => intl.formatCurrency(li.allocatedAmount, li.currency),
            alignRight: true,
        },
        {
            id: 'total_amount',
            name: totalAmountName,
            value: (li) => intl.formatCurrency(li.totalAmount, li.currency),
            alignRight: true,
            width: 150,
        },
    ];

    return columnDefinitions;
};
