import { intl } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { memo, useMemo } from 'react';

import Disclaimer from './components/Disclaimer/Disclaimer';
import { messages } from './TotalContainer.messages';
import { Amount, CurrencyAmount, CurrencyText, Line, Text } from './TotalContainer.styles';
import { AccCurrencyAmount, TotalContainerProps } from './TotalContainer.types';

const TotalContainer = memo<TotalContainerProps>((props) => {
    const { airwallexPaymentItems, currency, amount } = props;
    const allBillsCurrencies = airwallexPaymentItems.map((item) => item.currency);
    const isMulticurrency = new Set(allBillsCurrencies).size > 1;

    const accSourceCurrencyAmount = useMemo(() => {
        return airwallexPaymentItems.reduce((acc: AccCurrencyAmount, item) => {
            if (
                !item.status ||
                item.status === domain.AirwallexInvoiceStatus.Failed ||
                item.status === domain.AirwallexInvoiceStatus.Cancelled ||
                item.status === domain.AirwallexInvoiceStatus.Rejected ||
                item.feeAmount === undefined ||
                item.sourceAmount === undefined ||
                !item.sourceCurrency
            ) {
                return acc;
            }

            if (!acc[item.sourceCurrency.id]) {
                acc[item.sourceCurrency.id] = { totalAmount: 0, feeAmount: 0 };
            }

            acc[item.sourceCurrency.id].feeAmount += item.feeAmount;
            acc[item.sourceCurrency.id].totalAmount += item.sourceAmount + item.feeAmount;

            return acc;
        }, {});
    }, [airwallexPaymentItems]);

    return (
        <div>
            <Disclaimer>
                <Text>{isMulticurrency ? messages.multicurrencyTotal : messages.total}</Text>

                <Amount>{intl.formatCurrency(amount, currency)}</Amount>
            </Disclaimer>

            {Object.keys(accSourceCurrencyAmount).map((sourceCurrency) => (
                <Line key={sourceCurrency}>
                    <CurrencyText>
                        {messages.currencyTotal({
                            currency: sourceCurrency,
                            feeAmount: intl.formatNumber(accSourceCurrencyAmount[sourceCurrency].feeAmount),
                        })}
                    </CurrencyText>

                    <CurrencyAmount>
                        {intl.formatCurrency(accSourceCurrencyAmount[sourceCurrency].totalAmount, sourceCurrency)}
                    </CurrencyAmount>
                </Line>
            ))}
        </div>
    );
});

export default TotalContainer;
