import { TotalLine } from 'pages/requestList/types';

export const columnWidth = {
    bill: 120,
    dueDate: 80,
    amountDue: 120,
    amount: 120,
    paymentDetails: 150,
    paymentReference: 100,
    paymentStatus: 70,
    xeroLink: 50,
    attachments: 30,
    menu: 30,
};

export const initialTotalForSupplier: TotalLine = {
    friendlyName: '',
    amount: 0,
    amountDue: 0,
    currency: '',
    dueDate: '',
    paymentReference: '',
    bankAccountName: '',
    amaxPayBankAccountId: '',
    id: '',
    billRequestIdList: [],
    isTotalLine: true,
    oneOfBillRequestsId: '',
};
