import { selectors } from 'modules/common';
import { useMount, useUnmount } from 'react-use';
import { useRecoilState } from 'recoil';
import { isPayOrCapturePromotionsAllowed } from 'shared/helpers';
import { useAddonPromotions } from 'shared/hooks/useAddonPromotions';
import { AddonPromotionType, pageVisiblePromotionsState } from 'shared/states';

export const useInitWorkflowDetailsPromotions = (company: selectors.types.ExpandedCompany | null) => {
    const [pageVisiblePromotions, setPageVisiblePromotions] = useRecoilState(pageVisiblePromotionsState);
    const { canShowPromotion } = useAddonPromotions();

    useMount(() => {
        if (
            company &&
            isPayOrCapturePromotionsAllowed(company, AddonPromotionType.Pay) &&
            canShowPromotion(AddonPromotionType.Pay)
        ) {
            setPageVisiblePromotions((currentState) =>
                currentState ? [...currentState, AddonPromotionType.Pay] : [AddonPromotionType.Pay]
            );
        }

        if (
            company &&
            isPayOrCapturePromotionsAllowed(company, AddonPromotionType.Capture) &&
            canShowPromotion(AddonPromotionType.Capture)
        ) {
            setPageVisiblePromotions((currentState) =>
                currentState ? [...currentState, AddonPromotionType.Capture] : [AddonPromotionType.Capture]
            );
        }
    });

    useUnmount(() => {
        setPageVisiblePromotions(null);
    });

    return pageVisiblePromotions;
};
