import { toast } from '@approvalmax/ui/src/components';
import { useCallback, useEffect } from 'react';
import { useSetTFAEnablingEmail } from 'shared/data';

import { fieldNameEmail, fieldNameEmailVerificationCode, fieldNameStateId } from '../../TwoFaEnablingWizard.constants';
import {
    useTwoFaWizardController,
    useTwoFaWizardFormContext,
    useTwoFaWizardStep,
} from '../../TwoFaEnablingWizard.hooks';
import { messages } from './StepBackupEmailVerify.messages';

export const useStepBackupEmail = () => {
    const form = useTwoFaWizardFormContext();
    const email = form.watch(fieldNameEmail);
    const stateId = form.watch(fieldNameStateId);

    const { setWizardStep } = useTwoFaWizardStep();
    const { setWizardController } = useTwoFaWizardController();

    const { mutate: requestCode, isLoading } = useSetTFAEnablingEmail({
        onSuccess: () => {
            toast.success(messages.notificationCodeResend);

            form.setValue(fieldNameEmailVerificationCode, '');

            setWizardStep('backupEmailVerify');
        },
    });

    const handleRequestCode = useCallback(() => {
        if (stateId && email) {
            requestCode({ body: { stateId, email } });
        }
    }, [email, requestCode, stateId]);

    const handleGoStepEmail = useCallback(() => {
        form.setValue(fieldNameEmailVerificationCode, '');

        setWizardStep('backupEmailEnter');
    }, [form, setWizardStep]);

    useEffect(() => {
        setWizardController({
            title: messages.title,
            onPrev: undefined,
            onNext: undefined,
        });
    }, [setWizardController]);

    return {
        email,
        handleGoStepEmail,
        handleRequestCode,
        isLoading,
    };
};
