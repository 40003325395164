import { useSessionNotification } from 'modules/common';
import { selectors } from 'modules/common';
import { useSelector } from 'modules/react-redux';
import { useCallback, useMemo } from 'react';
import { useMount } from 'react-use';
import { useRecoilValue } from 'recoil';
import { amplitudeService } from 'services/amplitude';
import { isNewUserForPushingInviteTeammatesExperiment } from 'shared/helpers/experiments';
import { activeCompanyIdState } from 'shared/states';
import { getPath, Path } from 'urlBuilder';

export const usePushingInviteTeammates = () => {
    const banner = useSessionNotification('PushingInviteTeammates');
    const activeCompanyId = useRecoilValue(activeCompanyIdState);
    const companies = useSelector(selectors.company.getCompanies);
    const profile = useSelector(selectors.profile.findProfile);

    const areAllCompaniesTrial = useMemo(() => companies.every((company) => company.flags.isTrialLicense), [companies]);
    const activeCompany = useMemo(
        () => companies.find((company) => company.id === activeCompanyId),
        [companies, activeCompanyId]
    );

    const isVisible =
        profile &&
        isNewUserForPushingInviteTeammatesExperiment(profile.createdDate) &&
        activeCompany &&
        activeCompany.flags.isTrialLicense &&
        !activeCompany.flags.isExpired &&
        activeCompany.allMembers.length < 2 &&
        areAllCompaniesTrial &&
        !banner.isHidden;

    const handleInvite = useCallback(() => {
        amplitudeService.sendData('users: click on cta in invite promotion bumper', {
            org_id: activeCompanyId,
            user_id: profile?.id,
        });
    }, [activeCompanyId, profile?.id]);

    const handleHide = useCallback(() => {
        banner.hide();
    }, [banner]);

    useMount(() => {
        amplitudeService.sendData('users: land on invite promotion bumper', {
            org_id: activeCompanyId,
            user_id: profile?.id,
        });
    });

    return {
        isVisible,
        handleInvite,
        handleHide,
        companyUsersPath: getPath(Path.companyUsers, activeCompanyId, {
            addAsAdmin: true,
            addWithInvite: true,
            openAddPopup: true,
        }),
    };
};
