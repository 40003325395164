import { RequestMutationOptions } from '@approvalmax/data';
import { useQueryClient } from '@tanstack/react-query';
import { useMutate } from 'shared/data';

import { companiesApiPaths } from '../paths';
import { CreateCompany } from './useCreateCompany.types';

export const useCreateCompany = (mutationOptions?: RequestMutationOptions<CreateCompany<true>>) => {
    const queryClient = useQueryClient();

    return useMutate(companiesApiPaths.create, {
        mutationOptions: {
            ...mutationOptions,
            onSettled: async (data, error, variables, context) => {
                await queryClient.invalidateQueries([companiesApiPaths.select]);

                mutationOptions?.onSettled?.(data, error, variables, context);
            },
        },
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};

export const useCreateCompanyRaw = (mutationOptions?: RequestMutationOptions<CreateCompany>) => {
    const queryClient = useQueryClient();

    return useMutate(companiesApiPaths.create, {
        mutationOptions: {
            ...mutationOptions,
            onSettled: async (data, error, variables, context) => {
                await queryClient.invalidateQueries([companiesApiPaths.select]);

                mutationOptions?.onSettled?.(data, error, variables, context);
            },
        },
        apiVersion: 'v1',
    });
};
