import { FC, memo } from 'react';
import styled from 'styled-components';

import { PurchaseOrder } from '../../../../../../types';
import ChangeMatchingPopupItem from './components/ChangeMatchingPopupItem/ChangeMatchingPopupItem';
import PanelEmpty from './components/PanelEmpty/PanelEmpty';

const Content = styled.div`
    flex-grow: 1;
    overflow: auto;
`;

interface ChangeMatchingPopupBodyProps {
    purchaseOrders: PurchaseOrder[];
    companyId: string;
}

const ChangeMatchingPopupBody: FC<ChangeMatchingPopupBodyProps> = (props) => {
    const { purchaseOrders, companyId } = props;

    if (!purchaseOrders.length) {
        return <PanelEmpty />;
    }

    return (
        <Content>
            {purchaseOrders.map((po) => (
                <ChangeMatchingPopupItem {...po} companyId={companyId} key={po.id} />
            ))}
        </Content>
    );
};

export default memo(ChangeMatchingPopupBody);
