import { selectors } from 'modules/common';
import { useSelector } from 'modules/react-redux';
import { useCompanyIdBasedOnUrl } from 'shared/hooks/useCompanyIdBasedOnUrl';

/**
 * Get company data based on company id from URL
 *
 * @return A company or throws exception when no found
 */
export const useCompanyBasedOnUrl = () => {
    // some of our components located outside any <Route />, so we can't get Route match there
    // this approach works despite the component location
    const { companyId } = useCompanyIdBasedOnUrl();

    return useSelector((state) => selectors.company.getCompanyById(state, companyId));
};
