import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { budgetsApiPaths } from '../paths';
import { GetBudgetImpacts } from './useGetBudgetImpacts.types';

export const useGetBudgetImpacts = (
    params?: RequestParams<GetBudgetImpacts>,
    queryOptions?: RequestQueryOptions<GetBudgetImpacts<true>>
) => {
    return useGet(budgetsApiPaths.getBudgetImpacts, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetBudgetImpacts['params']['query']>(
                {
                    requestId: params?.query?.requestId,
                    companyId: params?.query?.companyId,
                },
                queryOptions
            ),
        },
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};

export const useGetBudgetImpactsRaw = (
    params?: RequestParams<GetBudgetImpacts>,
    queryOptions?: RequestQueryOptions<GetBudgetImpacts>
) => {
    return useGet(budgetsApiPaths.getBudgetImpacts, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetBudgetImpacts['params']['query']>(
                {
                    requestId: params?.query?.requestId,
                    companyId: params?.query?.companyId,
                },
                queryOptions
            ),
        },
        apiVersion: 'v1',
    });
};
