import FieldsSection from 'pages/requestList/components/FieldsSection';
import { SectionContainer } from 'pages/requestList/components/SectionContainer/SectionContainer';
import styled from 'styled-components';

export const StyledSectionContainer = styled(SectionContainer)`
    padding-bottom: 24px;
`;

export const FieldsSectionWrap = styled.div`
    display: flex;
    flex-flow: row wrap;
    padding: 10px 50px 0 60px;
    border-bottom: none;
`;

export const StyledField = styled(FieldsSection.Field)`
    min-width: 144px;
    max-width: 144px;
    margin-right: 10px;
    margin-bottom: 34px;
`;
