import { font, Popup } from '@approvalmax/ui';
import { ui } from 'modules/common';
import styled from 'styled-components';

export const Root = styled(Popup.DefaultContent)`
    width: 700px;
`;

export const Content = styled.div`
    display: flex;
    flex-flow: column;
    border-top: 1px solid #ebeaea;
    padding: 20px 60px 40px 80px;
`;

export const Description = styled.div`
    ${font(13, '#333')};
`;

export const CommentEditor = styled(ui.AddCommentEditor)`
    margin-top: 40px;
`;
