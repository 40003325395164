import styled from 'styled-components';

import FieldEntryWrapper from '../../../../../components/FieldEntryWrapper/FieldEntryWrapper';

export const Root = styled.div`
    width: 100%;
`;

export const Content = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const StyledFieldEntryWrapper = styled(FieldEntryWrapper)`
    width: 20%;
`;
