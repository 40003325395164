import { Flex, Text } from '@approvalmax/ui/src/components';
import { domHelpers, intl, numberHelpers } from '@approvalmax/utils';
import capitalize from 'lodash/capitalize';
import { FC, memo } from 'react';

import LineItemFooterSeparator from '../lineItemsSection/LineItemFooterSeparator';
import { messages } from './AmountInfo.messages';
import { AmountInfoProps } from './AmountInfo.types';

export const AmountInfo: FC<AmountInfoProps> = memo((props) => {
    const {
        currency,
        netAmount,
        taxAmount,
        totalAmount,
        discountAmount,
        subtotal,
        shippingAmount,
        handlingAmount,
        debitTotal,
        creditTotal,
        hideSeparator,
        qa,
    } = props;

    return (
        <Flex spacing='4' direction='column' alignItems='end' maxWidth={250}>
            {numberHelpers.isNumber(netAmount) ? (
                <Flex
                    data-qa={domHelpers.generateDataQa(qa, 'net-amount')}
                    spacing='0 8'
                    alignItems='center'
                    wrap={false}
                >
                    <Text
                        data-qa={domHelpers.generateDataQa(qa, 'net-amount-label')}
                        font='body'
                        fontWeight='medium'
                        color='midnight70'
                        fontSize='small'
                    >
                        {messages.netAmountText}
                    </Text>

                    <Flex data-qa={domHelpers.generateDataQa(qa, 'net-amount-value')} justifyContent='end' width={180}>
                        <Text font='body' fontWeight='bold' fontSize='small' color='black100'>
                            {intl.formatCurrency(netAmount, currency, 2, false)}
                        </Text>
                    </Flex>
                </Flex>
            ) : null}

            {numberHelpers.isNumber(subtotal) ? (
                <Flex
                    data-qa={domHelpers.generateDataQa(qa, 'subtotal')}
                    spacing='0 8'
                    alignItems='center'
                    wrap={false}
                >
                    <Text
                        data-qa={domHelpers.generateDataQa(qa, 'subtotal-label')}
                        font='body'
                        fontWeight='medium'
                        color='midnight70'
                        fontSize='small'
                    >
                        {messages.subtotal}
                    </Text>

                    <Flex data-qa={domHelpers.generateDataQa(qa, 'subtotal-value')} justifyContent='end' width={180}>
                        <Text font='body' fontWeight='bold' fontSize='small' color='black100'>
                            {intl.formatCurrency(subtotal, currency, 2, false)}
                        </Text>
                    </Flex>
                </Flex>
            ) : null}

            {numberHelpers.isNumber(discountAmount) ? (
                <Flex
                    data-qa={domHelpers.generateDataQa(qa, 'discount-amount')}
                    spacing='0 8'
                    alignItems='center'
                    wrap={false}
                >
                    <Text
                        data-qa={domHelpers.generateDataQa(qa, 'discount-amount-label')}
                        font='body'
                        fontWeight='medium'
                        color='midnight70'
                        fontSize='small'
                    >
                        {messages.discountAmountText}
                    </Text>

                    <Flex
                        data-qa={domHelpers.generateDataQa(qa, 'discount-amount-value')}
                        justifyContent='end'
                        width={180}
                    >
                        <Text font='body' fontWeight='bold' fontSize='small' color='black100'>
                            {intl.formatCurrency(discountAmount, currency, 2, false)}
                        </Text>
                    </Flex>
                </Flex>
            ) : null}

            {numberHelpers.isNumber(taxAmount) ? (
                <Flex
                    data-qa={domHelpers.generateDataQa(qa, 'tax-amount')}
                    spacing='0 8'
                    alignItems='center'
                    wrap={false}
                >
                    <Text
                        data-qa={domHelpers.generateDataQa(qa, 'tax-amount-label')}
                        font='body'
                        fontWeight='medium'
                        color='midnight70'
                        fontSize='small'
                    >
                        {capitalize(messages.taxAmountText)}
                    </Text>

                    <Flex data-qa={domHelpers.generateDataQa(qa, 'tax-amount-value')} justifyContent='end' width={180}>
                        <Text font='body' fontWeight='bold' fontSize='small' color='black100'>
                            {intl.formatCurrency(taxAmount, currency, 2, false)}
                        </Text>
                    </Flex>
                </Flex>
            ) : null}

            {numberHelpers.isNumber(shippingAmount) ? (
                <Flex
                    data-qa={domHelpers.generateDataQa(qa, 'shipping-amount')}
                    spacing='0 8'
                    alignItems='center'
                    wrap={false}
                >
                    <Text
                        data-qa={domHelpers.generateDataQa(qa, 'shipping-amount-label')}
                        font='body'
                        fontWeight='medium'
                        color='midnight70'
                        fontSize='small'
                    >
                        {messages.shippingAmountText}
                    </Text>

                    <Flex
                        data-qa={domHelpers.generateDataQa(qa, 'shipping-amount-value')}
                        justifyContent='end'
                        width={180}
                    >
                        <Text font='body' fontWeight='bold' fontSize='small' color='black100'>
                            {intl.formatCurrency(shippingAmount, currency, 2, false)}
                        </Text>
                    </Flex>
                </Flex>
            ) : null}

            {numberHelpers.isNumber(handlingAmount) ? (
                <Flex
                    data-qa={domHelpers.generateDataQa(qa, 'handling-amount')}
                    spacing='0 8'
                    alignItems='center'
                    wrap={false}
                >
                    <Text
                        data-qa={domHelpers.generateDataQa(qa, 'handling-amount-label')}
                        font='body'
                        fontWeight='medium'
                        color='midnight70'
                        fontSize='small'
                    >
                        {messages.handlingAmountText}
                    </Text>

                    <Flex
                        data-qa={domHelpers.generateDataQa(qa, 'handling-amount-value')}
                        justifyContent='end'
                        width={180}
                    >
                        <Text font='body' fontWeight='bold' fontSize='small' color='black100'>
                            {intl.formatCurrency(handlingAmount, currency, 2, false)}
                        </Text>
                    </Flex>
                </Flex>
            ) : null}

            {numberHelpers.isNumber(debitTotal) ? (
                <Flex
                    data-qa={domHelpers.generateDataQa(qa, 'debit-total')}
                    spacing='0 8'
                    alignItems='center'
                    wrap={false}
                >
                    <Text
                        data-qa={domHelpers.generateDataQa(qa, 'debit-total-label')}
                        font='body'
                        fontWeight='medium'
                        color='midnight70'
                        fontSize='small'
                    >
                        {messages.debitTotalText}
                    </Text>

                    <Flex data-qa={domHelpers.generateDataQa(qa, 'debit-total-value')} justifyContent='end' width={180}>
                        <Text font='body' fontWeight='bold' fontSize='small' color='black100'>
                            {intl.formatCurrency(debitTotal, currency, 2, false)}
                        </Text>
                    </Flex>
                </Flex>
            ) : null}

            {numberHelpers.isNumber(creditTotal) ? (
                <Flex
                    data-qa={domHelpers.generateDataQa(qa, 'credit-total')}
                    spacing='0 8'
                    alignItems='center'
                    wrap={false}
                >
                    <Text
                        data-qa={domHelpers.generateDataQa(qa, 'credit-total-label')}
                        font='body'
                        fontWeight='medium'
                        color='midnight70'
                        fontSize='small'
                    >
                        {messages.creditTotalText}
                    </Text>

                    <Flex
                        data-qa={domHelpers.generateDataQa(qa, 'credit-total-value')}
                        justifyContent='end'
                        width={180}
                    >
                        <Text font='body' fontWeight='bold' fontSize='small' color='black100'>
                            {intl.formatCurrency(creditTotal, currency, 2, false)}
                        </Text>
                    </Flex>
                </Flex>
            ) : null}

            {!hideSeparator && <LineItemFooterSeparator />}

            {numberHelpers.isNumber(totalAmount) && (
                <Flex
                    data-qa={domHelpers.generateDataQa(qa, 'total-amount')}
                    spacing='0 8'
                    alignItems='center'
                    wrap={false}
                >
                    <Text
                        data-qa={domHelpers.generateDataQa(qa, 'total-amount-label')}
                        font='body'
                        fontWeight='medium'
                        color='midnight70'
                        fontSize='small'
                    >
                        {capitalize(messages.totalAmountText)}
                    </Text>

                    <Flex
                        data-qa={domHelpers.generateDataQa(qa, 'total-amount-value')}
                        justifyContent='end'
                        width={180}
                    >
                        <Text font='body' fontWeight='bold' fontSize='small' color='black100'>
                            {intl.formatCurrency(totalAmount, currency, 2, false)}
                        </Text>
                    </Flex>
                </Flex>
            )}
        </Flex>
    );
});

AmountInfo.displayName = 'AmountInfo';
