import { domain } from 'modules/data';
import { FC } from 'react';

import { messages } from './PaymentStatusBadge.messages';
import { FailureBadge, PendingBadge, SuccessBadge } from './PaymentStatusBadge.styles';
import { PaymentStatusBadgeProps } from './PaymentStatusBadge.types';

const PaymentStatusBadge: FC<PaymentStatusBadgeProps> = (props) => {
    const { status, errorMessage } = props;

    switch (status) {
        case domain.AirwallexInvoiceStatus.Sent:
            return <SuccessBadge>{messages.sent}</SuccessBadge>;

        case domain.AirwallexInvoiceStatus.Processing:
            return <PendingBadge>{messages.processing}</PendingBadge>;

        case domain.AirwallexInvoiceStatus.Failed:
            return <FailureBadge title={errorMessage}>{messages.failed}</FailureBadge>;

        case domain.AirwallexInvoiceStatus.Cancelled:
            return <FailureBadge title={errorMessage}>{messages.cancelled}</FailureBadge>;

        case domain.AirwallexInvoiceStatus.Rejected:
            return <FailureBadge title={errorMessage}>{messages.rejected}</FailureBadge>;

        default:
            return null;
    }
};

export default PaymentStatusBadge;
