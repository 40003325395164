import { Link } from '@approvalmax/ui';
import { font } from 'modules/styles';
import styled, { css } from 'styled-components';

export const Root = styled.div``;

export const SectionHeaderText = styled.div`
    ${font(14, '#000000', 'semibold')}
    margin-bottom: 12px;
    margin-top: 24px;
    text-transform: uppercase;
`;

export const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
`;

export const LinkContainer = styled.div``;

export const TextBlock = styled.div<{ width: number }>`
    max-width: 200px;
    white-space: pre-line;

    ${(props) => css`
        width: ${props.width}px;
    `}
`;

export const WarningBlock = styled.div`
    max-width: 200px;
    white-space: pre-line;
    font-weight: bold;
    color: #a3341a;
    cursor: pointer;
`;

export const LinksBlock = styled.div`
    max-width: 42px;
    display: flex;
    align-items: center;

    & > a + a {
        margin-left: 10px;
    }
`;

export const ExternalLink = styled(Link)`
    color: #0085c4;
`;

export const Date = styled.div`
    margin-top: 4px;
    ${font(12, '#6c6a6a')}
`;
