import { domain } from 'modules/data';
import { findDocumentFieldState } from 'shared/helpers';

export const useExpenseReportFieldSettings = (documentFields: domain.DocumentField[]) => ({
    employee: findDocumentFieldState(documentFields, 'Employee'),
    advance: findDocumentFieldState(documentFields, 'Advance'),
    date: findDocumentFieldState(documentFields, 'Date'),
    dueDate: findDocumentFieldState(documentFields, 'DueDate'),
    department: findDocumentFieldState(documentFields, 'Department'),
    class: findDocumentFieldState(documentFields, 'Class'),
    location: findDocumentFieldState(documentFields, 'Location'),
    accountForCorporateCardExpenses: findDocumentFieldState(documentFields, 'AccountForCorporateCardExpenses'),
    memo: findDocumentFieldState(documentFields, 'Memo'),
    currency: findDocumentFieldState(documentFields, 'Currency'),
    exchangeRate: findDocumentFieldState(documentFields, 'ExchangeRate'),
    postingPeriod: findDocumentFieldState(documentFields, 'PostingPeriod'),
    transactionNumber: findDocumentFieldState(documentFields, 'TransactionNumber'),
});
