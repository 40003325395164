import { LineItemsTable } from 'pages/requestList/components/lineItemsSection/LineItemsTable';
import { SectionContainer } from 'pages/requestList/components/SectionContainer/SectionContainer';
import styled from 'styled-components';

export const StyledSection = styled(SectionContainer)`
    padding-bottom: 16px;
`;

export const StyledTable = styled(LineItemsTable)`
    overflow-x: auto;

    & > table {
        table-layout: fixed;
    }
`;
