import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import React from 'react';

import { messages } from './requestActivitySelectors.Dear.messages';

export function getDearEventHeader(
    request: selectors.types.ExpandedRequest,
    event: domain.RequestHistoryEvent
): React.ReactNode {
    switch (event.type) {
        case domain.RequestHistoryEventType.RejectedExternally:
            return messages.rejectedExternally;

        case domain.RequestHistoryEventType.PostApprovalChangeDetected:
            return messages.postApprovalChangeDetected;

        case domain.RequestHistoryEventType.UpdatedExternallyResetTemplate:
            return messages.updatedExternallyResetTemplate;

        case domain.RequestHistoryEventType.EmailToPartnerSent:
            return messages.emailToPartnerSent;

        default:
            return null;
    }
}
