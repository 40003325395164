import { AlertIcon } from '@approvalmax/ui';
import { Alert, Flex, Text } from '@approvalmax/ui/src/components';
import { FC } from 'react';

import { SettingsSubsectionProps } from './SettingsSubsection.types';

const SettingsSubsection: FC<SettingsSubsectionProps> = (props) => {
    const { subtitle, warning, children, bold, color = 'midnight100' } = props;

    return (
        <Flex spacing='12' direction='column'>
            {bold ? (
                <Text font='label' color={color} fontSize='large' fontWeight='medium'>
                    {subtitle}
                </Text>
            ) : (
                <Text font='body' color={color} fontSize='large'>
                    {subtitle}
                </Text>
            )}

            {warning && (
                <Alert color='midnight20' invalid startIcon={<AlertIcon />}>
                    {warning}
                </Alert>
            )}

            {children}
        </Flex>
    );
};

export default SettingsSubsection;
