import {
    addOnsFeatures,
    qboAdvancedFeatures,
    qboPremiumFeatures,
    xeroAdvancedFeatures,
    xeroPremiumFeatures,
} from '../../AllFeatureTrialPaywallPopup.constants';
import { TrialCompany, TrialFeature, TrialIntegrationType } from '../../AllFeatureTrialPaywallPopup.types';
import { addonPayEnabledCurrencies } from './Content.constants';

export const getAdvancedFeatures = (integrationType?: TrialIntegrationType) => {
    if (!integrationType) return [];

    switch (integrationType) {
        case TrialIntegrationType.Xero:
            return xeroAdvancedFeatures;

        case TrialIntegrationType.Quickbooks:
            return qboAdvancedFeatures;

        default:
            return [];
    }
};

export const getPremiumFeatures = (integrationType?: TrialIntegrationType) => {
    if (!integrationType) return [];

    switch (integrationType) {
        case TrialIntegrationType.Xero:
            return xeroPremiumFeatures;

        case TrialIntegrationType.Quickbooks:
            return qboPremiumFeatures;

        default:
            return [];
    }
};

export const getAddOnsFeatures = ({
    integrationType,
    company,
}: {
    integrationType?: TrialIntegrationType;
    company?: TrialCompany;
}) => {
    if (!integrationType) return [];

    const addons = [];

    if (!company?.hasAddOnCapture) {
        const captureAddon = addOnsFeatures.find(({ id }) => id === TrialFeature.AddonsCapture);

        captureAddon && addons.push(captureAddon);
    }

    if (
        integrationType === TrialIntegrationType.Xero &&
        !company?.hasAddOnPay &&
        company?.currency &&
        addonPayEnabledCurrencies.includes(company.currency) &&
        company.isSubscriptionActive
    ) {
        const payAddon = addOnsFeatures.find(({ id }) => id === TrialFeature.AddonsPay);

        payAddon && addons.push(payAddon);
    }

    return addons;
};

export const isShowAdvanced = (company?: TrialCompany) => {
    return (
        (company?.plan ? company?.plan === 'standard' : true) &&
        getAdvancedFeatures(company?.integrationType).length > 0
    );
};

export const isShowPremium = (company?: TrialCompany) => {
    return company?.plan !== 'premium' && getPremiumFeatures(company?.integrationType).length > 0;
};

export const getInitialFeature = (company?: TrialCompany) => {
    if (!company?.integrationType) return;

    const showAdvanced = isShowAdvanced(company);
    const showPremium = isShowPremium(company);

    if (showAdvanced) {
        return getAdvancedFeatures(company.integrationType)[0].id;
    }

    if (showPremium) {
        return getPremiumFeatures(company.integrationType)[0].id;
    }

    const addOnsFeatures = getAddOnsFeatures({
        integrationType: company.integrationType,
        company,
    });

    if (addOnsFeatures.length) {
        return addOnsFeatures[0].id;
    }

    return;
};
