import { Flex, Text } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { FC, memo } from 'react';

import { messages } from './ErrorScreen.messages';
import { ErrorScreenProps } from './ErrorScreen.types';

const ErrorScreen: FC<ErrorScreenProps> = memo((props) => {
    const { integrationErrorPreventsWork, integrationType, errorMessage } = props;
    const integrationName = selectors.integration.getIntegrationTypeName(integrationType);

    return (
        <Flex alignItems='center' justifyContent='center' height='100%'>
            <Text font='body' fontSize='large' color='midnight70'>
                {integrationErrorPreventsWork
                    ? messages.disconnectionWarningText({
                          integrationName,
                      })
                    : errorMessage}
            </Text>
        </Flex>
    );
});

export default ErrorScreen;
