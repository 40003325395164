import { DotsIcon } from '@approvalmax/ui';
import { Box, Button, Dropdown, Menu, Spacing, Text } from '@approvalmax/ui/src/components';
import { FC, memo, useMemo } from 'react';
import bemFactory from 'react-bem-factory';
import { UserAvatar } from 'shared/components';

import { useActionItems } from './FirstUserCell.hooks';
import { Root } from './FirstUserCell.styles';
import { FirstUserCellProps } from './FirstUserCell.types';

const qa = bemFactory.qa('wfc-first-user-cell');

export const FirstUserCell: FC<FirstUserCellProps> = memo((props) => {
    const { team, user, readonly, ifText } = props;

    const actionItems = useActionItems(props, qa);
    const teamUser = useMemo(() => team.find((u) => u.id === user.id) || user, [team, user]);

    return (
        <Root wrap={false} width='236px' data-qa={qa()}>
            <UserAvatar user={teamUser} size={24} />

            <Box>
                <Text
                    font='label'
                    fontSize='small'
                    fontWeight='medium'
                    color='midnight100'
                    ellipsis={1}
                    data-qa={qa('title')}
                >
                    {teamUser.displayName}
                </Text>

                <Spacing height={4} />

                <Text font='label' fontSize='small' color='midnight70'>
                    {ifText}
                </Text>
            </Box>

            {!readonly && actionItems.length > 0 && (
                <Box>
                    <Dropdown
                        closeOnClickInnerButton
                        activator={
                            <Button noPadding data-qa={qa('actions-menu')}>
                                <DotsIcon color='midnight90' />
                            </Button>
                        }
                        width='auto'
                    >
                        <Menu items={actionItems} size='small' />
                    </Dropdown>
                </Box>
            )}
        </Root>
    );
});

FirstUserCell.displayName = 'FirstUserCell';
