import { Button } from '@approvalmax/ui';
import { font, oPopupPadding } from 'modules/styles';
import { LineItemsTable } from 'pages/requestList/components/lineItemsSection/LineItemsTable';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

export const Content = styled.div`
    display: flex;
    flex-flow: column;
    ${oPopupPadding()};
`;

export const Subtitle = styled.div`
    ${font(20, '#000', 'semibold')}
`;

export const Description = styled.div`
    margin-top: 20px;
    font-weight: 400;
    font-size: 13px;
    ${font(13, '#000', 'standard')}
`;

export const ConfirmButton = styled(Button)`
    margin-top: 20px;
`;

export const StyledLineItemsTable = styled(LineItemsTable)`
    max-height: 200px;
`;

export const StyledSkeleton = styled(Skeleton)`
    margin-top: 15px;
`;
