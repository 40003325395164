import { Mods, mods } from '@approvalmax/theme';
import { ExternalLink, font } from '@approvalmax/ui';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

export const SectionHeaderText = styled.div`
    ${font(14, '#000000', 'semibold')}
    text-transform: uppercase;
`;

export const StyledExternalLink = styled(ExternalLink)`
    font-weight: 500;
`;

export const DateTimeTextWrapper = styled.div<StyledMods<Mods<'invalid'>>>`
    ${mods.invalid.true`
        color: #cb4e30;
    `}
`;
