import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { integrationApiPaths } from '../paths';
import { GetContacts } from './useGetContacts.types';

export const useGetContacts = (
    params?: RequestParams<GetContacts>,
    queryOptions?: RequestQueryOptions<GetContacts>
) => {
    return useGet(integrationApiPaths.getContacts, {
        params,
        queryOptions: {
            enabled: enabledByParams<GetContacts['params']['query']>(
                {
                    requestId: params?.query?.requestId,
                    companyId: params?.query?.companyId,
                    ruleType: params?.query?.ruleType,
                },
                queryOptions
            ),
            ...queryOptions,
        },
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};
