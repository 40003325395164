import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { integrationApiPaths } from '../paths';
import { CreateXeroCounterpartyPaymentDetails } from './useCreateXeroCounterpartyPaymentDetails.types';

export const useCreateXeroCounterpartyPaymentDetails = (
    mutationOptions?: RequestMutationOptions<CreateXeroCounterpartyPaymentDetails>
) => {
    return useMutate(integrationApiPaths.xeroCounterpartyPaymentDetails, {
        mutationOptions,
        apiVersion: 'v1',
    });
};
