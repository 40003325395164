import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestFormPage.SplitViewWrapper.UploadPlaceholder', {
    title: 'Uploading your files',
    filesDragMessage: 'Drag & drop to upload as files',
    filesToSupplieDragMessage: 'Drag & drop to upload as attachments to supplier',
    uploadDisplayDefaultTitle: 'Attach file',
    uploadDisplayOcrTitle: 'Capture or attach file',
    duplicateFile:
        'Please re-upload the file with a different name, as a file with "{fileName}" name is already added.',
});
