import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.TwoFaActionWizard.EnterCodeForm', {
    errorFieldIsRequired: 'Field is required',
    confirmButton: 'Confirm',
    anotherMethodButton: 'Use another authentication method',
    trustThisDevice: 'Trust this device',
    trustThisDeviceTooltip:
        'Your trusted device will be unbound if you either change your password or disable 2FA on your account.',
});
