import { DataTable, Popup } from '@approvalmax/ui';
import styled from 'styled-components';

export const StyledDefaultContent = styled(Popup.DefaultContent)`
    min-width: 700px;
    min-height: 400px;
    width: 60%;
    height: 60%;
`;

export const PopupContent = styled.div`
    display: flex;
    flex-direction: column;
    height: 90%;
    overflow: hidden;
`;

export const StyledDataTable = styled(DataTable)`
    height: 100%;
    overflow: hidden;

    & .ui-data-row {
        height: auto;
        min-height: 50px;
    }

    & .ui-data-table__table-body-wrp {
        display: flex;
        flex-direction: column;
    }
`;

export const StyledCheckboxColumnHeader = styled(DataTable.CheckboxColumnHeader)``;

export const StyledDefaultColumnHeader = styled(DataTable.DefaultColumnHeader)`
    padding-left: 32px;
`;
