import { atom } from 'recoil';

import { PurchaseOrderFields } from './NetSuitePurchaseOrderRequestForm.types';

export const netSuiteSendToSupplierState = atom<boolean>({
    key: 'netSuiteSendToSupplierState',
    default: false,
});

export const netSuitePurchaseOrderFieldValuesState = atom<Partial<PurchaseOrderFields>>({
    key: 'netSuitePurchaseOrderFieldValuesState',
    default: {},
});
