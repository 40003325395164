import { errorHelpers } from '@approvalmax/utils';

import { timeoutsEndStorageKey } from './useNotifyAdmins.constants';

const convertTimeoutsToTimestamps = (timeouts: Record<string, number>) => {
    const nowTimestampSec = Math.floor(Date.now() / 1000);

    return Object.entries(timeouts).reduce<Record<string, number>>((result, [companyId, timeout]) => {
        result[companyId] = nowTimestampSec + timeout;

        return result;
    }, {});
};

export const saveTimeouts = (timeouts: Record<string, number>) => {
    try {
        localStorage.setItem(timeoutsEndStorageKey, JSON.stringify(convertTimeoutsToTimestamps(timeouts)));
    } catch (error) {
        errorHelpers.captureException(error);
    }
};

export const restoreTimeouts = () => {
    try {
        const storageValue = localStorage.getItem(timeoutsEndStorageKey);

        if (!storageValue) {
            return {};
        }

        const parsedValue = JSON.parse(storageValue);

        if (!parsedValue || typeof parsedValue !== 'object') {
            return {};
        }

        const nowTimestampSec = Math.floor(Date.now() / 1000);

        return Object.entries(parsedValue).reduce<Record<string, number>>((result, [companyId, timestamp]) => {
            if (typeof timestamp === 'number' && timestamp > nowTimestampSec) {
                result[companyId] = timestamp - nowTimestampSec;
            }

            return result;
        }, {});
    } catch (error) {
        errorHelpers.captureException(error);

        return {};
    }
};
