import { MutateDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data/api';

import { requestsApiPaths } from '../paths';
import { UseReverseRequestData, UseReverseRequestResponse } from './useReverseRequest.types';

export const useReverseRequest = () => {
    return useMutateLegacy<MutateDataParams<UseReverseRequestData>, UseReverseRequestResponse>(
        requestsApiPaths.reverse
    );
};
