import type { MutateDataParams, MutationOptions } from '@approvalmax/data';
import { routerHelpers } from '@approvalmax/utils';
import { useQueryClient } from '@tanstack/react-query';
import { useMutateLegacy } from 'shared/data/api';

import { paymentServicesApiPaths } from '../paths';
import type { UseRenameAmaxPayOpenBankingBanksPathParams } from './useRenameAmaxPayOpenBankingBankAccounts.types';

export const useRenameAmaxPayOpenBankingBankAccounts = (
    mutationOptions: MutationOptions<{}, MutateDataParams<{}, UseRenameAmaxPayOpenBankingBanksPathParams>> = {}
) => {
    const queryClient = useQueryClient();

    return useMutateLegacy<MutateDataParams<{}, UseRenameAmaxPayOpenBankingBanksPathParams>>(
        paymentServicesApiPaths.crudAmaxPayOpenBankingBankAccounts,
        {
            mutationOptions: {
                ...mutationOptions,
                onSuccess: async (data, variables, context) => {
                    await queryClient.invalidateQueries([
                        routerHelpers.pathToUrl(
                            paymentServicesApiPaths.amaxPayOpenBankingBankAccounts,
                            variables.pathParams
                        ),
                    ]);

                    mutationOptions?.onSuccess?.(data, variables, context);
                },
            },
            method: 'patch',
        }
    );
};
