import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { integrationApiPaths } from '../paths';
import { GetNetSuiteCountries } from './useGetNetSuiteCountries.types';

export const useGetNetSuiteCountries = (
    params?: RequestParams<GetNetSuiteCountries>,
    queryOptions?: RequestQueryOptions<GetNetSuiteCountries<true>>
) => {
    return useGet(integrationApiPaths.getNetSuiteCountries, {
        params,
        queryOptions,
        method: 'get',
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};
