import { Avatar } from 'modules/common/ui';
import { FC, memo } from 'react';
import { getPath, Path } from 'urlBuilder';

import { AddMatchedPOsRequestersPopupCellProps } from '../AddMatchedPOsRequestersPopup.types';
import { messages } from './RequesterCell.messages';
import { Content, Root, StyledLink } from './RequesterCell.styles';

const RequesterCell: FC<AddMatchedPOsRequestersPopupCellProps> = memo((props) => {
    const { item, className, style, qa, meta } = props;

    return (
        <Root className={className} style={style} data-qa={qa}>
            <Avatar user={item} size={30} />

            <Content>
                {messages.description({ name: item.displayName })}

                <StyledLink href={getPath(Path.request, item.requestId, meta.companyId)} targetBlank>
                    {item.requestName}
                </StyledLink>
            </Content>
        </Root>
    );
});

export default RequesterCell;
