import { AddIcon } from '@approvalmax/ui';
import { Box, Button, Dropdown, Flex, Select, Text, TextField } from '@approvalmax/ui/src/components';
import { stringHelpers } from '@approvalmax/utils';
import differenceBy from 'lodash/differenceBy';
import { factories, selectors } from 'modules/common';
import { sendChameleonEvent } from 'modules/utils/helpers/chameleon';
import { FC, memo, useCallback, useMemo, useState } from 'react';
import bemFactory from 'react-bem-factory';
import { useMediatedState } from 'react-use';
import { SelectUserMenuItem } from 'shared/components';

import { selectPropsMap } from './AddUserButton.constants';
import { messages } from './AddUserButton.messages';
import { AddUserButtonProps } from './AddUserButton.types';

const qa = bemFactory.qa('wort-add-user-button');

const AddUserButton: FC<AddUserButtonProps> = memo((props) => {
    const {
        title,
        users,
        excludedUsers = [],
        onSelect,
        disabled,
        onlyAddNewMode,
        onAddNew,
        description,
        type = 'matrix',
        invalid,
        dataStarterGuide,
        itemsCountToEnableSearch = 20,
    } = props;

    const [isAddNewMode, setIsAddNewMode] = useState(false);
    const [isSelectOpen, setIsSelectOpen] = useState(false);
    const items = useMemo(() => differenceBy(users, excludedUsers, 'id'), [excludedUsers, users]);
    const isOnlyAddNewMode = useMemo(() => onlyAddNewMode || items.length === 0, [items.length, onlyAddNewMode]);
    const [email, setEmail] = useMediatedState((value: string) => value.trim(), '');
    const [showErrors, setShowErrors] = useState(false);

    const createUserButtonProps = useMemo(
        () => ({
            startIcon: <AddIcon size={16} />,
            name: messages.addNewUser,
            size: 'small' as const,
            onClick: () => {
                setIsAddNewMode(true);
                setIsSelectOpen(false);
            },
        }),
        []
    );

    const handleChange = useCallback(
        (userId: string, users: selectors.types.ExpandedCompanyUser[]) => {
            const user = users.find((user) => user.id === userId);

            if (user) {
                onSelect(user);
            }
        },
        [onSelect]
    );

    const onDropdownOpen = useCallback(
        (open: boolean) => {
            if (isOnlyAddNewMode) {
                setIsAddNewMode(open);
            } else {
                if (!open) {
                    setIsAddNewMode(false);
                }
            }
        },
        [isOnlyAddNewMode]
    );

    const onSelectOpen = useCallback(
        (open: boolean) => {
            if (!isOnlyAddNewMode) {
                setIsSelectOpen(open);
            }
        },
        [isOnlyAddNewMode]
    );

    const onSubmit = useCallback(() => {
        const parsedEmail = stringHelpers.parseEmail(email);

        if (!parsedEmail) {
            setShowErrors(true);

            return;
        }

        if (excludedUsers.some((user) => user.userEmail === parsedEmail)) {
            setIsAddNewMode(false);
            setEmail('');

            return;
        }

        const existingUser = users.find((user) => user.userEmail === email);

        if (existingUser) {
            onSelect(existingUser);
        } else {
            const newUser = factories.user.createUser(email);

            onAddNew(newUser);
            onSelect(newUser);
            sendChameleonEvent('added_user');
        }

        setEmail('');
        setShowErrors(false);
        setIsAddNewMode(false);
    }, [email, excludedUsers, onAddNew, onSelect, setEmail, users]);

    const invalidEmail = showErrors && !stringHelpers.parseEmail(email);

    const isSearchEnabled = items.length >= itemsCountToEnableSearch;

    const selectProps = selectPropsMap[type];

    return (
        <Box>
            <Dropdown
                display='block'
                open={isAddNewMode}
                onOpen={onDropdownOpen}
                isClicking
                width='auto'
                disabled={disabled}
                activator={
                    <Select
                        noInput={!isSelectOpen || !isSearchEnabled}
                        autocomplete={isSelectOpen && isSearchEnabled}
                        items={items}
                        customMenuItem={({ key, ...item }) => <SelectUserMenuItem key={key} {...item} size='small' />}
                        onChange={handleChange}
                        itemNameKey='displayName'
                        dropdownWidth='auto'
                        hideEndIcon
                        placeholder={title}
                        actionItemProps={createUserButtonProps}
                        open={isSelectOpen && !isOnlyAddNewMode}
                        onOpen={onSelectOpen}
                        disabled={disabled}
                        invalid={invalid}
                        {...selectProps}
                        data-starter-guide={dataStarterGuide}
                        data-qa={qa()}
                        placement='bottom-start'
                    />
                }
            >
                <Box width={210} spacing='8'>
                    <Flex spacing='12' justifyContent='center'>
                        <Box width='100%'>
                            <Text font='label' fontSize='small'>
                                {messages.addUser}
                            </Text>
                        </Box>

                        <Text font='label' fontSize='xsmall'>
                            {description || messages.description}
                        </Text>

                        <TextField
                            placeholder={messages.emailPlaceholder}
                            size='small'
                            initFocus
                            width='fill-available'
                            value={email}
                            onChange={setEmail}
                            required
                            invalid={invalidEmail}
                            onEnter={onSubmit}
                            data-qa={qa('panel-empty-email-editor')}
                        />

                        <Button
                            onClick={onSubmit}
                            size='small'
                            color='blue80'
                            type='submit'
                            disabled={invalidEmail}
                            data-qa={qa('panel-empty-add-button')}
                        >
                            {messages.addNewUser}
                        </Button>
                    </Flex>
                </Box>
            </Dropdown>
        </Box>
    );
});

AddUserButton.displayName = 'AddUserButton';

export default AddUserButton;
