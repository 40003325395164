import { intl } from '@approvalmax/utils';
import { DoneIcon } from 'modules/sprites';
import FieldsSection from 'pages/requestList/components/FieldsSection';
import { SectionContainer } from 'pages/requestList/components/SectionContainer/SectionContainer';
import { memo } from 'react';

import { FieldsSectionWrap, StyledField } from '../../NetSuiteRequestCard.styles';
import { getRevenueStatusText } from './NetSuiteSalesOrderFooterFields.helpers';
import { messages } from './NetSuiteSalesOrderFooterFields.messages';
import { BooleanValueWrapper } from './NetSuiteSalesOrderFooterFields.styles';
import { NetSuiteSalesOrderFooterFieldsProps } from './NetSuiteSalesOrderFooterFields.types';

const NetSuiteSalesOrderFooterFields = memo<NetSuiteSalesOrderFooterFieldsProps>((props) => {
    const { request } = props;

    const details = request.details;

    const hasSalesDetailsSection =
        details.salesRep ||
        details.opportunity ||
        details.salesEffectiveDate ||
        details.excludeCommissions ||
        details.partner;

    const hasShippingDetailsSection =
        details.shipDate || details.shipMethod || details.shipComplete || details.shippingAddress;

    const hasRevenueDetailsSection =
        details.revenueStatus ||
        typeof details.recognizedRevenue === 'number' ||
        typeof details.deferredRevenue === 'number';

    const hasPaymentDetailsSection =
        details.paymentMethod ||
        details.creditCardNumber ||
        details.creditCardExpirationDate ||
        details.creditCardCardholderName ||
        details.creditCardStreet ||
        details.creditCardZipCode ||
        details.creditCardProcessor ||
        details.creditCardApproved ||
        details.pnRef ||
        details.authCode ||
        details.avsStreetMatch ||
        details.avsZipMatch;

    return (
        <>
            {hasSalesDetailsSection && (
                <SectionContainer title={messages.salesDetailsSection} noPadding withHeaderPadding>
                    <FieldsSectionWrap>
                        <StyledField
                            title={messages.salesRep}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={details.salesRep}
                            hideIfEmpty
                        />

                        <StyledField
                            title={messages.opportunity}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={details.opportunity}
                            hideIfEmpty
                        />

                        <StyledField
                            title={messages.salesEffectiveDate}
                            valueType={FieldsSection.Field.ValueType.Date}
                            value={details.salesEffectiveDate}
                            hideIfEmpty
                        />

                        <StyledField
                            title={messages.excludeCommissions}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={details.excludeCommissions ? messages.positiveBooleanValue : null}
                            hideIfEmpty
                        />

                        <StyledField
                            title={messages.partner}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={details.partner}
                            hideIfEmpty
                        />
                    </FieldsSectionWrap>
                </SectionContainer>
            )}

            {hasShippingDetailsSection && (
                <SectionContainer title={messages.shippingDetailsSection} noPadding withHeaderPadding>
                    <FieldsSectionWrap>
                        <StyledField
                            title={messages.shipDate}
                            valueType={FieldsSection.Field.ValueType.Date}
                            value={details.shipDate}
                            hideIfEmpty
                        />

                        <StyledField
                            title={messages.shipMethod}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={details.shipMethod}
                            hideIfEmpty
                        />

                        <StyledField
                            title={messages.shipComplete}
                            forceDisplayValue={
                                details.shipComplete ? (
                                    <BooleanValueWrapper>
                                        <DoneIcon width={13} height={10} />
                                    </BooleanValueWrapper>
                                ) : null
                            }
                            hideIfEmpty
                        />

                        <StyledField
                            title={messages.shippingAddress}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={details.shippingAddress?.fullAddress?.replace(/<br>/g, ' ').trim() ?? null}
                            hideIfEmpty
                        />
                    </FieldsSectionWrap>
                </SectionContainer>
            )}

            {hasRevenueDetailsSection && (
                <SectionContainer title={messages.revenueDetailsSection} noPadding withHeaderPadding>
                    <FieldsSectionWrap>
                        <StyledField
                            title={messages.revenueStatus}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={details.revenueStatus ? getRevenueStatusText(details.revenueStatus) : null}
                            hideIfEmpty
                        />

                        <StyledField
                            title={messages.recognizedRevenue}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={
                                typeof details.recognizedRevenue === 'number'
                                    ? intl.formatNumber(details.recognizedRevenue, 'auto')
                                    : null
                            }
                            hideIfEmpty
                        />

                        <StyledField
                            title={messages.deferredRevenue}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={
                                typeof details.deferredRevenue === 'number'
                                    ? intl.formatNumber(details.deferredRevenue, 'auto')
                                    : null
                            }
                            hideIfEmpty
                        />
                    </FieldsSectionWrap>
                </SectionContainer>
            )}

            {hasPaymentDetailsSection && (
                <SectionContainer title={messages.paymentDetailsSection} noPadding withHeaderPadding>
                    <FieldsSectionWrap>
                        <StyledField
                            title={messages.paymentMethod}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={details.paymentMethod}
                            hideIfEmpty
                        />

                        <StyledField
                            title={messages.creditCardNumber}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={details.creditCardNumber}
                            hideIfEmpty
                        />

                        <StyledField
                            title={messages.creditCardExpirationDate}
                            valueType={FieldsSection.Field.ValueType.Date}
                            value={details.creditCardExpirationDate}
                            hideIfEmpty
                        />

                        <StyledField
                            title={messages.creditCardCardholderName}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={details.creditCardCardholderName}
                            hideIfEmpty
                        />

                        <StyledField
                            title={messages.creditCardStreet}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={details.creditCardStreet}
                            hideIfEmpty
                        />

                        <StyledField
                            title={messages.creditCardZipCode}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={details.creditCardZipCode}
                            hideIfEmpty
                        />

                        <StyledField
                            title={messages.creditCardProcessor}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={details.creditCardProcessor}
                            hideIfEmpty
                        />

                        <StyledField
                            title={messages.creditCardApproved}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={details.creditCardApproved ? messages.positiveBooleanValue : null}
                            hideIfEmpty
                        />

                        <StyledField
                            title={messages.pnRef}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={details.pnRef}
                            hideIfEmpty
                        />

                        <StyledField
                            title={messages.authCode}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={details.authCode}
                            hideIfEmpty
                        />

                        <StyledField
                            title={messages.avsStreetMatch}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={details.avsStreetMatch}
                            hideIfEmpty
                        />

                        <StyledField
                            title={messages.avsZipMatch}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={details.avsZipMatch}
                            hideIfEmpty
                        />
                    </FieldsSectionWrap>
                </SectionContainer>
            )}
        </>
    );
});

export default NetSuiteSalesOrderFooterFields;
