import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { CreateAmaxPayXeroBatchPayments } from './useCreateAmaxPayXeroBatchPayments.types';

export const useCreateAmaxPayXeroBatchPayments = (
    mutationOptions?: RequestMutationOptions<CreateAmaxPayXeroBatchPayments>
) => {
    return useMutate(requestsApiPaths.amaxPayXeroBatchPayments, {
        mutationOptions,
        apiVersion: 'v2',
    });
};
