import { useDispatch, useSelector } from 'modules/react-redux';
import { useCallback, useMemo } from 'react';
import { useDeleteReviewerV1Beta } from 'shared/data/v2';

import { saveTemplate } from '../../actions';
import { getStepApprovers, getStepEditors } from '../../selectors/stepSelectors';
import { ActiveWorkflow } from '../../types/activeWorkflow';
import {
    fieldSystemPurposesToAdd,
    fieldSystemPurposesToMigrate,
} from './MigrationFromReviewerV1PopupContent.constants';

export const useMigrate = (template: ActiveWorkflow) => {
    const dispatch = useDispatch();
    const { mutateAsync: deleteReviewerV1Beta } = useDeleteReviewerV1Beta();

    // reviewV1 step can only be the first step
    const reviewV1Step = template.steps[0];
    const reviewers = useSelector((state) => getStepEditors(state, reviewV1Step, template));
    const approvers = useSelector((state) => getStepApprovers(state, template, reviewV1Step));

    const [fieldsToAdd, fieldsToMigrate] = useSelector((state) => {
        const fields = Object.values(state.entities.fields).filter((field) => field.companyId === template.companyId);
        // fields that reviewersV1 has access to edit
        const fieldsToAdd = fields.filter((field) => fieldSystemPurposesToAdd.includes(field.systemPurpose));
        // fields that are in the reviewerV1 matrix, exclude read-only fields
        const fieldsToMigrate = fields.filter(
            (field) =>
                fieldSystemPurposesToMigrate.includes(field.systemPurpose) &&
                !reviewV1Step.readonlyFieldIds.includes(field.id)
        );

        return [fieldsToAdd, fieldsToMigrate];
    });

    const reviewersToMigrate = useMemo(() => {
        // only reviewersV1 who are also approvers
        const result = reviewers.filter((reviewer) => approvers.some((approver) => approver.id === reviewer.id));

        return result;
    }, [approvers, reviewers]);

    const migrate = useCallback(async () => {
        // remove reviewV1 data from the step
        const newStep = {
            ...reviewV1Step,
            editorMatrix: [],
            readonlyFieldIds: [],
            requiredFieldIds: [],
        };

        // migrate data if there are reviewers to migrate
        if (reviewersToMigrate.length > 0) {
            // migrate required fields
            newStep.editPermissionsRequiredFieldIds = reviewV1Step.requiredFieldIds;
            newStep.editingMatrix = reviewersToMigrate.map((reviewer) => {
                const editorRulesConditions = (
                    reviewV1Step.editorMatrix.find((line) => line.lineId === reviewer.id)?.rules?.[0]?.conditions || []
                ).filter((condition) => fieldsToMigrate.some((field) => field.id === condition.fieldId));

                return {
                    lineId: reviewer.id,
                    rules: [
                        {
                            conditions: [
                                // set allowEditing to true so reviewerV1 doesn't lose access to fields he can edit
                                ...fieldsToAdd.map((field) => ({
                                    fieldId: field.id,
                                    fieldName: field.name,
                                    fieldSystemPurpose: field.systemPurpose,
                                    conditionType: null,
                                    allowEditing: true,
                                })),
                                ...fieldsToMigrate.map((field) => ({
                                    fieldId: field.id,
                                    fieldName: field.name,
                                    fieldSystemPurpose: field.systemPurpose,
                                    conditionType: null,
                                    // migrate conditions for existing fields
                                    ...editorRulesConditions.find((c) => c.fieldId === field.id),
                                    allowEditing: true,
                                })),
                            ],
                        },
                    ],
                };
            });
        }

        // replace reviewV1 step by migrated step
        const newTemplate = {
            ...template,
            steps: [newStep, ...template.steps.slice(1)],
        };

        await Promise.all([
            dispatch(saveTemplate(newTemplate)),
            deleteReviewerV1Beta({
                params: {
                    path: { companyId: template.companyId },
                },
            }),
        ]);
    }, [deleteReviewerV1Beta, dispatch, fieldsToAdd, fieldsToMigrate, reviewV1Step, reviewersToMigrate, template]);

    return { migrate, reviewersToMigrate };
};
