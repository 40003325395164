import { mods } from '@approvalmax/theme';
import { Link } from '@approvalmax/ui';
import { LockSlimIcon } from 'modules/sprites';
import styled from 'styled-components';

import { ChangeMatchingPopupItemProps } from './ChangeMatchingPopupItem.types';

export const Container = styled.div`
    padding: 20px 60px 18px 0;
    width: 100%;
    display: flex;
    border-top: 1px solid #cacccd;

    &:first-of-type {
        border-top: none;
    }
`;

export const Content = styled.div`
    width: calc(100% - 80px);
    flex-grow: 0;
`;

export const Line = styled.div`
    align-items: center;
    margin-bottom: 15px;
    display: flex;
`;

export const LineReference = styled(Line)`
    width: 60%;
    margin-right: 40%;
    flex-grow: 0;
`;

const Text = styled.span<Pick<ChangeMatchingPopupItemProps, 'isBilled'>>`
    color: #000;
    font-size: 13px;
    white-space: pre-wrap;
    line-height: 16px;

    ${mods('isBilled', true)`
        color: #7f7d7d;
    `}
`;

export const StyledLink = styled(Link)`
    font-weight: 600;
    word-break: break-word;
    padding-right: 10px;
`;

export const Amount = styled(Text)`
    font-weight: 600;
    margin-left: auto;
`;

export const CheckboxContainer = styled.div`
    width: 80px;
    flex-shrink: 0;
    padding-top: 1px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
`;

export const GreyText = styled.span`
    font-size: 12px;
    line-height: 14px;
    color: #5e5c5c;
`;

export const InfoText = styled.span`
    font-size: 12px;
    line-height: 14px;
    color: #000;
    margin: 0 30px 0 20px;
`;

export const InfoTextReference = styled(InfoText)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const LockedIcon = styled(LockSlimIcon)`
    opacity: 0.4;
`;
