import { font, uTransparentLink } from 'modules/styles';
import styled from 'styled-components';

export const Root = styled.a`
    display: flex;
    align-items: center;
    cursor: pointer;

    ${uTransparentLink};
`;

export const Text = styled.div`
    margin-left: 5px;

    ${font(13, '#000')}
`;
