import { Mods, mods } from '@approvalmax/theme';
import { TransparentButton } from '@approvalmax/ui';
import { StyledMods } from '@styled-kit/mods';
import { DropdownTriangleIcon } from 'modules/sprites';
import { FC, PropsWithChildren } from 'react';
import { useToggle } from 'react-use';
import styled from 'styled-components';
import { VelocityTransitionGroup } from 'velocity-react';

const ToggleButton = styled(TransparentButton)`
    display: flex;
    align-items: center;
    padding: 15px 0 0;
    fill: #477753;
    transition: fill 500ms ease-in-out;
    cursor: pointer;
`;

const StyledIcon = styled(DropdownTriangleIcon)<StyledMods<Mods<'collapsed'>>>`
    transform: rotate(180deg);
    transition: transform 300ms ease-in-out;

    ${mods.collapsed.true`
        transform: rotate(0);
    `};
`;

const SectionTitle = styled.span`
    margin-right: 5px;
    text-transform: uppercase;
    color: #477753;
    font-weight: bold;
`;

interface CollapsibleSectionProps extends PropsWithChildren {
    defaultCollapsed?: boolean;
    title: string;
}

const CollapsibleSection: FC<CollapsibleSectionProps> = (props) => {
    const { defaultCollapsed = false, children, title } = props;

    const [collapsed, toggleCollapsed] = useToggle(defaultCollapsed);

    return (
        <div>
            <ToggleButton execute={toggleCollapsed}>
                <SectionTitle>{title}</SectionTitle>

                <StyledIcon $collapsed={collapsed} />
            </ToggleButton>

            <VelocityTransitionGroup
                enter={{ animation: 'slideDown', duration: 300 }}
                leave={{ animation: 'slideUp', duration: 300 }}
            >
                {collapsed ? <div>{children}</div> : undefined}
            </VelocityTransitionGroup>
        </div>
    );
};

export default CollapsibleSection;
