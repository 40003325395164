import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.NetSuite.NetSuiteRequestCard.RequestCardBillPayment', {
    nsTransactionNumberFieldText: 'Transaction number',
    nsCheckNumberFieldText: 'Check number',
    nsTransactionDateFieldText: 'Date',
    nsPostingPeriodFieldText: 'Posting period',
    nsMemoFieldText: 'Memo',
    nsPayablesAccountFieldText: 'A/P account',
    nsAccountFieldText: 'Account',
    nsDepartmentFieldText: 'Department',
    nsClassFieldText: 'Class',
    nsLocationFieldText: 'Location',
    nsToBePrintedFieldText: 'To be printed',
    nsPrintVoucherFieldText: 'Print voucher',
    toBePrintedPositiveValue: 'Yes',
    printVoucherPositiveValue: 'Yes',
});
