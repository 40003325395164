import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { requestsReportsApiPaths } from '../paths';
import { CreateManualJournalFromBillInvoice } from './useCreateManualJournalFromBillInvoice.types';

export const useCreateManualJournalFromBillInvoice = (
    mutationOptions?: RequestMutationOptions<CreateManualJournalFromBillInvoice>
) => {
    return useMutate(requestsReportsApiPaths.createManualJournalFromBillInvoice, {
        mutationOptions,
        apiVersion: 'v1',
        method: 'post',
        mapToCamelCase: true,
    });
};
