import { getLockDateWarningText } from 'modules/common/selectors/requestSelectors.Xero';
import { FC, memo } from 'react';
import bemFactory from 'react-bem-factory';

import FieldsSection from '../../../components/FieldsSection';
import { messages } from './XeroCreditNoteReceivableFields.messages';
import { XeroCreditNoteReceivableFieldsProps } from './XeroCreditNoteReceivableFields.types';

const qa = bemFactory.qa('reql-xero-fields-section');

const XeroCreditNoteReceivableFields: FC<XeroCreditNoteReceivableFieldsProps> = memo((props) => {
    const { request } = props;

    const details = request.details;
    const lockDateWarningText = getLockDateWarningText(request);

    return (
        <FieldsSection>
            <FieldsSection.Field
                qa={qa('date')}
                title={messages.cnRecDateFieldText}
                valueType={FieldsSection.Field.ValueType.Date}
                value={details.date}
                warningText={lockDateWarningText}
            />

            <FieldsSection.Field qa={qa('number')} title={messages.cnRecNumberFieldText} value={details.number} />

            {details.reference ? (
                <FieldsSection.Field
                    qa={qa('reference')}
                    title={messages.cnRecReferenceFieldText}
                    value={details.reference}
                />
            ) : (
                <FieldsSection.Field.Empty />
            )}

            {details.brandingTheme ? (
                <FieldsSection.Field
                    qa={qa('brandingTheme')}
                    title={messages.cnRecBrandingThemeFieldText}
                    value={details.brandingTheme}
                />
            ) : (
                <FieldsSection.Field.Empty />
            )}
        </FieldsSection>
    );
});

export default XeroCreditNoteReceivableFields;
