import { Button, Popup } from '@approvalmax/ui';
import { memo } from 'react';

import { getModifiedPropertyName } from './DiscrepanciesStep.helpers';
import { messages } from './DiscrepanciesStep.messages';
import {
    ButtonBox,
    Content,
    StyledFirstTh,
    StyledIcon,
    StyledTable,
    StyledTd,
    StyledTdDiscrepancy,
    StyledTh,
    StyledTr,
} from './DiscrepanciesStep.styles';
import { DiscrepanciesStepTypes } from './DiscrepanciesStep.types';

const DiscrepanciesStep = memo<DiscrepanciesStepTypes>((props) => {
    const { title, beneficiaryDiscrepancies, onRequestClose } = props;

    return (
        <Popup.DefaultContent title={title}>
            <Content>
                <div>{messages.beforeTable}</div>

                <br />

                <div>
                    <StyledTable>
                        <StyledTr>
                            <StyledFirstTh>{messages.beneficiary}</StyledFirstTh>

                            <StyledTh>{messages.discrepancy}</StyledTh>
                        </StyledTr>

                        <tbody>
                            {beneficiaryDiscrepancies.map((discrepancy) => (
                                <StyledTr key={discrepancy.beneficiaryName}>
                                    <StyledTd>{discrepancy.beneficiaryName}</StyledTd>

                                    <StyledTdDiscrepancy>
                                        <StyledIcon />

                                        {discrepancy.modifiedProperties.map((property) => (
                                            <div key={property.name}>- {getModifiedPropertyName(property.name)}</div>
                                        ))}

                                        {discrepancy.isBeneficiaryDeleted && messages.isRemoved}
                                    </StyledTdDiscrepancy>
                                </StyledTr>
                            ))}
                        </tbody>
                    </StyledTable>
                </div>

                <br />

                <div>{messages.afterTable}</div>

                <br />

                <ButtonBox>
                    <Button execute={onRequestClose}>{messages.ok}</Button>
                </ButtonBox>
            </Content>
        </Popup.DefaultContent>
    );
});

export default DiscrepanciesStep;
