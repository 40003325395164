import './lineItemFooterSeparator.scss';

import { FC, memo } from 'react';
import bemFactory from 'react-bem-factory';

const bem = bemFactory.block('reql-line-item-footer-separator');

interface LineItemFooterSeparatorProps {
    className?: string;
}

const LineItemFooterSeparator: FC<LineItemFooterSeparatorProps> = (props) => {
    const { className } = props;

    return <div className={bem.add(className)()} />;
};

export default memo(LineItemFooterSeparator);
