import { useMemo } from 'react';

import { useGetXeroCounterpartyPaymentDetails, useGetXeroCounterpartyPaymentDetailsForRequest } from '../../data/v1';
import { FormState, UseInitialFormValuesParams } from './EditPaymentDetailsPopupContent.types';

export const useInitialFormValues = ({
    paymentDetailsId,
    companyId,
    contact,
    preloadedListOfPaymentDetails,
    requestId,
}: UseInitialFormValuesParams): FormState => {
    const { data } = useGetXeroCounterpartyPaymentDetails(
        {
            path: {
                companyId,
                counterpartyId: contact?.counterpartyId,
            },
        },
        {
            enabled: !preloadedListOfPaymentDetails && !requestId,
        }
    );

    const { data: xeroCounterpartyPaymentDetailsForRequest } = useGetXeroCounterpartyPaymentDetailsForRequest(
        {
            path: {
                companyId,
                counterpartyId: contact?.counterpartyId,
                requestId,
            },
        },
        {
            enabled: !preloadedListOfPaymentDetails && Boolean(requestId),
        }
    );

    const listOfPaymentDetailsForCounterParty =
        preloadedListOfPaymentDetails || data?.items || xeroCounterpartyPaymentDetailsForRequest?.items;

    const selectedPaymentDetails = useMemo(
        () => listOfPaymentDetailsForCounterParty?.find((item) => item.id === paymentDetailsId),
        [listOfPaymentDetailsForCounterParty, paymentDetailsId]
    );

    const noAnyPaymentDetailsForCounterparty = listOfPaymentDetailsForCounterParty?.length === 0;

    const isGBPCurrency = contact?.financialDetails.currency === 'GBP' || !contact?.financialDetails.currency;

    if (!selectedPaymentDetails || !paymentDetailsId) {
        const defaultAccountName = contact?.financialDetails.bankAccountName || contact?.name || '';

        return {
            isDefault: true,
            country: 'GB',
            currency: isGBPCurrency ? 'GBP' : '',
            name: noAnyPaymentDetailsForCounterparty ? defaultAccountName : '',
            accountNumber: noAnyPaymentDetailsForCounterparty ? contact?.financialDetails.bankAccountNumber ?? '' : '',
            sortCode: noAnyPaymentDetailsForCounterparty ? contact?.financialDetails.sortCode ?? '' : '',
        };
    }

    return {
        isDefault: selectedPaymentDetails.isDefault,
        ...selectedPaymentDetails.bankAccount,
    };
};
