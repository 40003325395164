import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { workflowsApiPaths } from '../paths';
import { GetWorkflows } from './useGetWorkflows.types';

export const useGetWorkflows = (
    params?: RequestParams<GetWorkflows>,
    queryOptions?: RequestQueryOptions<GetWorkflows<true>>
) => {
    return useGet(workflowsApiPaths.getWorkflows, {
        params,
        queryOptions,
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};

export const useGetWorkflowsRaw = (
    params?: RequestParams<GetWorkflows>,
    queryOptions?: RequestQueryOptions<GetWorkflows>
) => {
    return useGet(workflowsApiPaths.getWorkflows, {
        params,
        queryOptions,
        apiVersion: 'v1',
    });
};
