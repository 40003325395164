import { ChevronLeftIcon } from '@approvalmax/ui';
import { Box, Flex, Spacing, Text } from '@approvalmax/ui/src/components';
import SignUpProgress from 'pages/auth/components/SignUpProgress/SignUpProgress';
import { FC, memo } from 'react';

import { messages } from './VideoContent.messages';
import { BackButton, BackContainter, StyledButton } from './VideoContent.styles';
import { VideoContentProps } from './VideoContent.types';

const VideoContent: FC<VideoContentProps> = memo((props) => {
    const { videoSrc, title, description, createDemo, connectDemo, isLoading, onBack, onCreateDemo, onConnectDemo } =
        props;

    return (
        <Flex width={500} direction='column' alignItems='center' spacing='0'>
            <BackContainter>
                <BackButton theme='secondary' preset='small' execute={onBack} disabled={isLoading}>
                    <ChevronLeftIcon width={18} height={18} />

                    {messages.back}
                </BackButton>

                <SignUpProgress currentStep={3} />
            </BackContainter>

            <Box width={450}>
                <Text font='headline' fontSize='small' textAlign='center' spacing='28 0 0'>
                    {title}
                </Text>
            </Box>

            <Box width={390}>
                <Text font='body' textAlign='center' spacing='28 0 0'>
                    {description}
                </Text>
            </Box>

            <Spacing height={20} />

            <iframe
                width='500'
                height='281.25'
                src={videoSrc}
                title='YouTube video player'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowFullScreen
            />

            <Spacing height={28} />

            <StyledButton execute={onCreateDemo} disabled={isLoading}>
                {createDemo}
            </StyledButton>

            <Spacing height={12} />

            <StyledButton theme='secondary' execute={onConnectDemo} disabled={isLoading}>
                {connectDemo}
            </StyledButton>
        </Flex>
    );
});

export default VideoContent;
