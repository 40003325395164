import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { integrationApiPaths } from '../paths';
import { DeleteXeroCounterpartyPaymentDetails } from './useDeleteXeroCounterpartyPaymentDetails.types';

export const useDeleteXeroCounterpartyPaymentDetails = (
    mutationOptions?: RequestMutationOptions<DeleteXeroCounterpartyPaymentDetails>
) => {
    return useMutate(integrationApiPaths.xeroCounterpartyPaymentDetail, {
        mutationOptions,
        method: 'delete',
        apiVersion: 'v1',
    });
};
