import { getLockDateWarningText } from 'modules/common/selectors/requestSelectors.Xero';
import { FC, memo } from 'react';
import bemFactory from 'react-bem-factory';

import FieldsSection from '../../../components/FieldsSection';
import { messages } from './XeroBillFields.messages';
import { XeroBillFieldsProps } from './XeroBillFields.types';

const qa = bemFactory.qa('reql-xero-fields-section');

const XeroBillFields: FC<XeroBillFieldsProps> = memo((props) => {
    const { request } = props;

    const details = request.details;
    const lockDateWarningText = getLockDateWarningText(request);

    return (
        <FieldsSection>
            <FieldsSection.Field
                qa={qa('date')}
                title={messages.billDateFieldText}
                valueType={FieldsSection.Field.ValueType.Date}
                value={details.date}
                warningText={lockDateWarningText}
            />

            <FieldsSection.Field
                qa={qa('dueDate')}
                title={messages.billDueDateFieldText}
                valueType={FieldsSection.Field.ValueType.Date}
                value={details.dueDate}
            />

            {details.number ? (
                <FieldsSection.Field qa={qa('reference')} title={messages.referenceFieldText} value={details.number} />
            ) : (
                <FieldsSection.Field.Empty />
            )}

            <FieldsSection.Field.Empty />
        </FieldsSection>
    );
});

export default XeroBillFields;
