import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { authApiPaths } from '../paths';
import { CompleteRegistration } from './useCompleteRegistration.types';

export const useCompleteRegistration = (mutationOptions?: RequestMutationOptions<CompleteRegistration>) => {
    return useMutate(authApiPaths.completeRegistration, {
        mutationOptions,
        apiVersion: 'v2',
    });
};
