import './attachmentsSection.scss';

import { openSplitViewState } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { AttachmentList } from 'modules/components/Attachment/AttachmentList';
import { domain } from 'modules/data';
import { FC, memo, useCallback } from 'react';
import bemFactory from 'react-bem-factory';
import { FormattedMessage } from 'react-intl';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { splitViewGoToBarDefaultSettings, splitViewGoToBarSettingsState } from 'shared/components';
import { activeAttachmentIdState, ContentSplitViewMode, contentSplitViewState } from 'shared/states';

import SectionContainer from '../../components/SectionContainer';

const i18nPrefix = 'requestList/containers/card/AttachmentsSection';

const bem = bemFactory.block('reql-attachments-section');
const qa = bemFactory.qa('reql-attachments-section');

interface AttachmentsSectionProps {
    className?: string;
    request: selectors.types.ExpandedRequest;
}

const AttachmentsSection: FC<AttachmentsSectionProps> = (props) => {
    const { className, request } = props;

    const setOpenSplitView = useSetRecoilState(openSplitViewState);
    const [contentSplitView, setContentSplitView] = useRecoilState(contentSplitViewState);
    const activeAttachmentId = useRecoilValue(activeAttachmentIdState);
    const setSplitViewGoToBarSettings = useSetRecoilState(splitViewGoToBarSettingsState);

    const { attachments } = request;
    const { isActualVersion } = request.flags;
    const onOpen = useCallback(
        (attachment: domain.RequestAttachment) => {
            if (
                (contentSplitView.mode === ContentSplitViewMode.BillLinkedToBatchPayment ||
                    contentSplitView.mode === ContentSplitViewMode.NetSuiteBillLinkedToBillPayment) &&
                contentSplitView.billId === request.id
            ) {
                setSplitViewGoToBarSettings({
                    show: true,
                    targetContent: {
                        mode: contentSplitView.mode,
                        billId: request.id,
                    },
                    targetContentName: request.displayName,
                });
                setContentSplitView({
                    mode:
                        contentSplitView.mode === ContentSplitViewMode.BillLinkedToBatchPayment
                            ? ContentSplitViewMode.AttachmentsToBillLinkedToBatchPayment
                            : ContentSplitViewMode.AttachmentsToNetSuiteBillLinkedToBillPayment,
                    attachmentId: attachment.id,
                    billId: request.id,
                    billFriendlyName: request.displayName,
                });
            } else {
                setSplitViewGoToBarSettings(splitViewGoToBarDefaultSettings);
                setContentSplitView({
                    mode: ContentSplitViewMode.AttachmentsToRequest,
                    attachmentId: attachment.id,
                });
                setOpenSplitView(true);
            }
        },
        [
            contentSplitView,
            request.displayName,
            request.id,
            setContentSplitView,
            setSplitViewGoToBarSettings,
            setOpenSplitView,
        ]
    );

    if (attachments.length === 0) {
        return null;
    }

    return (
        <SectionContainer
            text={<FormattedMessage id={`${i18nPrefix}.filesSectionText`} defaultMessage='Files' />}
            className={bem.add(className)()}
            data-qa={qa()}
        >
            <AttachmentList
                attachments={attachments}
                onOpen={onOpen}
                activeAttachmentId={activeAttachmentId}
                disabled={!isActualVersion}
            />
        </SectionContainer>
    );
};

export default memo(AttachmentsSection);
