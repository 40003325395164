import { ExtractComponentProp } from '@approvalmax/types';
import { ChevronDownIcon } from '@approvalmax/ui';
import { Button, Flex, Popup, Text } from '@approvalmax/ui/src/components';
import { errorHelpers } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { FC, memo, useCallback, useMemo } from 'react';
import bemFactory from 'react-bem-factory';
import { useToggle } from 'react-use';

import { ChoiceTypePopupContent } from './components';
import { messages } from './StepTypeEditor.messages';
import { StepTypeEditorProps } from './StepTypeEditor.types';

const qa = bemFactory.qa('wort-step-approval-condition');

const StepTypeEditor: FC<StepTypeEditorProps> = memo((props) => {
    const { condition, approvalCount, company, disabled, onConditionChange } = props;

    const [isPopupOpen, setIsPopupOpen] = useToggle(false);

    const conditionText = useMemo(() => {
        switch (condition) {
            case domain.TemplateStepType.DecisionStep:
                return messages.stepTypeDefault;

            case domain.TemplateStepType.AnyOfDecisionStep:
                return approvalCount && approvalCount > 1
                    ? messages.stepTypeAnyOfCount({ count: approvalCount })
                    : messages.stepTypeAnyOf;

            default:
                return errorHelpers.assertNever(condition);
        }
    }, [condition, approvalCount]);

    const onConditionBtnClick = useCallback(() => {
        if (!company.flags.isAuditor) {
            setIsPopupOpen(true);
        }
    }, [company.flags.isAuditor, setIsPopupOpen]);

    const handleTypeSelect = useCallback<ExtractComponentProp<typeof ChoiceTypePopupContent, 'onSelect'>>(
        (type, approvalCount) => {
            onConditionChange(type, approvalCount);
            setIsPopupOpen(false);
        },
        [onConditionChange, setIsPopupOpen]
    );

    return (
        <>
            <Flex spacing='4' alignItems='center'>
                <Text font='label' fontSize='small' color='midnight70'>
                    {messages.label}
                </Text>

                <Button
                    disabled={disabled || company.flags.isAuditor}
                    onClick={onConditionBtnClick}
                    data-qa={qa('open-approval-condition-popup')}
                    endIcon={company.flags.isAuditor ? undefined : <ChevronDownIcon size={12} />}
                    color='transparent'
                    noPadding
                    uppercase={false}
                    size='small'
                >
                    {conditionText}
                </Button>
            </Flex>

            <Popup open={isPopupOpen} onToggle={setIsPopupOpen}>
                <ChoiceTypePopupContent
                    defaultStepType={condition}
                    defaultNumberOfApprovers={approvalCount}
                    onSelect={handleTypeSelect}
                />
            </Popup>
        </>
    );
});

export default StepTypeEditor;
