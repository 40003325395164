import { TextButton } from '@approvalmax/ui';
import { font } from 'modules/styles';
import { LineItemsTable } from 'pages/requestList/components/lineItemsSection/LineItemsTable';
import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    background: white;
    padding: 15px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.3);
    margin-top: 10px;
    transform: translate(16px, 4px);

    &:after {
        position: absolute;
        top: -7px;
        right: 14px;
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 8px solid white;
    }
`;

export const TableContainer = styled.div`
    display: flex;
`;

export const StyledLineItemsTable = styled(LineItemsTable)`
    width: 100%;
`;

export const Control = styled.div`
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const StyledTextButton = styled(TextButton)`
    margin-top: 32px;
`;

export const POInfo = styled.div`
    margin-top: 10px;
`;

export const Date = styled.span`
    margin-left: 20px;
    ${font(12, '#5e5c5c')};
`;

export const Value = styled.b`
    color: black;
`;
