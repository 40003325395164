import { domain } from 'modules/data';

import { messages } from './getGrnStatusText.messages';

export const getGrnStatusText = (grnStatus?: domain.GoodsReceivedNotesStatus) => {
    switch (grnStatus) {
        case domain.GoodsReceivedNotesStatus.FullyReceived:
            return messages.statusFullyReceived;
        case domain.GoodsReceivedNotesStatus.PartiallyReceived:
            return messages.statusPartiallyReceived;
        case domain.GoodsReceivedNotesStatus.NotReceived:
            return messages.statusNotReceived;
        default:
            return null;
    }
};
