import { Reference } from '@approvalmax/types';
import { Flex, Select, Spacing, Tag } from '@approvalmax/ui/src/components';
import { HTMLTextFieldElement } from '@approvalmax/ui/src/components/TextField/TextField.types';
import { arrayHelpers, browserHelpers } from '@approvalmax/utils';
import { constants, dataProviders, selectors } from 'modules/common';
import { domain } from 'modules/data';
import { FC, memo, useCallback, useMemo, useRef, useState } from 'react';
import bemFactory from 'react-bem-factory';

import { allowedConditionTypes } from './GeneralConditionCell.constants';
import { useConditionItems } from './GeneralConditionCell.hooks';
import { messages } from './GeneralConditionCell.messages';
import { ChangeButton, Root } from './GeneralConditionCell.styles';
import { GeneralConditionCellProps } from './GeneralConditionCell.types';

const qa = bemFactory.qa('wfc-exact-condition-cell');

const GeneralConditionCell: FC<GeneralConditionCellProps> = memo((props) => {
    const {
        lineId,
        rule,
        field,
        condition,
        readonly,
        onConditionChange,
        onCreateFieldOption,
        requiredFieldIds,
        matrixType,
    } = props;

    const [inEdit, setInEdit] = useState(condition.conditionType === null);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const selectRef = useRef<HTMLTextFieldElement | null>(null);
    const conditionItems = useConditionItems(field.name);

    const changeConditionType = useCallback(
        (conditionType: (typeof allowedConditionTypes)[number]) => {
            let newCondition: domain.AlwaysTrueCondition | domain.ExactValuesCondition;

            if (conditionType === 'any') {
                newCondition = {
                    ...condition,
                    conditionType: null,
                };
            } else {
                newCondition = {
                    ...condition,
                    conditionType,
                    exactValues: arrayHelpers.cloneImmutableArray(
                        ('exactValues' in condition && condition.exactValues) || []
                    ),
                };
            }

            onConditionChange(lineId, rule, field, newCondition);

            if (conditionType === 'any') {
                setInEdit(false);
            } else {
                setTimeout(() => {
                    selectRef.current?.focus();
                }, 0);
            }
        },
        [condition, field, lineId, onConditionChange, rule]
    );

    const isOptionalField = !requiredFieldIds.includes(field.id);
    const staticValues: Reference[] = useMemo(
        () =>
            selectors.field.allowsEmptyValue(field, matrixType, isOptionalField)
                ? [dataProviders.FieldDataProvider.EmptyValue]
                : [],
        [field, isOptionalField, matrixType]
    );
    const multItems = useMemo(
        () =>
            condition.conditionType !== null ? [...condition.exactValues, ...staticValues, ...field.exactValues] : [],
        [condition, staticValues, field.exactValues]
    );

    const changeExactValues = useCallback(
        (exactValues: string[], items: Reference[]) => {
            const newValue = exactValues.find((val) => multItems.every((item) => item.id !== val));

            if (newValue) {
                const newOption = {
                    id: newValue,
                    text: newValue,
                };

                onCreateFieldOption(field, newOption);

                return;
            }

            const newCondition = {
                ...condition,
                exactValues: items.filter(
                    (item) => exactValues.includes(item.id) && item.id !== constants.commonConstants.EMPTY_VALUE_ID
                ),
            };

            onConditionChange(lineId, rule, field, newCondition);
        },
        [condition, field, lineId, multItems, onConditionChange, onCreateFieldOption, rule]
    );

    const onOpen = useCallback(
        (open: boolean) => {
            if (!open) {
                const delay = browserHelpers.isSafari() ? 150 : 1;

                setTimeout(() => {
                    if (containerRef.current && !containerRef.current.contains(document.activeElement)) {
                        setInEdit(condition.conditionType === null);
                    }
                }, delay);
            }
        },
        [condition.conditionType]
    );

    const onClick = useCallback(() => {
        setInEdit(true);
        selectRef.current?.focus();
    }, []);

    const handleBlur = useCallback(() => {
        const delay = browserHelpers.isSafari() ? 150 : 1;

        setTimeout(() => {
            if (containerRef.current && !containerRef.current.contains(document.activeElement)) {
                setInEdit(condition.conditionType === null);
            }
        }, delay);
    }, [condition.conditionType]);

    const invalid = !selectors.matrix.isValidCondition(condition);
    const exactValuesPlaceholder = messages.selectFieldName({ fieldName: (field.name || '').toLowerCase() });
    const exactValues = condition.conditionType !== null ? condition.exactValues : [];
    const values = exactValues.map((v) => v.id);

    const showSelect = Boolean(condition.conditionType);

    const handleRemove = useCallback(
        (item: Reference) => () => {
            if (!condition.conditionType) return;

            const newCondition = {
                ...condition,
                exactValues: condition.exactValues.filter((v) => v.id !== item.id),
            };

            onConditionChange(lineId, rule, field, newCondition);
        },
        [condition, field, lineId, onConditionChange, rule]
    );

    const tagColor = useMemo(
        () =>
            condition.conditionType === domain.ConditionType.NegativeExactValuesCondition ? 'midnight70' : 'midnight40',
        [condition.conditionType]
    );

    return (
        <Root width='172px' ref={containerRef} data-qa={qa()} data-qa-id={field.id} data-qa-name={field.name}>
            <Select
                items={conditionItems}
                value={condition.conditionType || 'any'}
                onChange={changeConditionType}
                textActivatorColor='midnight70'
                textActivatorHoverColor='blue90'
                openIconOnHover
                size='small'
                noInput
                dropdownWidth='max-content'
                onOpen={onOpen}
                data-qa={qa('condition-type-dropdown')}
            />

            <Spacing height={4} />

            {inEdit && showSelect && (
                <Select
                    placeholder={exactValuesPlaceholder}
                    disabled={readonly}
                    multiple
                    value={values}
                    items={multItems}
                    itemNameKey='text'
                    autocomplete
                    creatable
                    onChange={changeExactValues}
                    size='xsmall'
                    onBlur={handleBlur}
                    activatorRef={selectRef}
                    initFocus
                    data-qa={qa('values-editor')}
                    tagColor={tagColor}
                />
            )}

            {!inEdit && showSelect && (
                <>
                    <Flex spacing='4'>
                        {exactValues.map((item, index) => (
                            <Tag
                                key={`${item.id}-${index}`}
                                closable
                                size='xsmall'
                                onClose={handleRemove(item)}
                                color={tagColor}
                                title={item.text}
                            >
                                {item.text}
                            </Tag>
                        ))}
                    </Flex>

                    <Spacing height={4} />

                    <ChangeButton
                        onClick={onClick}
                        size='xsmall'
                        color={invalid ? 'red40' : 'blue10'}
                        $active={invalid}
                    >
                        {messages.selectFieldName({ fieldName: field.name })}
                    </ChangeButton>
                </>
            )}
        </Root>
    );
});

export default GeneralConditionCell;
