import { mathService } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { isTotalLine, TotalLine } from 'pages/requestList/types';
import { useMemo } from 'react';
import { noValue } from 'shared/constants';
import { UseGetAmaxPayXeroRequestDetailsResponse } from 'shared/data';

import {
    getUniqueSuppliers,
    groupAndSummarizeItemsBySupplier,
    sortSuppliers,
} from './XeroAmaxPayBatchPaymentRequestCard.helpers';

export const useBankAccountData = (
    items: (domain.AmaxPayPaymentItem | TotalLine)[],
    requestDetails: UseGetAmaxPayXeroRequestDetailsResponse | undefined
) => {
    const bankAccount = useMemo(() => {
        if (!items.length) {
            return null;
        }

        const firstItem = items[0];

        // For now we only support one bank account for all items
        // so we can take bank account id from first item
        const selectedBankAccountId = isTotalLine(firstItem)
            ? firstItem.amaxPayBankAccountId
            : firstItem.amaxPayBankAccount?.id;

        return requestDetails?.bankAccounts.find((bankAccount) => bankAccount.id === selectedBankAccountId);
    }, [requestDetails?.bankAccounts, items]);

    const xeroBankAccount = bankAccount?.xeroBankAccount;

    return bankAccount
        ? {
              ...bankAccount,
              xeroBankAccount: {
                  id: xeroBankAccount?.id ?? noValue,
                  name: xeroBankAccount?.name ?? noValue,
                  currencyCode: xeroBankAccount?.currencyCode ?? noValue,
                  bankAccountNumber: xeroBankAccount?.bankAccountNumber ?? noValue,
                  sortCode: xeroBankAccount?.sortCode ?? noValue,
              },
          }
        : null;
};

export const useAmounts = (items: domain.AmaxPayPaymentItem[]) => {
    const amounts = useMemo(() => {
        const totals = items.reduce(
            (acc, item) => {
                if (item.paymentStatus === domain.AmaxPayPaymentStatus.CancelledByUser) {
                    return acc;
                }

                acc.total = mathService.add(acc.total, item.amount);

                if (item.paymentStatus === domain.AmaxPayPaymentStatus.Paid) {
                    acc.totalPaid = mathService.add(acc.totalPaid, item.amount);
                }

                return acc;
            },
            {
                total: 0,
                totalPaid: 0,
            }
        );

        return {
            ...totals,
            remaining: mathService.subtract(totals.total, totals.totalPaid),
        };
    }, [items]);

    return amounts;
};

export const useGroupedItemsWithSupplierTotals = (items: domain.AmaxPayPaymentItem[], isMergedBySupplier: boolean) =>
    useMemo(() => {
        if (!isMergedBySupplier) {
            return items;
        }

        const suppliers = getUniqueSuppliers(items);
        const sortedSuppliers = sortSuppliers(suppliers);

        return groupAndSummarizeItemsBySupplier<domain.AmaxPayPaymentItem>(items, sortedSuppliers);
    }, [isMergedBySupplier, items]);
