import './lineItemFooterTotal.scss';

import React, { FC } from 'react';
import bemFactory from 'react-bem-factory';

const bem = bemFactory.block('reql-line-item-footer-total');
const qa = bemFactory.qa('reql-line-item-footer-total');

interface LineItemFooterTotalProps {
    className?: string;
    qa?: string;
    label: React.ReactNode;
    value: string;
}

const LineItemFooterTotal: FC<LineItemFooterTotalProps> = (props) => {
    const { className, qa: qa$, label, value } = props;

    return (
        <div className={bem.add(className)()} data-qa={qa()} data-qa-id={qa$}>
            <div className={bem('label')} data-qa={qa('label')}>
                {label}
            </div>

            <div className={bem('value')} data-qa={qa('value')}>
                {value}
            </div>
        </div>
    );
};

export default LineItemFooterTotal;
