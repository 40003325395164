export const companiesApiPaths = <const>{
    create: 'Companies/create',
    select: 'Companies/select',
    edit: 'Companies/edit',
    delete: 'Companies/delete',
    migrateToMatchingV2: 'Companies/migrateToMatchingV2',
    getUserContext: 'Companies/getUserContext',
    finishNetSuiteIntegration: 'Companies/finishNetSuiteIntegration',
    selectFields: 'Companies/selectFields',
    getMatchingSettings: 'Companies/getMatchingSettings',
    getAllParticipantWorkflowVersions: 'Companies/getAllParticipantWorkflowVersions',
    getAllOpenRequestsWithParticipantDecisionRequired: 'Companies/getAllOpenRequestsWithParticipantDecisionRequired',
    getQuantityOfAllOpenRequestsWithParticipantDecisionRequired:
        'Companies/getQuantityOfAllOpenRequestsWithParticipantDecisionRequired',
    offboardParticipantWithAllRequestsReplacement: 'Companies/offboardParticipantWithAllRequestsReplacement',
    updateParticipantPermissions: 'Companies/:companyId/participants/:participantId/permissions',
    respondToInvitation: 'Companies/respondToInvitation',
    getBudgetSettings: 'Companies/getBudgetSettings',
    updateBudgetSettings: 'Companies/setBudgetSettings',
    pullIntegrations: 'companies/pullIntegrations',
    getNetSuiteCustomCompanyFields: 'companies/selectNetSuiteCustomCompanyFields',
    updateNetSuiteFields: 'companies/addOrUpdateNetSuiteFields',
};
