import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { workflowsApiPaths } from '../paths';
import { DeleteReviewerV1Beta } from './useDeleteReviewerV1Beta.types';

export const useDeleteReviewerV1Beta = (mutationOptions?: RequestMutationOptions<DeleteReviewerV1Beta>) => {
    return useMutate(workflowsApiPaths.workflowsReviewerV1, {
        mutationOptions,
        method: 'delete',
        apiVersion: 'v2',
    });
};
