import { intl } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { ColumnDefinition } from 'pages/requestList/selectors/types/ColumnDefinition';

import { messages } from './BalanceStep.messages';

export const columnDefinitions: ColumnDefinition<domain.RequestCurrentBalance>[] = [
    {
        id: 'currency',
        name: messages.currency,
        value: (li) => li.currencyIsoCode,
    },
    {
        id: 'available',
        name: messages.available,
        value: (li) => intl.formatNumber(li.availableAmount),
    },
    {
        id: 'toBePaid',
        name: messages.toBePaid,
        value: (li) => intl.formatNumber(li.toBePaidAmount),
    },
];
