import { LoadingSpinner } from '@approvalmax/ui';
import { intl } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { FC, memo } from 'react';

import { messages } from './ContactInfo.messages';
import { LoaderContainer, Root } from './ContactInfo.styles';
import { ToolbarAddressProps } from './ContactInfo.types';

const Counterparty: FC<ToolbarAddressProps> = (props) => {
    const { counterparty, request, showVatRegistration = true, showAddress = true, isLoading } = props;

    if (
        request.integrationCode === domain.IntegrationCode.NetSuiteBill ||
        request.integrationCode === domain.IntegrationCode.NetSuitePO ||
        request.integrationCode === domain.IntegrationCode.NetSuiteSalesOrder ||
        request.integrationCode === domain.IntegrationCode.NetSuiteVRA
    ) {
        const { details } = request;

        const fullAddress = details.address ? details.address.fullAddress?.replace(/<br>/g, ' ') : '';

        return (
            <Root>
                {fullAddress && <div>{fullAddress}</div>}

                {details.vatRegistrationNumber && showVatRegistration && (
                    <div>VAT Registration: {details.vatRegistrationNumber}</div>
                )}
            </Root>
        );
    }

    if (request.integrationCode === domain.IntegrationCode.NetSuiteInvoice) {
        const {
            details: { billingAddress, vatRegistrationNumber },
        } = request;

        const fullAddress = showAddress && billingAddress && billingAddress.fullAddress?.replace(/<br>/g, ' ');
        const vatRegistration = showVatRegistration && vatRegistrationNumber;

        if (!fullAddress && !vatRegistration) {
            return null;
        }

        return (
            <Root>
                {fullAddress && <div>{fullAddress}</div>}

                {vatRegistration && <div>VAT Registration: {vatRegistration}</div>}
            </Root>
        );
    }

    if (request.integrationCode === domain.IntegrationCode.DearPo) {
        const { details } = request;

        const fullAddress = details.billingAddress ? details.billingAddress.replace(/<br>/g, ' ').trim() : '';

        return fullAddress ? (
            <Root>
                <div>{fullAddress}</div>
            </Root>
        ) : null;
    }

    if (request.integrationCode === domain.IntegrationCode.NetSuiteBillPayment) {
        const { details } = request;

        if (showAddress && details.payeeAddress?.fullAddress) {
            const fullAddress = details.payeeAddress.fullAddress.replace(/<br>/g, ' ').trim();

            return (
                <Root>
                    <div>{fullAddress}</div>
                </Root>
            );
        }

        return null;
    }

    if (isLoading)
        return (
            <Root>
                <LoaderContainer>
                    <LoadingSpinner />
                </LoaderContainer>
            </Root>
        );

    if (!counterparty) {
        return null;
    }

    const address = [counterparty.city, counterparty.region, counterparty.postalCode, counterparty.country]
        .filter((item) => item)
        .join(', ');

    const accountNumber = counterparty.accountNumber
        ? intl.formatMessage(messages.accountNumber, { accountNumber: counterparty.accountNumber })
        : '';

    const taxNumber = counterparty.taxNumber
        ? intl.formatMessage(messages.taxNumber, { taxNumber: counterparty.taxNumber })
        : '';

    const rows = [
        accountNumber,
        counterparty.addressLine1,
        counterparty.addressLine2,
        counterparty.addressLine3,
        address,
        taxNumber,
        counterparty.emailAddress,
    ].filter((item) => item) as string[];

    if (!rows.length) {
        return null;
    }

    return (
        <Root>
            {rows.map((row) => (
                <div key={row}>{row}</div>
            ))}
        </Root>
    );
};

export default memo(Counterparty);
