import { Box, Flex, Grid } from '@approvalmax/ui/src/components';
import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';

import { messages } from './PasskeysSettings.messages';
import { Title } from './PasskeysSettings.styles';

export const Loading = memo(() => {
    return (
        <Box width='100%' spacing='12 0'>
            <Flex alignItems='center' justifyContent='space-between' width='100%'>
                <Title>{messages.title}</Title>

                <Skeleton width={80} height={25} />
            </Flex>

            <Grid gap={12} padding='12 0'>
                <Skeleton height={60} />

                <Skeleton height={60} />
            </Grid>
        </Box>
    );
});

Loading.displayName = 'Loading';
