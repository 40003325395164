import { DateTimeIsoString, Guid } from '@approvalmax/types';

import { Permission } from '../backend';
import { TwoFaEnforcementType } from './TwoFa';
import { User } from './User';

export enum AllFeaturesTrialStatus {
    Active = 'Active',
    InActive = 'InActive',
    Expired = 'Expired',
    AwaitingActivation = 'AwaitingActivation',
}

export enum CompanyBetaFeature {
    HideButtonsExternalApproversV1 = 'HideButtonsExternalApproversV1',
    HideReassignForApprovers = 'HideReassignForApprovers',
    HideBatchPaymentInContact = 'HideBatchPaymentInContact',
    XeroMatchingV2 = 'XeroMatchingV2',
    BudgetingZeroAmounts = 'BudgetingZeroAmounts',
    XeroAirwallexBatchPayment = 'AirwallexXeroBatchPayment',
    SafetyCatch = 'SafetyCatch',
    NetSuiteRefDataCache = 'NetSuiteRefDataCache',
    QBooksBudgeting = 'QBooksBudgeting',
    NetSuiteBillPaymentEdit = 'NetSuiteBillPaymentEdit',
    NetSuiteShowAllTaxes = 'NetSuiteShowAllTaxes',
    NetSuiteRA = 'NetSuiteRA',
    NetSuiteInvoiceEdit = 'NetSuiteInvoiceEdit',
    NetSuiteJournalEntry = 'NetSuiteJournalEntry',
    NetSuiteVendor = 'NetSuiteVendor',
    XeroAccrualsReport = 'XeroAccrualsReport',
    XeroManualJournal = 'XeroManualJournal',
    PayOpenBankingUK = 'PayOpenBankingUK',
    QBOMatchingMultipleBills = 'QBOMatchingMultipleBills',
    RequestDetailsVersioning = 'RequestDetailsVersioning',
    QBORequestDetailsVersioning = 'QBORequestDetailsVersioning',
    RequestReviewStep = 'RequestReviewStep',
    QBORequestReviewStep = 'QBORequestReviewStep',
    QBOMatchingOnEditing = 'QBOMatchingOnEditing',
    XeroOcr = 'XeroOcr',
    EditingOnApproval = 'EditingOnApproval',
    ReviewerV1 = 'ReviewerV1',
    PayMergedBySupplier = 'PayMergedBySupplier',
    OnHoldStatus = 'OnHoldStatus',
    CaptureNetSuiteBill = 'CaptureNetSuiteBill',
    CaptureXeroSalesInvoice = 'CaptureXeroSalesInvoice',
    CaptureQBOBill = 'CaptureQBOBill',
    AmaxPayScheduledPayments = 'AmaxPayScheduledPayments',
    AddonPromoCapture = 'AddonPromoCapture',
    AddonPromoPay = 'AddonPromoPay',
    WalletsUK = 'WalletsUK',
}

export enum CompanyLicenseFeature {
    XeroWorkflows = 'XeroWorkflows',
    QBOWorkflows = 'QBOWorkflows',
    AMWorkflows = 'AMWorkflows',
    NetSuiteWorkflows = 'NetSuiteWorkflows',
    DearWorkflows = 'DearWorkflows',
    QBOMatching = 'QBOMatching',
    AutoApprovalStep = 'AutoApprovalStep',
    XeroBudgets = 'XeroBudgets',
    XeroContactWorkflows = 'XeroContactWorkflows',
    QBOVendorWorkflows = 'QBOVendorWorkflows',
    XeroMatchingV2 = 'XeroMatchingV2',
    XeroBillBatchPayments = 'XeroBillBatchPayments',
    AirwallexXeroBatchPayments = 'AirwallexXeroBatchPayments',
    WorkflowVersioning = 'WorkflowVersioning',
    GRN = 'GRN',
    QBOJournalEntryWorkflows = 'QBOJournalEntryWorkflows',
    XeroManualJournals = 'XeroManualJournals',
    XeroQuotes = 'XeroQuotes',
    XeroAccrualReports = 'XeroAccrualReports',
    RequestReviewStep = 'RequestReviewStep',
    PublicAPI = 'PublicAPI',
    Capture = 'Capture',
    Pay = 'Pay',
    OnHoldStatus = 'OnHoldStatus',
    EditingOnApproval = 'EditingOnApproval',
    RequestDetailsVersioning = 'RequestDetailsVersioning',
}

export enum OrganisationPlanPrivilege {
    Premium = 'Premium',
    Advanced = 'Advanced',
    Standard = 'Standard',
}

export enum CompanyPlan {
    Trial = 'AMAT',
    PaymentRequired = 'AMPR',
    Partner = 'AMPE',
    Xero = 'AMXO',
    QBooks = 'AMQB',
    NetSuite = 'AMNS',
    XeroAdvanced = 'AMXV',
    QBooksAdvanced = 'AMQV',
    NetSuiteAdvanced = 'AMNV',
    XeroPremium = 'AMXP',
    QBooksPremium = 'AMQP',
    NetSuitePremium = 'AMNP',
    Professional = 'AMAL',
    Retired = 'AMRE',
    ReceiptBankProfessional = 'RBAL',
    ReceiptBankQuickBooksOnlineStandard = 'RBQB',
    ReceiptBankQuickBooksOnlineAdvanced = 'RBQV',
    ReceiptBankQuickBooksOnlinePremium = 'RBQP',
    ReceiptBankXeroStandard = 'RBXO',
    ReceiptBankXeroAdvanced = 'RBXV',
    ReceiptBankXeroPremium = 'RBXP',
    SmallBusinessPackage = 'AMBB',
    Cin7CorePremium = 'AMCP',
}

export enum CompanyPlanEdition {
    Standard = 'Standard',
    NotForProfit = 'NotForProfit',
}

export enum CompanyUserRole {
    None = 'None',
    Participant = 'Participant',
    Auditor = 'Auditor',
    Manager = 'Manager',
    WorkflowManager = 'WorkflowManager',
}

export enum CompanyUserStatus {
    NotInvited = 'NotInvited',
    Invited = 'Invited',
    Rejected = 'Rejected',
    Active = 'Active',
}

export enum CompanyMatchingSettingsBillApprovalPolicy {
    Never = 'Never',
    WithPercentageThreshold = 'WithPercentageThreshold',
    WithThreshold = 'WithThreshold',
    Always = 'Always',
}

export enum ApprovalAllowanceType {
    Never = 'Never',
    WithPercentageThreshold = 'WithPercentageThreshold',
    WithThreshold = 'WithThreshold',
    Always = 'Always',
}

export enum MatchingAmountType {
    Gross = 'Gross',
    Net = 'Net',
}

export enum AddMatchedPOsRequestersAsApproversForBillStatuses {
    None = 'None',
    Manually = 'Manually',
    All = 'All',
}

export interface CompanyBillMatchingSettings {
    addMatchedPOsRequestersAsApproversForBill: AddMatchedPOsRequestersAsApproversForBillStatuses;
    autoMatchingEnabled: boolean;
    manualMatchingEnabled: boolean;
    canMatchBillsWithRetrospectivePurchaseOrders: boolean;
    allowApprovalOfNotMatchedBills: CompanyMatchingSettingsBillApprovalPolicy;
    notMatchedBillsApprovalThreshold: number;
    allowApprovalOfOverbudgetBills: CompanyMatchingSettingsBillApprovalPolicy;
    overbudgetBillsApprovalPercentageThreshold: number;
    overbudgetBillsApprovalThreshold: number;
    amountType: MatchingAmountType;
    insufficientBudgetMatchBillsApprovalAllowed: ApprovalAllowanceType;
    insufficientBudgetMatchBillsApprovalThreshold: number;
    insufficientBudgetMatchBillsApprovalPercentageThreshold: number;
}

export interface CompanyPurchaseOrderMatchingSettings {
    autoMarkingPurchaseOrderAsBilledEnabled: boolean;
    autoUnmarkingPurchaseOrderAsBilledEnabled: boolean;
}

export interface CompanyXeroPurchaseOrderSettings {
    isGrnEnabled: boolean;
}

export interface CompanyQboPurchaseOrderSettings {
    isGrnEnabled: boolean;
}

export interface CompanyQboPriceCheckerSettings {
    isEnabledForBills: boolean;
    isEnabledForExpenses: boolean;
    isEnabledForPurchaseOrders: boolean;
}

export interface CompanyDelegate {
    userId: Guid;
    delegateUserId: Guid;
    delegateFrom?: string | null;
    delegateTo?: string | null;
}

export interface CompanyUserToStatusMap {
    [userId: string]: CompanyUserStatus;
}

export interface ReceiptBankIntegration {
    id: Guid;
    companyId: Guid;
    createdDate: string;
    modifiedDate: string;
    isConnected: boolean;
}

export interface AirwallexIntegration {
    id: Guid;
    companyId: Guid;
    createdDate: string;
    modifiedDate: string;
    isConnected: boolean;
    integratedAccountName: string;
    connectedByUser: User | null;
}

export interface MatchingMetadata {
    hasMatchedV2Documents: boolean;
}

export interface NetSuiteApiFilter {
    isExpandAccount: boolean;
    taxCodeListsInclude: string;
}

export interface CompanyUserPermissionSettingsItem {
    permission: Permission;
    isEditable: boolean;
    isEnabled: boolean;
}

export interface CompanyUserPermissionsSettings {
    [userId: string]: CompanyUserPermissionSettingsItem[];
}

export interface Company {
    id: Guid;
    name: string;
    author: Guid;
    defaultCurrency: string;
    betaFeatures: CompanyBetaFeature[];
    licenseFeatures: CompanyLicenseFeature[];
    licenseProductPlan: CompanyPlan;
    licenseExpires: DateTimeIsoString | null;
    licenseId: Guid;
    isReadonly: boolean;
    subscriptionId: Guid | null;
    delegates: CompanyDelegate[];
    userStatuses: CompanyUserToStatusMap;
    auditors: Guid[];
    managers: Guid[];
    participants: Guid[];
    workflowManagers: Guid[];
    integrationId: Guid | null;
    receiptBankIntegration: ReceiptBankIntegration | null;
    airwallexIntegration: AirwallexIntegration | null;
    timeZone: string;
    billMatchingSettings: CompanyBillMatchingSettings | undefined;
    purchaseOrderMatchingSettings: CompanyPurchaseOrderMatchingSettings;
    tempUsers: { [key: string]: User } | null;
    allFeaturesTrialStatus: AllFeaturesTrialStatus;
    allFeaturesTrialEndDate: DateTimeIsoString | null;
    matchingMetadata: MatchingMetadata | null;
    netSuiteApiFilter?: NetSuiteApiFilter;
    enforcementTfaType: TwoFaEnforcementType;
    xeroPurchaseOrderSettings?: CompanyXeroPurchaseOrderSettings;
    qboPurchaseOrderSettings?: CompanyQboPurchaseOrderSettings;
    qboPriceCheckerSettings?: CompanyQboPriceCheckerSettings;
    userPermissionsSettings?: CompanyUserPermissionsSettings;
    /**
     * Indicates if there are any workflow within this company where user is a payer
     */
    hasActiveWorkflowWithUserAsPayer: boolean;
}
