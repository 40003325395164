import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { companiesApiPaths } from '../paths';
import { GetAllOpenRequestsWithParticipantDecisionRequired } from './useGetAllOpenRequestsWithParticipantDecisionRequired.types';

export const useGetAllOpenRequestsWithParticipantDecisionRequired = (
    params?: RequestParams<GetAllOpenRequestsWithParticipantDecisionRequired>,
    queryOptions?: RequestQueryOptions<GetAllOpenRequestsWithParticipantDecisionRequired>
) => {
    return useGet(companiesApiPaths.getAllOpenRequestsWithParticipantDecisionRequired, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetAllOpenRequestsWithParticipantDecisionRequired['params']['query']>(
                {
                    userId: params?.query?.userId,
                    companyId: params?.query?.companyId,
                },
                queryOptions
            ),
        },
        apiVersion: 'v1',
    });
};
