import '../workflowSection.scss';

import { Guid } from '@approvalmax/types';
import { TransparentButton } from '@approvalmax/ui';
import { selectors, ui } from 'modules/common';
import { State } from 'modules/data';
import {
    AutoApprovedStepIcon,
    UserBadgeApprovedIcon,
    WorkflowStepEditIcon,
    WorkflowStepReassignIcon,
} from 'modules/sprites';
import { FC, memo, useCallback, useMemo } from 'react';
import bemFactory from 'react-bem-factory';
import { useDispatch, useSelector } from 'react-redux';

import { showEditReviewersPopup, showRequestReassignPopup } from '../../../actions';
import { getRequestReviewStepCommands } from '../../../selectors/requestSelectors';
import { getMemberOrUser } from '../../../selectors/userSelectors';
import { StepParticipantHover } from '../StepParticipantHover/StepParticipantHover';
import { messages } from './ReviewStepDetails.messages';
import { ReviewStepDetailsProps } from './ReviewStepDetails.types';

const bem = bemFactory.block('reql-workflow-section');
const qa = bemFactory.qa('reql-workflow-section');

export const ReviewStepDetails: FC<ReviewStepDetailsProps> = memo((props) => {
    const { request, readonly, reviewStep } = props;

    const dispatch = useDispatch();
    const me = useSelector(selectors.profile.getProfileUser);
    const allUsers = useSelector(selectors.user.getUsers);
    const team = useSelector((state: State) => selectors.company.getCompanyTeam(state, request.company));
    const stepCommands = getRequestReviewStepCommands(request, reviewStep, me.id);
    const isCompanyReadonly = request.company.isReadonly;
    const skipped = reviewStep?.isCompleted && !reviewStep.completedByUser;

    const getUser = useCallback((userId: Guid) => getMemberOrUser(userId, team, allUsers), [allUsers, team]);

    const editStepParticipants = () => {
        dispatch(showEditReviewersPopup(request.id, reviewStep));
    };

    const reassignRequest = () => {
        dispatch(showRequestReassignPopup(request.id, reviewStep.reviewStepId));
    };

    const expandedReviewers = useMemo(
        () =>
            reviewStep.reviewers.map((reviewer) => ({
                reviewer,
                expandedUser: getUser(reviewer.id),
            })),
        [getUser, reviewStep.reviewers]
    );

    return (
        <div className={bem('step-details')}>
            <div className={bem('step-participant-list')}>
                {skipped && (
                    <div className={bem('step-auto-approved')} data-qa={qa('step-auto-approved')}>
                        <AutoApprovedStepIcon className={bem('step-auto-approved-icon')} width={30} height={30} />

                        {messages.skippedText({ br: <br /> })}
                    </div>
                )}

                {expandedReviewers.map(({ reviewer, expandedUser }) => (
                    <StepParticipantHover
                        key={`reviewer-${reviewer.id}/${reviewer.delegateFor}/${reviewer.addedBy}`}
                        className={bem('step-participant')}
                        qa={qa('step-reviewer')}
                        participant={reviewer}
                        request={request}
                    >
                        {reviewStep.isCompleted && reviewStep.completedByUser?.id === reviewer.id && (
                            <UserBadgeApprovedIcon
                                className={bem('step-participant-badge', 'approved')}
                                width={16}
                                height={16}
                            />
                        )}

                        <ui.Avatar
                            title={null}
                            size={30}
                            user={expandedUser}
                            className={bem('step-participant-avatar')}
                        />

                        <div className={bem('step-participant-name', { hide: reviewStep.reviewers.length !== 1 })}>
                            {expandedUser.displayName}
                        </div>
                    </StepParticipantHover>
                ))}

                {!readonly && (
                    <>
                        <TransparentButton
                            className={bem('step-edit-button')}
                            disabled={isCompanyReadonly}
                            qa={qa('step-edit-button')}
                            title={messages.editReviewerTitleText}
                            execute={editStepParticipants}
                            command={stepCommands.editParticipants}
                        >
                            <WorkflowStepEditIcon width={30} height={30} />
                        </TransparentButton>

                        <TransparentButton
                            className={bem('step-reassign-button')}
                            disabled={isCompanyReadonly}
                            qa={qa('step-reassign-button')}
                            title={messages.reassignReviewerTitleText}
                            execute={reassignRequest}
                            command={stepCommands.reassignRequest}
                        >
                            <WorkflowStepReassignIcon width={30} height={30} />
                        </TransparentButton>
                    </>
                )}
            </div>
        </div>
    );
});

ReviewStepDetails.displayName = 'ReviewStepDetails';
