import moment from 'moment/moment';

/**
 * Get the date or a max date if the date going after it
 */
export const getMaxDate = (date: Date | undefined, maxDate: Date | string | undefined | null) => {
    if (maxDate && date) {
        return moment(date).isAfter(maxDate) ? new Date(maxDate) : date;
    }

    return date;
};
