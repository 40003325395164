import { domHelpers, mathService, numberHelpers } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { FC, memo } from 'react';

import { AmountInfo } from '../../../../../components/AmountInfo/AmountInfo';
import FieldsSection from '../../../../../components/FieldsSection';
import { useNetSuiteRequestNetAmount } from '../../NetSuiteRequestCard.hooks';
import { Footer, StyledExchangeRate } from '../../NetSuiteRequestCard.styles';
import NetSuiteLink from '../NetSuiteLink/NetSuiteLink';
import { netSuiteRequestFooterQa } from './NetSuiteRequestFooter.constants';
import { NetSuiteRequestFooterProps } from './NetSuiteRequestFooter.types';

const NetSuiteRequestFooter: FC<NetSuiteRequestFooterProps> = memo((props) => {
    const { request, displayCurrency, exchangeRate } = props;

    const { details, integrationCode, currency } = request;

    const netAmount = useNetSuiteRequestNetAmount(details);

    const requestCurrency = details.currency?.text || currency;

    const isNetSuiteBillPayment = integrationCode === domain.IntegrationCode.NetSuiteBillPayment;
    const isNetSuiteInvoice = integrationCode === domain.IntegrationCode.NetSuiteInvoice;
    const isAccountsReceivable = isNetSuiteInvoice || integrationCode === domain.IntegrationCode.NetSuiteSalesOrder;
    const isJournalEntry = integrationCode === domain.IntegrationCode.NetSuiteJournalEntry;

    const subtotal = isAccountsReceivable ? details.subTotal : null;
    const discountAmount = isAccountsReceivable ? details.discountTotal : null;
    const shippingAmount = isNetSuiteInvoice ? details.shippingCost : null;
    const handlingAmount = isNetSuiteInvoice ? details.handlingCost : null;

    const showAmountInOrganisationCurrency =
        displayCurrency &&
        displayCurrency?.isoCode !== currency &&
        numberHelpers.isNumber(exchangeRate) &&
        exchangeRate !== 1;

    return (
        <FieldsSection>
            <Footer>
                <div>
                    <StyledExchangeRate request={request} />

                    {details.url && (
                        <NetSuiteLink
                            url={details.url}
                            qa={domHelpers.generateDataQa(netSuiteRequestFooterQa, 'external-url-link')}
                        />
                    )}
                </div>

                {integrationCode !== domain.IntegrationCode.NetSuiteVendor && (
                    <div>
                        {showAmountInOrganisationCurrency ? (
                            <AmountInfo
                                currency={displayCurrency.currencyName}
                                netAmount={
                                    numberHelpers.isNumber(netAmount)
                                        ? mathService.multiply(netAmount, exchangeRate)
                                        : null
                                }
                                discountAmount={
                                    numberHelpers.isNumber(discountAmount)
                                        ? mathService.multiply(discountAmount, exchangeRate)
                                        : null
                                }
                                taxAmount={
                                    numberHelpers.isNumber(details.taxAmount)
                                        ? mathService.multiply(details.taxAmount, exchangeRate)
                                        : null
                                }
                                totalAmount={
                                    numberHelpers.isNumber(details.totalAmount)
                                        ? mathService.multiply(details.totalAmount, exchangeRate)
                                        : null
                                }
                                shippingAmount={
                                    numberHelpers.isNumber(shippingAmount)
                                        ? mathService.multiply(shippingAmount, exchangeRate)
                                        : null
                                }
                                handlingAmount={
                                    numberHelpers.isNumber(handlingAmount)
                                        ? mathService.multiply(handlingAmount, exchangeRate)
                                        : null
                                }
                                debitTotal={
                                    isJournalEntry && numberHelpers.isNumber(details.debitTotal)
                                        ? mathService.multiply(details.debitTotal, exchangeRate)
                                        : null
                                }
                                creditTotal={
                                    isJournalEntry && numberHelpers.isNumber(details.creditTotal)
                                        ? mathService.multiply(details.creditTotal, exchangeRate)
                                        : null
                                }
                                subtotal={
                                    numberHelpers.isNumber(subtotal)
                                        ? mathService.multiply(subtotal, exchangeRate)
                                        : null
                                }
                                hideSeparator={isNetSuiteBillPayment || isJournalEntry}
                                qa={netSuiteRequestFooterQa}
                            />
                        ) : (
                            <AmountInfo
                                currency={requestCurrency}
                                netAmount={netAmount}
                                discountAmount={discountAmount}
                                taxAmount={details.taxAmount}
                                totalAmount={details.totalAmount}
                                shippingAmount={shippingAmount}
                                handlingAmount={handlingAmount}
                                debitTotal={isJournalEntry ? details.debitTotal : null}
                                creditTotal={isJournalEntry ? details.creditTotal : null}
                                subtotal={subtotal}
                                hideSeparator={isNetSuiteBillPayment || isJournalEntry}
                                qa={netSuiteRequestFooterQa}
                            />
                        )}
                    </div>
                )}
            </Footer>
        </FieldsSection>
    );
});

export default NetSuiteRequestFooter;
