import { Popup } from '@approvalmax/ui/src/components';
import { memo, useCallback, useEffect } from 'react';

import { ScreenAppCode } from './components/ScreenAppCode/ScreenAppCode';
import { ScreenBackupCode } from './components/ScreenBackupCode/ScreenBackupCode';
import { ScreenEmailCode } from './components/ScreenEmailCode/ScreenEmailCode';
import { ScreenMethodSelect } from './components/ScreenMethodSelect/ScreenMethodSelect';
import { useTwoFaActionWizardContext } from './TwoFaActionWizard.context';
import { TwoFaActionWizardMethods, TwoFaActionWizardProps } from './TwoFaActionWizard.types';

export const TwoFaActionWizard = memo<TwoFaActionWizardProps>((props) => {
    const { isOpen, onClose, disableAutoClose } = props;

    const { activeScreen, resetContext } = useTwoFaActionWizardContext();

    const handleToggle = useCallback(
        (open: boolean) => {
            return !open && onClose();
        },
        [onClose]
    );

    useEffect(() => {
        if (!isOpen) {
            resetContext();
        }
    }, [isOpen, resetContext]);

    return (
        <Popup open={isOpen} size='medium' onToggle={handleToggle} preventAutoClose={disableAutoClose}>
            {activeScreen === TwoFaActionWizardMethods.authenticatorAppCode && <ScreenAppCode {...props} />}

            {activeScreen === TwoFaActionWizardMethods.backupCode && <ScreenBackupCode {...props} />}

            {activeScreen === TwoFaActionWizardMethods.emailCode && <ScreenEmailCode {...props} />}

            {activeScreen === 'methodSelect' && <ScreenMethodSelect {...props} />}
        </Popup>
    );
});

TwoFaActionWizard.displayName = 'TwoFaActionWizard';
