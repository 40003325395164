import { Popup } from '@approvalmax/ui';
import { memo } from 'react';

import StepBackupCodes from './components/StepBackupCodes/StepBackupCodes';
import StepBackupMethods from './components/StepBackupMethods/StepBackupMethods';
import StepCodeEnter from './components/StepCodeEnter/StepCodeEnter';
import StepCodeScan from './components/StepCodeScan/StepCodeScan';
import StepEmailConfirm from './components/StepEmailConfirm/StepEmailConfirm';
import StepEmailEnter from './components/StepEmailEnter/StepEmailEnter';
import StepSuccess from './components/StepSuccess/StepSuccess';
import { useTwoFaEnablingWizardContext } from './TwoFaEnablingWizard.context';
import { useTwoFaSetupWizard } from './TwoFaEnablingWizard.hooks';
import { TwoFaEnablingWizardProps } from './TwoFaEnablingWizard.types';

/**
 * @deprecated Use Redesigned version
 */
const TwoFaEnablingWizard = memo<TwoFaEnablingWizardProps>((props) => {
    const { isActive, onCancel } = useTwoFaSetupWizard();
    const { context } = useTwoFaEnablingWizardContext();

    if (!isActive) {
        return null;
    }

    return (
        <Popup {...props} isOpen={context.isOpen} onRequestClose={onCancel}>
            {
                {
                    codeScan: <StepCodeScan />,
                    codeEnter: <StepCodeEnter />,
                    backupMethods: <StepBackupMethods />,
                    backupCodes: <StepBackupCodes />,
                    emailEnter: <StepEmailEnter />,
                    emailConfirm: <StepEmailConfirm />,
                    success: <StepSuccess />,
                }[context.activeStep]
            }
        </Popup>
    );
});

export default TwoFaEnablingWizard;
