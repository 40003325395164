import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { matchingApiPaths } from '../paths';
import { GetMatchingInfo } from './useGetMatchingInfo.types';

export const useGetMatchingInfo = (
    params?: RequestParams<GetMatchingInfo>,
    queryOptions?: RequestQueryOptions<GetMatchingInfo>
) =>
    useGet(matchingApiPaths.getMatchingInfo, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetMatchingInfo['params']['query']>(
                { companyId: params?.query?.companyId },
                queryOptions
            ),
        },

        apiVersion: 'v1',
        mapToCamelCase: true,
    });
