import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';
import { QueryKeys } from 'modules/common';
import { domain } from 'modules/data';
import { GlobalLoadingBar } from 'modules/page';
import { reloadRequest, showMatchingPORequestersPopup } from 'pages/requestList/actions';
import { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { api } from 'services/api';
import { useGetMatchingSettings } from 'shared/data/v1';

import { getActiveRequest } from '../../../../../../../selectors/pageSelectors';
import { getMatchingData, getMatchingTransfer } from '../../../../selectors';
import { PurchaseOrder } from '../../../../types';
import { getDifferentRequesters, getMatchingRequesters, mapData } from './ChangeMatchingPopup.helpers';
import { ChangeMatchingPopupProps } from './ChangeMatchingPopup.types';
import { ChangeMatchingPopupContextProvider } from './ChangeMatchingPopupContext';
import ChangeMatchingPopupContent from './components/ChangeMatchingPopupContent/ChangeMatchingPopupContent';
import ChangeMatchingPopupEmpty from './components/ChangeMatchingPopupEmpty/ChangeMatchingPopupEmpty';

const ChangeMatchingPopup: FC<ChangeMatchingPopupProps> = (props) => {
    const request = useSelector(getActiveRequest);
    const dispatch = useDispatch();

    const { billId, companyId, onClose } = props;

    const { data: dataMatchingSettings } = useGetMatchingSettings({
        query: { companyId },
    });

    const matchingSettings = useMemo(() => {
        if (!dataMatchingSettings) return null;

        return mapData(dataMatchingSettings);
    }, [dataMatchingSettings]);

    const isFirstApiCallRef = useRef(true);
    const [appliedPOFilter, setAppliedPOFilter] = useState<domain.XeroMatchingV2POFilter>({});

    const { data, isInitialLoading } = useQuery(
        [QueryKeys.XERO_MATCHING_CONTEXT, billId, appliedPOFilter],
        async () => {
            const response = await api.xeroMatching.getMatchingContext(billId, companyId, appliedPOFilter);

            return getMatchingData(response.data);
        },
        { cacheTime: 0 }
    );

    const queryClient = useQueryClient();

    useEffect(() => {
        queryClient.invalidateQueries([QueryKeys.XERO_MATCHING_CONTEXT]);
    }, [appliedPOFilter, queryClient]);

    const updateMatchingDataMutation = useMutation(
        (allocations: domain.AllocationTransfer[]) => {
            const transferData = {
                billId,
                allocations,
                companyId,
                requestVersion: request.version,
            };

            return api.xeroMatching.applyMatching(transferData);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries([QueryKeys.XERO_MATCHING_CONTEXT]);
                queryClient.invalidateQueries([QueryKeys.XERO_ALLOCATION_DATA]);
                queryClient.invalidateQueries([QueryKeys.XERO_BILL_MATCHING_DATA]);
                queryClient.invalidateQueries([QueryKeys.XER0_PO_MATCHING_DATA]);
                onClose();
            },
        }
    );

    if (isInitialLoading && isFirstApiCallRef.current) return <GlobalLoadingBar isLoading={true} />;

    isFirstApiCallRef.current = false;

    const noMatchingPOWithEmptyFilter =
        !isInitialLoading && (!data || (data.purchaseOrders.length === 0 && isEmpty(appliedPOFilter)));

    if (noMatchingPOWithEmptyFilter) {
        return <ChangeMatchingPopupEmpty />;
    }

    const onSaveMatching = async (matchingState: PurchaseOrder[]) => {
        await updateMatchingDataMutation.mutateAsync(getMatchingTransfer(matchingState));

        const activeStep = request.steps.find((step) => step.state === domain.RequestStepState.Active);

        if (activeStep) {
            const shoudShowAddMatchedPOsRequestersAsApproversForBillPopup =
                matchingSettings?.billMatchingSettings?.addMatchedPOsRequestersAsApproversForBill ===
                    domain.AddMatchedPOsRequestersAsApproversForBillStatuses.Manually &&
                activeStep?.type === domain.TemplateStepType.DecisionStep;

            if (shoudShowAddMatchedPOsRequestersAsApproversForBillPopup) {
                const requesters = getDifferentRequesters(
                    getMatchingRequesters(matchingState),
                    activeStep?.participants
                );

                const requestersInCompany = requesters.filter((requester) =>
                    request.company.allMembers.some((member) => member.databaseId === requester.id)
                );

                if (requestersInCompany.length > 0) {
                    dispatch(showMatchingPORequestersPopup(requestersInCompany));
                }
            } else {
                dispatch(reloadRequest(request.id, request.companyId));
            }
        }
    };

    return (
        <ChangeMatchingPopupContextProvider
            fetchedMatchingData={data}
            billId={billId}
            onSave={onSaveMatching}
            appliedPOFilter={appliedPOFilter}
            companyId={companyId}
        >
            <ChangeMatchingPopupContent
                isFetching={isInitialLoading}
                setAppliedPOFilter={setAppliedPOFilter}
                appliedPOFilter={appliedPOFilter}
            />
        </ChangeMatchingPopupContextProvider>
    );
};

export default memo(ChangeMatchingPopup);
