import { ChevronRightIcon } from '@approvalmax/ui';
import { Flex } from '@approvalmax/ui/src/components';
import * as common from 'modules/common';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { memo, useCallback, useEffect, useState } from 'react';

import { getActiveTemplate } from '../../../../selectors/pageSelectors';
import { SingletonStepsTypes } from '../../SingletonSteps.types';
import { ActionSelector } from '../ActionSelector/ActionSelector';
import { AutoApprovalActivator } from '../AutoApprovalActivator/AutoApprovalActivator';
import { ReviewStepActivator } from '../ReviewStepActivator/ReviewStepActivator';
import { SingletonStepPromotion } from '../SingletonStepPromotion/SingletonStepPromotion';

export const StepSelector = memo(() => {
    const [activationView, setActivationView] = useState<SingletonStepsTypes>(SingletonStepsTypes.SelectStep);
    const company = useSelector(common.selectors.navigation.getActiveCompany);
    const template = useSelector(getActiveTemplate);
    const canUpdateActiveCompanySettings = useSelector(common.selectors.company.canUpdateActiveCompanySettings);
    const integrationErrorPreventsWork = Boolean(!company.flags.hasActiveIntegration && template?.integrationCode);
    const readonly = !canUpdateActiveCompanySettings || integrationErrorPreventsWork || company.isReadonly;

    const enoughRightsToShowAutoApprove = company.licenseFeatures.includes(
        domain.CompanyLicenseFeature.AutoApprovalStep
    );
    const showPromotionOfAutoApprove =
        company.allFeaturesTrialStatus === domain.AllFeaturesTrialStatus.AwaitingActivation &&
        !enoughRightsToShowAutoApprove;
    const isAutoApprovalActive =
        (template && template.autoApprovalRules.length > 0 && !showPromotionOfAutoApprove) ||
        template?.showAutoApprovalStepWithoutRules;
    const canSeeAutoApprove =
        (enoughRightsToShowAutoApprove || showPromotionOfAutoApprove) &&
        template?.integrationCode !== domain.IntegrationCode.QBooksVendor;
    const canAddAutoApprovalStep = !isAutoApprovalActive && canSeeAutoApprove;

    const workflowHasAccessToReviewStep = selectors.template.getHasAccessToReviewStepByIntegrationCode(
        template?.integrationCode
    );
    const enoughRightsToShowReviewStep =
        selectors.company.getIsReviewStepAvailable(company) && workflowHasAccessToReviewStep;
    const showPromotionOfReviewStep =
        workflowHasAccessToReviewStep &&
        company.integration?.integrationType === domain.IntegrationType.Xero &&
        company.allFeaturesTrialStatus === domain.AllFeaturesTrialStatus.AwaitingActivation &&
        !enoughRightsToShowReviewStep;
    const isReviewStepActive =
        (template && template.reviewStep.reviewers.length > 0 && !showPromotionOfReviewStep) ||
        template?.showReviewStepWithoutReviewers;
    const canSeeReviewStep = enoughRightsToShowReviewStep || showPromotionOfReviewStep;
    const canAddReviewStep = !isReviewStepActive && canSeeReviewStep;

    useEffect(() => {
        if (showPromotionOfAutoApprove) {
            setActivationView(SingletonStepsTypes.AutoApprovalStep);
        } else if (showPromotionOfReviewStep) {
            setActivationView(SingletonStepsTypes.ReviewStep);
        }
    }, [showPromotionOfAutoApprove, showPromotionOfReviewStep]);

    const hide = useCallback(() => {
        setActivationView(SingletonStepsTypes.SelectStep);
    }, []);

    if (readonly || !template || (!canAddAutoApprovalStep && !canAddReviewStep)) {
        return null;
    }

    return (
        <>
            <Flex height={28} alignItems='center'>
                <ChevronRightIcon size={16} color='midnight60' />
            </Flex>

            {activationView === SingletonStepsTypes.ReviewStep &&
                (showPromotionOfReviewStep ? (
                    <SingletonStepPromotion hide={hide} company={company} />
                ) : (
                    <ReviewStepActivator hide={hide} integrationCode={template.integrationCode} />
                ))}

            {activationView === SingletonStepsTypes.AutoApprovalStep &&
                (showPromotionOfAutoApprove ? (
                    <SingletonStepPromotion hide={hide} company={company} />
                ) : (
                    <AutoApprovalActivator hide={hide} />
                ))}

            {activationView === SingletonStepsTypes.SelectStep && (
                <ActionSelector
                    setActivationView={setActivationView}
                    canAddReviewStep={canAddReviewStep}
                    canAddAutoApprovalStep={canAddAutoApprovalStep}
                />
            )}
        </>
    );
});

StepSelector.displayName = 'StepSelector';
