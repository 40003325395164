import { domHelpers } from '@approvalmax/utils';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useKeyPressEvent, useLatest } from 'react-use';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import Menu from '../../../Menu/Menu';
import type { SelectOnChange } from '../../../Select/Select.types';
import { toast } from '../../../Toast/Toast.helpers';
import { inputValuePristineState, selectInputFocusState, selectInputValueState } from '../Activator/Activator.states';
import { useDropdownOpen } from '../DropdownMenu/DropdownMenu.hooks';
import { filterItems, filterItemsOnPristineInputValue } from './MenuItems.helpers';
import { messages } from './MenuItems.messages';
import { createdItemsState, menuItemsState } from './MenuItems.states';
import { MenuItemsProps } from './MenuItems.types';

/**
 * Menu items for the DropdownMenu component
 */
const MenuItems = memo(
    <Item extends Record<string, any>, Multiple extends boolean>(props: MenuItemsProps<Item, Multiple>) => {
        const {
            items,
            itemIdKey,
            itemNameKey,
            multiple,
            autocomplete,
            customMenuItem,
            hideNoData,
            creatable,
            validateCreatedValue,
            selectedItems,
            onChange,
            progress,
            preventSearch,
            open,
            onOpen,
            initOpen,
            filterSelectedItems,
            qa,
            ...restProps
        } = props;

        const [menuItems, setMenuItems] = useRecoilState<Item[]>(menuItemsState);
        const [inputValue, setInputValue] = useRecoilState(selectInputValueState);
        const inputValuePristine = useRecoilValue(inputValuePristineState);
        const [dropdownOpen, setDropdownOpen] = useDropdownOpen({ open, onOpen, initOpen });
        const setInputFocus = useSetRecoilState(selectInputFocusState);
        const [creatableItem, setCreatableItem] = useState<Item>();
        const [createdItems, setCreatedItems] = useRecoilState<Item[]>(createdItemsState);
        const latestSelectedItems = useLatest(selectedItems);

        /**
         * Add item to selected items
         */
        const addItem = useCallback(
            (item: Item, isNew?: boolean) => () => {
                if (multiple) {
                    setInputFocus(false);
                    setTimeout(() => setInputFocus(true), 0);

                    const newSelectedItems = item.selected
                        ? selectedItems.filter((selectedItem) => selectedItem[itemIdKey] !== item[itemIdKey])
                        : [...selectedItems, item];

                    onChange?.(
                        newSelectedItems.map<string | number>((item) => item[itemIdKey]) as Parameters<
                            SelectOnChange<Item, Multiple>
                        >[0],
                        [...items, ...(isNew ? [item] : [])]
                    );

                    return;
                }

                onChange?.(item[itemIdKey], items);
                setDropdownOpen(false);
            },
            [itemIdKey, items, multiple, onChange, selectedItems, setDropdownOpen, setInputFocus]
        );

        const menuRef = useRef<HTMLUListElement>(null);

        /**
         * Active item id selected by keyboard navigation
         */
        const [activeItemId, setActiveItemId] = useState((!multiple && selectedItems[0]?.[itemIdKey]) || undefined);

        const activeItemIndex = useMemo(
            () => menuItems.findIndex((item) => item[itemIdKey] === activeItemId),
            [activeItemId, itemIdKey, menuItems]
        );

        /**
         * Items filtered by inputValue and selectedItems
         */
        useEffect(() => {
            const searchValue = autocomplete && !preventSearch ? inputValue.trim() : '';

            const filteredItems = inputValuePristine
                ? filterItemsOnPristineInputValue(items, selectedItems, itemIdKey, filterSelectedItems)
                : filterItems(items, selectedItems, searchValue, itemNameKey, itemIdKey, filterSelectedItems);

            const exactMatch = [...filteredItems, ...selectedItems].some(
                (item) => item[itemNameKey].toLowerCase() === searchValue.toLowerCase()
            );

            if (creatable && searchValue && !exactMatch) {
                setCreatableItem({
                    [itemIdKey]: searchValue,
                    [itemNameKey]: searchValue,
                } as Item);
            } else {
                setCreatableItem(undefined);
            }

            setMenuItems(filteredItems);
        }, [
            items,
            itemIdKey,
            itemNameKey,
            setMenuItems,
            selectedItems,
            inputValue,
            autocomplete,
            setCreatableItem,
            creatable,
            preventSearch,
            inputValuePristine,
            filterSelectedItems,
        ]);

        /**
         * Scroll to the selected item on Dropdown open. Work for single select only
         */
        useEffect(() => {
            let timerId: ReturnType<typeof setTimeout>;

            if (!multiple && dropdownOpen) {
                const menuElement = menuRef.current;
                const selectedItem = latestSelectedItems.current?.find((item) => item.selected);

                if (!selectedItem) {
                    return;
                }

                // we have to use setTimeout, because otherwise scrolling might not scroll 100% of times.
                // rAf or queueMicrotask won't help here, gap must be longer than 50mc
                timerId = setTimeout(() => {
                    const selectedItemElement = menuElement?.querySelector(
                        `[data-item-active="${selectedItem[itemIdKey]}"]`
                    );

                    selectedItemElement?.scrollIntoView({
                        block: 'nearest',
                        behavior: 'smooth',
                    });
                }, 100);
            }

            return () => {
                timerId && clearTimeout(timerId);
            };
        }, [dropdownOpen, itemIdKey, latestSelectedItems, multiple]);

        /**
         * Scroll menu to active item
         */
        const scrollMenuToActiveItem = useCallback(
            (item: MenuItemsProps<Item, Multiple>['items'][number]) => {
                const activeItemElement = menuRef.current?.querySelector(`[data-item-active="${item[itemIdKey]}"]`);

                activeItemElement?.scrollIntoView({
                    block: 'nearest',
                    behavior: 'smooth',
                });
            },
            [itemIdKey]
        );

        /**
         * Set active item id to next item id on key down
         */
        const handleKeyDown = useCallback(
            (event: KeyboardEvent) => {
                if (!menuItems.length || !dropdownOpen) return;

                event.preventDefault();

                const nextItemIndex = menuItems.length <= activeItemIndex + 1 ? 0 : activeItemIndex + 1;

                const nextItem = menuItems[nextItemIndex];

                if (nextItem) {
                    setActiveItemId(nextItem[itemIdKey]);

                    scrollMenuToActiveItem(nextItem);
                }
            },
            [activeItemIndex, dropdownOpen, itemIdKey, menuItems, scrollMenuToActiveItem]
        );

        /**
         * Set active item id to previous item id on key up
         */
        const handleKeyUp = useCallback(
            (event: KeyboardEvent) => {
                if (!menuItems.length || !dropdownOpen) return;

                event.preventDefault();

                const prevItemIndex = activeItemIndex === 0 ? menuItems.length - 1 : activeItemIndex - 1;
                const prevItem = menuItems[prevItemIndex];

                if (prevItem) {
                    setActiveItemId(prevItem[itemIdKey]);
                    scrollMenuToActiveItem(prevItem);
                } else if (creatable && creatableItem?.[itemIdKey]) {
                    setActiveItemId(creatableItem[itemIdKey]);
                    scrollMenuToActiveItem(creatableItem);
                }
            },
            [activeItemIndex, creatable, creatableItem, dropdownOpen, itemIdKey, menuItems, scrollMenuToActiveItem]
        );

        /**
         * Add and select creatable item
         */
        const addAndSelectCreatableItem = useCallback(() => {
            if (creatableItem) {
                const { isValid, errorMessage } = validateCreatedValue?.(creatableItem.id) ?? { isValid: true };

                if (isValid) {
                    setCreatedItems([...createdItems, creatableItem]);
                    addItem(creatableItem, true)();
                }

                if (errorMessage) {
                    toast.error(errorMessage);
                }

                setCreatableItem(undefined);
                setInputValue('');
            }
        }, [addItem, creatableItem, createdItems, setCreatedItems, setInputValue, validateCreatedValue]);

        /**
         * Add active item to selected items on Enter
         */
        const handleEnter = useCallback(
            (event: KeyboardEvent) => {
                if (!creatableItem && (!menuItems.length || !dropdownOpen)) return;

                event.preventDefault();

                const activeItem = menuItems[activeItemIndex];

                if (activeItem) {
                    addItem(activeItem)();

                    const nextItemId = menuItems[activeItemIndex + 1]?.[itemIdKey];
                    const prevItemId = menuItems[activeItemIndex - 1]?.[itemIdKey];

                    setActiveItemId(nextItemId || prevItemId);
                } else if (
                    creatable &&
                    creatableItem &&
                    (activeItemId === creatableItem[itemIdKey] || !menuItems.length)
                ) {
                    addAndSelectCreatableItem();
                    setActiveItemId(menuItems[0]?.[itemIdKey]);
                }
            },
            [
                activeItemId,
                activeItemIndex,
                addAndSelectCreatableItem,
                addItem,
                creatable,
                creatableItem,
                dropdownOpen,
                itemIdKey,
                menuItems,
            ]
        );

        useKeyPressEvent((event) => ['ArrowDown', 'ArrowRight'].includes(event.key), handleKeyDown);
        useKeyPressEvent((event) => ['ArrowUp', 'ArrowLeft'].includes(event.key), handleKeyUp);
        useKeyPressEvent('Enter', handleEnter);

        /**
         * Get item props with active state and onClick handler to add item to selected items
         */
        const getItemProps = useCallback(
            (item: MenuItemsProps<Item, Multiple>['items'][number]) => ({
                ...item,
                active: activeItemId === item[itemIdKey],
                name: item[itemNameKey],
                onClick: addItem(item),
                dataItemActive: item[itemIdKey],
                key: item[itemIdKey],
                role: 'option',
                'aria-selected': activeItemId === item[itemIdKey],
                'data-qa': item[itemNameKey],
            }),
            [activeItemId, addItem, itemIdKey, itemNameKey]
        );

        return (
            <Menu divider disableFocusVisible ref={menuRef} qa={domHelpers.generateDataQa(qa, 'select')} {...restProps}>
                {creatableItem?.[itemIdKey] && (
                    <Menu.Item
                        role='option'
                        name={messages.addCreatableItemText({ name: creatableItem[itemIdKey] })}
                        onClick={addAndSelectCreatableItem}
                        dataItemActive={creatableItem[itemIdKey]}
                        active={activeItemId === creatableItem[itemIdKey]}
                    />
                )}

                {menuItems.map((item) => {
                    // reason to extract key: https://github.com/jsx-eslint/eslint-plugin-react/issues/613
                    const { key, ...itemProps } = getItemProps(item);

                    return customMenuItem ? (
                        customMenuItem({ key, ...itemProps } as Item & { key: string | number })
                    ) : (
                        <Menu.Item key={key} {...itemProps} />
                    );
                })}

                {!progress && !hideNoData && !menuItems.length && <Menu.Item name={messages.noData} disabled />}
            </Menu>
        );
    }
);

export default MenuItems;
