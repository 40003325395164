import { memo, useMemo } from 'react';

import FieldEntry from '../../../../../components/FieldEntry';
import SectionSeparator from '../../../../../components/SectionSeparator/SectionSeparator';
import SectionHeaderText from '../SectionHeaderText/SectionHeaderText';
import { messages } from './FinancialDetailsSection.messages';
import { Content, Root, StyledFieldEntryWrapper } from './FinancialDetailsSection.styles';
import { FinancialDetailsSectionProps } from './FinancialDetailsSection.types';

export const FinancialDetailsSection = memo<FinancialDetailsSectionProps>((props) => {
    const { contact, isUKCompany } = props;

    const sortCode = useMemo(() => {
        if (isUKCompany && contact.bankAccountDetails && /^\d{14}$/.test(contact.bankAccountDetails)) {
            return contact.bankAccountDetails.slice(0, 6);
        }

        return null;
    }, [isUKCompany, contact.bankAccountDetails]);

    if (!contact.bankAccountDetails && !contact.defaultCurrency) {
        return null;
    }

    return (
        <>
            <Root>
                <SectionHeaderText>{messages.header}</SectionHeaderText>

                <Content>
                    <StyledFieldEntryWrapper>
                        <FieldEntry title={messages.baseCurrency} value={contact.defaultCurrency} />
                    </StyledFieldEntryWrapper>

                    {sortCode && (
                        <StyledFieldEntryWrapper>
                            <FieldEntry title={messages.sortCode} value={sortCode} />
                        </StyledFieldEntryWrapper>
                    )}

                    <StyledFieldEntryWrapper>
                        <FieldEntry
                            title={messages.bankAccountNumber}
                            value={sortCode ? contact.bankAccountDetails?.slice(6) : contact.bankAccountDetails}
                        />
                    </StyledFieldEntryWrapper>
                </Content>
            </Root>

            <SectionSeparator />
        </>
    );
});

FinancialDetailsSection.displayName = 'FinancialDetailsSection';
