import { useGetBatchPaymentAmaxPayXeroBill } from 'shared/data/v2';

import { useNormalizedLinkedBillResponse } from './useNormalizedLinkedBillResponse';

export const useBillLinkedToAmaxPayXeroBatchPayment = (
    params: Parameters<typeof useGetBatchPaymentAmaxPayXeroBill>[0],
    queryOptions: Parameters<typeof useGetBatchPaymentAmaxPayXeroBill>[1]
) => {
    return useNormalizedLinkedBillResponse(
        params?.path?.billRequestId || '',
        useGetBatchPaymentAmaxPayXeroBill(params, queryOptions)
    );
};
