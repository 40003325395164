import { MutateDataParams } from '@approvalmax/data';
import { useQueryClient } from '@tanstack/react-query';
import { useMutateLegacy } from 'shared/data';

import { companiesApiPaths } from '../../../v1/queries/companies/paths';
import { paymentServicesApiPaths } from '../paths';
import { mapData } from './useFinishConnectionToAirwallex.map';
import {
    UseFinishConnectionToAirwallexData,
    UseFinishConnectionToAirwallexResponse,
    UseFinishConnectionToAirwallexResponseBackend,
} from './useFinishConnectionToAirwallex.types';

export const useFinishConnectionToAirwallex = () => {
    const queryClient = useQueryClient();

    return useMutateLegacy<
        MutateDataParams<UseFinishConnectionToAirwallexData>,
        UseFinishConnectionToAirwallexResponseBackend,
        UseFinishConnectionToAirwallexResponse
    >(paymentServicesApiPaths.finishConnectionToAirwallex, {
        mapData,
        mutationOptions: {
            onSuccess: (_response, requestData) => {
                queryClient.invalidateQueries([companiesApiPaths.select, { companyId: requestData.body?.companyId }]);
            },
        },
    });
};
