import { Flex, Select, Tag } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { FC, memo, useCallback, useMemo } from 'react';

import { useWorkflows } from '../../CopyWorkflowPopup.hooks';
import WarningList from '../WarningList/WarningList';
import { messages } from './MultipleForm.messages';
import { MultipleFormProps } from './MultipleForm.types';

const MultipleForm: FC<MultipleFormProps> = memo((props) => {
    const { activeWorkflow, availableCompanies, selectedCompany, selectedWorkflows, showErrors, onStateChange } = props;

    const { workflows, isFetching: isFetchingWorkflows } = useWorkflows(selectedCompany, activeWorkflow);

    const workflowsItems = useMemo(() => {
        if (!selectedWorkflows) return workflows;

        return selectedWorkflows.reduce(
            (acc, workflow) => {
                const workflowIndex = acc.findIndex(({ id }) => id === workflow.id);

                if (workflowIndex === -1) {
                    return [...acc, workflow];
                }

                return acc;
            },
            [...workflows]
        );
    }, [selectedWorkflows, workflows]);

    const selectedWorkflowIds = useMemo(() => selectedWorkflows?.map(({ id }) => id) || [], [selectedWorkflows]);

    const onCompanyChange = useCallback(
        (value: string, items: selectors.types.ExpandedCompany[]) => {
            const company = items.find(({ id }) => id === value);

            onStateChange({
                selectedCompany: company || null,
            });
        },
        [onStateChange]
    );

    const onWorkflowsChange = useCallback(
        (value: string[], items: selectors.types.ExpandedTemplate[]) => {
            const selectedWorkflows = items.filter(({ id }) => value.includes(id));

            onStateChange({ selectedWorkflows });
        },
        [onStateChange]
    );

    const getWorkflowTag = useCallback(
        (item: (typeof workflowsItems)[number]) => {
            const companyInfo = availableCompanies.find((company) => company.id === item.companyId);

            return <Tag {...item}>{`${companyInfo?.displayName} — ${item.displayName}`}</Tag>;
        },
        [availableCompanies]
    );

    return (
        <>
            <Flex direction='column' spacing='24'>
                <Select
                    label={messages.toCompanyFieldLabel}
                    placeholder={messages.selectOrganisation}
                    items={availableCompanies}
                    itemNameKey='displayName'
                    invalid={showErrors && !selectedCompany}
                    value={selectedCompany?.id}
                    onChange={onCompanyChange}
                    autocomplete
                    required
                />

                {(selectedCompany || selectedWorkflowIds.length > 0) && (
                    <Select
                        placeholder={messages.selectWorkflows}
                        items={workflowsItems}
                        itemNameKey='displayName'
                        value={selectedWorkflowIds}
                        onChange={onWorkflowsChange}
                        progress={isFetchingWorkflows}
                        autocomplete
                        multiple
                        required
                        customTagItem={getWorkflowTag}
                        invalid={showErrors && selectedWorkflowIds.length === 0}
                    />
                )}
            </Flex>

            {selectedWorkflows && <WarningList activeWorkflow={activeWorkflow} selectedWorkflows={selectedWorkflows} />}
        </>
    );
});

export default MultipleForm;
