import { SortOrder } from '@approvalmax/ui/src/components';
import { intl } from '@approvalmax/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { domain } from 'modules/data';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
    initialFilterData,
    initialSortingConfig,
    initialSortingConfigBySupplier,
} from './AddBillInvoicesPopup.constants';
import { schema } from './AddBillInvoicesPopup.schema';
import type { FormValues } from './AddBillInvoicesPopup.types';
import { BillsListType, UseSelectableBills } from './AddBillInvoicesPopup.types';

export const useSelectableBills = ({ billBatchPayment, excludeBillsWithZeroAmount }: UseSelectableBills) => {
    const [selectableBills, setSelectableBills] = useState<domain.BatchPaymentSelectableBillItem[]>([]);

    const selectedCount = useMemo(
        () => selectableBills.reduce((acc, bill) => acc + +bill.selected, 0),
        [selectableBills]
    );

    const totalAmountOfSelectedBillsFormatted = useMemo(() => {
        const amount = selectableBills.reduce((sum, bill) => (bill.selected ? bill.amountDue + sum : sum), 0);

        return intl.formatCurrency(amount, selectableBills[0]?.currency || '');
    }, [selectableBills]);

    const updateSelectableBills = useCallback(
        (newSelectableBills: domain.BatchPaymentSelectableBillItem[]) => {
            setSelectableBills((prevSelectableBills) =>
                newSelectableBills
                    .filter(
                        (bill) =>
                            billBatchPayment.items.every(
                                (item) => item.xeroBillInvoiceRequestId !== bill.xeroBillInvoiceRequestId
                            ) && (excludeBillsWithZeroAmount ? bill.amount > 0 : true)
                    )
                    .map((newSelectableBill) => ({
                        ...newSelectableBill,
                        selected: !!prevSelectableBills.find(
                            (selectableBill) =>
                                selectableBill.xeroBillInvoiceRequestId === newSelectableBill.xeroBillInvoiceRequestId
                        )?.selected,
                    }))
            );
        },
        [billBatchPayment.items, excludeBillsWithZeroAmount]
    );

    const toggleAllBills = useCallback(() => {
        const allBillsAreSelected = !!selectableBills.length && selectableBills.every((bill) => bill.selected);

        setSelectableBills((prevBills) => prevBills.map((bill) => ({ ...bill, selected: !allBillsAreSelected })));
    }, [selectableBills]);

    const toggleBill = useCallback((id: string) => {
        setSelectableBills((prevBills) =>
            prevBills.map((bill) => ({
                ...bill,
                selected: bill.xeroBillInvoiceRequestId === id ? !bill.selected : bill.selected,
            }))
        );
    }, []);

    const toggleBills = useCallback((idList: string[]) => {
        setSelectableBills((prevBills) =>
            prevBills.map((bill) => ({
                ...bill,
                selected: idList.includes(bill.xeroBillInvoiceRequestId),
            }))
        );
    }, []);

    const getAllBillsBySupplierAreSelected = useCallback(
        (supplierId: string) => {
            return selectableBills.filter((bill) => bill.contact?.id === supplierId).every((bill) => bill.selected);
        },
        [selectableBills]
    );

    const toggleAllBillsBySupplierId = useCallback(
        (supplierId: string) => {
            const allContactBillsAreSelected = getAllBillsBySupplierAreSelected(supplierId);

            setSelectableBills((prevBills) =>
                prevBills.map((bill) => ({
                    ...bill,
                    selected: bill.contact?.id === supplierId ? !allContactBillsAreSelected : bill.selected,
                }))
            );
        },
        [getAllBillsBySupplierAreSelected]
    );

    return {
        selectableBills,
        setSelectableBills,
        selectedCount,
        updateSelectableBills,
        toggleAllBills,
        toggleBill,
        toggleBills,
        toggleAllBillsBySupplierId,
        totalAmountOfSelectedBillsFormatted,
    };
};

export const useFilters = <T>(
    mapFilterTransfer: (filterData: FormValues, sortingConfig: domain.AddBillsToBatchSortingConfig) => T
) => {
    const initialTransferFilterData = mapFilterTransfer(initialFilterData, initialSortingConfig);
    const [sortingConfig, setSortingConfig] = useState(initialSortingConfig);
    const [filterTransfer, setFilterTransfer] = useState(initialTransferFilterData);
    const form = useForm<FormValues>({ values: initialFilterData, resolver: zodResolver(schema) });

    const changeSortingConfig = useCallback(
        (columnId: string, newSortOrder: SortOrder) => {
            const newSortingConfig: domain.AddBillsToBatchSortingConfig = {
                sortBy: columnId,
                sortOrder: newSortOrder,
            };

            const formValues = form.getValues();

            setSortingConfig(newSortingConfig);
            setFilterTransfer(mapFilterTransfer(formValues, newSortingConfig));
        },
        [form, mapFilterTransfer]
    );

    const applyFilters = useCallback(
        (formValues: FormValues) => {
            setFilterTransfer(mapFilterTransfer(formValues, sortingConfig));
        },
        [mapFilterTransfer, sortingConfig]
    );

    const resetFilter = useCallback(() => {
        const formValues = form.getValues();
        const billsListType = formValues.billsListType;

        const sortingConfig =
            billsListType === BillsListType.List ? initialSortingConfig : initialSortingConfigBySupplier;

        setSortingConfig(sortingConfig);
        setFilterTransfer(mapFilterTransfer(initialFilterData, sortingConfig));
    }, [form, mapFilterTransfer]);

    return {
        initialTransferFilterData,
        sortingConfig,
        filterTransfer,
        setFilterTransfer,
        changeSortingConfig,
        applyFilters,
        resetFilter,
        form,
    };
};
