import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.NetSuiteSalesOrderFooterFields', {
    salesDetailsSection: 'Sales Details',
    shippingDetailsSection: 'Shipping Details',
    revenueDetailsSection: 'Revenue Details',
    paymentDetailsSection: 'Payment Details',
    salesRep: 'Sales Rep',
    opportunity: 'Opportunity',
    salesEffectiveDate: 'Sales Effective Date',
    excludeCommissions: 'Exclude Commissions',
    partner: 'Partner',
    shipDate: 'Ship Date',
    shipMethod: 'Ship Method',
    shipComplete: 'Ship Complete',
    shippingAddress: 'Shipping Address',
    paymentMethod: 'Payment Method',
    creditCardNumber: 'Credit Card #',
    creditCardExpirationDate: 'Expires (MM/YYYY)',
    creditCardCardholderName: 'Name on Card',
    creditCardStreet: 'Card Street',
    creditCardZipCode: 'Card Zip Code',
    creditCardProcessor: 'Payment Processing Profile',
    creditCardApproved: 'Credit Card Approved',
    pnRef: 'P/N Ref.',
    authCode: 'Auth. Code',
    avsStreetMatch: 'AVS Street Match',
    avsZipMatch: 'AVS Zip Match',
    revenueStatus: 'Revenue Status',
    recognizedRevenue: 'Recognized Revenue',
    deferredRevenue: 'Deferred Revenue',
    positiveBooleanValue: 'Yes',
    revenueStatusPending: 'Pending',
    revenueStatusInProgress: 'In Progress',
    revenueStatusCompleted: 'Completed',
    revenueStatusRevCommitment: 'On Revenue Commitment',
});
