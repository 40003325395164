import { mixins, Mods, mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

export const Root = styled.th.attrs<{
    $transform?: string;
    $transition?: string;
}>((props) => ({
    style: {
        transform: props.$transform,
        transition: props.$transition,
    },
}))<
    StyledMods<
        {
            transform?: string;
            transition?: string;
            isDragging?: boolean;
        } & Mods<'color', 'silver80' | 'white100'>
    >
>`
    ${mods.color(mixins.colorBg)};

    position: relative;

    ${mods('isDragging', true)`
        z-index: 2000;

        & > * {
            background-color: ${theme.color.transparent};
        }
    `}
`;

export const SortableHandleContent = styled.div<
    StyledMods<
        Mods<'hide'> & {
            isDragging?: boolean;
        }
    >
>`
    width: 100%;
    height: 12px;
    padding-bottom: 4px;
    display: flex;
    justify-content: center;
    opacity: 0;
    transition: opacity ${theme.duration.medium}ms linear;
    cursor: move;
    pointer-events: auto;
    z-index: 5;

    ${mods.hide.true`
        visibility: hidden;
    `}

    * {
        cursor: move;
    }

    &:hover {
        opacity: 1;
    }
`;
