import { domain } from 'modules/data';

import { ESettingsSection } from './WorkflowSettingsPopupContent.types';

export const settingsApplicabilityMx = {
    null: [ESettingsSection.SafetyCatch, ESettingsSection.Instruction, ESettingsSection.DecisionPolicy],
    [domain.IntegrationCode.XeroBill]: [
        ESettingsSection.LockDate,
        ESettingsSection.SafetyCatch,
        ESettingsSection.FraudBypassing,
        ESettingsSection.FraudChangesAfter,
        ESettingsSection.XeroBillMatching,
        ESettingsSection.Instruction,
        ESettingsSection.DecisionPolicy,
        ESettingsSection.OverrideGoToBtnInXero,
        ESettingsSection.UseRejectedPrefix,
        ESettingsSection.HistoryPushingType,
        ESettingsSection.CaptureViaEmail,
    ],
    [domain.IntegrationCode.XeroCreditNotesPayable]: [
        ESettingsSection.LockDate,
        ESettingsSection.SafetyCatch,
        ESettingsSection.FraudBypassing,
        ESettingsSection.FraudChangesAfter,
        ESettingsSection.DecisionPolicy,
        ESettingsSection.UseRejectedPrefix,
        ESettingsSection.HistoryPushingType,
    ],
    [domain.IntegrationCode.XeroInvoice]: [
        ESettingsSection.LockDate,
        ESettingsSection.SafetyCatch,
        ESettingsSection.FraudBypassing,
        ESettingsSection.FraudChangesAfter,
        ESettingsSection.Instruction,
        ESettingsSection.DecisionPolicy,
        ESettingsSection.OverrideGoToBtnInXero,
        ESettingsSection.UseRejectedPrefix,
        ESettingsSection.EmailToSupplierSetting,
        ESettingsSection.HistoryPushingType,
        ESettingsSection.CaptureViaEmail,
    ],
    [domain.IntegrationCode.XeroCreditNotesReceivable]: [
        ESettingsSection.LockDate,
        ESettingsSection.SafetyCatch,
        ESettingsSection.FraudBypassing,
        ESettingsSection.FraudChangesAfter,
        ESettingsSection.DecisionPolicy,
        ESettingsSection.UseRejectedPrefix,
        ESettingsSection.HistoryPushingType,
    ],
    [domain.IntegrationCode.XeroPo]: [
        ESettingsSection.SafetyCatch,
        ESettingsSection.FraudBypassing,
        ESettingsSection.FraudChangesAfter,
        ESettingsSection.XeroPOMatching,
        ESettingsSection.Instruction,
        ESettingsSection.DecisionPolicy,
        ESettingsSection.EmailToSupplierSetting,
        ESettingsSection.GoodsReceivedNote,
        ESettingsSection.UseRejectedPrefix,
        ESettingsSection.HistoryPushingType,
    ],
    [domain.IntegrationCode.XeroQuote]: [
        ESettingsSection.DecisionPolicy,
        ESettingsSection.EmailToSupplierSetting,
        ESettingsSection.Instruction,
        ESettingsSection.LockDate,
        ESettingsSection.Terms,
        ESettingsSection.HistoryPushingType,
    ],
    [domain.IntegrationCode.XeroManualJournal]: [
        ESettingsSection.LockDate,
        ESettingsSection.PostingPreferencesType,
        ESettingsSection.Instruction,
        ESettingsSection.DecisionPolicy,
    ],
    [domain.IntegrationCode.XeroContact]: [
        ESettingsSection.SafetyCatch,
        ESettingsSection.Instruction,
        ESettingsSection.DecisionPolicy,
    ],
    [domain.IntegrationCode.XeroAirwallexBatchPayment]: [
        ESettingsSection.SafetyCatch,
        ESettingsSection.Instruction,
        ESettingsSection.DecisionPolicy,
    ],
    [domain.IntegrationCode.XeroBillBatchPayment]: [
        ESettingsSection.LockDate,
        ESettingsSection.SafetyCatch,
        ESettingsSection.Instruction,
        ESettingsSection.DecisionPolicy,
        ESettingsSection.SupplierBankAccountRequired,
    ],
    [domain.IntegrationCode.QBooksPo]: [
        ESettingsSection.LockDate,
        ESettingsSection.SafetyCatch,
        ESettingsSection.Instruction,
        ESettingsSection.LineItemSettings,
        ESettingsSection.DecisionPolicy,
        ESettingsSection.EmailToSupplierSetting,
        ESettingsSection.QBooksFraudBypassing,
        ESettingsSection.QBooksFraudChangesAfter,
        ESettingsSection.GoodsReceivedNote,
        ESettingsSection.PriceChecker,
    ],
    [domain.IntegrationCode.QBooksBill]: [
        ESettingsSection.LockDate,
        ESettingsSection.SafetyCatch,
        ESettingsSection.Instruction,
        ESettingsSection.LineItemSettings,
        ESettingsSection.DecisionPolicy,
        ESettingsSection.QBooksBillMatching,
        ESettingsSection.QBooksFraudBypassing,
        ESettingsSection.QBooksFraudChangesAfter,
        ESettingsSection.PriceChecker,
        ESettingsSection.CaptureViaEmail,
    ],
    [domain.IntegrationCode.QBooksExpense]: [
        ESettingsSection.LockDate,
        ESettingsSection.SafetyCatch,
        ESettingsSection.Instruction,
        ESettingsSection.LineItemSettings,
        ESettingsSection.QBooksFraudBypassing,
        ESettingsSection.QBooksFraudChangesAfter,
        ESettingsSection.DecisionPolicy,
        ESettingsSection.PriceChecker,
    ],
    [domain.IntegrationCode.QBooksJournalEntry]: [
        ESettingsSection.LockDate,
        ESettingsSection.SafetyCatch,
        ESettingsSection.Instruction,
        ESettingsSection.DecisionPolicy,
    ],
    [domain.IntegrationCode.QBooksVendor]: [
        ESettingsSection.SafetyCatch,
        ESettingsSection.Instruction,
        ESettingsSection.DecisionPolicy,
    ],
    [domain.IntegrationCode.QBooksInvoice]: [
        ESettingsSection.LockDate,
        ESettingsSection.SafetyCatch,
        ESettingsSection.Instruction,
        ESettingsSection.DecisionPolicy,
        ESettingsSection.EmailToSupplierSetting,
        ESettingsSection.QBooksFraudChangesAfter,
    ],
    [domain.IntegrationCode.XeroAmaxPayBatchPayment]: [
        ESettingsSection.SafetyCatch,
        ESettingsSection.Instruction,
        ESettingsSection.DecisionPolicy,
        ESettingsSection.BalanceControlCheck,
    ],
    [domain.IntegrationCode.NetSuiteBill]: [
        ESettingsSection.SafetyCatch,
        ESettingsSection.Instruction,
        ESettingsSection.NetSuiteFraudBypassing,
        ESettingsSection.NetSuiteFraudChangesAfter,
        ESettingsSection.DecisionPolicy,
        ESettingsSection.NetSuiteAvailableLineItemType,
        ESettingsSection.PushApprovalMaxUrlToNetSuite,
        ESettingsSection.CaptureViaEmail,
    ],
    [domain.IntegrationCode.NetSuitePO]: [
        ESettingsSection.SafetyCatch,
        ESettingsSection.Instruction,
        ESettingsSection.NetSuiteFraudBypassing,
        ESettingsSection.NetSuiteFraudChangesAfter,
        ESettingsSection.DecisionPolicy,
        ESettingsSection.EmailToSupplierSetting,
        ESettingsSection.NetSuiteAvailableLineItemType,
        ESettingsSection.PushApprovalMaxUrlToNetSuite,
    ],
    [domain.IntegrationCode.NetSuiteSalesOrder]: [
        ESettingsSection.NetSuiteFraudBypassing,
        ESettingsSection.NetSuiteFraudChangesAfter,
        ESettingsSection.DecisionPolicy,
        ESettingsSection.PushApprovalMaxUrlToNetSuite,
    ],
    [domain.IntegrationCode.NetSuiteInvoice]: [
        ESettingsSection.NetSuiteFraudBypassing,
        ESettingsSection.NetSuiteFraudChangesAfter,
        ESettingsSection.DecisionPolicy,
        ESettingsSection.PushApprovalMaxUrlToNetSuite,
    ],
    [domain.IntegrationCode.NetSuiteExpenseReport]: [
        ESettingsSection.NetSuiteFraudBypassing,
        ESettingsSection.NetSuiteExpenseReportFraudChangesAfter,
        ESettingsSection.Instruction,
        ESettingsSection.DecisionPolicy,
        ESettingsSection.PushApprovalMaxUrlToNetSuite,
    ],
    [domain.IntegrationCode.NetSuiteVRA]: [
        ESettingsSection.NetSuiteFraudBypassing,
        ESettingsSection.NetSuiteFraudChangesAfter,
        ESettingsSection.DecisionPolicy,
        ESettingsSection.PushApprovalMaxUrlToNetSuite,
    ],
    [domain.IntegrationCode.NetSuiteBillPayment]: [
        ESettingsSection.NetSuiteFraudBypassing,
        ESettingsSection.NetSuiteFraudChangesAfter,
        ESettingsSection.DecisionPolicy,
        ESettingsSection.PushApprovalMaxUrlToNetSuite,
    ],
    [domain.IntegrationCode.NetSuiteRA]: [
        ESettingsSection.NetSuiteFraudBypassing,
        ESettingsSection.NetSuiteFraudChangesAfter,
        ESettingsSection.DecisionPolicy,
        ESettingsSection.PushApprovalMaxUrlToNetSuite,
    ],
    [domain.IntegrationCode.NetSuiteJournalEntry]: [
        ESettingsSection.NetSuiteFraudBypassing,
        ESettingsSection.NetSuiteFraudChangesAfter,
        ESettingsSection.DecisionPolicy,
        ESettingsSection.PushApprovalMaxUrlToNetSuite,
    ],
    [domain.IntegrationCode.NetSuiteVendor]: [
        ESettingsSection.Instruction,
        ESettingsSection.DecisionPolicy,
        ESettingsSection.PushApprovalMaxUrlToNetSuite,
    ],
    [domain.IntegrationCode.DearPo]: [
        ESettingsSection.SafetyCatch,
        ESettingsSection.DearFraudBypassing,
        ESettingsSection.DearFraudChangesAfter,
        ESettingsSection.DecisionPolicy,
        ESettingsSection.DearPORejectedNoteToMemo,
    ],
};

export const xeroBillToPoMatchingLearnMore =
    'https://support.approvalmax.com/portal/kb/approvalmax-1/approvalmax-for-xero/bill-to-po-matching';
