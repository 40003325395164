import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('CompleteAirwallexPaymentPopup', {
    payWithAirwallex: 'Pay with Airwallex',
    twoFaIsNotEnabled: '2FA is not enabled',
    disconnectedAirwallex: 'The organisation is disconnected from Airwallex.',
    disconnectedXero: 'The organisation is disconnected from Xero.',
    payNow: 'Pay now',
    schedulePayment: 'Schedule payment',
    alreadyScheduled: 'The payment is already scheduled',
});
