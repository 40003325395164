import { Checkbox } from '@approvalmax/ui/src/components';
import { domain } from 'modules/data';
import { FC, memo, useCallback } from 'react';

import { SettingsSection } from '../SettingsSection/SettingsSection';
import SettingsSubsection from '../SettingsSubsection/SettingsSubsection';
import { messages } from './SectionNetSuiteFraudChangesAfter.messages';
import { SectionNetSuiteFraudChangesAfterProps } from './SectionNetSuiteFraudChangesAfter.types';

const SectionNetSuiteFraudChangesAfter: FC<SectionNetSuiteFraudChangesAfterProps> = memo((props) => {
    const { company, integrationCode, templateSettings, onChange, readonly, qa } = props;

    const { Customer, Payee, Vendor, Amount, Date, Category, Memo, Item, Account, Line } =
        domain.TemplateSettingsNetSuitePostApprovalChangeDetectionTarget;

    const settings = templateSettings.netSuitePostApprovalChangeDetectionSettings;
    const isBillPayment = integrationCode === domain.IntegrationCode.NetSuiteBillPayment;
    const isInvoiceOrSalesOrder =
        integrationCode === domain.IntegrationCode.NetSuiteInvoice ||
        integrationCode === domain.IntegrationCode.NetSuiteSalesOrder;

    const handleChange = useCallback(
        (newTargets: domain.TemplateSettingsNetSuitePostApprovalChangeDetectionTarget[]) => {
            onChange((prevTemplateSettings) => ({
                ...prevTemplateSettings,
                netSuitePostApprovalChangeDetectionSettings:
                    prevTemplateSettings.netSuitePostApprovalChangeDetectionSettings
                        ? {
                              ...prevTemplateSettings.netSuitePostApprovalChangeDetectionSettings,
                              targets: newTargets,
                          }
                        : undefined,
            }));
        },
        [onChange]
    );

    if (!settings) {
        return null;
    }

    return (
        <SettingsSection
            title={messages.title}
            learnMoreLink='https://support.approvalmax.com/portal/en/kb/articles/what-is-the-fraud-detection-changes-after-approval-setting-netsuite'
        >
            <SettingsSubsection subtitle={messages.subtitle}>
                <Checkbox.Group
                    name='detectionChangesAfterApproval'
                    disabled={readonly}
                    onChange={handleChange}
                    value={settings.targets}
                    block
                >
                    {isInvoiceOrSalesOrder && (
                        <Checkbox
                            value={Customer}
                            data-qa={qa(`NetSuiteFraudDetectionChangesAfterSection-${Customer}`)}
                        >
                            {messages.optionCustomer}
                        </Checkbox>
                    )}

                    {isBillPayment && (
                        <Checkbox value={Payee} data-qa={qa(`NetSuiteFraudDetectionChangesAfterSection-${Payee}`)}>
                            {messages.optionPayee}
                        </Checkbox>
                    )}

                    {!isBillPayment && !isInvoiceOrSalesOrder && (
                        <Checkbox value={Vendor} data-qa={qa(`NetSuiteFraudDetectionChangesAfterSection-${Vendor}`)}>
                            {messages.optionVendor}
                        </Checkbox>
                    )}

                    <Checkbox value={Amount} data-qa={qa(`NetSuiteFraudDetectionChangesAfterSection-${Amount}`)}>
                        {messages.optionTotalAmount({ companyCurrencyCode: company.defaultCurrencyText })}
                    </Checkbox>

                    <Checkbox value={Date} data-qa={qa(`NetSuiteFraudDetectionChangesAfterSection-${Date}`)}>
                        {messages.optionDate}
                    </Checkbox>

                    <Checkbox value={Memo} data-qa={qa(`NetSuiteFraudDetectionChangesAfterSection-${Memo}`)}>
                        {messages.optionMemo}
                    </Checkbox>

                    {!isBillPayment && (
                        <Checkbox value={Item} data-qa={qa(`NetSuiteFraudDetectionChangesAfterSection-${Item}`)}>
                            {messages.optionItem}
                        </Checkbox>
                    )}

                    {!isBillPayment && !isInvoiceOrSalesOrder && (
                        <>
                            <Checkbox
                                value={Account}
                                data-qa={qa(`NetSuiteFraudDetectionChangesAfterSection-${Account}`)}
                            >
                                {messages.optionAccount}
                            </Checkbox>

                            <Checkbox
                                value={Category}
                                data-qa={qa(`NetSuiteFraudDetectionChangesAfterSection-${Category}`)}
                            >
                                {messages.optionExpenseCategory}
                            </Checkbox>
                        </>
                    )}

                    {isBillPayment && (
                        <Checkbox value={Line} data-qa={qa(`NetSuiteFraudDetectionChangesAfterSection-${Line}`)}>
                            {messages.optionLine}
                        </Checkbox>
                    )}
                </Checkbox.Group>
            </SettingsSubsection>
        </SettingsSection>
    );
});

SectionNetSuiteFraudChangesAfter.displayName = 'SectionNetSuiteFraudChangesAfter';

export default SectionNetSuiteFraudChangesAfter;
