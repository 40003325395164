import { Box, Popup, Spacing, Text } from '@approvalmax/ui/src/components';
import { memo, useCallback } from 'react';
import bemFactory from 'react-bem-factory';
import { useMount } from 'react-use';

import { TwoFaActionWizardProps } from '../../TwoFaActionWizard.types';
import { EnterCodeForm } from '../EnterCodeForm/EnterCodeForm';
import { messages } from './ScreenEmailCode.messages';

const qa = bemFactory.qa('pro-email-code-popup');

export const ScreenEmailCode = memo<TwoFaActionWizardProps>((props) => {
    const {
        title,
        isLoading,
        onSendCodeViaEmail,
        onEnterEmailCode,
        isTrustThisDeviceEnabled,
        isTFAAlternativeEmailEnabled,
        isTFABackupCodesEnabled,
    } = props;

    const handleSubmit = useCallback(
        (code: string, isTrustedDevice: boolean) => {
            onEnterEmailCode?.(code, isTrustedDevice);
        },
        [onEnterEmailCode]
    );

    useMount(() => {
        onSendCodeViaEmail?.();
    });

    return (
        <>
            <Popup.Header title={title} />

            <Popup.Body qa={qa()}>
                <Box maxWidth={450}>
                    <Text font='body' fontWeight='regular'>
                        {messages.description}
                    </Text>
                </Box>

                <Spacing height={24} />

                <EnterCodeForm
                    isLoading={isLoading}
                    fieldLabel={messages.codeFieldTitle}
                    fieldPlaceholder={messages.emailCodePlaceholder}
                    isTrustedDeviceEnabled={isTrustThisDeviceEnabled}
                    isAlternativeMethodEnabled={isTFAAlternativeEmailEnabled || isTFABackupCodesEnabled}
                    onSubmit={handleSubmit}
                />
            </Popup.Body>
        </>
    );
});

ScreenEmailCode.displayName = 'ScreenEmailCode';
