import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { matchingApiPaths } from '../paths';
import { AddXeroMatchedPosRequestersToActiveStep } from './useAddXeroMatchedPosRequestersToActiveStep.types';

export const useAddXeroMatchedPosRequestersToActiveStep = (
    mutationOptions?: RequestMutationOptions<AddXeroMatchedPosRequestersToActiveStep>
) => {
    return useMutate(matchingApiPaths.addXeroMatchedPosRequestersToActiveStep, {
        mutationOptions,
        apiVersion: 'v1',
        method: 'post',
    });
};
