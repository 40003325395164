import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { workflowsApiPaths } from '../paths';
import { GetWorkflowVersionDocumentFields } from './useGetWorkflowVersionDocumentFields.types';

export const useGetWorkflowVersionDocumentFields = (
    params?: RequestParams<GetWorkflowVersionDocumentFields>,
    queryOptions?: RequestQueryOptions<GetWorkflowVersionDocumentFields>
) => {
    return useGet(workflowsApiPaths.getWorkflowVersionDocumentFields, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetWorkflowVersionDocumentFields['params']['query']>(
                {
                    companyId: params?.query?.companyId,
                    workflowVersionIds: params?.query?.workflowVersionIds,
                },
                queryOptions
            ),
        },
        apiVersion: 'v1',
    });
};
