import { forwardRef, memo, useCallback } from 'react';
import { useController } from 'react-hook-form';

import { DateTimePicker } from '../../DateTimePicker';
import type { ControllerProps } from './Controller.types';

export const Controller = memo(
    forwardRef<HTMLInputElement, ControllerProps>((props, ref) => {
        const { control, name, rules, onChange: onChangeProp, ...restProps } = props;

        const {
            field: { onChange, ...field },
            fieldState,
        } = useController({
            control,
            name: name || 'dateTimePicker',
            rules: { required: restProps.required },
        });

        const handleChange = useCallback(
            (value: Date | undefined) => {
                // need to pass `null` instead of `undefined` in order to clear the value
                onChange(value ?? null);
                onChangeProp?.(value);
            },
            [onChange, onChangeProp]
        );

        return (
            <DateTimePicker
                {...field}
                onChange={handleChange}
                {...fieldState}
                {...restProps}
                ref={ref}
                hint={fieldState.error?.message || restProps.hint}
            />
        );
    })
);

Controller.displayName = 'Controller';
