import { Guid } from '@approvalmax/types';
import { selectors } from 'modules/common';

export interface SchedulePaymentPopupProps {
    request: selectors.types.ExpandedRequest;
}

export enum Step {
    Schedule = 'Schedule',
    TwoFa = 'TwoFa',
}

export interface ProcessSchedulePaymentApiCallParams {
    requestId: Guid;
    companyId: Guid;
    scheduledDate: string;
    oneTimePassword: string;
}
