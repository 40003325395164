import { domHelpers } from '@approvalmax/utils';
import {
    ComponentProps,
    forwardRef,
    ForwardRefExoticComponent,
    memo,
    MemoExoticComponent,
    RefAttributes,
    useCallback,
    useMemo,
} from 'react';

import { Flex } from '../../../Flex/Flex';
import Radio from '../../Radio';
import GroupController from '../GroupController/GroupController';
import { GroupContext } from './Group.context';
import { useCheckedValues } from './Group.hooks';
import { Root } from './Group.styles';
import { ChildrenComponents, GroupProps } from './Group.types';

/**
 * The `Radio.Group` component allows to use `Radio` components as a group.
 * Use the `Radio` component only with `Radio.Group`
 * You can also pass some properties from `Radio` to `Radio.Group` to apply to all radios.
 */
const Group = memo(
    forwardRef<HTMLInputElement, GroupProps>((props, ref) => {
        const { children, spacing, width, direction, onChange: onChangeSrc, value, options, qa, ...restProps } = props;

        const { toggleCheckedValue, getCheckedStatus } = useCheckedValues(value);

        const onChangeGroup = useCallback<Required<ComponentProps<typeof Radio>>['onChange']>(
            (_, event) => {
                toggleCheckedValue(event.target.value);

                onChangeSrc?.(event.target.value, event);
            },
            [onChangeSrc, toggleCheckedValue]
        );

        const contextValue = useMemo(() => {
            return {
                contextProps: {
                    ...restProps,
                    onChangeGroup,
                    getCheckedStatus,
                },
                ref,
            };
        }, [getCheckedStatus, onChangeGroup, ref, restProps]);

        return (
            <Root
                as={Flex}
                spacing={spacing}
                $width={width}
                direction={direction}
                data-qa={domHelpers.generateDataQa(qa, 'radio-group')}
            >
                <GroupContext.Provider value={contextValue}>
                    {options?.map((option) => <Radio {...option} key={option.value} />)}

                    {children}
                </GroupContext.Provider>
            </Root>
        );
    })
) as MemoExoticComponent<ForwardRefExoticComponent<GroupProps & RefAttributes<HTMLInputElement>>> & ChildrenComponents;

Group.Controller = GroupController;

export default Group;
