import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.requestList.xero.XeroPurchaseOrderFields', {
    poDateFieldText: 'Date',
    poDeliveryDateFieldText: 'Delivery Date',
    poNumberFieldText: 'Order number',
    poReferenceFieldText: 'Reference',
    poBrandingThemeFieldText: 'Theme',
    emptyPurchaseOrderNumber: 'Allocated on approval',
});
