import { Guid, Reference, ValueOf } from '@approvalmax/types';
import { arrayHelpers, compareHelpers, intl, mathService, miscHelpers } from '@approvalmax/utils';
import * as Sentry from '@sentry/browser';
import { domain } from 'modules/data';
import { asMutable } from 'modules/immutable';
import moment from 'moment';

import { ExpandedQBooksAccountLineItem } from '../types/ExpandedQBooksAccountLineItem';
import {
    CalculateLineItemsTaxSummaryParams,
    CalculateLineItemTaxesParams,
    CalculateShippingTaxParams,
    CombinedRate,
    TaxLine,
    TaxSummary,
} from '../types/QBooksTax';

export function findEffectiveTaxRate(rate: domain.QBooksTaxRate, date: string): number | null {
    let mdate = moment.utc(date);

    const effectiveRate = rate.effectiveTaxRate.find(
        (r) =>
            (r.startDate != null ? moment.utc(r.startDate).isSameOrBefore(mdate) : true) &&
            (r.endDate != null ? moment.utc(r.endDate).isSameOrAfter(mdate) : true)
    );

    return effectiveRate ? effectiveRate.rate : null;
}

export const getTaxCodeEffectiveRates = (
    taxCodeId: Guid,
    date: string,
    qbooksContext: domain.QBooksContext,
    taxApplicableOnType?: keyof typeof domain.TaxApplicableOnType
) => {
    const taxCode = qbooksContext.taxCodes.find((tc) => tc.id === taxCodeId);

    if (!taxCode) {
        return {
            combinedRates: [],
            effectiveRate: 0,
        };
    }

    const taxRateList =
        taxApplicableOnType === domain.TaxApplicableOnType.Sales ? taxCode.salesTaxRateList : taxCode.taxRateList;

    const combinedRates: CombinedRate[] = arrayHelpers
        .arraySort(asMutable(taxRateList), (a, b) => {
            return compareHelpers.numberComparator2Asc(
                a.taxOnTaxOrder != null ? a.taxOnTaxOrder : -1,
                b.taxOnTaxOrder != null ? b.taxOnTaxOrder : -1
            );
        })
        .map((taxRef) => {
            const rate = qbooksContext.taxRates.find((tr) => tr.id === taxRef.taxRateId);

            if (!rate) {
                Sentry.captureMessage(`Failed to find taxRateId ${taxRef.taxRateId} for taxCode ${taxCodeId}`);

                return null;
            }

            let rateTaxRate = findEffectiveTaxRate(rate, date);

            if (rateTaxRate == null) {
                Sentry.captureMessage(`Failed to find taxRateId ${taxRef.taxRateId} for taxCode ${taxCodeId}`);

                return null;
            }

            return {
                taxRateId: rate.id,
                rate: rateTaxRate,
                name: `Tax ${rateTaxRate}%`,
                hidden: rate.hidden,
                zeroTaxAmount: rate.zeroTaxAmount,
                taxOrder: taxRef.taxOnTaxOrder,
                taxApplicable: taxRef.taxApplicable,
                effectivePercentOnSubtotal: 0,
            };
        })
        .filter(miscHelpers.notEmptyFilter);

    // do calc effective rate like tax exclusive
    let taxableAmount = 100;
    let taxes = calcTaxesOnCombinedRates(taxableAmount, combinedRates);

    const effectiveRate =
        taxes.length > 0
            ? mathService.round(
                  taxes.map((tl) => tl.taxAmount).reduce((m, amt) => m + amt),
                  4
              )
            : 0;

    combinedRates.forEach((cr: CombinedRate, index: number) => {
        cr.effectivePercentOnSubtotal = taxes[index].taxAmount * 100;
    });

    return {
        combinedRates,
        effectiveRate,
    };
};

export const calcTaxesOnCombinedRates = (taxableAmount: number, combinedRates: CombinedRate[]): TaxLine[] => {
    let taxes: TaxLine[] = [];

    combinedRates.forEach((cr: CombinedRate, index: number) => {
        const rateTaxableAmount = taxableAmount;
        const taxRateApplicability = index === 0 ? domain.QBooksTaxTypeApplicable.TaxOnAmount : cr.taxApplicable;

        switch (taxRateApplicability) {
            case domain.QBooksTaxTypeApplicable.TaxOnAmountPlusTax:
                {
                    const stAmount = rateTaxableAmount + taxes[cr.taxOrder - 1].taxAmount;
                    const taxableAmount = Math.round(stAmount * 100) / 100;

                    taxes.push({
                        taxableAmount,
                        taxAmount: Math.round(taxableAmount * cr.rate) / 100,
                        unroundedTaxAmount: (stAmount * cr.rate) / 100,
                        name: `${cr.name} on ${intl.formatNumber(stAmount, 2)}`,
                        rate: cr.rate,
                    });
                }

                break;

            case domain.QBooksTaxTypeApplicable.TaxOnTax:
                {
                    const stAmount = taxes[cr.taxOrder - 1].taxAmount;

                    taxes.push({
                        taxableAmount: stAmount,
                        taxAmount: Math.round(stAmount * cr.rate) / 100,
                        unroundedTaxAmount: (stAmount * cr.rate) / 100,
                        name: `${cr.name} on ${intl.formatNumber(stAmount, 2)}`,
                        rate: cr.rate,
                    });
                }

                break;

            default:
                taxes.push({
                    taxableAmount: rateTaxableAmount,
                    taxAmount: Math.round(rateTaxableAmount * cr.rate) / 100,
                    unroundedTaxAmount: (rateTaxableAmount * cr.rate) / 100,
                    name: `${cr.name} on ${intl.formatNumber(rateTaxableAmount, 2)}`,
                    rate: cr.rate,
                });
        }
    });

    if (taxes.length > 0) {
        const { effectiveTotalTax, totalTax } = taxes.reduce(
            (accumData, tax) => ({
                effectiveTotalTax: tax.unroundedTaxAmount + accumData.effectiveTotalTax,
                totalTax: mathService.round(tax.taxAmount + accumData.totalTax, 2),
            }),
            { effectiveTotalTax: 0, totalTax: 0 }
        );

        let diff = mathService.subtract(effectiveTotalTax, totalTax);

        if (diff !== 0) {
            if (diff > 0) {
                taxes[0].taxAmount = mathService.add(taxes[0].taxAmount, diff);
            } else {
                for (let tax of taxes) {
                    if (Math.abs(tax.taxAmount) >= Math.abs(diff)) {
                        tax.taxAmount = mathService.add(tax.taxAmount, diff);
                        break;
                    } else {
                        diff = mathService.add(diff, tax.taxAmount);
                        tax.taxAmount = 0;
                    }
                }
            }
        }
    }

    return taxes;
};

const calculateLineItemTaxes = ({
    lineAmountType,
    date,
    lineItemAmount,
    lineItemTaxCode,
    qbooksContext,
    taxApplicableOnType,
    discount,
    discountType,
    subtotalAmount,
}: CalculateLineItemTaxesParams): domain.QBooksTaxComponent[] => {
    const itemAmount = lineItemAmount;
    const liTaxCode = lineItemTaxCode;

    if (!liTaxCode || (!itemAmount && itemAmount !== 0) || lineAmountType === domain.LineAmountType.NoTax) {
        return [];
    }

    const taxCode = qbooksContext.taxCodes.find(({ id }) => id === liTaxCode.id);

    if (!taxCode) {
        return [];
    }

    let expandedCode = getTaxCodeEffectiveRates(taxCode.id, date, qbooksContext, taxApplicableOnType);

    let taxableAmount =
        (itemAmount * 100) /
        (100 + (lineAmountType === domain.LineAmountType.TaxExclusive ? 0 : expandedCode.effectiveRate));

    if (subtotalAmount && discount) {
        taxableAmount = mathService.round(
            discountType === domain.QBooksDiscountType.Amount
                ? mathService.subtract(
                      taxableAmount,
                      mathService.divide(mathService.multiply(taxableAmount, discount), subtotalAmount)
                  )
                : mathService.divide(mathService.multiply(taxableAmount, mathService.subtract(100, discount)), 100),
            7
        );
    }

    let taxes = calcTaxesOnCombinedRates(taxableAmount, expandedCode.combinedRates);

    return expandedCode.combinedRates.map((combinedRate, index) => ({
        taxRateId: combinedRate.taxRateId,
        taxComponentName: taxes[index].name,
        taxAmount: mathService.round(taxes[index].taxAmount, 2),
        taxableAmount: mathService.round(taxes[index].taxableAmount, 2),
        hidden: combinedRate.hidden,
        taxPercent: combinedRate.rate,
    }));
};

const calculateLineItemTaxesUS = (
    date: string,
    lineItemAmount: number | undefined,
    globalTaxCode: Reference | null,
    qbooksContext: domain.QBooksContext
): domain.QBooksTaxComponent[] => {
    const itemAmount = lineItemAmount;

    if (!globalTaxCode || (!itemAmount && itemAmount !== 0)) {
        return [];
    }

    const taxCode = qbooksContext.taxCodes.find(({ id }) => id === globalTaxCode.id);

    if (!taxCode) {
        return [];
    }

    let expandedCode = getTaxCodeEffectiveRates(taxCode.id, date, qbooksContext, domain.TaxApplicableOnType.Sales);

    let taxes = calcTaxesOnCombinedRates(mathService.round(itemAmount, 7), expandedCode.combinedRates);

    return expandedCode.combinedRates.map((combinedRate, index) => ({
        taxRateId: combinedRate.taxRateId,
        taxComponentName: taxes[index].name,
        taxAmount: mathService.round(taxes[index].taxAmount, 7),
        taxableAmount: mathService.round(taxes[index].taxableAmount, 7),
        hidden: combinedRate.hidden,
        taxPercent: combinedRate.rate,
    }));
};

export const calculateShippingTaxSummary = (
    details: domain.QBooksSalesInvoiceDetails,
    qbooksContext: domain.QBooksContext
): TaxSummary => {
    const shippingTaxComponents = calculateShippingTax({
        date: details.date,
        shippingPrice: details.shipping.price,
        shippingTaxCode: details.shippingTaxCode,
        qbooksContext,
    });

    const totalTax = shippingTaxComponents.reduce((acc, { taxAmount }) => acc + taxAmount, 0);

    return {
        totalTax,
        taxComponents: shippingTaxComponents,
    };
};

export const calculateShippingTax = ({
    date,
    shippingPrice,
    shippingTaxCode,
    qbooksContext,
}: CalculateShippingTaxParams): domain.QBooksTaxComponent[] => {
    if (!shippingTaxCode || (!shippingPrice && shippingPrice !== 0)) {
        return [];
    }

    const taxCode = qbooksContext.taxCodes.find(({ id }) => id === shippingTaxCode.id);

    if (!taxCode) {
        return [];
    }

    const expandedCode = getTaxCodeEffectiveRates(taxCode.id, date, qbooksContext, domain.TaxApplicableOnType.Sales);

    // TODO: QBO Sales Invoice: should be updated in task with shipping field update
    // const taxableAmount = shippingPrice;
    // (shippingPrice * 100) /
    // (100 + (lineAmountType === domain.LineAmountType.TaxInclusive ? expandedCode.effectiveRate : 0));

    let taxes = calcTaxesOnCombinedRates(shippingPrice, expandedCode.combinedRates);

    return expandedCode.combinedRates.map((combinedRate, index) => ({
        taxRateId: combinedRate.taxRateId,
        taxComponentName: taxes[index].name,
        taxAmount: mathService.round(taxes[index].taxAmount, 2),
        taxableAmount: mathService.round(taxes[index].taxableAmount, 2),
        hidden: combinedRate.hidden,
        taxPercent: combinedRate.rate,
    }));
};

export const calculateLineItemsTaxSummary = ({
    lineItems,
    lineAmountType,
    date,
    qbooksContext,
    taxApplicableOnType,
    discountType,
    discount,
}: CalculateLineItemsTaxSummaryParams): TaxSummary => {
    const originalSubtotalAmount = lineItems.reduce(
        (acc, { unitPrice, qty }) => mathService.add(acc, mathService.multiply(unitPrice ?? 0, qty ?? 0)),
        0
    );

    const liTaxComponents = lineItems.flatMap(({ computedAmount, taxCode }) =>
        calculateLineItemTaxes({
            lineAmountType,
            date,
            lineItemAmount: computedAmount,
            lineItemTaxCode: taxCode,
            qbooksContext,
            taxApplicableOnType,
            discountType,
            discount,
            subtotalAmount: originalSubtotalAmount,
        })
    );

    const [totalTax, taxComponents] = getAggregatedTaxComponents(liTaxComponents);

    return {
        totalTax,
        taxComponents,
    };
};

export const calculateLineItemsTaxSummaryUS = ({
    date,
    lineItems,
    qbooksContext,
    taxCode = null,
    discount,
    discountType,
    applyTaxAfterDiscount,
}: CalculateLineItemsTaxSummaryParams): TaxSummary => {
    const amount = lineItems.reduce((acc, { computedAmount }) => acc + (computedAmount || 0), 0);

    let liTaxComponents = lineItems.flatMap(({ computedAmount, isTaxable }) => {
        let itemNetAmount = isTaxable ? computedAmount : 0;

        if (applyTaxAfterDiscount && computedAmount && discount) {
            const discountAmount =
                discountType === domain.QBooksDiscountType.Amount
                    ? mathService.subtract(
                          computedAmount,
                          mathService.divide(mathService.multiply(discount, computedAmount), amount)
                      )
                    : mathService.multiply(
                          computedAmount,
                          mathService.divide(mathService.subtract(100, discount), 100)
                      );

            itemNetAmount = isTaxable ? discountAmount : 0;
        }

        return calculateLineItemTaxesUS(date, itemNetAmount, taxCode, qbooksContext);
    });

    const [totalTax, taxComponents] = getAggregatedTaxComponents(liTaxComponents);

    return {
        totalTax,
        taxComponents,
    };
};

const getAggregatedTaxComponents = (
    taxComponents: domain.QBooksTaxComponent[]
): [totalTax: number, aggregatedTaxComponents: domain.QBooksTaxComponent[]] => {
    let totalTax = 0;
    let aggregatedTaxComponents: domain.QBooksTaxComponent[] = [];

    taxComponents.forEach((taxComponent) => {
        totalTax = mathService.round(mathService.add(totalTax, taxComponent.taxAmount), 2);

        let existing = aggregatedTaxComponents.find(({ taxRateId }) => taxRateId === taxComponent.taxRateId);

        if (existing) {
            existing.taxableAmount = mathService.add(existing.taxableAmount, taxComponent.taxableAmount);
            existing.taxAmount = mathService.add(existing.taxAmount, taxComponent.taxAmount);
        } else {
            aggregatedTaxComponents.push(taxComponent);
        }
    });

    return [totalTax, aggregatedTaxComponents];
};

export const calculateTotalTaxSummary = (
    lineItemsTaxSummary: domain.QBooksTaxComponent[],
    shippingTaxSummary: domain.QBooksTaxComponent[]
): TaxSummary => {
    let totalTax = 0;

    const combinedTaxSummary = [...lineItemsTaxSummary, ...shippingTaxSummary];
    const taxComponents = combinedTaxSummary.reduce<domain.QBooksTaxComponent[]>((acc, taxComponent) => {
        totalTax = mathService.round(mathService.add(totalTax, taxComponent.taxAmount), 2);

        const existing = acc.find(({ taxRateId }) => taxRateId === taxComponent.taxRateId);

        if (existing) {
            existing.taxableAmount = mathService.add(existing.taxableAmount, taxComponent.taxableAmount);
            existing.taxAmount = mathService.add(existing.taxAmount, taxComponent.taxAmount);
        } else {
            acc.push(taxComponent);
        }

        return acc;
    }, []);

    return {
        totalTax,
        taxComponents,
    };
};

export function calculateAccountLineItemsTaxSummary(
    lineAmountType: ValueOf<domain.LineAmountType>,
    date: string,
    lineItems: ExpandedQBooksAccountLineItem[],
    qbooksContext: domain.QBooksContext
) {
    let liTaxComponents = lineItems.flatMap((li) =>
        calculateLineItemTaxes({
            lineAmountType,
            date,
            lineItemAmount: li.amount,
            lineItemTaxCode: li.taxCode,
            qbooksContext,
        })
    );

    let totalTax = 0;
    let aggregatedTaxComponents: domain.QBooksTaxComponent[] = [];

    liTaxComponents.forEach((tc) => {
        totalTax = mathService.round(mathService.add(totalTax, tc.taxAmount), 2);

        let existing = aggregatedTaxComponents.find((x) => x.taxRateId === tc.taxRateId);

        if (existing) {
            existing.taxableAmount = mathService.add(existing.taxableAmount, tc.taxableAmount);
            existing.taxAmount = mathService.add(existing.taxAmount, tc.taxAmount);
        } else {
            aggregatedTaxComponents.push(tc);
        }
    });

    return {
        totalTax,
        taxComponents: aggregatedTaxComponents,
    };
}

export const getTaxCodeList = (
    qbooksContext: domain.QBooksContext,
    requestDate: string,
    taxApplicableOnType?: domain.TaxApplicableOnType
) => {
    const unavailableTaxRateIds = requestDate
        ? qbooksContext.taxRates
              .filter((taxRate) => {
                  const effectiveTaxRate = findEffectiveTaxRate(taxRate, requestDate);

                  return effectiveTaxRate === null;
              })
              .map((taxRate) => taxRate.id)
        : [];

    return qbooksContext.taxCodes.filter((taxCode) => {
        if (taxCode.taxRateList.length === 0 && taxCode.salesTaxRateList.length === 0) {
            return false;
        }

        const isAvailableTaxRate = taxCode.taxRateList.some(
            (taxRate) => !unavailableTaxRateIds.includes(taxRate.taxRateId)
        );

        const isAvailableSalesTaxRate = taxCode.salesTaxRateList.some(
            ({ taxRateId }) => !unavailableTaxRateIds.includes(taxRateId)
        );

        if (taxApplicableOnType === domain.TaxApplicableOnType.Sales) {
            return isAvailableSalesTaxRate;
        }

        const isSalesOnlyTaxCode = taxCode.salesTaxRateList.length > 0 && taxCode.taxRateList.length === 0;

        return !isSalesOnlyTaxCode && (isAvailableTaxRate || taxCode.taxRateList.length === 0);
    });
};
