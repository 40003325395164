import { Guid } from '@approvalmax/types';
import { SortOrder } from '@approvalmax/ui/src/components';

import { BillsListType } from '../../../shared/components/AddBillInvoicesPopup/AddBillInvoicesPopup.types';
import { AirwallexBatchSelectableBillItem } from './XeroAirwallexBatchPayment';
import { AmaxPayBatchSelectableBillItem } from './XeroAmaxPayBatchPayment';
import { XeroBatchSelectableBillItem } from './XeroBillBatchPayment';

export interface SelectableBillItemForBatchPaymentAdditionalData {
    plannedDate: string | null;
    selected: boolean;
    id: string;
}

export type BatchPaymentSelectableBillItem =
    | AmaxPayBatchSelectableBillItem
    | XeroBatchSelectableBillItem
    | AirwallexBatchSelectableBillItem;

export interface AddBillsToBatchFilterData {
    contactId: Guid | null;
    reference: string;
    amountFrom: number | null;
    amountTo: number | null;
    dateFrom: Date | null;
    dateTo: Date | null;
    dueDateFrom: Date | null;
    dueDateTo: Date | null;
    plannedDateFrom: Date | null;
    plannedDateTo: Date | null;
    excludeBillsWithZeroAmount: boolean;
    billsListType: BillsListType;
}

export interface AddBillsToBatchSortingConfig {
    sortBy: string;
    sortOrder: SortOrder;
}

export enum AddBillsToBatchTableColumns {
    Name = 'Name',
    Supplier = 'Supplier',
    Date = 'Date',
    DueDate = 'DueDate',
    PlannedDate = 'PlannedDate',
    AmountDue = 'AmountDue',
}
