import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { companiesApiPaths } from '../paths';
import { GetFields } from './useGetFields.types';

export const useGetFields = (params?: RequestParams<GetFields>, queryOptions?: RequestQueryOptions<GetFields>) => {
    return useGet(companiesApiPaths.selectFields, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetFields['params']['query']>(
                {
                    companyId: params?.query?.companyId,
                    withValues: params?.query?.withValues,
                },
                queryOptions
            ),
        },
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};
