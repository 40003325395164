import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data/api';

import { slackApiPaths } from '../paths';
import { ConnectSlackUser } from './useConnectSlackUser.types';

export const useConnectSlackUser = (
    params?: RequestParams<ConnectSlackUser>,
    queryOptions?: RequestQueryOptions<ConnectSlackUser>
) => {
    return useGet(slackApiPaths.authConnectUser, {
        params,
        queryOptions,
        apiSource: 'slack',
        apiVersion: 'v1',
        method: 'get',
    });
};
