import { MutateDataParams, MutationOptions } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data/api';

import { requestsApiPaths } from '../paths';
import {
    UseAmaxPayPaymentDetailsDiscrepanciesData,
    UseAmaxPayPaymentDetailsDiscrepanciesPathParams,
    UseAmaxPayPaymentDetailsDiscrepanciesResponse,
} from './useAmaxPayPaymentDetailsDiscrepancies.types';

export const useAmaxPayPaymentDetailsDiscrepancies = (
    mutationOptions?: MutationOptions<
        UseAmaxPayPaymentDetailsDiscrepanciesResponse,
        MutateDataParams<UseAmaxPayPaymentDetailsDiscrepanciesData, UseAmaxPayPaymentDetailsDiscrepanciesPathParams>
    >
) => {
    return useMutateLegacy<
        MutateDataParams<UseAmaxPayPaymentDetailsDiscrepanciesData, UseAmaxPayPaymentDetailsDiscrepanciesPathParams>,
        UseAmaxPayPaymentDetailsDiscrepanciesResponse
    >(requestsApiPaths.amaxPayPaymentDetailsDiscrepancies, {
        mutationOptions,
    });
};
