import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { integrationApiPaths } from '../paths';
import { GetNetSuiteExchangeRates } from './useGetNetSuiteExchangeRates.types';

export const useGetNetSuiteExchangeRates = (
    params?: RequestParams<GetNetSuiteExchangeRates>,
    queryOptions?: RequestQueryOptions<GetNetSuiteExchangeRates>
) => {
    return useGet(integrationApiPaths.getNetSuiteExchangeRates, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetNetSuiteExchangeRates['params']['query']>(
                {
                    companyId: params?.query?.companyId,
                },
                queryOptions
            ),
        },
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};
