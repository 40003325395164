import { font } from 'modules/styles';
import styled from 'styled-components';

export const Text = styled.span`
    ${font(13, '#7f7d7d')}
    margin-left: 5px;
    margin-bottom: 11px;
`;

export const Amount = styled.span`
    ${font(13, '#000', 'semibold')}
    display: inline-block;
    width: 160px;
    text-align: right;
`;

export const Line = styled.div`
    text-align: right;
`;

export const CurrencyText = styled.span`
    ${font(11, '#565656', 'semibold')}
    line-height: 20px;
`;

export const CurrencyAmount = styled.span`
    ${font(13, '#000', 'semibold')}
    line-height: 20px;
    display: inline-block;
    width: 160px;
    text-align: right;
`;
