import moment from 'moment/moment';

/**
 * Get the date or a minimum date if the date going before it
 */
export const getMinDate = (date: Date | undefined, minDate: Date | string | undefined | null) => {
    if (minDate && date) {
        return moment(date).isBefore(minDate) ? new Date(minDate) : date;
    }

    return date;
};
