import { GetNextPageParam, InfiniteQueryOptions } from '@approvalmax/data';
import { useGetInfiniteLegacy } from 'shared/data/api';

import { integrationsApiPaths } from '../paths';
import {
    UseGetXeroCounterpartiesParams,
    UseGetXeroCounterpartiesPathParams,
    UseGetXeroCounterpartiesResponse,
    UseGetXeroCounterpartiesResponseBackend,
} from './useGetXeroCounterparties.types';

export const useGetXeroCounterparties = (
    pathParams: UseGetXeroCounterpartiesPathParams,
    params: UseGetXeroCounterpartiesParams,
    getNextPageParam: GetNextPageParam<UseGetXeroCounterpartiesResponse, UseGetXeroCounterpartiesParams>,
    queryOptions?: InfiniteQueryOptions<UseGetXeroCounterpartiesResponseBackend, UseGetXeroCounterpartiesResponse>
) => {
    return useGetInfiniteLegacy<UseGetXeroCounterpartiesResponseBackend, UseGetXeroCounterpartiesResponse>(
        integrationsApiPaths.getXeroCounterparties,
        {
            queryParams: params,
            pathParams,
            getNextPageParam,
            queryOptions,
            method: 'get',
        }
    );
};
