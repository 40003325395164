import { useGetLegacy } from 'shared/data';

import { twoFaApiPaths } from '../paths';
import { mapData } from './useGetTFAAlternativeMethods.map';
import { GetTFAAlternativeMethodsBackend, GetTFAAlternativeMethodsResponse } from './useGetTFAAlternativeMethods.types';

export const useGetTFAAlternativeMethods = () => {
    return useGetLegacy<GetTFAAlternativeMethodsBackend, GetTFAAlternativeMethodsResponse>(
        twoFaApiPaths.getTFAAlternativeMethods,
        {
            mapData,
        }
    );
};
