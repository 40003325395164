import { Popup } from '@approvalmax/ui';
import { Box, Button, Flex, Text } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { XERO_AIRWALLEX_KB_LINK } from 'modules/common/constants/xeroConstants';
import { domain } from 'modules/data';
import { GlobalLoadingBar } from 'modules/page';
import { useSelector } from 'modules/react-redux';
import { getIsPayer } from 'pages/requestList/selectors/requestSelectors';
import { memo, useCallback } from 'react';
import { useToggle } from 'react-use';
import { useSetRecoilState } from 'recoil';

import { schedulePaymentPopupOpenState } from '../../../../../XeroRequestCard/components/SchedulePaymentPopup/SchedulePaymentPopup.states';
import { useProcessCancelScheduledPayment, useScheduledPaymentDate } from './ScheduledPaymentSection.hooks';
import { messages } from './ScheduledPaymentSection.messages';
import { DateTimeTextWrapper, SectionHeaderText, StyledExternalLink } from './ScheduledPaymentSection.styles';
import { ScheduledPaymentSectionProps } from './ScheduledPaymentSection.types';

const ScheduledPaymentSection = memo<ScheduledPaymentSectionProps>((props) => {
    const { request } = props;

    const { scheduledPaymentDate, scheduledPaymentFailed } = request.details;
    const company = useSelector((state) => selectors.company.findCompanyById(state, request.companyId));
    const setIsOpenSchedulePaymentPopup = useSetRecoilState(schedulePaymentPopupOpenState);
    const [isOpenCancelPopup, toggleIsOpenCancelPopup] = useToggle(false);
    const isActiveXeroIntegration =
        company?.integration?.integrationType === domain.IntegrationType.Xero &&
        company?.integration?.status === domain.IntegrationStatus.Connected;
    const { is2faEnabled, databaseId: userId } = useSelector(selectors.profile.getProfile);

    const expandedRequest = useSelector((state) => request && selectors.request.expandRequest(state, request));
    const meIsPayer = userId ? getIsPayer(userId, expandedRequest) : false;

    const openScheduledPaymentPopup = useCallback(() => {
        setIsOpenSchedulePaymentPopup(true);
    }, [setIsOpenSchedulePaymentPopup]);

    const { processCancelScheduledPaymentApiCall, isLoading } = useProcessCancelScheduledPayment({
        requestId: request.id,
        companyId: request.companyId,
    });

    const cancelScheduledPayment = useCallback(async () => {
        await processCancelScheduledPaymentApiCall();
        toggleIsOpenCancelPopup();
    }, [processCancelScheduledPaymentApiCall, toggleIsOpenCancelPopup]);

    const { dateString, timeString } = useScheduledPaymentDate(scheduledPaymentDate);

    if (!dateString || !timeString) {
        return null;
    }

    return (
        <Box bordered spacing='12 20'>
            <GlobalLoadingBar isLoading={isLoading} />

            <Flex direction='column' spacing='8'>
                <Flex justifyContent='space-between' alignItems='center'>
                    <SectionHeaderText>{messages.title}</SectionHeaderText>

                    <Flex alignItems='center'>
                        <Button
                            outline
                            color='green80'
                            size='small'
                            onClick={toggleIsOpenCancelPopup}
                            disabled={!meIsPayer}
                        >
                            {messages.cancel}
                        </Button>

                        <Button
                            color='green80'
                            size='small'
                            onClick={openScheduledPaymentPopup}
                            title={!is2faEnabled ? messages.twoFaIsNotEnabled : undefined}
                            disabled={!isActiveXeroIntegration || !meIsPayer || !is2faEnabled}
                        >
                            {messages.edit}
                        </Button>
                    </Flex>
                </Flex>

                {scheduledPaymentFailed && (
                    <Text font='body' fontSize='small' color='midnight70'>
                        {messages.failedText({
                            learnMoreLink: (
                                <StyledExternalLink href={XERO_AIRWALLEX_KB_LINK}>Learn more</StyledExternalLink>
                            ),
                        })}
                    </Text>
                )}

                <Flex spacing='32'>
                    <Flex direction='column' spacing='2'>
                        <Text font='body' fontSize='small' fontWeight='medium' color='midnight70'>
                            {messages.paymentDate}
                        </Text>

                        <DateTimeTextWrapper $invalid={scheduledPaymentFailed}>
                            <Text font='body' fontWeight={scheduledPaymentFailed ? 'medium' : 'regular'}>
                                {dateString}
                            </Text>
                        </DateTimeTextWrapper>
                    </Flex>

                    <Flex direction='column' spacing='2'>
                        <Text font='body' fontSize='small' fontWeight='medium' color='midnight70'>
                            {messages.paymentTime}
                        </Text>

                        <DateTimeTextWrapper $invalid={scheduledPaymentFailed}>
                            <Text font='body' fontWeight={scheduledPaymentFailed ? 'medium' : 'regular'}>
                                {timeString}
                            </Text>
                        </DateTimeTextWrapper>
                    </Flex>
                </Flex>
            </Flex>

            <Popup isOpen={isOpenCancelPopup} onRequestClose={toggleIsOpenCancelPopup} disableAutoClose>
                <Popup.ConfirmationContent
                    onConfirm={cancelScheduledPayment}
                    onCancel={toggleIsOpenCancelPopup}
                    title={messages.cancellationPopupTitle}
                    description={messages.cancellationPopupDescription({
                        date: dateString,
                        time: timeString,
                    })}
                    confirmButtonText={messages.confirmCancellation}
                    cancelButtonText={messages.cancelButtonText}
                />
            </Popup>
        </Box>
    );
});

export default ScheduledPaymentSection;
