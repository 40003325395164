import { domain } from 'modules/data';
import { AftPromotionType } from 'shared/states';

import { messages } from './FeaturePromotion.messages';
import { FeaturePromotionProps } from './FeaturePromotion.types';

export const getTitle = (promotionType: AftPromotionType) => {
    switch (promotionType) {
        case AftPromotionType.BillToPoMatchingBill:
        case AftPromotionType.BillToPoMatchingPO:
            return messages.titleBillToPOMatching;

        case AftPromotionType.XeroBudgetChecking:
            return messages.titleBudgetChecking;

        case AftPromotionType.GoodsReceivedNote:
            return messages.titleGoodsReceivedNote;

        default: {
            return '';
        }
    }
};

export const getDescription = (promotionType: AftPromotionType) => {
    switch (promotionType) {
        case AftPromotionType.BillToPoMatchingBill:
            return messages.descriptionBillToPOMatchingBill;

        case AftPromotionType.BillToPoMatchingPO:
            return messages.descriptionBillToPOMatchingPO;

        case AftPromotionType.XeroBudgetChecking:
            return messages.descriptionBudgetChecking;

        case AftPromotionType.GoodsReceivedNote:
            return messages.descriptionGoodsReceivedNote;

        default: {
            return '';
        }
    }
};

export const getOrigin = (promotionType: AftPromotionType) => {
    switch (promotionType) {
        case AftPromotionType.BillToPoMatchingBill:
        case AftPromotionType.BillToPoMatchingPO:
            return 'po_matching_requestcard';

        case AftPromotionType.XeroBudgetChecking:
            return 'budgetchecking_requestcard';

        case AftPromotionType.GoodsReceivedNote:
            return 'GRN_requestcard';

        default: {
            return 'po_matching_requestcard';
        }
    }
};

export const getLearnMoreLink = (
    promotionType: AftPromotionType,
    integrationType: FeaturePromotionProps['integrationType']
) => {
    switch (promotionType) {
        case AftPromotionType.BillToPoMatchingBill:
        case AftPromotionType.BillToPoMatchingPO:
            if (integrationType === domain.IntegrationType.QBooks) {
                return 'https://support.approvalmax.com/portal/en/kb/approvalmax-1/approvalmax-for-quickbooks-online/matching';
            } else {
                return 'https://support.approvalmax.com/portal/en/kb/approvalmax-1/approvalmax-for-xero/bill-to-po-matching';
            }

        case AftPromotionType.XeroBudgetChecking:
            return 'https://support.approvalmax.com/portal/en/kb/approvalmax-1/approvalmax-for-xero/budget-checking';

        case AftPromotionType.GoodsReceivedNote:
            if (integrationType === domain.IntegrationType.QBooks) {
                return 'https://support.approvalmax.com/portal/en/kb/articles/does-approvalmax-support-goods-received-note-for-quickbooks-online-po';
            } else {
                return 'https://support.approvalmax.com/portal/en/kb/articles/does-approvalmax-support-goods-received-note-for-xero-po';
            }

        default: {
            return '';
        }
    }
};
