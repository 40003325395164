import './fieldsSection.scss';

import React, { FC, PropsWithChildren } from 'react';
import bemFactory from 'react-bem-factory';

import FieldEntry from './FieldEntry';

const bem = bemFactory.block('reql-fields-section');

export interface ChildrenComponents {
    Field: typeof FieldEntry;
}

export interface FieldsSectionProps extends PropsWithChildren {
    className?: string;
}

const FieldsSection: FC<FieldsSectionProps> & ChildrenComponents = (props) => {
    const { className, children } = props;

    if (React.Children.count(children) === 0) {
        return null;
    }

    return <div className={bem.add(className)()}>{children}</div>;
};

FieldsSection.Field = FieldEntry;

export default FieldsSection;
