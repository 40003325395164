import { errorHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { store } from 'modules/store';
import { getPath, Path } from 'urlBuilder';

import { routingService } from '../routing';
import { isShowCompleteSetup, isShowMobile } from './utils';

export const mobileAppService = {
    init() {
        try {
            if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPod/i)) {
                let frg = window.location.hash;

                frg = frg && frg.substr(1);

                let redirect = (location: string) => {
                    const el = document.createElement('iframe');

                    el.src = location;
                    el.className = 'u-hiddenVisually';
                    document.body.appendChild(el);
                };

                redirect('approvalmax://app?' + frg);
            }
        } catch (error) {
            errorHelpers.captureException(error);
        }
    },
    checkAndRedirectToMobile() {
        try {
            const state = store.getState();
            const startupData = selectors.session.getStartupData(state);

            if (startupData.needsToCompleteSignupWizard) {
                routingService.push(getPath(Path.profileSetupEmailOld));
            } else if (isShowCompleteSetup(state)) {
                routingService.push(getPath(Path.completeSetup));
            } else if (isShowMobile()) {
                routingService.push(getPath(Path.mobile));
            }
        } catch (error) {
            errorHelpers.captureException(error);
        }
    },
};
