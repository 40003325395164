import { CloseIcon } from '@approvalmax/ui';
import { Box, Button, Grid, Text } from '@approvalmax/ui/src/components';
import { memo } from 'react';
import { Link } from 'react-router-dom';

import { getTextByUseCase } from './PushingInviteTeammates.helpers';
import { usePushingInviteTeammates } from './PushingInviteTeammates.hooks';
import { messages } from './PushingInviteTeammates.messages';

export const PushingInviteTeammates = memo(() => {
    const { isVisible, companyUsersPath, handleInvite, handleHide } = usePushingInviteTeammates();

    if (!isVisible) {
        return null;
    }

    return (
        <Box color='black100' spacing='8 60'>
            <Grid gridTemplateColumns='auto max-content max-content' alignItems='center' gap={16}>
                <Text font='label' fontSize='medium'>
                    {messages.description({
                        useCase: getTextByUseCase(),
                    })}
                </Text>

                <Button color='white100' size='small' as={Link} to={companyUsersPath} onClick={handleInvite}>
                    {messages.invite}
                </Button>

                <Button size='small' color='transparent' noPadding onClick={handleHide}>
                    <CloseIcon color='white100' size={28} />
                </Button>
            </Grid>
        </Box>
    );
});
