import { DeviceNotebookIcon } from '@approvalmax/ui';
import { Box, Button, Grid, Link, Popup, Progress, Stepper, Text } from '@approvalmax/ui/src/components';
import { intl } from '@approvalmax/utils';
import encodeQR from '@paulmillr/qr';
import { domain } from 'modules/data';
import moment from 'moment';
import { memo, useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useLocation } from 'react-router-dom';
import { routingService } from 'services/routing';
import { useAmaxPayXeroRefreshByPaymentId, useBillRequestPaymentDetails } from 'shared/data';
import { getPath, Path } from 'urlBuilder';

import { messages } from './PaymentPopupContent.messages';
import { PaymentPopupContentProps } from './PaymentPopupContent.types';

export const PaymentPopupContent = memo<PaymentPopupContentProps>((props) => {
    const { companyId, requestId, paymentData, showPaymentCanceledPopup } = props;

    const [activeStep, setActiveStep] = useState(0);

    const { data: paymentStatusData } = useAmaxPayXeroRefreshByPaymentId(
        {
            companyId,
            paymentId: paymentData?.paymentId,
        },
        {
            refetchInterval: 5 * 1000,
            cacheTime: 0,
        }
    );

    const { data: paymentDetails, isFetching: isPaymentDetailsLoading } = useBillRequestPaymentDetails({
        companyId,
        requestId,
        billRequestId: paymentData?.billInvoiceId,
    });

    const { pathname, search } = useLocation();
    const qrCodeSrc = useMemo(
        () => `data:image/svg+xml;utf8,${encodeURIComponent(encodeQR(paymentData?.authorisationUrl ?? '', 'svg'))}`,
        [paymentData?.authorisationUrl]
    );

    useEffect(() => {
        let timerId: ReturnType<typeof setTimeout>;

        if (!paymentStatusData?.data) {
            return;
        }

        if (paymentStatusData.data.paymentStatus !== domain.AmaxPayPaymentStatus.ReadyToPay) {
            if (paymentStatusData.data.paymentStatus === domain.AmaxPayPaymentStatus.Processing) {
                setActiveStep((prevActiveStep) => {
                    if (prevActiveStep === 1) {
                        return 2;
                    }

                    return prevActiveStep;
                });
            }

            const searchParams = new URLSearchParams(search);

            searchParams.set('showPaymentSummary', 'true');

            const successPageUrl = getPath(Path.amaxPaymentSuccess, `${pathname}?${searchParams.toString()}`);

            // setTimeout here is just to make UX more friendly, otherwise
            // user never see loading state on the 3rd stepper's step
            timerId = setTimeout(() => {
                routingService.reloadToUrl(successPageUrl);
            }, 2 * 1000);
        } else if (paymentStatusData.data.authorisationUrlOpenedAt) {
            const openAt = moment(paymentStatusData.data.authorisationUrlOpenedAt);
            const duration = moment.duration(openAt.diff(moment()));

            // covers a case then user opens a bank app, but don't do anything and got inactivity timeout,
            // then opens this popup again. At this point field `authorisationUrlOpenedAt` will already be
            // filled by old date
            if (duration.asMinutes() > -10) {
                setActiveStep((prevActiveStep) => {
                    if (prevActiveStep === 0) {
                        return 1;
                    }

                    return prevActiveStep;
                });
            }
        }

        if (paymentStatusData.data.isConsentRejected) {
            showPaymentCanceledPopup();
        }

        return () => {
            timerId && clearTimeout(timerId);
        };
    }, [paymentStatusData, pathname, search, showPaymentCanceledPopup]);

    return (
        <>
            <Popup.Header title={messages.confirmPayment} />

            <Popup.Body spacing='32'>
                <Grid gap={24}>
                    <Grid gap={12} justifyContent='center'>
                        <Box spacing='16 52' color='midnight20' radius='xsmall'>
                            <Grid gap={40} gridTemplateColumns='auto auto' alignItems='center'>
                                <img width={160} height={160} src={qrCodeSrc} alt='mobile application qr-code' />

                                <Grid gap={12} justifyItems='center'>
                                    <Text uppercase font='label' fontSize='small' color='midnight70'>
                                        {messages.or}
                                    </Text>

                                    <Button
                                        as='a'
                                        color='blue80'
                                        size='medium'
                                        rel='noopener noreferrer'
                                        href={paymentData?.authorisationUrl}
                                        startIcon={<DeviceNotebookIcon color='white100' />}
                                    >
                                        {messages.loginOnDesktop}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>

                    <Box spacing='16' color='midnight20' radius='xsmall'>
                        <Grid gridTemplateColumns='148px auto' columnGap={16} rowGap={8}>
                            <Text font='label' fontSize='medium' color='midnight70'>
                                {messages.paymentTotal}
                            </Text>

                            <Text font='label' fontSize='medium' fontWeight='medium' color='midnight100'>
                                {intl.formatCurrency(paymentData?.paymentTotal, paymentData?.paymentCurrency ?? '')}
                            </Text>

                            {paymentData?.scheduledDate && (
                                <>
                                    <Text font='label' fontSize='medium' color='midnight70'>
                                        {messages.paymentDate}
                                    </Text>

                                    <Text font='label' fontSize='medium' color='midnight100'>
                                        {moment(paymentData?.scheduledDate).format('ll')}
                                    </Text>
                                </>
                            )}

                            <Text font='label' fontSize='medium' color='midnight70'>
                                {messages.payeeName}
                            </Text>

                            <Text font='label' fontSize='medium' color='midnight100'>
                                {paymentData?.payeeName}
                            </Text>

                            {(paymentDetails?.data.bankAccountDetails.accountNumber || isPaymentDetailsLoading) && (
                                <>
                                    <Text font='label' fontSize='medium' color='midnight70'>
                                        {messages.payeeAccountNumber}
                                    </Text>

                                    <Text font='label' fontSize='medium' color='midnight100'>
                                        {paymentDetails?.data.bankAccountDetails.accountNumber ?? (
                                            <Skeleton width={80} />
                                        )}
                                    </Text>
                                </>
                            )}

                            {(paymentDetails?.data.bankAccountDetails.sortCode || isPaymentDetailsLoading) && (
                                <>
                                    <Text font='label' fontSize='medium' color='midnight70'>
                                        {messages.payeeSortCode}
                                    </Text>

                                    <Text font='label' fontSize='medium' color='midnight100'>
                                        {paymentDetails?.data.bankAccountDetails.sortCode ?? <Skeleton width={80} />}
                                    </Text>
                                </>
                            )}
                        </Grid>
                    </Box>

                    <Stepper direction='vertical' activeStep={activeStep}>
                        <Stepper.Step label={messages.securelyLogin} />

                        <Stepper.Step
                            label={messages.confirmPayment}
                            icon={activeStep === 1 ? <Progress shape='circle' size='xsmall' /> : undefined}
                        />

                        <Stepper.Step
                            label={messages.returnTo}
                            icon={activeStep === 2 ? <Progress shape='circle' size='xsmall' /> : undefined}
                        />
                    </Stepper>

                    <Text font='body' fontSize='small' color='midnight70'>
                        {messages.weHavePartnered({ bankName: paymentData?.bankName })}
                    </Text>

                    <Text font='body' fontSize='small' color='midnight70'>
                        {messages.byUsingYouAgree({
                            termsAndConditions: (
                                <Link
                                    font='body'
                                    fontSize='small'
                                    external
                                    href='https://www.yapily.com/legal/end-user-terms'
                                >
                                    {messages.termsAndConditions}
                                </Link>
                            ),
                            privacyNotice: (
                                <Link
                                    font='body'
                                    fontSize='small'
                                    external
                                    href='https://www.yapily.com/legal/privacy-policy'
                                >
                                    {messages.privacyNotice}
                                </Link>
                            ),
                        })}
                    </Text>
                </Grid>
            </Popup.Body>
        </>
    );
});

PaymentPopupContent.displayName = 'PaymentPopupContent';
