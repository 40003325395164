import { selectors } from 'modules/common';
import { State } from 'modules/data';
import { FC, memo, useMemo } from 'react';
import bemFactory from 'react-bem-factory';
import { useSelector } from 'react-redux';

import { messages } from './DelegationHeader.messages';
import { Avatar, Delegate, Name, Root, Text } from './DelegationHeader.styles';
import { DelegationHeaderProps } from './DelegationHeader.types';

const qa = bemFactory.qa('reql-toolbar');

export const DelegationHeader: FC<DelegationHeaderProps> = memo((props) => {
    const { request } = props;

    const delegatedUser = useSelector((state: State) => {
        const me = selectors.profile.getProfileUser(state);

        if (request.flags.isDelegateApprover && request.activeStep) {
            const delegatedApprover = request.activeStep.participants.find(
                (participant) => participant.userId === me.id && participant.delegateFor
            );

            return delegatedApprover?.delegateFor
                ? selectors.company.getCompanyUserById(state, request.company, delegatedApprover.delegateFor)
                : null;
        }

        if (request.flags.isDelegateReviewer && request.reviewStep) {
            const delegatedReviewer = request.reviewStep.reviewers.find(
                (reviewer) => reviewer.userEmail === me.id && reviewer.delegateFor
            );

            return delegatedReviewer?.delegateFor
                ? selectors.company.getCompanyUserById(state, request.company, delegatedReviewer.delegateFor)
                : null;
        }

        return null;
    });

    const text = useMemo(() => {
        if (request.flags.isDelegateApprover) return messages.approverOnBehalfOfText;

        if (request.flags.isDelegateReviewer) return messages.reviewerOnBehalfOfText;

        return '';
    }, [request.flags.isDelegateApprover, request.flags.isDelegateReviewer]);

    if ((!request.flags.isDelegateApprover && !request.flags.isDelegateReviewer) || !delegatedUser) return null;

    return (
        <Root>
            <Text>{text}</Text>

            <Delegate user={delegatedUser}>
                <Avatar user={delegatedUser} size={30} />

                <Name data-qa={qa('on-behalf-header-delegate-name')}>{delegatedUser.displayName}</Name>
            </Delegate>
        </Root>
    );
});

DelegationHeader.displayName = 'DelegationHeader';
