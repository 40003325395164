const amaxPayXeroRequestContextPrefix = 'requests/companies/:companyId/requests/:requestId/amaxPay/xero';

export const requestsApiPaths = <const>{
    setCustomerDecisionInQuote: 'requests/setCustomerDecisionInQuote',
    getQuoteForCustomerDecision: 'requests/xero/getQuoteForCustomerDecision',
    setQuoteDecisionByCustomer: 'requests/xero/setQuoteDecisionByCustomer',
    getQuoteForCustomerDecisionPdf: 'requests/xero/getQuoteForCustomerDecisionPdf',
    resendQuoteForCustomerDecision: 'requests/resendQuoteForCustomerDecision',
    reverse: 'requests/reverse',
    changeWatchers: 'requests/changeWatchers',
    create: 'requests/create',
    publish: 'requests/publish',
    checkAirwallexBeneficiaries: 'requests/airwallex/checkBeneficiaries',
    edit: 'requests/edit',
    delete: 'requests/delete',
    getAuditReportDetails: 'requests/getAuditReportDetails',
    importManualJournal: 'requests/xero/importManualJournal',
    airwallexSchedulePayment: 'requests/airwallex/schedulePayment',
    airwallexCancelScheduledPayment: 'requests/airwallex/cancelScheduledPayment',
    retry: 'requests/respond/retry',
    amaxPayXeroRequestDetails: `${amaxPayXeroRequestContextPrefix}/details`,
    amaxPayXeroBankAccounts: `${amaxPayXeroRequestContextPrefix}/bankAccounts`,
    amaxPayXeroPay: `${amaxPayXeroRequestContextPrefix}/pay`,
    amaxPayXeroRefresh: `${amaxPayXeroRequestContextPrefix}/payments/refresh`,
    amaxPayBillCancel: `${amaxPayXeroRequestContextPrefix}/cancel`,
    amaxPayXeroRefreshByPaymentId: 'requests/companies/:companyId/amaxPay/xero/payments/:paymentId/refresh',
    amaxPayPaymentDetailsDiscrepancies: `${amaxPayXeroRequestContextPrefix}/paymentDetails/discrepancies`,
    billRequestPaymentDetails: `${amaxPayXeroRequestContextPrefix}/bills/:billRequestId/paymentDetails`,
    searchRequests: 'search/searchRequests',
    getAttachment: 'requests/getAttachment',
    netSuitePayees: 'integration/getNetSuitePayees',
};
