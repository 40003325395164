import { ScrollableArea } from '@approvalmax/ui';
import { FeaturePromotion } from 'pages/requestList/components/FeaturePromotion/FeaturePromotion';
import styled from 'styled-components';

import AddCommentSection from '../../card/AddCommentSection/AddCommentSection';
import XeroPendingStatusToolbarSection from '../XeroPendingStatusToolbarSection';

export const Root = styled.div`
    display: flex;
    flex-flow: column;
`;

export const PendingStatusSection = styled(XeroPendingStatusToolbarSection)`
    flex: none;
`;

export const Body = styled(ScrollableArea)`
    flex: 1;
    border-top: 1px solid #e4e4e4;

    @media only screen and (max-height: 600px) {
        overflow: unset;
    }
`;

export const Content = styled.div`
    padding-top: 20px;
`;

export const StyledAddCommentSection = styled(AddCommentSection)`
    flex: none;
`;

export const StyledFeaturePromotion = styled(FeaturePromotion)`
    margin: 20px 40px;
`;
