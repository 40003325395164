import { miscHelpers } from '@approvalmax/utils';
import { GetFido2CredentialOptions } from 'shared/data/v2';

export const getPublicKeyCreationOptions = (credentialOptions: GetFido2CredentialOptions['response']) => {
    const { relayingParty, timeoutInMilliseconds, attestationConveyance, ...restCredentialOptions } = credentialOptions;

    return {
        ...restCredentialOptions,
        attestation: attestationConveyance,
        authenticatorSelection: {
            authenticatorAttachment: credentialOptions.authenticatorSelection?.authenticatorAttachment ?? undefined,
            requireResidentKey: credentialOptions.authenticatorSelection?.requireResidentKey ?? undefined,
            residentKey: credentialOptions.authenticatorSelection?.requireResidentKey ? 'required' : 'preferred',
        },
        rp: relayingParty,
        challenge: miscHelpers.base64ToBytes(credentialOptions.challenge),
        user: {
            ...credentialOptions.user,
            id: miscHelpers.base64ToBytes(credentialOptions.user.id),
        },
        pubKeyCredParams: credentialOptions.pubKeyCredParams.map((param) => ({
            type: param.credentialType,
            alg: param.algorithm,
        })),
        excludeCredentials: credentialOptions.excludeCredentials?.map((credential) => ({
            ...credential,
            type: credential.type,
            id: miscHelpers.base64ToBytes(credential.id),
            transports: (credential.transports as AuthenticatorTransport[]) ?? undefined,
        })),
        extensions: {
            credProps: true,
        },
        timeout: timeoutInMilliseconds,
    };
};

export const createPublicKey = async (credentialOptions: GetFido2CredentialOptions['response']) => {
    const publicKeyOptions = getPublicKeyCreationOptions(credentialOptions);

    const credential = (await navigator.credentials.create({
        publicKey: publicKeyOptions as PublicKeyCredentialCreationOptions,
    })) as PublicKeyCredential | null;

    if (!credential) {
        throw new Error('Failed to create public key');
    }

    return credential;
};

export const getAttestationResponseTransfer = (
    credential: PublicKeyCredential,
    credentialOptions: GetFido2CredentialOptions['response']
) => {
    const response = credential.response as AuthenticatorAttestationResponse;

    return {
        id: credential.id,
        optionsId: credentialOptions.id,
        type: credential.type,
        rawId: miscHelpers.arrayBufferToBase64(credential.rawId),
        response: {
            attestationObject: miscHelpers.arrayBufferToBase64(response.attestationObject),
            clientDataJson: miscHelpers.arrayBufferToBase64(response.clientDataJSON),
        },
        extensions: {
            isAppIdExtension: false,
            isAuthenticatorSelectionEnabled: false,
        },
    };
};
