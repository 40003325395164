import { intl } from '@approvalmax/utils';
import { FC, memo } from 'react';
import styled from 'styled-components';

import greenHatch from '../../../../../resources/greenHatch.png';

const BarContainer = styled.div`
    width: 100%;
    height: 8px;
    display: flex;
    background-color: #e9eef0;
`;

const Bar = styled.div<{ width: number }>`
    width: ${(props) => props.width}%;
`;

const BlackBar = styled(Bar)`
    background-color: #000;
`;

const DarkGreenBar = styled(Bar)`
    background-color: #4c8a5c;
`;

const LightGreenBar = styled(Bar)`
    background-color: #b9d4c1;
`;

const GrayBar = styled(Bar)`
    background-color: #969494;
`;

const DashedGreenBar = styled(Bar)`
    background: url(${greenHatch});
`;

const OverflowedBar = styled.div`
    width: 100%;
    background-color: #ca5c41;
`;

interface ColorBarProps {
    blackBarValue?: number;
    darkGreenBarValue?: number;
    lightGreenBarValue?: number;
    grayBarValue?: number;
    dashedGreenBarValue?: number;
    totalValue?: number;
    isOverflowed?: boolean;
    currency: string;
}

const ColorBar: FC<ColorBarProps> = (props) => {
    const {
        blackBarValue = 0,
        darkGreenBarValue = 0,
        lightGreenBarValue = 0,
        grayBarValue = 0,
        dashedGreenBarValue = 0,
        totalValue = 0,
        isOverflowed = false,
        currency,
    } = props;

    const getPercentFromTotal = (value: number): number => {
        return 100 / (totalValue / value) || 0;
    };

    const percentOfBlack = getPercentFromTotal(blackBarValue);
    const percentOfDarkGreen = getPercentFromTotal(darkGreenBarValue);
    const percentOfLightGreen = getPercentFromTotal(lightGreenBarValue);
    const percentOfGray = getPercentFromTotal(grayBarValue);
    const percentOfDashedGreen = getPercentFromTotal(dashedGreenBarValue);

    return (
        <BarContainer
            title={intl.formatCurrency(
                totalValue -
                    blackBarValue -
                    darkGreenBarValue -
                    lightGreenBarValue -
                    grayBarValue -
                    dashedGreenBarValue,
                currency
            )}
        >
            {isOverflowed ? (
                <OverflowedBar
                    title={intl.formatCurrency(
                        blackBarValue + darkGreenBarValue + (lightGreenBarValue || dashedGreenBarValue),
                        currency
                    )}
                />
            ) : (
                <>
                    <BlackBar width={percentOfBlack} title={intl.formatCurrency(blackBarValue, currency)} />

                    <GrayBar width={percentOfGray} title={intl.formatCurrency(grayBarValue, currency)} />

                    <DarkGreenBar width={percentOfDarkGreen} title={intl.formatCurrency(darkGreenBarValue, currency)} />

                    <LightGreenBar
                        width={percentOfLightGreen}
                        title={intl.formatCurrency(lightGreenBarValue, currency)}
                    />

                    <DashedGreenBar
                        width={percentOfDashedGreen}
                        title={intl.formatCurrency(dashedGreenBarValue, currency)}
                    />
                </>
            )}
        </BarContainer>
    );
};

export default memo(ColorBar);
