import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { authApiPaths } from '../paths';
import { GetCannyIoToken } from './useGetCannyIoToken.types';

export const useGetCannyIoToken = (
    params?: RequestParams<GetCannyIoToken>,
    queryOptions?: RequestQueryOptions<GetCannyIoToken>
) => {
    return useGet(authApiPaths.cannyIoToken, {
        params,
        queryOptions,
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};
