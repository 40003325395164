import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('CompleteAirwallexPaymentPopup.BalanceStep', {
    description: `
        Check available balances and confirm the batch payment to go into Airwallex processing.
        Please note: you can’t cancel payments after confirmation.
        {br}{br}
        You will receive a notification once the batch is fully processed (or in case anything goes wrong).
        You can also track the payment status on the batch form.
        {br}{br}
        *To be paid amount is calculated approximately. It does not include any fees.
        If the source currency is different from the bill currency, we will  use rates from {link} as
        of the batch creation date. The exact amount will be calculated by Airwallex and reflected on the batch form.`,
    confirmButton: 'Confirm payment',
    currency: 'Currency',
    available: 'Available',
    toBePaid: 'To be paid*',
    noBalances: 'No balances',
    generalError: 'Unable to retrieve account balances. Try again later or contact support.',
});
