import { mods } from '@approvalmax/theme';
import { font } from '@approvalmax/ui';
import { StyledMods } from '@styled-kit/mods';
import styled, { css } from 'styled-components';

import { SectionContainerNoPadding } from './SectionContainer.types';

export const Wrap = styled.section<SectionContainerNoPadding>`
    display: flex;
    flex-flow: column;

    ${(props) =>
        props.noPadding
            ? css`
                  padding: 0;
              `
            : css`
                  padding: 0 60px;
              `}
`;

export const Header = styled.header<StyledMods<{ withPadding: boolean }>>`
    ${font(14, '#000', 'semibold')}
    flex: none;
    height: 40px;
    line-height: 40px;
    text-transform: uppercase;

    ${mods('withPadding', true)`
        padding: 0 60px;
    `}
`;

export const Content = styled.div`
    overflow: hidden;
`;
