import { MutateDataParams } from '@approvalmax/data';
import { ErrorCode } from '@approvalmax/types';
import { toast } from '@approvalmax/ui/src/components';
import { notificationService } from 'services/notification';
import { useMutateLegacy } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { messages } from './useSetCustomerDecisionInQuote.messages';
import { UseSetCustomerDecisionInQuoteRequestParams } from './useSetCustomerDecisionInQuote.types';

export const useSetCustomerDecisionInQuote = () => {
    return useMutateLegacy<MutateDataParams<UseSetCustomerDecisionInQuoteRequestParams>, any, any>(
        requestsApiPaths.setCustomerDecisionInQuote,
        {
            mutationOptions: {
                onSuccess: (data_, requestParams) => {
                    if (requestParams.body?.isAccepted) {
                        notificationService.showInfoToast(messages.acceptedRequest);
                    } else {
                        notificationService.showInfoToast(messages.declinedRequest);
                    }
                },
                onError: (error) => {
                    if (error?.code === ErrorCode.E5043_XERO_QUOTE_UNAVAILABLE_FOR_CUSTOMER_DECISION) {
                        toast.error(messages.unavailableQuoteError);
                    } else {
                        toast.error(error.message);
                    }
                },
            },
        }
    );
};
