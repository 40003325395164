import { MutateDataParams, MutationOptions } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { twoFaApiPaths } from '../paths';
import { UseSetTFAEnablingEmailRequest, UseSetTFAEnablingEmailResponse } from './useSetTFAEnablingEmail.types';

export const useSetTFAEnablingEmail = (
    mutationOptions?: MutationOptions<UseSetTFAEnablingEmailResponse, MutateDataParams<UseSetTFAEnablingEmailRequest>>
) => {
    return useMutateLegacy<MutateDataParams<UseSetTFAEnablingEmailRequest>, UseSetTFAEnablingEmailResponse>(
        twoFaApiPaths.enablingEmailSet,
        { mutationOptions }
    );
};
