import { openSplitViewState } from '@approvalmax/ui/src/components';
import { intl } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { backend, domain } from 'modules/data';
import { font } from 'modules/styles';
import { LineItemsTable } from 'pages/requestList/components/lineItemsSection/LineItemsTable';
import OpenInSourceLink from 'pages/requestList/components/OpenInSourceLink/OpenInSourceLink';
import { FC, memo, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { splitViewGoToBarDefaultSettings, splitViewGoToBarSettingsState } from 'shared/components';
import { activeAttachmentIdState, ContentSplitViewMode, contentSplitViewState } from 'shared/states';
import styled from 'styled-components';

import { getColumnDefinitions } from './tableConfig';

const i18nPrefix =
    'requestList/containers/xero/BillBatchPaymentRequestCard/components/BillsInformationSection/BillsInformationSection';

const Root = styled.div``;

const SectionHeaderText = styled.div`
    ${font(14, '#000000', 'semibold')}
    margin-bottom: 12px;
`;

const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
`;

const LinkContainer = styled.div``;

const TotalContainer = styled.div``;

const TotalText = styled.span`
    margin-right: 50px;
    ${font(11, '#565656', 'semibold')}
`;

const TotalAmount = styled.span`
    ${font(13, '#000', 'semibold')}
`;

interface BillsInformationSectionProps {
    billBatchPayment: domain.XeroBillBatchPaymentDetails;
    currency: string;
    amount: number;
    countryCode: backend.OrganisationVersion;
    request: selectors.types.ExpandedRequest;
}

const BillsInformationSection: FC<BillsInformationSectionProps> = (props) => {
    const { billBatchPayment, currency, amount, countryCode, request } = props;

    const setOpenSplitView = useSetRecoilState(openSplitViewState);
    const setContentSplitView = useSetRecoilState(contentSplitViewState);
    const activeAttachmentId = useRecoilValue(activeAttachmentIdState);
    const setSplitViewGoToBarSettings = useSetRecoilState(splitViewGoToBarSettingsState);

    const onSelectAttachment = useCallback(
        (attachmentId: string, xeroBatchPaymentInvoiceId: string, xeroBatchPaymentInvoiceName: string) => {
            setOpenSplitView(true);
            setContentSplitView({
                mode: ContentSplitViewMode.AttachmentsToBillLinkedToBatchPayment,
                attachmentId,
                billId: xeroBatchPaymentInvoiceId,
                billFriendlyName: xeroBatchPaymentInvoiceName,
            });
            setSplitViewGoToBarSettings({
                show: true,
                targetContent: {
                    mode: ContentSplitViewMode.BillLinkedToBatchPayment,
                    billId: xeroBatchPaymentInvoiceId,
                },
                targetContentName: xeroBatchPaymentInvoiceName,
            });
        },
        [setContentSplitView, setSplitViewGoToBarSettings, setOpenSplitView]
    );

    const handleSelectBill = useCallback(
        (xeroBatchPaymentInvoiceId: string) => {
            setSplitViewGoToBarSettings(splitViewGoToBarDefaultSettings);
            setContentSplitView({
                mode: ContentSplitViewMode.BillLinkedToBatchPayment,
                billId: xeroBatchPaymentInvoiceId,
            });
            setOpenSplitView(true);
        },
        [setContentSplitView, setSplitViewGoToBarSettings, setOpenSplitView]
    );

    const columnDefinitions = useMemo(
        () =>
            getColumnDefinitions({
                currency,
                countryCode,
                activeAttachmentId,
                onSelectAttachment,
                onSelectBill: handleSelectBill,
            }),
        [activeAttachmentId, countryCode, currency, handleSelectBill, onSelectAttachment]
    );

    return (
        <Root>
            <SectionHeaderText>
                <FormattedMessage id={`${i18nPrefix}.headerText`} defaultMessage='XERO BATCH PAYMENT BILLS' />
            </SectionHeaderText>

            <LineItemsTable columnDefinitions={columnDefinitions} lineItems={billBatchPayment.items} />

            <Footer>
                <LinkContainer>
                    <OpenInSourceLink
                        url={billBatchPayment.url}
                        editUrl={billBatchPayment.editUrl}
                        statusV2={request.statusV2}
                        integrationType={request.integrationType}
                        integrationCode={request.integrationCode}
                    />
                </LinkContainer>

                <TotalContainer>
                    <TotalText>
                        <FormattedMessage id={`${i18nPrefix}.total`} defaultMessage='TOTAL:' />
                    </TotalText>

                    <TotalAmount>{intl.formatCurrency(amount, currency)}</TotalAmount>
                </TotalContainer>
            </Footer>
        </Root>
    );
};

export default memo(BillsInformationSection);
