import { domain } from 'modules/data';
import { FC, memo } from 'react';

import BillView from './components/BillView/BillView';
import PurchaseOrderView from './components/PurchaseOrderView/PurchaseOrderView';
import { getShowManualMatching } from './selectors';
import { XeroMatchingV2SectionProps } from './types';

const XeroMatchingV2Section: FC<XeroMatchingV2SectionProps> = memo((props) => {
    const { request, readonly, preloadedMatchingData } = props;
    const isBill = request.integrationCode === domain.IntegrationCode.XeroBill;
    const showManualMatching = getShowManualMatching(request);

    if (!showManualMatching) {
        return null;
    }

    return isBill ? (
        <BillView request={request} readonly={readonly} preloadedMatchingData={preloadedMatchingData} />
    ) : (
        <PurchaseOrderView request={request} />
    );
});

export default XeroMatchingV2Section;
