import './forceDecisionButton.scss';

import { combineCommandsAnd, Command } from '@approvalmax/types';
import { AutoDropdown, Button, TransparentButton } from '@approvalmax/ui';
import { eventHelpers, intl } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { BubbleCloseIcon, ForceIcon } from 'modules/sprites';
import React from 'react';
import bemFactory from 'react-bem-factory';
import { defineMessages, FormattedMessage } from 'react-intl';

const i18nPrefix = 'requestList/components/ForceDecisionButton.tsx';
const messages = defineMessages({
    forceDecisionMenuButtonTitle: {
        id: `${i18nPrefix}.forceDecisionMenuButtonTitle`,
        defaultMessage: 'Force approval / rejection for this request',
    },
    forceApprovalButtonTitle: {
        id: `${i18nPrefix}.forceApprovalButtonTitle`,
        defaultMessage: 'Force approval of this request as company administrator',
    },
    forceRejectionButtonTitle: {
        id: `${i18nPrefix}.forceRejectionButtonTitle`,
        defaultMessage: 'Force rejection of this request as company administrator',
    },
});

interface Props {
    className?: string;
    disabled?: boolean;
    small: boolean;
    hideTip: boolean;
    forceApproveCommand: Command;
    forceRejectCommand: Command;
    onForceDecision: (decision: domain.RequestStepParticipantDecision) => void;
    onHideTip: () => void;
}

class ForceDecisionButton extends React.Component<Props> {
    public render() {
        const {
            className,
            small,
            forceApproveCommand,
            forceRejectCommand,
            onForceDecision,
            disabled: disabled$,
            hideTip,
            onHideTip,
        } = this.props;
        const bem = bemFactory.block('reql-force-decision-button');
        const qa = bemFactory.qa('reql-force-decision-button');

        return (
            <AutoDropdown
                panelFlow='to-left'
                className={bem.add(className)()}
                button={
                    small ? (
                        <TransparentButton
                            qa={qa('force-decision-menu-button')}
                            title={intl.formatMessage(messages.forceDecisionMenuButtonTitle)}
                            disabled={disabled$}
                        >
                            {({ elementProps }) => (
                                <button className={bem('small-button')} {...elementProps}>
                                    <ForceIcon width={18} height={24} />
                                </button>
                            )}
                        </TransparentButton>
                    ) : (
                        <Button
                            qa={qa('force-decision-menu-button')}
                            title={intl.formatMessage(messages.forceDecisionMenuButtonTitle)}
                            disabled={disabled$}
                            command={combineCommandsAnd(forceApproveCommand, forceRejectCommand)}
                            className={bem('large-button')}
                            padding='narrow20'
                            colorTheme='grey'
                        >
                            <ForceIcon width={14} height={18} className={bem('large-button-icon')} />

                            <FormattedMessage
                                id={`${i18nPrefix}.forceDecisionMenuButtonText`}
                                defaultMessage='Force Decision'
                            />
                        </Button>
                    )
                }
            >
                {() => (
                    <div className={bem('panel')} onMouseDown={eventHelpers.preventDefaultCallback}>
                        {!hideTip && (
                            <div className={bem('tip-panel')}>
                                <div
                                    onMouseDown={() => {
                                        onHideTip();
                                    }}
                                    className={bem('close-button')}
                                    data-qa={qa('close-button')}
                                >
                                    <BubbleCloseIcon width={9} height={9} />
                                </div>

                                <div className={bem('tip-panel-title')}>
                                    <FormattedMessage
                                        id={`${i18nPrefix}.title`}
                                        defaultMessage='Forcing approval decision'
                                    />
                                </div>

                                <div className={bem('tip-panel-description')}>
                                    <FormattedMessage
                                        id={`${i18nPrefix}.description`}
                                        defaultMessage={
                                            'As an Administrator you can force approval or rejection of this request. ' +
                                            '{br}{br}This will override decisions of other approvers.'
                                        }
                                        values={{
                                            br: <br />,
                                        }}
                                    />
                                </div>
                            </div>
                        )}

                        <div className={bem('menu-list')}>
                            <TransparentButton
                                qa={qa('force-decision-approve-menu-item')}
                                execute={() => onForceDecision(domain.RequestStepParticipantDecision.Approve)}
                                command={forceApproveCommand}
                                title={intl.formatMessage(messages.forceApprovalButtonTitle)}
                            >
                                {({ elementProps }) => (
                                    <button
                                        {...elementProps}
                                        className={bem('menu-item', {
                                            regular: hideTip,
                                        })}
                                    >
                                        <FormattedMessage
                                            id={`${i18nPrefix}.forceApprovalButtonText`}
                                            defaultMessage='Force approval'
                                        />
                                    </button>
                                )}
                            </TransparentButton>

                            <TransparentButton
                                qa={qa('force-decision-reject-menu-item')}
                                execute={() => onForceDecision(domain.RequestStepParticipantDecision.Reject)}
                                command={forceRejectCommand}
                                title={intl.formatMessage(messages.forceRejectionButtonTitle)}
                            >
                                {({ elementProps }) => (
                                    <button
                                        {...elementProps}
                                        className={bem('menu-item', {
                                            regular: hideTip,
                                        })}
                                    >
                                        <FormattedMessage
                                            id={`${i18nPrefix}.forceRejectionButtonText`}
                                            defaultMessage='Force rejection'
                                        />
                                    </button>
                                )}
                            </TransparentButton>
                        </div>
                    </div>
                )}
            </AutoDropdown>
        );
    }
}

export default ForceDecisionButton;
