import { domain } from 'modules/data';
import { ExchangeRateSource } from 'modules/data/domain';
import moment from 'moment';
import { FC, memo } from 'react';
import { getExchangeRateSourceName } from 'shared/helpers/exchangeRate';

import { messages } from './ExchangeRate.messages';
import { ExchangeRateProps } from './ExchangeRate.types';

const ExchangeRate: FC<ExchangeRateProps> = memo((props) => {
    const { request, className } = props;

    const isManual = request.isManualExchangeRate || request.exchangeRateSource === ExchangeRateSource.Manual;

    let exchangeRate =
        isManual && typeof request.currencyExchangeRate === 'number'
            ? request.currencyExchangeRate
            : request.exchangeRate;
    let currency = request.currency;
    let baseCurrency = request.company.defaultCurrency;

    if (request.integrationType === domain.IntegrationType.NetSuite && 'exchangeRate' in request.details) {
        const details = request.details;

        const requestCurrency = details.currency?.text || request.currency;

        exchangeRate = details.exchangeRate;

        if (exchangeRate && exchangeRate !== 1) {
            baseCurrency = requestCurrency;
            currency = request.company.defaultCurrency;
        } else {
            return null;
        }
    } else if (request.integrationType === domain.IntegrationType.Dear) {
        const details = request.details;

        exchangeRate = details.currencyRate;

        if (exchangeRate && exchangeRate !== 1) {
            baseCurrency = request.currency;
            currency = request.company.defaultCurrency;
        } else {
            return null;
        }
    }

    if (!exchangeRate || currency === baseCurrency) {
        return null;
    }

    const sourceName = getExchangeRateSourceName(request.exchangeRateSource);

    const sourceInfo =
        sourceName && request.exchangeRateDate
            ? `${moment.utc(request.exchangeRateDate).format('ll')}, ${sourceName}`
            : sourceName;

    const showXeroSyncDisclaimer =
        request.integrationType === domain.IntegrationType.Xero &&
        request.exchangeRateSource &&
        ![domain.ExchangeRateSource.Xero, domain.ExchangeRateSource.Manual, domain.ExchangeRateSource.None].includes(
            request.exchangeRateSource
        );

    return (
        <div className={className}>
            {messages.exchangeRateText({
                currency,
                baseCurrency,
                rate: exchangeRate,
            })}

            {sourceInfo && <span>{` (${sourceInfo})`}</span>}

            {showXeroSyncDisclaimer && '. ' + messages.xeroSyncDisclaimer}
        </div>
    );
});

export default ExchangeRate;
