import { arrayHelpers, errorHelpers, mathService } from '@approvalmax/utils';
import { produce } from 'immer';
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';
import zip from 'lodash/zip';
import { domain } from 'modules/data';
import {
    addArrayItem,
    immutable,
    ImmutableObject,
    insertArrayItem,
    mergeIn,
    removeArrayItem,
    setIn,
    updateArrayItem,
} from 'modules/immutable';
import moment from 'moment';

import {
    Action,
    ADD_QBOOKS_ACCOUNT_LINE_ITEM,
    ADD_QBOOKS_LINE_ITEM,
    CHANGE_QBOOKS_ACCOUNT_LINE_ITEM_AMOUNT,
    CHANGE_QBOOKS_ACCOUNT_LINE_ITEM_CHECKED,
    CHANGE_QBOOKS_ACCOUNT_LINE_ITEM_DESCRIPTION,
    CHANGE_QBOOKS_ACCOUNT_LINE_ITEM_MATCHING,
    CHANGE_QBOOKS_ACCOUNT_LINE_ITEMS_ACCOUNT,
    CHANGE_QBOOKS_ACCOUNT_LINE_ITEMS_ALL_CHECKED,
    CHANGE_QBOOKS_ACCOUNT_LINE_ITEMS_CLASS,
    CHANGE_QBOOKS_ACCOUNT_LINE_ITEMS_CUSTOMER,
    CHANGE_QBOOKS_ACCOUNT_LINE_ITEMS_TAX_CODE,
    CHANGE_QBOOKS_APPLY_TAX_AFTER_DISCOUNT,
    CHANGE_QBOOKS_BILLING_ADDRESS,
    CHANGE_QBOOKS_CUSTOM_FIELD,
    CHANGE_QBOOKS_CUSTOMER,
    CHANGE_QBOOKS_CUSTOMER_MEMO,
    CHANGE_QBOOKS_DATE,
    CHANGE_QBOOKS_DEPARTMENT,
    CHANGE_QBOOKS_DISCOUNT,
    CHANGE_QBOOKS_DISCOUNT_TYPE,
    CHANGE_QBOOKS_DUE_DATE,
    CHANGE_QBOOKS_EXPENSE_NUMBER,
    CHANGE_QBOOKS_LINE_AMOUNT_TYPE,
    CHANGE_QBOOKS_LINE_ITEM_CHECKED,
    CHANGE_QBOOKS_LINE_ITEM_DESCRIPTION,
    CHANGE_QBOOKS_LINE_ITEM_MATCHING,
    CHANGE_QBOOKS_LINE_ITEM_QTY,
    CHANGE_QBOOKS_LINE_ITEM_SERVICE_DATE,
    CHANGE_QBOOKS_LINE_ITEM_UNIT_PRICE,
    CHANGE_QBOOKS_LINE_ITEMS_ALL_CHECKED,
    CHANGE_QBOOKS_LINE_ITEMS_CLASS,
    CHANGE_QBOOKS_LINE_ITEMS_CUSTOMER,
    CHANGE_QBOOKS_LINE_ITEMS_ITEM,
    CHANGE_QBOOKS_LINE_ITEMS_TAX_CODE,
    CHANGE_QBOOKS_MAILING_ADDRESS,
    CHANGE_QBOOKS_MEMO,
    CHANGE_QBOOKS_NEW_CREATED_CUSTOMER,
    CHANGE_QBOOKS_NEW_CREATED_VENDOR,
    CHANGE_QBOOKS_NUMBER,
    CHANGE_QBOOKS_PAYEE,
    CHANGE_QBOOKS_PAYEE_TYPE,
    CHANGE_QBOOKS_PAYMENT_ACCOUNT,
    CHANGE_QBOOKS_PAYMENT_METHOD,
    CHANGE_QBOOKS_PAYMENT_TYPE,
    CHANGE_QBOOKS_REQUEST_CURRENCY,
    CHANGE_QBOOKS_SHIPPING_ADDRESS,
    CHANGE_QBOOKS_SHIPPING_CUSTOMER,
    CHANGE_QBOOKS_SHIPPING_DATE,
    CHANGE_QBOOKS_SHIPPING_FROM_ADDRESS,
    CHANGE_QBOOKS_SHIPPING_METHOD,
    CHANGE_QBOOKS_SHIPPING_PRICE,
    CHANGE_QBOOKS_SHIPPING_TAX_CODE,
    CHANGE_QBOOKS_TAX_CODE,
    CHANGE_QBOOKS_TERMS,
    CHANGE_QBOOKS_TRACKING_NUMBER,
    CHANGE_QBOOKS_VENDOR,
    CHANGE_QBOOKS_VENDOR_MESSAGE,
    CLONE_QBOOKS_ACCOUNT_LINE_ITEMS,
    CLONE_QBOOKS_ACCOUNT_LINE_ITEMS_ALL,
    CLONE_QBOOKS_LINE_ITEMS,
    CLONE_QBOOKS_LINE_ITEMS_ALL,
    QBOOKS_EMAIL_TO_CUSTOMER_CHANGE_DATA,
    QBOOKS_EMAIL_TO_CUSTOMER_INIT,
    QBOOKS_EMAIL_TO_CUSTOMER_TOGGLE,
    QBOOKS_EMAIL_TO_VENDOR_CHANGE_DATA,
    QBOOKS_EMAIL_TO_VENDOR_INIT,
    QBOOKS_EMAIL_TO_VENDOR_TOGGLE,
    REMOVE_QBOOKS_ACCOUNT_LINE_ITEMS,
    REMOVE_QBOOKS_ACCOUNT_LINE_ITEMS_ALL,
    REMOVE_QBOOKS_LINE_ITEMS,
    REMOVE_QBOOKS_LINE_ITEMS_ALL,
    REORDER_QBOOKS_ACCOUNT_LINE_ITEM,
    REORDER_QBOOKS_LINE_ITEMS,
    TOGGLE_QBOOKS_ACCOUNT_LINE_ITEM_IS_BILLABLE,
    TOGGLE_QBOOKS_ACCOUNT_LINE_ITEM_IS_TAXABLE,
    TOGGLE_QBOOKS_LINE_ITEM_IS_BILLABLE,
    TOGGLE_QBOOKS_LINE_ITEM_IS_TAXABLE,
    UPDATE_QBOOKS_FORM_WITH_CONTEXT,
    UPDATE_QBOOKS_LINE_ITEMS_WITH_CURRENCY_EXCHANGE_RATE,
} from '../../actions';
import { getComputedPrice, isEmptyQBooksAccountLineItem, isEmptyQBooksLineItem } from '../../selectors/qbooks';
import { isEmailToSupplierEmpty } from '../../utils/supplierEmailUtils';

export type RequestType = ImmutableObject<domain.Request>;

const getNewDate = (term: domain.QBooksTerm, dueDate: string) => {
    let newDueDate;

    const dueDaysTerm = term.dueTerms?.find(({ type }) => type === 'DueDays');
    const dueNextMonthDaysTerm = term.dueTerms?.find(({ type }) => type === 'DueNextMonthDays');
    const dayOfMonthDueTerm = term.dueTerms?.find(({ type }) => type === 'DayOfMonthDue');

    if (dueDaysTerm) {
        newDueDate = moment(dueDate).add(dueDaysTerm.value, 'd').toISOString();
    } else if (dayOfMonthDueTerm?.value) {
        const date = moment.utc(dueDate);

        if (dueNextMonthDaysTerm) {
            date.add(dueNextMonthDaysTerm.value, 'd');
        }

        if (date.date() > dayOfMonthDueTerm.value) {
            // next month
            date.add(1, 'month');
        }

        const endOfMonthDate = moment(date).endOf('month').date();

        date.date(Math.min(endOfMonthDate, dayOfMonthDueTerm.value));
        newDueDate = date.toISOString();
    }

    return newDueDate || '';
};

function updateLineItem(
    state: RequestType,
    lineItemId: string,
    mutator: (
        li: domain.QBooksLineItem,
        details: domain.QBooksPurchaseOrderDetails | domain.QBooksSalesInvoiceDetails
    ) => domain.QBooksLineItem
) {
    const details = state.details as domain.QBooksPurchaseOrderDetails;

    let lineItem = details.lineItems.find((li) => li.id === lineItemId);

    if (!lineItem) {
        throw errorHelpers.invalidOperationError(`Failed to find line item with id ${lineItemId}`);
    }

    return setIn(
        state,
        ['details', 'lineItems'],
        updateArrayItem(
            details.lineItems,
            (li) => li.id === lineItemId,
            mutator(
                {
                    ...lineItem,
                },
                details
            )
        )
    );
}

function updateAccountLineItem(
    state: RequestType,
    accountLineItemId: string,
    mutator: (
        li: domain.QBooksAccountLineItem,
        details: domain.QBooksPurchaseOrderDetails
    ) => domain.QBooksAccountLineItem
) {
    const details = state.details as domain.QBooksPurchaseOrderDetails;

    let accountLineItem = details.accountLineItems.find((li) => li.id === accountLineItemId);

    if (!accountLineItem) {
        throw errorHelpers.invalidOperationError(`Failed to find account line item with id ${accountLineItemId}`);
    }

    return setIn(
        state,
        ['details', 'accountLineItems'],
        updateArrayItem(
            details.accountLineItems,
            (li) => li.id === accountLineItemId,
            mutator(
                {
                    ...accountLineItem,
                },
                details
            )
        )
    );
}

function getSupplierToEmails(supplier: domain.QBooksContact | null) {
    return (
        supplier?.contactPersons
            ?.filter((person) => person.emailAddress && person.includeInEmails)
            .map((person) => person.emailAddress) || []
    );
}

function getEmailDetails(
    supplier: domain.QBooksContact | null,
    context: domain.QBooksContext,
    emailToSupplier?: domain.EmailToSupplier | null
) {
    const contactEmails = getSupplierToEmails(supplier);
    const { supplierEmailSettings } = context;

    const to: string[] = contactEmails || [];
    const cc: string[] = uniq<string>(supplierEmailSettings?.cc || []);
    const subject = emailToSupplier?.subject || supplierEmailSettings?.subject || '';
    const body = emailToSupplier?.body || supplierEmailSettings?.body || '';

    return { to, subject, body, cc };
}

function updateLineItemsWithExchangeRate(
    details:
        | domain.QBooksBillDetails
        | domain.QBooksExpenseDetails
        | domain.QBooksPurchaseOrderDetails
        | domain.QBooksSalesInvoiceDetails,
    exchangeRate: number | null
) {
    return details.lineItems.map((lineItem) => {
        const newLineItem = { ...lineItem };

        const shouldUpdatePrice = !newLineItem.isUnitPriceChanged;

        if (shouldUpdatePrice) {
            if (newLineItem.originalUnitPrice) {
                newLineItem.unitPrice = exchangeRate
                    ? mathService.round(newLineItem.originalUnitPrice * exchangeRate, 4)
                    : newLineItem.originalUnitPrice;
            }

            if (newLineItem.originalUnitPriceGross) {
                newLineItem.unitPriceGross = exchangeRate
                    ? mathService.round(newLineItem.originalUnitPriceGross * exchangeRate, 4)
                    : newLineItem.originalUnitPriceGross;
            }
        }

        return newLineItem;
    });
}

export default function (state: RequestType, action: Action): RequestType {
    switch (action.type) {
        case CHANGE_QBOOKS_VENDOR_MESSAGE:
            return setIn(state, ['details', 'vendorMessage'], action.payload.newVendorMessage);

        case CHANGE_QBOOKS_MEMO:
            return setIn(state, ['details', 'memo'], action.payload.newMemo);

        case CHANGE_QBOOKS_CUSTOMER_MEMO:
            return setIn(state, ['details', 'customerMemo'], action.payload.newCustomerMemo);

        case CHANGE_QBOOKS_VENDOR: {
            const { newVendor: supplier, details, company, context } = action.payload;

            const supplierPrev = details.vendor ? { ...details.vendor } : null;
            const supplierEmailDetails = getEmailDetails(supplier, context);
            const detailsNext: domain.QBooksPurchaseOrderDetails | domain.QBooksBillDetails = {
                ...details,
                vendor: supplier,
            };
            const isBill = detailsNext.integrationCode === domain.IntegrationCode.QBooksBill;
            const requestNext = {
                ...state,
                details: detailsNext,
            } as domain.QBooksBillRequest | domain.QBooksExpenseRequest | domain.QBooksPoRequest;

            const isEmailToVendorDisabled =
                context.supplierEmailSettings?.state === domain.TemplateSettingsEmailToSupplierState.Disabled;

            if (supplier) {
                const currentCurrency = requestNext.currency;

                if (supplier.currency) {
                    const newCurrency = supplier.currency.id;

                    requestNext.currency = newCurrency;
                    requestNext.exchangeRate = supplier.currency.rateToCompanyCurrency;

                    requestNext.currencyExchangeRate =
                        currentCurrency !== newCurrency ? null : requestNext.currencyExchangeRate;
                } else {
                    requestNext.exchangeRate = 1;
                    requestNext.currency = company.defaultCurrency;
                    requestNext.currencyExchangeRate = null;
                }

                if (supplier.addresses && supplier.addresses[0]) {
                    detailsNext.mailingAddress = supplier.addresses[0].text;
                }

                if (isBill && supplier.termId) {
                    const term = context.terms.find(({ id }) => id === supplier.termId);

                    if (term) {
                        detailsNext.term = term;
                        detailsNext.dueDate = getNewDate(term, detailsNext.date);
                    }
                }

                if (!isBill && detailsNext.emailToSupplier && !isEmailToVendorDisabled) {
                    const emailToSupplier = { ...detailsNext.emailToSupplier };

                    const toExcluded = getSupplierToEmails(supplierPrev);
                    const toEmails = [...emailToSupplier.to, ...supplierEmailDetails.to].filter(
                        (email) => !toExcluded.includes(email)
                    );

                    const ccEmails = [...emailToSupplier.cc];

                    detailsNext.emailToSupplier = {
                        ...emailToSupplier,
                        ...supplierEmailDetails,
                        to: uniq<string>(toEmails),
                        cc: uniq<string>(ccEmails),
                    };
                }
            } else {
                detailsNext.mailingAddress = '';
                requestNext.currency = company.defaultCurrency;
                requestNext.exchangeRate = null;

                if (!isBill && detailsNext.emailToSupplier && !isEmailToVendorDisabled) {
                    detailsNext.emailToSupplier = { ...detailsNext.emailToSupplier, to: [] };
                }
            }

            detailsNext.lineItems = updateLineItemsWithExchangeRate(
                detailsNext,
                requestNext.currencyExchangeRate || requestNext.exchangeRate
            );

            return immutable(requestNext);
        }

        case CHANGE_QBOOKS_CUSTOMER: {
            const { newCustomer: customer, details, company, context } = action.payload;

            const contactPrev = details.customer ? { ...details.customer } : null;

            const contactEmailDetails = getEmailDetails(customer, context);

            const detailsNext: domain.QBooksSalesInvoiceDetails = { ...details, customer };
            const requestNext = {
                ...state,
                details: detailsNext,
            } as domain.QBooksInvoiceRequest;

            const isEmailToCustomerDisabled =
                context.supplierEmailSettings?.state === domain.TemplateSettingsEmailToSupplierState.Disabled;

            if (customer) {
                const currentCurrency = requestNext.currency;

                if (customer.currency) {
                    const newCurrency = customer.currency.id;

                    requestNext.currency = newCurrency;
                    requestNext.exchangeRate = customer.currency.rateToCompanyCurrency;

                    requestNext.currencyExchangeRate =
                        currentCurrency !== newCurrency ? null : requestNext.currencyExchangeRate;
                } else {
                    requestNext.exchangeRate = 1;
                    requestNext.currency = company.defaultCurrency;
                    requestNext.currencyExchangeRate = null;
                }

                if (customer.addresses && customer.addresses[0]) {
                    const billingAddress = customer.addresses.find(
                        (address) => address.type === domain.QBooksAddressType.Postal
                    );

                    const shippingAddress = customer.addresses.find(
                        (address) => address.type === domain.QBooksAddressType.Physical
                    );

                    detailsNext.billingAddress = billingAddress?.text ?? '';
                    detailsNext.shipping = {
                        ...details.shipping,
                        address: shippingAddress?.text ?? '',
                    };
                }

                if (customer.termId) {
                    const term = context.terms.find(({ id }) => id === customer.termId);

                    if (term) {
                        detailsNext.term = term;
                        detailsNext.dueDate = getNewDate(term, detailsNext.date);
                    }
                }

                if (detailsNext.emailToContact && !isEmailToCustomerDisabled) {
                    const emailToContact = { ...detailsNext.emailToContact };

                    const toExcluded = getSupplierToEmails(contactPrev);
                    const toEmails = [...emailToContact.to, ...contactEmailDetails.to].filter(
                        (email) => !toExcluded.includes(email)
                    );

                    const ccEmails = [...emailToContact.cc];

                    detailsNext.emailToContact = {
                        ...emailToContact,
                        ...contactEmailDetails,
                        to: uniq<string>(toEmails),
                        cc: uniq<string>(ccEmails),
                    };
                }
            } else {
                detailsNext.billingAddress = '';
                detailsNext.shipping = {
                    ...details.shipping,
                    address: '',
                };
                requestNext.currency = company.defaultCurrency;
                requestNext.exchangeRate = null;

                if (detailsNext.emailToContact && !isEmailToCustomerDisabled) {
                    detailsNext.emailToContact = { ...detailsNext.emailToContact, to: [] };
                }
            }

            detailsNext.lineItems = updateLineItemsWithExchangeRate(
                detailsNext,
                requestNext.currencyExchangeRate || requestNext.exchangeRate
            );

            return immutable(requestNext);
        }

        case CHANGE_QBOOKS_PAYEE: {
            const payee = action.payload.payee;
            const details = { ...state.details, payee };
            const request = { ...state, details } as domain.Request;

            return immutable(request);
        }

        case CHANGE_QBOOKS_NEW_CREATED_VENDOR: {
            const newCreatedVendor = action.payload.newCreatedVendor;
            const details = { ...state.details, newCreatedVendor };
            const request = { ...state, details } as domain.Request;

            return immutable(request);
        }

        case CHANGE_QBOOKS_NEW_CREATED_CUSTOMER: {
            const { newCreatedCustomer } = action.payload;
            const details = { ...state.details, newCreatedCustomer };
            const request = { ...state, details } as domain.Request;

            return immutable(request);
        }

        case CHANGE_QBOOKS_PAYEE_TYPE: {
            const payeeType = action.payload.payeeType;
            const details = { ...state.details, payeeType };
            const request = { ...state, details } as domain.Request;

            return immutable(request);
        }

        case CHANGE_QBOOKS_PAYMENT_ACCOUNT: {
            const paymentAccount = action.payload.paymentAccount;
            const details = { ...state.details, paymentAccount };
            const request = { ...state, details } as domain.Request;

            return immutable(request);
        }

        case CHANGE_QBOOKS_PAYMENT_METHOD: {
            const paymentMethod = action.payload.paymentMethod;
            const details = { ...state.details, paymentMethod };
            const request = { ...state, details } as domain.Request;

            return immutable(request);
        }

        case CHANGE_QBOOKS_PAYMENT_TYPE: {
            const paymentType = action.payload.paymentType;
            const details = { ...state.details, paymentType };
            const request = { ...state, details } as domain.Request;

            return immutable(request);
        }

        case CHANGE_QBOOKS_EXPENSE_NUMBER: {
            const expenseNumber = action.payload.expenseNumber;
            const details = { ...state.details, expenseNumber };
            const request = { ...state, details } as domain.Request;

            return immutable(request);
        }

        case CHANGE_QBOOKS_REQUEST_CURRENCY: {
            const currentCurrency = state.currency;
            const newCurrency = action.payload.currency.id;

            const request = {
                ...state,
                currency: newCurrency,
                currencyExchangeRate: currentCurrency !== newCurrency ? null : state.currencyExchangeRate,
            } as domain.Request;

            return immutable(request);
        }

        case CHANGE_QBOOKS_MAILING_ADDRESS:
            return setIn(state, ['details', 'mailingAddress'], action.payload.newMailingAddress);

        case CHANGE_QBOOKS_BILLING_ADDRESS:
            return setIn(state, ['details', 'billingAddress'], action.payload.newBillingAddress);

        case CHANGE_QBOOKS_DATE:
            return produce(state, (draft) => {
                const details = draft.details as domain.QBooksBillDetails | domain.QBooksSalesInvoiceDetails;
                const { newDate } = action.payload;

                details.date = newDate;

                const term = details.term;

                if (term) {
                    details.dueDate = getNewDate(term, details.date);
                }

                return draft;
            });

        case CHANGE_QBOOKS_DUE_DATE:
            return setIn(state, ['details', 'dueDate'], action.payload.newDueDate);

        case CHANGE_QBOOKS_NUMBER:
            return setIn(state, ['details', 'number'], action.payload.newNumber);

        case CHANGE_QBOOKS_SHIPPING_FROM_ADDRESS: {
            const { shipping } = state.details as domain.QBooksSalesInvoiceDetails;

            return setIn(state, ['details', 'shipping'], {
                ...shipping,
                fromAddress: action.payload.newShippingFromAddress,
            });
        }

        case CHANGE_QBOOKS_SHIPPING_PRICE: {
            const { shipping } = state.details as domain.QBooksSalesInvoiceDetails;

            return setIn(state, ['details', 'shipping'], {
                ...shipping,
                price: action.payload.newShippingPrice,
                priceGross: action.payload.newShippingPriceGross,
            });
        }

        case CHANGE_QBOOKS_SHIPPING_TAX_CODE: {
            const { shipping } = state.details as domain.QBooksSalesInvoiceDetails;

            return mergeIn(state, ['details'], {
                shippingTaxCode: action.payload.newShippingTaxCode,
                shipping: {
                    ...shipping,
                    price: action.payload.newShippingPrice,
                    priceGross: action.payload.newShippingPriceGross,
                },
            });
        }

        case CHANGE_QBOOKS_SHIPPING_DATE: {
            const { shipping } = state.details as domain.QBooksSalesInvoiceDetails;

            return setIn(state, ['details', 'shipping'], {
                ...shipping,
                date: action.payload.newShippingDate,
            });
        }

        case CHANGE_QBOOKS_TRACKING_NUMBER: {
            const { shipping } = state.details as domain.QBooksSalesInvoiceDetails;

            return setIn(state, ['details', 'shipping'], {
                ...shipping,
                trackingNumber: action.payload.newTrackingNumber,
            });
        }

        case CHANGE_QBOOKS_TERMS:
            return produce(state, (draft) => {
                const details = draft.details as domain.QBooksBillDetails | domain.QBooksSalesInvoiceDetails;
                const newTerm = action.payload.newTerms;

                details.term = newTerm;

                if (newTerm && details.date) {
                    let newDueDate = getNewDate(newTerm, details.date);

                    if (newDueDate) {
                        details.dueDate = newDueDate;
                    }
                }

                return draft;
            });

        case CHANGE_QBOOKS_SHIPPING_ADDRESS: {
            const details = state.details as domain.QBooksPurchaseOrderDetails;

            return setIn(state, ['details', 'shipping'], {
                ...details.shipping,
                address: action.payload.newShippingAddress,
            });
        }

        case CHANGE_QBOOKS_SHIPPING_CUSTOMER: {
            const details = state.details as domain.QBooksPurchaseOrderDetails;
            const shipping = details.shipping;
            const newCustomer = action.payload.newCustomer;

            let newAddress = shipping.address || '';

            if (newCustomer && newCustomer.addresses && newCustomer.addresses[0]) {
                const deliveryAddress = newCustomer.addresses.find(
                    (address) => address.type === domain.QBooksAddressType.Physical
                );

                newAddress = deliveryAddress ? deliveryAddress.text : newCustomer.addresses[0].text;
            }

            return setIn(state, ['details', 'shipping'], {
                ...shipping,
                customer: newCustomer,
                address: newAddress,
            });
        }

        case CHANGE_QBOOKS_SHIPPING_METHOD: {
            const details = state.details as domain.QBooksPurchaseOrderDetails;

            return setIn(state, ['details', 'shipping'], {
                ...details.shipping,
                method: action.payload.newShippingMethod,
            });
        }

        case CHANGE_QBOOKS_DEPARTMENT:
            return setIn(state, ['details', 'department'], action.payload.newDepartment);

        case CHANGE_QBOOKS_DISCOUNT:
            return setIn(state, ['details', 'discount'], action.payload.newDiscount);

        case CHANGE_QBOOKS_DISCOUNT_TYPE:
            return setIn(state, ['details', 'discountType'], action.payload.newDiscountType);

        case CHANGE_QBOOKS_TAX_CODE:
            return mergeIn(state, ['details'], {
                taxCode: action.payload.newTaxCode,
                useAutoTaxes: action.payload.newTaxCode?.id === 'basedOnLocationTax',
            });

        case CHANGE_QBOOKS_APPLY_TAX_AFTER_DISCOUNT:
            return setIn(state, ['details', 'applyTaxAfterDiscount'], action.payload.newApplyTaxAfterDiscount);

        case CHANGE_QBOOKS_CUSTOM_FIELD: {
            const details = state.details as domain.QBooksPurchaseOrderDetails | domain.QBooksSalesInvoiceDetails;
            const allCustomFields = action.payload.context.fields.filter((field) =>
                [domain.FieldSystemPurpose.QBooksCustom, domain.FieldSystemPurpose.QBooksInvoiceCustom].includes(
                    field.systemPurpose
                )
            );
            const newCustomFields = allCustomFields.map((customField) => {
                let newValue;

                const oldField = details.customFields.find(({ field }) => field.id === customField.id);

                if (customField.id === action.payload.fieldId) {
                    newValue = action.payload.newValue;
                } else if (oldField) {
                    newValue = oldField.value;
                } else {
                    newValue = '';
                }

                return {
                    field: customField,
                    value: newValue,
                };
            });

            return setIn(state, ['details', 'customFields'], newCustomFields);
        }

        case CHANGE_QBOOKS_LINE_AMOUNT_TYPE: {
            const newLineAmountType = action.payload.newLineAmountType;
            const details = state.details as
                | domain.QBooksBillDetails
                | domain.QBooksExpenseDetails
                | domain.QBooksPurchaseOrderDetails
                | domain.QBooksSalesInvoiceDetails;

            const taxApplicableOnType =
                details.integrationCode === domain.IntegrationCode.QBooksInvoice
                    ? domain.TaxApplicableOnType.Sales
                    : domain.TaxApplicableOnType.Purchases;

            switch (newLineAmountType) {
                case domain.LineAmountType.NoTax: {
                    const newAccountLineItems =
                        'accountLineItems' in details
                            ? details.lineAmountType === domain.LineAmountType.TaxExclusive
                                ? details.accountLineItems.map((accLineItem) => ({
                                      ...accLineItem,
                                      taxCode: undefined,
                                  }))
                                : details.accountLineItems.map((accLineItem) => {
                                      return isEmptyQBooksAccountLineItem(accLineItem)
                                          ? { ...accLineItem, taxCode: undefined }
                                          : {
                                                ...accLineItem,
                                                amount: mathService.round(
                                                    getComputedPrice({
                                                        price: accLineItem.amount,
                                                        taxCode: accLineItem.taxCode,
                                                        qbooksContext: action.payload.qbooksContext,
                                                        lineAmountType: details.lineAmountType,
                                                        date: details.date,
                                                        taxApplicableOnType,
                                                    }).computedPriceNet || 0,
                                                    2
                                                ),
                                                taxCode: undefined,
                                            };
                                  })
                            : [];

                    return mergeIn(state, ['details'], {
                        lineAmountType: newLineAmountType,
                        lineItems: details.lineItems.map((li) =>
                            isEmptyQBooksLineItem(li)
                                ? li
                                : ({
                                      ...li,
                                      taxCode: undefined,
                                      unitPriceGross: li.unitPrice,
                                  } as domain.QBooksLineItem)
                        ),
                        ...('accountLineItems' in details && {
                            accountLineItems: newAccountLineItems,
                        }),
                    } as domain.QBooksRequestDetails);
                }

                case domain.LineAmountType.TaxExclusive: {
                    const newAccountLineItems =
                        'accountLineItems' in details
                            ? details.accountLineItems.map((accLineItem) => {
                                  return isEmptyQBooksAccountLineItem(accLineItem)
                                      ? accLineItem
                                      : {
                                            ...accLineItem,
                                            amount: mathService.round(
                                                getComputedPrice({
                                                    price: accLineItem.amount,
                                                    taxCode: accLineItem.taxCode,
                                                    qbooksContext: action.payload.qbooksContext,
                                                    lineAmountType: details.lineAmountType,
                                                    date: details.date,
                                                    taxApplicableOnType,
                                                }).computedPriceNet || 0,
                                                2
                                            ),
                                        };
                              })
                            : [];

                    return mergeIn(state, ['details'], {
                        lineAmountType: newLineAmountType,
                        accountLineItems: newAccountLineItems,
                    } as domain.QBooksRequestDetails);
                }

                case domain.LineAmountType.TaxInclusive: {
                    const newAccountLineItems =
                        'accountLineItems' in details
                            ? details.accountLineItems.map((accLineItem) => {
                                  return isEmptyQBooksAccountLineItem(accLineItem)
                                      ? accLineItem
                                      : {
                                            ...accLineItem,
                                            amount: mathService.round(
                                                getComputedPrice({
                                                    price: accLineItem.amount,
                                                    taxCode: accLineItem.taxCode,
                                                    qbooksContext: action.payload.qbooksContext,
                                                    lineAmountType: details.lineAmountType,
                                                    date: details.date,
                                                    taxApplicableOnType,
                                                }).computedPriceGross || 0,
                                                2
                                            ),
                                        };
                              })
                            : [];

                    return mergeIn(state, ['details'], {
                        lineAmountType: newLineAmountType,
                        accountLineItems: newAccountLineItems,
                    } as domain.QBooksRequestDetails);
                }

                default: {
                    throw new Error('Invalid newLineAmountType');
                }
            }
        }

        case CHANGE_QBOOKS_LINE_ITEM_CHECKED:
            return updateLineItem(state, action.payload.lineItemId, (li) => {
                li.checked = action.payload.checked;

                return li;
            });

        case CHANGE_QBOOKS_LINE_ITEMS_ALL_CHECKED: {
            const details = state.details as domain.QBooksSharedDetails;

            return setIn(
                state,
                ['details', 'lineItems'],
                details.lineItems.map((lineItem) => ({ ...lineItem, checked: action.payload }))
            );
        }

        case CHANGE_QBOOKS_ACCOUNT_LINE_ITEM_CHECKED:
            return updateAccountLineItem(state, action.payload.lineItemId, (li) => {
                li.checked = action.payload.checked;

                return li;
            });

        case CHANGE_QBOOKS_ACCOUNT_LINE_ITEMS_ALL_CHECKED: {
            const details = state.details as domain.QBooksSharedDetails;

            return setIn(
                state,
                ['details', 'accountLineItems'],
                details.accountLineItems.map((lineItem) => ({ ...lineItem, checked: action.payload }))
            );
        }

        case CHANGE_QBOOKS_LINE_ITEMS_ITEM:
            return action.payload.lineItemIds.reduce<RequestType>((prev, lineItemId) => {
                return updateLineItem(prev, lineItemId, (lineItem, details) => {
                    const newItem = action.payload.newItem;

                    lineItem.item = newItem || undefined;

                    const isSalesInvoice = details.integrationCode === domain.IntegrationCode.QBooksInvoice;
                    const itemDetails = isSalesInvoice ? newItem?.salesDetails : newItem?.purchaseDetails;

                    if (itemDetails) {
                        lineItem.description = itemDetails.description || '';

                        if (
                            itemDetails.taxCode &&
                            action.payload.qbooksContext.taxCodes.some(
                                (taxCode) => taxCode.id === itemDetails.taxCode?.id
                            ) &&
                            action.payload.qbooksContext.hasTaxesFeature &&
                            details.lineAmountType !== domain.LineAmountType.NoTax
                        ) {
                            lineItem.taxCode = itemDetails.taxCode;
                        }

                        const taxApplicableOnType =
                            details.integrationCode === domain.IntegrationCode.QBooksInvoice
                                ? domain.TaxApplicableOnType.Sales
                                : domain.TaxApplicableOnType.Purchases;

                        const unitPrice = getComputedPrice({
                            price: itemDetails.unitPrice || 0,
                            taxCode: lineItem.taxCode,
                            qbooksContext: action.payload.qbooksContext,
                            lineAmountType: details.lineAmountType,
                            date: details.date,
                            taxApplicableOnType,
                        });

                        let unitPriceNet = unitPrice.computedPriceNet || 0;
                        let unitPriceGross = unitPrice.computedPriceGross || 0;

                        lineItem.originalUnitPrice = unitPriceNet;
                        lineItem.originalUnitPriceGross = unitPriceGross;

                        const exchangeRate = state.currencyExchangeRate || state.exchangeRate;

                        if (exchangeRate) {
                            unitPriceNet = mathService.round(unitPriceNet * exchangeRate, 4);
                            unitPriceGross = mathService.round(unitPriceGross * exchangeRate, 4);
                        }

                        lineItem.unitPrice = unitPriceNet;
                        lineItem.unitPriceGross = unitPriceGross;
                        lineItem.qty = lineItem.unitPrice != null ? 1 : undefined;

                        lineItem.class = newItem?.class ?? undefined;
                    }

                    return lineItem;
                });
            }, state);

        case CHANGE_QBOOKS_LINE_ITEM_DESCRIPTION:
            return updateLineItem(state, action.payload.lineItemId, (li) => {
                li.description = action.payload.newDescription;

                return li;
            });

        case CHANGE_QBOOKS_LINE_ITEM_SERVICE_DATE:
            return updateLineItem(state, action.payload.lineItemId, (li) => {
                li.serviceDate = action.payload.newServiceDate || undefined;

                return li;
            });

        case CHANGE_QBOOKS_LINE_ITEM_QTY:
            return updateLineItem(state, action.payload.lineItemId, (li) => {
                li.qty = action.payload.newQty != null ? action.payload.newQty : undefined;

                return li;
            });

        case CHANGE_QBOOKS_LINE_ITEMS_CLASS:
            return action.payload.lineItemIds.reduce<RequestType>((prev, lineItemId) => {
                return updateLineItem(prev, lineItemId, (lineItem) => {
                    lineItem.class = action.payload.newClass || undefined;

                    return lineItem;
                });
            }, state);

        case CHANGE_QBOOKS_LINE_ITEM_MATCHING:
            return updateLineItem(state, action.payload.lineItemId, (li) => {
                li.matchedLineItemId = action.payload.matchedLineItemId || undefined;

                return li;
            });

        case CHANGE_QBOOKS_LINE_ITEMS_CUSTOMER:
            return action.payload.lineItemIds.reduce<RequestType>((prev, lineItemId) => {
                return updateLineItem(prev, lineItemId, (lineItem) => {
                    lineItem.customer = action.payload.newCustomer || undefined;

                    return lineItem;
                });
            }, state);

        case CHANGE_QBOOKS_LINE_ITEMS_TAX_CODE:
            return action.payload.lineItemIds.reduce<RequestType>((prev, lineItemId, index) => {
                return updateLineItem(prev, lineItemId, (lineItem) => {
                    lineItem.taxCode = action.payload.newTaxCode || undefined;
                    lineItem.unitPrice = action.payload.unitPriceArray[index].newUnitPriceNet;
                    lineItem.unitPriceGross = action.payload.unitPriceArray[index].newUnitPriceGross;

                    return lineItem;
                });
            }, state);

        case CHANGE_QBOOKS_LINE_ITEM_UNIT_PRICE:
            return updateLineItem(state, action.payload.lineItemId, (li) => {
                if (li.unitPrice !== action.payload.newUnitPriceNet) {
                    li.isUnitPriceChanged = true;
                }

                li.unitPrice = action.payload.newUnitPriceNet;
                li.unitPriceGross = action.payload.newUnitPriceGross;

                return li;
            });

        case REORDER_QBOOKS_LINE_ITEMS: {
            const details = state.details as domain.QBooksPurchaseOrderDetails;

            return setIn(
                state,
                ['details', 'lineItems'],
                arrayHelpers.arrayMove(details.lineItems, action.payload.oldIndex, action.payload.newIndex)
            );
        }

        case REMOVE_QBOOKS_LINE_ITEMS: {
            const details = state.details as domain.QBooksPurchaseOrderDetails;

            let lineItems = action.payload.lineItemIds.reduce<domain.QBooksLineItem[]>((prev, lineItemId) => {
                return removeArrayItem(prev, (lineItem) => lineItem.id === lineItemId);
            }, details.lineItems);

            if (lineItems.length === 0) {
                lineItems = [action.payload.newLineItem];
            }

            return setIn(state, ['details', 'lineItems'], lineItems);
        }

        case REMOVE_QBOOKS_LINE_ITEMS_ALL: {
            return setIn(state, ['details', 'lineItems'], [action.payload.newLineItem]);
        }

        case CLONE_QBOOKS_LINE_ITEMS: {
            const details = state.details as domain.QBooksPurchaseOrderDetails;
            const newLineItems = action.payload.newLineItems;

            return setIn(
                state,
                ['details', 'lineItems'],
                newLineItems.reduce<domain.QBooksLineItem[]>((prev, lineItem, index) => {
                    return insertArrayItem(
                        prev,
                        lineItem,
                        prev.findIndex((lineItem) => lineItem.id === action.payload.lineItemIds[index]) + 1
                    );
                }, details.lineItems)
            );
        }

        case CLONE_QBOOKS_LINE_ITEMS_ALL: {
            const details = state.details as domain.QBooksPurchaseOrderDetails;
            const newLineItems = action.payload.newLineItems;

            return setIn(state, ['details', 'lineItems'], flatten(zip(details.lineItems, newLineItems)));
        }

        case ADD_QBOOKS_LINE_ITEM: {
            const details = state.details as domain.QBooksPurchaseOrderDetails;

            return setIn(state, ['details', 'lineItems'], addArrayItem(details.lineItems, action.payload.newLineItem));
        }

        case CHANGE_QBOOKS_ACCOUNT_LINE_ITEMS_CLASS:
            return action.payload.accountLineItemIds.reduce<RequestType>((prev, accountLineItemId) => {
                return updateAccountLineItem(prev, accountLineItemId, (accountLineItem) => {
                    accountLineItem.class = action.payload.newClass || undefined;

                    return accountLineItem;
                });
            }, state);

        case CHANGE_QBOOKS_ACCOUNT_LINE_ITEMS_CUSTOMER:
            return action.payload.accountLineItemIds.reduce<RequestType>((prev, accountLineItemId) => {
                return updateAccountLineItem(prev, accountLineItemId, (accountLineItem) => {
                    accountLineItem.customer = action.payload.newCustomer || undefined;

                    return accountLineItem;
                });
            }, state);

        case CHANGE_QBOOKS_ACCOUNT_LINE_ITEM_AMOUNT:
            return updateAccountLineItem(state, action.payload.accountLineItemId, (li) => {
                li.amount = action.payload.newAmount !== null ? action.payload.newAmount : undefined;

                return li;
            });

        case CHANGE_QBOOKS_ACCOUNT_LINE_ITEM_DESCRIPTION:
            return updateAccountLineItem(state, action.payload.accountLineItemId, (li) => {
                li.description = action.payload.newDescription;

                return li;
            });

        case CHANGE_QBOOKS_ACCOUNT_LINE_ITEMS_TAX_CODE:
            return action.payload.accountLineItemIds.reduce<RequestType>((prev, accountLineItemId) => {
                return updateAccountLineItem(prev, accountLineItemId, (accountLineItem) => {
                    accountLineItem.taxCode = action.payload.newTaxCode || undefined;

                    return accountLineItem;
                });
            }, state);

        case CHANGE_QBOOKS_ACCOUNT_LINE_ITEMS_ACCOUNT:
            return action.payload.accountLineItemIds.reduce<RequestType>((prev, accountLineItemId) => {
                return updateAccountLineItem(prev, accountLineItemId, (accountLineItem, details) => {
                    const newAccount = action.payload.newAccount;

                    accountLineItem.account = newAccount || undefined;

                    if (
                        newAccount &&
                        newAccount.taxCode &&
                        action.payload.qbooksContext.hasTaxesFeature &&
                        details.lineAmountType !== domain.LineAmountType.NoTax
                    ) {
                        accountLineItem.taxCode = newAccount.taxCode;
                    }

                    return accountLineItem;
                });
            }, state);

        case CHANGE_QBOOKS_ACCOUNT_LINE_ITEM_MATCHING:
            return updateAccountLineItem(state, action.payload.accountLineItemId, (li) => {
                li.matchedLineItemId = action.payload.matchedLineItemId || undefined;

                return li;
            });

        case REORDER_QBOOKS_ACCOUNT_LINE_ITEM: {
            const details = state.details as domain.QBooksPurchaseOrderDetails;

            return setIn(
                state,
                ['details', 'accountLineItems'],
                arrayHelpers.arrayMove(details.accountLineItems, action.payload.oldIndex, action.payload.newIndex)
            );
        }

        case REMOVE_QBOOKS_ACCOUNT_LINE_ITEMS: {
            const details = state.details as domain.QBooksPurchaseOrderDetails;

            let accountLineItems = action.payload.accountLineItemIds.reduce<domain.QBooksAccountLineItem[]>(
                (prev, accountLineItemId) => {
                    return removeArrayItem(prev, (accountLineItem) => accountLineItem.id === accountLineItemId);
                },
                details.accountLineItems
            );

            if (accountLineItems.length === 0) {
                accountLineItems = [action.payload.newAccountLineItem];
            }

            return setIn(state, ['details', 'accountLineItems'], accountLineItems);
        }

        case REMOVE_QBOOKS_ACCOUNT_LINE_ITEMS_ALL: {
            return setIn(state, ['details', 'accountLineItems'], [action.payload.newAccountLineItem]);
        }

        case CLONE_QBOOKS_ACCOUNT_LINE_ITEMS: {
            const details = state.details as domain.QBooksPurchaseOrderDetails;
            const newAccountLineItems = action.payload.newAccountLineItems;

            return setIn(
                state,
                ['details', 'accountLineItems'],
                newAccountLineItems.reduce<domain.QBooksAccountLineItem[]>((prev, accountLineItem, index) => {
                    return insertArrayItem(
                        prev,
                        accountLineItem,
                        prev.findIndex(
                            (accountLineItem) => accountLineItem.id === action.payload.accountLineItemIds[index]
                        ) + 1
                    );
                }, details.accountLineItems)
            );
        }

        case CLONE_QBOOKS_ACCOUNT_LINE_ITEMS_ALL: {
            const details = state.details as domain.QBooksPurchaseOrderDetails;
            const newAccountLineItems = action.payload.newAccountLineItems;

            return setIn(
                state,
                ['details', 'accountLineItems'],
                flatten(zip(details.accountLineItems, newAccountLineItems))
            );
        }

        case ADD_QBOOKS_ACCOUNT_LINE_ITEM: {
            const details = state.details as domain.QBooksPurchaseOrderDetails;

            return setIn(
                state,
                ['details', 'accountLineItems'],
                addArrayItem(details.accountLineItems, action.payload.newAccountLineItem)
            );
        }

        case QBOOKS_EMAIL_TO_VENDOR_INIT: {
            const { context, author, details, isNew } = action.payload;

            const { supplierEmailSettings } = context;

            const emailDetails: domain.EmailToSupplierDetails = isNew
                ? getEmailDetails(details.vendor, context, details.emailToSupplier)
                : details.emailToSupplier || { to: [], cc: [], subject: '', body: '' };

            const sendToSupplier = isNew
                ? supplierEmailSettings?.state === domain.TemplateSettingsEmailToSupplierState.EnabledAndActive
                : details.sendToSupplier;

            let emailToSupplier: domain.EmailToSupplier = {
                from: author.displayName,
                to: uniq<string>([...emailDetails.to, ...(details.emailToSupplier?.to || [])]),
                cc: uniq<string>(
                    isNew
                        ? [author.userEmail, ...emailDetails.cc, ...(details.emailToSupplier?.cc || [])]
                        : emailDetails.cc
                ),
                replyTo: isNew ? author.userEmail : details.emailToSupplier?.replyTo || '',
                subject: emailDetails.subject,
                body: emailDetails.body,
                attachments: details.emailToSupplier?.attachments || [],
            };

            const isEmailToSupplierDisabled =
                supplierEmailSettings?.state === domain.TemplateSettingsEmailToSupplierState.Disabled;

            if (isEmailToSupplierEmpty(emailToSupplier) && details.vendor && !isEmailToSupplierDisabled) {
                const supplierEmailDetails = getEmailDetails(details.vendor, context);

                emailToSupplier = {
                    ...emailToSupplier,
                    ...supplierEmailDetails,
                };
            }

            return mergeIn(state, ['details'], { sendToSupplier, emailToSupplier });
        }

        case QBOOKS_EMAIL_TO_VENDOR_TOGGLE:
            return setIn(state, ['details', 'sendToSupplier'], action.payload.sendToVendor);

        case QBOOKS_EMAIL_TO_CUSTOMER_INIT: {
            const { context, author, details, isNew } = action.payload;

            const { supplierEmailSettings } = context;

            const emailDetails: domain.EmailToSupplierDetails = isNew
                ? getEmailDetails(details.customer, context, details.emailToContact)
                : details.emailToContact || { to: [], cc: [], subject: '', body: '' };

            const isEmailHasToBeSent = isNew
                ? supplierEmailSettings?.state === domain.TemplateSettingsEmailToSupplierState.EnabledAndActive
                : details.emailToContact.emailHasToBeSent;

            let emailToContact: domain.EmailToSupplier = {
                from: author.displayName,
                to: uniq<string>(emailDetails.to),
                cc: uniq<string>(isNew ? [author.userEmail, ...emailDetails.cc] : emailDetails.cc),
                replyTo: isNew ? author.userEmail : details.emailToContact?.replyTo || '',
                subject: emailDetails.subject,
                body: emailDetails.body,
                attachments: details.emailToContact?.attachments || [],
                emailHasToBeSent: isEmailHasToBeSent,
            };

            const isEmailToSupplierDisabled =
                supplierEmailSettings?.state === domain.TemplateSettingsEmailToSupplierState.Disabled;

            if (isEmailToSupplierEmpty(emailToContact) && details.customer && !isEmailToSupplierDisabled) {
                const supplierEmailDetails = getEmailDetails(details.customer, context);

                emailToContact = {
                    ...emailToContact,
                    ...supplierEmailDetails,
                };
            }

            return mergeIn(state, ['details'], { emailToContact });
        }

        case QBOOKS_EMAIL_TO_CUSTOMER_TOGGLE: {
            const details = state.details as domain.QBooksSalesInvoiceDetails;
            const emailToContact = details.emailToContact;

            return setIn(state, ['details', 'emailToContact'], {
                ...emailToContact,
                emailHasToBeSent: action.payload.emailHasToBeSent,
            });
        }

        case QBOOKS_EMAIL_TO_VENDOR_CHANGE_DATA:
            return setIn(state, ['details', 'emailToSupplier', action.payload.key], action.payload.value);

        case QBOOKS_EMAIL_TO_CUSTOMER_CHANGE_DATA:
            return setIn(state, ['details', 'emailToContact', action.payload.key], action.payload.value);

        case TOGGLE_QBOOKS_LINE_ITEM_IS_BILLABLE:
            return updateLineItem(state, action.payload.lineItemId, (li) => {
                li.isBillable = action.payload.isBillable;

                if (action.payload.hasTaxableFeature) {
                    li.isTaxable = action.payload.isBillable;
                }

                return li;
            });

        case TOGGLE_QBOOKS_LINE_ITEM_IS_TAXABLE:
            return updateLineItem(state, action.payload.lineItemId, (li) => {
                if (action.payload.isTaxable) {
                    li.isBillable = true;
                }

                li.isTaxable = action.payload.isTaxable;

                return li;
            });

        case TOGGLE_QBOOKS_ACCOUNT_LINE_ITEM_IS_BILLABLE:
            return updateAccountLineItem(state, action.payload.accountLineItemId, (li) => {
                li.isBillable = action.payload.isBillable;

                if (action.payload.hasTaxableFeature) {
                    li.isTaxable = action.payload.isBillable;
                }

                return li;
            });

        case TOGGLE_QBOOKS_ACCOUNT_LINE_ITEM_IS_TAXABLE:
            return updateAccountLineItem(state, action.payload.accountLineItemId, (li) => {
                if (action.payload.isTaxable) {
                    li.isBillable = true;
                }

                li.isTaxable = action.payload.isTaxable;

                return li;
            });

        case UPDATE_QBOOKS_LINE_ITEMS_WITH_CURRENCY_EXCHANGE_RATE: {
            const isSubmittedManualRate = state.isManualExchangeRate;

            const defaultExchangeRate = isSubmittedManualRate ? null : state.exchangeRate;

            const newExchangeRate = action.payload.newExchangeRate || defaultExchangeRate || null;
            const details = state.details as domain.QBooksPurchaseOrderDetails;

            return setIn(state, ['details', 'lineItems'], updateLineItemsWithExchangeRate(details, newExchangeRate));
        }

        case UPDATE_QBOOKS_FORM_WITH_CONTEXT: {
            const qbooksContext = action.payload.qbooksContext;
            const { qbooksItems, taxCodes, defaultSalesTermId, defaultPurchaseTermId } = qbooksContext;

            let newState = state;

            const details = state.details as
                | domain.QBooksPurchaseOrderDetails
                | domain.QBooksBillDetails
                | domain.QBooksSalesInvoiceDetails;

            const isSalesInvoice = details.integrationCode === domain.IntegrationCode.QBooksInvoice;

            const taxApplicableOnType = isSalesInvoice
                ? domain.TaxApplicableOnType.Sales
                : domain.TaxApplicableOnType.Purchases;

            if (qbooksItems?.length) {
                const newLineItems = details.lineItems.map((lineItem) => {
                    const newTaxCode = taxCodes.some((taxCode) => taxCode.id === lineItem.taxCode?.id)
                        ? lineItem.taxCode
                        : undefined;

                    const newLineItem = { ...lineItem, taxCode: newTaxCode };

                    const qbooksItemInfo = qbooksItems.find((item) => newLineItem.item?.id === item.id);

                    const itemDetails = isSalesInvoice ? qbooksItemInfo?.salesDetails : qbooksItemInfo?.purchaseDetails;

                    if (itemDetails) {
                        const originalComputedUnitPrice = getComputedPrice({
                            price: itemDetails.unitPrice || 0,
                            taxCode: newLineItem.taxCode,
                            qbooksContext: qbooksContext,
                            lineAmountType: details.lineAmountType,
                            date: details.date,
                            taxApplicableOnType,
                        });

                        let unitPriceNet = originalComputedUnitPrice.computedPriceNet || 0;

                        const unitPriceGross = originalComputedUnitPrice.computedPriceGross || 0;

                        newLineItem.originalUnitPrice = unitPriceNet;
                        newLineItem.originalUnitPriceGross = unitPriceGross;

                        const exchangeRate = state.currencyExchangeRate || state.exchangeRate;

                        if (exchangeRate) {
                            unitPriceNet = mathService.round(unitPriceNet * exchangeRate, 4);
                        }

                        newLineItem.isUnitPriceChanged = newLineItem.unitPrice !== unitPriceNet;
                    }

                    const unitPrice =
                        details.lineAmountType === domain.LineAmountType.TaxExclusive
                            ? newLineItem.unitPrice
                            : newLineItem.unitPriceGross;

                    const computedUnitPrice = getComputedPrice({
                        price: unitPrice,
                        taxCode: newLineItem.taxCode,
                        qbooksContext: qbooksContext,
                        lineAmountType: details.lineAmountType,
                        date: details.date,
                        taxApplicableOnType,
                    });

                    newLineItem.unitPriceGross = computedUnitPrice.computedPriceGross;

                    return newLineItem;
                });

                newState = setIn(newState, ['details', 'lineItems'], newLineItems);
            }

            if ('accountLineItems' in details && details.accountLineItems) {
                const newAccountLineItems = details.accountLineItems.map((lineItem) => {
                    const newTaxCode =
                        lineItem.taxCode && taxCodes.some((taxCode) => taxCode.id === lineItem.taxCode?.id)
                            ? lineItem.taxCode
                            : undefined;

                    return { ...lineItem, taxCode: newTaxCode };
                });

                newState = setIn(newState, ['details', 'accountLineItems'], newAccountLineItems);
            }

            const defaultTermId = isSalesInvoice ? defaultSalesTermId : defaultPurchaseTermId;

            if (defaultTermId && 'term' in details && !details.term) {
                const term = qbooksContext.terms.find(({ id }) => id === defaultTermId);

                if (term) {
                    newState = mergeIn(state, ['details'], { term, dueDate: getNewDate(term, details.date) });
                }
            }

            return newState;
        }

        default:
            return state;
    }
}
