import { font } from '@approvalmax/ui';
import FieldEntryWrapper from 'pages/requestList/components/FieldEntryWrapper/FieldEntryWrapper';
import styled from 'styled-components';

import SectionHeaderText from '../SectionHeaderText/SectionHeaderText';

export const Container = styled.div`
    display: flex;
`;

export const HeaderContaier = styled(SectionHeaderText)`
    display: flex;
`;

export const SameAsPostalAddress = styled.span`
    margin-left: 27px;
    ${font(13, '#7f7d7d', 'standard')}
`;

export const StyledFieldEntryWrapper = styled(FieldEntryWrapper)`
    width: 20%;
`;
