import { DataTable, DataTableColumnDefinition, DataTablePadding } from '@approvalmax/ui';

import { messages } from './AddMatchedPOsRequestersPopup.messages';
import { StyledDefaultColumnHeader } from './AddMatchedPOsRequestersPopup.styles';
import RequesterCell from './components/RequesterCell';

export const tableColumns: DataTableColumnDefinition[] = [
    {
        id: 'requester',
        name: messages.checkboxColumnTitle,
        sortable: false,
        cellComponent: (props) => (
            <DataTable.CheckboxCell {...props} padding={DataTablePadding.PopupLeft} cellComponent={RequesterCell} />
        ),
        columnComponent: (props) => (
            <DataTable.CheckboxColumnHeader
                {...props}
                padding={DataTablePadding.PopupLeft}
                columnComponent={StyledDefaultColumnHeader}
            />
        ),
    },
];
