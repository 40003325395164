import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { integrationApiPaths } from '../paths';
import { GetXeroBankAccounts } from './useGetXeroBankAccounts.types';

export const useGetXeroBankAccounts = (
    params?: RequestParams<GetXeroBankAccounts>,
    queryOptions?: RequestQueryOptions<GetXeroBankAccounts>
) => {
    return useGet(integrationApiPaths.xeroBankAccounts, {
        params,
        queryOptions,
        apiVersion: 'v1',
        method: 'get',
    });
};
