import { FC, memo } from 'react';

import { NetSuiteLineTable } from '../NetSuiteLineTable/NetSuiteLineTable';
import { useTableDefinitions } from './NetSuiteVendorAddressList.hooks';
import { messages } from './NetSuiteVendorAddressList.messages';
import { NetSuiteVendorAddressListProps } from './NetSuiteVendorAddressList.types';

export const NetSuiteVendorAddressList: FC<NetSuiteVendorAddressListProps> = memo((props) => {
    const { addressList, fieldSettings } = props;

    const tableDef = useTableDefinitions(fieldSettings);

    return addressList.length > 0 ? (
        <NetSuiteLineTable title={messages.sectionHeaderText} columnDefinitions={tableDef} lineItems={addressList} />
    ) : null;
});

NetSuiteVendorAddressList.displayName = 'NetSuiteVendorAddressList';
