import { Box, Divider, Flex } from '@approvalmax/ui/src/components';
import range from 'lodash/range';
import { Fragment, memo } from 'react';
import Skeleton from 'react-loading-skeleton';

const RequestCardSkeleton = memo(() => {
    return (
        <Box color='white100' width='100%'>
            <Box spacing='20 60 15'>
                <Flex justifyContent='space-between'>
                    <Skeleton height={19} width={200} />

                    <Skeleton height={19} width={100} />
                </Flex>

                <Box spacing='18 0 0'>
                    <Flex justifyContent='space-between'>
                        <div>
                            <Skeleton height={10} width={70} />

                            <Skeleton height={10} width={130} />

                            <Skeleton height={10} width={90} />
                        </div>

                        <Skeleton height={34} width={118} />
                    </Flex>
                </Box>

                <Box spacing='18 0 0'>
                    <Flex spacing='8'>
                        <Skeleton height={30} width={30} borderRadius={30} />

                        <div>
                            <Skeleton height={9} width={140} />

                            <Skeleton height={10} width={90} />
                        </div>
                    </Flex>
                </Box>
            </Box>

            <Divider />

            <Box spacing='20 60 15'>
                <Box spacing='0 0 50'>
                    <Flex container justifyContent='space-between'>
                        {range(4).map((i) => (
                            <Flex size={6} key={i} block>
                                <Skeleton height={8} width={50} />

                                <Skeleton height={10} width={90} />
                            </Flex>
                        ))}
                    </Flex>
                </Box>

                <Flex container wrap={false}>
                    <Flex size={4} block>
                        <Box spacing='8 0'>
                            <Skeleton height={8} width={40} />
                        </Box>
                    </Flex>

                    <Flex size={4} block>
                        <Box spacing='8 0'>
                            <Skeleton height={8} width={80} />
                        </Box>
                    </Flex>

                    <Flex size={4} block>
                        <Box spacing='8 0'>
                            <Skeleton height={8} width={30} />
                        </Box>
                    </Flex>

                    <Flex size={4} block>
                        <Box spacing='8 0'>
                            <Skeleton height={8} width={60} />
                        </Box>
                    </Flex>

                    <Flex size={4} block>
                        <Box spacing='8 0'>
                            <Skeleton height={8} width={60} />
                        </Box>
                    </Flex>

                    <Flex size={4} block>
                        <Box spacing='8 0'>
                            <Skeleton height={8} width={20} />
                        </Box>
                    </Flex>
                </Flex>

                <Divider />

                {range(6).map((i) => (
                    <Fragment key={i}>
                        <Flex container wrap={false}>
                            {range(6).map((k) => (
                                <Flex size={4} key={k} block>
                                    <Box spacing='8 0'>
                                        <Skeleton height={10} width={80} />
                                    </Box>
                                </Flex>
                            ))}
                        </Flex>

                        <Divider />
                    </Fragment>
                ))}

                <Box spacing='16 0 0'>
                    <Flex justifyContent='end' direction='column' alignItems='end' spacing='4'>
                        <Skeleton height={8} width={140} />

                        <Skeleton height={10} width={180} />

                        <Skeleton height={10} width={190} />

                        <Skeleton height={10} width={90} />
                    </Flex>
                </Box>

                <Box spacing='40 0 0'>
                    <Flex justifyContent='space-between' alignItems='center'>
                        <div>
                            <Skeleton height={10} width={150} />

                            <Skeleton height={8} width={300} />
                        </div>

                        <Skeleton height={36} width={142} />
                    </Flex>
                </Box>

                <Box spacing='40 0 0'>
                    <Skeleton height={10} width={150} />

                    <Box spacing='20 0 0'>
                        <Flex>
                            <Skeleton height={30} width={180} borderRadius={15} />

                            <Skeleton height={30} width={180} borderRadius={15} />

                            <Skeleton height={30} width={180} borderRadius={15} />
                        </Flex>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
});

export default RequestCardSkeleton;
