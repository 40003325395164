import { ChevronLeftIcon } from '@approvalmax/ui';
import { Box, Button, Flex } from '@approvalmax/ui/src/components';
import { FC, memo } from 'react';

import { messages } from './FooterButtons.messages';
import { FooterButtonsProps } from './FooterButtons.types';

export const FooterButtons: FC<FooterButtonsProps> = memo((props) => {
    const { isNextDisabled, isNextLoading, nextButtonText, backButtonText, isNextTypeSubmit, onNext, onBack } = props;

    return (
        <Flex spacing='24' justifyContent='end'>
            {onBack && (
                <Button
                    color='blue10'
                    size='large'
                    onClick={onBack}
                    startIcon={
                        backButtonText ? undefined : (
                            <Flex shrink={0}>
                                <Box height={20} width={20}>
                                    <ChevronLeftIcon size={20} />
                                </Box>
                            </Flex>
                        )
                    }
                >
                    <Flex spacing='8'>{backButtonText ?? messages.goBack}</Flex>
                </Button>
            )}

            <Box width={200}>
                <Button
                    block
                    progress={isNextLoading}
                    onClick={onNext}
                    type={isNextTypeSubmit ? 'submit' : undefined}
                    color='blue80'
                    size='large'
                    disabled={isNextDisabled || isNextLoading}
                >
                    {nextButtonText ?? messages.next}
                </Button>
            </Box>
        </Flex>
    );
});

FooterButtons.displayName = 'FooterButtons';
