import { Reference, ValueOf } from '@approvalmax/types';
import { intl } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { ColumnDefinition } from 'pages/requestList/selectors/types/ColumnDefinition';

import { messages } from './XeroManualJournalLinesSection.messages';

export const getColumnDefinition = (
    currency: string,
    lineAmountType: ValueOf<domain.LineAmountType>,
    usedTrackingCategories: Reference[]
): ColumnDefinition<domain.XeroManualJournalLine>[] => [
    {
        id: 'numberCol',
        name: messages.numberColName,
        value: (li, index) => String(index + 1),
    },
    {
        id: 'description',
        name: messages.descriptionColName,
        value: (li) => li.description || '',
    },
    {
        id: 'account',
        name: messages.accountColName,
        value: (li) => li.account?.text ?? null,
    },
    {
        id: 'tax',
        name: messages.taxColName,
        value: (li) => li.tax?.text ?? null,
    },
    {
        id: 'taxAmount',
        name: messages.taxAmountColName,
        alignRight: true,
        value: (li) => {
            let taxAmount = 0;

            if (lineAmountType !== domain.LineAmountType.NoTax && typeof li.amount === 'number' && li.tax) {
                const taxableAmount =
                    (li.amount * 100) /
                    (100 + (lineAmountType === domain.LineAmountType.TaxExclusive ? 0 : li.tax.rate));

                taxAmount = Math.round(taxableAmount * li.tax.rate) / 100;
            }

            return intl.formatNumber(taxAmount, 'auto');
        },
    },
    ...usedTrackingCategories.map<ColumnDefinition<domain.XeroManualJournalLine>>((category) => {
        return {
            id: category.id,
            name: category.text,
            value: (li) => {
                const trackingItem = li.tracking.find((item) => item.category.id === category.id);

                return trackingItem?.value.text ?? null;
            },
        };
    }),
    {
        id: 'debit',
        name: `${messages.debitColName} (${currency})`,
        alignRight: true,
        value: (li) =>
            li.amountType === domain.XeroManualJournalLineAmountType.Debit && typeof li.amount === 'number'
                ? intl.formatNumber(li.amount, 'auto')
                : null,
    },
    {
        id: 'credit',
        name: `${messages.creditColName} (${currency})`,
        alignRight: true,
        value: (li) =>
            li.amountType === domain.XeroManualJournalLineAmountType.Credit && typeof li.amount === 'number'
                ? intl.formatNumber(li.amount, 'auto')
                : null,
    },
];
