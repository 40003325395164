import { Button } from '@approvalmax/ui';
import { selectors, statics } from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import moment from 'moment';
import { memo, useMemo } from 'react';
import { TextWithMentions } from 'shared/components';

import FieldsSection from '../FieldsSection';
import EditPopup from './components/EditPopup/EditPopup';
import {
    useGoodsReceivedNotesData,
    useGoodsReceivedNotesLabel,
    useGoodsReceiveNotesPopup,
} from './GoodsReceivedNotes.hooks';
import { messages } from './GoodsReceivedNotes.messages';
import {
    Content,
    ContentFieldsSection,
    ContentTitle,
    Header,
    StatusLabel,
    Title,
    Wrap,
} from './GoodsReceivedNotes.styles';
import { GoodsReceivedNotesProps } from './GoodsReceivedNotes.types';

const GoodsReceivedNotes = memo<GoodsReceivedNotesProps>((props) => {
    const { request, readonly } = props;

    const requestExpanded = useSelector((state) => selectors.request.findRequestById(state, request.id));

    const data = useGoodsReceivedNotesData(request);
    const label = useGoodsReceivedNotesLabel(request);
    const company = useSelector((state) => selectors.company.getCompanyById(state, request.companyId));
    const currentUser = useSelector(selectors.profile.getProfileUser);
    const companyUserRole = selectors.company.getCompanyUserRole(company, currentUser.id);

    const companyTimeZone = useMemo(() => statics.timeZone.findTimeZoneById(company.timeZone), [company.timeZone]);
    const utcOffset = statics.timeZone.getUtcOffset(companyTimeZone);

    const isCurrentUserWatcher = useMemo(
        () => request.watchers.some((watcher) => watcher.watcher.id === currentUser.id),
        [currentUser.id, request.watchers]
    );
    const isDisabledUpdateStatus =
        readonly || company.isReadonly || (isCurrentUserWatcher && companyUserRole !== domain.CompanyUserRole.Manager);

    const { isPopupOpen, onPopupOpen, onPopupClose } = useGoodsReceiveNotesPopup();

    return (
        <>
            <Wrap>
                <Header>
                    <Title>{messages.title}</Title>

                    {label && <StatusLabel status={label.status}>{label.title}</StatusLabel>}

                    <Button
                        fontSize='small12'
                        preset='small'
                        execute={onPopupOpen}
                        title={messages.updateStatus}
                        disabled={isDisabledUpdateStatus}
                    >
                        {messages.updateStatus}
                    </Button>
                </Header>

                {data && (
                    <Content>
                        <ContentTitle>{messages.lastNote}</ContentTitle>

                        <ContentFieldsSection>
                            <FieldsSection.Field
                                title={messages.fieldDate}
                                valueType={FieldsSection.Field.ValueType.Date}
                                value={moment.utc(data.date).add(utcOffset, 'minutes').format()}
                            />

                            <FieldsSection.Field
                                title={messages.fieldAuthor}
                                valueType={FieldsSection.Field.ValueType.String}
                                value={data.author?.displayName}
                            />

                            {data.comment &&
                            data.comment.mentionedUsers &&
                            data.comment.mentionedUsers.length > 0 &&
                            requestExpanded ? (
                                <FieldsSection.Field
                                    title={messages.fieldComment}
                                    isHtml={false}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={null}
                                >
                                    <TextWithMentions
                                        request={requestExpanded}
                                        html={data.comment?.text}
                                        mentionedUsers={data.comment?.mentionedUsers}
                                    />
                                </FieldsSection.Field>
                            ) : (
                                <FieldsSection.Field
                                    isHtml
                                    title={messages.fieldComment}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={data.comment}
                                />
                            )}
                        </ContentFieldsSection>
                    </Content>
                )}
            </Wrap>

            <EditPopup
                isOpen={isPopupOpen}
                requestId={request.id}
                companyId={request.companyId}
                onClose={onPopupClose}
            />
        </>
    );
});

export default GoodsReceivedNotes;
