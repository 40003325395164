import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages(
    'pages/requestList/containers/card/Toolbar/components/POStatus/components/ChangeStatusPopup',
    {
        popupTitle: 'Mark the Purchase Order as "{status}"',
        buttonText: 'Mark as "{status}"',
        commentDescription:
            'Please provide a comment (optional) to let everyone know why this Purchase Order has been marked as "{status}".',
        successToastText: 'Purchase Order has been marked as "{status}"',
        errorToastText: 'Failed to mark Purchase Order as "{status}"',
    }
);
