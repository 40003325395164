import { ErrorCode, Guid } from '@approvalmax/types';
import { dateTimeHelpers } from '@approvalmax/utils';
import { schemas, stateTree } from 'modules/data';
import { createAction, useDispatch } from 'modules/react-redux';
import moment from 'moment';
import { normalize } from 'normalizr';
import { RELOAD_REQUEST_RESPONSE } from 'pages/requestList/actions';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { useAirwallexSchedulePayment, UseAirwallexSchedulePaymentResponse } from 'shared/data';

import { getStartOfDayDate, getTimeOptions } from './SchedulePaymentPopup.helpers';
import { schedulePaymentPopupOpenState } from './SchedulePaymentPopup.states';
import { ProcessSchedulePaymentApiCallParams, Step } from './SchedulePaymentPopup.types';

export const useGetAllowedDates = () => {
    const minDateDirty = moment().add(2, 'hours').toDate();
    const maxDateDirty = moment().add(30, 'days').toDate();

    return { minDate: getStartOfDayDate(minDateDirty), maxDate: getStartOfDayDate(maxDateDirty) };
};

export const useGetDefaultDateTime = (minDate: Date, scheduledPaymentDateString?: string) => {
    if (scheduledPaymentDateString) {
        const scheduledPaymentDateWithTime = new Date(scheduledPaymentDateString);
        const scheduledPaymentDateHours = scheduledPaymentDateWithTime.getHours();

        return {
            defaultDate: moment.utc(scheduledPaymentDateWithTime).startOf('day').toDate(),
            defaultSelectedHours: scheduledPaymentDateHours,
        };
    }

    const defaultDate = minDate;
    const nowHours = new Date().getHours();
    const defaultSelectedHours = (nowHours + 2) % 24;

    return { defaultDate, defaultSelectedHours };
};

export const useFilteredTimeOptions = (minDate: Date, selectedDate?: Date) => {
    const timeOptions = useMemo(getTimeOptions, []);

    return useMemo(() => {
        if (selectedDate === minDate) {
            const twoHoursLater = moment().add(2, 'hours').toDate();
            const minAvailableHours = twoHoursLater.getHours();

            return timeOptions.filter((option) => +option.id >= minAvailableHours);
        }

        return timeOptions;
    }, [minDate, selectedDate, timeOptions]);
};

export const useDateTimeLocalString = (selectedDate: Date | undefined, selectedTime?: number) =>
    useMemo(() => {
        if (!selectedDate || !selectedTime) return '';

        const date = moment(selectedDate);
        const dateTime = date.add(selectedTime, 'hours');

        return dateTime.format('DD MMM YYYY HH:mm');
    }, [selectedDate, selectedTime]);

export const useClearOnClose = (open: boolean, setCurrentStep: Dispatch<SetStateAction<Step>>) => {
    useEffect(() => {
        if (!open) {
            setCurrentStep(Step.Schedule);
        }
    }, [open, setCurrentStep]);
};

export const useOpenPopup = () => {
    const [isOpen, setOpen] = useRecoilState(schedulePaymentPopupOpenState);

    const toggleOpen = useCallback(
        (forceValue?: boolean) => {
            setOpen(typeof forceValue === 'boolean' ? forceValue : !isOpen);
        },
        [isOpen, setOpen]
    );

    return {
        isOpen,
        toggleOpen,
    };
};

export const usePopupActionButtonDisabled = (
    currentStep: Step,
    isLoading: boolean,
    selectedDate: Date | undefined,
    selectedTime?: number
) =>
    useMemo(() => {
        if (currentStep === Step.Schedule && (!selectedDate || typeof selectedTime !== 'number')) {
            return true;
        }

        return isLoading;
    }, [currentStep, isLoading, selectedDate, selectedTime]);

export const useProcessAirwallexSchedulePayment = () => {
    const dispatch = useDispatch();
    const { mutateAsync: schedulePayment, isLoading } = useAirwallexSchedulePayment();

    const processSchedulePaymentApiCall = useCallback(
        async ({ requestId, companyId, scheduledDate, oneTimePassword }: ProcessSchedulePaymentApiCallParams) => {
            const responseData = await schedulePayment({
                body: {
                    requestId,
                    companyId,
                    scheduledDate,
                    oneTimePassword,
                },
            });

            const { entities } = normalize<UseAirwallexSchedulePaymentResponse, stateTree.Entities>(
                responseData.Requests[0],
                schemas.requestSchema
            );

            dispatch(
                createAction(RELOAD_REQUEST_RESPONSE, {
                    entities,
                })
            );
        },
        [schedulePayment, dispatch]
    );

    return { processSchedulePaymentApiCall, isLoading };
};

export const useHandlePopupButtonClick = (
    currentStep: Step,
    companyId: Guid,
    requestId: Guid,
    selectedDate: Date | undefined,
    selectedTime: number | undefined,
    toggleOpen: (forceValue?: boolean) => void,
    setCurrentStep: Dispatch<SetStateAction<Step>>,
    processSchedulePaymentApiCall: (params: ProcessSchedulePaymentApiCallParams) => Promise<void>
) =>
    useCallback(
        async (code?: string) => {
            if (currentStep === Step.Schedule) {
                if (typeof selectedTime === 'undefined' || typeof selectedDate === 'undefined') {
                    return;
                }

                setCurrentStep(Step.TwoFa);
            } else {
                if (typeof selectedTime === 'undefined' || typeof selectedDate === 'undefined' || !code) {
                    return;
                }

                const date = dateTimeHelpers.convertLocalToUTC(selectedDate);

                date.setHours(selectedTime);

                try {
                    await processSchedulePaymentApiCall({
                        requestId,
                        companyId,
                        scheduledDate: date.toISOString(),
                        oneTimePassword: code,
                    });
                } catch (error) {
                    /**
                     * To forward only the E4002_WRONG_CONFIRMATION_CODE error to the 2fa wizard.
                     * For other errors, the default handler will be sufficient.
                     */
                    if (error.code === ErrorCode.E4002_WRONG_CONFIRMATION_CODE) {
                        throw error;
                    }
                }

                toggleOpen(false);
            }
        },
        [
            currentStep,
            setCurrentStep,
            selectedTime,
            selectedDate,
            processSchedulePaymentApiCall,
            requestId,
            companyId,
            toggleOpen,
        ]
    );

export const useHandleDatePickerChange = (
    setSelectedDate: Dispatch<SetStateAction<Date | undefined>>,
    minDate: Date,
    maxDate: Date
) =>
    useCallback(
        (date: Date | undefined) => {
            if (!date) {
                setSelectedDate(undefined);

                return;
            }

            const normalizedDate = moment(date).isBefore(minDate)
                ? minDate
                : moment(date).isAfter(maxDate)
                  ? maxDate
                  : date;

            setSelectedDate(normalizedDate);
        },
        [maxDate, minDate, setSelectedDate]
    );

export const useHandleTimeSelectChange = (setSelectedTime: Dispatch<SetStateAction<number | undefined>>) =>
    useCallback(
        (value?: string) => {
            if (typeof value === 'string') {
                setSelectedTime(+value);
            } else {
                setSelectedTime(undefined);
            }
        },
        [setSelectedTime]
    );
