import { mathService } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { isTotalLine } from 'pages/requestList/types';
import { useMemo } from 'react';

import {
    getUniqueSuppliers,
    groupAndSummarizeItemsBySupplier,
    sortSuppliers,
} from '../../../../../../xero/XeroAmaxPayBatchPaymentRequestCard/XeroAmaxPayBatchPaymentRequestCard.helpers';
import { AmaxPayPaymentItemWithId } from './PaymentSummaryPopupContent.types';

export const useAmounts = (items: domain.AmaxPayPaymentItem[]) => {
    return useMemo(() => {
        const totals = items.reduce(
            (acc, item) => {
                if (item.paymentStatus === domain.AmaxPayPaymentStatus.CancelledByUser) {
                    return acc;
                }

                acc.total = mathService.add(acc.total, item.amount);

                if (item.paymentStatus === domain.AmaxPayPaymentStatus.Paid) {
                    acc.totalPaid = mathService.add(acc.totalPaid, item.amount);
                }

                return acc;
            },
            {
                total: 0,
                totalPaid: 0,
            }
        );

        return {
            ...totals,
            remaining: mathService.subtract(totals.total, totals.totalPaid),
        };
    }, [items]);
};

export const useItems = (request: domain.XeroAmaxPayBatchPaymentRequest) => {
    const items = useMemo(() => {
        const allItems = request.details.items;

        const items = allItems.reduce<AmaxPayPaymentItemWithId[]>((acc, item) => {
            if (item.paymentStatus !== domain.AmaxPayPaymentStatus.CancelledByUser) {
                acc.push({
                    ...item,
                    id: item.xeroBillInvoiceRequestId,
                });
            }

            return acc;
        }, []);

        if (!request.details.isMergedBySupplier) {
            return items;
        }

        const suppliers = getUniqueSuppliers(items);
        const sortedSuppliers = sortSuppliers(suppliers);

        return groupAndSummarizeItemsBySupplier<AmaxPayPaymentItemWithId>(items, sortedSuppliers);
    }, [request]);

    const firstReadyToPayOfFailedItem = useMemo(() => {
        return items.find((item) => {
            if (request.details.isMergedBySupplier) {
                return (
                    isTotalLine(item) &&
                    (item.paymentStatus === domain.AmaxPayPaymentStatus.ReadyToPay ||
                        item.paymentStatus === domain.AmaxPayPaymentStatus.Failed)
                );
            }

            return (
                item.paymentStatus === domain.AmaxPayPaymentStatus.ReadyToPay ||
                item.paymentStatus === domain.AmaxPayPaymentStatus.Failed
            );
        });
    }, [items, request.details.isMergedBySupplier]);

    return { items, firstReadyToPayOfFailedItem };
};
