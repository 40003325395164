import './requestNoteSection.scss';

import { RichEditorView } from '@approvalmax/ui/src/components';
import React from 'react';
import bemFactory from 'react-bem-factory';
import { FormattedMessage } from 'react-intl';

const i18nPrefix = 'requestList/components/RequestNoteSection';

interface Props {
    className?: string;
    text: string;
}

class RequestNoteSection extends React.Component<Props> {
    public render() {
        const { className, text } = this.props;
        const bem = bemFactory.block('reql-request-note-section');
        const qa = bemFactory.block('reql-request-note-section');

        if (!text) {
            return null;
        }

        return (
            <div className={bem.add(className)()}>
                <div className={bem('field')}>
                    <div className={bem('label')}>
                        <FormattedMessage
                            id={`${i18nPrefix}.poNoteForApproversText`}
                            defaultMessage='Note for Approvers'
                        />
                    </div>

                    <RichEditorView html={text} dataQa={qa('value')} highlightUrls />
                </div>
            </div>
        );
    }
}

export default RequestNoteSection;
