import { MutateDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { UseResendQuoteForCustomerDecisionRequest } from './useResendQuoteForCustomerDecision.types';

export const useResendQuoteForCustomerDecision = () => {
    return useMutateLegacy<MutateDataParams<UseResendQuoteForCustomerDecisionRequest>>(
        requestsApiPaths.resendQuoteForCustomerDecision
    );
};
