import './lineItemsTable.scss';

import { FC, memo, ReactNode, useMemo } from 'react';
import bemFactory from 'react-bem-factory';

import { ColumnDefinition } from '../../selectors/types/ColumnDefinition';

const bem = bemFactory.block('reql-line-items-table');
const qa = bemFactory.qa('reql-line-items-table');

interface Cell {
    val?: string | null;
    cell: ReactNode;
    disabled: boolean;
    invalid: boolean;
    hideTitle?: boolean;
}

type Row = Cell[];

export interface LineItemsTableProps {
    className?: string;
    columnDefinitions: Array<ColumnDefinition<any>>;
    lineItems: any[];
    paddingTop?: 'small' | 'medium';
    emptyCellValue?: string;
}

export const LineItemsTable: FC<LineItemsTableProps> = memo((props) => {
    const { className, columnDefinitions, lineItems, paddingTop = 'medium', emptyCellValue } = props;

    const hasValueFlags = useMemo(
        () => columnDefinitions.map((colDef) => colDef.alwaysVisible || false),
        [columnDefinitions]
    );
    const rows = useMemo(
        () =>
            lineItems.map((li, lineItemIndex) =>
                columnDefinitions.map((colDef, i) => {
                    const val = colDef.value(li, lineItemIndex);
                    const disabled = !!colDef.getIsDisabled?.(li);
                    const invalid = !!colDef.getIsInvalid?.(li);
                    const isEmpty = emptyCellValue ? val === emptyCellValue : false;

                    if (val !== null && val !== '' && !isEmpty) {
                        hasValueFlags[i] = true;
                    }

                    return {
                        val,
                        cell: colDef.cell ? colDef.cell(val, li, disabled, invalid) : val,
                        disabled,
                        invalid,
                        hideTitle: colDef.hideTitle,
                    };
                })
            ),
        [columnDefinitions, emptyCellValue, hasValueFlags, lineItems]
    );
    const filteredColumnDefs = columnDefinitions.filter((colDef, i) => hasValueFlags[i]);
    const filteredRows = rows.map((r) => r.filter((v, i) => hasValueFlags[i]));

    const getRowIsDisabled = (row: Row) => {
        return row.every((cell) => cell.disabled);
    };

    const getRowIsInvalid = (row: Row) => {
        return row.every((cell) => cell.invalid);
    };

    return (
        <div className={bem.add(className)()} data-qa={qa()}>
            <table className={bem('table')}>
                <thead className={bem('table-head')} data-qa={qa('table-head')}>
                    <tr>
                        {filteredColumnDefs.map((colDef, colIndex) => (
                            <td
                                key={colDef.id}
                                className={bem('table-head-col', {
                                    required: colDef.required,
                                    'align-right': filteredColumnDefs[colIndex].alignRight,
                                    'padding-top-small': paddingTop === 'small',
                                })}
                                style={{
                                    width: colDef.width || 'auto',
                                    minWidth: colDef.minWidth || 'auto',
                                }}
                                data-qa={qa('table-head-col')}
                                data-qa-id={colDef.id}
                                data-qa-name={colDef.name}
                            >
                                {colDef.name}
                            </td>
                        ))}
                    </tr>
                </thead>

                <tbody>
                    {filteredRows.map((row, i) => (
                        <tr
                            key={i}
                            className={bem('table-row', {
                                disabled: getRowIsDisabled(row),
                                invalid: getRowIsInvalid(row),
                            })}
                            data-qa={qa('table-row')}
                        >
                            {row.map((cell, j) => (
                                <td
                                    key={j}
                                    title={(!cell.hideTitle && cell.val) || undefined}
                                    className={bem('table-cell', {
                                        'align-right': filteredColumnDefs[j].alignRight,
                                        disabled: cell.disabled,
                                        invalid: cell.invalid,
                                    })}
                                    data-qa={qa('table-cell')}
                                    data-qa-id={filteredColumnDefs[j].id}
                                    data-qa-name={filteredColumnDefs[j].name}
                                >
                                    {cell.cell}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
});

LineItemsTable.displayName = 'LineItemsTable';
