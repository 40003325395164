import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { integrationApiPaths } from '../paths';
import { UpdateXeroCounterpartyPaymentDetails } from './useUpdateXeroCounterpartyPaymentDetails.types';

export const useUpdateXeroCounterpartyPaymentDetails = (
    mutationOptions?: RequestMutationOptions<UpdateXeroCounterpartyPaymentDetails>
) => {
    return useMutate(integrationApiPaths.xeroCounterpartyPaymentDetail, {
        mutationOptions,
        method: 'put',
        apiVersion: 'v1',
    });
};
