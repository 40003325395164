import { createContext, useCallback, useEffect, useState } from 'react';

import { TabsContextValue, TabsValue } from './Tabs.types';

export const TabsContext = createContext<TabsContextValue>({
    value: 0,
    onChange: () => undefined,
});

export const useContextValues = (props: TabsContextValue) => {
    const { onChange, value: externalValue, ...restProps } = props;

    const [value, setValue] = useState(externalValue);

    useEffect(() => {
        setValue(externalValue);
    }, [externalValue]);

    const handleChange = useCallback(
        (key: TabsValue) => {
            setValue(key);

            onChange?.(key);
        },
        [onChange]
    );

    return {
        value,
        onChange: handleChange,
        ...restProps,
    };
};
