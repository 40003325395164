import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('requestList.containers.netsuite.NetSuiteRequestCard.NetSuiteCreditList', {
    sectionHeaderText: 'Credits applied',
    reference: 'Reference',
    appliedTo: 'Applied to',
    type: 'Type',
    dateDue: 'Date',
    currencyName: 'Currency',
    paymentAmount: 'Payment',
});
