import { ApiError, ErrorCode } from '@approvalmax/types';
import { Text, toast } from '@approvalmax/ui/src/components';
import { useMutation } from '@tanstack/react-query';
import { backend } from 'modules/data';
import { reloadRequestSync } from 'pages/requestList/actions';
import { TemplateRequestListFilter } from 'pages/requestList/config';
import { useSelectedFilter } from 'pages/requestList/hooks';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { api } from 'services/api';
import { getPath, Path } from 'urlBuilder';

import { messages } from './CompleteAirwallexPaymentPopup.messages';
import { Step, UsePayProps } from './CompleteAirwallexPaymentPopup.types';

export const usePay = (props: UsePayProps) => {
    const { request, onRequestClose, setCurrentStep } = props;
    const dispatch = useDispatch();

    const selectedFilter = useSelectedFilter();

    const pay = async (transfer: backend.transfers.RequestPayTransfer) => {
        const response = await api.requests.pay(transfer);
        const requests = response.Requests || [];

        dispatch(reloadRequestSync(transfer.requestId, requests));
    };

    const onSuccess = () => {
        onRequestClose();

        if (selectedFilter === TemplateRequestListFilter.AwaitingPayment) {
            toast.success(
                <>
                    <Text font='body'>{messages.successPay}</Text>

                    <Link
                        to={getPath(Path.request, request.id, request.companyId)}
                    >{`Go to ${request.displayName}`}</Link>
                </>
            );
        } else {
            toast.success(messages.successPay);
        }
    };

    const onError = (error: ApiError) => {
        if (error.code === ErrorCode.E7716_BENEFICIARY_WAS_EDITED_BEFORE_PAY) {
            setCurrentStep(Step.Discrepancies);
        } else {
            onRequestClose();
        }
    };

    return useMutation(pay, { onSuccess, onError });
};
