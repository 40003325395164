import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { GetQBooksRequestsDuplicates } from './useGetQBooksRequestsDuplicates.types';

export const useGetQBooksRequestsDuplicates = (
    params?: RequestParams<GetQBooksRequestsDuplicates>,
    queryOptions?: RequestQueryOptions<GetQBooksRequestsDuplicates>
) => {
    return useGet(requestsApiPaths.qBooksRequestsDuplicates, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetQBooksRequestsDuplicates['params']['query']>(
                {
                    integrationCode: params?.query?.integrationCode,
                    amount: params?.query?.amount,
                    currency: params?.query?.currency,
                    date: params?.query?.date,
                    vendorId: params?.query?.vendorId,
                },
                queryOptions
            ),
        },
        apiVersion: 'v2',
    });
};
