import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { font } from 'modules/styles';
import { FC, memo } from 'react';
import bemFactory from 'react-bem-factory';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';

const i18nPrefix = 'requestList/components/card/Toolbar/MyFinalDecisionBadge';

enum MyFinalDecisionBadgeMod {
    Approved = 'approved',
    Rejected = 'rejected',
    None = '',
}

const MyFinalDecisionBadgeContainer = styled.div<{ mod: MyFinalDecisionBadgeMod }>`
    flex: none;
    user-select: none;
    border: solid 2px #fff;
    ${font(13, '#000', 'bold')}
    text-transform: uppercase;
    padding: 6px 22px;

    ${(props) => {
        switch (props.mod) {
            case MyFinalDecisionBadgeMod.Approved:
                return css`
                    color: #4c8a5c;
                    border-color: #77aa84;
                `;

            case MyFinalDecisionBadgeMod.Rejected:
                return css`
                    color: #bd5942;
                    border-color: #bd5942;
                `;

            case MyFinalDecisionBadgeMod.None:
                return css``;
        }
    }}
`;

const qa = bemFactory.qa('reql-toolbar-final-decision-badget');

interface MyFinalDecisionBadgeProps {
    request: selectors.types.ExpandedRequest;
}

const MyFinalDecisionBadge: FC<MyFinalDecisionBadgeProps> = (props) => {
    const { request } = props;

    const myFinalDecision = request.myFinalDecision;

    if (!myFinalDecision || !request.flags.status.isOpen) {
        return null;
    }

    const approved = myFinalDecision === domain.RequestStepParticipantDecision.Approve;
    const rejected = myFinalDecision === domain.RequestStepParticipantDecision.Reject;

    const modifier: MyFinalDecisionBadgeMod = rejected
        ? MyFinalDecisionBadgeMod.Rejected
        : approved
          ? MyFinalDecisionBadgeMod.Approved
          : MyFinalDecisionBadgeMod.None;

    return (
        <MyFinalDecisionBadgeContainer mod={modifier} data-qa={qa('my-final-decision-badge')}>
            {approved ? (
                <FormattedMessage id={`${i18nPrefix}.myFinalDecisionApproved`} defaultMessage='Approved by me' />
            ) : (
                <FormattedMessage id={`${i18nPrefix}.myFinalDecisionRejected`} defaultMessage='Rejected by me' />
            )}
        </MyFinalDecisionBadgeContainer>
    );
};

export default memo(MyFinalDecisionBadge);
