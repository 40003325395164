import { lazy } from 'react';
import { Path } from 'urlBuilder';

export * from './resources/states';
export * from './resources/types';

export default {
    path: `/${Path.profileSetupSso}`,
    component: lazy(() => import(/* webpackChunkName: "manager-pages" */ './page')),
};
