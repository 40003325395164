import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestFormPage.SplitViewWrapper.OcrUploadPlaceholder', {
    uploadOcrButtonTitle: 'Upload via ApprovalMax Capture',
    uploadOcrButtonDescription:
        'ApprovalMax will capture file details and auto-populate the bill. No manual entry needed',
    uploadButtonTitle: 'Upload as regular attachment',
    uploadButtonDescription: 'Manually fill in the bill fields',
    filesDragMessage: 'Drag & drop to upload as files',
    fileToOcrDragMessage: 'Upload via ApprovalMax Capture',
});
