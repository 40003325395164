import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { profileApiPaths } from '../paths';
import { UpdateProfile } from './useUpdateProfile.types';

export const useUpdateProfile = (mutationOptions?: RequestMutationOptions<UpdateProfile>) => {
    return useMutate(profileApiPaths.updateProfile, {
        mutationOptions,
        apiVersion: 'v1',
    });
};
