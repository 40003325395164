import styled from 'styled-components';

const Badge = styled.span`
    height: 18px;
    padding: 1px 4px;
    font-size: 12px;
    text-transform: uppercase;
    border: 1px solid #000;
    border-radius: 2px;
`;

export const SuccessBadge = styled(Badge)`
    color: #fff;
    background-color: #77aa84;
    border-color: #77aa84;
`;

export const PendingBadge = styled(Badge)`
    color: #77aa84;
    border-color: #77aa84;
    background-color: #fff;
`;

export const FailureBadge = styled(Badge)`
    color: #a3341a;
    border-color: #f1bfb4;
    background-color: #fef1ee;
`;
