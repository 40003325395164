import { Mods, mods } from '@approvalmax/theme';
import { font, oDropdownPanel, Tooltip, TransparentButton } from '@approvalmax/ui';
import { StyledMods } from '@styled-kit/mods';
import { InfoIconGray } from 'modules/sprites';
import styled from 'styled-components';

export const Root = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px;
    width: fit-content;
`;

export const Panel = styled.div<StyledMods<{ fullWidth: boolean }>>`
    ${oDropdownPanel()}
    display: flex;
    flex-flow: column;
    padding: 15px;
    max-width: 300px;

    ${mods('fullWidth', true)`
        max-width: 700px;
    `};
`;

export const Line = styled.div<StyledMods<Mods<'hide'>>>`
    display: flex;
    align-items: center;
    margin-top: 4px;
    gap: 5px;

    ${mods.hide.true`
        display: none;
    `}
`;

export const Header = styled.div`
    margin-bottom: 5px;
    display: flex;
    align-items: center;
`;

export const HeaderStatusText = styled.span`
    ${font(12, '#000', 'semibold')}
    margin-left: 15px;
    line-height: 1.33;
`;

export const BankDetailsHeader = styled.div`
    display: flex;
    ${font(11, '#6c6a6a', 'semibold')}
    padding: 10px 0 5px;
    border-bottom: 1px solid #cacccd;
`;

export const BankDetailsContent = styled.div`
    display: flex;
    word-break: break-word;
`;

export const BankDetails = styled.div<StyledMods<{ fullWidth?: boolean }>>`
    width: 50%;
    padding-right: 5px;

    ${mods('fullWidth', true)`
        width: 100%;
    `};
`;

export const BankDetailsHeaderContent = styled.div`
    width: 50%;
`;

export const Label = styled.div`
    white-space: nowrap;
    ${font(12, '#5e5c5c', 'semibold')}
`;

export const StyledTooltip = styled(Tooltip)`
    overflow: hidden;
`;

export const Value = styled.div<StyledMods<Mods<'invalid' | 'active'>>>`
    white-space: nowrap;
    ${font(12, '#6c6a6a')}
    overflow: hidden;
    text-overflow: ellipsis;

    ${mods.invalid.true`
        ${font(12, '#a3341a', 'bold')}
    `}

    ${mods.active.true`
        ${font(12, '#000', 'bold')}
    `}
`;

export const IconButton = styled(TransparentButton)<StyledMods<{ forDropDown?: boolean }>>`
    ${mods('forDropDown', true)`
         position: absolute;
         z-index: 1;
         right: 5px;
         background: white;
         cursor: pointer;
         height: 18px;
    `};
`;

export const StyledInfoIconGray = styled(InfoIconGray)<StyledMods<Mods<'disabled'>>>`
    ${mods.disabled.true`
        opacity: 0.4;
        cursor: default;
    `}
`;

export const Gradient = styled.div`
    position: absolute;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 69%);
    right: 21px;
    top: 1px;
    bottom: 1px;
    width: 10px;
`;
