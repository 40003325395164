import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('requestList.containers.netsuite.NetSuiteRequestCard.NetSuiteApplyList', {
    sectionHeaderText: 'Applied to',
    item: 'Item',
    amountDue: 'Amount due',
    currencyName: 'Currency',
    dateDue: 'Date due',
    discountAmount: 'Discount taken',
    discountAvailable: 'Discount available',
    discountDate: 'Discount date',
    originalAmount: 'Original amount',
    paymentAmount: 'Payment',
    reference: 'Reference',
    type: 'Type',
    billItemText: 'Bill {transactionId}',
});
