import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.XeroMatchingV2Section.BillView', {
    changeMatchingButtonTitle: 'Change Matching',
    allocatedAmountToBilledPOs: 'Allocations to Billed POs',
    allocatedAmount: 'Allocated amount',
    remaining: 'Remaining',
    sectionHeaderText: 'Bill to PO Matching',
    matchWithPO: 'Match with PO',
    editMatching: 'Edit matching',
    noMatchedPO: 'This Bill is not currently matched with any Purchase Order.',
    showTable: 'Matched PO',
});
