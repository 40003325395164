import { Popup } from '@approvalmax/ui';
import { errorHelpers, intl } from '@approvalmax/utils';
import { domain, State } from 'modules/data';
import { FailedListIcon, ToastRejectedIcon } from 'modules/sprites';
import { font } from 'modules/styles';
import { FC, memo } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import LineItemFooterTotal from '../../../../../components/lineItemsSection/LineItemFooterTotal';
import { LineItemsTable } from '../../../../../components/lineItemsSection/LineItemsTable';
import { getActiveRequest } from '../../../../../selectors/pageSelectors';
import { getXeroLineItemsTableDef } from '../../../../../selectors/xeroRequestSelectors';
import { BudgetImpactsLineItem } from '../../XeroBudgetingSection.types';
import BreakDownsSection from './components/BreakDownsSection/BreakDownsSection';

const i18nPrefix =
    'requestList/containers/xero/XeroBudgetingV2Section/BudgetImpactDetailsPopup/BudgetImpactDetailsPopup';

const messages = defineMessages({
    popupTitle: {
        id: `${i18nPrefix}.popupTitle`,
        defaultMessage: 'Budget check for “{budgetName}” budget',
    },
    totalBudgetImpact: {
        id: `${i18nPrefix}.totalBudgetImpact`,
        defaultMessage: 'Total budget impact:',
    },
    warningOfExceed: {
        id: `${i18nPrefix}.warningOfExceed`,
        defaultMessage:
            'Please note that with these line items and respective line items of other Bills and Purchase Orders, the budget for the defined period is exceeded',
    },
    overbudgetText: {
        id: `${i18nPrefix}.overbudgetText`,
        defaultMessage:
            'Please note that with these line items and respective line items of other Bills (not counting Purchase Orders), the budget for the defined period is exceeded',
    },
});

const StyledDefaultContent = styled(Popup.DefaultContent)`
    min-width: 900px;
    min-height: 400px;
`;

const PopupContent = styled.div`
    padding: 0 60px 0 80px;
    overflow: auto;
`;

const PopupTitle = styled.div`
    font-size: 12px;
`;

const BoldText = styled.span`
    font-weight: bolder;
`;

const FooterTotal = styled(LineItemFooterTotal)`
    display: flex;
    justify-content: flex-end;
`;

const Warning = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 15px;
    margin-bottom: 15px;
    border: solid 1px #d6da92;
    background-color: #fff6e9;
`;

const WarningText = styled.span`
    margin-left: 10px;
    ${font(12, '#000')}
`;

const Overbudget = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 15px;
    margin-bottom: 15px;
    border: solid 1px #ba3e21;
    background-color: #fef1ee;
`;

const OverbudgetText = styled.span`
    margin-left: 10px;
    ${font(12, '#a3341a')}
`;

interface BudgetImpactDetailsPopupProps {
    data: BudgetImpactsLineItem;
    currency: string;
}

const BudgetImpactDetailsPopup: FC<BudgetImpactDetailsPopupProps> = (props) => {
    const {
        data: { detailsLineItems, budgetName, budgetPeriod, account, totalBudgetImpact, status },
        currency,
    } = props;

    const request = useSelector(getActiveRequest);
    const tableDef = useSelector((state: State) => getXeroLineItemsTableDef(state, request));

    let messageBlock;

    switch (status) {
        case domain.BudgetCheckStatus.Warning:
            messageBlock = (
                <Warning>
                    <ToastRejectedIcon width={20} height={20} />

                    <WarningText>{intl.formatMessage(messages.warningOfExceed)}</WarningText>
                </Warning>
            );
            break;

        case domain.BudgetCheckStatus.Overbudget:
            messageBlock = (
                <Overbudget>
                    <FailedListIcon width={20} height={20} />

                    <OverbudgetText>{intl.formatMessage(messages.overbudgetText)}</OverbudgetText>
                </Overbudget>
            );
            break;

        case domain.BudgetCheckStatus.Ok:
            messageBlock = null;
            break;

        default:
            throw errorHelpers.assertNever(status);
    }

    return (
        <StyledDefaultContent title={intl.formatMessage(messages.popupTitle, { budgetName })}>
            <PopupContent>
                {messageBlock}

                <PopupTitle>
                    <FormattedMessage
                        id={`${i18nPrefix}.description`}
                        defaultMessage='Please see below line items of this Bill that affect the {budgetName} budget, {account} account for {budgetPeriod} period.'
                        values={{
                            budgetName: <BoldText>“{budgetName}”</BoldText>,
                            account: <BoldText>“{account}”</BoldText>,
                            budgetPeriod: <BoldText>“{budgetPeriod}”</BoldText>,
                        }}
                    />
                </PopupTitle>

                <LineItemsTable columnDefinitions={tableDef} lineItems={detailsLineItems} />

                <FooterTotal
                    label={intl.formatMessage(messages.totalBudgetImpact)}
                    value={intl.formatCurrency(totalBudgetImpact, currency)}
                />

                <BreakDownsSection breakDowns={props.data.budgetBreakdowns} currency={currency} />
            </PopupContent>
        </StyledDefaultContent>
    );
};

export default memo(BudgetImpactDetailsPopup);
