import { domain } from 'modules/data';
import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { DomainEntitiesOcrOcrStatus, GetOcr, useGetOcr, useGetOcrStatus } from 'shared/data/v2';
import { checkOcrStatusTimeoutIdSelector, ocrIsLoadingState } from 'shared/states';

export const useCheckOcrStatus = (
    request?: domain.Request | null,
    attachmentId?: string,
    successCallback?: (data: GetOcr['response']) => void | Promise<void>,
    errorCallback?: (errorMessage?: string) => void
) => {
    const setCheckOcrStatusTimeoutId = useSetRecoilState(checkOcrStatusTimeoutIdSelector);
    const setOcrIsLoading = useSetRecoilState(ocrIsLoadingState);

    const {
        data: ocrStatus,
        refetch: refetchOcrStatus,
        isError,
        isFetched,
    } = useGetOcrStatus(
        {
            path: {
                companyId: request?.companyId,
                requestId: request?.id,
                attachmentId: attachmentId,
            },
        },
        {
            enabled: false,
        }
    );

    const { refetch: refetchOcr } = useGetOcr(
        {
            path: {
                companyId: request?.companyId,
                requestId: request?.id,
                attachmentId: attachmentId,
            },
        },
        {
            enabled: false,
        }
    );

    const checkOcrStatus = useCallback(
        async (initialDelay?: number) => {
            if (initialDelay) {
                const timeoutId = setTimeout(checkOcrStatus, initialDelay);

                setCheckOcrStatusTimeoutId(timeoutId);
            } else {
                setOcrIsLoading(true);
                setCheckOcrStatusTimeoutId(null);

                const { data: ocrStatus, isError } = await refetchOcrStatus();

                if (
                    !isError &&
                    ocrStatus?.status !== DomainEntitiesOcrOcrStatus.Succeeded &&
                    ocrStatus?.status !== DomainEntitiesOcrOcrStatus.Failed
                ) {
                    const timeoutId = setTimeout(checkOcrStatus, 2000);

                    setCheckOcrStatusTimeoutId(timeoutId);
                } else {
                    const { data } = await refetchOcr();

                    if (ocrStatus?.status === DomainEntitiesOcrOcrStatus.Succeeded && data) {
                        ocrStatus && successCallback && (await successCallback(data));
                    }

                    if (isError || (ocrStatus?.status === DomainEntitiesOcrOcrStatus.Failed && data)) {
                        errorCallback && errorCallback(data?.failureReason);
                    }

                    setOcrIsLoading(false);
                }
            }
        },
        [setCheckOcrStatusTimeoutId, setOcrIsLoading, refetchOcrStatus, refetchOcr, successCallback, errorCallback]
    );

    const isOcrAttachmentInProgress =
        !isError &&
        (ocrStatus?.status === DomainEntitiesOcrOcrStatus.InProgress ||
            ocrStatus?.status === DomainEntitiesOcrOcrStatus.Requested);

    return {
        checkOcrStatus,
        isOcrAttachmentInProgress,
        isFetched,
    };
};
