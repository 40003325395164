import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { GetXeroRequestsDuplicates } from './useGetXeroRequestsDuplicates.types';

export const useGetXeroRequestsDuplicates = (
    params?: RequestParams<GetXeroRequestsDuplicates>,
    queryOptions?: RequestQueryOptions<GetXeroRequestsDuplicates>
) => {
    return useGet(requestsApiPaths.xeroRequestsDuplicates, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetXeroRequestsDuplicates['params']['query']>(
                {
                    integrationCode: params?.query?.integrationCode,
                    date: params?.query?.date,
                    currency: params?.query?.currency,
                    amount: params?.query?.amount,
                    counterPartyId: params?.query?.counterPartyId,
                },
                queryOptions
            ),
        },
        apiVersion: 'v2',
    });
};
