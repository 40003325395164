import { backend, domain } from 'modules/data';
import { AddonPromotionType } from 'shared/states';

import { CompanyInfo } from './isPayOrCapturePromotionsAllowed.types';

export const isPayOrCapturePromotionsAllowed = (companyInfo: CompanyInfo, promotionType: AddonPromotionType) => {
    const hasLicenseFeature =
        promotionType === AddonPromotionType.Pay
            ? companyInfo.licenseFeatures.includes(domain.CompanyLicenseFeature.Pay)
            : companyInfo.licenseFeatures.includes(domain.CompanyLicenseFeature.Capture);
    const hasBetaFeature =
        promotionType === AddonPromotionType.Pay
            ? companyInfo.betaFeatures.includes(domain.CompanyBetaFeature.AddonPromoPay)
            : companyInfo.betaFeatures.includes(domain.CompanyBetaFeature.AddonPromoCapture);

    let hasCorrectCompanyPlan = false;

    if (companyInfo.integration?.integrationType === domain.IntegrationType.Xero) {
        hasCorrectCompanyPlan =
            companyInfo.licenseProductPlan === domain.CompanyPlan.Xero ||
            companyInfo.licenseProductPlan === domain.CompanyPlan.XeroAdvanced ||
            companyInfo.licenseProductPlan === domain.CompanyPlan.XeroPremium;
    } else if (companyInfo.integration?.integrationType === domain.IntegrationType.QBooks) {
        hasCorrectCompanyPlan =
            companyInfo.licenseProductPlan === domain.CompanyPlan.QBooks ||
            companyInfo.licenseProductPlan === domain.CompanyPlan.QBooksAdvanced ||
            companyInfo.licenseProductPlan === domain.CompanyPlan.QBooksPremium;
    }

    const isQboWithPay =
        companyInfo.integration?.integrationType === domain.IntegrationType.QBooks &&
        promotionType === AddonPromotionType.Pay;

    const allowedInCountry =
        promotionType === AddonPromotionType.Pay
            ? companyInfo?.integration?.countryCode === backend.OrganisationVersion.UK
            : true;

    return (
        companyInfo.allFeaturesTrialStatus !== domain.AllFeaturesTrialStatus.Active &&
        !hasLicenseFeature &&
        hasCorrectCompanyPlan &&
        hasBetaFeature &&
        allowedInCountry &&
        !isQboWithPay
    );
};
