import { useMemo } from 'react';

import { useLineCustomFieldsDefinition } from '../../NetSuiteRequestCard.hooks';
import { getColumnDefs } from './NetSuiteExpenses.config';
import { ExpensesFieldSettings, NetSuiteExpensesProps } from './NetSuiteExpenses.types';

export const useTableDefinitions = (
    expenseLines: NetSuiteExpensesProps['expenseLines'],
    companyCustomFields: NetSuiteExpensesProps['companyCustomFields'],
    integrationCode: NonNullable<NetSuiteExpensesProps['integrationCode']>,
    fieldSettings: ExpensesFieldSettings,
    exchangeRate?: number | null
) => {
    const customFieldsColumnDefinition = useLineCustomFieldsDefinition(expenseLines, companyCustomFields);

    const resultColumnDefinition = useMemo(
        () => [...getColumnDefs(integrationCode, fieldSettings, exchangeRate), ...customFieldsColumnDefinition],
        [customFieldsColumnDefinition, integrationCode, fieldSettings, exchangeRate]
    );

    return resultColumnDefinition;
};
