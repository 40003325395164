import { useQuery } from '@tanstack/react-query';
import { QueryKeys, selectors } from 'modules/common';
import { domain } from 'modules/data';
import { api } from 'services/api';

import { XeroMatchingBillMatchingData } from '../selectors/types/matchingTypes';
import { getXeroBillMatchingData } from '../utils/helpers';

export const useXeroBillMatchingData = (
    expandedRequest: selectors.types.ExpandedRequest,
    preloadedMatchingData?: XeroMatchingBillMatchingData
) => {
    const showMatchingData =
        expandedRequest.integrationCode === domain.IntegrationCode.XeroBill &&
        (expandedRequest.company.flags.isXeroMatchingV2 || expandedRequest.company.flags.isXeroMatchingV2ReadOnly) &&
        !preloadedMatchingData;

    const billMatchingData = useQuery(
        [QueryKeys.XERO_BILL_MATCHING_DATA, expandedRequest.id, expandedRequest.companyId],
        async () => {
            const response = await api.xeroMatching.getBillMatchingInfo({
                billId: expandedRequest.id,
                companyId: expandedRequest.companyId,
            });

            return getXeroBillMatchingData(response.data);
        },
        {
            refetchOnMount: 'always',
            enabled: showMatchingData,
        }
    );

    return { data: preloadedMatchingData ? preloadedMatchingData : billMatchingData.data };
};
