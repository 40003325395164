import { Avatar, Button, Flex, Form, Popup, Text, TextField } from '@approvalmax/ui/src/components';
import { errorHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { memo, useCallback } from 'react';
import bemFactory from 'react-bem-factory';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { closeAddCommentPopup, saveTemplate } from '../../actions';
import { AddCommentPopupPostAction } from '../../reducers/page/addCommentPopupReducer';
import { getActiveTemplate, getAddCommentPopup } from '../../selectors/pageSelectors';
import { commentName } from './AddCommentPopup.constants';
import { messages } from './AddCommentPopup.messages';
import { FormData } from './AddCommentPopup.types';

const qa = bemFactory.qa('reql-add-comment-popup');

const AddCommentPopup = memo(() => {
    const dispatch = useDispatch();
    const workflow = useSelector(getActiveTemplate)!;
    const addCommentPopup = useSelector(getAddCommentPopup);
    const profile = useSelector(selectors.profile.getProfileUser);

    const handleOpen = useCallback((open: boolean) => !open && dispatch(closeAddCommentPopup()), [dispatch]);

    const form = useForm<FormData>({
        values: {
            ...workflow,
            [commentName]: '',
        },
    });

    const onSubmit = form.handleSubmit((data) => {
        if (addCommentPopup.postAction === null) {
            throw errorHelpers.notSupportedError('PostAction can not be null');
        }

        switch (addCommentPopup.postAction) {
            case AddCommentPopupPostAction.Activate:
                dispatch(saveTemplate(data, { enabled: true, addCommentPopupPostAction: addCommentPopup.postAction }));
                break;

            case AddCommentPopupPostAction.Deactivate:
                dispatch(saveTemplate(data, { enabled: false, addCommentPopupPostAction: addCommentPopup.postAction }));
                break;

            case AddCommentPopupPostAction.Save:
            case AddCommentPopupPostAction.Update:
                dispatch(saveTemplate(data, { addCommentPopupPostAction: addCommentPopup.postAction }));
                break;

            default:
                throw errorHelpers.assertNever(addCommentPopup.postAction);
        }

        handleOpen(false);
    });

    return (
        <Popup open={addCommentPopup.isActive} onToggle={handleOpen} preventAutoClose>
            <Form form={form} onSubmit={onSubmit}>
                <Popup.Header
                    title={messages.popupTitle}
                    data-qa={qa()}
                    actions={
                        <Button
                            size='medium'
                            color='blue10'
                            type='submit'
                            title={messages.buttonHint}
                            data-qa={qa('done-button')}
                        >
                            {messages.buttonText}
                        </Button>
                    }
                />

                <Popup.Body>
                    <Text font='body' spacing='0 0 24'>
                        {messages.description({
                            br: <br />,
                        })}
                    </Text>

                    <Flex>
                        <Flex shrink={0}>
                            <Avatar
                                alt={`${profile.firstName} ${profile.lastName}`}
                                src={profile.avatar}
                                size={32}
                                color='blue10'
                            />
                        </Flex>

                        <Flex grow={1}>
                            <TextField
                                name={commentName}
                                multiline
                                maxLength={255}
                                initFocus
                                placeholder={messages.addCommentPlaceholder}
                                data-qa={qa('comment-editor')}
                            />
                        </Flex>
                    </Flex>
                </Popup.Body>
            </Form>
        </Popup>
    );
});

export default AddCommentPopup;
