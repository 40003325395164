import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.StepParticipantHover', {
    approver: 'Approver',
    reviewer: 'Reviewer',

    // title
    participantTitleWorkflowApprover: '{userName} added as an {role} to this step according to approval workflow rules',
    participantTitleDefaultApprover: '{userName} added as the default {role} to this step',
    participantTitleAddedManually: '{userName} added as an {role} to this step by {addedBy}',
    participantTitleAddedManuallyByMe: '{userName} added as an {role} to this step by me',
    participantTitleWorkflowApproverDelegate:
        '{userName} as a Substitute for {delegatedUser}, who was added to this step according to approval workflow rules',
    participantTitleMatchingApproverDelegate:
        '{userName} as a Substitute for {delegatedUser}, who was added to this step according to purchase order matching',
    participantTitleMatchingApprover: '{userName} added as an {role} to this step according to Purchase Order matching',
    participantTitleDefaultApproverDelegate:
        '{userName} as a Substitute for {delegatedUser}, who was added as default {role} to this step',
    participantTitleAddedManuallyDelegate:
        '{userName} as a Substitute for {delegatedUser}, who was added to this step by {addedBy}',
    participantTitleAddedManuallyByMeDelegate:
        '{userName} as a Substitute for {delegatedUser}, who was added to this step by me',

    // hover text
    participantHoverTextWorkflowApproverDelegate:
        'Added as a Substitute for {delegatedUser}, who was added to this step according to the approval workflow rules',
    participantHoverTextWorkflowApprover: 'Added as an {role} to this step according to approval workflow rules',
    participantHoverTextDefaultApproverDelegate:
        'Added as a Substitute for {delegatedUser}, who was added as the default {role} to this step',
    participantHoverTextDefaultApprover: 'Added as the default {role} to this step',
    participantHoverTextAddedManuallyByMeDelegate:
        'Added as a Substitute for {delegatedUser}, who was added to this step by me',
    participantHoverTextAddedManuallyByMe: 'Added as an {role} to this step by me',
    participantHoverTextAddedManuallyDelegate:
        'Added as a Substitute for {delegatedUser}, who was added to this step by {addedBy}',
    participantHoverTextAddedManually: 'Added as an {role} to this step by {addedBy}',
    participantHoverTextMatchingApproverDelegate:
        'Added as a Substitute for {delegatedUser}, who was added to this step according to purchase order matching',
    participantHoverTextMatchingApprover: 'Added as an {role} to this step according to Purchase Order matching',
});
