import { ExternalLink, TransparentButton } from '@approvalmax/ui';
import { actions, selectors } from 'modules/common';
import { BubbleCloseIcon } from 'modules/sprites';
import { font } from 'modules/styles';
import { XERO_MATCHING_V2_HELP_ITEM_ID } from 'pages/requestList/constants';
import { FC, memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { VelocityTransitionGroup } from 'velocity-react';

const i18nPrefix =
    'requestList/containers/xero/XeroMatchingV2Section/components/BillView/components/ChangeMatchingPopup/components/MatchingPopupLinks/MatchingPopupLinks';

const Container = styled.div`
    position: relative;
    margin-bottom: 20px;
    padding: 15px 60px 15px 80px;
    ${font(12)}
    background-color: #f5f5f5;
`;

const CloseBtnContainer = styled.div`
    position: absolute;
    top: 50%;
    right: 60px;
    transform: translateY(-50%);
`;

const CloseBtn = styled(TransparentButton)`
    ${font(12, '#384650')}
    fill: #777777;

    &:hover {
        fill: #d43f3a;
        color: #d43f3a;
    }
`;

const StyledVelocityTransitionGroup = styled(VelocityTransitionGroup)`
    display: block;

    &:empty {
        position: relative;
        top: -1px;
        border-bottom: 1px solid #caccce;
    }
`;

const XERO_MATCHING_V2_URL =
    'https://support.approvalmax.com/portal/en/kb/approvalmax-1/approvalmax-for-xero/bill-to-po-matching';
const XERO_MATCHING_V2_VIDEO_URL = 'https://youtu.be/lUZt963Qh0s';

const MatchingPopupLinks: FC = () => {
    const dispatch = useDispatch();

    const hiddenHelpItems = useSelector(selectors.userPreferences.getHiddenHelpItems);

    const showHelpSection = !hiddenHelpItems.includes(XERO_MATCHING_V2_HELP_ITEM_ID);

    const onHide = () => {
        dispatch(actions.updateHiddenHelpItem(XERO_MATCHING_V2_HELP_ITEM_ID, true));
    };

    return (
        <StyledVelocityTransitionGroup
            enter={{ animation: 'slideDown', duration: 300 }}
            leave={{ animation: 'slideUp', duration: 300 }}
        >
            {showHelpSection && (
                <Container>
                    <FormattedMessage
                        id={`${i18nPrefix}.text1`}
                        defaultMessage='ApprovalMax allows to manually match incoming Bills with the initial Purchase Orders. {learnMoreLink}'
                        values={{
                            learnMoreLink: (
                                <ExternalLink href={XERO_MATCHING_V2_URL!}>
                                    <FormattedMessage
                                        id={`${i18nPrefix}.learnMoreLinkText`}
                                        defaultMessage='Learn more'
                                    />
                                </ExternalLink>
                            ),
                        }}
                    />

                    <br />

                    <FormattedMessage
                        id={`${i18nPrefix}.text2`}
                        defaultMessage='See {videoLink} on How to match Bills with Purchase Orders'
                        values={{
                            videoLink: (
                                <ExternalLink href={XERO_MATCHING_V2_VIDEO_URL!}>
                                    <FormattedMessage id={`${i18nPrefix}.videoUrl`} defaultMessage='video' />
                                </ExternalLink>
                            ),
                        }}
                    />

                    <CloseBtnContainer>
                        <CloseBtn execute={onHide}>
                            <BubbleCloseIcon width={9} height={9} />
                        </CloseBtn>
                    </CloseBtnContainer>
                </Container>
            )}
        </StyledVelocityTransitionGroup>
    );
};

export default memo(MatchingPopupLinks);
