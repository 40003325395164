import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { GetRequestVersions } from './useGetRequestVersions.types';

export const useGetRequestVersions = (
    params?: RequestParams<GetRequestVersions>,
    queryOptions?: RequestQueryOptions<GetRequestVersions>
) => {
    return useGet(requestsApiPaths.versions, {
        params,
        queryOptions,
        apiVersion: 'v2',
    });
};
