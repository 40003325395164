import { atom } from 'recoil';
import { AddonPromotionType } from 'shared/states';

import { TryPayOrCapturePopupInfoState, TryPayOrCapturePopupOrigin } from './TryPayOrCapturePopupContent.types';

export const tryPayOrCapturePopupOpenState = atom({
    key: 'tryPayOrCapturePopupOpenState',
    default: false,
});

export const tryPayOrCapturePopupInfoState = atom<TryPayOrCapturePopupInfoState>({
    key: 'tryPayOrCapturePopupInfoState',
    default: { type: AddonPromotionType.Pay, origin: TryPayOrCapturePopupOrigin.ViewForm },
});

export const tryPayOrCapturePopupCloseCallbackState = atom<VoidFunction | null>({
    key: 'tryPayOrCapturePopupCloseCallbackState',
    default: null,
});
