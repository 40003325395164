import { Popup } from '@approvalmax/ui';
import { actions, selectors } from 'modules/common';
import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEvent } from 'react-use';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useIsSaving } from 'shared/hooks/useIsSaving';
import { hasChangesState } from 'shared/states';
import { unsavedChangesCallbackState } from 'shared/states/unsavedChangesCallback';

import { messages } from './UnsavedChangesModule.messages';

export const UnsavedChangesModule = memo(() => {
    const discardChangesPopup = useSelector(selectors.navigation.getDiscardChangesPopupData);
    const [hasChanges, setHasChanges] = useRecoilState(hasChangesState);
    const unsavedChangesCallback = useRecoilValue(unsavedChangesCallbackState);
    const isSaving = useIsSaving();

    const beforeUnloadHandler = useCallback(
        (event?: BeforeUnloadEvent) => {
            if (event && (hasChanges || isSaving)) {
                return (event.returnValue = '');
            }

            return undefined;
        },
        [isSaving, hasChanges]
    );

    const beforeUnloadHandlerAsync = useCallback(() => {
        setTimeout(beforeUnloadHandler, 0);
    }, [beforeUnloadHandler]);

    useEvent('beforeunload', beforeUnloadHandlerAsync);

    const dispatch = useDispatch();

    const continueEditing = async () => {
        dispatch(actions.cancelDiscardChangesPopup());

        if (unsavedChangesCallback) {
            await unsavedChangesCallback(false);
        }

        if (discardChangesPopup) {
            discardChangesPopup.unsavedChangesConfirmationCallback(false);
        }
    };

    const discardChanges = async () => {
        dispatch(actions.cancelDiscardChangesPopup());

        setHasChanges(false);

        if (unsavedChangesCallback) {
            await unsavedChangesCallback(true);
        }

        if (discardChangesPopup) {
            discardChangesPopup.unsavedChangesConfirmationCallback(true);
        }
    };

    return (
        <Popup isOpen={Boolean(discardChangesPopup)} onRequestClose={continueEditing}>
            <Popup.ConfirmationContent
                onConfirm={continueEditing}
                onCancel={discardChanges}
                title={messages.title}
                description={messages.description}
                confirmButtonText={messages.confirmButtonText}
                confirmButtonTitle={messages.confirmButtonTitle}
                cancelButtonText={messages.cancelButtonText}
                cancelButtonTitle={messages.cancelButtonTitle}
            />
        </Popup>
    );
});

UnsavedChangesModule.displayName = 'UnsavedChangesModule';
