import { Guid } from '@approvalmax/types';
import { RichEditorView } from '@approvalmax/ui/src/components';
import { intl, mathService, numberHelpers } from '@approvalmax/utils';
import { useQuery } from '@tanstack/react-query';
import { constants, QueryKeys } from 'modules/common';
import { backend, domain } from 'modules/data';
import { DoneIcon } from 'modules/sprites';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { api } from 'services/api';
import { GetNetSuiteExchangeRates, useGetNetSuiteExchangeRates } from 'shared/data/v1';
import { findDocumentFieldState } from 'shared/helpers';

import { ColumnDefinition } from '../../../selectors/types/ColumnDefinition';
import { LineItemCheckBoxValueWrapper } from './NetSuiteRequestCard.styles';
import { getCustomFieldValue, sortCustomFields } from './NetSuiteRequestCard.utils';

const { netSuiteConstants } = constants;

export const useNetSuiteCustomFields = (companyId: Guid) => {
    const { data, isInitialLoading } = useQuery([QueryKeys.NETSUITE_COMPANY_CUSTOM_FIELDS, companyId], () =>
        api.netSuite.selectCustomCompanyFields({ companyId })
    );

    return {
        data,
        isInitialLoading,
    };
};

export const useNetSuiteRequestNetAmount = (details: domain.NetSuiteRequestDetails) => {
    switch (details.integrationCode) {
        case domain.IntegrationCode.NetSuiteBill:
        case domain.IntegrationCode.NetSuitePO:
        case domain.IntegrationCode.NetSuiteVRA:
            return details.netAmount;

        case domain.IntegrationCode.NetSuiteExpenseReport:
        case domain.IntegrationCode.NetSuiteSalesOrder:
        case domain.IntegrationCode.NetSuiteJournalEntry:
        default:
            return null;
    }
};

export const useBillFieldSettings = (documentFields: domain.DocumentField[]) => {
    return {
        billingAddress: findDocumentFieldState(documentFields, 'BillingAddress'),
        reference: findDocumentFieldState(documentFields, 'Reference'),
        currency: findDocumentFieldState(documentFields, 'Currency'),
        exchangeRate: findDocumentFieldState(documentFields, 'OriginalExchangeRate'),
        terms: findDocumentFieldState(documentFields, 'Terms'),
        dueDate: findDocumentFieldState(documentFields, 'DueDate'),
        discountAmount: findDocumentFieldState(documentFields, 'DiscountAmount'),
        discountDate: findDocumentFieldState(documentFields, 'DiscountDate'),
        paymentHold: findDocumentFieldState(documentFields, 'IsPaymentHold'),
        memo: findDocumentFieldState(documentFields, 'Memo'),
        vatRegistration: findDocumentFieldState(documentFields, 'VatRegistrationNumber'),
        account: findDocumentFieldState(documentFields, 'Account'),
        department: findDocumentFieldState(documentFields, 'Department'),
        class: findDocumentFieldState(documentFields, 'Class'),
        location: findDocumentFieldState(documentFields, 'Location'),
    };
};

export const usePurchaseOrderFieldSettings = (documentFields: domain.DocumentField[]) => {
    return {
        billingAddress: findDocumentFieldState(documentFields, 'BillingAddress'),
        reference: findDocumentFieldState(documentFields, 'Reference'),
        currency: findDocumentFieldState(documentFields, 'Currency'),
        exchangeRate: findDocumentFieldState(documentFields, 'OriginalExchangeRate'),
        terms: findDocumentFieldState(documentFields, 'Terms'),
        dueDate: findDocumentFieldState(documentFields, 'DueDate'),
        employee: findDocumentFieldState(documentFields, 'Employee'),
        memo: findDocumentFieldState(documentFields, 'Memo'),
        vatRegistration: findDocumentFieldState(documentFields, 'VatRegistrationNumber'),
        department: findDocumentFieldState(documentFields, 'Department'),
        class: findDocumentFieldState(documentFields, 'Class'),
        location: findDocumentFieldState(documentFields, 'Location'),
        incoterm: findDocumentFieldState(documentFields, 'Incoterm'),
    };
};

export const getSalesOrderFieldSettings = (documentFields: domain.DocumentField[]) => {
    return {
        billingSchedule: findDocumentFieldState(documentFields, 'BillingSchedule'),
        class: findDocumentFieldState(documentFields, 'Class'),
        department: findDocumentFieldState(documentFields, 'Department'),
        discountItem: findDocumentFieldState(documentFields, 'DiscountItem'),
        discountRate: findDocumentFieldState(documentFields, 'DiscountRate'),
        endDate: findDocumentFieldState(documentFields, 'EndDate'),
        location: findDocumentFieldState(documentFields, 'Location'),
        memo: findDocumentFieldState(documentFields, 'Memo'),
        paymentReference: findDocumentFieldState(documentFields, 'PaymentReference'),
        startDate: findDocumentFieldState(documentFields, 'StartDate'),
        terms: findDocumentFieldState(documentFields, 'Terms'),
        transactionDate: findDocumentFieldState(documentFields, 'TransactionDate'),
        transactionNumber: findDocumentFieldState(documentFields, 'TransactionNumber'),
        vatRegistrationNumber: findDocumentFieldState(documentFields, 'VatRegistrationNumber'),
    };
};

export const useVRAFieldSettings = (documentFields: domain.DocumentField[]) => {
    return {
        vendor: findDocumentFieldState(documentFields, 'Vendor'),
        billingAddress: findDocumentFieldState(documentFields, 'BillingAddress'),
        transactionNumber: findDocumentFieldState(documentFields, 'TransactionNumber'),
        currency: findDocumentFieldState(documentFields, 'Currency'),
        exchangeRate: findDocumentFieldState(documentFields, 'OriginalExchangeRate'),
        transactionDate: findDocumentFieldState(documentFields, 'TransactionDate'),
        class: findDocumentFieldState(documentFields, 'Class'),
        department: findDocumentFieldState(documentFields, 'Department'),
        location: findDocumentFieldState(documentFields, 'Location'),
        memo: findDocumentFieldState(documentFields, 'Memo'),
    };
};

export const useExpensesFieldSettings = (documentFields: domain.DocumentField[]) => {
    return {
        category: findDocumentFieldState(documentFields, 'ExpensesCategory'),
        memo: findDocumentFieldState(documentFields, 'ExpensesMemo'),
        department: findDocumentFieldState(documentFields, 'ExpensesDepartment'),
        class: findDocumentFieldState(documentFields, 'ExpensesClass'),
        location: findDocumentFieldState(documentFields, 'ExpensesLocation'),
        customer: findDocumentFieldState(documentFields, 'ExpensesCustomer'),
        isBillable: findDocumentFieldState(documentFields, 'ExpensesIsBillable'),
        taxCode: findDocumentFieldState(documentFields, 'ExpensesTaxCode'),
        taxAmount: findDocumentFieldState(documentFields, 'ExpensesTaxRate'),
        taxRate: findDocumentFieldState(documentFields, 'ExpensesTaxAmount'),
        amortizationSchedule: findDocumentFieldState(documentFields, 'ExpensesAmortizationSchedule'),
        amortizationStartDate: findDocumentFieldState(documentFields, 'ExpensesAmortizationStartDate'),
        amortizationEndDate: findDocumentFieldState(documentFields, 'ExpensesAmortizationEndDate'),
        amortizationResidual: findDocumentFieldState(documentFields, 'ExpensesAmortizationResidual'),
    };
};

export const useItemsFieldSettings = (documentFields: domain.DocumentField[]) => {
    return {
        customer: findDocumentFieldState(documentFields, 'ItemsCustomer'),
        description: findDocumentFieldState(documentFields, 'ItemsDescription'),
        isBillable: findDocumentFieldState(documentFields, 'ItemsIsBillable'),
        vendorName: findDocumentFieldState(documentFields, 'ItemsVendorName'),
        location: findDocumentFieldState(documentFields, 'ItemsLocation'),
        class: findDocumentFieldState(documentFields, 'ItemsClass'),
        department: findDocumentFieldState(documentFields, 'ItemsDepartment'),
        units: findDocumentFieldState(documentFields, 'ItemsUnits'),
        expectedReceiptDate: findDocumentFieldState(documentFields, 'ItemsExpectedReceiptDate'),
        taxCode: findDocumentFieldState(documentFields, 'ItemsTaxCode'),
        taxAmount: findDocumentFieldState(documentFields, 'ItemsTaxAmount'),
        taxRate: findDocumentFieldState(documentFields, 'ItemsTaxRate'),
        isClosed: findDocumentFieldState(documentFields, 'ItemsIsClosed'),
        amortizationSchedule: findDocumentFieldState(documentFields, 'ItemsAmortizationSchedule'),
        amortizationStartDate: findDocumentFieldState(documentFields, 'ItemsAmortizationStartDate'),
        amortizationEndDate: findDocumentFieldState(documentFields, 'ItemsAmortizationEndDate'),
        amortizationResidual: findDocumentFieldState(documentFields, 'ItemsAmortizationResidual'),
    };
};

export const getSalesOrderItemsFieldSettings = (documentFields: domain.DocumentField[]) => {
    return {
        amount: findDocumentFieldState(documentFields, 'ItemsAmount'),
        class: findDocumentFieldState(documentFields, 'ItemsClass'),
        department: findDocumentFieldState(documentFields, 'ItemsDepartment'),
        description: findDocumentFieldState(documentFields, 'ItemsDescription'),
        excludeFromRateRequest: findDocumentFieldState(documentFields, 'ItemsExcludeFromRateRequest'),
        isClosed: findDocumentFieldState(documentFields, 'ItemsIsClosed'),
        item: findDocumentFieldState(documentFields, 'ItemsItem'),
        location: findDocumentFieldState(documentFields, 'ItemsLocation'),
        orderPriority: findDocumentFieldState(documentFields, 'ItemsOrderPriority'),
        priceLevel: findDocumentFieldState(documentFields, 'ItemsPriceLevel'),
        pst: findDocumentFieldState(documentFields, 'ItemsPST'),
        pstAmount: findDocumentFieldState(documentFields, 'ItemsPSTAmount'),
        quantity: findDocumentFieldState(documentFields, 'ItemsQuantity'),
        quantityBackOrdered: findDocumentFieldState(documentFields, 'ItemsQuantityBackOrdered'),
        quantityBilled: findDocumentFieldState(documentFields, 'ItemsQuantityBilled'),
        quantityCommitted: findDocumentFieldState(documentFields, 'ItemsQuantityCommitted'),
        quantityFulfilled: findDocumentFieldState(documentFields, 'ItemsQuantityFulfilled'),
        revenueRecognitionEndDate: findDocumentFieldState(documentFields, 'ItemsRevenueRecognitionEndDate'),
        revenueRecognitionSchedule: findDocumentFieldState(documentFields, 'ItemsRevenueRecognitionSchedule'),
        revenueRecognitionStartDate: findDocumentFieldState(documentFields, 'ItemsRevenueRecognitionStartDate'),
        serialNumbers: findDocumentFieldState(documentFields, 'ItemsSerialNumbers'),
        taxAmount: findDocumentFieldState(documentFields, 'ItemsTaxAmount'),
        taxCode: findDocumentFieldState(documentFields, 'ItemsTaxCode'),
        taxRate: findDocumentFieldState(documentFields, 'ItemsTaxRate'),
        unitPrice: findDocumentFieldState(documentFields, 'ItemsUnitPrice'),
        units: findDocumentFieldState(documentFields, 'ItemsUnits'),
    };
};

export const useBillPaymentFieldSettings = (documentFields: domain.DocumentField[]) => {
    return {
        amount: findDocumentFieldState(documentFields, 'Amount'),
        balance: findDocumentFieldState(documentFields, 'Balance'),
        payeeAddress: findDocumentFieldState(documentFields, 'PayeeAddress'),
        currency: findDocumentFieldState(documentFields, 'Currency'),
        exchangeRate: findDocumentFieldState(documentFields, 'ExchangeRate'),
        memo: findDocumentFieldState(documentFields, 'Memo'),
        account: findDocumentFieldState(documentFields, 'Account'),
        department: findDocumentFieldState(documentFields, 'Department'),
        class: findDocumentFieldState(documentFields, 'Class'),
        location: findDocumentFieldState(documentFields, 'Location'),
        transactionNumber: findDocumentFieldState(documentFields, 'TransactionNumber'),
        transactionDate: findDocumentFieldState(documentFields, 'TransactionDate'),
        checkNumber: findDocumentFieldState(documentFields, 'CheckNumber'),
        postingPeriod: findDocumentFieldState(documentFields, 'PostingPeriod'),
        toBePrinted: findDocumentFieldState(documentFields, 'ToBePrinted'),
        printVoucher: findDocumentFieldState(documentFields, 'PrintVoucher'),
    };
};

export const useInvoiceFieldSettings = (documentFields: domain.DocumentField[]) => {
    return {
        billingAddress: findDocumentFieldState(documentFields, 'BillingAddress'),
        transactionNumber: findDocumentFieldState(documentFields, 'TransactionNumber'),
        transactionReference: findDocumentFieldState(documentFields, 'TransactionReference'),
        vatRegistration: findDocumentFieldState(documentFields, 'VatRegistrationNumber'),
        terms: findDocumentFieldState(documentFields, 'Terms'),
        department: findDocumentFieldState(documentFields, 'Department'),
        class: findDocumentFieldState(documentFields, 'Class'),
        location: findDocumentFieldState(documentFields, 'Location'),
        memo: findDocumentFieldState(documentFields, 'Memo'),
        dueDate: findDocumentFieldState(documentFields, 'DueDate'),
        transactionDate: findDocumentFieldState(documentFields, 'TransactionDate'),
        postingPeriod: findDocumentFieldState(documentFields, 'PostingPeriod'),
        startDate: findDocumentFieldState(documentFields, 'StartDate'),
        endDate: findDocumentFieldState(documentFields, 'EndDate'),
        discountAmount: findDocumentFieldState(documentFields, 'DiscountAmount'),
        discountDate: findDocumentFieldState(documentFields, 'DiscountDate'),
        discountItem: findDocumentFieldState(documentFields, 'DiscountItem'),
        discountRate: findDocumentFieldState(documentFields, 'DiscountRate'),
        opportunity: findDocumentFieldState(documentFields, 'Opportunity'),
        excludeCommissions: findDocumentFieldState(documentFields, 'ExcludeCommissions'),
        partner: findDocumentFieldState(documentFields, 'Partner'),
        salesRep: findDocumentFieldState(documentFields, 'SalesRep'),
        leadSource: findDocumentFieldState(documentFields, 'LeadSource'),
        salesEffectiveDate: findDocumentFieldState(documentFields, 'SalesEffectiveDate'),
        shipDate: findDocumentFieldState(documentFields, 'ShipDate'),
        shipMethod: findDocumentFieldState(documentFields, 'ShipMethod'),
        shippingAddress: findDocumentFieldState(documentFields, 'ShippingAddress'),
    };
};

export const useInvoiceItemListFieldSettings = (documentFields: domain.DocumentField[]) => {
    return {
        item: findDocumentFieldState(documentFields, 'Item'),
        units: findDocumentFieldState(documentFields, 'Units'),
        priceLevel: findDocumentFieldState(documentFields, 'PriceLevel'),
        quantity: findDocumentFieldState(documentFields, 'Quantity'),
        description: findDocumentFieldState(documentFields, 'Description'),
        unitPrice: findDocumentFieldState(documentFields, 'UnitPrice'),
        amount: findDocumentFieldState(documentFields, 'Amount'),
        taxCode: findDocumentFieldState(documentFields, 'TaxCode'),
        taxRate: findDocumentFieldState(documentFields, 'TaxRate'),
        taxAmount: findDocumentFieldState(documentFields, 'TaxAmount'),
        grossAmount: findDocumentFieldState(documentFields, 'GrossAmount'),
        department: findDocumentFieldState(documentFields, 'Department'),
        class: findDocumentFieldState(documentFields, 'Class'),
        location: findDocumentFieldState(documentFields, 'Location'),
    };
};

export const useApplyListFieldSettings = (documentFields: domain.DocumentField[]) => {
    return {
        dateDue: findDocumentFieldState(documentFields, 'DateDue'),
        type: findDocumentFieldState(documentFields, 'Type'),
        reference: findDocumentFieldState(documentFields, 'Reference'),
        originalAmount: findDocumentFieldState(documentFields, 'OriginalAmount'),
        amountDue: findDocumentFieldState(documentFields, 'AmountDue'),
        currency: findDocumentFieldState(documentFields, 'CurrencyName'),
        discountDate: findDocumentFieldState(documentFields, 'DiscountDate'),
        discountAvailable: findDocumentFieldState(documentFields, 'DiscountAvailable'),
        discountAmount: findDocumentFieldState(documentFields, 'DiscountAmount'),
        paymentAmount: findDocumentFieldState(documentFields, 'PaymentAmount'),
        transactionId: findDocumentFieldState(documentFields, 'TransactionId'),
        amountOnApproval: findDocumentFieldState(documentFields, 'AmountOnApproval'),
    };
};

export const useCreditListFieldSettings = (documentFields: domain.DocumentField[]) => {
    return {
        reference: findDocumentFieldState(documentFields, 'Reference'),
        appliedTo: findDocumentFieldState(documentFields, 'AppliedTo'),
        type: findDocumentFieldState(documentFields, 'Type'),
        dateDue: findDocumentFieldState(documentFields, 'DateDue'),
        total: findDocumentFieldState(documentFields, 'Total'),
        transactionId: findDocumentFieldState(documentFields, 'TransactionId'),
        currency: findDocumentFieldState(documentFields, 'CurrencyName'),
        paymentAmount: findDocumentFieldState(documentFields, 'PaymentAmount'),
    };
};

export const useJournalEntryFieldSettings = (documentFields: domain.DocumentField[]) => {
    return {
        reference: findDocumentFieldState(documentFields, 'Reference'),
        transactionNumber: findDocumentFieldState(documentFields, 'TransactionNumber'),
        transactionDate: findDocumentFieldState(documentFields, 'TransactionDate'),
        reversalDate: findDocumentFieldState(documentFields, 'ReversalDate'),
        reversalDefer: findDocumentFieldState(documentFields, 'ReversalDefer'),
        memo: findDocumentFieldState(documentFields, 'Memo'),
        department: findDocumentFieldState(documentFields, 'Department'),
        class: findDocumentFieldState(documentFields, 'Class'),
        location: findDocumentFieldState(documentFields, 'Location'),
    };
};

export const useJournalEntryLinesFieldSettings = (documentFields: domain.DocumentField[]) => {
    return {
        taxCode: findDocumentFieldState(documentFields, 'TaxCode'),
        taxRate: findDocumentFieldState(documentFields, 'TaxRate'),
        taxAmount: findDocumentFieldState(documentFields, 'TaxAmount'),
        memo: findDocumentFieldState(documentFields, 'Memo'),
        department: findDocumentFieldState(documentFields, 'Department'),
        class: findDocumentFieldState(documentFields, 'Class'),
        location: findDocumentFieldState(documentFields, 'Location'),
        amortizationSchedule: findDocumentFieldState(documentFields, 'AmortizationSchedule'),
        amortizationStartDate: findDocumentFieldState(documentFields, 'AmortizationStartDate'),
        amortizationEndDate: findDocumentFieldState(documentFields, 'AmortizationEndDate'),
        amortizationResidual: findDocumentFieldState(documentFields, 'AmortizationResidual'),
    };
};

export const useLineCustomFieldsDefinition = <
    Line extends {
        customFields?: domain.NetSuiteCustomField[];
    },
>(
    itemLines: Line[],
    companyCustomFields: backend.SelectNetSuiteCustomCompanyFieldsAnswer['Fields']
) => {
    const usedCustomFields: {
        [scriptId: string]: domain.NetSuiteCustomField & {
            name: string;
        };
    } = {};

    itemLines.forEach((line) => {
        line.customFields?.forEach((field) => {
            if (!usedCustomFields[field.scriptId]) {
                const fieldReference = companyCustomFields.find(
                    (companyField) => companyField.NetSuiteField.Field.ScriptId === field.scriptId
                );

                if (fieldReference) {
                    usedCustomFields[field.scriptId] = {
                        ...field,
                        name: fieldReference.NetSuiteField.Field.Name,
                    };
                }
            }
        });
    });

    const customFieldsColumnDefinition: ColumnDefinition<Line>[] = [];

    sortCustomFields(Object.values(usedCustomFields), companyCustomFields).forEach((field) => {
        if (field.fieldType && netSuiteConstants.ignoredCustomFields.includes(field.fieldType)) {
            return;
        }

        customFieldsColumnDefinition.push({
            id: `itemLineCustomField-${field.scriptId}`,
            name: field.name,
            value: (li) => {
                const customField = li.customFields?.find((liField) => liField.scriptId === field.scriptId);

                if (!customField) {
                    return null;
                }

                const customFieldValue = getCustomFieldValue(customField, true);

                if (typeof customFieldValue === 'boolean') {
                    return customFieldValue === false ? null : 'true';
                }

                return customFieldValue;
            },
            cell: (value) => {
                if (field.fieldType === 'CheckBox') {
                    return value === 'true' ? (
                        <LineItemCheckBoxValueWrapper>
                            <DoneIcon width={13} height={10} />
                        </LineItemCheckBoxValueWrapper>
                    ) : null;
                }

                if (field.fieldType === 'RichText' || field.fieldType === 'Hyperlink') {
                    return <RichEditorView html={value || '-'} />;
                }

                return value;
            },
            minWidth: 100,
        });
    });

    return customFieldsColumnDefinition;
};

export const useDisplayCurrency = () => {
    const [displayCurrency, setDisplayCurrency] = useState<
        GetNetSuiteExchangeRates['response']['rates'][number] | null
    >(null);

    const onDisplayCurrencyChange = useCallback((value: GetNetSuiteExchangeRates['response']['rates'][number]) => {
        setDisplayCurrency(value);
    }, []);

    return {
        displayCurrency,
        onDisplayCurrencyChange,
    };
};

export const useGetExchangeInfo = (
    companyId: string,
    requestExchangeRate: number | null,
    displayCurrencyId?: string
) => {
    const { data: exchangeRatesData } = useGetNetSuiteExchangeRates(
        { query: { companyId, forDate: moment().startOf('day').toISOString() } },
        { refetchOnMount: false, enabled: false }
    );

    const exchangeRate = useMemo(() => {
        const selectedExchangeRate = exchangeRatesData?.rates.find(
            (exchangeRate) => exchangeRate.id === displayCurrencyId
        );

        return requestExchangeRate && selectedExchangeRate
            ? mathService.divide(requestExchangeRate, selectedExchangeRate.rateValue)
            : 1;
    }, [displayCurrencyId, exchangeRatesData?.rates, requestExchangeRate]);

    return {
        exchangeRate,
    };
};

export const useDiscountRate = (discountRate: number | null, isDiscountRateAbsolute: boolean | null) => {
    return useMemo(() => {
        if (!numberHelpers.isNumber(discountRate)) {
            return null;
        }

        const rate = intl.formatNumber(discountRate, 'auto');

        return isDiscountRateAbsolute ? rate : `${rate}%`;
    }, [discountRate, isDiscountRateAbsolute]);
};
