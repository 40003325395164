import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('modules.components.PushingInviteTeammates', {
    description: 'Invite your teammates to explore the platform together to {useCase}',
    invite: 'Invite',

    useCaseDefault: 'strengthen internal financial controls',

    useCaseStreamlineAuditProcesses: 'streamline audit processes',
    useCaseEstablishInternalFinancialControls: 'establish internal financial controls',
    useCaseAutomateApprovalWorkflows: 'automate approval workflows',
    useCaseSpeedupTheApprovalProcess: 'speed up the approval process',

    useCaseStreamlineAuditProcessesAndEstablishInternalFinancialControls:
        'streamline financial controls and audit processes',
    useCaseStreamlineAuditProcessesAndAutomateApprovalWorkflows: 'streamline financial controls and approvals',
    useCaseStreamlineAuditProcessesAndSpeedupTheApprovalProcess: 'speed up the approval and audit processes',
    useCaseEstablishInternalFinancialControlsAndAutomateApprovalWorkflows:
        'automate approvals and establish financial controls',
    useCaseEstablishInternalFinancialControlsAndSpeedupTheApprovalProcess:
        'speed up approvals and establish financial controls',
    useCaseAutomateApprovalWorkflowsAndSpeedupTheApprovalProcess: 'automate and speed up the approval process',

    useCaseStreamlineAuditProcessesAndEstablishInternalFinancialControlsAndAutomateApprovalWorkflows:
        'establish financial controls and streamline approvals and audits',
    useCaseStreamlineAuditProcessesAndEstablishInternalFinancialControlsAndSpeedupTheApprovalProcess:
        'establish financial controls, streamline audits, and automate approvals',
    useCaseStreamlineAuditProcessesAndAutomateApprovalWorkflowsAndSpeedupTheApprovalProcess:
        'streamline audits and speed up approvals with automation',
    useCaseEstablishInternalFinancialControlsAndAutomateApprovalWorkflowsAndSpeedupTheApprovalProcess:
        'establish financial controls and speed up approvals with automation',
});
