import './fileItem.scss';

import { LoadingSpinner, TransparentButton } from '@approvalmax/ui';
import { openSplitViewState } from '@approvalmax/ui/src/components';
import { DeleteIcon, EyeDocPreviewIcon, PaperClipIcon } from 'modules/sprites';
import { FC, memo } from 'react';
import bemFactory from 'react-bem-factory';
import { useRecoilValue } from 'recoil';
import { contentSplitViewState } from 'shared/states';

import { messages } from './FileItem.messages';
import { FileItemProps } from './FileItem.types';

const bem = bemFactory.block('reqf-file-item');
const qa = bemFactory.block('reqf-file-item');

const FileItem: FC<FileItemProps> = (props) => {
    const { fileName, fileId, loading, loadingText, disabled, readonly, onRemove, onOpen } = props;

    const contentSplitView = useRecoilValue(contentSplitViewState);
    const openSplitView = useRecoilValue(openSplitViewState);

    return (
        <div
            className={bem(null, {
                loading,
                active: openSplitView && 'attachmentId' in contentSplitView && contentSplitView.attachmentId === fileId,
            })}
            data-qa={qa()}
            data-qa-name={fileName}
            onClick={(e) => !disabled && !loading && onOpen(e.ctrlKey || e.altKey)}
        >
            {/* hover preview */}

            <div className={bem('preview-panel')}>
                <EyeDocPreviewIcon width={18} height={12} />
            </div>

            {/* loading */}

            <div className={bem('loading-panel')}>
                <LoadingSpinner size='small12' className={bem('loading-panel-spinner')} />

                <div className={bem('loading-panel-text')}>{loadingText || messages.loadingText}</div>
            </div>

            {/* regular elements */}

            <PaperClipIcon className={bem('paper-clip-icon')} width={16} height={15} />

            <div className={bem('name')}>{fileName}</div>

            {!readonly && !disabled && (
                <TransparentButton execute={onRemove} disabled={disabled} className={bem('delete-button')}>
                    <DeleteIcon width={10} height={10} />
                </TransparentButton>
            )}
        </div>
    );
};

export default memo(FileItem);
