import { domain } from 'modules/data';
import { atom } from 'recoil';

export type NetSuiteApplyListLineExpanded = domain.NetSuiteBillPaymentApplyListItem & {
    id: string;
    isEmpty: boolean;
};

export const netSuiteBillPaymentApplyListState = atom<NetSuiteApplyListLineExpanded[]>({
    key: 'netSuiteBillPaymentApplyListState',
    default: [],
});
