import { Text } from '@approvalmax/ui/src/components';
import { FC, memo } from 'react';

import { OutstandingCellProps } from './OutstandingCell.types';

export const OutstandingCell: FC<OutstandingCellProps> = memo((props) => {
    const { value, className } = props;

    const color = value?.days !== undefined && value?.days > 0 ? 'red100' : undefined;

    return (
        <Text font='body' ellipsis={1} color={color} className={className}>
            {value?.text}
        </Text>
    );
});

OutstandingCell.displayName = 'OutstandingCell';
