import { Command, Guid } from '@approvalmax/types';
import { errorHelpers } from '@approvalmax/utils';
import { selectors, statics } from 'modules/common';
import { backend, domain, State } from 'modules/data';
import { createSelector } from 'reselect';

import { AirwallexContext } from '../data/Airwallex/AirwallexContext';
import { NetSuiteContext } from '../data/netsuite/NetSuiteContext';
import { XeroContext } from '../data/xero/XeroContext';
import { Context } from '../reducers/page/contextReducer';
import { validateNetSuiteRequest } from './netsuite/validationSelectors';
import { getContext, getNewAttachments, getRequest } from './pageSelectors';
import { getInitialQBooksRequest, getQBooksRequestTransfer, validateQBooksRequest } from './qbooks';
import { getInitialStandaloneRequest, getStandaloneRequestTransfer, validateStandaloneRequest } from './standalone';
import { getInitialXeroRequest, getXeroRequestTransfer, validateXeroRequest } from './xero';

export function getInitialRequest(
    state: State,
    request: domain.Request,
    contexts: {
        context: Context;
        xeroContext?: XeroContext;
        qbooksContext?: domain.QBooksContext;
        netSuiteContext?: NetSuiteContext;
        airwallexContext?: AirwallexContext;
    }
): domain.Request {
    const integrationType = request.integrationType;

    switch (integrationType) {
        case domain.IntegrationType.Xero:
            switch (request.integrationCode) {
                case domain.IntegrationCode.XeroPo:
                case domain.IntegrationCode.XeroBill:
                case domain.IntegrationCode.XeroQuote:
                case domain.IntegrationCode.XeroInvoice:
                    return getInitialXeroRequest(state, request, contexts.xeroContext!);

                case domain.IntegrationCode.XeroContact:
                case domain.IntegrationCode.XeroCreditNotesPayable:
                case domain.IntegrationCode.XeroCreditNotesReceivable:
                case domain.IntegrationCode.XeroBillBatchPayment:
                case domain.IntegrationCode.XeroAirwallexBatchPayment:
                case domain.IntegrationCode.XeroAmaxPayBatchPayment:
                case domain.IntegrationCode.XeroManualJournal:
                    return request;

                default:
                    throw errorHelpers.notSupportedError(integrationType);
            }

        case domain.IntegrationType.QBooks:
            return getInitialQBooksRequest(request, contexts.qbooksContext!);

        case domain.IntegrationType.NetSuite:
            return request;

        case domain.IntegrationType.Dear:
            throw errorHelpers.notSupportedError();

        case domain.IntegrationType.None:
            return getInitialStandaloneRequest(state, contexts.context, request);

        default:
            throw errorHelpers.assertNever(integrationType);
    }
}

export function getEditCommands(state: State): {
    delete: Command;
    saveDraft: Command;
    saveAndSubmit: Command;
    saveAndResubmit: Command;
    saveAndApprove: Command;
    saveAndCompleteReview: Command;
    saveChanges: Command;
} {
    const request = getRequest(state);

    const editMode = getRequestEditMode(state, request);

    const isDraft = request.statusV2 === domain.RequestStatusV2.Draft;
    const loadingAttachments = getNewAttachments(state).length > 0;

    return {
        delete: {
            disabled: false,
            hidden: !isDraft,
        },
        saveDraft: {
            disabled: loadingAttachments,
            hidden: !isDraft,
        },
        saveAndSubmit: {
            disabled: loadingAttachments,
            hidden: !isDraft,
        },
        saveAndResubmit: {
            disabled: loadingAttachments,
            hidden:
                isDraft || (editMode !== RequestEditMode.Submitter && editMode !== RequestEditMode.ExternalSubmitter),
        },
        saveAndApprove: {
            disabled: loadingAttachments,
            hidden: editMode !== RequestEditMode.Editor && editMode !== RequestEditMode.Approver,
        },
        saveAndCompleteReview: {
            disabled: loadingAttachments,
            hidden: editMode !== RequestEditMode.Reviewer,
        },
        saveChanges: {
            disabled: loadingAttachments,
            hidden: editMode !== RequestEditMode.Reviewer,
        },
    };
}

export enum RequestEditMode {
    Submitter = 'Submitter',
    Editor = 'Editor',
    Reviewer = 'Reviewer',
    Approver = 'Approver',
    ExternalSubmitter = 'ExternalSubmitter',
}

export function getRequestEditMode(state: State, request: domain.Request) {
    const me = selectors.profile.getProfileUser(state).id;
    const isActiveReviewer = selectors.request.canReview(request, me);
    const company = selectors.company.findCompanyById(state, request.companyId);
    const activeStep = selectors.request.getActiveStep(request);
    const canEditAsApprover = company && selectors.request.getCanEditAsApprover(request, activeStep, company, me);

    if (request.origin === domain.RequestOrigin.ApprovalMax && request.authorId === me) {
        return RequestEditMode.Submitter;
    }

    if (
        [domain.RequestOrigin.PublicApi, domain.RequestOrigin.Email].includes(request.origin) &&
        request.authorId === me &&
        !isActiveReviewer
    ) {
        return RequestEditMode.ExternalSubmitter;
    }

    if (isActiveReviewer) {
        return RequestEditMode.Reviewer;
    }

    if (canEditAsApprover) {
        return RequestEditMode.Approver;
    }

    return RequestEditMode.Editor;
}

export function getRequestTransfer(
    state: State,
    request: domain.Request
): backend.Transfer<backend.transfers.RequestEditTransfer> {
    const integrationType = request.integrationType;

    switch (integrationType) {
        case domain.IntegrationType.Xero:
            return getXeroRequestTransfer(state, request);

        case domain.IntegrationType.QBooks:
            return getQBooksRequestTransfer(state, request);

        case domain.IntegrationType.NetSuite:
        case domain.IntegrationType.Dear:
            throw errorHelpers.notSupportedError();

        case domain.IntegrationType.None:
            return getStandaloneRequestTransfer(state, request);

        default:
            throw errorHelpers.assertNever(integrationType);
    }
}

export function validateRequest(state: State, request: domain.Request): string[] {
    const integrationType = request.integrationType;

    const sharedErrors: string[] = [];

    const { errorMessages } = request.requestNote;

    if (errorMessages) {
        sharedErrors.push(...errorMessages);
    }

    let integrationTypeErrors: string[] = [];

    switch (integrationType) {
        case domain.IntegrationType.Xero:
            integrationTypeErrors = validateXeroRequest(state, request);
            break;

        case domain.IntegrationType.QBooks:
            integrationTypeErrors = validateQBooksRequest(state, request);
            break;

        case domain.IntegrationType.NetSuite:
            integrationTypeErrors = validateNetSuiteRequest(state, request);
            break;

        case domain.IntegrationType.Dear:
            throw errorHelpers.notSupportedError();

        case domain.IntegrationType.None:
            return validateStandaloneRequest(state, request);

        default:
            throw errorHelpers.assertNever(integrationType);
    }

    return [...sharedErrors, ...integrationTypeErrors];
}

export const getRequiredFields: (
    state: State,
    request: domain.Request
) => {
    fieldIds: Guid[];
} = createSelector(
    (state: State) => getContext(state),
    (context) => {
        return {
            fieldIds: context.requiredFieldIds,
        };
    }
);

export const getReadonlyFields: (
    state: State,
    request: domain.Request
) => {
    fieldIds: Guid[];
} = createSelector(
    (state: State) => getContext(state),
    (context) => {
        return {
            fieldIds: context.readonlyFieldIds,
        };
    }
);

export function getAllRequestAttachments(state: State, request: domain.Request): domain.RequestAttachment[] {
    const integrationType = domain.getIntegrationTypeByCode(request.integrationCode);

    switch (integrationType) {
        case domain.IntegrationType.Xero:
            if (
                request.integrationCode === domain.IntegrationCode.XeroPo ||
                request.integrationCode === domain.IntegrationCode.XeroQuote ||
                request.integrationCode === domain.IntegrationCode.XeroInvoice
            ) {
                const poDetails = request.details;
                const supplierAttachments = (poDetails.emailToSupplier && poDetails.emailToSupplier.attachments) || [];

                return request.attachments.concat(supplierAttachments);
            }

            return request.attachments;

        case domain.IntegrationType.QBooks:
            if (request.integrationCode === domain.IntegrationCode.QBooksPo) {
                const poDetails = request.details;
                const vendorAttachments = (poDetails.emailToSupplier && poDetails.emailToSupplier.attachments) || [];

                return request.attachments.concat(vendorAttachments);
            }

            return request.attachments;

        case domain.IntegrationType.NetSuite: {
            if (request.integrationCode === domain.IntegrationCode.NetSuitePO) {
                const poDetails = request.details;
                const supplierAttachments = (poDetails.emailToSupplier && poDetails.emailToSupplier.attachments) || [];

                return request.attachments.concat(supplierAttachments);
            }

            return request.attachments;
        }

        case domain.IntegrationType.Dear:
            throw errorHelpers.notSupportedError();

        case domain.IntegrationType.None:
            return request.attachments;

        default:
            throw errorHelpers.assertNever(integrationType);
    }
}

export const getFieldsApprovalPermissionBySystemPurpose = (
    state: State,
    request: domain.Request,
    fieldSystemPurposes: domain.FieldSystemPurpose[]
): { required: boolean; disabled: boolean }[] => {
    const activeStep = selectors.request.getActiveStep(request);
    const editMode = getRequestEditMode(state, request);
    const me = selectors.profile.getProfileUser(state).id;
    const participant = selectors.request.getParticipantWithEditPermission(me, activeStep?.participants);

    if (editMode !== RequestEditMode.Approver) {
        return fieldSystemPurposes.map(() => ({ required: false, disabled: false }));
    }

    return fieldSystemPurposes.map((fieldSystemPurpose) => {
        const field = selectors.field.getFieldsBySystemPurpose(state, request.companyId, fieldSystemPurpose)[0];
        const permission = participant?.editPermissions.find((permission) => permission.fieldId === field?.id);

        return {
            required: Boolean(field?.id && activeStep?.editPermissionsRequiredFieldIds.includes(field.id)),
            disabled: !permission,
        };
    });
};

export const getFieldsApprovalPermissionById: (state: State, request: domain.Request, fieldIds: string[]) => any =
    createSelector(
        (state: State) => state.entities.fields,
        (state: State, request: domain.Request) => selectors.request.getActiveStep(request),
        (state: State, request: domain.Request) => getRequestEditMode(state, request),
        (state: State, request: domain.Request) => {
            const activeStep = selectors.request.getActiveStep(request);
            const me = selectors.profile.getProfileUser(state).id;

            return selectors.request.getParticipantWithEditPermission(me, activeStep?.participants);
        },
        (state: State, request: domain.Request, fieldIds: string[]) => fieldIds,

        (fields, activeStep, editMode, participant, fieldIds) => {
            if (editMode !== RequestEditMode.Approver) return {};

            if (!fields) {
                return null;
            }

            return fieldIds.reduce((acc, fieldId) => {
                const field = fields[fieldId] || null;
                const permission = participant?.editPermissions.find((permission) => permission.fieldId === field?.id);

                return {
                    ...acc,
                    [fieldId]: {
                        required: Boolean(field?.id && activeStep?.editPermissionsRequiredFieldIds.includes(field.id)),
                        disabled: !permission,
                    },
                };
            }, {});
        }
    );

export const getIsExchangeRateInputAvailable = (request: domain.Request) => {
    const selectedCurrency = statics.currency.findCurrencyByCode(request.currency);
    const defaultCurrency = statics.currency.findCurrencyByCode(request.companyCurrency);

    return defaultCurrency && selectedCurrency && defaultCurrency.id !== selectedCurrency.id;
};
