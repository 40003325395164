import { BudgetImpact as BudgetImpactRaw, BudgetImpactsAnswer } from '../../backend';
import { BudgetImpact } from '../Budget';

export const mapBudgetImpact = (budgetImpact: BudgetImpactRaw): BudgetImpact => {
    return {
        ...budgetImpact,
        account: {
            id: budgetImpact.account.Id,
            text: budgetImpact.account.Name,
        },
    };
};

export const mapBudgetImpactData = (data: BudgetImpactsAnswer) => {
    return {
        currency: data.data.currency,
        budgetImpacts: data.data.budgetImpacts.map(mapBudgetImpact),
    };
};
