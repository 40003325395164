import { Popup } from '@approvalmax/ui';
import { DatePicker, Flex, Select, Spacing, Text } from '@approvalmax/ui/src/components';
import { TwoFaActionWizard } from 'modules/components';
import { domain } from 'modules/data';
import { memo, useCallback, useState } from 'react';

import {
    useClearOnClose,
    useDateTimeLocalString,
    useFilteredTimeOptions,
    useGetAllowedDates,
    useGetDefaultDateTime,
    useHandleDatePickerChange,
    useHandlePopupButtonClick,
    useHandleTimeSelectChange,
    useOpenPopup,
    usePopupActionButtonDisabled,
    useProcessAirwallexSchedulePayment,
} from './SchedulePaymentPopup.hooks';
import { messages } from './SchedulePaymentPopup.messages';
import { Content } from './SchedulePaymentPopup.styles';
import { SchedulePaymentPopupProps, Step } from './SchedulePaymentPopup.types';

const SchedulePaymentPopup = memo<SchedulePaymentPopupProps>((props) => {
    const { request } = props;

    const [currentStep, setCurrentStep] = useState<Step>(Step.Schedule);
    const { isOpen, toggleOpen } = useOpenPopup();

    const scheduledPaymentDate =
        request.integrationCode === domain.IntegrationCode.XeroAirwallexBatchPayment
            ? request.details.scheduledPaymentDate
            : undefined;

    const { minDate, maxDate } = useGetAllowedDates();
    const { defaultDate, defaultSelectedHours } = useGetDefaultDateTime(minDate, scheduledPaymentDate);

    const [selectedTime, setSelectedTime] = useState<number | undefined>(defaultSelectedHours);
    const [selectedDate, setSelectedDate] = useState(defaultDate);
    const filteredTimeOptions = useFilteredTimeOptions(minDate, selectedDate);
    const dateTimeLocalString = useDateTimeLocalString(selectedDate, selectedTime);

    const { processSchedulePaymentApiCall, isLoading } = useProcessAirwallexSchedulePayment();

    const handlePopupButtonClick = useHandlePopupButtonClick(
        currentStep,
        request.companyId,
        request.id,
        selectedDate,
        selectedTime,
        toggleOpen,
        setCurrentStep,
        processSchedulePaymentApiCall
    );

    const popupActionButtonDisabled = usePopupActionButtonDisabled(currentStep, isLoading, selectedDate, selectedTime);

    useClearOnClose(isOpen, setCurrentStep);

    const handleDatePickerChange = useHandleDatePickerChange(setSelectedDate, minDate, maxDate);

    const handleTimeSelectChange = useHandleTimeSelectChange(setSelectedTime);

    const onRequestClose = useCallback(() => toggleOpen(false), [toggleOpen]);

    if (currentStep === Step.Schedule) {
        return (
            <Popup isOpen={isOpen} onRequestClose={onRequestClose} disableAutoClose>
                <Popup.DefaultContent
                    title={currentStep === Step.Schedule ? messages.titleSchedule : messages.titleTwoFA}
                    buttons={
                        <Popup.DefaultContent.Button
                            disabled={popupActionButtonDisabled}
                            execute={handlePopupButtonClick}
                        >
                            {scheduledPaymentDate ? messages.save : messages.create}
                        </Popup.DefaultContent.Button>
                    }
                >
                    <Content>
                        {currentStep === Step.Schedule && (
                            <div>
                                <Spacing height={8} />

                                <Text font='body'>{messages.description}</Text>

                                <Spacing height={8} />

                                <Flex container>
                                    <Flex size={12}>
                                        <DatePicker
                                            label={messages.paymentDate}
                                            placeholder={messages.placeholder}
                                            value={selectedDate}
                                            minDate={minDate}
                                            maxDate={maxDate}
                                            invalid={!selectedDate}
                                            onChange={handleDatePickerChange}
                                        />
                                    </Flex>

                                    <Flex size={12}>
                                        <Select
                                            label={messages.paymentTime}
                                            items={filteredTimeOptions}
                                            placeholder={messages.placeholder}
                                            value={selectedTime + ''}
                                            onChange={handleTimeSelectChange}
                                        />
                                    </Flex>
                                </Flex>
                            </div>
                        )}
                    </Content>
                </Popup.DefaultContent>
            </Popup>
        );
    }

    return (
        <TwoFaActionWizard
            title={messages.titleTwoFA}
            description={messages.twoFAToConfirm({ date: dateTimeLocalString, br: <br /> })}
            isOpen
            onEnterAppCode={handlePopupButtonClick}
            onClose={toggleOpen}
            disableAutoClose
        />
    );
});

SchedulePaymentPopup.displayName = 'SchedulePaymentPopup';

export default SchedulePaymentPopup;
