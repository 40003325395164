import { Button } from '@approvalmax/ui/src/components';
import styled from 'styled-components';

export const Root = styled.div`
    padding: 20px 20px 30px;
    border: 1px solid #dfdfdf;
`;

export const CloseButton = styled(Button)`
    margin-left: auto;
    margin-right: 4px;
`;
