import { findProfileUser } from 'modules/common/selectors/profileSelectors';
import { ExpandedRequest } from 'modules/common/selectors/types/Request';
import { domain } from 'modules/data';
import { RequestHistoryEventType, RequestStepParticipantDecision } from 'modules/data/domain/Request';
import { useSelector } from 'react-redux';
import { useGetDextTransaction } from 'shared/data/v2';

import { messages } from './ToolbarNotifications.messages';

const approveEvents = new Set([
    RequestHistoryEventType.ApproverApproved,
    RequestHistoryEventType.ApproverApprovedStepApproved,
]);

const resetEvents = new Set([
    RequestHistoryEventType.RequesterUpdatedWithReset,
    RequestHistoryEventType.RequesterResetTemplate,
    RequestHistoryEventType.UpdatedExternallyResetTemplate,
]);

export const usePreviouslyApprovedWarning = (request: ExpandedRequest) => {
    const me = useSelector(findProfileUser);

    if (!request.flags.isActiveApprover || !me) {
        return null;
    }

    // if approval founded in previously steps
    if (request.myDecisions.some((desicion) => desicion.decision === RequestStepParticipantDecision.Approve)) {
        return messages.approverApproved;
    }

    // search in history
    const myApproveIndex = request.history.findIndex(
        (historyEvent) => historyEvent.authorId === me.id && approveEvents.has(historyEvent.type)
    );

    if (myApproveIndex === -1) {
        return null;
    }

    let lastResetAfterApproveEventType = null;

    for (let i = request.history.length - 1; i > myApproveIndex; i--) {
        const historyEvent = request.history[i];

        if (resetEvents.has(historyEvent.type)) {
            lastResetAfterApproveEventType = historyEvent.type;
            break;
        }
    }

    if (!lastResetAfterApproveEventType) {
        return messages.approverApproved;
    }

    let message = null;

    switch (lastResetAfterApproveEventType) {
        case RequestHistoryEventType.RequesterUpdatedWithReset:
            message = messages.requesterUpdatedWithReset;
            break;

        case RequestHistoryEventType.RequesterResetTemplate:
            message = messages.requesterResetTemplate;
            break;

        case RequestHistoryEventType.UpdatedExternallyResetTemplate:
            message = messages.updatedExternallyResetTemplate;
            break;
    }

    return message;
};

export const useDextNewSupplierWarning = (request: ExpandedRequest) => {
    const { data: dextTransaction } = useGetDextTransaction(
        {
            path: {
                requestId: request.id,
                companyId: request.companyId,
            },
        },
        {
            enabled: request.origin === domain.RequestOrigin.ReceiptBank,
        }
    );

    return dextTransaction?.hasNewSupplier;
};
