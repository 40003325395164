import { RequestMutationOptions } from '@approvalmax/data';
import { routerHelpers } from '@approvalmax/utils';
import { useQueryClient } from '@tanstack/react-query';
import { useMutate } from 'shared/data';

import { budgetsApiPaths } from '../paths';
import { GetQBooksBudgets } from '../useGetQBooksBudgets/useGetQBooksBudgets.types';
import { UpdateQBooksBudget } from './useUpdateQBooksBudget.types';

export const useUpdateQBooksBudget = (mutationOptions?: RequestMutationOptions<UpdateQBooksBudget>) => {
    const queryClient = useQueryClient();

    return useMutate(budgetsApiPaths.qBooksBudget, {
        mutationOptions: {
            ...mutationOptions,
            onSuccess: (data, variables, context) => {
                const budgetListApiUrl = routerHelpers.pathToUrl(budgetsApiPaths.qBooksBudgets, {
                    companyId: variables.params?.path?.companyId,
                });

                queryClient.setQueryData([budgetListApiUrl], (current: GetQBooksBudgets['response']) => {
                    return {
                        ...current,
                        items: current.items.map((item) => {
                            if (item.budgetId === data.budgetId) {
                                return data;
                            }

                            return item;
                        }),
                    };
                });

                mutationOptions?.onSuccess?.(data, variables, context);
            },
        },
        method: 'patch',
        apiVersion: 'v2',
    });
};
