import { LoadingSpinner } from '@approvalmax/ui';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from 'modules/common';
import { DextPrepareLogoIcon } from 'modules/sprites';
import { font, uTransparentLink } from 'modules/styles';
import { FC, memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { api } from 'services/api';
import styled from 'styled-components';

const i18nPrefix = 'requestList/components/rbExternalUrl/RbExternalUrl';

interface RbExternalUrlProps {
    className?: string;
    qa?: string;
    requestId: string;
    companyId: string;
}

const Root = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

const RBLink = styled.a`
    display: flex;
    align-items: center;
    ${uTransparentLink}
`;

const RBText = styled.div`
    margin-left: 5px;
    margin-bottom: 2px;
    ${font(13, '#000')}
`;

const queryConfig = {
    staleTime: Infinity,
};

const RbExternalUrl: FC<RbExternalUrlProps> = (props) => {
    const { className, qa, requestId, companyId } = props;

    const fetchOriginDeepLink = () => api.requests.originDeepLink({ requestId, companyId });
    const queryId = [QueryKeys.ORIGINAL_DEEP_LINKS, requestId];
    const { data, isInitialLoading } = useQuery(queryId, fetchOriginDeepLink, queryConfig);

    if (isInitialLoading) {
        return <LoadingSpinner />;
    }

    if (!data) {
        return null;
    }

    return (
        <Root className={className}>
            <RBLink
                target='_blank'
                rel='noopener noreferrer'
                href={data.originDeepLink}
                data-qa={qa || 'rb-external-url-link'}
            >
                <DextPrepareLogoIcon width={69} height={16} />

                <RBText>
                    <FormattedMessage id={`${i18nPrefix}.openInRbLinkText`} defaultMessage='Open original document' />
                </RBText>
            </RBLink>
        </Root>
    );
};

export default memo(RbExternalUrl);
