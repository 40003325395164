import { CloseCircleFilledIcon } from '@approvalmax/ui';
import { Button, Flex } from '@approvalmax/ui/src/components';
import { domain } from 'modules/data';
import { FC, memo, useCallback } from 'react';
import { UserAvatar, UserCard } from 'shared/components';
import { useGetUserWithProfileInfo } from 'shared/hooks/useGetUserWithProfileInfo';

import { messages } from './StepUser.messages';
import { DescriptionText, RemoveButton, UserName, UserRoot } from './StepUser.styles';
import { StepUserProps } from './StepUser.types';

const StepUser: FC<StepUserProps> = memo((props) => {
    const { user, team, readonly, isCompanyMember, hideInviteButton, onInvite, onRemove, onOpenMatrix, tag } = props;

    const teamUser = team.find((u) => u.id === user.id);
    const userStatus = teamUser ? teamUser.status : domain.CompanyUserStatus.Active;
    const hasInviteButton = userStatus !== domain.CompanyUserStatus.Active && !hideInviteButton;
    const notActive = userStatus !== domain.CompanyUserStatus.Active;
    const name = (teamUser || user).displayName;
    const title = hasInviteButton ? messages.notInvitedUserTitle({ name }) : undefined;
    const rulesPreviewText = 'rulesPreviewText' in user && user.rulesPreviewText;
    const { getUserWithProfileInfo, loading } = useGetUserWithProfileInfo(teamUser);

    const handleRemove = useCallback(() => {
        if (user) {
            onRemove(user);
        }
    }, [onRemove, user]);

    const handleInvite = useCallback(() => {
        if (user) {
            onInvite(user);
        }
    }, [onInvite, user]);

    const handleOpenMatrix = useCallback(() => {
        if (isCompanyMember) {
            onOpenMatrix?.(user.id);
        }
    }, [isCompanyMember, onOpenMatrix, user.id]);

    const hasAdditionInfo = rulesPreviewText || tag || (!readonly && notActive);

    if (!user) {
        return null;
    }

    return (
        <UserRoot alignItems='stretch' spacing='8' wrap={false} width='100%'>
            <UserCard getUser={getUserWithProfileInfo} loading={loading}>
                <Flex alignItems='start'>
                    <Button color='transparent' noPadding uppercase={false} icon>
                        <UserAvatar size={28} user={teamUser || user} removed={!isCompanyMember} title={title} />
                    </Button>
                </Flex>
            </UserCard>

            <Flex direction='column' alignItems='start' justifyContent='center' spacing='4'>
                <Flex direction='column' alignItems='start' spacing='2'>
                    <UserCard getUser={getUserWithProfileInfo} loading={loading}>
                        <Button color='transparent' noPadding uppercase={false}>
                            <UserName
                                font='label'
                                fontSize='small'
                                fontWeight='medium'
                                $removed={!isCompanyMember}
                                color={notActive ? 'midnight70' : 'midnight100'}
                                title={user.userEmail}
                                ellipsis={1}
                            >
                                {name}
                            </UserName>
                        </Button>
                    </UserCard>

                    {rulesPreviewText && (
                        <Button
                            color='transparent'
                            outline
                            noPadding
                            size='xsmall'
                            uppercase={false}
                            onClick={handleOpenMatrix}
                            title={isCompanyMember ? messages.editRules : messages.editRulesRemovedUser}
                            disabled={readonly || !onOpenMatrix}
                        >
                            <DescriptionText font='body' fontSize='small' color='midnight70'>
                                {rulesPreviewText}
                            </DescriptionText>
                        </Button>
                    )}
                </Flex>

                {tag}

                {!readonly && notActive && (
                    <Button onClick={handleInvite} color='blue10' size='xsmall'>
                        {userStatus === domain.CompanyUserStatus.NotInvited ? messages.invite : messages.inviteAgain}
                    </Button>
                )}
            </Flex>

            {!readonly && (
                <Flex direction='column' justifyContent={hasAdditionInfo ? 'start' : 'center'}>
                    <RemoveButton
                        onClick={handleRemove}
                        size='small'
                        noPadding
                        startIcon={<CloseCircleFilledIcon size={12} color='midnight100' onClick={handleRemove} />}
                    />
                </Flex>
            )}
        </UserRoot>
    );
});

export default StepUser;
