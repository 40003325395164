import { LazyGetDataParams, RequestQueryOptions } from '@approvalmax/data';
import { PascalCaseToCamelCase } from '@approvalmax/types';
import { useGetLazy } from 'shared/data';

import { integrationApiPaths } from '../paths';
import { GetLazyNetSuiteEmployee } from './useGetLazyNetSuiteEmployee.types';

export const useGetLazyNetSuiteEmployee = (queryOptions?: RequestQueryOptions<GetLazyNetSuiteEmployee<true>>) => {
    return useGetLazy<
        LazyGetDataParams<{}, GetLazyNetSuiteEmployee['params']['query']>,
        PascalCaseToCamelCase<GetLazyNetSuiteEmployee['response']>
    >(integrationApiPaths.getNetSuiteEmployee, {
        queryOptions,
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};
