import { font } from 'modules/styles';
import styled from 'styled-components';

export const Main = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    max-width: 400px;
`;

export const BlockLine = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    text-align: right;
    justify-content: end;
`;

export const BlockLabel = styled.span`
    ${font(11, '#777d81', 'semibold')}
`;

export const BlockValue = styled.span`
    width: 180px;
    margin-left: 10px;
    text-align: right;
    ${font(13, '#000', 'semibold')}
`;

export const Separator = styled.div`
    width: 100%;
    height: 1px;
    background: #979797;
    margin-top: 10px;
    margin-bottom: 10px;
`;
