import { validators } from '@approvalmax/ui';
import { Flex, Label, mentionHelpers, Radio, RichEditor, Select, Text, toast } from '@approvalmax/ui/src/components';
import { MentionOptions } from '@tiptap/extension-mention';
import { constants, selectors } from 'modules/common';
import PlaceholderSuggestionList from 'modules/components/PlaceholderSuggestionList/PlaceholderSuggestionList';
import { domain } from 'modules/data';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { emailToSupplierHelpers } from 'shared/helpers';

import { DefaultCcOption } from '../../WorkflowSettingsPopupContent.types';
import { SettingsSection } from '../SettingsSection/SettingsSection';
import SettingsSubsection from '../SettingsSubsection/SettingsSubsection';
import {
    defaultLabels,
    defaultLegendItems,
    netSuitePoLegendItems,
    qBooksPoLabels,
    qBooksPoLegendItems,
    qBooksSalesInvoiceLabels,
    qBooksSalesInvoiceLegendItems,
    xeroQuoteLabels,
    xeroQuoteLegendItems,
    xeroSalesInvoiceLabels,
    xeroSalesInvoiceLegendItems,
} from './SectionEmailToSupplier.constants';
import { messages } from './SectionEmailToSupplier.messages';
import { LegendItem, SectionEmailToSupplierProps } from './SectionEmailToSupplier.types';

export const SectionEmailToSupplier: FC<SectionEmailToSupplierProps> = memo((props) => {
    const {
        template,
        supplierEmailSettings,
        links,
        onChangeStatus,
        onChangeCC,
        onChangeSubject,
        onChangeBody,
        readonly,
        qa,
    } = props;

    const isQbooks = domain.getIntegrationTypeByCode(template.integrationCode) === domain.IntegrationType.QBooks;

    let labels;
    let legendItems: LegendItem[];

    switch (template.integrationCode) {
        case domain.IntegrationCode.QBooksPo:
            labels = qBooksPoLabels;
            legendItems = qBooksPoLegendItems;
            break;

        case domain.IntegrationCode.QBooksInvoice:
            labels = qBooksSalesInvoiceLabels;
            legendItems = qBooksSalesInvoiceLegendItems;
            break;

        case domain.IntegrationCode.XeroQuote:
            labels = xeroQuoteLabels;
            legendItems = xeroQuoteLegendItems;
            break;

        case domain.IntegrationCode.XeroInvoice:
            labels = xeroSalesInvoiceLabels;
            legendItems = xeroSalesInvoiceLegendItems;
            break;

        case domain.IntegrationCode.NetSuitePO:
            labels = defaultLabels;
            legendItems = netSuitePoLegendItems;
            break;

        default:
            labels = defaultLabels;
            legendItems = defaultLegendItems;
            break;
    }

    const [localBody, setLocalBody] = useState(supplierEmailSettings?.body || '');
    const [localSubject, setLocalSubject] = useState(supplierEmailSettings?.subject || '');

    useEffect(() => {
        if (supplierEmailSettings?.body) {
            const newBody = emailToSupplierHelpers.addMentionExtensionTags(
                supplierEmailSettings.body,
                legendItems.map((item) => item.value)
            );

            setLocalBody(newBody);
        }
    }, [supplierEmailSettings?.body, legendItems]);

    useEffect(() => {
        if (supplierEmailSettings?.subject) {
            const newSubject = emailToSupplierHelpers.addMentionExtensionTags(
                supplierEmailSettings.subject,
                legendItems.map((item) => item.value)
            );

            setLocalSubject(newSubject);
        }
    }, [supplierEmailSettings?.subject, legendItems]);

    const ccValues: { id: string; text: string }[] = useMemo(
        () =>
            supplierEmailSettings?.cc?.map<DefaultCcOption>((email) => ({
                id: email,
                text: email,
            })) || [],
        [supplierEmailSettings?.cc]
    );

    const handleNewTagValidate = useCallback(
        (text: string | null) => {
            const isQboEmailValid =
                isQbooks && text && text.length <= constants.qBooksConstants.EMAIL_ADDRESS_MAX_LENGTH;
            const isXeroEmailValid =
                !isQbooks && text && text.length <= constants.xeroConstants.EMAIL_ADDRESS_MAX_LENGTH;

            return Boolean(validators.isEmail(text) && (isQboEmailValid || isXeroEmailValid));
        },
        [isQbooks]
    );

    const handleNewTagAppend = useCallback(
        (value: DefaultCcOption['id'][]) => {
            const valuesJoined = value.join(',');

            if (isQbooks && valuesJoined.length > constants.qBooksConstants.VENDOR_EMAIL_CC_MAX_LENGTH) {
                toast.error(
                    messages.qboEmailToVendorEmailCcLimitExceeded({
                        charactersNumber: constants.qBooksConstants.VENDOR_EMAIL_CC_MAX_LENGTH,
                    })
                );
            } else {
                onChangeCC(value);
            }
        },
        [isQbooks, onChangeCC]
    );

    const customMentionConfig = useMemo<Pick<MentionOptions, 'renderLabel' | 'suggestion'>>(
        () => ({
            renderLabel({ node }) {
                const name = node.attrs.placeholderName;

                return `[${name}]`;
            },
            suggestion: {
                ...mentionHelpers.createSuggestion({ mentionListComponent: PlaceholderSuggestionList }),
                char: '[',
                items: ({ query }: { query: string }) => {
                    return legendItems
                        .filter((item) => item.value.toLowerCase().includes(query.toLowerCase()))
                        .map((item) => ({
                            placeholderName: item.value,
                        }));
                },
            },
        }),
        [legendItems]
    );

    const emailAddressesForCC = useMemo(() => ccValues.map((v) => v.id), [ccValues]);

    const [isValidEmail, setIsValidEmail] = useState(false);

    const handleInputChange = useCallback(
        (value: string) => {
            const isValid = handleNewTagValidate(value);

            setIsValidEmail(isValid);
        },
        [handleNewTagValidate]
    );

    return (
        <SettingsSection title={labels.section} learnMoreLink={links[selectors.types.Link.EmailToSupplier]}>
            <SettingsSubsection subtitle={labels.title} bold>
                <Text font='body' fontSize='large' color='midnight100'>
                    {labels.description}
                </Text>

                <Radio.Group
                    disabled={readonly}
                    value={supplierEmailSettings?.state || domain.TemplateSettingsEmailToSupplierState.Enabled}
                    onChange={onChangeStatus}
                    name='emailToSupplier'
                    block
                    data-qa={qa('email-to-supplier')}
                >
                    <Radio value={domain.TemplateSettingsEmailToSupplierState.Enabled}>{messages.enabled}</Radio>

                    <Radio value={domain.TemplateSettingsEmailToSupplierState.EnabledAndActive}>
                        {messages.enabledAndChecked}
                    </Radio>

                    <Radio value={domain.TemplateSettingsEmailToSupplierState.Disabled}>{messages.disabled}</Radio>
                </Radio.Group>
            </SettingsSubsection>

            <SettingsSubsection subtitle={messages.defaultCCTitle} bold>
                <Text font='body' fontSize='large' color='midnight100'>
                    {messages.defaultCCDescription}
                </Text>

                <Select
                    placeholder={messages.emailAddressesForCCOptionPlaceholder}
                    disabled={readonly}
                    multiple
                    value={emailAddressesForCC}
                    items={ccValues}
                    itemNameKey='text'
                    autocomplete
                    hint={messages.emailAddressesForCCOptionEmptyListPlaceholder}
                    creatable={isValidEmail}
                    hideNoData={isValidEmail}
                    onChange={handleNewTagAppend}
                    onInputChange={handleInputChange}
                />
            </SettingsSubsection>

            <SettingsSubsection subtitle={messages.templateTitle} bold>
                <Text font='body' fontSize='large' color='midnight100'>
                    {labels.templateDescription}
                </Text>

                <Text font='body' fontSize='large' color='midnight100'>
                    {messages.templateInstruction}
                </Text>

                <Flex spacing='8'>
                    {legendItems.map((item) => (
                        <Flex spacing='4' width='100%' alignItems='center' key={item.value}>
                            <Text font='label' fontSize='large' color='midnight100' fontWeight='medium'>
                                [{item.value}]
                            </Text>

                            <Text font='label' fontSize='large' color='midnight70'>
                                —
                            </Text>

                            <Text font='label' fontSize='large' color='midnight70'>
                                {item.description}
                            </Text>
                        </Flex>
                    ))}
                </Flex>

                <div>
                    <Label>{messages.templateSubject}</Label>

                    <RichEditor
                        placeholder={messages.templateSubjectPlaceholder}
                        value={localSubject}
                        onChange={onChangeSubject}
                        maxLength={constants.commonConstants.EMAIL_TO_SUPPLIER_SUBJECT_MAX_LENGTH}
                        readOnly={readonly}
                        allowMention
                        customMentionConfig={customMentionConfig}
                        allowTextFormatting={false}
                        restrictNewLine
                        minHeight={30}
                    />
                </div>

                <div>
                    <Label>{messages.templateBody}</Label>

                    <RichEditor
                        placeholder={messages.templateBodyPlaceholder}
                        value={localBody}
                        onChange={onChangeBody}
                        maxLength={constants.commonConstants.EMAIL_TO_SUPPLIER_BODY_MAX_LENGTH}
                        readOnly={readonly}
                        allowMention
                        customMentionConfig={customMentionConfig}
                    />
                </div>
            </SettingsSubsection>
        </SettingsSection>
    );
});

SectionEmailToSupplier.displayName = 'SectionEmailToSupplier';
