import { CompanyPlans, TrialFeature } from '@approvalmax/ui/src/components/complex';
import { ExpandedCompany } from 'modules/common/selectors/types';
import { domain } from 'modules/data';
import { StartTrialPopupOrigin } from 'modules/profile/actions';

import { plansMapper } from './StartTrialPeriodPopup.constants';

export const getInitialFeatureByOrigin = (company?: ExpandedCompany, origin?: StartTrialPopupOrigin) => {
    if (!company) {
        return undefined;
    }

    const integrationType = company.integration?.integrationType;

    if (integrationType === domain.IntegrationType.Xero) {
        const isAdvanced = company.licenseProductPlan === domain.CompanyPlan.XeroAdvanced;

        switch (origin) {
            case 'auto_approvals_wf_builder':
                if (!isAdvanced) {
                    return TrialFeature.XeroAdvancedAutoApprovals;
                }

                break;

            case 'stand-alone_wf':
                return TrialFeature.XeroPremiumStandAloneWorkflows;

            case 'contact_approvals_wf':
                if (!isAdvanced) {
                    return TrialFeature.XeroAdvancedSupplierOnboarding;
                }

                break;

            case 'batch_payments_wf':
                return TrialFeature.XeroPremiumBatchPaymentApprovals;

            case 'quote_worflow_wf':
                if (!isAdvanced) {
                    return TrialFeature.XeroAdvancedSalesQuoteApprovals;
                }

                break;

            case 'manual_journal_wf':
                if (!isAdvanced) {
                    return TrialFeature.XeroAdvancedManualJournalApprovals;
                }

                break;

            case 'versionshistory_wfbuilder':
                if (!isAdvanced) {
                    return TrialFeature.XeroAdvancedWorkflowVersionHistory;
                }

                break;

            case 'po_matching_requestcard':
                if (!isAdvanced) {
                    return TrialFeature.XeroAdvancedBillToPOMatching;
                }

                break;

            case 'budgetchecking_requestcard':
                if (!isAdvanced) {
                    return TrialFeature.XeroAdvancedBudgetChecking;
                }

                break;

            case 'GRN_requestcard':
            case 'GRN_settings':
                if (!isAdvanced) {
                    return TrialFeature.XeroAdvancedGoodsReceivedNotes;
                }

                break;

            case 'po_matching_settings':
                if (!isAdvanced) {
                    return TrialFeature.XeroAdvancedBillToPOMatching;
                }

                break;

            case domain.IntegrationCode.XeroAirwallexBatchPayment:
                return TrialFeature.XeroPremiumPaymentsWithAirwallex;

            case 'accrual_reports_popup':
                if (!isAdvanced) {
                    return TrialFeature.XeroAdvancedAccrualReports;
                }

                break;

            case 'amax_pay_batch_payments_wf':
                return TrialFeature.AddonsPay;

            default:
                return undefined;
        }
    }

    if (integrationType === domain.IntegrationType.QBooks) {
        const isAdvanced = company.licenseProductPlan === domain.CompanyPlan.QBooksAdvanced;

        switch (origin) {
            case 'auto_approvals_wf_builder':
                if (!isAdvanced) {
                    return TrialFeature.QboAdvancedAutoApprovals;
                }

                break;

            case 'stand-alone_wf':
                return TrialFeature.QboPremiumStandAloneWorkflows;

            case 'vendor_approvals_wf':
                if (!isAdvanced) {
                    return TrialFeature.QboAdvancedVendorOnboarding;
                }

                break;

            case 'journal_entry_wf':
                if (!isAdvanced) {
                    return TrialFeature.QboAdvancedJournalEntryApprovals;
                }

                break;

            case 'versionshistory_wfbuilder':
                if (!isAdvanced) {
                    return TrialFeature.QboAdvancedWorkflowVersionHistory;
                }

                break;

            case 'po_matching_requestcard':
                if (!isAdvanced) {
                    return TrialFeature.QboAdvancedBillToPOMatching;
                }

                break;

            case 'GRN_requestcard':
            case 'GRN_settings':
                if (!isAdvanced) {
                    return TrialFeature.QboAdvancedGoodsReceivedNotes;
                }

                break;

            case 'po_matching_settings':
                if (!isAdvanced) {
                    return TrialFeature.QboAdvancedBillToPOMatching;
                }

                break;

            default:
                return undefined;
        }
    }

    return undefined;
};

export const getPlanType = (plan?: domain.CompanyPlan): CompanyPlans => {
    if (!plan) return 'standard';

    return plansMapper[plan] || 'standard';
};
