import { Reference } from '@approvalmax/types';
import { selectors } from 'modules/common';
import { domain, schemas } from 'modules/data';
import moment from 'moment';

import { messages } from './XeroBudgetingSection.messages';
import { BudgetBreakdown, BudgetImpactsLineItem, BudgetsImpacts, TrackingCategory } from './XeroBudgetingSection.types';

export const getShowBudgeting = (request: selectors.types.ExpandedRequest) => {
    const supported =
        (request.integrationCode === domain.IntegrationCode.XeroPo ||
            request.integrationCode === domain.IntegrationCode.XeroBill) &&
        (request.statusV2 === domain.RequestStatusV2.OnApproval || request.statusV2 === domain.RequestStatusV2.OnHold);

    return supported;
};

const getDetailsLineItems = (
    lineItems: domain.BudgetImpact['lineItems']
): {
    detailsLineItems: domain.XeroLineItem[];
    totalAmountOfBillsAndPO: number;
} => {
    let totalAmountOfBillsAndPO = 0;

    const detailsLineItems: domain.XeroLineItem[] = [];

    lineItems.forEach((lineItem) => {
        totalAmountOfBillsAndPO += lineItem.Amount || 0;
        detailsLineItems.push(schemas.request.mapLineItem(lineItem));
    });

    return {
        detailsLineItems,
        totalAmountOfBillsAndPO,
    };
};

const getBudgetBreakdowns = (budgetBreakdowns: domain.BudgetImpact['budgetBreakdowns']): BudgetBreakdown[] => {
    return budgetBreakdowns.map((budgetBreakdown) => ({
        planned: budgetBreakdown.budgetAmounts.plannedAmount,
        billsApproved: budgetBreakdown.budgetAmounts.billApprovedAmount,
        billsOpen: budgetBreakdown.budgetAmounts.billSubmittedAmount,
        purchaseOrdersApproved: budgetBreakdown.budgetAmounts.poApprovedAmount,
        purchaseOrdersOpen: budgetBreakdown.budgetAmounts.poSubmittedAmount,
        remaining: budgetBreakdown.budgetAmounts.remainingAmount,
        sectionName: moment.utc(budgetBreakdown.monthOfYear.asDateTime).format('MMM YY'),
        period: moment.utc(budgetBreakdown.monthOfYear.asDateTime).format('MMM YYYY'),
    }));
};

export const getBudgetImpactsData = (budgetImpacts: domain.BudgetImpact[], currency: string): BudgetsImpacts => {
    const lineItems: BudgetImpactsLineItem[] = [];
    const allCategories: Reference[] = [];

    budgetImpacts.forEach((budget) => {
        const trackingCategories: TrackingCategory[] = [];

        budget.tracking.forEach((t) => {
            trackingCategories.push({
                category: {
                    id: t.CategoryId,
                    text: t.CategoryName,
                },
                value: {
                    id: t.OptionId,
                    text: t.OptionName,
                },
            });

            if (!allCategories.some((category) => category.id === t.CategoryId)) {
                allCategories.push({
                    id: t.CategoryId,
                    text: t.CategoryName,
                });
            }
        });

        const { detailsLineItems, totalAmountOfBillsAndPO } = getDetailsLineItems(budget.lineItems);
        const budgetBreakdowns = getBudgetBreakdowns(budget.budgetBreakdowns);

        if (budgetBreakdowns.length > 1) {
            budgetBreakdowns.unshift({
                // INFO: The first and static item in the BreakDownsSection
                planned: budget.budgetAmounts.plannedAmount,
                billsApproved: budget.budgetAmounts.billApprovedAmount,
                billsOpen: budget.budgetAmounts.billSubmittedAmount,
                purchaseOrdersApproved: budget.budgetAmounts.poApprovedAmount,
                purchaseOrdersOpen: budget.budgetAmounts.poSubmittedAmount,
                remaining: budget.budgetAmounts.remainingAmount,
                sectionName: messages.fullPeriod,
                period: `${moment.utc(budget.budgetPeriodStart).format('ll')} - ${moment
                    .utc(budget.budgetPeriodEnd)
                    .format('ll')}`,
            });
        }

        if (
            budget.budgetCheckPeriod === domain.BudgetCheckPeriod.YearToDate ||
            budget.budgetCheckPeriod === domain.BudgetCheckPeriod.YearToDateWithCustomFirstMonth
        ) {
            budgetBreakdowns[0].sectionName += messages.ytd;
        }

        const lineItem = {
            budgetName: budget.budgetName,
            account: `${budget.account.id} - ${budget.account.text}`,
            budgetPeriod: `${moment.utc(budget.budgetPeriodStart).format('ll')} - ${moment
                .utc(budget.budgetPeriodEnd)
                .format('ll')}`,
            remainingBudget: budget.budgetAmounts.remainingAmount,
            plannedBudget: budget.budgetAmounts.plannedAmount,
            status: budget.budgetCheckStatus,
            trackingCategories,
            detailsLineItems,
            budgetBreakdowns,
            totalAmountOfBillsAndPO,
            totalBudgetImpact: budget.linesAmount,
        };

        lineItems.push(lineItem);
    });

    return {
        lineItems,
        allCategories,
        currency,
    };
};
