import { domain } from 'modules/data';

export interface UploadPlaceholderProps {
    request: domain.Request;
    setContainerTitle: (title: string) => void;
}

export enum UploadType {
    General,
    EmailToSupplier,
    Ocr,
}
