import { backend, domain } from 'modules/data';

export function parseQBooksTaxRate(value: backend.IntegrationsQBooksTaxRate): domain.QBooksTaxRate {
    return {
        id: value.TaxRateId,
        text: value.Name,
        hidden: value.Hidden,
        zeroTaxAmount:
            value.SpecialTaxType === backend.IntegrationsQBooksQBOSpecialTaxType.ReverseCharge ||
            value.SpecialTaxType === backend.IntegrationsQBooksQBOSpecialTaxType.ZeroRated,
        effectiveTaxRate: (value.EffectiveTaxRate || []).map((x) => ({
            startDate: x.StartDate,
            endDate: x.EndDate,
            rate: x.TaxRate,
        })),
    };
}
