export enum OrganisationCountOption {
    LessThanThree = 'LessThanThree',
    ThreeToTen = 'ThreeToTen',
    MoreThanTen = 'MoreThanTen',
}

export enum RequiredNextActionForNewOrganisation {
    UpdateManagedOrganisationCount = 'UpdateManagedOrganisationCount',
    ImmediatelyAddToSubscription = 'ImmediatelyAddToSubscription',
    ImmediatelyPutOnDextLicense = 'ImmediatelyPutOnDextLicense',
}

export interface ActionsForNewOrganisation {
    canImmediatelyPutOnDextLicense: boolean;
    shouldUpdateManagedOrganisationCount: boolean;
    canImmediatelyAddToSubscription: boolean;
    requiredNextAction?: RequiredNextActionForNewOrganisation;
}
