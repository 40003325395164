import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages(
    'pages.RequestList.Toolbar.PayWithAirwallex.CompleteAirwallexPaymentPopup.TwoFaStep',
    {
        title: 'Authorise the payment',
        description:
            'Enter the 6-digit authentication code provided by the Authentication app connected to your ApprovalMax account. If you have problems, refer to this <supportlink>support article</supportlink>',
        codeFieldTitle: 'Enter Authenticator Code',
        wrongCodeErrorText: 'Wrong code',
        userCodePlaceholder: 'Enter authenticator code...',
        confirmButton: 'Confirm',
    }
);
