import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { FC, memo, useCallback } from 'react';
import bemFactory from 'react-bem-factory';
import { useRecoilValue } from 'recoil';
import { routingService } from 'services/routing';
import { activeAttachmentIdState, ocrIsLoadingState } from 'shared/states';
import { getPath, Path } from 'urlBuilder';

import { useDextNewSupplierWarning, usePreviouslyApprovedWarning } from './ToolbarNotifications.hooks';
import { messages } from './ToolbarNotifications.messages';
import {
    EditingRequiredNoticeButton,
    ImportantNotice,
    ImportantNoticeText,
    ImportantNoticeWarnIcon,
    Root,
} from './ToolbarNotifications.styles';
import { ToolbarNotificationsProps } from './ToolbarNotifications.types';

const qa = bemFactory.qa('reql-toolbar');

const ToolbarNotifications: FC<ToolbarNotificationsProps> = memo((props) => {
    const { matchingValidationError, isLocked, request, readonly } = props;

    const ocrIsLoading = useRecoilValue(ocrIsLoadingState);
    const activeAttachmentId = useRecoilValue(activeAttachmentIdState);
    const commands = request.commands;
    const hasReviewerV1Beta = request.company.betaFeatures.includes(domain.CompanyBetaFeature.ReviewerV1);
    const isXeroBill = request.integrationCode === domain.IntegrationCode.XeroBill;

    const edit = useCallback(() => {
        routingService.push(getPath(Path.editRequest, request.id, request.companyId, { activeAttachmentId }));
    }, [request, activeAttachmentId]);

    const isCompanyReadonly = request.company.isReadonly;

    const isDisconnected =
        request.company.integration && request.integrationCode
            ? request.company.integration.status === domain.IntegrationStatus.Disconnected
            : false;

    const isActionDisabled = isLocked || isCompanyReadonly || isDisconnected;

    const previouslyApprovedWarning = usePreviouslyApprovedWarning(request);

    const hasNewSupplier = useDextNewSupplierWarning(request);
    const requestName = selectors.template.getTemplateDisplayNameByCode(request.integrationCode);
    const isEditingOnApprovalAvailable = selectors.company.getIsEditingOnApprovalAvailable(
        request.company,
        request.integrationCode
    );
    const requiresEditingForEditOnApproval = request.requiresEditingForEditOnApproval && isEditingOnApprovalAvailable;
    const requiresEditingForReviewerV1 = request.requiresEditingForReviewerV1 && hasReviewerV1Beta;
    const requiresEditingBanner = !readonly && (requiresEditingForReviewerV1 || requiresEditingForEditOnApproval);
    const editPermissionsRequiredFieldsThatShouldBeFilled = request.editPermissionsRequiredFieldsThatShouldBeFilled;

    return (
        <Root>
            {request.majorFraudulentActivityTexts.length > 0 && (
                <ImportantNotice>
                    <ImportantNoticeWarnIcon width={23} height={19} />

                    <ImportantNoticeText data-qa={qa('fraud-notice-text')}>
                        {request.majorFraudulentActivityTexts.map((text, index) => {
                            const isNotLastElement = index !== request.majorFraudulentActivityTexts.length - 1;

                            return (
                                <span key={`${index}-${text}`}>
                                    {text}

                                    {isNotLastElement && <br />}
                                </span>
                            );
                        })}
                    </ImportantNoticeText>
                </ImportantNotice>
            )}

            {requiresEditingBanner && (
                <ImportantNotice>
                    <ImportantNoticeWarnIcon width={23} height={19} />

                    <ImportantNoticeText data-qa={qa('edit-required-text')}>
                        {messages.editRequestNoticeText({
                            requestName,
                            endAdornment: editPermissionsRequiredFieldsThatShouldBeFilled.length > 0 ? ':' : '.',
                        })}

                        {editPermissionsRequiredFieldsThatShouldBeFilled.length > 0 && (
                            <div>
                                <b>
                                    {editPermissionsRequiredFieldsThatShouldBeFilled
                                        .map((field) => field.name)
                                        .join(', ')}
                                    .
                                </b>
                            </div>
                        )}
                    </ImportantNoticeText>

                    {hasReviewerV1Beta && isXeroBill && (
                        <EditingRequiredNoticeButton
                            qa={qa('editing-required-notice-button')}
                            size='large'
                            disabled={isActionDisabled}
                            command={commands.editSecondary}
                            execute={edit}
                        >
                            {messages.editRequestButtonText}
                        </EditingRequiredNoticeButton>
                    )}
                </ImportantNotice>
            )}

            {matchingValidationError && !requiresEditingBanner && (
                <ImportantNotice>
                    <ImportantNoticeWarnIcon width={23} height={19} />

                    <ImportantNoticeText data-qa={qa('matching-error-text')}>
                        {matchingValidationError.errorText}
                    </ImportantNoticeText>
                </ImportantNotice>
            )}

            {ocrIsLoading && (
                <ImportantNotice>
                    <ImportantNoticeWarnIcon width={23} height={19} />

                    <ImportantNoticeText data-qa={qa('ocr-in-progress-text')}>
                        {messages.ocrInProgressText}
                    </ImportantNoticeText>
                </ImportantNotice>
            )}

            {previouslyApprovedWarning && (
                <ImportantNotice>
                    <ImportantNoticeWarnIcon width={23} height={19} />

                    <ImportantNoticeText data-qa={qa('previously-approved-warning-text')}>
                        {previouslyApprovedWarning}
                    </ImportantNoticeText>
                </ImportantNotice>
            )}

            {hasNewSupplier && (
                <ImportantNotice>
                    <ImportantNoticeWarnIcon width={23} height={19} />

                    <ImportantNoticeText data-qa={qa('dext-has-new-supplier-warning-text')}>
                        {messages.dextNewSupplierWarning}
                    </ImportantNoticeText>
                </ImportantNotice>
            )}
        </Root>
    );
});

export default ToolbarNotifications;
