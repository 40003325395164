import { atom } from 'recoil';

import { AddAsWatchersPopupState } from './AddAsWatchersPopup.types';

export const addAsWatcherPopupState = atom<AddAsWatchersPopupState>({
    key: 'addAsWatcherPopupState',
    default: {
        usersWithoutAccess: [],
    },
});
