import { mods } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

import SectionContainer from '../../../../../components/SectionContainer';

export const StyledSectionContainer = styled(SectionContainer)`
    margin: 30px 0;
`;

export const NoMatching = styled.div`
    width: 100%;
    margin-bottom: 30px;
    color: #5e5c5c;
`;

export const Header = styled.div`
    width: 100%;
    display: inline-flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
`;

export const HeaderContainer = styled.div<StyledMods<{ hasButton: boolean }>>`
    display: flex;
    justify-content: space-between;

    ${mods('hasButton', false)`
        margin-top: 10px;
    `};
`;

export const Title = styled.span`
    line-height: 35px;
`;
