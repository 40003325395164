import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { integrationApiPaths } from '../paths';
import { GetXeroCounterpartyPaymentDetails } from './useGetXeroCounterpartyPaymentDetails.types';

export const useGetXeroCounterpartyPaymentDetails = (
    params?: RequestParams<GetXeroCounterpartyPaymentDetails>,
    queryOptions?: RequestQueryOptions<GetXeroCounterpartyPaymentDetails>
) => {
    return useGet(integrationApiPaths.xeroCounterpartyPaymentDetails, {
        params,
        queryOptions,
        apiVersion: 'v1',
        method: 'get',
    });
};
