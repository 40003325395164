import { theme } from '@approvalmax/theme';
import { LinkIcon, NewTabIcon } from '@approvalmax/ui';
import { font } from 'modules/styles';
import styled from 'styled-components';

export const DraftBadge = styled.div`
    flex: none;
    ${font(9, '#fff')}
    letter-spacing: 1px;
    background: #9b9b9b;
    padding: 1px 4px;
    border-radius: 4px;
`;

export const Amount = styled.div`
    flex: none;
    ${font(16, '#000', 'semibold')}
`;

export const StyledNewTabIcon = styled(NewTabIcon)`
    color: ${theme.color.blue100};
    position: relative;
    top: 2px;
`;

export const StyledLinkIcon = styled(LinkIcon)`
    transform: rotate(-45deg);
`;
