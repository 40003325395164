import moment from 'moment';
import { useCallback } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { addonPromotionInfoState, AddonPromotionType, pageVisiblePromotionsState } from 'shared/states';

export const useAddonPromotions = () => {
    const [addonPromotionInfo, setAddonPromotionInfo] = useRecoilState(addonPromotionInfoState);
    const setPageVisiblePromotions = useSetRecoilState(pageVisiblePromotionsState);

    const closePromotion = useCallback(
        (promotion: AddonPromotionType, notShowAgain = false) => {
            setAddonPromotionInfo((currentState) => ({
                ...currentState,
                [promotion]: {
                    ...currentState[promotion],
                    timesClosed: notShowAgain ? 3 : currentState[promotion].timesClosed + 1,
                    lastCloseDate: new Date().toISOString(),
                },
            }));

            setPageVisiblePromotions((currentState) => {
                const newState = currentState?.filter((item) => item !== promotion);

                return newState && newState.length > 0 ? newState : null;
            });
        },
        [setAddonPromotionInfo, setPageVisiblePromotions]
    );

    const canShowPromotion = useCallback(
        (promotion: AddonPromotionType) => {
            if (addonPromotionInfo[promotion].timesClosed >= 3) {
                return false;
            }

            const isTwoDaysPassed = moment().diff(moment(addonPromotionInfo[promotion].lastCloseDate), 'days') >= 2;

            return !addonPromotionInfo[promotion].lastCloseDate || isTwoDaysPassed;
        },
        [addonPromotionInfo]
    );

    return { closePromotion, canShowPromotion };
};
