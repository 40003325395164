import { Text } from '@approvalmax/ui/src/components';
import { getCompanyById } from 'modules/common/selectors/companySelectors';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { FC, memo, useEffect } from 'react';
import { isOcrEnabledForTemplate } from 'shared/helpers';

import { OcrUploadPlaceholder } from '../OcrUploadPlaceholder/OcrUploadPlaceholder';
import { RequestUploadPlaceholder } from '../RequestUploadPlaceholder/RequestUploadPlaceholder';
import { useFilesUploading } from './UploadPlaceholder.hooks';
import { messages } from './UploadPlaceholder.messages';
import { UploadPlaceholderProps } from './UploadPlaceholder.types';

export const UploadPlaceholder: FC<UploadPlaceholderProps> = memo((props) => {
    const { request, setContainerTitle } = props;

    const company = useSelector((state) => getCompanyById(state, request.companyId));

    const {
        onDrop,
        isLoadingNewAttachments,
        isLoadingNewOcrAttachments,
        isLoadingNewSupplierAttachments,
        filterFiles,
        setFiles,
        files,
    } = useFilesUploading(request);

    useEffect(() => {
        if (request.integrationCode && isOcrEnabledForTemplate(company, request.integrationCode)) {
            setContainerTitle(messages.uploadDisplayOcrTitle);
        } else {
            setContainerTitle(messages.uploadDisplayDefaultTitle);
        }
    }, [company, company.betaFeatures, company.licenseFeatures, request.integrationCode, setContainerTitle]);

    const showOcrUploadPlaceholder =
        request.integrationCode &&
        isOcrEnabledForTemplate(company, request.integrationCode) &&
        request.statusV2 === domain.RequestStatusV2.Draft;

    return (
        <>
            {(isLoadingNewAttachments || isLoadingNewSupplierAttachments || isLoadingNewOcrAttachments) && (
                <Text textAlign='center' spacing='16 0' fontSize='small' fontWeight='regular' font='headline'>
                    {messages.title}
                </Text>
            )}

            {showOcrUploadPlaceholder ? (
                <OcrUploadPlaceholder
                    onDrop={onDrop}
                    isLoadingNewAttachments={isLoadingNewAttachments}
                    isLoadingNewOcrAttachments={isLoadingNewOcrAttachments}
                    filterFiles={filterFiles}
                    files={files}
                    setFiles={setFiles}
                />
            ) : (
                <RequestUploadPlaceholder
                    request={request}
                    onDrop={onDrop}
                    files={files}
                    setFiles={setFiles}
                    isLoadingNewAttachments={isLoadingNewAttachments}
                    isLoadingNewSupplierAttachments={isLoadingNewSupplierAttachments}
                    filterFiles={filterFiles}
                />
            )}
        </>
    );
});

UploadPlaceholder.displayName = 'UploadPlaceholder';
