import { selectors } from 'modules/common';
import { getTemplateDisplayNameByCode } from 'modules/common/selectors/templateSelectors';
import { domain, schemas } from 'modules/data';
import React from 'react';

import { resolveStepName } from './requestActivitySelectors';
import { messages } from './requestActivitySelectors.Xero.messages';

export function isXeroSystemEvent(
    request: selectors.types.ExpandedRequest,
    event: domain.RequestHistoryEvent
): boolean {
    if (!event.isSystem || request.integrationType !== domain.IntegrationType.Xero) {
        return false;
    }

    switch (event.type) {
        case domain.RequestHistoryEventType.ApprovedInXero:
        case domain.RequestHistoryEventType.CancelledInXero:
        case domain.RequestHistoryEventType.RejectedExternally:
        case domain.RequestHistoryEventType.PostApprovalChangeDetected:
        case domain.RequestHistoryEventType.UpdatedExternallyResetTemplate:
        case domain.RequestHistoryEventType.AttachmentUploadFailed:
            return true;

        default:
            return false;
    }
}

export function getXeroEventHeader(
    request: selectors.types.ExpandedRequest,
    event: domain.RequestHistoryEvent
): React.ReactNode {
    const isPurchaseOrder = request.integrationCode === domain.IntegrationCode.XeroPo;

    switch (event.type) {
        case domain.RequestHistoryEventType.PostApprovalChangeDetected:
            return messages.postApprovalChangeDetected;

        case domain.RequestHistoryEventType.UpdatedExternallyResetTemplate:
            return messages.updatedExternallyResetTemplate;

        case domain.RequestHistoryEventType.EmailToPartnerSent: {
            const templateName = getTemplateDisplayNameByCode(request.integrationCode);

            return request.integrationCode === domain.IntegrationCode.XeroPo
                ? messages.emailToPartnerSentSupplier({ templateName })
                : messages.emailToPartnerSentCustomer({ templateName });
        }

        case domain.RequestHistoryEventType.ApprovedInXero:
            return messages.approvedInXero;

        case domain.RequestHistoryEventType.RejectedExternally:
            return messages.rejectedExternally;

        case domain.RequestHistoryEventType.CancelledInXero:
            return messages.cancelledInXero;

        case domain.RequestHistoryEventType.MarkedAsBilled:
            return messages.markedAsBilled;

        case domain.RequestHistoryEventType.MarkAsBilledSynced:
            return messages.markAsBilledSynced;

        case domain.RequestHistoryEventType.MarkAsCustomerDecisionSynced:
            return messages.markAsCustomerDecisionSynced;

        case domain.RequestHistoryEventType.UnmarkedAsBilled:
            return messages.unmarkedAsBilled;

        case domain.RequestHistoryEventType.RequestMatchedByUser:
            return isPurchaseOrder ? messages.requestMatchedByUserPO : messages.requestMatchedByUserBill;

        case domain.RequestHistoryEventType.RequestMatchedBySystem:
            return isPurchaseOrder ? messages.requestMatchedBySystemPO : messages.requestMatchedBySystemBill;

        case domain.RequestHistoryEventType.RequestUnmatchedByUser:
            return isPurchaseOrder ? messages.requestUnmatchedByUserPO : messages.requestUnmatchedByUserBill;

        case domain.RequestHistoryEventType.RequestUnmatchedBySystem:
            return isPurchaseOrder ? messages.requestUnmatchedBySystemPO : messages.requestUnmatchedBySystemBill;

        case domain.RequestHistoryEventType.AirwallexBatchPaymentRequestPaid:
            return messages.airwallexBatchPaymentRequestPaid;

        default:
            return null;
    }
}

export function getXeroEventChanges(
    request: selectors.types.ExpandedRequest,
    event: domain.RequestHistoryEvent
): Array<React.ReactElement<any>> {
    const result: Array<React.ReactElement<any>> = [];

    if (!event.changes) {
        return result;
    }

    const changes = event.changes;

    switch (event.type) {
        case domain.RequestHistoryEventType.ParticipantsWereAddedDueToMatchingBillWithPO: {
            changes.stepChanges?.forEach((stepChange) => {
                let stepName = resolveStepName(request, stepChange.step);

                if (stepChange.addedApprovers) {
                    result.push(
                        ...stepChange.addedApprovers.map((a) => {
                            return (
                                <span key={a.user.UserId}>
                                    {messages.stepChangeAddedApproverPOMatching({
                                        approver: selectors.user.expandUser(schemas.mapUser(a.user)).displayName,
                                        stepName,
                                    })}
                                </span>
                            );
                        })
                    );
                }
            });
            break;
        }
    }

    return result;
}
