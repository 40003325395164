import { useEffect } from 'react';
import { useAddTFAEnablingBackupCodes } from 'shared/data';

import { fieldNameCodesAreSaved, fieldNameStateId } from '../../TwoFaEnablingWizard.constants';
import { useTwoFaWizardController, useTwoFaWizardFormContext } from '../../TwoFaEnablingWizard.hooks';
import { messages } from './StepBackupCodes.messages';

export const useStepBackupCodes = () => {
    const form = useTwoFaWizardFormContext();
    const { setWizardController } = useTwoFaWizardController();

    const stateId = form.watch(fieldNameStateId);
    const isConfirmed = form.watch(fieldNameCodesAreSaved);

    const { mutate: loadBackupCodes, data, isLoading } = useAddTFAEnablingBackupCodes();

    useEffect(() => {
        if (stateId) {
            loadBackupCodes({ body: { stateId } });
        }
    }, [loadBackupCodes, stateId]);

    useEffect(() => {
        setWizardController({
            title: messages.title,
            onPrev: undefined,
            onNext: undefined,
        });
    }, [setWizardController]);

    return {
        isLoading,
        isConfirmed,
        backupCodes: data?.backupCodes || [],
    };
};
