import { Button, Flex, Form, Link, Popup, Radio, Text } from '@approvalmax/ui/src/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { constants } from 'modules/common';
import { memo } from 'react';
import bemFactory from 'react-bem-factory';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { useTwoFaActionWizardContext } from '../../TwoFaActionWizard.context';
import { TwoFaActionWizardMethods, TwoFaActionWizardProps } from '../../TwoFaActionWizard.types';
import { fieldNameOption } from './ScreenMethodSelect.constants';
import { messages } from './ScreenMethodSelect.messages';
import { schema } from './ScreenMethodSelect.schema';

const qa = bemFactory.qa('pro-method-select-popup');

export const ScreenMethodSelect = memo<TwoFaActionWizardProps>((props) => {
    const { isTFAAlternativeEmailEnabled, isTFABackupCodesEnabled } = props;

    const { setActiveScreen } = useTwoFaActionWizardContext();

    const form = useForm<z.infer<typeof schema>>({
        defaultValues: {
            [fieldNameOption]: TwoFaActionWizardMethods.authenticatorAppCode,
        },
        resolver: zodResolver(schema),
    });

    const handleSubmit = form.handleSubmit((data: z.infer<typeof schema>) => {
        setActiveScreen(data.option);
    });

    return (
        <>
            <Popup.Header title={messages.title} />

            <Popup.Body qa={qa()}>
                <Form form={form} onSubmit={handleSubmit}>
                    <Flex direction='column' spacing='24'>
                        <Radio.Group name={fieldNameOption} direction='column' spacing='12'>
                            <Radio value={TwoFaActionWizardMethods.authenticatorAppCode}>{messages.appCodeRadio}</Radio>

                            {isTFAAlternativeEmailEnabled && (
                                <Radio value={TwoFaActionWizardMethods.emailCode}>{messages.emailCodeRadio}</Radio>
                            )}

                            {isTFABackupCodesEnabled && (
                                <Radio value={TwoFaActionWizardMethods.backupCode}>{messages.backupCodeRadio}</Radio>
                            )}
                        </Radio.Group>

                        <Button color='blue80' size='large' type='submit'>
                            {messages.nextButton}
                        </Button>

                        <Text font='body' fontWeight='regular' color='midnight80'>
                            {messages.note({
                                supportlink: (chunks) => (
                                    <Link external font='body' href={constants.xeroConstants.XERO_TWO_FA_SUPPORT_LINK}>
                                        {chunks}
                                    </Link>
                                ),
                            })}
                        </Text>
                    </Flex>
                </Form>
            </Popup.Body>
        </>
    );
});

ScreenMethodSelect.displayName = 'ScreenMethodSelect';
