import { QueryOptions } from '@approvalmax/data';
import { useGetLegacy } from 'shared/data';

import { requestsApiPaths } from '../paths';
import {
    UseGetAmaxPayXeroRequestDetailsPathParams,
    UseGetAmaxPayXeroRequestDetailsResponse,
    UseGetAmaxPayXeroRequestDetailsResponseBackend,
} from './useGetAmaxPayXeroRequestDetails.types';

export const useGetAmaxPayXeroRequestDetails = (
    pathParams: UseGetAmaxPayXeroRequestDetailsPathParams,
    queryOptions?: QueryOptions<UseGetAmaxPayXeroRequestDetailsResponseBackend, UseGetAmaxPayXeroRequestDetailsResponse>
) => {
    return useGetLegacy<UseGetAmaxPayXeroRequestDetailsResponseBackend, UseGetAmaxPayXeroRequestDetailsResponse>(
        requestsApiPaths.amaxPayXeroRequestDetails,
        {
            pathParams,
            queryOptions: {
                staleTime: 60000,
                ...queryOptions,
            },
            method: 'get',
        }
    );
};
