import { Guid } from '@approvalmax/types';
import { miscHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { backend, schemas } from 'modules/data';
import { normalize } from 'normalizr';

export function getMemberOrUser(
    userId: Guid,
    team: selectors.types.ExpandedCompanyUser[],
    allUsers: selectors.types.ExpandedUser[]
) {
    miscHelpers.invariant(userId, 'userId must be a string (a falsy value provided).');

    const result = team.find((u) => u.id === userId) || allUsers.find((u) => u.id === userId);

    miscHelpers.invariant(result, `User ${userId} must exist in the store.`);

    return result!;
}

export const getDisplayName = (user: backend.UserAnswer | null) => {
    if (!user) return '';

    const entities = normalize(user, schemas.userSchema).entities;
    const expandedUser = entities.users ? selectors.user.expandUser(Object.values(entities.users)[0]) : null;

    return expandedUser?.displayName || '';
};
