import { Reference } from '@approvalmax/types';
import { dateTimeHelpers, intl } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { backend, domain, State } from 'modules/data';
import { clearCacheStorage } from 'modules/data-providers';
import {
    createAction,
    createAsyncAction,
    createErrorAction,
    createSelectingAction,
    ExtractActions,
} from 'modules/react-redux';
import { api } from 'services/api';
import { emailToSupplierHelpers } from 'shared/helpers';

import {
    createEditableQBookContact,
    createEditableQBookCustomer,
    EditableQBooksCustomer,
    EditableQBooksVendor,
} from '../data/qbooks/EditableQBooksCounterparty';
import * as qbooksAccountLineItem from '../data/qbooks/QBooksAccountLineItem';
import { parseQBooksAddress } from '../data/qbooks/QBooksAddress';
import { parseQBooksContact } from '../data/qbooks/QBooksContact';
import { parseQBooksContext } from '../data/qbooks/QBooksContext';
import { parseQBooksItem } from '../data/qbooks/QBooksItem';
import * as qbooksLineItem from '../data/qbooks/QBooksLineItem';
import { getContext, getRequest } from '../selectors/pageSelectors';
import {
    getComputedPrice,
    getEditableQBooksCustomerTransfer,
    getEditableQBooksVendorTransfer,
    getQBooksAccountLineItemById,
    getQBooksAccountLineItems,
    getQBooksContext,
    getQBooksLineItemById,
    getQBooksLineItems,
} from '../selectors/qbooks';
import { RequestEditMode } from '../selectors/requestSelectors';
import { getRuleTypeByEditMode } from '../utils/helpers';
import { messages } from './actions.messages';

export const loadQBooksRequestContext = async (
    request: domain.QBooksRequest,
    response: backend.RequestsRequestEditingContext,
    editMode: RequestEditMode
): Promise<domain.QBooksContext> => {
    // Hack: it should actually always come from the server and be set to defaultLineAmountType.

    const requestHasValidLineAmountType = Boolean(
        [
            response.request.QBooksPurchaseOrderDetails,
            response.request.QBooksBillInvoiceDetails,
            response.request.QBooksExpenseDetails,
            response.request.QBooksSalesInvoiceDetails,
        ].find((x) => Boolean(x))?.LineAmountType
    );

    const result = parseQBooksContext(response.qbooksContext, requestHasValidLineAmountType);
    const ruleType = getRuleTypeByEditMode(editMode);

    if (response.request.IntegrationCode !== domain.IntegrationCode.QBooksVendor) {
        const companyAddressesResponse = await api.integration.getQBooksCompanyAddresses({
            requestId: request.id,
            ruleType,
            companyId: request.companyId,
        });

        result.companyAddresses = companyAddressesResponse.Addresses.map(parseQBooksAddress);

        const qbooksItemsTransfer = {
            requestId: request.id,
            ruleType,
            searchQuery: null,
            startFrom: 0,
            rowNum: null,
            companyId: request.companyId,
        };

        const qbooksItems =
            response.request.IntegrationCode === domain.IntegrationCode.QBooksInvoice
                ? await api.integration.getQBooksSalesItems(qbooksItemsTransfer)
                : await api.integration.getQBooksPurchaseItems(qbooksItemsTransfer);

        result.qbooksItems = qbooksItems.Items.map(parseQBooksItem);
    }

    if (response.supplierEmailSettings) {
        result.supplierEmailSettings = {
            ...response.supplierEmailSettings,
            subject: emailToSupplierHelpers.escapeExpressions(response.supplierEmailSettings.subject || ''),
        };
    }

    return result;
};

export const CHANGE_QBOOKS_VENDOR_MESSAGE = 'REQUESTFORM/CHANGE_QBOOKS_VENDOR_MESSAGE';
export const changeQbooksVendorMessage = (newVendorMessage: string) =>
    createAction(CHANGE_QBOOKS_VENDOR_MESSAGE, {
        newVendorMessage,
    });

export const CHANGE_QBOOKS_MEMO = 'REQUESTFORM/CHANGE_QBOOKS_MEMO';
export const changeQbooksMemo = (newMemo: string) =>
    createAction(CHANGE_QBOOKS_MEMO, {
        newMemo,
    });

export const CHANGE_QBOOKS_CUSTOMER_MEMO = 'REQUESTFORM/CHANGE_QBOOKS_CUSTOMER_MEMO';
export const changeQbooksCustomerMemo = (newCustomerMemo: string) =>
    createAction(CHANGE_QBOOKS_CUSTOMER_MEMO, {
        newCustomerMemo,
    });

export const CHANGE_QBOOKS_VENDOR = 'REQUESTFORM/CHANGE_QBOOKS_VENDOR';
export const changeQbooksVendor = (newVendor: domain.QBooksContact) =>
    createSelectingAction(CHANGE_QBOOKS_VENDOR, (state) => {
        const request = getRequest<domain.QBooksPoRequest | domain.QBooksBillRequest>(state);
        const context = getQBooksContext(state);
        const company = selectors.company.getCompanyById(state, request.companyId);
        const details = request.details;

        return { company, newVendor, context, details };
    });

export const CHANGE_QBOOKS_CUSTOMER = 'REQUESTFORM/CHANGE_QBOOKS_CUSTOMER';
export const changeQbooksCustomer = (newCustomer: domain.QBooksContact) =>
    createSelectingAction(CHANGE_QBOOKS_CUSTOMER, (state) => {
        const request = getRequest<domain.QBooksInvoiceRequest>(state);
        const context = getQBooksContext(state);
        const company = selectors.company.getCompanyById(state, request.companyId);
        const details = request.details;

        return { company, newCustomer, context, details };
    });

export const CHANGE_QBOOKS_PAYEE = 'REQUESTFORM/CHANGE_QBOOKS_PAYEE';
export const changeQbooksPayee = (payee: domain.QBooksPayee | null) =>
    createSelectingAction(CHANGE_QBOOKS_PAYEE, (state) => ({
        company: selectors.company.getCompanyById(state, getRequest(state).companyId),
        payee,
    }));

export const CHANGE_QBOOKS_NEW_CREATED_VENDOR = 'REQUESTFORM/CHANGE_QBOOKS_NEW_CREATED_VENDOR';
export const changeQbooksNewCreatedVendor = (newCreatedVendor: domain.QBooksPayee | null) =>
    createAction(CHANGE_QBOOKS_NEW_CREATED_VENDOR, {
        newCreatedVendor,
    });

export const CHANGE_QBOOKS_NEW_CREATED_CUSTOMER = 'REQUESTFORM/CHANGE_QBOOKS_NEW_CREATED_CUSTOMER';
export const changeQbooksNewCreatedCustomer = (newCreatedCustomer: domain.QBooksPayee | null) =>
    createAction(CHANGE_QBOOKS_NEW_CREATED_CUSTOMER, {
        newCreatedCustomer,
    });

export const CHANGE_QBOOKS_PAYEE_TYPE = 'REQUESTFORM/CHANGE_QBOOKS_PAYEE_TYPE';
export const changeQbooksPayeeType = (payeeType: domain.QBooksPayeeType | null) =>
    createSelectingAction(CHANGE_QBOOKS_PAYEE_TYPE, (state) => ({
        company: selectors.company.getCompanyById(state, getRequest(state).companyId),
        payeeType,
    }));

export const CHANGE_QBOOKS_PAYMENT_ACCOUNT = 'REQUESTFORM/CHANGE_QBOOKS_PAYMENT_ACCOUNT';
export const changeQbooksPaymentAccount = (paymentAccount: domain.QBooksAccount | null) =>
    createSelectingAction(CHANGE_QBOOKS_PAYMENT_ACCOUNT, (state) => ({
        company: selectors.company.getCompanyById(state, getRequest(state).companyId),
        paymentAccount,
    }));

export const CHANGE_QBOOKS_PAYMENT_METHOD = 'REQUESTFORM/CHANGE_QBOOKS_PAYMENT_METHOD';
export const changeQbooksPaymentMethod = (paymentMethod: domain.QBooksPaymentMethod) =>
    createSelectingAction(CHANGE_QBOOKS_PAYMENT_METHOD, (state) => ({
        company: selectors.company.getCompanyById(state, getRequest(state).companyId),
        paymentMethod,
    }));

export const CHANGE_QBOOKS_PAYMENT_TYPE = 'REQUESTFORM/CHANGE_QBOOKS_PAYMENT_TYPE';
export const changeQbooksPaymentType = (paymentType: domain.QBooksPayeeType) =>
    createSelectingAction(CHANGE_QBOOKS_PAYMENT_TYPE, (state) => ({
        company: selectors.company.getCompanyById(state, getRequest(state).companyId),
        paymentType,
    }));

export const CHANGE_QBOOKS_EXPENSE_NUMBER = 'REQUESTFORM/CHANGE_QBOOKS_EXPENSE_NUMBER';
export const changeQbooksExpenseNumber = (expenseNumber: domain.QBooksExpenseDetails['expenseNumber']) =>
    createSelectingAction(CHANGE_QBOOKS_EXPENSE_NUMBER, (state) => ({
        company: selectors.company.getCompanyById(state, getRequest(state).companyId),
        expenseNumber,
    }));

export const CHANGE_QBOOKS_REQUEST_CURRENCY = 'REQUESTFORM/CHANGE_QBOOKS_REQUEST_CURRENCY';
export const changeQbooksRequestCurrency = (currency: Reference) =>
    createSelectingAction(CHANGE_QBOOKS_REQUEST_CURRENCY, (state) => ({
        company: selectors.company.getCompanyById(state, getRequest(state).companyId),
        currency,
    }));

export const CHANGE_QBOOKS_MAILING_ADDRESS = 'REQUESTFORM/CHANGE_QBOOKS_MAILING_ADDRESS';
export const changeQbooksMailingAddress = (newMailingAddress: string) =>
    createAction(CHANGE_QBOOKS_MAILING_ADDRESS, {
        newMailingAddress,
    });

export const CHANGE_QBOOKS_BILLING_ADDRESS = 'REQUESTFORM/CHANGE_QBOOKS_BILLING_ADDRESS';
export const changeQbooksBillingAddress = (newBillingAddress: string) =>
    createAction(CHANGE_QBOOKS_BILLING_ADDRESS, {
        newBillingAddress,
    });

export const CHANGE_QBOOKS_DATE = 'REQUESTFORM/CHANGE_QBOOKS_DATE';
export const changeQbooksDate = (newDate: string) =>
    createAction(CHANGE_QBOOKS_DATE, {
        newDate,
    });

export const CHANGE_QBOOKS_DUE_DATE = 'REQUESTFORM/CHANGE_QBOOKS_DUE_DATE';
export const changeQbooksDueDate = (newDueDate: string) =>
    createAction(CHANGE_QBOOKS_DUE_DATE, {
        newDueDate,
    });

export const CHANGE_QBOOKS_TERMS = 'REQUESTFORM/CHANGE_QBOOKS_TERMS';
export const changeQbooksTerms = (newTerms: domain.QBooksTerm | null) =>
    createAction(CHANGE_QBOOKS_TERMS, {
        newTerms,
    });

export const CHANGE_QBOOKS_NUMBER = 'REQUESTFORM/CHANGE_QBOOKS_NUMBER';
export const changeQbooksNumber = (newNumber: string) =>
    createAction(CHANGE_QBOOKS_NUMBER, {
        newNumber,
    });

export const CHANGE_QBOOKS_SHIPPING_FROM_ADDRESS = 'REQUESTFORM/CHANGE_QBOOKS_SHIPPING_FROM_ADDRESS';
export const changeQbooksShippingFromAddress = (newShippingFromAddress: string) =>
    createAction(CHANGE_QBOOKS_SHIPPING_FROM_ADDRESS, {
        newShippingFromAddress,
    });

export const CHANGE_QBOOKS_SHIPPING_PRICE = 'REQUESTFORM/CHANGE_QBOOKS_SHIPPING_PRICE';
export const changeQbooksShippingPrice = (newShippingPrice: number) =>
    createSelectingAction(CHANGE_QBOOKS_SHIPPING_PRICE, (state) =>
        getNewComputedShippingPrice(state, newShippingPrice, false)
    );

const getNewComputedShippingPrice = (
    state: State,
    newShippingPrice: number | null | false,
    newTaxCode: Reference | null | false
) => {
    const request = getRequest<domain.QBooksInvoiceRequest>(state);
    const { details } = request;

    const result = getComputedPrice({
        price:
            newShippingPrice !== false
                ? newShippingPrice
                : details.lineAmountType === domain.LineAmountType.TaxExclusive
                  ? details.shipping.price
                  : details.shipping.priceGross,
        taxCode: (newTaxCode !== false ? newTaxCode : details.shippingTaxCode) || undefined,
        qbooksContext: getQBooksContext(state),
        lineAmountType: details.lineAmountType,
        date: details.date,
        taxApplicableOnType: domain.TaxApplicableOnType.Sales,
    });

    return {
        newShippingPrice: result.computedPriceNet,
        newShippingPriceGross: result.computedPriceGross,
    };
};

export const CHANGE_QBOOKS_SHIPPING_TAX_CODE = 'REQUESTFORM/CHANGE_QBOOKS_SHIPPING_TAX_CODE';
export const changeQbooksShippingTaxCode = (newShippingTaxCode: Reference) =>
    createSelectingAction(CHANGE_QBOOKS_SHIPPING_TAX_CODE, (state) => ({
        newShippingTaxCode,
        ...getNewComputedShippingPrice(state, false, newShippingTaxCode),
    }));

export const CHANGE_QBOOKS_SHIPPING_CUSTOMER = 'REQUESTFORM/CHANGE_QBOOKS_SHIPPING_CUSTOMER';
export const changeQbooksShippingCustomer = (newCustomer: domain.QBooksContact | null) =>
    createAction(CHANGE_QBOOKS_SHIPPING_CUSTOMER, {
        newCustomer,
    });

export const CHANGE_QBOOKS_SHIPPING_METHOD = 'REQUESTFORM/CHANGE_QBOOKS_SHIPPING_METHOD';
export const changeQbooksShippingMethod = (newShippingMethod: string) =>
    createAction(CHANGE_QBOOKS_SHIPPING_METHOD, {
        newShippingMethod,
    });

export const CHANGE_QBOOKS_SHIPPING_ADDRESS = 'REQUESTFORM/CHANGE_QBOOKS_SHIPPING_ADDRESS';
export const changeQbooksShippingAddress = (newShippingAddress: string) =>
    createAction(CHANGE_QBOOKS_SHIPPING_ADDRESS, {
        newShippingAddress,
    });

export const CHANGE_QBOOKS_TRACKING_NUMBER = 'REQUESTFORM/CHANGE_QBOOKS_TRACKING_NUMBER';
export const changeQbooksTrackingNumber = (newTrackingNumber: string) =>
    createAction(CHANGE_QBOOKS_TRACKING_NUMBER, {
        newTrackingNumber,
    });

export const CHANGE_QBOOKS_SHIPPING_DATE = 'REQUESTFORM/CHANGE_QBOOKS_SHIPPING_DATE';
export const changeQbooksShippingDate = (newShippingDate: string) =>
    createAction(CHANGE_QBOOKS_SHIPPING_DATE, {
        newShippingDate,
    });

export const CHANGE_QBOOKS_DEPARTMENT = 'REQUESTFORM/CHANGE_QBOOKS_DEPARTMENT';
export const changeQbooksDepartment = (newDepartment: Reference | null) =>
    createAction(CHANGE_QBOOKS_DEPARTMENT, {
        newDepartment,
    });

export const CHANGE_QBOOKS_DISCOUNT = 'REQUESTFORM/CHANGE_QBOOKS_DISCOUNT';
export const changeQbooksDiscount = (newDiscount: number | null) =>
    createAction(CHANGE_QBOOKS_DISCOUNT, {
        newDiscount,
    });

export const CHANGE_QBOOKS_DISCOUNT_TYPE = 'REQUESTFORM/CHANGE_QBOOKS_DISCOUNT_TYPE';
export const changeQbooksDiscountType = (newDiscountType: domain.QBooksDiscountType) =>
    createAction(CHANGE_QBOOKS_DISCOUNT_TYPE, {
        newDiscountType,
    });

export const CHANGE_QBOOKS_TAX_CODE = 'REQUESTFORM/CHANGE_QBOOKS_TAX_CODE';
export const changeQbooksTaxCode = (newTaxCode: Reference | null) =>
    createAction(CHANGE_QBOOKS_TAX_CODE, {
        newTaxCode,
    });

export const CHANGE_QBOOKS_APPLY_TAX_AFTER_DISCOUNT = 'REQUESTFORM/CHANGE_QBOOKS_APPLY_TAX_AFTER_DISCOUNT';
export const changeQbooksApplyTaxAfterDiscount = (newApplyTaxAfterDiscount: boolean) =>
    createAction(CHANGE_QBOOKS_APPLY_TAX_AFTER_DISCOUNT, {
        newApplyTaxAfterDiscount,
    });

export const CHANGE_QBOOKS_CUSTOM_FIELD = 'REQUESTFORM/CHANGE_QBOOKS_CUSTOM_FIELD';
export const changeQbooksCustomField = (fieldId: string, newValue: string) =>
    createSelectingAction(CHANGE_QBOOKS_CUSTOM_FIELD, (state) => ({
        fieldId,
        newValue,
        context: getContext(state),
        qbooksContext: getQBooksContext(state),
    }));

export const CHANGE_QBOOKS_LINE_AMOUNT_TYPE = 'REQUESTFORM/CHANGE_QBOOKS_LINE_AMOUNT_TYPE';
export const changeQbooksLineAmountType = (newLineAmountType: domain.LineAmountType) =>
    createSelectingAction(CHANGE_QBOOKS_LINE_AMOUNT_TYPE, (state) => ({
        newLineAmountType,
        qbooksContext: getQBooksContext(state),
    }));

export const CHANGE_QBOOKS_LINE_ITEMS_ITEM = 'REQUESTFORM/CHANGE_QBOOKS_LINE_ITEMS_ITEM';
export const changeQbooksLineItemsItem = (lineItemIds: string[], newItem: domain.QBooksItem | null) =>
    createSelectingAction(CHANGE_QBOOKS_LINE_ITEMS_ITEM, (state) => ({
        qbooksContext: getQBooksContext(state),
        lineItemIds,
        newItem,
    }));

export const CHANGE_QBOOKS_LINE_ITEM_DESCRIPTION = 'REQUESTFORM/CHANGE_QBOOKS_LINE_ITEM_DESCRIPTION';
export const changeQbooksLineItemDescription = (lineItemId: string, newDescription: string) =>
    createAction(CHANGE_QBOOKS_LINE_ITEM_DESCRIPTION, {
        lineItemId,
        newDescription,
    });

export const CHANGE_QBOOKS_LINE_ITEM_SERVICE_DATE = 'REQUESTFORM/CHANGE_QBOOKS_LINE_ITEM_SERVICE_DATE';
export const changeQbooksLineItemServiceDate = (lineItemId: string, newServiceDate: string | null) =>
    createAction(CHANGE_QBOOKS_LINE_ITEM_SERVICE_DATE, {
        lineItemId,
        newServiceDate,
    });

export const CHANGE_QBOOKS_LINE_ITEM_QTY = 'REQUESTFORM/CHANGE_QBOOKS_LINE_ITEM_QTY';
export const changeQbooksLineItemQty = (lineItemId: string, newQty: number | null) =>
    createAction(CHANGE_QBOOKS_LINE_ITEM_QTY, {
        lineItemId,
        newQty,
    });

function getNewComputedUnitPrice(
    state: State,
    lineItemId: string,
    newUnitPrice: number | null | false,
    newTaxCode: Reference | null | false
) {
    const request = getRequest<
        domain.QBooksBillRequest | domain.QBooksPoRequest | domain.QBooksExpenseRequest | domain.QBooksInvoiceRequest
    >(state);
    const { details } = request;
    const lineItem = getQBooksLineItemById(state, request, lineItemId);

    const taxApplicableOnType =
        details.integrationCode === domain.IntegrationCode.QBooksInvoice
            ? domain.TaxApplicableOnType.Sales
            : domain.TaxApplicableOnType.Purchases;

    const result = getComputedPrice({
        price:
            newUnitPrice !== false
                ? newUnitPrice
                : details.lineAmountType === domain.LineAmountType.TaxExclusive
                  ? lineItem.unitPrice
                  : lineItem.unitPriceGross,
        taxCode: newTaxCode !== false ? newTaxCode || undefined : lineItem.taxCode,
        qbooksContext: getQBooksContext(state),
        lineAmountType: details.lineAmountType,
        date: details.date,
        taxApplicableOnType,
    });

    return {
        newUnitPriceNet: result.computedPriceNet,
        newUnitPriceGross: result.computedPriceGross,
    };
}

export const CHANGE_QBOOKS_LINE_ITEM_UNIT_PRICE = 'REQUESTFORM/CHANGE_QBOOKS_LINE_ITEM_UNIT_PRICE';
export const changeQbooksLineItemUnitPrice = (lineItemId: string, newUnitPrice: number | null) =>
    createSelectingAction(CHANGE_QBOOKS_LINE_ITEM_UNIT_PRICE, (state) => {
        return {
            lineItemId,
            ...getNewComputedUnitPrice(state, lineItemId, newUnitPrice, false),
        };
    });

export const CHANGE_QBOOKS_LINE_ITEMS_TAX_CODE = 'REQUESTFORM/CHANGE_QBOOKS_LINE_ITEMS_TAX_CODE';
export const changeQbooksLineItemsTaxCode = (lineItemIds: string[], newTaxCode: domain.QBooksTaxCode | null) =>
    createSelectingAction(CHANGE_QBOOKS_LINE_ITEMS_TAX_CODE, (state) => ({
        lineItemIds,
        newTaxCode,
        unitPriceArray: lineItemIds.map((lineItemId) => getNewComputedUnitPrice(state, lineItemId, false, newTaxCode)),
    }));

export const CHANGE_QBOOKS_LINE_ITEMS_CUSTOMER = 'REQUESTFORM/CHANGE_QBOOKS_LINE_ITEMS_CUSTOMER';
export const changeQbooksLineItemsCustomer = (lineItemIds: string[], newCustomer: Reference | null) =>
    createSelectingAction(CHANGE_QBOOKS_LINE_ITEMS_CUSTOMER, () => ({
        lineItemIds,
        newCustomer,
    }));

export const CHANGE_QBOOKS_LINE_ITEMS_CLASS = 'REQUESTFORM/CHANGE_QBOOKS_LINE_ITEMS_CLASS';
export const changeQbooksLineItemsClass = (lineItemIds: string[], newClass: Reference | null) =>
    createSelectingAction(CHANGE_QBOOKS_LINE_ITEMS_CLASS, () => ({
        lineItemIds,
        newClass,
    }));

export const CHANGE_QBOOKS_LINE_ITEM_MATCHING = 'REQUESTFORM/CHANGE_QBOOKS_LINE_ITEM_MATCHING';
export const changeQbooksLineItemMatching = (lineItemId: string, matchedLineItemId?: string) =>
    createAction(CHANGE_QBOOKS_LINE_ITEM_MATCHING, {
        lineItemId,
        matchedLineItemId,
    });

export const REORDER_QBOOKS_LINE_ITEMS = 'REQUESTFORM/REORDER_QBOOKS_LINE_ITEMS';
export const reorderQbooksLineItems = (oldIndex: number, newIndex: number) =>
    createAction(REORDER_QBOOKS_LINE_ITEMS, {
        oldIndex,
        newIndex,
    });

export const REMOVE_QBOOKS_LINE_ITEMS = 'REQUESTFORM/REMOVE_QBOOKS_LINE_ITEMS';
export const removeQbooksLineItems = (lineItemIds: string[]) =>
    createAction(REMOVE_QBOOKS_LINE_ITEMS, {
        lineItemIds,
        newLineItem: qbooksLineItem.createQBooksLineItem(),
    });

export const REMOVE_QBOOKS_LINE_ITEMS_ALL = 'REQUESTFORM/REMOVE_QBOOKS_LINE_ITEMS_ALL';
export const removeQbooksLineItemsAll = () =>
    createAction(REMOVE_QBOOKS_LINE_ITEMS_ALL, {
        newLineItem: qbooksLineItem.createQBooksLineItem(),
    });

export const CLONE_QBOOKS_LINE_ITEMS = 'REQUESTFORM/CLONE_QBOOKS_LINE_ITEMS';
export const cloneQbooksLineItems = (lineItemIds: string[]) =>
    createSelectingAction(CLONE_QBOOKS_LINE_ITEMS, (state) => ({
        lineItemIds,
        newLineItems: lineItemIds.map((lineItemId) =>
            qbooksLineItem.cloneQBooksLineItem(getQBooksLineItemById(state, getRequest(state), lineItemId))
        ),
    }));

export const CLONE_QBOOKS_LINE_ITEMS_ALL = 'REQUESTFORM/CLONE_QBOOKS_LINE_ITEMS_ALL';
export const cloneQbooksLineItemsAll = () =>
    createSelectingAction(CLONE_QBOOKS_LINE_ITEMS_ALL, (state) => {
        const lineItemIds = getQBooksLineItems(state, getRequest(state)).map((lineItem) => lineItem.id);

        return {
            lineItemIds,
            newLineItems: lineItemIds.map((lineItemId) =>
                qbooksLineItem.cloneQBooksLineItem(getQBooksLineItemById(state, getRequest(state), lineItemId))
            ),
        };
    });

export const CHANGE_QBOOKS_LINE_ITEM_CHECKED = 'REQUESTFORM/CHANGE_QBOOKS_LINE_ITEM_CHECKED';
export const changeQbooksLineItemChecked = (lineItemId: string, checked?: boolean) =>
    createSelectingAction(CHANGE_QBOOKS_LINE_ITEM_CHECKED, () => ({
        lineItemId,
        checked,
    }));

export const CHANGE_QBOOKS_LINE_ITEMS_ALL_CHECKED = 'REQUESTFORM/CHANGE_QBOOKS_LINE_ITEMS_ALL_CHECKED';
export const changeQbooksLineItemsAllChecked = (checked: boolean) =>
    createSelectingAction(CHANGE_QBOOKS_LINE_ITEMS_ALL_CHECKED, () => checked);

export const ADD_QBOOKS_LINE_ITEM = 'REQUESTFORM/ADD_QBOOKS_LINE_ITEM';
export const addQbooksLineItem = () =>
    createAction(ADD_QBOOKS_LINE_ITEM, {
        newLineItem: qbooksLineItem.createQBooksLineItem(),
    });

export const ADD_QBOOKS_ACCOUNT_LINE_ITEM = 'REQUESTFORM/ADD_QBOOKS_ACCOUNT_LINE_ITEM';
export const addQbooksAccountLineItem = () =>
    createAction(ADD_QBOOKS_ACCOUNT_LINE_ITEM, {
        newAccountLineItem: qbooksAccountLineItem.createQBooksAccountLineItem(),
    });

export const CLONE_QBOOKS_ACCOUNT_LINE_ITEMS = 'REQUESTFORM/CLONE_QBOOKS_ACCOUNT_LINE_ITEMS';
export const cloneQbooksAccountLineItems = (accountLineItemIds: string[]) =>
    createSelectingAction(CLONE_QBOOKS_ACCOUNT_LINE_ITEMS, (state) => ({
        accountLineItemIds,
        newAccountLineItems: accountLineItemIds.map((accountLineItemId) =>
            qbooksAccountLineItem.cloneQBooksAccountLineItem(
                getQBooksAccountLineItemById(state, getRequest(state), accountLineItemId)
            )
        ),
    }));

export const CLONE_QBOOKS_ACCOUNT_LINE_ITEMS_ALL = 'REQUESTFORM/CLONE_QBOOKS_ACCOUNT_LINE_ITEMS_ALL';
export const cloneQbooksAccountLineItemsAll = () =>
    createSelectingAction(CLONE_QBOOKS_ACCOUNT_LINE_ITEMS_ALL, (state) => {
        const accountLineItemIds = getQBooksAccountLineItems(state, getRequest(state)).map(
            (accountLineItem) => accountLineItem.id
        );

        return {
            accountLineItemIds,
            newAccountLineItems: accountLineItemIds.map((accountLineItemId) =>
                qbooksAccountLineItem.cloneQBooksAccountLineItem(
                    getQBooksAccountLineItemById(state, getRequest(state), accountLineItemId)
                )
            ),
        };
    });

export const CHANGE_QBOOKS_ACCOUNT_LINE_ITEM_CHECKED = 'REQUESTFORM/CHANGE_QBOOKS_ACCOUNT_LINE_ITEM_CHECKED';
export const changeQbooksAccountLineItemChecked = (lineItemId: string, checked?: boolean) =>
    createSelectingAction(CHANGE_QBOOKS_ACCOUNT_LINE_ITEM_CHECKED, () => ({
        lineItemId,
        checked,
    }));

export const CHANGE_QBOOKS_ACCOUNT_LINE_ITEMS_ALL_CHECKED = 'REQUESTFORM/CHANGE_QBOOKS_ACCOUNT_LINE_ITEMS_ALL_CHECKED';
export const changeQbooksAccountLineItemsAllChecked = (checked: boolean) =>
    createSelectingAction(CHANGE_QBOOKS_ACCOUNT_LINE_ITEMS_ALL_CHECKED, () => checked);

export const REMOVE_QBOOKS_ACCOUNT_LINE_ITEMS = 'REQUESTFORM/REMOVE_QBOOKS_ACCOUNT_LINE_ITEMS';
export const removeQbooksAccountLineItems = (accountLineItemIds: string[]) =>
    createSelectingAction(REMOVE_QBOOKS_ACCOUNT_LINE_ITEMS, () => ({
        accountLineItemIds,
        newAccountLineItem: qbooksAccountLineItem.createQBooksAccountLineItem(),
    }));

export const REMOVE_QBOOKS_ACCOUNT_LINE_ITEMS_ALL = 'REQUESTFORM/REMOVE_QBOOKS_ACCOUNT_LINE_ITEMS_ALL';
export const removeQbooksAccountLineItemsAll = () =>
    createAction(REMOVE_QBOOKS_ACCOUNT_LINE_ITEMS_ALL, {
        newAccountLineItem: qbooksAccountLineItem.createQBooksAccountLineItem(),
    });

export const REORDER_QBOOKS_ACCOUNT_LINE_ITEM = 'REQUESTFORM/REORDER_QBOOKS_ACCOUNT_LINE_ITEM';
export const reorderQbooksAccountLineItem = (oldIndex: number, newIndex: number) =>
    createAction(REORDER_QBOOKS_ACCOUNT_LINE_ITEM, {
        oldIndex,
        newIndex,
    });

export const CHANGE_QBOOKS_ACCOUNT_LINE_ITEMS_CUSTOMER = 'REQUESTFORM/CHANGE_QBOOKS_ACCOUNT_LINE_ITEMS_CUSTOMER';
export const changeQbooksAccountLineItemsCustomer = (accountLineItemIds: string[], newCustomer: Reference | null) =>
    createSelectingAction(CHANGE_QBOOKS_ACCOUNT_LINE_ITEMS_CUSTOMER, () => ({
        accountLineItemIds,
        newCustomer,
    }));

export const CHANGE_QBOOKS_ACCOUNT_LINE_ITEMS_CLASS = 'REQUESTFORM/CHANGE_QBOOKS_ACCOUNT_LINE_ITEMS_CLASS';
export const changeQbooksAccountLineItemsClass = (accountLineItemIds: string[], newClass: Reference | null) =>
    createSelectingAction(CHANGE_QBOOKS_ACCOUNT_LINE_ITEMS_CLASS, () => ({
        accountLineItemIds,
        newClass,
    }));

export const CHANGE_QBOOKS_ACCOUNT_LINE_ITEM_DESCRIPTION = 'REQUESTFORM/CHANGE_QBOOKS_ACCOUNT_LINE_ITEM_DESCRIPTION';
export const changeQbooksAccountLineItemDescription = (accountLineItemId: string, newDescription: string) =>
    createSelectingAction(CHANGE_QBOOKS_ACCOUNT_LINE_ITEM_DESCRIPTION, () => ({
        accountLineItemId,
        newDescription,
    }));

export const CHANGE_QBOOKS_ACCOUNT_LINE_ITEMS_ACCOUNT = 'REQUESTFORM/CHANGE_QBOOKS_ACCOUNT_LINE_ITEMS_ACCOUNT';
export const changeQbooksAccountLineItemsAccount = (
    accountLineItemIds: string[],
    newAccount: domain.QBooksAccount | null
) =>
    createSelectingAction(CHANGE_QBOOKS_ACCOUNT_LINE_ITEMS_ACCOUNT, (state) => ({
        qbooksContext: getQBooksContext(state),
        accountLineItemIds,
        newAccount,
    }));

export const CHANGE_QBOOKS_ACCOUNT_LINE_ITEM_MATCHING = 'REQUESTFORM/CHANGE_QBOOKS_ACCOUNT_LINE_ITEM_MATCHING';
export const changeQbooksAccountLineItemMatching = (accountLineItemId: string, matchedLineItemId?: string) =>
    createAction(CHANGE_QBOOKS_ACCOUNT_LINE_ITEM_MATCHING, {
        accountLineItemId,
        matchedLineItemId,
    });

export const CHANGE_QBOOKS_ACCOUNT_LINE_ITEM_AMOUNT = 'REQUESTFORM/CHANGE_QBOOKS_ACCOUNT_LINE_ITEM_AMOUNT';
export const changeQbooksAccountLineItemAmount = (accountLineItemId: string, newAmount: number | null) =>
    createAction(CHANGE_QBOOKS_ACCOUNT_LINE_ITEM_AMOUNT, {
        accountLineItemId,
        newAmount,
    });

export const CHANGE_QBOOKS_ACCOUNT_LINE_ITEMS_TAX_CODE = 'REQUESTFORM/CHANGE_QBOOKS_ACCOUNT_LINE_ITEMS_TAX_CODE';
export const changeQbooksAccountLineItemsTaxCode = (
    accountLineItemIds: string[],
    newTaxCode: domain.QBooksTaxCode | null
) =>
    createSelectingAction(CHANGE_QBOOKS_ACCOUNT_LINE_ITEMS_TAX_CODE, () => ({
        accountLineItemIds,
        newTaxCode,
    }));

export const QBOOKS_EMAIL_TO_VENDOR_INIT = 'REQUESTFORM/QBOOKS_EMAIL_TO_VENDOR_INIT';
export const qbooksEmailToVendorInit = () =>
    createSelectingAction(QBOOKS_EMAIL_TO_VENDOR_INIT, (state) => {
        const request = getRequest<domain.QBooksPoRequest>(state);
        const context = getQBooksContext(state);
        const author = selectors.profile.getProfileUser(state);
        const company = selectors.company.getCompanyById(state, request.companyId);
        const details = request.details;
        const isNew = !dateTimeHelpers.toDuration(request.createdDate, request.modifiedDate).asSeconds();

        return { context, author, company, details, isNew };
    });

export const QBOOKS_EMAIL_TO_VENDOR_TOGGLE = 'REQUESTFORM/QBOOKS_EMAIL_TO_VENDOR_TOGGLE';
export const qbooksEmailToVendorToggle = (sendToVendor: boolean) =>
    createAction(QBOOKS_EMAIL_TO_VENDOR_TOGGLE, { sendToVendor });

export const QBOOKS_EMAIL_TO_CUSTOMER_INIT = 'REQUESTFORM/QBOOKS_EMAIL_TO_CUSTOMER_INIT';
export const qbooksEmailToCustomerInit = () =>
    createSelectingAction(QBOOKS_EMAIL_TO_CUSTOMER_INIT, (state) => {
        const request = getRequest<domain.QBooksInvoiceRequest>(state);
        const context = getQBooksContext(state);
        const author = selectors.profile.getProfileUser(state);
        const details = request.details;
        const isNew = !dateTimeHelpers.toDuration(request.createdDate, request.modifiedDate).asSeconds();

        return { context, author, details, isNew };
    });

export const QBOOKS_EMAIL_TO_CUSTOMER_TOGGLE = 'REQUESTFORM/QBOOKS_EMAIL_TO_CUSTOMER_TOGGLE';
export const qbooksEmailToCustomerToggle = (emailHasToBeSent: boolean) =>
    createAction(QBOOKS_EMAIL_TO_CUSTOMER_TOGGLE, { emailHasToBeSent });

export const QBOOKS_EMAIL_TO_CUSTOMER_CHANGE_DATA = 'REQUESTFORM/QBOOKS_EMAIL_TO_CUSTOMER_CHANGE_DATA';
export const qbooksEmailToCustomerChangeData = (
    value: domain.EmailToSupplier[keyof domain.EmailToSupplier],
    key: keyof domain.EmailToSupplier
) => createAction(QBOOKS_EMAIL_TO_CUSTOMER_CHANGE_DATA, { value, key });

export const QBOOKS_EMAIL_TO_VENDOR_CHANGE_DATA = 'REQUESTFORM/QBOOKS_EMAIL_TO_VENDOR_CHANGE_DATA';
export const qbooksEmailToVendorChangeData = (
    value: domain.EmailToSupplier[keyof domain.EmailToSupplier],
    key: keyof domain.EmailToSupplier
) => createAction(QBOOKS_EMAIL_TO_VENDOR_CHANGE_DATA, { value, key });

export const TOGGLE_QBOOKS_LINE_ITEM_IS_BILLABLE = 'REQUESTFORM/TOGGLE_QBOOKS_LINE_ITEM_IS_BILLABLE';
export const toggleQbooksLineItemIsBillable = (lineItemId: string, hasTaxableFeature: boolean, isBillable: boolean) =>
    createSelectingAction(TOGGLE_QBOOKS_LINE_ITEM_IS_BILLABLE, () => ({
        lineItemId,
        hasTaxableFeature,
        isBillable,
    }));

export const TOGGLE_QBOOKS_LINE_ITEM_IS_TAXABLE = 'REQUESTFORM/TOGGLE_QBOOKS_LINE_ITEM_IS_TAXABLE';
export const toggleQbooksLineItemIsTaxable = (lineItemId: string, isTaxable: boolean) =>
    createSelectingAction(TOGGLE_QBOOKS_LINE_ITEM_IS_TAXABLE, () => ({
        lineItemId,
        isTaxable,
    }));

export const TOGGLE_QBOOKS_ACCOUNT_LINE_ITEM_IS_BILLABLE = 'REQUESTFORM/TOGGLE_QBOOKS_ACCOUNT_LINE_ITEM_IS_BILLABLE';
export const toggleQbooksAccountLineItemIsBillable = (
    accountLineItemId: string,
    hasTaxableFeature: boolean,
    isBillable: boolean
) =>
    createSelectingAction(TOGGLE_QBOOKS_ACCOUNT_LINE_ITEM_IS_BILLABLE, () => ({
        accountLineItemId,
        hasTaxableFeature,
        isBillable,
    }));

export const TOGGLE_QBOOKS_ACCOUNT_LINE_ITEM_IS_TAXABLE = 'REQUESTFORM/TOGGLE_QBOOKS_ACCOUNT_LINE_ITEM_IS_TAXABLE';
export const toggleQbooksAccountLineItemIsTaxable = (accountLineItemId: string, isTaxable: boolean) =>
    createSelectingAction(TOGGLE_QBOOKS_ACCOUNT_LINE_ITEM_IS_TAXABLE, () => ({
        accountLineItemId,
        isTaxable,
    }));

export const SHOW_BOOKS_VENDOR_CREATION_POPUP = 'REQUESTFORM/SHOW_BOOKS_VENDOR_CREATION_POPUP';
export const showQBooksContactCreationPopup = () =>
    createAction(SHOW_BOOKS_VENDOR_CREATION_POPUP, {
        vendor: createEditableQBookContact(),
    });

export const CREATE_QBOOKS_VENDOR = 'REQUESTFORM/CREATE_QBOOKS_VENDOR';
export const CREATE_QBOOKS_VENDOR_RESPONSE = 'REQUESTFORM/CREATE_QBOOKS_VENDOR_RESPONSE';
export const CREATE_QBOOKS_VENDOR_FAILURE = 'REQUESTFORM/CREATE_QBOOKS_VENDOR_FAILURE';
export const createQBooksVendor = (newContact: EditableQBooksVendor) =>
    createAsyncAction({
        request: (state: State) =>
            createAction(CREATE_QBOOKS_VENDOR, {
                newContact,
                transfer: getEditableQBooksVendorTransfer(state, newContact),
                companyName: selectors.company.getCompanyById(state, getRequest(state).companyId).displayName,
            }),

        response: async (request) => {
            const createdVendor = await api.integration.createQBooksVendor(request.transfer);

            return createAction(CREATE_QBOOKS_VENDOR_RESPONSE, { createdVendor });
        },

        failure: (error) => createErrorAction(CREATE_QBOOKS_VENDOR_FAILURE, error, {}),

        successToast: intl.formatMessage({
            id: `requestForm/actions/qbooks.qbooksVendorCreationSuccess`,
            defaultMessage: 'Vendor created',
        }),

        isBlocking: true,

        didDispatchResponse: (request, response, state, dispatch) => {
            dispatch(clearCacheStorage((storageId) => String(storageId).startsWith('requestOptions')));

            const integrationCode = getRequest(state).integrationCode;
            const parsedVendor = parseQBooksContact(response.createdVendor.Vendor);

            switch (integrationCode) {
                case domain.IntegrationCode.QBooksExpense:
                    dispatch(changeQbooksNewCreatedVendor(parsedVendor));
                    break;

                default:
                    dispatch(changeQbooksVendor(parsedVendor));
            }
        },
    });

export const SHOW_QBOOKS_CUSTOMER_CREATION_POPUP = 'REQUESTFORM/SHOW_QBOOKS_CUSTOMER_CREATION_POPUP';
export const showQBooksCustomerCreationPopup = () =>
    createAction(SHOW_QBOOKS_CUSTOMER_CREATION_POPUP, {
        customer: createEditableQBookCustomer(),
    });

export const CREATE_QBOOKS_CUSTOMER = 'REQUESTFORM/CREATE_QBOOKS_CUSTOMER';
export const CREATE_QBOOKS_CUSTOMER_RESPONSE = 'REQUESTFORM/CREATE_QBOOKS_CUSTOMER_RESPONSE';
export const CREATE_QBOOKS_CUSTOMER_FAILURE = 'REQUESTFORM/CREATE_QBOOKS_CUSTOMER_FAILURE';
export const createQBooksCustomer = (newCustomer: EditableQBooksCustomer) =>
    createAsyncAction({
        request: (state: State) =>
            createAction(CREATE_QBOOKS_CUSTOMER, {
                newCustomer,
                transfer: getEditableQBooksCustomerTransfer(state, newCustomer),
                companyName: selectors.company.getCompanyById(state, getRequest(state).companyId).displayName,
            }),

        response: async (request) => {
            const createdCustomer = await api.integration.createQBooksCustomer(request.transfer);

            return createAction(CREATE_QBOOKS_CUSTOMER_RESPONSE, { createdCustomer });
        },

        failure: (error) => createErrorAction(CREATE_QBOOKS_CUSTOMER_FAILURE, error, {}),

        successToast: messages.qbooksCustomerCreationSuccess,

        isBlocking: true,

        didDispatchResponse: (_request, response, _state, dispatch) => {
            dispatch(clearCacheStorage((storageId) => String(storageId).startsWith('requestOptions')));

            const parsedCustomer = parseQBooksContact(response.createdCustomer.Customer);

            dispatch(changeQbooksCustomer(parsedCustomer));
        },
    });

export const UPDATE_QBOOKS_LINE_ITEMS_WITH_CURRENCY_EXCHANGE_RATE =
    'REQUESTFORM/UPDATE_QBOOKS_LINE_ITEMS_WITH_CURRENCY_EXCHANGE_RATE';
export const updateQBooksLineItemsWithCurrencyExchangeRate = (newExchangeRate: number | null) =>
    createAction(UPDATE_QBOOKS_LINE_ITEMS_WITH_CURRENCY_EXCHANGE_RATE, {
        newExchangeRate,
    });

export const UPDATE_QBOOKS_FORM_WITH_CONTEXT = 'UPDATE_QBOOKS_FORM_WITH_CONTEXT';
export const updateQBooksFormWithContext = () =>
    createSelectingAction(UPDATE_QBOOKS_FORM_WITH_CONTEXT, (state) => ({
        qbooksContext: getQBooksContext(state),
    }));

export const CHANGE_QBOOKS_JOURNAL_ENTRY_REQUEST = 'REQUESTFORM/CHANGE_QBOOKS_JOURNAL_ENTRY_REQUEST';
export const changeQbooksJournalEntryRequest = () => createAction(CHANGE_QBOOKS_JOURNAL_ENTRY_REQUEST, {});

export type Action = ExtractActions<
    | typeof addQbooksAccountLineItem
    | typeof addQbooksLineItem
    | typeof changeQbooksAccountLineItemsAccount
    | typeof changeQbooksAccountLineItemAmount
    | typeof changeQbooksAccountLineItemsClass
    | typeof changeQbooksAccountLineItemsCustomer
    | typeof changeQbooksAccountLineItemDescription
    | typeof changeQbooksAccountLineItemMatching
    | typeof changeQbooksAccountLineItemsTaxCode
    | typeof changeQbooksApplyTaxAfterDiscount
    | typeof changeQbooksBillingAddress
    | typeof changeQbooksCustomField
    | typeof changeQbooksCustomer
    | typeof changeQbooksCustomerMemo
    | typeof changeQbooksDate
    | typeof changeQbooksDepartment
    | typeof changeQbooksDiscount
    | typeof changeQbooksDiscountType
    | typeof changeQbooksDueDate
    | typeof changeQbooksExpenseNumber
    | typeof changeQbooksJournalEntryRequest
    | typeof changeQbooksLineAmountType
    | typeof changeQbooksLineItemChecked
    | typeof changeQbooksAccountLineItemChecked
    | typeof changeQbooksLineItemsAllChecked
    | typeof changeQbooksAccountLineItemsAllChecked
    | typeof changeQbooksLineItemsClass
    | typeof changeQbooksLineItemsCustomer
    | typeof changeQbooksLineItemDescription
    | typeof changeQbooksLineItemsItem
    | typeof changeQbooksLineItemMatching
    | typeof changeQbooksLineItemQty
    | typeof changeQbooksLineItemServiceDate
    | typeof changeQbooksLineItemsTaxCode
    | typeof changeQbooksLineItemUnitPrice
    | typeof changeQbooksMailingAddress
    | typeof changeQbooksMemo
    | typeof changeQbooksNewCreatedCustomer
    | typeof changeQbooksNewCreatedVendor
    | typeof changeQbooksNumber
    | typeof changeQbooksPayee
    | typeof changeQbooksPayeeType
    | typeof changeQbooksPaymentAccount
    | typeof changeQbooksPaymentMethod
    | typeof changeQbooksPaymentType
    | typeof changeQbooksRequestCurrency
    | typeof changeQbooksShippingAddress
    | typeof changeQbooksShippingCustomer
    | typeof changeQbooksShippingDate
    | typeof changeQbooksShippingFromAddress
    | typeof changeQbooksShippingMethod
    | typeof changeQbooksShippingPrice
    | typeof changeQbooksShippingTaxCode
    | typeof changeQbooksTaxCode
    | typeof changeQbooksTerms
    | typeof changeQbooksTrackingNumber
    | typeof changeQbooksVendor
    | typeof changeQbooksVendorMessage
    | typeof cloneQbooksAccountLineItems
    | typeof cloneQbooksLineItems
    | typeof cloneQbooksAccountLineItemsAll
    | typeof cloneQbooksLineItemsAll
    | typeof createQBooksCustomer
    | typeof createQBooksVendor
    | typeof qbooksEmailToCustomerChangeData
    | typeof qbooksEmailToCustomerInit
    | typeof qbooksEmailToCustomerToggle
    | typeof qbooksEmailToVendorChangeData
    | typeof qbooksEmailToVendorInit
    | typeof qbooksEmailToVendorToggle
    | typeof removeQbooksAccountLineItems
    | typeof removeQbooksLineItems
    | typeof removeQbooksAccountLineItemsAll
    | typeof removeQbooksLineItemsAll
    | typeof reorderQbooksAccountLineItem
    | typeof reorderQbooksLineItems
    | typeof showQBooksContactCreationPopup
    | typeof showQBooksCustomerCreationPopup
    | typeof toggleQbooksAccountLineItemIsBillable
    | typeof toggleQbooksAccountLineItemIsTaxable
    | typeof toggleQbooksLineItemIsBillable
    | typeof toggleQbooksLineItemIsTaxable
    | typeof updateQBooksFormWithContext
    | typeof updateQBooksLineItemsWithCurrencyExchangeRate
>;
