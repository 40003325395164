import { Box, Button, Flex, Text } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { actions } from 'modules/profile';
import { FC, memo, useCallback } from 'react';
import bemFactory from 'react-bem-factory';
import { useDispatch } from 'react-redux';
import { workflowPaywallOrigin } from 'shared/helpers';

import { messages } from './PromotionListItem.messages';
import { PromotionListItemProps } from './PromotionListItem.types';

const qa = bemFactory.qa('wfl-xero-card');

const PromotionListItem: FC<PromotionListItemProps> = memo((props) => {
    const { integrationCode, company, icon, ...restProps } = props;

    const dispatch = useDispatch();

    const tryNow = useCallback(
        () => dispatch(actions.openConsentToStartTrialPopup(company, workflowPaywallOrigin(integrationCode))),
        [company, dispatch, integrationCode]
    );

    return (
        <Box spacing='8 0' data-qa={qa(`list-item-promotion-${integrationCode}`)} {...restProps}>
            <Flex spacing='12' alignItems='center' wrap={false}>
                <Flex grow={1}>
                    <Text font='label' color='midnight70' ellipsis={1}>
                        {selectors.template.getTemplateWorkflowNameByCode(integrationCode)}
                    </Text>
                </Flex>

                {icon && <Flex shrink={1}>{icon}</Flex>}

                <Button size='small' color='green80' onClick={tryNow}>
                    {messages.button}
                </Button>
            </Flex>
        </Box>
    );
});

export default PromotionListItem;
