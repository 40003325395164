import { errorHelpers, intl, mathService } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { FC, memo, useMemo } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import ColorBar from '../../../../../ColorBar/ColorBar';

const i18nPrefix =
    'requestList/containers/xero/XeroMatchingV2Section/components/BillView/components/ChangeMatchingPopup/components/ChangeMatchingPopupHeader/ChangeMatchingPopupHeader';
const messages = defineMessages({
    grossAmount: {
        id: `${i18nPrefix}.grossAmount`,
        defaultMessage: 'Total (Gross):',
    },
    netAmount: {
        id: `${i18nPrefix}.netAmount`,
        defaultMessage: 'Total (Net):',
    },
});

const Header = styled.div`
    padding: 0 60px 20px 80px;
    border-bottom: 1px solid #cacccd;
`;

const Square = styled.div`
    display: inline-block;
    margin-right: 5px;
    width: 12px;
    height: 12px;
    background-color: #e9eef0;
`;

const LightGreenSquare = styled(Square)`
    background-color: #b9d4c1;
`;

const DarkGreenSquare = styled(Square)`
    background-color: #4c8a5c;
`;

const BlackSquare = styled(Square)`
    background-color: #000;
`;

const GraySquare = styled(Square)`
    background-color: #969494;
`;

const LegendContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
`;

const Legend = styled.div`
    width: 50%;
    display: flex;
    align-items: center;
    padding-right: 20px;
    margin-bottom: 14px;
`;

const LegendTitle = styled.span`
    margin-right: 10px;
    font-size: 12px;
    color: #5e5c5c;
    font-weight: 600;
`;

const LegendAmount = styled.div<{ isOverflowed?: boolean }>`
    color: #000;
    font-weight: 600;
    font-size: 12px;
    ${({ isOverflowed }) => (isOverflowed ? 'color: #ca5c41;' : '')}
`;

interface ChangeMatchingPopupHeaderProps {
    totalAmount?: number;
    totalAllocatedAmountToBilledPOs?: number;
    totalAllocatedAmount?: number;
    currency: string;
    isOverflowed: boolean;
    amountType: domain.MatchingAmountType | undefined;
}

const ChangeMatchingPopupHeader: FC<ChangeMatchingPopupHeaderProps> = (props) => {
    const {
        totalAmount = 0,
        totalAllocatedAmountToBilledPOs = 0,
        currency,
        isOverflowed,
        totalAllocatedAmount = 0,
        amountType,
    } = props;

    const remaining = mathService.subtract(
        totalAmount,
        mathService.add(totalAllocatedAmountToBilledPOs, totalAllocatedAmount)
    );

    const totalAmountMessage = useMemo(() => {
        switch (amountType) {
            case domain.MatchingAmountType.Gross:
                return intl.formatMessage(messages.grossAmount);

            case domain.MatchingAmountType.Net:
                return intl.formatMessage(messages.netAmount);

            default:
                return errorHelpers.assertNever(amountType ?? '');
        }
    }, [amountType]);

    return (
        <Header>
            <LegendContainer>
                <Legend>
                    <LegendTitle>{totalAmountMessage}</LegendTitle>

                    <LegendAmount>{intl.formatCurrency(totalAmount, currency)}</LegendAmount>
                </Legend>

                <Legend>
                    <LegendTitle>
                        <Square />

                        <FormattedMessage id={`${i18nPrefix}.remaining`} defaultMessage='Remaining amount:' />
                    </LegendTitle>

                    <LegendAmount isOverflowed={isOverflowed}>{intl.formatCurrency(remaining, currency)}</LegendAmount>
                </Legend>

                <Legend>
                    <LegendTitle>
                        <DarkGreenSquare />

                        <FormattedMessage
                            id={`${i18nPrefix}.allocatedAmountToBilledPOs`}
                            defaultMessage='Allocations to Billed POs:'
                        />
                    </LegendTitle>

                    <LegendAmount>{intl.formatCurrency(totalAllocatedAmountToBilledPOs, currency)}</LegendAmount>
                </Legend>

                <Legend>
                    <LegendTitle>
                        <LightGreenSquare />

                        <FormattedMessage
                            id={`${i18nPrefix}.allocatedAmount`}
                            defaultMessage='Allocations to not Billed POs:'
                        />
                    </LegendTitle>

                    <LegendAmount>{intl.formatCurrency(totalAllocatedAmount, currency)}</LegendAmount>
                </Legend>

                <Legend>
                    <LegendTitle>
                        <GraySquare />

                        <FormattedMessage
                            id={`${i18nPrefix}.otherAllocatedBills`}
                            defaultMessage='Allocations to Bills on Approval'
                        />
                    </LegendTitle>
                </Legend>

                <Legend>
                    <LegendTitle>
                        <BlackSquare />

                        <FormattedMessage
                            id={`${i18nPrefix}.allocatedAmountToNotBilledPOs`}
                            defaultMessage='Allocations to Approved Bills'
                        />
                    </LegendTitle>
                </Legend>
            </LegendContainer>

            <ColorBar
                darkGreenBarValue={totalAllocatedAmountToBilledPOs}
                lightGreenBarValue={totalAllocatedAmount}
                totalValue={totalAmount}
                isOverflowed={isOverflowed}
                currency={currency}
            />
        </Header>
    );
};

export default memo(ChangeMatchingPopupHeader);
