import { ui } from 'modules/common';
import { font, uTextEllipsis } from 'modules/styles';
import styled from 'styled-components';

export const Root = styled.div`
    display: flex;
    align-items: center;
    height: 60px;
    background: #384650;
    padding: 0 60px;
    user-select: none;
`;

export const Text = styled.div`
    margin-right: 10px;
    ${font(14, '#fff')}
`;

export const Delegate = styled(ui.UserHover)`
    flex: 1;
    display: flex;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
`;

export const Avatar = styled(ui.Avatar)`
    margin-right: 10px;
    flex: none;
`;

export const Name = styled.div`
    ${font(14, '#fff', 'semibold')}
    ${uTextEllipsis}
`;
