import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { myAccountApiPaths } from '../paths';
import { ActivateTrialOfAllFeatures } from './useActivateTrialOfAllFeatures.types';

export const useActivateTrialOfAllFeatures = (mutationOptions?: RequestMutationOptions<ActivateTrialOfAllFeatures>) => {
    return useMutate(myAccountApiPaths.activateTrialOfAllFeatures, {
        mutationOptions,
        apiVersion: 'v1',
        method: 'post',
        apiSource: 'myAccount',
    });
};
