import { RequestMutationOptions } from '@approvalmax/data';
import { useQueryClient } from '@tanstack/react-query';
import { notificationService } from 'services/notification';
import { useMutate } from 'shared/data';

import { companiesApiPaths } from '../paths';
import { DeleteCompany } from './useDeleteCompany.types';

export const useDeleteCompany = (mutationOptions?: RequestMutationOptions<DeleteCompany>) => {
    const queryClient = useQueryClient();

    return useMutate(companiesApiPaths.delete, {
        mutationOptions: {
            ...mutationOptions,
            onSuccess: async (data, variables, context) => {
                await queryClient.invalidateQueries([
                    companiesApiPaths.select,
                    { companyId: variables.body?.companyId },
                ]);
                mutationOptions?.onSuccess?.(data, variables, context);
            },
            onError: (error, variables, context) => {
                notificationService.showErrorToast(error.message);
                mutationOptions?.onError?.(error, variables, context);
            },
        },
        apiVersion: 'v1',
    });
};
