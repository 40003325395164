import { RequestMutationOptions } from '@approvalmax/data';
import { useQueryClient } from '@tanstack/react-query';
import { useMutate } from 'shared/data';

import { companiesApiPaths } from '../paths';
import { FinishNetSuiteIntegration } from './useFinishNetSuiteIntegration.types';

export const useFinishNetSuiteIntegration = (
    mutationOptions?: RequestMutationOptions<FinishNetSuiteIntegration<true>>
) => {
    return useMutate(companiesApiPaths.finishNetSuiteIntegration, {
        mutationOptions,
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};

export const useFinishNetSuiteIntegrationRaw = (
    mutationOptions?: RequestMutationOptions<FinishNetSuiteIntegration>
) => {
    const queryClient = useQueryClient();

    return useMutate(companiesApiPaths.finishNetSuiteIntegration, {
        mutationOptions: {
            ...mutationOptions,
            onSettled: async (data, error, variables, context) => {
                await queryClient.invalidateQueries([
                    companiesApiPaths.select,
                    { companyId: variables.body?.companyId },
                ]);

                mutationOptions?.onSettled?.(data, error, variables, context);
            },
        },
        apiVersion: 'v1',
    });
};
