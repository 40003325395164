import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.XeroAmaxPayBatchPaymentRequestCard.CancelPopupContent', {
    title: 'Please confirm the cancellation of the payment.',
    description:
        'The selected bill will be marked as cancelled, and you will lose the option to pay it with this batch payment.',
    comment: 'Comment',
    commentPlaceholder: 'Why do you want to cancel this payment?',
    confirm: 'Confirm',
});
