import { Link } from '@approvalmax/ui';
import { font } from 'modules/styles';
import styled from 'styled-components';

export const StyledLink = styled(Link)`
    ${font(12, '#477753', 'bold')}
`;

export const AmountContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const Total = styled.span`
    color: #777d81;
`;

export const Amount = styled.span`
    width: 155px;
    text-align: right;
    font-weight: bold;
`;
