import { domain } from 'modules/data';

import { ReactComponent as GoogleSsoLabel } from './assets/google-sso-label.svg';
import { ReactComponent as IntuitSsoLabel } from './assets/intuit-sso-label.svg';
import { ReactComponent as MicrosoftSsoLabel } from './assets/microsoft-sso-label.svg';
import { ReactComponent as XeroSsoLabel } from './assets/xero-sso-label.svg';

export const ssoItems = [
    {
        provider: domain.OAuthProvider.Xero,
        color: 'brandXero100',
        label: <XeroSsoLabel width={112} height={52} />,
    },
    {
        provider: domain.OAuthProvider.QBooks,
        color: 'brandIntuit100',
        label: <IntuitSsoLabel width={126} height={52} />,
    },
    {
        provider: domain.OAuthProvider.Google,
        color: 'midnight100',
        label: <GoogleSsoLabel width={172} height={52} />,
    },
    {
        provider: domain.OAuthProvider.Microsoft,
        color: 'midnight100',
        label: <MicrosoftSsoLabel width={175} height={52} />,
    },
] as const;
