import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { GetBatchPaymentAmaxPayXeroBill } from './useGetBatchPaymentAmaxPayXeroBill.types';

export const useGetBatchPaymentAmaxPayXeroBill = (
    params?: RequestParams<GetBatchPaymentAmaxPayXeroBill>,
    queryOptions?: RequestQueryOptions<GetBatchPaymentAmaxPayXeroBill>
) => {
    return useGet(requestsApiPaths.batchPaymentAmaxPayXeroBill, {
        params,
        queryOptions,
        apiVersion: 'v2',
    });
};
