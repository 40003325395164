import { AlertIcon } from '@approvalmax/ui';
import {
    Alert,
    Box,
    Button,
    Checkbox,
    Collapse,
    DatePicker,
    Flex,
    Form,
    Grid,
    Label,
    NumberField,
    Text,
    TextField,
} from '@approvalmax/ui/src/components';
import moment from 'moment';
import React, { FC, memo, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { noValue } from 'shared/constants';

import { messages } from './BillInvoiceFilter.messages';
import { BillInvoiceFilterProps } from './BillInvoiceFilter.types';
import { SuppliersSelect } from './components/SuppliersSelect/SuppliersSelect';

export const BillInvoiceFilter: FC<BillInvoiceFilterProps> = memo((props) => {
    const { requestId, companyId, resetFilter, attentionText } = props;

    const formContext = useFormContext();

    const dateFrom = formContext.watch('dateFrom');
    const dueDateFrom = formContext.watch('dueDateFrom');
    const plannedDateFrom = formContext.watch('plannedDateFrom');

    const resetForm = useCallback(() => {
        formContext.reset();
        resetFilter();
    }, [formContext, resetFilter]);

    formContext.register('dateFrom', {
        onChange: (event) => {
            const dateTo = formContext.getValues().dateTo;

            if (dateTo && event.target.value && moment(event.target.value).isAfter(moment(dateTo))) {
                formContext.setValue('dateTo', null);
            }
        },
    });
    formContext.register('dueDateFrom', {
        onChange: (event) => {
            const dueDateTo = formContext.getValues().dueDateTo;

            if (dueDateTo && event.target.value && moment(event.target.value).isAfter(moment(dueDateTo))) {
                formContext.setValue('dueDateTo', null);
            }
        },
    });
    formContext.register('plannedDateFrom', {
        onChange: (event) => {
            const plannedDateTo = formContext.getValues().plannedDateTo;

            if (plannedDateTo && event.target.value && moment(event.target.value).isAfter(moment(plannedDateTo))) {
                formContext.setValue('dueDateTo', null);
            }
        },
    });

    return (
        <Form.Part>
            <Collapse
                headerInRow
                bordered
                name={messages.title}
                spacing='0 24 16 24'
                radius='small'
                headerContent={
                    <Box width='100%'>
                        <Alert color='transparent' size='small' startIcon={<AlertIcon />}>
                            {attentionText}
                        </Alert>
                    </Box>
                }
            >
                <Grid gap={16}>
                    <Checkbox name='excludeBillsWithZeroAmount' size='small'>
                        {messages.excludeBillsWithZeroAmount}
                    </Checkbox>

                    <Grid gridTemplateColumns='1fr 1fr 1fr' columnGap='16px' rowGap='20px'>
                        <div>
                            <Label spacing='0 0 8' size='small'>
                                {messages.supplierFieldTitle}
                            </Label>

                            <SuppliersSelect requestId={requestId} companyId={companyId} />
                        </div>

                        <div>
                            <Label spacing='0 0 8' size='small'>
                                {messages.referenceFieldTitle}
                            </Label>

                            <TextField
                                maxLength={255}
                                placeholder={messages.referenceFieldPlaceholder}
                                name='reference'
                                size='small'
                            />
                        </div>

                        <div>
                            <Label spacing='0 0 8' size='small'>
                                {messages.amountFieldTitle}
                            </Label>

                            <Flex spacing='4' alignItems='center' wrap={false}>
                                <NumberField
                                    placeholder={messages.fromFieldTitle}
                                    name='amountFrom'
                                    width='100%'
                                    size='small'
                                />

                                <Text font='label' fontSize='small'>
                                    {noValue}
                                </Text>

                                <NumberField
                                    placeholder={messages.toFieldTitle}
                                    name='amountTo'
                                    width='100%'
                                    size='small'
                                />
                            </Flex>
                        </div>
                    </Grid>

                    <Grid gridTemplateColumns='repeat(auto-fill, minmax(250px, 1fr))' columnGap='16px' rowGap='20px'>
                        <div>
                            <Label spacing='0 0 8' size='small'>
                                {messages.dateFieldTitle}
                            </Label>

                            <Flex spacing='4' wrap={false}>
                                <DatePicker
                                    placeholder={messages.fromFieldTitle}
                                    width='100%'
                                    name='dateFrom'
                                    view='month'
                                    size='small'
                                />

                                <Flex height={28} alignItems='center'>
                                    <Text font='label' fontSize='small'>
                                        {noValue}
                                    </Text>
                                </Flex>

                                <DatePicker
                                    name='dateTo'
                                    placeholder={messages.toFieldTitle}
                                    width='100%'
                                    minDate={dateFrom ? new Date(dateFrom) : undefined}
                                    view='month'
                                    size='small'
                                />
                            </Flex>
                        </div>

                        <div>
                            <Label spacing='0 0 8' size='small'>
                                {messages.plannedDateFieldTitle}
                            </Label>

                            <Flex spacing='4' wrap={false}>
                                <DatePicker
                                    name='plannedDateFrom'
                                    placeholder={messages.fromFieldTitle}
                                    width='100%'
                                    view='month'
                                    size='small'
                                />

                                <Flex height={28} alignItems='center'>
                                    <Text font='label' fontSize='small'>
                                        {noValue}
                                    </Text>
                                </Flex>

                                <DatePicker
                                    minDate={plannedDateFrom ? new Date(plannedDateFrom) : undefined}
                                    name='plannedDateTo'
                                    placeholder={messages.toFieldTitle}
                                    width='100%'
                                    view='month'
                                    size='small'
                                />
                            </Flex>
                        </div>

                        <div>
                            <Label spacing='0 0 8' size='small'>
                                {messages.dueDateFieldTitle}
                            </Label>

                            <Flex spacing='4' wrap={false}>
                                <DatePicker
                                    name='dueDateFrom'
                                    placeholder={messages.fromFieldTitle}
                                    width='100%'
                                    view='month'
                                    size='small'
                                />

                                <Flex height={28} alignItems='center'>
                                    <Text font='label' fontSize='small'>
                                        {noValue}
                                    </Text>
                                </Flex>

                                <DatePicker
                                    name='dueDateTo'
                                    minDate={dueDateFrom ? new Date(dueDateFrom) : undefined}
                                    placeholder={messages.toFieldTitle}
                                    width='100%'
                                    view='month'
                                    size='small'
                                />
                            </Flex>
                        </div>
                    </Grid>

                    <Flex justifyContent='end'>
                        <Button onClick={resetForm} color='blue10' size='small'>
                            {messages.resetButtonText}
                        </Button>

                        <Button type='submit' color='midnight40' size='small'>
                            {messages.applyButtonText}
                        </Button>
                    </Flex>
                </Grid>
            </Collapse>
        </Form.Part>
    );
});

BillInvoiceFilter.displayName = 'BillInvoiceFilter';
