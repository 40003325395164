import { InfoIconGray, InfoIconGreen, InfoIconOrange, InfoIconRed } from 'modules/sprites';
import { memo } from 'react';

import { messages } from './BeneficiaryStatusLegend.messages';
import { IconWrapper, Legend, LegendItem, LegendText } from './BeneficiaryStatusLegend.styles';
import type { BeneficiaryStatusLegendProps } from './BeneficiaryStatusLegend.types';

export const BeneficiaryStatusLegend = memo<BeneficiaryStatusLegendProps>((props) => {
    const {
        redText = messages.redText,
        yellowText = messages.yellowText,
        greenText = messages.greenText,
        grayText = messages.grayText,
    } = props;

    return (
        <Legend>
            <LegendItem>
                <IconWrapper>
                    <InfoIconGreen />
                </IconWrapper>

                <LegendText>{greenText}</LegendText>
            </LegendItem>

            <LegendItem>
                <IconWrapper>
                    <InfoIconOrange />
                </IconWrapper>

                <LegendText>{yellowText}</LegendText>
            </LegendItem>

            <LegendItem>
                <IconWrapper>
                    <InfoIconRed />
                </IconWrapper>

                <LegendText>{redText}</LegendText>
            </LegendItem>

            <LegendItem>
                <IconWrapper>
                    <InfoIconGray />
                </IconWrapper>

                <LegendText>{grayText}</LegendText>
            </LegendItem>
        </Legend>
    );
});

BeneficiaryStatusLegend.displayName = 'BeneficiaryStatusLegend';
