import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { delegationsApiPaths } from '../paths';
import { GetCompanyDelegations } from './useGetCompanyDelegations.types';

export const useGetCompanyDelegations = (
    params?: RequestParams<GetCompanyDelegations>,
    queryOptions?: RequestQueryOptions<GetCompanyDelegations>
) => {
    return useGet(delegationsApiPaths.companyDelegations, {
        params,
        queryOptions,
        apiVersion: 'v2',
    });
};
