import { mods } from '@approvalmax/theme';
import { ScrollableArea } from '@approvalmax/ui';
import { StyledMods } from '@styled-kit/mods';
import { WarningLevel } from 'modules/request';
import { font } from 'modules/styles';
import ExchangeRate from 'pages/requestList/components/ExchangeRate/ExchangeRate';
import FieldsSection from 'pages/requestList/components/FieldsSection';
import styled from 'styled-components';

export const Root = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledScrollableArea = styled(ScrollableArea)`
    flex: 1;
    border-top: 1px solid #e4e4e4;

    @media only screen and (max-height: 600px) {
        overflow: unset;
    }
`;

export const StyledField = styled(FieldsSection.Field)`
    min-width: 144px;
    max-width: 144px;
    margin-right: 10px;
    margin-bottom: 34px;
`;

export const PriceIndicator = styled.div<{ warningLevel: WarningLevel }>`
    margin-left: 3px;
    border-radius: 50%;
    width: 5px;
    height: 5px;

    ${mods('warningLevel', WarningLevel.Yellow)`
        background: #ffe688;
    `}

    ${mods('warningLevel', WarningLevel.Orange)`
        background: #f0984f;
    `}

    ${mods('warningLevel', WarningLevel.Red)`
        background: #cb4e30;
    `}
`;

export const LineItemCheckBoxValueWrapper = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
`;

export const FieldsSectionWrap = styled.div<StyledMods<{ noBorder?: boolean }>>`
    display: flex;
    flex-flow: row wrap;
    padding: 30px 50px 0 60px;
    border-bottom: 1px solid #e4e4e4;

    ${mods('noBorder', true)`
        border-bottom: none;
    `}
`;

export const Footer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 12px;
`;

export const StyledExchangeRate = styled(ExchangeRate)`
    margin-bottom: 30px;
    ${font(11, '#565656', 'semibold')}
`;

export const CurrencySwitcherContainer = styled.div`
    margin-top: 12px;
    padding-left: 60px;
`;
