import { Dropdown } from '@approvalmax/ui';
import { memo, PropsWithChildren, useCallback, useState } from 'react';

import { messages } from './PaymentAmountIndicator.messages';
import { Panel, Root } from './PaymentAmountIndicator.styles';

const PaymentAmountIndicator = memo((props: PropsWithChildren) => {
    const { children } = props;

    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = useCallback(() => setIsOpen(true), []);

    const handleClose = useCallback(() => setIsOpen(false), []);

    return (
        <Dropdown
            button={(ref) => (
                <Root ref={ref} onMouseEnter={handleOpen} onMouseLeave={handleClose}>
                    {children}
                </Root>
            )}
            isOpen={isOpen}
            onRequestClose={handleClose}
        >
            <Panel>{messages.warningText}</Panel>
        </Dropdown>
    );
});

export default PaymentAmountIndicator;
