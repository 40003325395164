import { useBillLinkedToNetSuiteBillPayment } from 'pages/requestList/hooks/useBillLinkedToNetSuiteBillPayment';

export const useBillPaymentBill = ({
    companyId,
    lineRequestId,
    billPaymentRequestId,
    accountId,
    currencyId,
    payeeId,
}: {
    companyId: string;
    lineRequestId: string;
    billPaymentRequestId: string;
    accountId?: string;
    currencyId?: string;
    payeeId?: string;
}) => {
    const { request } = useBillLinkedToNetSuiteBillPayment({
        query: {
            payeeId,
            accountId,
            currencyId,
        },
        path: {
            companyId,
            lineRequestId,
            billPaymentRequestId,
        },
    });

    return {
        request,
    };
};
