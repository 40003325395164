import { MutateDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { twoFaApiPaths } from '../paths';
import { mapData } from './useStartAddingTFABackupCodes.map';
import {
    UseStartAddingTFABackupCodesBackend,
    UseStartAddingTFABackupCodesRequest,
    UseStartAddingTFABackupCodesResponse,
} from './useStartAddingTFABackupCodes.types';

export const useStartAddingTFABackupCodes = () => {
    return useMutateLegacy<
        MutateDataParams<UseStartAddingTFABackupCodesRequest>,
        UseStartAddingTFABackupCodesBackend,
        UseStartAddingTFABackupCodesResponse
    >(twoFaApiPaths.startAddingTFABackupCodes, {
        mapData,
    });
};
