import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import moment from 'moment';

import { messages } from '../messages';

export const useRequestDeadline = (request: selectors.types.ExpandedRequest) => {
    const hasDeadline = Boolean(!request.flags.status.isClosed && request.deadlineDate);

    const onApproval = request.statusV2 === domain.RequestStatusV2.OnApproval;
    const onHold = request.statusV2 === domain.RequestStatusV2.OnHold;
    const onReview = request.statusV2 === domain.RequestStatusV2.OnReview;

    if (!hasDeadline || !(onApproval || onReview || onHold)) {
        return null;
    }

    const deadlineDate = request.deadlineDate;

    const text = messages.deadlineText({ deadline: moment(deadlineDate).fromNow() });
    const title = messages.deadlineTitle({ deadline: moment(deadlineDate).format('LLL') });

    let warning: selectors.types.DueDateWarning | undefined;

    switch (selectors.request.getDueDateWarning(request, moment())) {
        case selectors.types.DueDateWarning.Overdue:
            warning = selectors.types.DueDateWarning.Overdue;
            break;

        case selectors.types.DueDateWarning.LessThanOneDay:
            warning = selectors.types.DueDateWarning.LessThanOneDay;
            break;

        default:
            warning = undefined;
            break;
    }

    return {
        title,
        text,
        warning,
    };
};
