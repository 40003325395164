import { selectors } from 'modules/common';

import { checkAccessToRequest } from '../selectors/requestSelectors';

export const getMentionedUsersWithoutAccessToRequest = (
    request: selectors.types.ExpandedRequest,
    mentionedUserIds: selectors.types.ExpandedUser['id'][]
) => {
    const { company } = request;

    return mentionedUserIds.reduce((acc, userId) => {
        const user = company.allMembers.find((member) => member.databaseId === userId);

        if (!user || !user.databaseId) {
            return acc;
        }

        const hasAccessToRequest = checkAccessToRequest(user.databaseId, user.userEmail, company, request);

        return hasAccessToRequest ? acc : [...acc, user];
    }, []);
};
