import styled from 'styled-components';

export const NoWrapCell = styled.span`
    white-space: nowrap;
`;

export const UnitPriceCell = styled(NoWrapCell)`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;
