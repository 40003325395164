import { errorHelpers } from '@approvalmax/utils';

export const facebookService = {
    init() {
        // @ts-expect-error An expression of type 'void' cannot be tested for truthiness
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        !(function (f: any, b: any, e: any, v: any, n: any, t: any, s: any) {
            if (f.fbq) return;

            n = f.fbq = function () {
                // eslint-disable-next-line
                n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
            };

            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = '2.0';
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
        })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

        window.fbq('init', '437155080749129');
        window.fbq('track', 'PageView');
    },
    trackSignUp() {
        if (window.fbq) {
            // Case when resource blocked (LinkedIn in Russia, for example)
            try {
                window.fbq('track', 'StartTrial');
            } catch (error) {
                errorHelpers.captureException(error);
            }
        }
    },
};
