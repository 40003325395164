import { WarningImage } from '@approvalmax/ui';
import { Button, Grid, Link, Popup, Text } from '@approvalmax/ui/src/components';
import { memo } from 'react';

import { messages } from './PaymentCanceledPopup.messages';
import { PaymentCanceledPopupProps } from './PaymentCanceledPopup.types';

export const PaymentCanceledPopup = memo<PaymentCanceledPopupProps>((props) => {
    const { onClose } = props;

    return (
        <Popup.Body spacing='32'>
            <Grid gap={24} justifyItems='center'>
                <WarningImage />

                <Text font='headline' fontSize='small' fontWeight='medium' textAlign='center'>
                    {messages.unableToConfirm}
                </Text>

                <Text font='body' fontSize='medium' textAlign='center'>
                    {messages.thereWasAnIssue}
                </Text>

                <Link
                    external
                    font='label'
                    fontSize='medium'
                    textAlign='center'
                    href='https://support.approvalmax.com/portal/en/kb/articles/why-the-payment-can-t-be-confirmed'
                >
                    {messages.learnMore}
                </Link>

                <Button block color='blue80' size='large' onClick={onClose}>
                    {messages.retry}
                </Button>
            </Grid>
        </Popup.Body>
    );
});

PaymentCanceledPopup.displayName = 'PaymentCanceledPopup';
