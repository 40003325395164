import { AxiosResponse } from 'axios';
import { rootApi } from 'shared/data';

import { customerFeedbackApiPaths } from '../paths';
import { GetCannyIoBoards } from './useGetCannyIoBoards.types';

export const apiRequest = async () => {
    const response: AxiosResponse<GetCannyIoBoards['response']> = await rootApi({
        url: customerFeedbackApiPaths.cannyIoBoards,
        method: 'GET',
    });

    return response.data;
};
