import { theme } from '@approvalmax/theme';
import { font } from 'modules/styles';
import styled from 'styled-components';

export const Wrapper = styled.div`
    margin-bottom: 20px;
    padding: 0 60px;
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
    padding: 16px;
    border: solid 1px #dfdfdf;
`;

export const IconContainer = styled.div`
    margin-right: 15px;
    color: ${theme.color.red80};
`;

export const Description = styled.div`
    ${font(12, '#384650')}
`;

export const BillItem = styled.div`
    margin-top: 5px;
`;
