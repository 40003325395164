import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { integrationApiPaths } from '../paths';
import { GetXeroCounterpartyPaymentDetailsForRequest } from './useGetXeroCounterpartyPaymentDetailsForRequest.types';

export const useGetXeroCounterpartyPaymentDetailsForRequest = (
    params?: RequestParams<GetXeroCounterpartyPaymentDetailsForRequest>,
    queryOptions?: RequestQueryOptions<GetXeroCounterpartyPaymentDetailsForRequest>
) => {
    return useGet(integrationApiPaths.getXeroCounterpartyPaymentDetailsForRequest, {
        params,
        queryOptions,
        apiVersion: 'v1',
        method: 'get',
    });
};
