import './documentPreviewPanel.scss';

import { GlobalHotKeys } from '@approvalmax/ui';
import { Box, Button, Flex, Progress, Text } from '@approvalmax/ui/src/components';
import { errorHelpers } from '@approvalmax/utils';
import PDFObject from 'pdfobject';
import { Component, createRef, SyntheticEvent } from 'react';
import bemFactory from 'react-bem-factory';

import * as selectors from '../../selectors';
import { messages } from './DocumentPreviewPanel.messages';
import { ContentLoading } from './DocumentPreviewPanel.styled';
import { Document } from './types';

function browserSupportsPdf() {
    return PDFObject.supportsPDFs || navigator.userAgent.indexOf('Firefox') !== -1;
}

const bem = bemFactory.block('common-ui-document-preview-panel');
const qa = bemFactory.qa('common-ui-document-preview-panel');

interface DocumentState {
    loadFailed: boolean;
    imageZoomed: boolean;
    imageSize: { width: number; height: number } | null;
}

interface Props {
    document: Document;
    className?: string;
    onRequestClose?: () => void;
    onRequestNext?: () => void;
    onRequestBack?: () => void;
}

interface State {
    loading: boolean;
    documentState: DocumentState;
}

class DocumentPreviewPanel extends Component<Props, State> {
    #imageRef = createRef<HTMLImageElement>();
    private _handlers: any;

    constructor(props: Props) {
        super(props);
        this._handlers = {
            left: this._requestGoBack,
            right: this._requestGoNext,
            escape: this._requestClose,
        };
        this.state = this._computeInitialState(props);
    }

    public UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (this.props.document.id !== nextProps.document.id) {
            this.setState(this._computeInitialState(nextProps));
        }
    }

    public componentDidMount() {
        /**
         * If there is a PDF viewer inside SplitView components, it captures all events above PDF viewer, disrupting
         * the SplitView resizer behavior. To address this issue, we implement an overlay div during resizing.
         * When not actively resizing, we hide this overlay to enable user interaction with the PDF viewer.
         */
        const resizerElement = document.querySelector('[data-type="Resizer"]');

        if (resizerElement) {
            resizerElement.addEventListener('mousedown', this._handleMouseDownOnResizer);
            document.addEventListener('mouseup', this._handleMouseUp);
        }
    }

    public componentWillUnmount() {
        const resizerElement = document.querySelector('[data-type="Resizer"]');

        resizerElement?.removeEventListener('mousedown', this._handleMouseDownOnResizer);
        document.removeEventListener('mouseup', this._handleMouseUp);
    }

    public render() {
        const { className, document: doc } = this.props;
        const docState = this.state.documentState;

        let content;

        if (docState.loadFailed) {
            content = this._renderUnknownDocument(doc);
        } else {
            switch (doc.fileType) {
                case selectors.types.FileType.Image:
                    content = this._renderImageDocument(doc);
                    break;

                case selectors.types.FileType.Pdf:
                    content = this._renderPdfDocument(doc);
                    break;

                case selectors.types.FileType.Other:
                    content = this._renderUnknownDocument(doc);
                    break;

                default:
                    throw errorHelpers.invalidOperationError();
            }
        }

        return (
            <GlobalHotKeys handlers={this._handlers}>
                <div className={bem.add(className)()}>
                    {content}

                    {this.state.loading && (
                        <ContentLoading
                            width={342}
                            direction='column'
                            justifyContent='center'
                            alignItems='center'
                            spacing='24'
                        >
                            <Progress shape='circle' />
                        </ContentLoading>
                    )}
                </div>
            </GlobalHotKeys>
        );
    }

    private _handleMouseDownOnResizer() {
        const pdfOverlayElement = document.querySelector<HTMLDivElement>('[data-type="Pdf-overlay"]');

        if (pdfOverlayElement) {
            pdfOverlayElement.style.visibility = 'visible';
        }
    }

    private _handleMouseUp() {
        const pdfOverlayElement = document.querySelector<HTMLDivElement>('[data-type="Pdf-overlay"]');

        if (pdfOverlayElement?.style.visibility === 'visible') {
            pdfOverlayElement.style.visibility = 'hidden';
        }
    }

    private _renderPdfDocument(document: Document) {
        if (!browserSupportsPdf()) {
            return this._renderUnknownDocument(document);
        }

        const documentUrl = new URL(document.url);

        if (documentUrl.protocol !== 'blob:' && !documentUrl.searchParams.has('inline')) {
            documentUrl.searchParams.append('inline', 'TRUE');
        }

        return (
            <div className={bem('content-pdf')}>
                <embed key={documentUrl.toString()} src={documentUrl.toString()} width='100%' height='100%' />

                <div className={bem('content-pdf-overlay')} data-type='Pdf-overlay' />
            </div>
        );
    }

    private _renderImageDocument(document: Document) {
        const docState = this.state.documentState;

        let style;

        if (docState.imageSize && docState.imageZoomed) {
            style = {
                width: `${docState.imageSize.width * 2}px`,
                height: `${docState.imageSize.height * 2}px`,
            };
        }

        return (
            <div
                onClick={this._requestGoNext}
                className={bem('content-image', {
                    hidden: this.state.loading,
                    zoomed: docState.imageZoomed,
                })}
            >
                <img
                    className={bem('content-image-img', { zoomed: docState.imageZoomed })}
                    data-qa={qa('content-image-img')}
                    style={style}
                    ref={this.#imageRef}
                    onLoad={this._onImageLoaded}
                    onError={this._onLoadError}
                    src={document.url}
                    onClick={this._toggleImageZoom}
                    title={document.name}
                />
            </div>
        );
    }

    private _renderUnknownDocument(document: Document) {
        return (
            <Flex justifyContent='center' alignItems='center' width='100%' height='100%'>
                <Box width='480px' spacing='24' bordered radius='medium'>
                    <Flex direction='column' justifyContent='center' alignItems='center' spacing='24'>
                        <Text textAlign='center' fontSize='small' fontWeight='regular' font='headline'>
                            {document.name}
                        </Text>

                        <Text fontWeight='regular' font='body'>
                            {messages.cannotGenerate}
                        </Text>

                        <Button
                            as='a'
                            href={document.url}
                            target='_blank'
                            rel='noopener noreferrer'
                            data-qa={qa('content-unknown-card-download-link')}
                            color='blue80'
                            size='large'
                        >
                            {messages.downloadBtnInfo}
                        </Button>
                    </Flex>
                </Box>
            </Flex>
        );
    }

    private _onImageLoaded = () => {
        this.setState({
            loading: false,
            documentState: {
                ...this.state.documentState,
                imageSize: {
                    width: this.#imageRef.current?.width ?? 0,
                    height: this.#imageRef.current?.height ?? 0,
                },
                imageZoomed: false,
            },
        });
    };

    private _onLoadError = () => {
        this.setState({
            loading: false,
            documentState: {
                ...this.state.documentState,
                loadFailed: true,
            },
        });
    };

    private _toggleImageZoom = (e: SyntheticEvent<any>) => {
        e.stopPropagation();
        this.setState({
            documentState: {
                ...this.state.documentState,
                imageZoomed: !this.state.documentState.imageZoomed,
            },
        });
    };

    private _requestGoBack = () => {
        if (this.props.onRequestBack) {
            this.props.onRequestBack();
        }
    };

    private _requestGoNext = () => {
        if (this.props.onRequestNext) {
            this.props.onRequestNext();
        }
    };

    private _requestClose = () => {
        if (this.props.onRequestClose) {
            this.props.onRequestClose();
        }
    };

    private _computeInitialState = (props: Props): State => {
        return {
            documentState: {
                loadFailed: false,
                imageZoomed: false,
                imageSize: null,
            },
            loading: props.document.fileType !== selectors.types.FileType.Other,
        };
    };
}

export default DocumentPreviewPanel;
