import type { MutateDataParams, MutationOptions } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data/api';

import { paymentServicesApiPaths } from '../paths';
import type {
    UseAmaxPayOpenBankingBankAccountMatchXeroBankAccountData,
    UseAmaxPayOpenBankingBankAccountMatchXeroBankAccountPathParams,
} from './useAmaxPayOpenBankingMatchXeroBankAccount.types';

export const useAmaxPayOpenBankingMatchXeroBankAccount = (
    mutationOptions: MutationOptions<
        {},
        MutateDataParams<
            UseAmaxPayOpenBankingBankAccountMatchXeroBankAccountData,
            UseAmaxPayOpenBankingBankAccountMatchXeroBankAccountPathParams
        >
    >
) =>
    useMutateLegacy<
        MutateDataParams<
            UseAmaxPayOpenBankingBankAccountMatchXeroBankAccountData,
            UseAmaxPayOpenBankingBankAccountMatchXeroBankAccountPathParams
        >,
        {}
    >(paymentServicesApiPaths.amaxPayOpenBankingBankAccountMatchXeroBankAccount, { mutationOptions });
