import { mods } from '@approvalmax/theme';
import { Field, font, Popup } from '@approvalmax/ui';
import { RichEditor } from '@approvalmax/ui/src/components';
import styled from 'styled-components';

export const Root = styled(Popup)``;

export const Wrap = styled(Popup.DefaultContent)`
    max-height: 80%;
    width: 660px;
`;

export const UpdateButton = styled(Popup.DefaultContent.Button)``;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow-y: auto;
    border-top: 1px solid #ebeaea;
    padding: 20px 60px 60px 60px;
`;

export const ContentDescription = styled.div`
    ${font(13, '#000', 'standard')};
    margin-bottom: 15px;
    line-height: 1.38;
`;

export const ContentField = styled(Field)<{ short?: boolean }>`
    ${mods('short', true)`
        width: 200px;
    `}
`;

export const RichEditorStyled = styled(RichEditor)`
    box-shadow: rgb(0 0 0) 0 0 0 1px inset !important;
    border-radius: 0 !important;
    min-height: 92px !important;
`;
