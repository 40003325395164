import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { myAccountApiPaths } from '../paths';
import { GetPrice } from './useGetPrice.types';

export const useGetPrice = (params?: RequestParams<GetPrice>, queryOptions?: RequestQueryOptions<GetPrice>) => {
    return useGet(myAccountApiPaths.price, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetPrice['params']['query']>(
                {
                    accountId: params?.query?.accountId,
                    productPlanId: params?.query?.productPlanId,
                    currency: params?.query?.currency,
                    billingCycle: params?.query?.billingCycle,
                    quantity: params?.query?.quantity,
                    planEdition: params?.query?.planEdition,
                },
                queryOptions
            ),
        },
        apiVersion: 'v1',
        apiSource: 'myAccount',
    });
};
