import { useTheme } from '@approvalmax/theme';
import { AmLogo, AndroidIcon, AppleIcon } from '@approvalmax/ui';
import { Button, Flex, Text } from '@approvalmax/ui/src/components';
import { memo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useMedia } from 'react-use';
import { amplitudeService } from 'services/amplitude';

import { appUrls } from './MobileApps.constants';
import { messages } from './MobileApps.messages';

export const MobileApps = memo(() => {
    const location = useLocation();

    const { theme } = useTheme();
    const isTabletScreen = useMedia(`(max-width: ${theme.container.breakpoint.medium})`);
    const isTabletOnlyScreen = useMedia(
        `(max-width: ${theme.container.breakpoint.medium}) and (min-width: ${theme.container.viewport.xsmall})`
    );

    const sendDecisionToAmplitude = useCallback(
        (os: string) => {
            amplitudeService.sendData(`help: click on mobile app link on "${location.pathname}" screen`, {
                'mobile app type': os,
            });
        },
        [location.pathname]
    );

    const handleDownloadIOS = useCallback(() => {
        sendDecisionToAmplitude('ios');
    }, [sendDecisionToAmplitude]);

    const handleDownloadAndroid = useCallback(() => {
        sendDecisionToAmplitude('android');
    }, [sendDecisionToAmplitude]);

    return (
        <Flex
            direction={isTabletOnlyScreen ? 'row' : 'column'}
            alignItems='center'
            justifyContent={isTabletOnlyScreen ? 'space-between' : undefined}
            width={isTabletOnlyScreen ? undefined : 176}
        >
            <Flex
                direction={isTabletOnlyScreen ? 'row' : 'column'}
                spacing='4'
                alignItems='center'
                width={isTabletOnlyScreen ? undefined : '100%'}
            >
                <AmLogo width={isTabletOnlyScreen ? 159 : 108} height={isTabletOnlyScreen ? 24 : 16} />

                <Text font='label' fontSize={isTabletOnlyScreen ? 'large' : 'small'} color='midnight70'>
                    {messages.intro}
                </Text>
            </Flex>

            <Flex
                direction={isTabletOnlyScreen ? 'row' : 'column'}
                spacing='12'
                width={isTabletOnlyScreen ? undefined : '100%'}
            >
                <Button
                    as='a'
                    onClick={handleDownloadIOS}
                    color={isTabletScreen ? 'midnight80' : 'white100'}
                    target='_blank'
                    href={appUrls.ios}
                    startIcon={<AppleIcon />}
                    block={!isTabletOnlyScreen}
                    outline={isTabletScreen}
                >
                    {messages.appStore}
                </Button>

                <Button
                    as='a'
                    onClick={handleDownloadAndroid}
                    color={isTabletScreen ? 'midnight80' : 'white100'}
                    target='_blank'
                    href={appUrls.android}
                    startIcon={<AndroidIcon />}
                    block={!isTabletOnlyScreen}
                    outline={isTabletScreen}
                >
                    {messages.googlePlay}
                </Button>
            </Flex>
        </Flex>
    );
});

MobileApps.displayName = 'MobileApps';
