import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { PutOnHold } from './usePutOnHold.types';

export const usePutOnHold = (mutationOptions?: RequestMutationOptions<PutOnHold>) => {
    return useMutate(requestsApiPaths.putOnHold, {
        mutationOptions,
        apiVersion: 'v2',
    });
};
