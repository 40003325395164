import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useCallback, useMemo } from 'react';

export const useCompanyMentionItems = (company: selectors.types.ExpandedCompany) => {
    const { allMembers } = company;

    const activeMembers = useMemo(
        () => allMembers.filter((member) => member.status === domain.CompanyUserStatus.Active),
        [allMembers]
    );

    const handleGetMentionItems = useCallback(
        ({ query }: { query: string }) => {
            return activeMembers
                .map((member) => ({
                    name: member.displayName,
                    email: member.userEmail,
                    avatar: member.avatar,
                    userId: member.databaseId,
                }))
                .filter(
                    (item) =>
                        item.name.toLowerCase().startsWith(query.toLowerCase()) ||
                        item.email.toLowerCase().startsWith(query.toLowerCase())
                )
                .slice(0, 100);
        },
        [activeMembers]
    );

    return {
        handleGetMentionItems,
    };
};
