import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('helpers.exchangeRate', {
    sourceXero: 'provided by Xero',
    sourceQBooks: 'provided by QuickBooks Online',
    sourceNetSuite: 'provided by NetSuite',
    sourceOpenExchangeRate: 'provided by Openexchangerates.org',
    sourceFixer: 'provided by Fixer.io',
    sourceCoinLayer: 'provided by CoinLayer.com',
    sourceBills: 'provided by each included Bill',
    sourceManual: 'Adjusted manually by requester',
});
