import { AddIcon, DeleteIcon } from '@approvalmax/ui';
import { Button, Flex, Form, Grid, Text, TextField } from '@approvalmax/ui/src/components';
import {
    addStep,
    finalizeAddStep,
    removeStep,
} from 'app/(workspace)/[companyId]/workflows/[workflowId]/resources/actions';
import { ComponentProps, FC, memo, useCallback } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useMount } from 'react-use';
import { amplitudeService } from 'services/amplitude';

import { FooterButtons, HeaderTitle, ScrollableContent } from '..';
import { formFieldName } from './ApprovalStepsStep.constants';
import { messages } from './ApprovalStepsStep.messages';
import { ApprovalStepsStepProps, FormValues } from './ApprovalStepsStep.types';

export const ApprovalStepsStep: FC<ApprovalStepsStepProps> = memo((props) => {
    const { template, defaultSteps, amplitudeParams, onNext, onBack } = props;

    const dispatch = useDispatch();

    const form = useForm<FormValues>({
        defaultValues: {
            [formFieldName]: defaultSteps ?? [{ name: '' }],
        },
    });
    const { fields, append, remove } = useFieldArray({
        control: form.control,
        name: formFieldName,
    });

    useMount(() => {
        amplitudeService.sendData('Workflow: land on wizard: approval steps', amplitudeParams);
    });

    const handleNext = form.handleSubmit((data) => {
        template.steps.forEach(() => dispatch(removeStep(0)));

        for (let step of data[formFieldName]) {
            dispatch(addStep(step.name?.trim()));
            dispatch(finalizeAddStep());
        }

        onNext(data[formFieldName]);
    });

    const handleAdd = useCallback(() => {
        append({
            name: '',
        });
    }, [append]);

    const handleEnter = useCallback<Required<ComponentProps<typeof TextField>>['onEnter']>(
        (e) => {
            e?.preventDefault();
            handleAdd();
        },
        [handleAdd]
    );

    return (
        <Form form={form} onSubmit={handleNext}>
            <ScrollableContent
                header={<HeaderTitle spacing='32 0' title={messages.title} subtitle={messages.subtitle} />}
                footer={<FooterButtons isNextTypeSubmit onBack={onBack} />}
            >
                <Grid gap={8}>
                    {fields.map((field, index) => {
                        return (
                            <Flex key={field.id} spacing='16'>
                                <Flex grow={1}>
                                    <TextField
                                        name={`${formFieldName}.${index}.name`}
                                        endIcon={
                                            <Text font='label' fontSize='large' color='midnight70' wrap={false}>
                                                {messages.step({
                                                    number: index + 1,
                                                })}
                                            </Text>
                                        }
                                        placeholder={messages.placeholder}
                                        size='large'
                                        clearable={false}
                                        onEnter={handleEnter}
                                        initFocus={index === fields.length - 1}
                                    />
                                </Flex>

                                {index === fields.length - 1 && (
                                    <Button
                                        color='blue80'
                                        size='large'
                                        outline
                                        onClick={handleAdd}
                                        startIcon={<AddIcon size={20} />}
                                    >
                                        {messages.addStep}
                                    </Button>
                                )}

                                {fields.length > 1 && (
                                    <Button size='large' color='red40' onClick={() => remove(index)}>
                                        <DeleteIcon size={20} />
                                    </Button>
                                )}
                            </Flex>
                        );
                    })}
                </Grid>
            </ScrollableContent>
        </Form>
    );
});

ApprovalStepsStep.displayName = 'ApprovalStepsStep';
