import { QueryOptions } from '@approvalmax/data';
import { useGetBillLinkedToBatchPayment, UseGetBillLinkedToBatchPaymentBackend } from 'shared/data';

import type { UseBillLinkedToAirwallexBatchPaymentParams } from './useBillLinkedToAirwallexBatchPayment.types';
import { useNormalizedLinkedBillResponse } from './useNormalizedLinkedBillResponse';

export const useBillLinkedToAirwallexBatchPayment = (
    { companyId, billId, batchPaymentRequestId }: UseBillLinkedToAirwallexBatchPaymentParams,
    queryOptions?: QueryOptions<UseGetBillLinkedToBatchPaymentBackend>
) => {
    return useNormalizedLinkedBillResponse(
        billId,
        useGetBillLinkedToBatchPayment(
            {
                companyId,
                requestId: billId,
                batchPaymentRequestId,
            },
            queryOptions
        )
    );
};
