import { intl, mathService } from '@approvalmax/utils';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys, selectors } from 'modules/common';
import { backend, domain } from 'modules/data';
import { useGetUserCompanyById } from 'modules/utils';
import { useMemo } from 'react';

import { useXeroBillMatchingData } from '../../../hooks';
import { XeroMatchingBillMatchingData } from '../../../selectors/types/matchingTypes';
import { getRequestDetailsMapped } from './Toolbar.helpers';
import { messages } from './Toolbar.messages';

const integrationsThatDontNeedRequestDetails: Array<domain.IntegrationCode | null> = [
    domain.IntegrationCode.XeroContact,
    domain.IntegrationCode.XeroBillBatchPayment,
    domain.IntegrationCode.XeroAirwallexBatchPayment,
    domain.IntegrationCode.XeroAmaxPayBatchPayment,
    domain.IntegrationCode.NetSuiteExpenseReport,
    domain.IntegrationCode.NetSuitePO,
    domain.IntegrationCode.NetSuiteSalesOrder,
    domain.IntegrationCode.NetSuiteVRA,
    domain.IntegrationCode.NetSuiteBillPayment,
    domain.IntegrationCode.NetSuiteInvoice,
    domain.IntegrationCode.NetSuiteJournalEntry,
    domain.IntegrationCode.NetSuiteVendor,
    domain.IntegrationCode.DearPo,
    domain.IntegrationCode.QBooksJournalEntry,
];

export const useRequestDetails = (
    request: selectors.types.ExpandedRequest,
    preloadedRequestDetails?: {
        possibleDuplicates?: domain.PossibleDuplicateItem[];
        counterparty?: backend.CounterpartyDetails;
    }
) => {
    const shouldFetchRequestDetails = useMemo(
        () => !integrationsThatDontNeedRequestDetails.includes(request.integrationCode) && !preloadedRequestDetails,
        [preloadedRequestDetails, request.integrationCode]
    );

    const { isInitialLoading, data, refetch } = useQuery(
        [QueryKeys.CONTACT_INFO, request.id],
        () => getRequestDetailsMapped(request.id, request.companyId),
        {
            staleTime: Infinity,
            enabled: shouldFetchRequestDetails,
        }
    );

    return {
        isInitialLoading,
        data: shouldFetchRequestDetails ? data : preloadedRequestDetails,
        refetch,
    };
};

export function useGetXeroMatchingV2ValidationError(
    request: selectors.types.ExpandedRequest,
    billMatchingData?: XeroMatchingBillMatchingData
): selectors.types.RequestMatchingValidationError | null {
    if (!request.company.flags.isXeroMatchingV2) {
        return null;
    }

    const billMatchingSettings = request.company.billMatchingSettings;
    const manualMatchingEnabled = billMatchingSettings?.manualMatchingEnabled;

    if (
        !manualMatchingEnabled ||
        request.integrationCode !== domain.IntegrationCode.XeroBill ||
        (request.statusV2 !== domain.RequestStatusV2.OnApproval &&
            request.statusV2 !== domain.RequestStatusV2.OnHold) ||
        !billMatchingData
    ) {
        // Only open bills with no resolution might have violations
        return null;
    }

    const BillApprovalPolicy = domain.CompanyMatchingSettingsBillApprovalPolicy;

    // Can a Bill be approved if it is not yet matched to a Purchase Order?
    switch (billMatchingSettings.allowApprovalOfNotMatchedBills) {
        case BillApprovalPolicy.Never: {
            if (billMatchingData.totalAllocatedAmountToThisBill === 0) {
                return {
                    type: selectors.types.RequestMatchingValidationErrorType.MatchingRequired,
                    errorText: messages.matchingPolicyViolationNotMatchedToAnyPurchaseOrder({
                        bill: request.displayName,
                    }),
                };
            }

            break;
        }

        case BillApprovalPolicy.WithThreshold: {
            const { exchangeRate } = request;

            const matchingTotal =
                billMatchingData.currency === request.company.defaultCurrency
                    ? billMatchingData.totalAmount
                    : mathService.divide(billMatchingData.totalAmount, exchangeRate ?? 1);

            if (
                !billMatchingData.totalAllocatedAmountToThisBill &&
                matchingTotal > billMatchingSettings.notMatchedBillsApprovalThreshold
            ) {
                return {
                    type: selectors.types.RequestMatchingValidationErrorType.MatchingRequired,
                    errorText: messages.matchingPolicyViolationNotMatchedToAnyPurchaseOrderAndExceedsThreshold({
                        bill: request.displayName,
                        currency: request.company.defaultCurrency,
                        threshold: intl.formatNumber(billMatchingSettings.notMatchedBillsApprovalThreshold),
                    }),
                };
            }

            break;
        }
    }

    return null;
}

export function useGetQBooksMatchingValidationError(
    request: selectors.types.ExpandedRequest
): selectors.types.RequestMatchingValidationError | null {
    if (request.company.integration?.integrationType !== domain.IntegrationType.QBooks) {
        return null;
    }

    return request.company.flags.isQBooksMatching && request.integrationCode === domain.IntegrationCode.QBooksBill
        ? selectors.request.getQbooksMatchingValidationError(request, request.company)
        : null;
}

export function useGetXeroMatchingV2InsufficientBudgetError(
    request: selectors.types.ExpandedRequest,
    preloadedMatchingData?: XeroMatchingBillMatchingData
): selectors.types.RequestMatchingValidationError | null {
    const canInsufficientBudgetMatchBeApproved = useInsufficientBudgetMatch(request, preloadedMatchingData);

    if (!request.company.flags.isXeroMatchingV2) {
        return null;
    }

    const billMatchingSettings = request.company.billMatchingSettings;

    if (
        canInsufficientBudgetMatchBeApproved ||
        request.integrationCode !== domain.IntegrationCode.XeroBill ||
        (request.statusV2 !== domain.RequestStatusV2.OnApproval && request.statusV2 !== domain.RequestStatusV2.OnHold)
    ) {
        // Only open bills with no resolution might have violations
        return null;
    }

    if (!canInsufficientBudgetMatchBeApproved) {
        switch (billMatchingSettings?.insufficientBudgetMatchBillsApprovalAllowed) {
            case domain.ApprovalAllowanceType.WithThreshold:
            case domain.ApprovalAllowanceType.Never:
                return {
                    type: selectors.types.RequestMatchingValidationErrorType.InsufficientBudget,
                    errorText: messages.insufficientBudgetWithThresholdOrNever({ requestName: request.displayName }),
                };

            case domain.ApprovalAllowanceType.WithPercentageThreshold:
                return {
                    type: selectors.types.RequestMatchingValidationErrorType.InsufficientBudget,
                    errorText: messages.insufficientBudgetWithPercentageThreshold({
                        requestName: request.displayName,
                        threshold: billMatchingSettings.insufficientBudgetMatchBillsApprovalPercentageThreshold,
                    }),
                };
        }
    }

    return null;
}

export const useInsufficientBudgetSettings = (companyId: string) => {
    const company = useGetUserCompanyById(companyId);

    if (company?.integration?.integrationType !== domain.IntegrationType.Xero) {
        return {
            allowanceType: domain.ApprovalAllowanceType.Always,
            threshold: 0,
            percentageThreshold: 0,
        };
    }

    const allowanceType =
        company?.billMatchingSettings?.insufficientBudgetMatchBillsApprovalAllowed ||
        domain.ApprovalAllowanceType.Always;

    const threshold = company?.billMatchingSettings?.insufficientBudgetMatchBillsApprovalThreshold || 0;

    const percentageThreshold =
        company?.billMatchingSettings?.insufficientBudgetMatchBillsApprovalPercentageThreshold || 0;

    return {
        allowanceType,
        threshold,
        percentageThreshold,
    };
};

export const useInsufficientBudgetMatch = (
    request: selectors.types.ExpandedRequest,
    preloadedMatchingData?: XeroMatchingBillMatchingData
) => {
    const company = useGetUserCompanyById(request.companyId);
    const billMatchingData = useXeroBillMatchingData(request, preloadedMatchingData).data;

    const settings = useInsufficientBudgetSettings(request.companyId);

    if (company?.integration?.integrationType !== domain.IntegrationType.Xero) {
        return false;
    }

    const isUnderMatchingRequiredThreshold =
        company.billMatchingSettings?.allowApprovalOfNotMatchedBills ===
            domain.CompanyMatchingSettingsBillApprovalPolicy.WithThreshold &&
        billMatchingData &&
        billMatchingData.totalAmount <= (request.company.billMatchingSettings?.notMatchedBillsApprovalThreshold ?? 0);

    const isMatchingV2 = company?.flags.isXeroMatchingV2 || company?.flags.isXeroMatchingV2ReadOnly;
    const isBill = request.integrationCode === domain.IntegrationCode.XeroBill;

    const isAlwaysAllowed = settings.allowanceType === domain.ApprovalAllowanceType.Always;

    const isAllowedThreshold =
        settings.allowanceType === domain.ApprovalAllowanceType.WithThreshold && billMatchingData
            ? billMatchingData.totalAmount <= settings.threshold ||
              billMatchingData.remaining === 0 ||
              isUnderMatchingRequiredThreshold
            : false;

    const isAllowedPercentageThreshold =
        settings.allowanceType === domain.ApprovalAllowanceType.WithPercentageThreshold && billMatchingData
            ? (billMatchingData.remaining / billMatchingData.totalAmount) * 100 < settings.percentageThreshold ||
              billMatchingData.remaining === 0 ||
              isUnderMatchingRequiredThreshold
            : false;

    const isNeverAllowed =
        settings.allowanceType === domain.ApprovalAllowanceType.Never && billMatchingData
            ? billMatchingData.allocatedAmount >= billMatchingData.totalAmount || isUnderMatchingRequiredThreshold
            : false;

    return isBill
        ? isMatchingV2 && (isAlwaysAllowed || isAllowedThreshold || isAllowedPercentageThreshold || isNeverAllowed)
        : true;
};
