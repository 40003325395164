import { selectors } from 'modules/common';
import { domain } from 'modules/data';

import { messages } from './requestActivitySelectors.NetSuite.messages';

export const isNetSuiteSystemEvent = (request: selectors.types.ExpandedRequest, event: domain.RequestHistoryEvent) => {
    if (!event.isSystem || request.integrationType !== domain.IntegrationType.NetSuite) {
        return false;
    }

    switch (event.type) {
        case domain.RequestHistoryEventType.RejectedExternally:
        case domain.RequestHistoryEventType.PostApprovalChangeDetected:
        case domain.RequestHistoryEventType.UpdatedExternallyResetTemplate:
        case domain.RequestHistoryEventType.AttachmentUploadFailed:
            return true;

        default:
            return false;
    }
};

export const getNetSuiteEventHeader = (request: selectors.types.ExpandedRequest, event: domain.RequestHistoryEvent) => {
    switch (event.type) {
        case domain.RequestHistoryEventType.NetSuiteApprovedExternally:
            return messages.approvedExternally;

        case domain.RequestHistoryEventType.RejectedExternally:
            return messages.rejectedExternally;

        case domain.RequestHistoryEventType.PostApprovalChangeDetected:
            return messages.postApprovalChangeDetected;

        case domain.RequestHistoryEventType.UpdatedExternallyResetTemplate:
            return messages.updatedExternallyResetTemplate;

        case domain.RequestHistoryEventType.EmailToPartnerSent:
            return messages.emailToPartnerSent;

        default:
            return null;
    }
};
