import { intl } from '@approvalmax/utils';
import { FC, memo, useMemo } from 'react';

import { messages } from './QBooksPOLineRemaining.messages';
import { Root, Value } from './QBooksPOLineRemaining.styles';
import { QBooksPOLineRemainingProps } from './QBooksPOLineRemaining.types';

const QBooksPOLineRemaining: FC<QBooksPOLineRemainingProps> = memo((props) => {
    const { matchingInfo, isAccountLineItem, request, lineItemId } = props;
    const totalAmountMatchedBills = useMemo(
        () =>
            matchingInfo.reduce((acc, { accountLineItem, lineItem }) => {
                const itemAmount = isAccountLineItem ? accountLineItem?.amount : lineItem?.amount;

                return acc + (itemAmount || 0);
            }, 0),
        [isAccountLineItem, matchingInfo]
    );

    const lineItem = useMemo(() => {
        const details = request.details;

        if (isAccountLineItem) {
            return details.accountLineItems.find((lineItem) => lineItem.id === lineItemId);
        }

        return details.lineItems.find((lineItem) => lineItem.id === lineItemId);
    }, [isAccountLineItem, lineItemId, request]);

    const remainingBalance = lineItem?.amount ? lineItem.amount - totalAmountMatchedBills : 0;

    return (
        <Root>
            {messages.remainingBalance({
                remainingBalance: intl.formatCurrency(remainingBalance, request.currency),
                b: (chunks) => <Value>{chunks}</Value>,
            })}
        </Root>
    );
});

export default QBooksPOLineRemaining;
