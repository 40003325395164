import { font } from '@approvalmax/ui';
import styled from 'styled-components';

export const Legend = styled.div`
    padding: 10px 0;
    display: flex;
    position: relative;
    justify-content: space-around;
    gap: 20px;
    border: 1px solid #cacccd;
`;

export const LegendItem = styled.div`
    display: flex;
    align-items: center;
`;

export const LegendText = styled.span`
    ${font(12, '#5e5c5c')};
`;

export const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-right: 8px;
`;
