import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.XeroManualJournalLinesSection', {
    title: 'Line items',
    accountColName: 'Account',
    debitColName: 'Debit',
    creditColName: 'Credit',
    descriptionColName: 'Description',
    taxColName: 'Tax',
    taxAmountColName: 'Tax Amount',
    numberColName: '#',
    debitTitle: 'Debit',
    creditTitle: 'Credit',
    totalTitle: 'Total',
    subtotalTitle: 'Subtotal',
    taxInclusiveText: 'Amounts are Tax Inclusive',
    taxExclusiveText: 'Amounts are Tax Exclusive',
});
