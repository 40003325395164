import { mods } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import { selectors } from 'modules/common';
import { font } from 'modules/styles';
import styled from 'styled-components';

export const DeadlineWarn = styled.div<StyledMods<{ warning?: selectors.types.DueDateWarning }>>`
    flex: none;
    margin-top: 10px;
    ${font(12, '#6c6a6a', 'semibold')}

    ${mods('warning', selectors.types.DueDateWarning.LessThanOneDay)`
        color: #f0984f;
    `}

    ${mods('warning', selectors.types.DueDateWarning.Overdue)`
        color: #cb4e30;
    `}
`;
