import { collapseDrawerState, Divider, Drawer as DrawerUI, Spacing } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { memo, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { isPayOrCapturePromotionsAllowed } from 'shared/helpers';
import { useAddonPromotions } from 'shared/hooks/useAddonPromotions';
import { usePermissions } from 'shared/hooks/usePermissions';
import { activeCompanyIdState } from 'shared/states';
import { AddonPromotionType, pageVisiblePromotionsState } from 'shared/states';

import {
    AllCompaniesMenu,
    AllRequestsMenu,
    BudgetingMenu,
    CompanyAlerts,
    CompanySelector,
    FeedbackPromotion,
    Invitations,
    NavigationPromotion,
    PayMenu,
    ReportsMenu,
    SettingsMenu,
    WorkflowsMenu,
} from './components';
import { DrawerPayAndCapturePromotion } from './components/DrawerPayAndCapturePromotion/DrawerPayAndCapturePromotion';
import { useCompanies, useHandleMenuClick } from './Drawer.hooks';

const Drawer = memo(() => {
    const contentRef = useRef<HTMLDivElement>(null);
    const activeCompanyId = useRecoilValue(activeCompanyIdState);
    const isDrawerCollapsed = useRecoilValue(collapseDrawerState);
    const pageVisiblePromotions = useRecoilValue(pageVisiblePromotionsState);

    const invitations = useSelector(selectors.companyInvitation.getPendingCompanyInvitations);
    const profile = useSelector(selectors.profile.findProfile);
    const { companies, company } = useCompanies();
    const { canShowPromotion } = useAddonPromotions();

    const { isPaymentDetailsViewEnabled } = usePermissions(company);

    const isAuditorOrManager = company?.flags.isAuditor || company?.flags.isManager;
    const isWorkflowManager = company?.flags.isWorkflowManager;

    const isSimpleUser = !isAuditorOrManager && !isWorkflowManager;

    const showPromotions = !isDrawerCollapsed && !pageVisiblePromotions;

    const showPayPromotion =
        showPromotions &&
        company &&
        isPayOrCapturePromotionsAllowed(company, AddonPromotionType.Pay) &&
        canShowPromotion(AddonPromotionType.Pay);

    const showCapturePromotion =
        showPromotions &&
        !showPayPromotion &&
        company &&
        isPayOrCapturePromotionsAllowed(company, AddonPromotionType.Capture) &&
        canShowPromotion(AddonPromotionType.Capture);

    const showFeedbackPromotion = showPromotions && !showPayPromotion && !showCapturePromotion;

    const showSettingsSectionForSimpleUser = isSimpleUser && isPaymentDetailsViewEnabled;

    const isPayMenuAvailable = company?.betaFeatures.includes(domain.CompanyBetaFeature.WalletsUK);

    const workflows = useSelector((state) =>
        company ? selectors.meta.getViewableTemplatesByCompanyId(state, company.id) : []
    );

    const handleMenuClick = useHandleMenuClick(company, profile);

    if (!companies.length) return null;

    return (
        <DrawerUI
            notifications={<Invitations invitations={invitations} />}
            notificationsCount={invitations.length}
            contentRef={contentRef}
        >
            {showPayPromotion && <DrawerPayAndCapturePromotion type={AddonPromotionType.Pay} />}

            {showCapturePromotion && <DrawerPayAndCapturePromotion type={AddonPromotionType.Capture} />}

            {showFeedbackPromotion && <FeedbackPromotion />}

            <AllCompaniesMenu onClick={handleMenuClick} />

            {isDrawerCollapsed ? (
                <Divider spacing='8 12' color='midnight50' />
            ) : (
                <CompanySelector
                    companies={companies}
                    canCreateNewCompanies={!!profile?.canCreateNewCompanies}
                    root={contentRef.current}
                />
            )}

            {company?.id === activeCompanyId && (
                <>
                    {!isDrawerCollapsed && (
                        <CompanyAlerts company={company} profileCreatedDate={profile?.createdDate} />
                    )}

                    <AllRequestsMenu company={company} onClick={handleMenuClick} />

                    {workflows.length > 0 && (
                        <>
                            <Divider spacing='8 12' color='midnight50' />

                            <WorkflowsMenu company={company} onClick={handleMenuClick} />
                        </>
                    )}

                    {company?.flags.isManager && isPayMenuAvailable && (
                        <>
                            <Divider spacing='8 12' color='midnight50' />

                            <PayMenu company={company} onClick={handleMenuClick} />
                        </>
                    )}

                    {isAuditorOrManager && (
                        <>
                            <Divider spacing='8 12' color='midnight50' />

                            <ReportsMenu company={company} onClick={handleMenuClick} />

                            <BudgetingMenu company={company} onClick={handleMenuClick} />

                            <SettingsMenu company={company} onClick={handleMenuClick} />
                        </>
                    )}

                    {(isWorkflowManager || showSettingsSectionForSimpleUser) && (
                        <>
                            <Divider spacing='8 12' color='midnight50' />

                            <SettingsMenu company={company} />
                        </>
                    )}

                    {!isDrawerCollapsed && company.integration?.integrationType && (
                        <>
                            <Spacing height={16} />

                            <NavigationPromotion company={company} />
                        </>
                    )}
                </>
            )}
        </DrawerUI>
    );
});

export default Drawer;
