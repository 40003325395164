import { MutateDataParams } from '@approvalmax/data';
import { useQueryClient } from '@tanstack/react-query';
import { useMutateLegacy } from 'shared/data';

import { companiesApiPaths } from '../../../v1/queries/companies/paths';
import { paymentServicesApiPaths } from '../paths';
import { UseDisconnectFromAirwallexData } from './useDisconnectFromAirwallex.types';

export const useDisconnectFromAirwallex = () => {
    const queryClient = useQueryClient();

    return useMutateLegacy<MutateDataParams<UseDisconnectFromAirwallexData>>(
        paymentServicesApiPaths.disconnectFromAirwallex,
        {
            mutationOptions: {
                onSuccess: (_response, requestData) => {
                    queryClient.invalidateQueries([
                        companiesApiPaths.select,
                        { companyId: requestData.body?.companyId },
                    ]);
                },
            },
        }
    );
};
