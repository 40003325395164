import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { integrationApiPaths } from '../paths';
import { GetNetSuiteFields } from './useGetNetSuiteFields.types';

export const useGetNetSuiteFields = (
    params?: RequestParams<GetNetSuiteFields>,
    queryOptions?: RequestQueryOptions<GetNetSuiteFields>
) => {
    return useGet(integrationApiPaths.getNetSuiteFields, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetNetSuiteFields['params']['query']>(
                {
                    companyId: params?.query?.companyId,
                },
                queryOptions
            ),
        },
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};
