import { FC, memo } from 'react';

import FieldEntry from '../../../../../components/FieldEntry';
import SectionSeparator from '../../../../../components/SectionSeparator/SectionSeparator';
import SectionHeaderText from '../SectionHeaderText/SectionHeaderText';
import { messages } from './RequestContactPersonSection.messages';
import { EmailField, FieldSection, NameField } from './RequestContactPersonSection.styles';
import { RequestContactPersonSectionProps } from './RequestContactPersonSection.types';
import { getFullName } from './RequestContactPersonSection.utils';

const RequestContactPersonSection: FC<RequestContactPersonSectionProps> = memo((props) => {
    const { contact } = props;

    const primaryPersonFullName = getFullName(contact);

    const hideSection =
        !contact.accountNumber && !primaryPersonFullName && !contact.emailAddress && !contact.persons?.length;

    if (hideSection) {
        return null;
    }

    return (
        <>
            <div>
                <SectionHeaderText>{messages.headerText}</SectionHeaderText>

                {contact.accountNumber && (
                    <FieldSection>
                        <FieldEntry title={messages.accountNumber} value={contact.accountNumber} />
                    </FieldSection>
                )}

                {(primaryPersonFullName || contact.emailAddress) && (
                    <FieldSection>
                        <NameField title={messages.primaryPerson} value={primaryPersonFullName} hideIfEmpty />

                        <EmailField title={messages.primaryPersonEmail} value={contact.emailAddress} hideIfEmpty />
                    </FieldSection>
                )}

                {contact.persons.map((person, index) => (
                    <FieldSection key={index}>
                        <NameField
                            title={messages.anotherPerson({
                                index: index + 1,
                            })}
                            value={getFullName(person)}
                        />

                        <EmailField
                            title={messages.anotherPersonEmail({
                                index: index + 1,
                            })}
                            value={person.emailAddress}
                        />
                    </FieldSection>
                ))}
            </div>

            <SectionSeparator />
        </>
    );
});

export default RequestContactPersonSection;
