import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';

import { getMemberOrUser } from '../../../selectors/userSelectors';
import { isReviewer } from './StepParticipantHover.helpers';
import { messages } from './StepParticipantHover.messages';
import { StepParticipantHoverProps } from './StepParticipantHover.types';

export const useHoverText = (
    participant: StepParticipantHoverProps['participant'],
    request: selectors.types.ExpandedRequest
) => {
    const me = useSelector(selectors.profile.getProfileUser);
    const allUsers = useSelector(selectors.user.getUsers);
    const team = useSelector((state) => selectors.company.getCompanyTeam(state, request.company));
    const user = (userId: string) => getMemberOrUser(userId, team, allUsers);
    const userId = 'userId' in participant ? participant.userId : participant.id;
    const participantUser = user(userId);
    const delegatedUser = participant.delegateFor ? user(participant.delegateFor).displayName : null;
    const role = isReviewer(participant) ? messages.reviewer : messages.approver;

    switch (participant.source) {
        case domain.RequestStepParticipantOrigin.ByRules:
        case domain.RequestStepReviewerSource.Rules:
            if (delegatedUser) {
                return {
                    hoverText: messages.participantHoverTextWorkflowApproverDelegate({ delegatedUser: delegatedUser }),
                    title: messages.participantTitleWorkflowApproverDelegate({
                        userName: participantUser.displayName,
                        delegatedUser,
                    }),
                };
            } else {
                return {
                    hoverText: messages.participantHoverTextWorkflowApprover({ role }),
                    title: messages.participantTitleWorkflowApprover({
                        userName: participantUser.displayName,
                        role,
                    }),
                };
            }

        case domain.RequestStepParticipantOrigin.IsBackup:
        case domain.RequestStepReviewerSource.Backup:
            if (delegatedUser) {
                return {
                    hoverText: messages.participantHoverTextDefaultApproverDelegate({ delegatedUser, role }),
                    title: messages.participantTitleDefaultApproverDelegate({
                        userName: participantUser.displayName,
                        delegatedUser,
                        role,
                    }),
                };
            } else {
                return {
                    hoverText: messages.participantHoverTextDefaultApprover({ role }),
                    title: messages.participantTitleDefaultApprover({
                        userName: participantUser.displayName,
                        role,
                    }),
                };
            }

        case domain.RequestStepParticipantOrigin.Manually:
        case domain.RequestStepReviewerSource.Manual: {
            const byMe = participant.addedBy === me.id;

            if (byMe) {
                if (delegatedUser) {
                    return {
                        hoverText: messages.participantHoverTextAddedManuallyByMeDelegate({ delegatedUser }),
                        title: messages.participantTitleAddedManuallyByMeDelegate({
                            userName: participantUser.displayName,
                            delegatedUser,
                        }),
                    };
                } else {
                    return {
                        hoverText: messages.participantHoverTextAddedManuallyByMe({ role }),
                        title: messages.participantTitleAddedManuallyByMe({
                            userName: participantUser.displayName,
                            role,
                        }),
                    };
                }
            } else {
                const addedBy = participant.addedBy ? user(participant.addedBy).displayName : '';

                if (delegatedUser) {
                    return {
                        hoverText: messages.participantHoverTextAddedManuallyDelegate({ delegatedUser, addedBy }),
                        title: messages.participantTitleAddedManuallyDelegate({
                            userName: participantUser.displayName,
                            delegatedUser,
                            addedBy,
                        }),
                    };
                } else {
                    return {
                        hoverText: messages.participantHoverTextAddedManually({ addedBy, role }),
                        title: messages.participantTitleAddedManually({
                            userName: participantUser.displayName,
                            addedBy,
                            role,
                        }),
                    };
                }
            }
        }

        case domain.RequestStepParticipantOrigin.ByMatching:
            if (delegatedUser) {
                return {
                    hoverText: messages.participantHoverTextMatchingApproverDelegate({ delegatedUser }),
                    title: messages.participantTitleMatchingApproverDelegate({
                        userName: participantUser.displayName,
                        delegatedUser,
                    }),
                };
            } else {
                return {
                    hoverText: messages.participantHoverTextMatchingApprover({ role }),
                    title: messages.participantTitleMatchingApprover({
                        userName: participantUser.displayName,
                        role,
                    }),
                };
            }

        default:
            return {
                useHoverText: '',
                title: '',
            };
    }
};
