import { MutateDataParams, QueryOptions } from '@approvalmax/data';
import { objectHelpers } from '@approvalmax/utils';
import { useGetLazyLegacy } from 'shared/data';

import { requestsApiPaths } from '../paths';
import {
    UseGetNetSuitePayeesRequestParams,
    UseGetNetSuitePayeesResponse,
    UseGetNetSuitePayeesResponseBackend,
} from './useGetNetSuitePayees.types';

export const useGetLazyNetSuitePayees = (queryOptions?: QueryOptions<UseGetNetSuitePayeesResponse>) => {
    return useGetLazyLegacy<
        MutateDataParams<UseGetNetSuitePayeesRequestParams>,
        UseGetNetSuitePayeesResponseBackend,
        UseGetNetSuitePayeesResponse
    >(requestsApiPaths.netSuitePayees, {
        queryOptions,
        mapData: objectHelpers.pascalCaseToCamelCase,
    });
};
