import { Flex, Spacing, Text } from '@approvalmax/ui/src/components';
import { XeroIcon } from 'modules/sprites';
import { FC, memo } from 'react';

import { ConnectionButton, ConnectToOrganisationButton, PopupFooter, PopupTitle } from '..';
import { messages } from './XeroContent.messages';
import { XeroContentProps } from './XeroContent.types';

const XeroContent: FC<XeroContentProps> = memo((props) => {
    const { isLoading, onCreateDemo, onConnectToOrganisation, onSkip, onBack } = props;

    return (
        <>
            <PopupTitle />

            <Spacing height={20} />

            <Flex container spacing='12'>
                <Flex size={12}>
                    <ConnectionButton
                        logo={XeroIcon}
                        title={messages.createDemo({
                            b: (chunks: any) => <b>{chunks}</b>,
                        })}
                        onClick={onCreateDemo}
                        isLoading={isLoading}
                        alignLogoTop
                    >
                        <Text font='body' fontSize='small' spacing='20 0 0'>
                            <b>{messages.demoHeader}</b>

                            <Spacing height={8} />

                            {messages.demoDescribe}
                        </Text>
                    </ConnectionButton>
                </Flex>

                <Flex size={12}>
                    <ConnectToOrganisationButton
                        onClick={onConnectToOrganisation}
                        logo={XeroIcon}
                        systemName={messages.xeroName}
                        isLoading={isLoading}
                    />
                </Flex>
            </Flex>

            <Spacing height={32} />

            <PopupFooter withBackButton onBack={onBack} onSkip={onSkip} isLoading={isLoading} />
        </>
    );
});

export default XeroContent;
