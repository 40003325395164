import { oPopupPadding } from '@approvalmax/ui';
import { AlertListIcon } from 'modules/sprites';
import styled from 'styled-components';

export const Content = styled.div`
    display: flex;
    flex-flow: column;
    ${oPopupPadding()};
`;

export const Description = styled.div`
    margin-top: 20px;
    font-size: 13px;
`;

export const StyledTable = styled.table`
    width: 100%;
    text-align: left;
`;

export const StyledTr = styled.tr`
    border-bottom: 1px solid #cacccd;
`;

export const StyledTh = styled.th`
    padding-bottom: 5px;
    font-size: 11px;
    font-weight: 600;
    line-height: 1.27;
    color: #6c6a6a;
`;

export const StyledFirstTh = styled(StyledTh)`
    width: 33%;
`;

export const StyledTd = styled.td`
    position: relative;
    padding: 7px 0 8px;
    font-size: 12px;
    line-height: 1.33;
    vertical-align: top;
`;

export const StyledTdDiscrepancy = styled(StyledTd)`
    padding-left: 19px;
`;

export const StyledIcon = styled(AlertListIcon)`
    color: #cb4e30;
    position: absolute;
    top: 10px;
    left: 2px;
`;

export const ButtonBox = styled.div`
    display: flex;
    justify-content: end;
`;
