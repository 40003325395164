import { BudgetImpact as BudgetImpactRaw, CompaniesFieldHeader } from '../backend';

export enum BudgetSyncStatus {
    Synchronizing = 'Synchronizing',
    Synchronized = 'Synchronized',
    NeedReview = 'NeedReview',
    SynchronizationFailed = 'SynchronizationFailed',
}

export enum BudgetOrigin {
    Manual = 'Manual',
    Xero = 'Xero',
    QBooks = 'QBooks',
}

export interface BudgetPeriod {
    month: number;
    year: number;
    asDateTime: Date;
}

interface BudgetCutset extends CompaniesFieldHeader {
    constraint: { id: string; value: string };
}

export interface Budget {
    budgetId: string;
    name: string;
    origin: BudgetOrigin;
    isEnabled: boolean;
    checkPeriod?: BudgetCheckPeriod;
    checkPeriodFrom?: BudgetPeriod;
    checkPeriodTill?: BudgetPeriod;
    cutset: BudgetCutset[];
    lastSyncDate?: string;
    modifiedDate: string;
    synchronizationStatus?: BudgetSyncStatus;
    checkPeriodFirstMonth?: number;
    zeroAmountHandlingType?: BudgetZeroAmountHandlingType;
    periodFrom?: BudgetPeriod;
    periodTill?: BudgetPeriod;
}

export enum BudgetCheckAmountType {
    Gross = 'Gross',
    Net = 'Net',
}

export interface BudgetSourceFile {
    sourceFileId: string;
    name: string;
    url: string;
    size: number;
    uploadResult: void;
}

export enum BudgetCheckStatus {
    Ok = 'Ok',
    Warning = 'Warning',
    Overbudget = 'Overbudget',
}

export enum BudgetCheckPeriod {
    Monthly = 'Monthly',
    Quarterly = 'Quarterly',
    Semiannually = 'Semiannually',
    Annually = 'Annually',
    YearToDate = 'YearToDate',
    AnnuallyWithCustomFirstMonth = 'AnnuallyWithCustomFirstMonth',
    YearToDateWithCustomFirstMonth = 'YearToDateWithCustomFirstMonth',
    Custom = 'Custom',
}

export interface BudgetTrackingCutset {
    fieldId: string;
    exactConstraintId: string;
    exactConstraintValue: string;
}

export enum BudgetZeroAmountHandlingType {
    Consider = 'Consider',
    NotConsider = 'NotConsider',
}

export type BudgetImpact = Omit<BudgetImpactRaw, 'account'> & {
    account: {
        id: string;
        text: string;
    };
};

export interface MonthOfYear {
    month: number;
    year: number;
    asDateTime: string;
}
