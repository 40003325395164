import { RequestMutationOptions } from '@approvalmax/data';
import { useQueryClient } from '@tanstack/react-query';
import { useMutate } from 'shared/data';

import { companiesApiPaths } from '../paths';
import { UpdateBudgetSettings } from './useUpdateBudgetSettings.types';

export const useUpdateBudgetSettings = (mutationOptions?: RequestMutationOptions<UpdateBudgetSettings>) => {
    const queryClient = useQueryClient();

    return useMutate(companiesApiPaths.updateBudgetSettings, {
        mutationOptions: {
            ...mutationOptions,
            onSuccess: async (data, variables, context) => {
                await queryClient.invalidateQueries([
                    companiesApiPaths.getBudgetSettings,
                    { companyId: variables.body?.companyId },
                ]);

                mutationOptions?.onSuccess?.(data, variables, context);
            },
        },
        apiVersion: 'v1',
    });
};
