import { Flex } from '@approvalmax/ui/src/components';
import { FC } from 'react';

import { messages } from './PaymentProcessingDetails.messages';
import { Header, Name, Root, Value } from './PaymentProcessingDetails.styles';
import { PaymentProcessingDetailsProps } from './PaymentProcessingDetails.types';

const PaymentProcessingDetails: FC<PaymentProcessingDetailsProps> = (props) => {
    const { paymentProcessingDetails } = props;

    if (!paymentProcessingDetails) return null;

    const { totalPayments, paymentsSent, paymentsFailed, paymentsInProcessing } = paymentProcessingDetails;

    return (
        <Root>
            <Header>{messages.paymentProcessingDetails}</Header>

            <Flex container>
                <Flex size={6}>
                    <Name>{messages.totalPayments}</Name>

                    <Value>{totalPayments}</Value>
                </Flex>

                <Flex size={6}>
                    <Name>{messages.paymentsSent}</Name>

                    <Value>{paymentsSent}</Value>
                </Flex>

                <Flex size={6}>
                    <Name>{messages.paymentsFailed}</Name>

                    <Value>{paymentsFailed}</Value>
                </Flex>

                <Flex size={6}>
                    <Name>{messages.paymentsInProcessing}</Name>

                    <Value>{paymentsInProcessing}</Value>
                </Flex>
            </Flex>
        </Root>
    );
};

export default PaymentProcessingDetails;
