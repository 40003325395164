import { Button, CloseIcon, ExternalLink } from '@approvalmax/ui';
import { Flex, Text } from '@approvalmax/ui/src/components';
import { actions } from 'modules/profile';
import { FC, memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { getDescription, getLearnMoreLink, getOrigin, getTitle } from './FeaturePromotion.helpers';
import { messages } from './FeaturePromotion.messages';
import { CloseButton, Root } from './FeaturePromotion.styles';
import { FeaturePromotionProps } from './FeaturePromotion.types';

export const FeaturePromotion: FC<FeaturePromotionProps> = memo((props) => {
    const { className, company, promotionType, integrationType, onClose } = props;

    const dispatch = useDispatch();

    const handleTryNow = useCallback(() => {
        dispatch(actions.openConsentToStartTrialPopup(company, getOrigin(promotionType)));
    }, [company, dispatch, promotionType]);

    return (
        <Root className={className}>
            <Flex alignItems='center' spacing='16'>
                <Text font='body' fontWeight='bold' fontSize='medium' uppercase>
                    {getTitle(promotionType)}
                </Text>

                <ExternalLink href={getLearnMoreLink(promotionType, integrationType)}>
                    {messages.learnMore}
                </ExternalLink>

                <CloseButton icon noPadding onClick={onClose} uppercase={false}>
                    <CloseIcon size={20} color='midnight90' />

                    {messages.close}
                </CloseButton>

                <Button theme='primary' colorTheme='purple' preset='small' execute={handleTryNow}>
                    {messages.tryNow}
                </Button>
            </Flex>

            <Text font='body' fontWeight='bold' fontSize='small' spacing='10 0 0'>
                {getDescription(promotionType)}
            </Text>
        </Root>
    );
});

FeaturePromotion.displayName = 'FeaturePromotion';
