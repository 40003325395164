import { selectors } from 'modules/common';
import { domain } from 'modules/data';

export const isAddressEmpty = (address: domain.EditableXeroContactAddress | undefined) => {
    if (!address) {
        return true;
    }

    const addressDisplayValue = selectors.ui.getDisplayAddress(address.address, address.postalCode);

    return !addressDisplayValue && !address.country && !address.city && !address.region && !address.attentionTo;
};

export const isSalesSettingsEmpty = (contact: domain.XeroContactDetails) => {
    return !contact.salesAccount && !contact.salesTrackingCategories.length;
};

export const isPurchaseSettingsEmpty = (contact: domain.XeroContactDetails) => {
    return !contact.purchasesAccount && !contact.purchasesTrackingCategories.length;
};
