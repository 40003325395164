import { MutateDataParams, MutationOptions } from '@approvalmax/data';
import { useSetRecoilState } from 'recoil';
import { useEditRequest, UseEditRequestData, UseEditRequestResponse } from 'shared/data';

import { isModifiedRequestState } from '../states';

export const useEditRequestForm = (
    mutationOptions?: MutationOptions<UseEditRequestResponse, MutateDataParams<UseEditRequestData>>
) => {
    const setIsModifiedRequest = useSetRecoilState(isModifiedRequestState);

    return useEditRequest({
        ...mutationOptions,
        onSuccess: (...args) => {
            setIsModifiedRequest(true);
            mutationOptions?.onSuccess?.(...args);
        },
    });
};
