import { MutateDataParams, MutationOptions } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data/api';

import { requestsApiPaths } from '../paths';
import { UseChangeWatchersData, UseChangeWatchersResponse } from './useChangeWatchers.types';

export const useChangeWatchers = (
    mutationOptions?: MutationOptions<UseChangeWatchersResponse, MutateDataParams<UseChangeWatchersData>>
) => {
    return useMutateLegacy<MutateDataParams<UseChangeWatchersData>, UseChangeWatchersResponse>(
        requestsApiPaths.changeWatchers,
        {
            mutationOptions,
        }
    );
};
