import { createAction, ExtractActions } from 'modules/react-redux';

export const CANCEL_ACTIVE_POPUP = 'COMMON/CANCEL_ACTIVE_POPUP';
export const cancelActivePopup = () => createAction(CANCEL_ACTIVE_POPUP, {});

export const SHOW_POPUP = 'COMMON/SHOW_POPUP';
export const showPopup = (popupId: string, data?: any) =>
    createAction(SHOW_POPUP, {
        popupId,
        data,
    });

export const SET_WORKFLOW_WIZARD_PREVIEW = 'SET_WORKFLOW_WIZARD_PREVIEW';
export const setWorkflowWizardPreview = (isWorkflowWizardPreview: boolean) =>
    createAction(SET_WORKFLOW_WIZARD_PREVIEW, {
        isWorkflowWizardPreview,
    });

export const TRY_OPEN_WORKFLOW_WIZARD = 'COMMON/TRY_OPEN_WORKFLOW_WIZARD';
export const tryOpenWorkflowWizard = () => createAction(TRY_OPEN_WORKFLOW_WIZARD, {});

export type Action = ExtractActions<
    typeof cancelActivePopup | typeof setWorkflowWizardPreview | typeof showPopup | typeof tryOpenWorkflowWizard
>;
