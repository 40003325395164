import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import {
    tryPayOrCapturePopupInfoState,
    tryPayOrCapturePopupOpenState,
    TryPayOrCapturePopupOrigin,
} from 'modules/profile';
import { useSelector } from 'modules/react-redux';
import { useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { isPayOrCapturePromotionsAllowed } from 'shared/helpers';
import { activeCompanyIdState, addonPromotionInfoState, AddonPromotionType } from 'shared/states';

export const useForcePromotionPopupOpen = () => {
    const [addonPromotionInfo, setAddonPromotionInfo] = useRecoilState(addonPromotionInfoState);
    const profile = useSelector(selectors.profile.findProfile);
    const activeCompanyId = useRecoilValue(activeCompanyIdState);
    const companies = useSelector(selectors.company.getCompanies);
    const company = useMemo(() => companies.find(({ id }) => id === activeCompanyId), [activeCompanyId, companies]);
    const companyTemplates = useSelector((state) =>
        selectors.template.getAllTemplatesByCompanyId(state, activeCompanyId)
    );

    const templateId = companyTemplates.find(
        (template) =>
            template.integrationCode === domain.IntegrationCode.XeroBill ||
            template.integrationCode === domain.IntegrationCode.QBooksBill
    )?.id;

    const template = useSelector((state) => selectors.template.findTemplateById(state, templateId || ''));
    const isRequester = template?.submitterMatrix.some((submitter) => submitter.lineId === profile?.email);

    const [tryPayOrCapturePopupOpen, setTryPayOrCapturePopupOpen] = useRecoilState(tryPayOrCapturePopupOpenState);
    const setTryPayOrCapturePopupInfo = useSetRecoilState(tryPayOrCapturePopupInfoState);
    const canShowPayPopup =
        company &&
        (company.flags.isManager || isRequester) &&
        isPayOrCapturePromotionsAllowed(company, AddonPromotionType.Pay) &&
        !addonPromotionInfo[AddonPromotionType.Pay].forcePopupHasBeenShown;
    const canShowCapturePopup =
        company &&
        (company.flags.isManager || isRequester) &&
        isPayOrCapturePromotionsAllowed(company, AddonPromotionType.Capture) &&
        !addonPromotionInfo[AddonPromotionType.Capture].forcePopupHasBeenShown;

    useEffect(() => {
        if ((canShowPayPopup || canShowCapturePopup) && !tryPayOrCapturePopupOpen) {
            const promotionType = canShowPayPopup ? AddonPromotionType.Pay : AddonPromotionType.Capture;

            setTimeout(() => {
                setAddonPromotionInfo((currentState) => ({
                    ...currentState,
                    [promotionType]: {
                        ...currentState[promotionType],
                        forcePopupHasBeenShown: true,
                    },
                }));
                setTryPayOrCapturePopupOpen(true);
                setTryPayOrCapturePopupInfo({
                    type: promotionType,
                    origin: TryPayOrCapturePopupOrigin.ForceOpen,
                });
            }, 1000);
        }
    }, [
        canShowCapturePopup,
        canShowPayPopup,
        setAddonPromotionInfo,
        setTryPayOrCapturePopupInfo,
        setTryPayOrCapturePopupOpen,
        tryPayOrCapturePopupOpen,
    ]);
};
