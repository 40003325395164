import { Text, toast } from '@approvalmax/ui/src/components';
import { domHelpers } from '@approvalmax/utils';
import { constants, selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { api } from 'services/api';
import { notificationService } from 'services/notification';
import { useChangeGrnStatus, useUploadAttachment } from 'shared/data';
import { handleFileUploadLimits } from 'shared/helpers';
import { getPath, Path } from 'urlBuilder';

import { useSelectedFilter } from '../../../../hooks';
import { grnStatusToTemplateFilterMap } from './EditPopup.config';
import { messages } from './EditPopup.messages';
import { StatusOption } from './EditPopup.types';

export const useEditPopupData = (request: selectors.types.ExpandedRequest) => {
    const selectedFilter = useSelectedFilter();

    const [statusOption, setStatusOption] = useState<StatusOption | null>(null);
    const [commentText, setCommentText] = useState<string>('');

    const onChangeStatusOption = useCallback((option: StatusOption | null) => {
        setStatusOption(option);
    }, []);

    const onResetData = useCallback(() => {
        setStatusOption(null);
        setCommentText('');
    }, []);

    const onChangeStatusSuccess = useCallback(
        (grnStatus: domain.GoodsReceivedNotesStatus) => {
            if (
                selectedFilter !== grnStatusToTemplateFilterMap[grnStatus] &&
                Object.values(grnStatusToTemplateFilterMap).some((value) => selectedFilter === value)
            ) {
                toast.success(
                    <>
                        <Text font='body'>{messages.grnStatusWasChanged}</Text>

                        <Link to={getPath(Path.request, request.id, request.companyId)}>
                            {messages.goTo({ displayName: request.displayName })}
                        </Link>
                    </>
                );
            } else {
                toast.success(messages.grnStatusWasChanged);
            }
        },
        [request.companyId, request.displayName, request.id, selectedFilter]
    );

    const { mutateAsync: handleChangeStatus } = useChangeGrnStatus({
        onSuccess: (_data, variables) => onChangeStatusSuccess(variables.grnStatus),
    });

    return {
        statusOption,
        commentText,
        onChangeStatusOption,
        onChangeComment: setCommentText,
        onResetData,
        handleChangeStatus,
    };
};

export const useEditPopupAttachments = (companyId: string) => {
    const [attachments, setAttachments] = useState<domain.RequestAttachment[]>([]);
    const [attachmentsInProgress, setAttachmentsInProgress] = useState<domain.RequestAttachment[]>([]);

    const attachmentsTotalCount = attachments.length + attachmentsInProgress.length;

    const { mutateAsync: uploadAttachment } = useUploadAttachment({
        onMutate: ({ file }) => {
            const attachment = mapFileToAttachment(file);

            setAttachmentsInProgress((prevState) => [...prevState, attachment]);
        },
        onSuccess: (response) => {
            const attachment = { ...response.data[0] };

            setAttachmentsInProgress((prevState) => prevState.filter((el) => el.name !== attachment.name));
            setAttachments((prevState) => [...prevState, attachment]);
        },
    });

    const onUpload = useCallback(
        (files: File[]) => {
            if (files.length > constants.uploadsConstants.COMMENT_MAX_ATTACHMENT_COUNT - attachmentsTotalCount) {
                notificationService.showErrorToast(
                    messages.errorFilesCount({
                        maxFilesCount: constants.uploadsConstants.COMMENT_MAX_ATTACHMENT_COUNT,
                    })
                );

                return;
            }

            const newFiles = handleFileUploadLimits(files);

            newFiles.forEach((file) => {
                if (file.size > constants.uploadsConstants.COMMENT_MAX_ATTACHMENT_FILESIZE) {
                    notificationService.showErrorToast(
                        messages.errorFileSize({
                            fileName: file.name,
                            maxSize: constants.uploadsConstants.COMMENT_MAX_ATTACHMENT_FILESIZE_TEXT,
                        })
                    );

                    return;
                }

                const ext = file.name.toLowerCase().split('.').pop();

                if (!ext || !constants.uploadsConstants.COMMENT_ALLOWED_FILE_EXTENSIONS.includes(`.${ext}`)) {
                    notificationService.showErrorToast(
                        messages.errorFileType({
                            fileName: file.name,
                        })
                    );

                    return;
                }

                uploadAttachment({ file });
            });
        },
        [attachmentsTotalCount, uploadAttachment]
    );

    const onRemove = useCallback((attachmentId: string) => {
        setAttachments((prevState) => prevState.filter((el) => el.id !== attachmentId));
        setAttachmentsInProgress((prevState) => prevState.filter((el) => el.id !== attachmentId));
    }, []);

    const onDownloadAttachment = useCallback(
        (attachmentId: string) => {
            domHelpers.downloadUrl(api.requests.urls.getAttachment({ attachmentId, companyId }));
        },
        [companyId]
    );

    const onResetAttachments = useCallback(() => {
        setAttachments([]);
        setAttachmentsInProgress([]);
    }, []);

    return {
        attachments,
        attachmentsInProgress,
        onUpload,
        onRemove,
        onDownloadAttachment,
        onResetAttachments,
    };
};

export const useEditPopupHelpStripe = (companyId: string) => {
    const company = useSelector((state: State) => selectors.company.findCompanyById(state, companyId));

    switch (company?.integration?.integrationType) {
        case domain.IntegrationType.Xero:
            return 'goods-received-notes-xero';

        case domain.IntegrationType.QBooks:
            return 'goods-received-notes-qbooks';

        default:
            return null;
    }
};

export function mapFileToAttachment(file: File): domain.RequestAttachment {
    return {
        id: file.name + file.size,
        name: file.name,
        size: file.size,
        createdDate: file.lastModified.toString(),
        attachmentType: domain.RequestAttachmentType.General,
    };
}
