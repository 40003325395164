import { Flex, Form, Radio } from '@approvalmax/ui/src/components';
import { getActiveTemplate } from 'app/(workspace)/[companyId]/workflows/[workflowId]/resources/selectors/pageSelectors';
import { selectors } from 'modules/common';
import { IntegrationCode } from 'modules/data/domain';
import { useSelector } from 'modules/react-redux';
import { FC, memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMount } from 'react-use';
import { amplitudeService } from 'services/amplitude';
import { routingService } from 'services/routing';
import { getPath, Path } from 'urlBuilder';

import { FooterButtons, HeaderTitle, ScrollableContent } from '..';
import { formFieldName, standaloneFakeId } from './TypeOfDocsStep.constants';
import { messages } from './TypeOfDocsStep.messages';
import { TagInLabel } from './TypeOfDocsStep.styles';
import { FormValues, TypeOfDocsStepProps } from './TypeOfDocsStep.types';

export const TypeOfDocsStep: FC<TypeOfDocsStepProps> = memo((props) => {
    const { integrateWorkflows, amplitudeParams, onNext } = props;

    const company = useSelector(selectors.navigation.getActiveCompany);
    const template = useSelector(getActiveTemplate);
    const isAvailableStandalone = company.availableStandaloneTemplates;
    const [loading, setLoading] = useState(false);

    const form = useForm<FormValues>();

    const [waitTemplateId, setWaitTemplateId] = useState<string | null>(null);

    useMount(() => {
        amplitudeService.sendData('Workflow: land on wizard: type of docs step', amplitudeParams);
    });

    useEffect(() => {
        if (
            template &&
            ((waitTemplateId === standaloneFakeId && !template.integrationCode) || template.id === waitTemplateId)
        ) {
            onNext(template);
        }
    }, [onNext, template, waitTemplateId]);

    const handleNext = form.handleSubmit((values) => {
        const workflowId = values[formFieldName];

        setLoading(true);

        if (workflowId === standaloneFakeId) {
            setWaitTemplateId(standaloneFakeId);
            routingService.push(getPath(Path.newWorkflow, company.id));

            return;
        }

        const workflow = integrateWorkflows.find((workflow) => workflow.id === workflowId);

        if (!workflow) {
            return;
        }

        setWaitTemplateId(workflow.id);
        routingService.push(getPath(Path.workflow, workflow.companyId, workflow.id));
    });

    return (
        <Form form={form} onSubmit={handleNext}>
            <ScrollableContent
                header={<HeaderTitle spacing='32 0' title={messages.title} subtitle={messages.subtitle} />}
                footer={
                    <FooterButtons isNextDisabled={!form.formState.isValid} isNextTypeSubmit isNextLoading={loading} />
                }
            >
                <Radio.Group disabled={loading} size='large' name={formFieldName} required direction='column'>
                    {integrateWorkflows.map((workflow) => (
                        <Radio key={workflow.id} value={workflow.id}>
                            {workflow.integrationCode === IntegrationCode.XeroBill ||
                            workflow.integrationCode === IntegrationCode.QBooksBill ? (
                                <Flex spacing='8' direction='row'>
                                    <span>{workflow.displayNamePlural}</span>

                                    <TagInLabel outline color='midnight80'>
                                        {messages.popular}
                                    </TagInLabel>
                                </Flex>
                            ) : (
                                workflow.displayNamePlural
                            )}
                        </Radio>
                    ))}

                    {isAvailableStandalone && (
                        <Radio key={standaloneFakeId} value={standaloneFakeId}>
                            {messages.other}
                        </Radio>
                    )}
                </Radio.Group>
            </ScrollableContent>
        </Form>
    );
});

TypeOfDocsStep.displayName = 'TypeOfDocsStep';
