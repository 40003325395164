import { domain } from 'modules/data';
import { atom } from 'recoil';

import { NetSuiteBillFields } from './NetSuiteBillRequestForm.types';

export const updateNetSuiteBillDataState = atom<((request: domain.NetSuiteBillRequest) => void) | null>({
    key: 'updateNetSuiteBillDataState',
    default: null,
});

export const netSuiteBillFieldValuesState = atom<Partial<NetSuiteBillFields>>({
    key: 'netSuiteBillFieldValuesState',
    default: {},
});
