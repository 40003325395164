import { theme } from '@approvalmax/theme';
import { oDropdownPanel } from '@approvalmax/ui';
import styled from 'styled-components';

export const Root = styled.div`
    margin-left: 20px;
`;

export const DropdownPanel = styled.div`
    ${oDropdownPanel()}
    display: flex;
    flex-flow: column;
    max-width: 300px;
`;

export const DropdownItem = styled.div`
    padding: 8px 16px;
    cursor: pointer;

    &:hover {
        background: ${theme.color.midnight20};
    }
`;
