import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.AddBillInvoicesPopup.BillInvoiceFilter', {
    title: 'Filter',
    referenceFieldPlaceholder: 'Enter Reference...',
    dateFieldTitle: 'Creation date range',
    fromFieldTitle: 'From',
    toFieldTitle: 'To',
    supplierFieldTitle: 'Supplier',
    referenceFieldTitle: 'Reference',
    amountLabel: 'Total amount of Bills:',
    amountFieldTitle: 'Amount due range',
    plannedDateFieldTitle: 'Planned date range',
    dueDateFieldTitle: 'Due date range',
    resetButtonText: 'Reset filter',
    applyButtonText: 'Apply filter',
    excludeBillsWithZeroAmount: 'Exclude bills reserved within another batch',
    totalAmountOfSelectedBills: 'Total amount of selected Bills: {amount}',
});
