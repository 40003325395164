import { LoadingBar, Popup } from '@approvalmax/ui';
import { memo } from 'react';

import { messages } from './ProcessingStep.messages';
import { Content, Description } from './ProcessingStep.styles';

const ProcessingStep = memo(() => {
    return (
        <Popup.DefaultContent title={messages.title} showCloseButton={false}>
            <Content>
                <LoadingBar />

                <Description>{messages.description}</Description>
            </Content>
        </Popup.DefaultContent>
    );
});

export default ProcessingStep;
