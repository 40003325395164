import { domHelpers } from '@approvalmax/utils';
import { ForwardedRef, forwardRef, memo, ReactElement, RefAttributes } from 'react';

import Label from '../../../Label/Label';
import Menu from '../../../Menu/Menu';
import { SelectProps } from '../../Select.types';
import Activator from '../Activator/Activator';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import MenuItems from '../MenuItems/MenuItems';
import SelectedItems from '../SelectedItems/SelectedItems';
import { useItems } from './Content.hooks';
import { ActionItemWrapper, Hint, MenuItemsWrapper, Root } from './Content.styles';

const Content = memo(
    forwardRef(
        <
            Item extends Record<string, any>,
            Multiple extends boolean | undefined = undefined,
            NoInput extends boolean = false,
        >(
            props: SelectProps<Item, Multiple, NoInput>,
            ref: ForwardedRef<HTMLDivElement>
        ) => {
            const {
                items: propsItems = [],
                value,
                onChange,
                itemNameKey = 'name',
                itemIdKey: itemIdKeyProp,
                size,
                noInput = false,
                initOpen,
                required,
                multiple = false,
                creatable = false,
                validateCreatedValue,
                autocomplete = false,
                onOpen,
                open,
                dropdownWidth,
                display = 'block',
                placement,
                onInputChange,
                label,
                invalid,
                disabled,
                name,
                onBlur,
                onFocus,
                placeholder,
                startIcon,
                hint,
                tabIndex,
                initFocus,
                customMenuItem,
                customTagItem,
                progress,
                fontWeight,
                textActivatorColor,
                uppercase,
                clearable,
                actionItemProps,
                hideNoData,
                readOnly,
                bordered,
                openIconOnHover = false,
                fontSize,
                textActivatorHoverColor,
                activatorRef,
                onClick,
                preventSearch,
                hideEndIcon,
                buttonColor,
                noPadding,
                outline,
                selectOnFocus = true,
                filterSelectedItems: filterSelectedItemsProp = false,
                tagColor = 'midnight40',
                qa,
                spacing,
                ...restProps
            } = props;

            const itemIdKey = itemIdKeyProp ?? ('id' as Exclude<keyof Item, number | symbol>);
            // ATM we have a lot of places w/ that logic and users got used to it,
            // and we want to leave it for multi-select as is
            const filterSelectedItems = multiple || filterSelectedItemsProp;

            const { items, selectedItems } = useItems({
                items: propsItems,
                itemIdKey,
                value,
            });

            return (
                <Root
                    $display={display}
                    tabIndex={0}
                    ref={ref}
                    onClick={onClick}
                    data-qa={domHelpers.generateDataQa(qa, 'select')}
                    {...restProps}
                >
                    {label && (
                        <Label size={size === 'xsmall' ? size : 'small'} required={required}>
                            {label}
                        </Label>
                    )}

                    <SelectedItems
                        itemIdKey={itemIdKey}
                        itemNameKey={itemNameKey}
                        items={items}
                        selectedItems={selectedItems}
                        multiple={multiple}
                        onChange={onChange}
                        size={size !== 'large' ? size : undefined}
                        customTagItem={customTagItem}
                        tagColor={tagColor}
                    />

                    <DropdownMenu
                        activator={
                            <Activator
                                itemNameKey={itemNameKey}
                                itemIdKey={itemIdKey}
                                multiple={multiple}
                                autocomplete={autocomplete}
                                onInputChange={onInputChange}
                                invalid={invalid}
                                disabled={disabled}
                                name={name}
                                onBlur={onBlur}
                                onFocus={onFocus}
                                placeholder={placeholder}
                                startIcon={startIcon}
                                tabIndex={tabIndex ?? 0}
                                initFocus={initFocus}
                                size={size}
                                required={required}
                                progress={progress}
                                noInput={noInput}
                                fontWeight={fontWeight}
                                color={textActivatorColor}
                                uppercase={uppercase}
                                clearable={clearable}
                                font='label'
                                readOnly={readOnly}
                                bordered={bordered}
                                openIconOnHover={openIconOnHover}
                                fontSize={fontSize}
                                hoverColor={textActivatorHoverColor}
                                onChangeSelect={onChange}
                                selectedItems={selectedItems}
                                items={items}
                                activatorRef={activatorRef}
                                hideEndIcon={hideEndIcon}
                                open={open}
                                onOpen={onOpen}
                                initOpen={initOpen}
                                buttonColor={buttonColor}
                                noPadding={noPadding}
                                outline={outline}
                                selectOnFocus={selectOnFocus}
                                spacing={spacing}
                            />
                        }
                        display={display}
                        width={dropdownWidth}
                        maxHeight={0}
                        onOpen={onOpen}
                        initOpen={initOpen}
                        open={open}
                        placement={placement}
                        floatingFocusManagerProps={{
                            returnFocus: false,
                        }}
                    >
                        <MenuItemsWrapper className='fs-mask'>
                            <MenuItems
                                itemIdKey={itemIdKey}
                                itemNameKey={itemNameKey}
                                items={items}
                                multiple={multiple}
                                autocomplete={autocomplete}
                                customMenuItem={customMenuItem}
                                size={size}
                                hideNoData={hideNoData}
                                creatable={creatable}
                                validateCreatedValue={validateCreatedValue}
                                selectedItems={selectedItems}
                                filterSelectedItems={filterSelectedItems}
                                onChange={onChange}
                                progress={progress}
                                preventSearch={preventSearch}
                                open={open}
                                onOpen={onOpen}
                                initOpen={initOpen}
                                qa={qa}
                            />
                        </MenuItemsWrapper>

                        {actionItemProps && (
                            <ActionItemWrapper>
                                <Menu.List items={[{ ...actionItemProps, active: true }]} />
                            </ActionItemWrapper>
                        )}
                    </DropdownMenu>

                    {hint && <Hint $invalid={invalid}>{hint}</Hint>}
                </Root>
            );
        }
    )
) as unknown as (<
    Item extends Record<string, any>,
    Multiple extends boolean | undefined = undefined,
    NoInput extends boolean = false,
>(
    props: SelectProps<Item, Multiple, NoInput> & RefAttributes<HTMLDivElement>
) => ReactElement) & { displayName: string };

Content.displayName = 'Content';

export default Content;
