import { TransparentButton } from '@approvalmax/ui';
import { Box, Dropdown, fullscreenSplitViewState } from '@approvalmax/ui/src/components';
import { AttachmentList } from 'modules/components/Attachment/AttachmentList';
import { domain } from 'modules/data';
import { AttachmentIcon, BubbleCloseIcon } from 'modules/sprites';
import { FC, memo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

const StyledAttachmentIcon = styled(AttachmentIcon)`
    fill: #77aa84;
`;

const Button = styled.button`
    cursor: pointer;
`;

const Header = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    padding: 0 16px;
`;

interface AttachmentsDropdownProps {
    attachments: domain.RequestAttachment[];
    onSelectAttachment: (attachmentId: string) => void;
    activeAttachmentId?: string;
    defaultOpen?: boolean;
}

const AttachmentsDropdown: FC<AttachmentsDropdownProps> = (props) => {
    const { attachments, activeAttachmentId, onSelectAttachment, defaultOpen } = props;

    const fullscreenSplitView = useRecoilValue(fullscreenSplitViewState);

    const defaultOpenValue =
        defaultOpen === false || fullscreenSplitView
            ? false
            : attachments.some((attachment) => attachment.id === activeAttachmentId);
    const [isOpen, setIsOpen] = useState(defaultOpenValue);

    const onOpen = (attachment: domain.RequestAttachment) => {
        if (!activeAttachmentId) {
            setIsOpen(false);
        }

        onSelectAttachment(attachment.id);
    };

    return (
        <Dropdown
            width={400}
            maxHeight={300}
            spacing='16 0'
            open={isOpen}
            onOpen={setIsOpen}
            activator={
                <Button onClick={() => setIsOpen(true)}>
                    <StyledAttachmentIcon />
                </Button>
            }
        >
            <Header>
                <TransparentButton execute={() => setIsOpen(false)}>
                    <BubbleCloseIcon width={12} height={12} />
                </TransparentButton>
            </Header>

            <Box spacing='0 16'>
                <AttachmentList attachments={attachments} onOpen={onOpen} activeAttachmentId={activeAttachmentId} />
            </Box>
        </Dropdown>
    );
};

export default memo(AttachmentsDropdown);
