import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { Dispatch, SetStateAction } from 'react';
import { ElementFactory } from 'react-bem-factory';

export enum WorkflowSettingsScrollPositionState {
    None = 'None',
    SectionCaptureViaEmail = 'SectionCaptureViaEmail',
}

export enum ESettingsSection {
    LockDate = 'LockDate',
    SafetyCatch = 'SafetyCatch',
    FraudBypassing = 'FraudBypassing',
    FraudChangesAfter = 'FraudChangesAfter',
    XeroBillMatching = 'XeroBillMatching',
    XeroPOMatching = 'XeroPOMatching',
    GoodsReceivedNote = 'GoodsReceivedNote',
    PriceChecker = 'PriceChecker',
    Receipting = 'Receipting',
    Instruction = 'Instruction',
    LineItemSettings = 'LineItemSettings',
    DecisionPolicy = 'DecisionPolicy',
    OverrideGoToBtnInXero = 'OverrideGoToBtnInXero',
    QBooksBillMatching = 'QBooksBillMatching',
    NetSuiteFraudBypassing = 'NetSuiteFraudBypassing',
    NetSuiteFraudChangesAfter = 'NetSuiteFraudChangesAfter',
    NetSuiteExpenseReportFraudChangesAfter = 'NetSuiteExpenseReportFraudChangesAfter',
    EmailToSupplierSetting = 'EmailToSupplierSetting',
    QBooksFraudBypassing = 'QBooksFraudBypassing',
    QBooksFraudChangesAfter = 'QBooksFraudChangesAfter',
    DearFraudBypassing = 'DearFraudBypassing',
    DearFraudChangesAfter = 'DearFraudChangesAfter',
    DearPORejectedNoteToMemo = 'DearPORejectedNoteToMemo',
    SupplierBankAccountRequired = 'SupplierBankAccountRequired',
    NetSuiteAvailableLineItemType = 'NetSuiteAvailableLineItemType',
    PostingPreferencesType = 'PostingPreferencesType',
    UseRejectedPrefix = 'UseRejectedPrefix',
    Terms = 'Terms',
    PushApprovalMaxUrlToNetSuite = 'PushApprovalMaxUrlToNetSuite',
    HistoryPushingType = 'HistoryPushingType',
    CaptureViaEmail = 'CaptureViaEmail',
    BalanceControlCheck = 'BalanceControlCheck',
}

export interface DefaultCcOption {
    id: string;
    text: string;
}

export interface ChangeHandler<TValue> {
    (value: TValue): void;
}

export type TemplateSettingsChangeHandler = React.Dispatch<React.SetStateAction<domain.TemplateSettings>>;

export interface CopyToClipboardHandler<TValue extends string> {
    (value: TValue): void;
}

export interface CheckValidFraudBypassing {
    (integrationType: domain.IntegrationType): boolean;
}

export interface UseEffectiveDateLimits {
    (templateSettings: domain.TemplateSettings): selectors.types.EffectiveDateLimits | null;
}

export interface SetWorkflowSettingsHandler extends Dispatch<SetStateAction<domain.TemplateSettings>> {}

export interface WorkflowSettingsPopupSectionProps {
    templateSettings: domain.TemplateSettings;
    onChange: SetWorkflowSettingsHandler;
    readonly?: boolean;
    qa: ElementFactory;
}
