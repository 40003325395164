import { Cin7Logo, NetSuiteLogo, QBooksLogo, XeroLogo } from '@approvalmax/ui';
import { domain } from 'modules/data';
import { ConnectQboMedium, ConnectQboMediumDisabled } from 'modules/sprites';

import { messages } from './IntegrationCard.messages';
import { Meta } from './IntegrationCard.types';

export const meta: Meta = {
    [domain.IntegrationType.Xero]: {
        logo: XeroLogo,
        title: messages.xeroTitle,
        description: messages.xeroDescription,
        button: {
            title: messages.xeroTooltip,
            label: messages.connect,
            color: 'brandXero100',
        },
        learnMoreUrl: 'https://support.approvalmax.com/portal/kb/approvalmax-1/approvalmax-for-xero',
    },
    [domain.IntegrationType.QBooks]: {
        logo: QBooksLogo,
        title: messages.qboTitle,
        description: messages.qboDescription,
        button: {
            title: messages.qboTooltip,
            component: <ConnectQboMedium height='40px' color='brandQBO80' />,
            componentDisabled: <ConnectQboMediumDisabled height='40px' />,
            color: 'brandQBO80',
        },
        learnMoreUrl: 'https://support.approvalmax.com/portal/kb/approvalmax-1/approvalmax-for-quickbooks-online',
    },
    [domain.IntegrationType.NetSuite]: {
        logo: NetSuiteLogo,
        title: messages.netSuiteTitle,
        description: messages.netSuiteDescription,
        button: {
            title: messages.netSuiteTooltip,
            label: messages.connect,
            color: 'midnight80',
        },
        learnMoreUrl: 'https://support.approvalmax.com/portal/en/kb/approvalmax-1/approvalmax-for-netsuite',
    },
    [domain.IntegrationType.Dear]: {
        logo: Cin7Logo,
        title: messages.cin7Title,
        description: messages.cin7Description,
        button: {
            title: messages.cin7Tooltip,
            label: messages.connect,
            color: 'brandCin780',
        },
        learnMoreUrl: 'https://support.approvalmax.com/portal/en/kb/approvalmax-1/approvalmax-for-dear',
    },
    [domain.IntegrationType.None]: null,
};
