import { ActionMenu, Button, Popup } from '@approvalmax/ui';
import { domain } from 'modules/data';
import { DoneIcon } from 'modules/sprites';
import { FC, memo, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import ChangeStatusPopup from './components/ChangeStatusPopup/ChangeStatusPopup';
import { ClosedStatusButton } from './POStatus.styles';
import { POStatusProps } from './POStatus.types';

const i18nPrefix = 'pages/requestList/containers/card/Toolbar/components/POStatus';

const POStatus: FC<POStatusProps> = (props) => {
    const { request, disabled } = props;

    const [showChangeStatusPopup, setShowChangeStatusPopup] = useState(false);

    const currentPOStatus = request.details.status || domain.QBooksPurchaseOrderStatus.Open;

    const openChangeStatusPopup = useCallback(() => setShowChangeStatusPopup(true), []);

    const closeChangeStatusPopup = useCallback(() => setShowChangeStatusPopup(false), []);

    return (
        <>
            {currentPOStatus === domain.QBooksPurchaseOrderStatus.Open ? (
                <Button
                    execute={openChangeStatusPopup}
                    disabled={disabled}
                    fontSize='small12'
                    preset='small'
                    theme='primary'
                >
                    <FormattedMessage id={`${i18nPrefix}.markAsCloseButton`} defaultMessage='CLOSE PO' />
                </Button>
            ) : (
                <ActionMenu
                    panelFlow='to-left'
                    button={
                        <ClosedStatusButton disabled={disabled} colorTheme='grey' fontSize='small12' preset='small'>
                            <DoneIcon />

                            <FormattedMessage id={`${i18nPrefix}.closedStatus`} defaultMessage='Closed' />
                        </ClosedStatusButton>
                    }
                >
                    <ActionMenu.Item execute={openChangeStatusPopup}>
                        <FormattedMessage id={`${i18nPrefix}.markAsOpenButton`} defaultMessage='Switch to Open' />
                    </ActionMenu.Item>
                </ActionMenu>
            )}

            <Popup isOpen={showChangeStatusPopup} onRequestClose={closeChangeStatusPopup}>
                <ChangeStatusPopup request={request} onClose={closeChangeStatusPopup} />
            </Popup>
        </>
    );
};

export default memo(POStatus);
