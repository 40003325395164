import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestFormPage.SplitViewWrapper.AttachmentToolbar', {
    addButtonText: 'Add',
    deleteButtonText: 'Delete',
    captureButtonText: 'Capture',
    downloadButtonText: 'Download',
    goToPrevDocumentButtonText: 'Go to previous attachment',
    goToNextDocument: 'Go to next attachment',
    deleteFileDescription: 'Delete file?',
    yesDeleteButtonText: 'Yes',
    noDeleteButtonText: 'No',
});
