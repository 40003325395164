import { errorHelpers } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { FC, memo } from 'react';

import { RequestCardBill } from './components/RequestCardBill/RequestCardBill';
import { RequestCardBillPayment } from './components/RequestCardBillPayment/RequestCardBillPayment';
import { RequestCardExpenseReport } from './components/RequestCardExpenseReport/RequestCardExpenseReport';
import { RequestCardInvoice } from './components/RequestCardInvoice/RequestCardInvoice';
import { RequestCardJournalEntry } from './components/RequestCardJournalEntry/RequestCardJournalEntry';
import { RequestCardPurchaseOrder } from './components/RequestCardPurchaseOrder/RequestCardPurchaseOrder';
import { RequestCardSalesOrder } from './components/RequestCardSalesOrder/RequestCardSalesOrder';
import { RequestCardVendor } from './components/RequestCardVendor/RequestCardVendor';
import { RequestCardVRA } from './components/RequestCardVRA/RequestCardVRA';
import { NetSuiteRequestCardProps } from './NetSuiteRequestCard.types';

export const NetSuiteRequestCard: FC<NetSuiteRequestCardProps> = memo((props) => {
    const { className, request, preloadedRequestDetails, readonly } = props;

    switch (request.integrationCode) {
        case domain.IntegrationCode.NetSuiteBill:
            return (
                <RequestCardBill
                    request={request}
                    className={className}
                    preloadedRequestDetails={preloadedRequestDetails}
                    readonly={readonly}
                />
            );

        case domain.IntegrationCode.NetSuitePO:
            return <RequestCardPurchaseOrder request={request} className={className} />;

        case domain.IntegrationCode.NetSuiteExpenseReport: {
            return <RequestCardExpenseReport request={request} className={className} />;
        }

        case domain.IntegrationCode.NetSuiteSalesOrder: {
            return <RequestCardSalesOrder request={request} className={className} />;
        }

        case domain.IntegrationCode.NetSuiteVRA: {
            return <RequestCardVRA request={request} className={className} />;
        }

        case domain.IntegrationCode.NetSuiteBillPayment: {
            return <RequestCardBillPayment request={request} className={className} />;
        }

        case domain.IntegrationCode.NetSuiteInvoice: {
            return <RequestCardInvoice request={request} className={className} />;
        }

        case domain.IntegrationCode.NetSuiteJournalEntry: {
            return <RequestCardJournalEntry request={request} className={className} />;
        }

        case domain.IntegrationCode.NetSuiteVendor: {
            return <RequestCardVendor request={request} className={className} />;
        }

        default:
            throw errorHelpers.notSupportedError();
    }
});

NetSuiteRequestCard.displayName = 'NetSuiteRequestCard';
