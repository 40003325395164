import { RequestMutationOptions } from '@approvalmax/data';
import { useQueryClient } from '@tanstack/react-query';
import { useMutate } from 'shared/data';

import { companiesApiPaths } from '../paths';
import { UpdateCompany } from './useUpdateCompany.types';

export const useUpdateCompany = (mutationOptions?: RequestMutationOptions<UpdateCompany>) => {
    const queryClient = useQueryClient();

    return useMutate(companiesApiPaths.edit, {
        mutationOptions: {
            ...mutationOptions,
            onSuccess: (response, variables, context) => {
                void queryClient.invalidateQueries([
                    companiesApiPaths.select,
                    { companyId: variables.body?.companyId },
                ]);
                mutationOptions?.onSuccess?.(response, variables, context);
            },
        },
        mapToCamelCase: true,
    });
};
