import { FC, memo } from 'react';

import { useJournalEntryLinesFieldSettings } from '../../NetSuiteRequestCard.hooks';
import { NetSuiteLineTable } from '../NetSuiteLineTable/NetSuiteLineTable';
import { useTableDefinitions } from './NetSuiteJournalEntryLines.hooks';
import { messages } from './NetSuiteJournalEntryLines.messages';
import { NetSuiteJournalEntryLinesProps } from './NetSuiteJournalEntryLines.types';

export const NetSuiteJournalEntryLines: FC<NetSuiteJournalEntryLinesProps> = memo((props) => {
    const { itemLines, companyCustomFields, documentFields, exchangeRate } = props;

    const itemsFieldSettings = useJournalEntryLinesFieldSettings(documentFields || []);

    const tableDef = useTableDefinitions(itemLines, companyCustomFields, itemsFieldSettings, exchangeRate);

    return itemLines.length > 0 ? (
        <NetSuiteLineTable title={messages.sectionHeaderText} columnDefinitions={tableDef} lineItems={itemLines} />
    ) : null;
});

NetSuiteJournalEntryLines.displayName = 'NetSuiteJournalEntryLines';
