import { DotsIcon } from '@approvalmax/ui';
import { Button, Dropdown, Menu, Text } from '@approvalmax/ui/src/components';
import { FC, memo, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { removeLineFromActiveMatrixAutoApproval, renameLineOfActiveMatrix } from '../../../../actions';
import { RuleNameButton } from '../RuleNameButton/RuleNameButton';
import { messages } from './RuleNameCell.messages';
import { Root } from './RuleNameCell.styles';
import { RuleNameCellProps } from './RuleNameCell.types';

export const RuleNameCell: FC<RuleNameCellProps> = memo((props) => {
    const { ruleName, ruleNames, ruleIndex, readonly } = props;

    const dispatch = useDispatch();
    const [isEditing, setIsEditing] = useState(false);

    const removeLine = useCallback(() => {
        dispatch(removeLineFromActiveMatrixAutoApproval(ruleIndex));
    }, [dispatch, ruleIndex]);

    const renameLine = useCallback(
        (name: string) => {
            dispatch(renameLineOfActiveMatrix(name, ruleIndex));
            setIsEditing(false);
        },
        [dispatch, ruleIndex]
    );

    const actionItems = useMemo(
        () => [
            {
                name: messages.rename,
                title: messages.rename,
                onClick: () => setIsEditing(true),
            },
            {
                name: messages.removeRule,
                title: messages.removeRuleTitle,
                onClick: removeLine,
            },
        ],
        [removeLine]
    );

    return (
        <Root wrap={false} width='236px' alignItems='start' justifyContent='space-between'>
            <RuleNameButton
                ruleNames={ruleNames}
                onSubmit={renameLine}
                defaultValue={ruleName}
                isOpen={isEditing}
                onOpen={setIsEditing}
            >
                <Text
                    spacing='4 0 0 0'
                    font='label'
                    fontSize='small'
                    fontWeight='medium'
                    color='midnight100'
                    ellipsis={1}
                >
                    {ruleName}
                </Text>
            </RuleNameButton>

            {!readonly && (
                <Dropdown
                    closeOnClickInnerButton
                    activator={
                        <Button noPadding>
                            <DotsIcon color='midnight90' />
                        </Button>
                    }
                    width='auto'
                >
                    <Menu items={actionItems} size='small' />
                </Dropdown>
            )}
        </Root>
    );
});

RuleNameCell.displayName = 'RuleNameCell';
