import { RequestMutationOptions } from '@approvalmax/data';
import { useQueryClient } from '@tanstack/react-query';
import { useMutate } from 'shared/data';

import { budgetsApiPaths } from '../paths';
import { UpdateXeroBudget } from './useUpdateXeroBudget.types';

export const useUpdateXeroBudget = (mutationOptions?: RequestMutationOptions<UpdateXeroBudget>) => {
    const queryClient = useQueryClient();

    return useMutate(budgetsApiPaths.updateXeroBudget, {
        mutationOptions: {
            ...mutationOptions,
            onSettled: async (data, error, variables, context) => {
                await queryClient.invalidateQueries([
                    budgetsApiPaths.updateXeroBudget,
                    { companyId: variables.body?.companyId },
                ]);
                mutationOptions?.onSettled?.(data, error, variables, context);
            },
        },
        apiVersion: 'v1',
    });
};
