import { Box, Checkbox, ConditionWrapper, Flex, Grid, NumberField, Radio, Text } from '@approvalmax/ui/src/components';
import { domain } from 'modules/data';
import { actions } from 'modules/profile';
import { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { xeroBillToPoMatchingLearnMore } from '../../WorkflowSettingsPopupContent.constants';
import { PromotionButtons } from '../PromotionButtons/PromotionButtons';
import { SectionFeaturePromotion } from '../SectionFeaturePromotion/SectionFeaturePromotion';
import { SettingsSection } from '../SettingsSection/SettingsSection';
import SettingsSubsection from '../SettingsSubsection/SettingsSubsection';
import { promoTemplateSettings } from './SectionXeroBillMatching.constants';
import { useSectionXeroBillMatching } from './SectionXeroBillMatching.hooks';
import { messages } from './SectionXeroBillMatching.messages';
import { RetrospectivePoMatchingOption, SectionXeroBillMatchingProps } from './SectionXeroBillMatching.types';

export const SectionXeroBillMatching = memo<SectionXeroBillMatchingProps>((props) => {
    const { company } = props;

    const templateSettings = 'promoView' in props ? promoTemplateSettings : props.templateSettings;
    const readonly = 'promoView' in props ? true : props.readonly;
    const qa = 'promoView' in props ? null : props.qa;
    const onChange = 'promoView' in props ? undefined : props.onChange;
    const isPromoView = 'promoView' in props;

    const dispatch = useDispatch();

    const handleTryIt = useCallback(() => {
        dispatch(actions.openConsentToStartTrialPopup(company, 'po_matching_settings'));
    }, [company, dispatch]);

    const {
        onChangeManualMatch,
        onChangeNotMatchedApproval,
        onChangeNotMatchedThreshold,
        onChangeOverbudgetApproval,
        onChangeOverbudgetPercent,
        onChangeOverbudgetAmount,
        onChangeRetrospectivePoMatching,
        onChangeAmountType,
        onChangeInsufficientAllowanceType,
        onChangeInsufficientThreshold,
        onChangeInsufficientPercentageThreshold,
        onChangeAddMatchedPOsRequestersAsApproversForBill,
    } = useSectionXeroBillMatching(onChange);

    if (!templateSettings.billMatchingSettings) {
        return null;
    }

    const isEnabledInsufficientBudgetMatch =
        templateSettings.billMatchingSettings.allowApprovalOfNotMatchedBills ===
            domain.CompanyMatchingSettingsBillApprovalPolicy.WithThreshold ||
        templateSettings.billMatchingSettings.allowApprovalOfNotMatchedBills ===
            domain.CompanyMatchingSettingsBillApprovalPolicy.Never;

    return (
        <ConditionWrapper
            condition={isPromoView}
            wrapperIf={(children) => (
                <SectionFeaturePromotion title={messages.title} onTryIt={handleTryIt}>
                    <Box spacing='0 24 16'>
                        <Flex spacing='24' direction='column'>
                            {children}
                        </Flex>
                    </Box>
                </SectionFeaturePromotion>
            )}
            wrapperElse={(children) => (
                <SettingsSection title={messages.title} learnMoreLink={xeroBillToPoMatchingLearnMore}>
                    {children}
                </SettingsSection>
            )}
        >
            <>
                <SettingsSubsection
                    subtitle={messages.sectionManualTitle}
                    color={isPromoView ? 'midnight60' : undefined}
                >
                    <Checkbox
                        disabled={readonly}
                        checked={templateSettings.billMatchingSettings.manualMatchingEnabled}
                        onChange={onChangeManualMatch}
                        data-qa={qa?.('MatchingSection-manualMatchEnabled')}
                    >
                        {messages.optionManual}
                    </Checkbox>
                </SettingsSubsection>

                {isPromoView && (
                    <PromotionButtons learnMoreLink={xeroBillToPoMatchingLearnMore} onClickTryIt={handleTryIt} />
                )}

                <SettingsSubsection
                    subtitle={messages.sectionNotMatchedApproveTitle}
                    color={isPromoView ? 'midnight60' : undefined}
                >
                    <Radio.Group
                        disabled={readonly}
                        value={templateSettings.billMatchingSettings.allowApprovalOfNotMatchedBills}
                        onChange={onChangeNotMatchedApproval}
                        name='notMatchedApproval'
                        block
                        data-qa={qa?.('MatchingSection-notMatchedBillsApprovalAllowed')}
                    >
                        <Radio value={domain.CompanyMatchingSettingsBillApprovalPolicy.Always}>
                            {messages.optionNotMatchedApproveYes}
                        </Radio>

                        <Grid gap={8}>
                            <Radio value={domain.CompanyMatchingSettingsBillApprovalPolicy.WithThreshold}>
                                {messages.optionNotMatchedApproveYesAmount}
                            </Radio>

                            <Flex spacing='8' alignItems='center'>
                                <Box width={20} />

                                <Box width={120}>
                                    <NumberField
                                        min={0}
                                        max={999999999999}
                                        invalid={
                                            templateSettings.billMatchingSettings.notMatchedBillsApprovalThreshold < 0
                                        }
                                        value={templateSettings.billMatchingSettings.notMatchedBillsApprovalThreshold}
                                        onChange={onChangeNotMatchedThreshold}
                                        textAlign='right'
                                        disabled={
                                            templateSettings.billMatchingSettings.allowApprovalOfNotMatchedBills !==
                                                domain.CompanyMatchingSettingsBillApprovalPolicy.WithThreshold ||
                                            readonly
                                        }
                                        size='small'
                                        clearable={false}
                                        data-qa={qa?.('MatchingSection-notMatchedBillsApprovalThreshold')}
                                    />
                                </Box>

                                <Text font='body' fontSize='medium' color='midnight70'>
                                    {company.defaultCurrencyText}
                                </Text>
                            </Flex>
                        </Grid>

                        <Radio value={domain.CompanyMatchingSettingsBillApprovalPolicy.Never}>
                            {messages.optionNotMatchedApproveNo}
                        </Radio>
                    </Radio.Group>
                </SettingsSubsection>

                <SettingsSubsection
                    subtitle={messages.sectionInsufficientBudgetTitle}
                    color={isPromoView ? 'midnight60' : undefined}
                >
                    <Radio.Group
                        disabled={readonly || !isEnabledInsufficientBudgetMatch}
                        value={
                            isEnabledInsufficientBudgetMatch
                                ? templateSettings.billMatchingSettings.insufficientBudgetMatchBillsApprovalAllowed
                                : domain.ApprovalAllowanceType.Always
                        }
                        onChange={onChangeInsufficientAllowanceType}
                        name='insufficientAllowanceType'
                        block
                        data-qa={qa?.('MatchingSection-insufficientBudgetMatchBillsApprovalAllowed')}
                    >
                        <Radio value={domain.ApprovalAllowanceType.Always}>
                            {messages.optionInsufficientBudgetAlways}
                        </Radio>

                        <Grid gap={8}>
                            <Radio value={domain.ApprovalAllowanceType.WithThreshold}>
                                {messages.optionInsufficientBudgetWithThreshold}
                            </Radio>

                            <Flex spacing='8' alignItems='center'>
                                <Box width={20} />

                                <Box width={120}>
                                    <NumberField
                                        textAlign='right'
                                        value={
                                            templateSettings.billMatchingSettings
                                                .insufficientBudgetMatchBillsApprovalThreshold
                                        }
                                        invalid={
                                            templateSettings.billMatchingSettings
                                                .insufficientBudgetMatchBillsApprovalThreshold < 0
                                        }
                                        min={0}
                                        max={999999999999}
                                        onChange={onChangeInsufficientThreshold}
                                        disabled={
                                            !isEnabledInsufficientBudgetMatch ||
                                            readonly ||
                                            templateSettings.billMatchingSettings
                                                .insufficientBudgetMatchBillsApprovalAllowed !==
                                                domain.ApprovalAllowanceType.WithThreshold
                                        }
                                        size='small'
                                        clearable={false}
                                        data-qa={qa?.('MatchingSection-insufficientBudgetMatchBillsApprovalThreshold')}
                                    />
                                </Box>

                                <Text font='body' fontSize='medium' color='midnight70'>
                                    {company.defaultCurrencyText}
                                </Text>
                            </Flex>
                        </Grid>

                        <Grid gap={8}>
                            <Radio value={domain.ApprovalAllowanceType.WithPercentageThreshold}>
                                {messages.optionInsufficientBudgetWithPercentageThreshold}
                            </Radio>

                            <Flex spacing='8' alignItems='center'>
                                <Box width={20} />

                                <Box width={120}>
                                    <NumberField
                                        textAlign='right'
                                        value={
                                            templateSettings.billMatchingSettings
                                                .insufficientBudgetMatchBillsApprovalPercentageThreshold
                                        }
                                        invalid={
                                            templateSettings.billMatchingSettings
                                                .overbudgetBillsApprovalPercentageThreshold < 0
                                        }
                                        min={0}
                                        max={100}
                                        onChange={onChangeInsufficientPercentageThreshold}
                                        disabled={
                                            !isEnabledInsufficientBudgetMatch ||
                                            readonly ||
                                            templateSettings.billMatchingSettings
                                                .insufficientBudgetMatchBillsApprovalAllowed !==
                                                domain.ApprovalAllowanceType.WithPercentageThreshold
                                        }
                                        size='small'
                                        clearable={false}
                                        data-qa={qa?.(
                                            'MatchingSection-insufficientBudgetMatchBillsApprovalPercentageThreshold'
                                        )}
                                    />
                                </Box>

                                <Text font='body' fontSize='medium' color='midnight70'>
                                    {messages.percent}
                                </Text>
                            </Flex>
                        </Grid>

                        <Radio value={domain.ApprovalAllowanceType.Never}>
                            {messages.optionInsufficientBudgetNever}
                        </Radio>
                    </Radio.Group>
                </SettingsSubsection>

                <SettingsSubsection
                    subtitle={messages.sectionOverbudgetTitle}
                    color={isPromoView ? 'midnight60' : undefined}
                >
                    <Radio.Group
                        value={templateSettings.billMatchingSettings.allowApprovalOfOverbudgetBills}
                        onChange={onChangeOverbudgetApproval}
                        disabled={readonly}
                        name='overbudgetApproval'
                        block
                        data-qa={qa?.('MatchingSection-overbudgetMatchBillsApprovalAllowed')}
                    >
                        <Radio value={domain.CompanyMatchingSettingsBillApprovalPolicy.Always}>
                            {messages.optionOverbudgetYes}
                        </Radio>

                        <Grid gap={8}>
                            <Radio value={domain.CompanyMatchingSettingsBillApprovalPolicy.WithPercentageThreshold}>
                                {messages.optionOverbudgetYesPercent}
                            </Radio>

                            <Flex spacing='8' alignItems='center'>
                                <Box width={20} />

                                <Box width={120}>
                                    <NumberField
                                        textAlign='right'
                                        value={
                                            templateSettings.billMatchingSettings
                                                .overbudgetBillsApprovalPercentageThreshold
                                        }
                                        invalid={
                                            templateSettings.billMatchingSettings
                                                .overbudgetBillsApprovalPercentageThreshold < 0
                                        }
                                        min={0}
                                        max={100}
                                        onChange={onChangeOverbudgetPercent}
                                        disabled={
                                            templateSettings.billMatchingSettings.allowApprovalOfOverbudgetBills !==
                                                domain.CompanyMatchingSettingsBillApprovalPolicy
                                                    .WithPercentageThreshold || readonly
                                        }
                                        size='small'
                                        clearable={false}
                                        data-qa={qa?.('MatchingSection-overbudgetMatchBillsApprovalPercentThreshold')}
                                    />
                                </Box>

                                <Text font='body' fontSize='medium' color='midnight70'>
                                    {messages.percentBelowXero}
                                </Text>
                            </Flex>
                        </Grid>

                        <Grid gap={8}>
                            <Radio value={domain.CompanyMatchingSettingsBillApprovalPolicy.WithThreshold}>
                                {messages.optionOverbudgetYesAmount}
                            </Radio>

                            <Flex spacing='8' alignItems='center'>
                                <Box width={20} />

                                <Box width={120}>
                                    <NumberField
                                        textAlign='right'
                                        value={templateSettings.billMatchingSettings.overbudgetBillsApprovalThreshold}
                                        invalid={
                                            templateSettings.billMatchingSettings.overbudgetBillsApprovalThreshold < 0
                                        }
                                        min={0}
                                        max={999999999999}
                                        onChange={onChangeOverbudgetAmount}
                                        disabled={
                                            templateSettings.billMatchingSettings.allowApprovalOfOverbudgetBills !==
                                                domain.CompanyMatchingSettingsBillApprovalPolicy.WithThreshold ||
                                            readonly
                                        }
                                        size='small'
                                        clearable={false}
                                        data-qa={qa?.('MatchingSection-overbudgetMatchBillsApprovalThreshold')}
                                    />
                                </Box>

                                <Text font='body' fontSize='medium' color='midnight70'>
                                    {messages.belowZero}
                                </Text>
                            </Flex>
                        </Grid>

                        <Radio value={domain.CompanyMatchingSettingsBillApprovalPolicy.Never}>
                            {messages.optionOverbudgetNo}
                        </Radio>
                    </Radio.Group>
                </SettingsSubsection>

                <SettingsSubsection
                    subtitle={messages.sectionAddMatchedPOsRequestersTitle}
                    color={isPromoView ? 'midnight60' : undefined}
                >
                    <Radio.Group
                        value={templateSettings.billMatchingSettings.addMatchedPOsRequestersAsApproversForBill}
                        onChange={onChangeAddMatchedPOsRequestersAsApproversForBill}
                        disabled={readonly}
                        name='addMatchedPosRequestersAsApprovers'
                        block
                        data-qa={qa?.('MatchingSection-retrospectivePOMatchingAllowed')}
                    >
                        <Radio value={domain.AddMatchedPOsRequestersAsApproversForBillStatuses.None}>
                            {messages.optionAddMatchedPOsRequestersNone}
                        </Radio>

                        <Radio value={domain.AddMatchedPOsRequestersAsApproversForBillStatuses.Manually}>
                            {messages.optionAddMatchedPOsRequestersManually}
                        </Radio>

                        <Radio value={domain.AddMatchedPOsRequestersAsApproversForBillStatuses.All}>
                            {messages.optionAddMatchedPOsRequestersAll}
                        </Radio>
                    </Radio.Group>
                </SettingsSubsection>

                <SettingsSubsection
                    subtitle={messages.sectionRetrospectiveTitle}
                    color={isPromoView ? 'midnight60' : undefined}
                >
                    <Radio.Group
                        value={
                            templateSettings.billMatchingSettings.canMatchBillsWithRetrospectivePurchaseOrders
                                ? RetrospectivePoMatchingOption.True
                                : RetrospectivePoMatchingOption.False
                        }
                        onChange={onChangeRetrospectivePoMatching}
                        disabled={readonly}
                        name='canMatchBillsWithRettrospectivePruchaseOrders'
                        block
                    >
                        <Radio value={RetrospectivePoMatchingOption.True}>{messages.optionRetrospectiveYes}</Radio>

                        <Radio value={RetrospectivePoMatchingOption.False}>{messages.optionRetrospectiveNo}</Radio>
                    </Radio.Group>
                </SettingsSubsection>

                <SettingsSubsection
                    subtitle={messages.sectionAmountTypeTitle}
                    warning={isPromoView ? undefined : messages.warning}
                    color={isPromoView ? 'midnight60' : undefined}
                >
                    <Radio.Group
                        value={templateSettings.billMatchingSettings.amountType}
                        onChange={onChangeAmountType}
                        disabled={readonly}
                        name='amountType'
                        block
                        data-qa={qa?.('MatchingSection-amountType')}
                    >
                        <Radio value={domain.MatchingAmountType.Gross}>{messages.optionAmountTypeGross}</Radio>

                        <Radio value={domain.MatchingAmountType.Net}>{messages.optionAmountTypeNet}</Radio>
                    </Radio.Group>
                </SettingsSubsection>
            </>
        </ConditionWrapper>
    );
});

SectionXeroBillMatching.displayName = 'SectionXeroBillMatching';
