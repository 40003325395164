import { Mods, mods, theme } from '@approvalmax/theme';
import { LoadingBar, TransparentButton } from '@approvalmax/ui';
import { StyledMods } from '@styled-kit/mods';
import { font } from 'modules/styles';
import styled from 'styled-components';

import amSmallBg from './assets/am-small-bg-x2.png';

export const Root = styled.div`
    position: relative;
    flex: none;
    background: #1e5864 url(${amSmallBg}) no-repeat center left;
    background-size: 960px 232px;
    display: flex;
    flex-flow: column;
    padding: 25px 60px 20px 60px;

    @media (max-width: ${theme.container.breakpoint.xsmall}) {
        padding: 40px 20px;
        background: #384650;
        align-items: center;
    }
`;

export const LogoContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const LogoText = styled.div`
    ${font(11, '#fff')}
    margin-right: 5px;
`;

export const TitleLine = styled.div`
    display: flex;
    align-items: center;
    margin-top: 24px;

    @media (max-width: ${theme.container.breakpoint.xsmall}) {
        margin-top: 21px;
    }
`;

export const Title = styled.div`
    ${font(20, '#fff', 'semibold')}
    flex: 1;

    @media (max-width: ${theme.container.breakpoint.xsmall}) {
        ${font(16, '#fff', 'standard')};
        text-align: center;
    }
`;

export const StyledCloseButton = styled(TransparentButton)`
    position: absolute;
    left: 15px;
    top: 15px;
    z-index: 1;
    fill: currentColor;
    color: #fff;
    padding: 10px;

    :not(.disabled) > &:hover {
        color: ${theme.color.midnight80};
    }
`;

export const StyledLoadingBar = styled(LoadingBar)<StyledMods<Mods<'active'>>>`
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity 300ms ease-in-out;

    ${mods.active.true`
        opacity: 1;
    `};
`;
