import { atom, selector } from 'recoil';

export enum ContentSplitViewMode {
    AttachmentsToBillLinkedToBatchPayment = 'AttachmentsToBillLinkedToBatchPayment',
    AttachmentsToComment = 'AttachmentsToComment',
    AttachmentsToEmailToSupplier = 'AttachmentsToEmailToSupplier',
    AttachmentsToExpenseLineItem = 'AttachmentsToExpenseLineItem',
    AttachmentsToNetSuiteBillLinkedToBillPayment = 'AttachmentsToNetSuiteBillLinkedToBillPayment',
    AttachmentsToRequest = 'AttachmentsToRequest',
    // AttachmentsToUnknown is used when a link with an attachmentId from an email was clicked
    AttachmentsToUnknown = 'AttachmentsToUnknown',
    AuditReport = 'AuditReport',
    BillLinkedToBatchPayment = 'BillLinkedToBatchPayment',
    NetSuiteBillLinkedToBillPayment = 'NetSuiteBillLinkedToBillPayment',
    VersionHistory = 'VersionHistory',
    Empty = 'Empty',
}

interface EmptyPayload {
    mode: ContentSplitViewMode.Empty;
}

interface AttachmentUnknownTypePayload {
    mode: ContentSplitViewMode.AttachmentsToUnknown;
    attachmentId: string;
}

interface AttachmentsToEmailToSupplierPayload {
    mode: ContentSplitViewMode.AttachmentsToEmailToSupplier;
    attachmentId: string;
}

interface AttachmentsToRequestPayload {
    mode: ContentSplitViewMode.AttachmentsToRequest;
    attachmentId: string;
}

interface AttachmentsToBillLinkedToBatchPaymentPayload {
    mode: ContentSplitViewMode.AttachmentsToBillLinkedToBatchPayment;
    attachmentId: string;
    billId: string;
    billFriendlyName: string;
}

interface AttachmentsToCommentPayload {
    mode: ContentSplitViewMode.AttachmentsToComment;
    attachmentId: string;
    commentId?: string;
}

interface AttachmentsToExpenseLineItemPayload {
    mode: ContentSplitViewMode.AttachmentsToExpenseLineItem;
    attachmentId: string;
}

interface AttachmentsToNetSuiteBillLinkedToBillPaymentPayload {
    mode: ContentSplitViewMode.AttachmentsToNetSuiteBillLinkedToBillPayment;
    attachmentId: string;
    billId: string;
    billFriendlyName: string;
}

interface AuditReportPayload {
    mode: ContentSplitViewMode.AuditReport;
}

interface BillLinkedToBatchPaymentPayload {
    mode: ContentSplitViewMode.BillLinkedToBatchPayment;
    billId: string;
}

interface NetSuiteBillLinkedToBillPaymentPayload {
    mode: ContentSplitViewMode.NetSuiteBillLinkedToBillPayment;
    billId: string;
}

interface VersionHistoryPayload {
    mode: ContentSplitViewMode.VersionHistory;
    activeRequestVersion?: number;
}

export type ContentSplitViewState =
    | AttachmentsToEmailToSupplierPayload
    | AttachmentsToRequestPayload
    | AttachmentsToBillLinkedToBatchPaymentPayload
    | AttachmentsToNetSuiteBillLinkedToBillPaymentPayload
    | AttachmentsToCommentPayload
    | AttachmentsToExpenseLineItemPayload
    | AttachmentUnknownTypePayload
    | AuditReportPayload
    | BillLinkedToBatchPaymentPayload
    | NetSuiteBillLinkedToBillPaymentPayload
    | VersionHistoryPayload
    | EmptyPayload;

export const contentSplitViewState = atom<ContentSplitViewState>({
    key: 'contentSplitViewState',
    default: { mode: ContentSplitViewMode.Empty },
});

export const activeAttachmentIdState = selector({
    key: 'activeAttachmentIdContentSplitView',
    get: ({ get }) => {
        const contentSplitView = get(contentSplitViewState);

        return ('attachmentId' in contentSplitView && contentSplitView.attachmentId) || undefined;
    },
});

export const attachmentModes = [
    ContentSplitViewMode.AttachmentsToBillLinkedToBatchPayment,
    ContentSplitViewMode.AttachmentsToNetSuiteBillLinkedToBillPayment,
    ContentSplitViewMode.AttachmentsToComment,
    ContentSplitViewMode.AttachmentsToEmailToSupplier,
    ContentSplitViewMode.AttachmentsToExpenseLineItem,
    ContentSplitViewMode.AttachmentsToRequest,
    ContentSplitViewMode.AttachmentsToUnknown,
];
