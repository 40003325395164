import { Mods, mods } from '@approvalmax/theme';
import { font, uTextEllipsis } from '@approvalmax/ui';
import FieldEntry from 'pages/requestList/components/FieldEntry';
import styled from 'styled-components';

import ResendQuote from './components/ResendQuote/ResendQuote';

export const Section = styled.div`
    display: flex;
    flex-flow: column;
    margin-bottom: 30px;
`;

export const Row = styled.div`
    display: flex;

    &:not(:last-child) {
        margin-bottom: 5px;
    }

    & > * {
        flex: 1;
        overflow: hidden;
        margin-right: 5px;
    }

    & > *:last-child {
        flex: 2;
        margin-right: 0;
    }
`;

export const StyledResendQuote = styled(ResendQuote)`
    ${Row} & {
        flex: 1;
    }
`;

export const FieldCc = styled(FieldEntry)<Mods<'hide'>>`
    ${mods.hide.true`
        visibility: hidden;
    `}
`;

export const AttachRow = styled.div`
    display: flex;
    flex-flow: column;
    margin-top: 10px;
`;

export const AttachTitle = styled.div`
    ${font(11, '#777d81', 'semibold')};
    ${uTextEllipsis};

    margin-bottom: 3px;
`;

export const AttachContent = styled.div`
    display: flex;
`;
