import { forwardRef, memo } from 'react';
import { useController } from 'react-hook-form';

import { RichEditor } from '../../RichEditor';
import { ControllerProps } from './Controller.types';

/**
 * The `RichEditor.Controller` allows you to use the `RichEditor` with the `react-hook-form` library.
 * They take the same properties as the `RichEditor` component,
 * plus the `control` property to bind it with the library and `rules` for validation.
 *
 * You can use control without the `Controller` subcomponent if it is in a `Form` or `Form.Part` component.
 */
export const Controller = memo(
    forwardRef<HTMLDivElement, ControllerProps>((props, ref) => {
        const { control, name, defaultValue, ...restProps } = props;

        const { field, fieldState } = useController({
            control,
            name: name || 'richEditor',
            rules: {
                required: restProps.required,
                maxLength: restProps.maxLength,
            },
            defaultValue,
        });

        return <RichEditor {...field} {...fieldState} {...restProps} ref={ref} />;
    })
);

Controller.displayName = 'Controller';
