import './paginator.scss';

import React from 'react';
import bemFactory from 'react-bem-factory';
import { FormattedMessage } from 'react-intl';

const i18nPrefix = 'common.Paginator';

interface Props {
    pageIndex: number;
    lastPage: boolean;
    disabled?: boolean;
    className?: string;
    onPageChange(nextIndex: number): void;
}

class Paginator extends React.Component<Props> {
    public render() {
        const { className, pageIndex, lastPage, disabled } = this.props;
        const bem = bemFactory.block('rpt-paginator');
        const firstPage = pageIndex === 0;

        const buttons = this._getPages().map((x, i) => {
            if (x.pageIndex !== undefined) {
                const active = x.active;

                return (
                    <div
                        key={x.pageIndex}
                        className={bem('page-button', { active })}
                        onClick={active ? undefined : () => this._changePage(x.pageIndex!)}
                    >
                        {x.pageIndex + 1}
                    </div>
                );
            } else {
                return (
                    <div key={`ellipsis-${i}`} className={bem('page-ellipsis')}>
                        &#8230;
                    </div>
                );
            }
        });

        return (
            <div className={bem.add(className)()}>
                <div
                    className={bem('prev-next-button', { disabled: firstPage || disabled })}
                    tabIndex={disabled ? undefined : 0}
                    onClick={() => this._changePage(pageIndex - 1)}
                >
                    <FormattedMessage id={`${i18nPrefix}.prevButtonText`} defaultMessage='Prev' />
                </div>

                <div className={bem('buttons-section')}>{buttons}</div>

                <div
                    className={bem('prev-next-button', { disabled: lastPage || disabled })}
                    tabIndex={disabled ? undefined : 0}
                    onClick={() => this._changePage(pageIndex + 1)}
                >
                    <FormattedMessage id={`${i18nPrefix}.nextButtonText`} defaultMessage='Next' />
                </div>
            </div>
        );
    }

    private _changePage = (newPage: number) => {
        if (this.props.disabled) {
            return;
        }

        if (newPage < 0 || (this.props.lastPage && newPage > this.props.pageIndex)) {
            return;
        }

        this.props.onPageChange(newPage);
    };

    private _getPages = (): Array<{
        pageIndex?: number;
        ellipsis?: boolean;
        active?: boolean;
    }> => {
        let pageArray = [];
        let pageIndex = 0;

        const lastPage = this.props.lastPage ? this.props.pageIndex : this.props.pageIndex + 1;

        if (lastPage > 4) {
            pageArray.push({
                pageIndex,
            });
            pageArray.push({
                ellipsis: true,
            });
            pageIndex = this.props.pageIndex - 1;
        }

        for (let i = pageIndex; i <= lastPage; i += 1) {
            pageArray.push({
                pageIndex: i,
                active: i === this.props.pageIndex,
            });
        }

        if (!this.props.lastPage) {
            pageArray.push({
                ellipsis: true,
            });
        }

        return pageArray;
    };
}

export default Paginator;
