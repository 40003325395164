import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.requestList.FeaturePromotion', {
    learnMore: 'Learn more',
    close: 'Close',
    tryNow: 'Try now for 14 days',

    titleBillToPOMatching: 'Bill to po matching',
    descriptionBillToPOMatchingPO:
        'You can match approved purchase orders and bills to ensure complete control over spending.',
    descriptionBillToPOMatchingBill: 'Match this bill to the relevant PO to ensure complete control over spending.',

    titleBudgetChecking: 'Budget',
    descriptionBudgetChecking: 'Check your spending against the budget right on this screen.',

    titleGoodsReceivedNote: 'Goods received notes',
    descriptionGoodsReceivedNote: 'Track delivery status within PO with goods receieved notes',
});
