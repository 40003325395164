import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { profileApiPaths } from '../paths';
import { FinishTFALogin } from './useFinishTFALogin.types';

export const useFinishTFALogin = (mutationOptions?: RequestMutationOptions<FinishTFALogin>) => {
    return useMutate(profileApiPaths.finishTFALogin, {
        mutationOptions,
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};
