import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { slackApiPaths } from '../paths';
import { CompleteSlackConnectUser } from './useCompleteSlackConnectUser.types';

export const useCompleteSlackConnectUser = (mutationOptions?: RequestMutationOptions<CompleteSlackConnectUser>) => {
    return useMutate(slackApiPaths.authCompleteConnectUser, {
        mutationOptions,
        apiSource: 'slack',
        apiVersion: 'v1',
    });
};
