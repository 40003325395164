import { oDropdownPanel } from '@approvalmax/ui';
import { InfoIcon } from 'modules/sprites';
import styled from 'styled-components';

export const Root = styled.div`
    display: flex;
    justify-content: end;
`;

export const StyledInfoIcon = styled(InfoIcon)`
    cursor: pointer;
    color: #777d81;
`;

export const Panel = styled.div`
    ${oDropdownPanel()}
    display: flex;
    flex-flow: column;
    padding: 20px;
    width: 395px;
    font-size: 11px;
`;
