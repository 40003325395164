import { backend, schemas, stateTree } from 'modules/data';
import { createAction, useDispatch } from 'modules/react-redux';
import moment from 'moment/moment';
import { normalize } from 'normalizr';
import { RELOAD_REQUEST_RESPONSE } from 'pages/requestList/actions';
import { useCallback, useMemo } from 'react';
import { useAirwallexCancelScheduledPayment } from 'shared/data/queries';

import { ProcessCancelScheduledPaymentParams } from './ScheduledPaymentSection.types';

export const useProcessCancelScheduledPayment = (params: ProcessCancelScheduledPaymentParams) => {
    const dispatch = useDispatch();

    const { mutateAsync: cancelScheduledPayment, isLoading } = useAirwallexCancelScheduledPayment();

    const processCancelScheduledPaymentApiCall = useCallback(async () => {
        const res = await cancelScheduledPayment({
            body: params,
        });

        const { entities } = normalize<backend.RequestSelectAnswer, stateTree.Entities>(
            res.Requests[0],
            schemas.requestSchema
        );

        dispatch(
            createAction(RELOAD_REQUEST_RESPONSE, {
                entities,
            })
        );
    }, [cancelScheduledPayment, dispatch, params]);

    return { processCancelScheduledPaymentApiCall, isLoading };
};

export const useScheduledPaymentDate = (scheduledPaymentDate?: string) =>
    useMemo(() => {
        if (!scheduledPaymentDate) return {};

        const momentDate = moment(scheduledPaymentDate);
        const dateString = momentDate.format('DD MMM YYYY');
        const timeString = momentDate.format('HH:mm');

        return {
            dateString,
            timeString,
        };
    }, [scheduledPaymentDate]);
