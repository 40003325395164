import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.Toolbar.PayWithAmax', {
    pay: 'Pay with ApprovalMax',
    areYouSure: 'Are you sure you want to stop payment?',
    yesStop: 'Yes, stop',
    disabledTooltip: 'Bank account is disconnected',
    scheduledDateShouldBeGreater: 'Scheduled date should be greater or equal tomorrow.',
    scheduledDateShouldNoLater: 'Scheduled date should no later than a month (31 days) from the current one.',
});
