import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.PayAndCapturePromotionStep', {
    payDescription:
        'Pay approved bills directly from ApprovalMax. Save time and add payments to your audit trail, without having to give employees access to bank accounts.',
    captureDescription:
        'Say goodbye to manual data entry! Automatically extract data from images or PDFs in seconds. Save time and avoid mistakes. Upload single or bulk files.',
    tryNowButtonText: 'Try now',
    learnMore: 'Learn more',
});
