import { ErrorCode } from '@approvalmax/types';
import { Button } from '@approvalmax/ui';
import { reloadRequest, showDeclineCustomerDecisionPopup } from 'pages/requestList/actions';
import { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSetCustomerDecisionInQuote } from 'shared/data/queries/requests/useSetCustomerDecisionInQuote/useSetCustomerDecisionInQuote';

import { messages } from './CustomerDecisionSection.messages';
import { ButtonsSection, Header, Root } from './CustomerDecisionSection.styles';
import { CustomerDecisionSectionProps } from './CustomerDecisionSection.types';

const CustomerDecisionSection = memo<CustomerDecisionSectionProps>((props) => {
    const { request } = props;
    const dispatch = useDispatch();
    const { mutate: setCustomerDecisionInQuote, isLoading, error } = useSetCustomerDecisionInQuote();

    const isDisabled =
        request.details.isStatusPushPending ||
        isLoading ||
        error?.code === ErrorCode.E5043_XERO_QUOTE_UNAVAILABLE_FOR_CUSTOMER_DECISION;

    const onDeclineButtonClick = useCallback(() => {
        dispatch(showDeclineCustomerDecisionPopup(request.id));
    }, [dispatch, request.id]);

    const onAcceptButtonClick = useCallback(() => {
        setCustomerDecisionInQuote(
            { body: { requestId: request.id, companyId: request.companyId, isAccepted: true } },
            {
                onSettled: () => {
                    dispatch(reloadRequest(request.id, request.companyId));
                },
            }
        );
    }, [dispatch, request.companyId, request.id, setCustomerDecisionInQuote]);

    return (
        <Root>
            <Header>{messages.sectionTitle}</Header>

            <ButtonsSection>
                <Button
                    colorTheme='grey'
                    title={messages.declineButtonTitle}
                    execute={onDeclineButtonClick}
                    disabled={isDisabled}
                >
                    {messages.declineButtonTitle}
                </Button>

                <Button title={messages.acceptButtonTitle} execute={onAcceptButtonClick} disabled={isDisabled}>
                    {messages.acceptButtonTitle}
                </Button>
            </ButtonsSection>
        </Root>
    );
});

export default CustomerDecisionSection;
