import { Mods, mods } from '@approvalmax/theme';
import styled from 'styled-components';

import { font, oDropdownPanel, uTransparentInput } from '../../../styles';
import TransparentButton from '../../../ui/button/TransparentButton';
import { TimeEditorProps } from './TimeEditor.types';

export const Panel = styled.div`
    display: flex;
    flex-direction: column;
    height: 270px;
    background-color: #fff;
    ${oDropdownPanel(0)}
`;

export const ClearButton = styled(TransparentButton)`
    position: absolute;
    top: 50%;
    right: 3px;
    transform: translateY(-50%);
    fill: #eaeaea;
    background: #a3a2a2;
    transition:
        opacity 150ms,
        background 150ms,
        visibility 200ms;
    opacity: 0;
    border: 0;
    border-radius: 50%;
    padding: 3px;
    line-height: 0;

    &:hover {
        background: #ee6f3a;
        fill: #fff;
    }
`;

export const InputWrapper = styled.div<Pick<TimeEditorProps, 'theme' | 'disabled' | 'invalid'>>`
    position: relative;
    display: flex;
    align-items: center;
    border: solid 1px #cacccd;
    border-radius: 2px;
    padding: 3px 20px 3px 3px;

    &:hover ${ClearButton} {
        opacity: 1;
    }

    ${mods('disabled', true)`
        cursor: default;
        color: #a0a1a1;
        background: #f8f5f5;
    `}

    ${mods('theme', 'transparent')`
        border: none;
    `}

    ${mods('invalid', true)`
        background-color: #fff5f3;
        border-color: #f99178;
        color: #923924;

        input::placeholder {
            color: #923924;
        }
    `}
`;

export const EditorWrapper = styled.div`
    position: relative;
    flex: 1;
    width: 50%;
`;

export const EditorIcon = styled.div`
    position: absolute;
    top: 50%;
    right: 5px;
    height: 16px;
    color: #4c8a5c;
    transform: translateY(-50%);

    & > svg {
        width: 16px;
        height: 16px;
    }
`;

export const Input = styled.input`
    ${uTransparentInput};
    width: 100%;
    line-height: 19px;
    cursor: pointer;
`;

export const ValuesWrapper = styled.div`
    display: flex;
    flex: 1;
    overflow: hidden;
    padding: 4px 0;
`;

export const ValueColumn = styled.div`
    overflow-y: auto;
    padding: 2px;
    border-right: 1px solid #ebeaea;

    &:last-child {
        border-right: none;
    }
`;

export const ValueItem = styled.div<Mods<'active'>>`
    overflow: hidden;
    padding: 6px 10px;
    cursor: pointer;
    ${font(12, '#000')}

    &:hover {
        background: #f5f5f5;
    }

    ${mods.active.true`
        background: #f5f5f5;
    `}
`;

export const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 4px 10px;
    border-top: 1px solid #ebeaea;
`;
