import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { reportsApiPaths } from '../paths';
import { GetReport } from './useGetReport.types';

export const useGetReport = (params?: RequestParams<GetReport>, queryOptions?: RequestQueryOptions<GetReport>) => {
    return useGet(reportsApiPaths.report, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetReport['params']['query']>(
                {
                    reportType: params?.query?.reportType,
                    companyId: params?.query?.companyId,
                    reportId: params?.query?.reportId,
                },
                queryOptions
            ),
        },
        apiVersion: 'v1',
    });
};
