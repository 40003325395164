import FieldEntryWrapper from 'pages/requestList/components/FieldEntryWrapper/FieldEntryWrapper';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
`;

export const StyledFieldWrapper = styled(FieldEntryWrapper)`
    flex: 1;
`;
