import { Grid, Text } from '@approvalmax/ui/src/components';
import { domain } from 'modules/data';
import moment from 'moment';
import { memo } from 'react';
import { AmaxPayPaymentStatusTag } from 'shared/components';

import { PaymentStatusCellProps } from './PaymentStatusCell.types';

export const PaymentStatusCell = memo<PaymentStatusCellProps>((props) => {
    const { paymentStatus, statusUpdatedAt, scheduledDate } = props;

    return (
        <Grid gap={4}>
            <div>
                <AmaxPayPaymentStatusTag paymentStatus={paymentStatus} />
            </div>

            {statusUpdatedAt && paymentStatus === domain.AmaxPayPaymentStatus.Paid && (
                <Text font='body' fontSize='xsmall' color='midnight70'>
                    {moment(statusUpdatedAt).format('ll')}
                </Text>
            )}

            {scheduledDate && paymentStatus === domain.AmaxPayPaymentStatus.Scheduled && (
                <Text font='body' fontSize='xsmall' color='midnight70' title={moment(scheduledDate).format('ll')}>
                    {moment(scheduledDate).format('ll')}
                </Text>
            )}
        </Grid>
    );
});

PaymentStatusCell.displayName = 'PaymentStatusCell';
