import { RequestMutationOptions } from '@approvalmax/data';
import { routerHelpers } from '@approvalmax/utils';
import { useQueryClient } from '@tanstack/react-query';
import { useMutate } from 'shared/data';

import { budgetsApiPaths } from '../paths';
import { SyncXeroBudgets } from './useSyncXeroBudgets.types';

export const useSyncXeroBudgets = (mutationOptions?: RequestMutationOptions<SyncXeroBudgets>) => {
    const queryClient = useQueryClient();

    return useMutate(budgetsApiPaths.syncXeroBudgets, {
        mutationOptions: {
            ...mutationOptions,
            onSettled: async (data, error, variables, context) => {
                await queryClient.invalidateQueries([
                    routerHelpers.pathToUrl(budgetsApiPaths.xeroBudgets, variables.params?.path),
                    { companyId: variables.body?.companyId },
                ]);

                mutationOptions?.onSettled?.(data, error, variables, context);
            },
        },
        apiVersion: 'v1',
    });
};
