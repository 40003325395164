import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { GetBatchPaymentXeroBill } from './useGetBatchPaymentXeroBill.types';

export const useGetBatchPaymentXeroBill = (
    params?: RequestParams<GetBatchPaymentXeroBill>,
    queryOptions?: RequestQueryOptions<GetBatchPaymentXeroBill>
) => {
    return useGet(requestsApiPaths.batchPaymentXeroBill, {
        params,
        queryOptions,
        apiVersion: 'v2',
    });
};
