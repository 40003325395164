import { browserHelpers } from '@approvalmax/utils';
import moment from 'moment';

import { textFieldMonthFormat, textFieldYearFormat, textFieldYearSafariFormat } from './DatePicker.constants';
import type { View } from './DatePicker.types';

const isSafari = browserHelpers.isSafari();
const isFirefox = browserHelpers.isFirefox();

const getNonHumanReadableYearFormat = () => {
    return isSafari || isFirefox ? textFieldYearSafariFormat : textFieldYearFormat;
};

export const parseEnteredTextFieldValue = (text: string | undefined, view: View) => {
    if (!text || (text && !text.trim())) return undefined;

    const newValue = moment(text, view === 'year' ? getNonHumanReadableYearFormat() : textFieldMonthFormat);

    if (!newValue.isValid()) return undefined;

    return newValue.toISOString();
};

export const formatTextFieldValue = (value: Date | string | undefined, view: View) => {
    if (!value) return;

    const date = moment(value);

    return date.format(view === 'year' ? getNonHumanReadableYearFormat() : textFieldMonthFormat);
};

export const formatTextFieldValueToHumanReadable = (value: Date | string | undefined) => {
    if (!value) {
        return;
    }

    return new Date(value).toLocaleString(undefined, {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
    });
};

export const normalizeValue = (value: Date | [Date | null, Date | null] | null | undefined) => {
    const preparedValue = Array.isArray(value) ? value[0] : value;

    if (!preparedValue) return undefined;

    return preparedValue;
};

export const getFocusedInputType = (view: View) => {
    if (view === 'year') {
        return isSafari || isFirefox ? 'text' : 'month';
    }

    return 'date';
};

export const exceedMinMax = (value: Date | undefined, minDate: Date | undefined, maxDate: Date | undefined) => {
    if (!value) {
        return false;
    }

    if (minDate && value < minDate) {
        return true;
    }

    if (maxDate && value > maxDate) {
        return true;
    }

    return false;
};
