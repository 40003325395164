import { LoadingSpinner } from '@approvalmax/ui';
import { Box } from '@approvalmax/ui/src/components';
import AttachmentsSection from 'pages/requestList/containers/card/AttachmentsSection';
import { FC, memo } from 'react';
import bemFactory from 'react-bem-factory';
import { useGetWorkflowVersionDocumentFields } from 'shared/data/v1';
import { useNetSuiteVendorFieldSettings } from 'shared/hooks';

import FieldsSection from '../../../../../components/FieldsSection';
import ActivitySection from '../../../../card/ActivitySection';
import AddCommentSection from '../../../../card/AddCommentSection/AddCommentSection';
import Toolbar from '../../../../card/Toolbar/Toolbar';
import { WorkflowSection } from '../../../../card/WorkflowSection';
import { useNetSuiteCustomFields } from '../../NetSuiteRequestCard.hooks';
import { FieldsSectionWrap, Root, StyledField, StyledScrollableArea } from '../../NetSuiteRequestCard.styles';
import { isFieldVisible } from '../../NetSuiteRequestCard.utils';
import NetSuiteCustomFields from '../NetSuiteCustomFields/NetSuiteCustomFields';
import NetSuiteRequestFooter from '../NetSuiteRequestFooter/NetSuiteRequestFooter';
import { NetSuiteVendorFooterFields } from '../NetSuiteVendorFooterFields/NetSuiteVendorFooterFields';
import { messages } from './RequestCardVendor.messages';
import { RequestCardVendorProps } from './RequestCardVendor.types';

const qa = bemFactory.qa('netsuite-vendor-fields-section');

export const RequestCardVendor: FC<RequestCardVendorProps> = memo((props) => {
    const { request, className } = props;

    const { data: workflowVersionDocumentFields, isFetching: isFetchingWorkflowVersionDocumentFields } =
        useGetWorkflowVersionDocumentFields({
            query: {
                companyId: request.companyId,
                workflowVersionIds: [request.workflowVersionId],
            },
        });

    const { details } = request;

    const fieldSettings = useNetSuiteVendorFieldSettings(workflowVersionDocumentFields?.data || []);

    const { data: companyCustomFields } = useNetSuiteCustomFields(request.companyId);

    return (
        <Root className={className}>
            <Toolbar request={request} showVatRegistration={isFieldVisible(fieldSettings.taxNumber)} />

            <StyledScrollableArea scrollResetKey={request.id}>
                {isFetchingWorkflowVersionDocumentFields ? (
                    <Box spacing='20'>
                        <LoadingSpinner />
                    </Box>
                ) : (
                    <FieldsSectionWrap $noBorder>
                        <StyledField
                            qa={qa('vendor-id')}
                            title={messages.vendorIdFieldText}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={details.vendorId}
                            hideIfEmpty
                        />

                        <StyledField
                            qa={qa('type')}
                            title={messages.typeFieldText}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={details.type}
                            hideIfEmpty
                        />

                        <StyledField
                            qa={qa('company-name')}
                            title={messages.companyNameFieldText}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={details.companyName}
                            hideIfEmpty
                        />

                        {isFieldVisible(fieldSettings.webAddress) && (
                            <StyledField
                                qa={qa('web-address')}
                                title={messages.webAddressFieldText}
                                valueType={FieldsSection.Field.ValueType.String}
                                value={details.webAddress}
                                hideIfEmpty
                            />
                        )}

                        {isFieldVisible(fieldSettings.salutation) && (
                            <StyledField
                                qa={qa('salutation')}
                                title={messages.salutationFieldText}
                                valueType={FieldsSection.Field.ValueType.String}
                                value={details.salutation}
                                hideIfEmpty
                            />
                        )}

                        <StyledField
                            qa={qa('first-name')}
                            title={messages.firstNameFieldText}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={details.firstName}
                            hideIfEmpty
                        />

                        <StyledField
                            qa={qa('middle-name')}
                            title={messages.middleNameFieldText}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={details.middleName}
                            hideIfEmpty
                        />

                        <StyledField
                            qa={qa('last-name')}
                            title={messages.lastNameFieldText}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={details.lastName}
                            hideIfEmpty
                        />

                        {isFieldVisible(fieldSettings.jobTitle) && (
                            <StyledField
                                qa={qa('job-title')}
                                title={messages.jobTitleFieldText}
                                valueType={FieldsSection.Field.ValueType.String}
                                value={details.jobTitle}
                                hideIfEmpty
                            />
                        )}

                        {isFieldVisible(fieldSettings.category) && (
                            <StyledField
                                qa={qa('category')}
                                title={messages.categoryFieldText}
                                valueType={FieldsSection.Field.ValueType.String}
                                value={details.category}
                                hideIfEmpty
                            />
                        )}

                        {isFieldVisible(fieldSettings.comments) && (
                            <StyledField
                                qa={qa('comments')}
                                title={messages.commentsFieldText}
                                valueType={FieldsSection.Field.ValueType.String}
                                value={details.comments}
                                hideIfEmpty
                            />
                        )}

                        <NetSuiteCustomFields
                            customFields={details.customFields}
                            companyCustomFields={companyCustomFields?.Fields ?? []}
                        />
                    </FieldsSectionWrap>
                )}

                <NetSuiteVendorFooterFields request={request} />

                {request.details.url && <NetSuiteRequestFooter request={request} exchangeRate={null} />}

                <AttachmentsSection request={request} />

                <WorkflowSection request={request} />

                <ActivitySection request={request} />

                {!request.flags.status.isDraft && <AddCommentSection request={request} />}
            </StyledScrollableArea>
        </Root>
    );
});

RequestCardVendor.displayName = 'RequestCardVendor';
