import { useMutation } from '@tanstack/react-query';
import { api } from 'services/api';

import { UseEditQBODocumentStatusMutationOptions } from './ChangeStatusPopup.types';

export const useEditQBODocumentStatusMutation = (options: UseEditQBODocumentStatusMutationOptions) => {
    const { transfer, onSuccess, onError } = options;

    const { isLoading, mutateAsync: editDocumentStatus } = useMutation(
        () => api.requests.editQBODocumentStatus(transfer),
        {
            onSuccess,
            onError,
        }
    );

    return {
        isLoading,
        editDocumentStatus,
    };
};
