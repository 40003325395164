// TODO: will be fixed in the api schema https://approvalmax.slack.com/archives/C03PACM9P62/p1724862954675219
export enum OcrFileContentType {
    DocumentPerFile = 'DocumentPerFile',
    MultiDocumentFile = 'MultiDocumentFile',
}

import { RequestType } from '@approvalmax/data';

import { ocrApiPaths } from '../paths';

export type UploadOcrDocument = RequestType<
    typeof ocrApiPaths.uploadDocument,
    {
        method: 'post';
        apiVersion: 'v2';
    }
>;
