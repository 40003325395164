import { selectors } from 'modules/common';
import { domain, schemas } from 'modules/data';
import { normalize } from 'normalizr';
import { SetStateAction, useMemo } from 'react';
import { useGetWorkflowsRaw } from 'shared/data/v1';

import { ActiveWorkflow } from '../../types/activeWorkflow';
import { getAvailableTemplates } from './CopyWorkflowPopup.helpers';
import { isMultiCopySummary, MultipleWorkflowCopyStateType, TabId } from './CopyWorkflowPopup.types';

export const useWorkflows = (
    company: selectors.types.ExpandedCompany | null,
    workflow: selectors.types.ExpandedTemplate
) => {
    const { data, ...restQueryData } = useGetWorkflowsRaw(
        {
            query: {
                companyId: company?.id,
            },
        },
        {
            enabled: Boolean(company),
        }
    );

    const workflows = normalize(data || {}, { Templates: [schemas.templateSchema] }).entities.templates;

    if (!workflows || !company)
        return {
            workflows: [],
            ...restQueryData,
        };

    const expandedWorkflows = Object.values(workflows).map(selectors.template.expandTemplate);

    return {
        workflows: getAvailableTemplates(company, workflow, expandedWorkflows),
        ...restQueryData,
    };
};

export const useHelpStripe = (props: { activeTab: TabId; activeWorkflow: ActiveWorkflow }) => {
    const { activeTab, activeWorkflow } = props;

    const variant = useMemo(() => (activeTab === TabId.SINGLE_COPY ? 'single' : 'multiple'), [activeTab]);

    const integrationType = activeWorkflow?.integrationCode
        ? domain.getIntegrationTypeByCode(activeWorkflow?.integrationCode)
        : 'standalone';

    const helpStripeId = useMemo<string>(() => {
        return `copy-workflow-popup-${variant}-${integrationType.toLowerCase()}`;
    }, [variant, integrationType]);

    return {
        helpStripeId,
    };
};

export const useCopyLogger = (props: {
    activeTab: TabId;
    setIsLoading: (value: SetStateAction<boolean>) => void;
    setShowSuccessfulPopup: (value: SetStateAction<boolean>) => void;
    setMultipleCopyFormState: (value: SetStateAction<MultipleWorkflowCopyStateType>) => void;
}) => {
    const { activeTab, setIsLoading, setShowSuccessfulPopup, setMultipleCopyFormState } = props;

    return useMemo(
        () => ({
            info: (message: string, data?: unknown) => {
                if (message === 'DONE' && activeTab === TabId.SINGLE_COPY) {
                    setIsLoading(false);
                    setShowSuccessfulPopup(true);
                }

                if (message === 'BULK_COPY_DONE' && activeTab === TabId.MULTIPLE_COPY) {
                    setIsLoading(false);

                    if (isMultiCopySummary(data)) {
                        setMultipleCopyFormState((current) => ({
                            ...current,
                            summary: data,
                        }));
                        setShowSuccessfulPopup(true);
                    }
                }
            },
            warn: () => undefined,
            error: () => setIsLoading(false),
        }),
        [activeTab, setIsLoading, setMultipleCopyFormState, setShowSuccessfulPopup]
    );
};
