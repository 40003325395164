import { DataTableColumnDefinition } from '@approvalmax/ui';
import startCase from 'lodash/startCase';
import moment from 'moment';
import { GetNetSuiteCacheStatus } from 'shared/data/v1';

import LastSyncErrorCell from './components/LastSyncErrorCell/LastSyncErrorCell';
import SyncButton from './components/SyncButton/SyncButton';
import { messages } from './NetSuiteCacheManagementPanel.messages';
import { StyledDefaultColumnHeader } from './NetSuiteCacheManagementPanel.styles';

export const tableColumns: DataTableColumnDefinition<
    GetNetSuiteCacheStatus['response']['data']['cacheObjects'][number] & { id: string }
>[] = [
    {
        id: 'field',
        name: messages.fieldCol,
        value: (item) => startCase(item.refDataType),
        columnComponent: StyledDefaultColumnHeader,
    },
    {
        id: 'lastSync',
        name: messages.lastSyncCol,
        value: (item) => (item.lastPullingTime ? moment(item.lastPullingTime).format('lll') : '-'),
        columnComponent: StyledDefaultColumnHeader,
    },
    {
        id: 'lastSyncError',
        name: messages.lastSyncErrorCol,
        cellComponent: LastSyncErrorCell,
        columnComponent: StyledDefaultColumnHeader,
    },
    {
        id: 'action',
        name: '',
        cellComponent: SyncButton,
        columnComponent: StyledDefaultColumnHeader,
    },
];
