import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { TakeOffHold } from './useTakeOffHold.types';

export const useTakeOffHold = (mutationOptions?: RequestMutationOptions<TakeOffHold>) => {
    return useMutate(requestsApiPaths.takeOffHold, {
        mutationOptions,
        apiVersion: 'v2',
    });
};
