import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.NetSuiteRequestCard.RequestCardSalesOrder', {
    referenceField: 'Transaction Number',
    paymentReferenceField: 'Reference',
    customerField: 'Customer',
    transactionDateField: 'Date',
    startDateField: 'Start Date',
    endDateField: 'End Date',
    classField: 'Class',
    departmentField: 'Department',
    location: 'Location',
    memoField: 'Memo',
    discountItem: 'Discount Item',
    discountRate: 'Discount Rate',
    terms: 'Terms',
    billingSchedule: 'Billing Schedule',
    positiveBooleanValue: 'Yes',
});
