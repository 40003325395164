import { QueryOptions } from '@approvalmax/data';
import { useGetLegacy } from 'shared/data';

import { requestsApiPaths } from '../paths';
import {
    UseGetAmaxPayXeroBankAccountsPathParams,
    UseGetAmaxPayXeroBankAccountsResponse,
} from './useGetAmaxPayXeroBankAccounts.types';

export const useGetAmaxPayXeroBankAccounts = (
    pathParams: UseGetAmaxPayXeroBankAccountsPathParams,
    queryOptions?: QueryOptions<UseGetAmaxPayXeroBankAccountsResponse>
) =>
    useGetLegacy<UseGetAmaxPayXeroBankAccountsResponse>(requestsApiPaths.amaxPayXeroBankAccounts, {
        queryOptions,
        pathParams,
        method: 'get',
    });
