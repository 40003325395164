import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.NetSuite.NetSuiteRequestCard.NetSuiteSalesOrderItemLines', {
    sectionHeaderText: 'Items',
    itemColumnName: 'Item',
    quantityCommitted: 'Committed',
    quantityFulfilled: 'Fulfilled',
    quantityBilled: 'Invoiced',
    quantityBackOrdered: 'Back Ordered',
    quantity: 'Quantity',
    units: 'Units',
    description: 'Description',
    serialNumbers: 'Serial/lot numbers',
    priceLevel: 'Price Level',
    unitPrice: 'Rate',
    amount: 'Amount',
    taxCode: 'Tax Code',
    taxRate: 'Tax Rate',
    taxAmount: 'Tax Amount',
    department: 'Department',
    class: 'Class',
    location: 'Location',
    orderPriority: 'Order Priority',
    revenueRecognitionSchedule: 'Revenue Recognition Schedule',
    revenueRecognitionStartDate: 'Revenue Recognition Start Date',
    revenueRecognitionEndDate: 'Revenue Recognition End Date',
    excludeFromRateRequest: 'Exclude From Rate Request',
    excludeFromRateRequestTooltip: 'Exclude From Rate Request',
    closedColumnName: 'Closed',
    closedColumnTooltip: 'This line is closed',
});
