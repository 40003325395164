import { Radio } from '@approvalmax/ui/src/components';
import { memo, useCallback } from 'react';

import { SettingsSection } from '../SettingsSection/SettingsSection';
import SettingsSubsection from '../SettingsSubsection/SettingsSubsection';
import { messages } from './SectionBalanceControlCheck.messages';
import { SectionBalanceControlCheckProps } from './SectionBalanceControlCheck.types';

export const SectionBalanceControlCheck = memo<SectionBalanceControlCheckProps>((props) => {
    const { templateSettings, readonly, onChange, qa } = props;

    const handleChange = useCallback(
        (newValue: string) => {
            onChange(newValue === 'true');
        },
        [onChange]
    );

    return (
        <SettingsSection title={messages.title}>
            <SettingsSubsection subtitle={messages.subtitle}>
                <Radio.Group
                    disabled={readonly}
                    value={Boolean(templateSettings?.isBalanceControlCheckEnabled).toString()}
                    onChange={handleChange}
                    name='isBalanceControlCheckEnabled'
                    block
                    data-qa={qa('balance-control-settings-section')}
                >
                    <Radio value='false'>{messages.allow}</Radio>

                    <Radio value='true'>{messages.disallow}</Radio>
                </Radio.Group>
            </SettingsSubsection>
        </SettingsSection>
    );
});

SectionBalanceControlCheck.displayName = 'SectionBalanceControlCheck';
