import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('profile.TryPayOrCapturePopupContent', {
    payTitle: 'The smarter, faster, safer way to pay your bills.',
    captureTitle: 'Automate tedious tasks.',
    payListItem1: 'Make payments directly in ApprovalMax',
    payListItem2: 'Track invoices and payments in real-time',
    payListItem3: 'Route payments accurately every time',
    payListItem4: 'Automate reconciliation tasks',
    payListItem5: 'Connect to your bank securely using open banking',
    captureListItem1: 'Upload single or bulk bills from PDFs or images',
    captureListItem2: 'Extract unlimited line items at no extra cost',
    captureListItem3: 'Email documents directly into ApprovalMax via a dedicated inbox',
    captureListItem4: 'Supports 40+ languages',
    captureListItem5: 'Track approvers, timestamps, decisions, and sync with your ledger for audit readiness',
    chooseOrganisation: 'Choose Organisation',
    tryNowPayButtonText: 'Try now 10 payments for free',
    tryNowCaptureButtonText: 'Try now 100 documents for free for 14 days',
    notifyAdminsButtonText: 'Notify admins about free all-feature trial',
    contactSupportButtonText: 'Contact Support to reset All Features Trial',
    learnMorePay: 'Learn more about ApprovalMax Pay',
    learnMoreCapture: 'Learn more about ApprovalMax Capture',
    videoTitle: 'YouTube video player',
    redirectWorkflowPageMessage:
        'Add yourself to requesters in the "Create in ApprovalMax" workflow step and update the workflow',
    redirectNewRequestPageMessage: 'Upload the bill file to ApprovalMax Capture and check the recognized bill',
});
