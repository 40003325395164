import { useMemo } from 'react';

import { useLineCustomFieldsDefinition } from '../../NetSuiteRequestCard.hooks';
import { getColumnDefinitions } from './NetSuiteApplyList.config';
import { ApplyListTableDefinitionOptions } from './NetSuiteApplyList.types';

export const useTableDefinitions = ({
    companyCustomFields,
    fieldSettings,
    exchangeRate,
    activeAttachmentId,
    onSelectBill,
    onSelectAttachment,
}: ApplyListTableDefinitionOptions) => {
    const customFieldsColumnDefinition = useLineCustomFieldsDefinition([], companyCustomFields);

    const resultColumnDefinition = useMemo(
        () => [
            ...getColumnDefinitions({
                fieldSettings,
                exchangeRate,
                activeAttachmentId,
                onSelectBill,
                onSelectAttachment,
            }),
            ...customFieldsColumnDefinition,
        ],
        [
            customFieldsColumnDefinition,
            activeAttachmentId,
            fieldSettings,
            exchangeRate,
            onSelectBill,
            onSelectAttachment,
        ]
    );

    return resultColumnDefinition;
};
