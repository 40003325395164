import { MutateDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { twoFaApiPaths } from '../paths';
import {
    UseVerifyTFADisablingBackupCodeRequest,
    UseVerifyTFADisablingBackupCodeResponse,
} from './useVerifyTFADisablingBackupCode.types';

export const useVerifyTFADisablingBackupCode = () => {
    return useMutateLegacy<
        MutateDataParams<UseVerifyTFADisablingBackupCodeRequest>,
        UseVerifyTFADisablingBackupCodeResponse
    >(twoFaApiPaths.disableTFAWithBackupCode);
};
