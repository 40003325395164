import { OracleNetsuiteLogo } from 'modules/sprites';
import { FC, memo } from 'react';

import { messages } from './NetSuiteLink.messages';
import { Root, Text } from './NetSuiteLink.styles';
import { NetSuiteLinkProps } from './NetSuiteLink.types';

const NetSuiteLink: FC<NetSuiteLinkProps> = memo((props) => {
    const { url, qa } = props;

    return (
        <Root target='_blank' rel='noopener noreferrer' href={url} data-qa={qa}>
            <OracleNetsuiteLogo width={30} height={30} />

            <Text>{messages.openInNetSuiteLinkText}</Text>
        </Root>
    );
});

export default NetSuiteLink;
