import { AmCircleLogo } from '@approvalmax/ui';
import { Button, Flex, Popup, Text } from '@approvalmax/ui/src/components';
import { FC, memo, useMemo } from 'react';
import { AccountPath, getAccountUrl } from 'urlBuilder';

import { messages } from './PopupContent.messages';
import { PopupContentProps } from './PopupContent.types';

export const PopupContent: FC<PopupContentProps> = memo((props) => {
    const { companyId, description, onClose } = props;

    const buyLink = useMemo(
        () => getAccountUrl(AccountPath.buyNow, { companyId, origin: 'wa_small_trial_exp_popup' }),
        [companyId]
    );

    return (
        <>
            <Popup.Header />

            <Popup.Body>
                <Flex direction='column' alignItems='center' spacing='24'>
                    <AmCircleLogo width={100} height={100} />

                    <Text font='headline' fontSize='small' fontWeight='medium' textAlign='center' color='midnight80'>
                        {description}
                    </Text>

                    <Button as='a' target='_blank' href={buyLink} color='blue80' size='large' onClick={onClose}>
                        {messages.subscribeNow}
                    </Button>
                </Flex>
            </Popup.Body>
        </>
    );
});

PopupContent.displayName = 'PopupContent';
