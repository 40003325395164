import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { GetNetSuiteRequestsDuplicates } from './useGetNetSuiteRequestsDuplicates.types';

export const useGetNetSuiteRequestsDuplicates = (
    params?: RequestParams<GetNetSuiteRequestsDuplicates>,
    queryOptions?: RequestQueryOptions<GetNetSuiteRequestsDuplicates>
) => {
    return useGet(requestsApiPaths.netSuiteRequestsDuplicates, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetNetSuiteRequestsDuplicates['params']['query']>(
                {
                    integrationCode: params?.query?.integrationCode,
                    date: params?.query?.date,
                    currency: params?.query?.currency,
                    amount: params?.query?.amount,
                    vendorId: params?.query?.vendorId,
                },
                queryOptions
            ),
        },
        apiVersion: 'v2',
    });
};
