import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { companiesApiPaths } from '../paths';
import { CreateTrialRequest } from './useCreateTrialRequest.types';

export const useCreateTrialRequest = (mutationOptions?: RequestMutationOptions<CreateTrialRequest>) => {
    return useMutate(companiesApiPaths.trialRequest, {
        mutationOptions,
        apiVersion: 'v2',
    });
};
