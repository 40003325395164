import './numberRangeFilter.scss';

import { NumberEditor } from '@approvalmax/ui';
import React from 'react';
import bemFactory from 'react-bem-factory';
import { FormattedMessage } from 'react-intl';

import * as numberRangeFilter from '../../data/filters/numberRangeFilter';

const i18nPrefix = 'reports.filters.NumberRangeFilter';

interface Props {
    filter: numberRangeFilter.NumberRangeFilter;
    onFilterChange(filter: numberRangeFilter.NumberRangeFilter): void;
}

class NumberRangeFilter extends React.Component<Props> {
    public render() {
        const { filter } = this.props;
        const bem = bemFactory.block('rpt-number-range-filter');

        return (
            <div className={bem()}>
                <div className={bem('above-text')}>
                    <FormattedMessage id={`${i18nPrefix}.aboveText`} defaultMessage='Above' />
                </div>

                <NumberEditor
                    allowFloat
                    precision={2}
                    className={bem('above-editor')}
                    value={filter.greaterOrEqual}
                    onChange={this._onGreaterOrEqualChange}
                    onBlur={this._onGreaterOrEqualBlur}
                />

                <div className={bem('below-text')}>
                    <FormattedMessage id={`${i18nPrefix}.belowText`} defaultMessage='Below' />
                </div>

                <NumberEditor
                    allowFloat
                    precision={2}
                    className={bem('below-editor')}
                    value={filter.lessOrEqual}
                    onChange={this._onLessOrEqualChange}
                    onBlur={this._onLessOrEqualBlur}
                />
            </div>
        );
    }

    private _onGreaterOrEqualChange = (value: number | null) => {
        this.props.onFilterChange({
            ...this.props.filter,
            greaterOrEqual: value,
        });
    };

    private _onLessOrEqualChange = (value: number | null) => {
        this.props.onFilterChange({
            ...this.props.filter,
            lessOrEqual: value,
        });
    };

    private _onGreaterOrEqualBlur = () => {
        let lessOrEqual = this.props.filter.lessOrEqual;
        let greaterOrEqual = this.props.filter.greaterOrEqual;

        if (greaterOrEqual && lessOrEqual && greaterOrEqual > lessOrEqual) {
            this.props.onFilterChange({
                ...this.props.filter,
                lessOrEqual: greaterOrEqual,
            });
        }
    };

    private _onLessOrEqualBlur = () => {
        let lessOrEqual = this.props.filter.lessOrEqual;
        let greaterOrEqual = this.props.filter.greaterOrEqual;

        if (greaterOrEqual && lessOrEqual && lessOrEqual < greaterOrEqual) {
            this.props.onFilterChange({
                ...this.props.filter,
                greaterOrEqual: lessOrEqual,
            });
        }
    };
}

export default NumberRangeFilter;
