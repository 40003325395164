import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { integrationApiPaths } from '../paths';
import { GetNetSuiteApiFilter } from './useGetNetSuiteApiFilter.types';

export const useGetNetSuiteApiFilter = (
    params?: RequestParams<GetNetSuiteApiFilter>,
    queryOptions?: RequestQueryOptions<GetNetSuiteApiFilter>
) => {
    return useGet(integrationApiPaths.getNetSuiteApiFilter, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetNetSuiteApiFilter['params']['query']>(
                {
                    companyId: params?.query?.companyId,
                },
                queryOptions
            ),
        },
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};
