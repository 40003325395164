import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.xeroRequestSelectors', {
    itemColumnName: 'Item',
    descriptionColumnName: 'Description',
    qtyColumnName: 'Qty',
    unitPriceColumnName: 'Unit Price',
    discountColumnName: 'Disc %',
    discountInvoiceColumnName: 'Disc (%)',
    accountColumnName: 'Account',
    taxRateColumnName: 'Tax',
    amountColumnName: 'Amount {currency}',
});
