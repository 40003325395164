import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { integrationApiPaths } from '../paths';
import { CreateNetSuiteVendor } from './useCreateNetSuiteVendor.types';

export const useCreateNetSuiteVendor = (mutationOptions?: RequestMutationOptions<CreateNetSuiteVendor>) => {
    return useMutate(integrationApiPaths.createNetSuiteVendor, {
        mutationOptions,
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};
