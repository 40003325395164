import { mathService } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { backend, domain } from 'modules/data';
import moment from 'moment';

import { getDisplayName } from '../../../selectors/userSelectors';
import {
    AllocationData,
    MatchedBillTableItem,
    MatchingData,
    POMatchingData,
    POTableItem,
    PurchaseOrder,
} from './types';

export const getMatchingData = (data: backend.XeroMatchingContext): MatchingData => {
    const purchaseOrders: PurchaseOrder[] = [];
    const totalAmount = data.totalAmount || 0;

    let totalAllocatedAmountToBilledPOs = 0;
    let totalAllocatedAmount = 0;

    data.documents.forEach((document) => {
        if (document.isBilled) {
            totalAllocatedAmountToBilledPOs = mathService.add(
                totalAllocatedAmountToBilledPOs,
                document.allocatedAmount || 0
            );
        } else {
            totalAllocatedAmount = mathService.add(totalAllocatedAmount, document.allocatedAmount || 0);
        }

        const po: PurchaseOrder = {
            totalAmount: document.totalAmount,
            allocatedAmount: document.allocatedAmount || 0,
            grnStatus: document.grnStatus,
            isBilled: !!document.isBilled,
            isMatched: document.isMatched,
            id: document.requestId,
            name: document.friendlyName,
            currency: document.currency,
            date: moment.utc(document.date).format('ll'),
            requester: {
                id: document.requester?.UserId || '',
                firstName: document.requester?.FirstName || '',
                lastName: document.requester?.LastName || '',
                userEmail: document.requester?.UserEmail || '',
                avatar: document.requester?.AvatarHttps || '',
                displayName: getDisplayName(document.requester),
                abbreviation: document.requester?.UserName || '',
            },
            reference: document.reference,
            warning: false,
        };

        purchaseOrders.push(po);
    });

    const remaining = mathService.subtract(
        totalAmount,
        mathService.add(totalAllocatedAmount, totalAllocatedAmountToBilledPOs)
    );

    return {
        totalAllocatedAmountToBilledPOs,
        totalAmount,
        currency: data.currency,
        purchaseOrders,
        remaining,
    };
};

export const getMatchingTransfer = (purchaseOrders: PurchaseOrder[]): domain.AllocationTransfer[] => {
    return purchaseOrders.map(({ id, allocatedAmount }) => ({
        purchaseOrderId: id,
        amount: allocatedAmount,
    }));
};

export const getAllocationData = (
    allocations: backend.XeroPOMatchingInfoDocument[],
    billId: string
): AllocationData => {
    const tableData: MatchedBillTableItem[] = [];

    let allocatedAmountToBilledPOs = 0;
    let allocatedAmountToNotBilledPOs = 0;

    allocations.forEach((alloc) => {
        if (alloc.requestId === billId) return;

        const tableItem = {
            name: alloc.friendlyName,
            currency: alloc.currency,
            amount: alloc.allocatedAmount,
        };

        tableData.push(tableItem);

        if (alloc.resolution === 'Approved') {
            // TODO: check statusV2
            allocatedAmountToBilledPOs = mathService.add(allocatedAmountToBilledPOs, alloc.allocatedAmount);
        } else {
            allocatedAmountToNotBilledPOs = mathService.add(allocatedAmountToNotBilledPOs, alloc.allocatedAmount);
        }
    });

    return {
        tableData,
        allocatedAmountToBilledPOs,
        allocatedAmountToNotBilledPOs,
    };
};

export const getPOMatchingData = (data: backend.XeroPOMatchingInfo): POMatchingData => {
    const { documents, currency, totalAmount: purchaseOrderTotalAmount } = data;

    let totalAmount = 0;
    let totalAllocatedAmountToOtherBills = 0;
    let totalAllocatedAmountToApprovedBills = 0;

    const tableData: POTableItem[] = [];

    documents.forEach((alloc) => {
        totalAmount = mathService.add(totalAmount, alloc.totalAmount);

        if (alloc.statusV2 === domain.RequestStatusV2.Approved) {
            totalAllocatedAmountToApprovedBills = mathService.add(
                totalAllocatedAmountToApprovedBills,
                alloc.allocatedAmount
            );
        } else {
            totalAllocatedAmountToOtherBills = mathService.add(totalAllocatedAmountToOtherBills, alloc.allocatedAmount);
        }

        tableData.push({
            id: alloc.requestId,
            name: alloc.friendlyName,
            matchedBy: getDisplayName(alloc.matchedBy),
            allocatedAmount: alloc.allocatedAmount,
            totalAmount: alloc.totalAmount,
            currency: alloc.currency,
            approved:
                alloc.resolution?.toLowerCase() === 'approved' ||
                alloc.statusV2?.toLowerCase() === domain.RequestStatusV2.Approved,
        });
    });

    const remaining = mathService.subtract(
        purchaseOrderTotalAmount,
        mathService.add(totalAllocatedAmountToApprovedBills, totalAllocatedAmountToOtherBills)
    );

    return {
        tableData,
        totalAmount,
        totalAllocatedAmountToOtherBills,
        totalAllocatedAmountToApprovedBills,
        currency,
        purchaseOrderTotalAmount,
        remaining,
    };
};

export const getShowManualMatching = (request: selectors.types.ExpandedRequest) => {
    const isPo = request.integrationCode === domain.IntegrationCode.XeroPo;
    const isBill = request.integrationCode === domain.IntegrationCode.XeroBill;
    const requestSupportsMatching = !(
        !(isPo || isBill) ||
        request.statusV2 === domain.RequestStatusV2.Cancelled ||
        (isPo && request.statusV2 !== domain.RequestStatusV2.Approved)
    );

    const manualMatchingEnabled = request.company.billMatchingSettings?.manualMatchingEnabled ?? false;
    const showManualMatching = requestSupportsMatching && manualMatchingEnabled;

    return showManualMatching;
};
