import { TextButton } from '@approvalmax/ui';
import { WarnIcon } from 'modules/sprites';
import { font } from 'modules/styles';
import styled from 'styled-components';

export const ImportantNotice = styled.div`
    display: flex;
    align-items: center;
    padding: 19px 60px;
    background: #f7f7f7;
`;

export const ImportantNoticeWarnIcon = styled(WarnIcon)`
    margin-right: 15px;
`;

export const ImportantNoticeText = styled.div`
    ${font(13, '#737373')}
    flex: 1;
`;

export const EditingRequiredNoticeButton = styled(TextButton)`
    margin-left: 10px;
`;

export const Root = styled.div`
    margin-top: 15px;
`;
