import { Box, Grid, Popup, Text } from '@approvalmax/ui/src/components';
import range from 'lodash/range';
import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';

import { BankAccountInfoLoading } from '../BankAccountInfo/BankAccountInfo.loading';
import { containerWidth } from './PaymentSummaryPopupContent.constants';
import { messages } from './PaymentSummaryPopupContent.messages';
import { PaymentSummaryPopupContentLoadingProps } from './PaymentSummaryPopupContent.types';

export const PaymentSummaryPopupContentLoading = memo<PaymentSummaryPopupContentLoadingProps>((props) => {
    const { requestDisplayName } = props;

    return (
        <>
            <Popup.Header title={requestDisplayName} />

            <Popup.Body>
                <Grid gridTemplateColumns={`auto ${containerWidth}px`} columnGap={24} overflowY='hidden'>
                    <Grid gap={12}>
                        {range(0, 5).map((index) => (
                            <Skeleton key={index} height={16} />
                        ))}
                    </Grid>

                    <Grid gap={12}>
                        <Text color='midnight100' font='body' fontSize='small' fontWeight='medium'>
                            {messages.paymentSummary}
                        </Text>

                        <Box color='midnight20' spacing='12' radius='xsmall'>
                            <BankAccountInfoLoading />
                        </Box>
                    </Grid>
                </Grid>
            </Popup.Body>
        </>
    );
});

PaymentSummaryPopupContentLoading.displayName = 'PaymentSummaryPopupContentLoading';
