import { domain } from 'modules/data';

export enum MatrixType {
    Approval = 'Approval',
    Editor = 'Editor',
    Requester = 'Requester',
    AutoApproval = 'AutoApproval',
    Reviewer = 'Reviewer',
    Editing = 'Editing',
}

export enum AccessType {
    Readonly = 'Readonly',
    Mandatory = 'Mandatory',
    Optional = 'Optional',
}

export interface GetMatrixDefinitionOptions {
    betaFeatures?: domain.CompanyBetaFeature[];
    licenseFeatures?: domain.CompanyLicenseFeature[];
    integrationCode: domain.IntegrationCode | null;
    matrixType: MatrixType;
}
