import { StatusBadge, StatusBadgeSize, useResolutionBadge } from 'modules/components';
import { FC, memo } from 'react';
import bemFactory from 'react-bem-factory';

import MyFinalDecisionBadge from '../MyFinalDecisionBadge/MyFinalDecisionBadge';
import { ResolutionBadgeProps } from './ResolutionBadge.types';

const qa = bemFactory.qa('reql-toolbar-resolution-badget');

const ResolutionBadge: FC<ResolutionBadgeProps> = (props) => {
    const { request } = props;

    const badge = useResolutionBadge(request);

    return badge && badge.label && badge.mode ? (
        <StatusBadge
            mode={badge.mode}
            label={badge.label}
            hasIcon={badge.hasIcon}
            tooltip={badge.tooltip}
            size={StatusBadgeSize.Large}
            qa={qa('resolution-badge')}
        />
    ) : (
        <MyFinalDecisionBadge request={request} />
    );
};

export default memo(ResolutionBadge);
