import { Popup } from '@approvalmax/ui';
import { mentionHelpers } from '@approvalmax/ui/src/components';
import { domain } from 'modules/data';
import { GlobalLoadingBar } from 'modules/page';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import bemFactory from 'react-bem-factory';
import { useDispatch } from 'react-redux';
import { useSetRecoilState } from 'recoil';
import { notificationService } from 'services/notification';
import { useCompanyMentionItems } from 'shared/hooks/useCompanyMentionItems';

import { reloadRequest } from '../../../../../../../actions';
import { addAsWatcherPopupState } from '../../../../../../../components/AddAsWatchersPopup/AddAsWatchersPopup.states';
import { getMentionedUsersWithoutAccessToRequest } from '../../../../../../../utils/getUsersWithoutAccessToRequest';
import { useEditQBODocumentStatusMutation } from './ChangeStatusPopup.hooks';
import { messages } from './ChangeStatusPopup.messages';
import { CommentEditor, Content, Description, Root } from './ChangeStatusPopup.styles';
import { ChangeStatusPopupProps } from './ChangeStatusPopup.types';

const qa = bemFactory.qa('change-po-status-popup');

const ChangeStatusPopup: FC<ChangeStatusPopupProps> = (props) => {
    const { request, onClose } = props;

    const dispatch = useDispatch();
    const setWatchersPopup = useSetRecoilState(addAsWatcherPopupState);

    const [commentText, setCommentText] = useState('');

    const { handleGetMentionItems } = useCompanyMentionItems(request.company);
    const { mentionedUserIds } = useMemo(() => mentionHelpers.prepareCommentPayload(commentText), [commentText]);

    const poStatus = request.details.status || domain.QBooksPurchaseOrderStatus.Open;
    const nextPOStatus =
        poStatus === domain.QBooksPurchaseOrderStatus.Open
            ? domain.QBooksPurchaseOrderStatus.Closed
            : domain.QBooksPurchaseOrderStatus.Open;

    const { isLoading, editDocumentStatus } = useEditQBODocumentStatusMutation({
        transfer: {
            requestId: request.id,
            commentText,
            documentStatus: nextPOStatus,
            companyId: request.companyId,
            mentionedUserIds,
        },
        onSuccess: () => {
            dispatch(reloadRequest(request.id, request.companyId));

            notificationService.showInfoToast(messages.successToastText({ status: nextPOStatus }));

            onClose();
        },
        onError: () => {
            notificationService.showInfoToast(messages.errorToastText({ status: nextPOStatus }));
        },
    });

    const handleSubmit = useCallback(() => {
        const usersWithoutAccess = getMentionedUsersWithoutAccessToRequest(request, mentionedUserIds);

        if (usersWithoutAccess.length) {
            setWatchersPopup((state) => ({ ...state, usersWithoutAccess }));
        } else {
            editDocumentStatus();
        }
    }, [request, mentionedUserIds, setWatchersPopup, editDocumentStatus]);

    useEffect(() => {
        setWatchersPopup((state) => ({ ...state, onSubmit: editDocumentStatus }));
    }, [editDocumentStatus, setWatchersPopup]);

    return (
        <Root
            qa={qa()}
            title={messages.popupTitle({ status: nextPOStatus })}
            buttons={
                <Popup.DefaultContent.Button qa={qa('submit-button')} disabled={isLoading} execute={handleSubmit}>
                    {messages.buttonText({ status: nextPOStatus })}
                </Popup.DefaultContent.Button>
            }
        >
            <Content>
                <GlobalLoadingBar isLoading={isLoading} />

                <Description>{messages.commentDescription({ status: nextPOStatus })}</Description>

                <CommentEditor
                    focusOnMount
                    qa={qa('comment-editor')}
                    value={commentText}
                    onChange={setCommentText}
                    allowMention
                    mentionItems={handleGetMentionItems}
                />
            </Content>
        </Root>
    );
};

export default memo(ChangeStatusPopup);
