import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { companiesApiPaths } from '../paths';
import { GetAllParticipantWorkflowVersions } from './useGetAllParticipantWorkflowVersions.types';

export const useGetAllParticipantWorkflowVersions = (
    params?: RequestParams<GetAllParticipantWorkflowVersions>,
    queryOptions?: RequestQueryOptions<GetAllParticipantWorkflowVersions>
) => {
    return useGet(companiesApiPaths.getAllParticipantWorkflowVersions, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetAllParticipantWorkflowVersions['params']['query']>(
                {
                    userId: params?.query?.userId,
                    companyId: params?.query?.companyId,
                },
                queryOptions
            ),
        },
        apiVersion: 'v1',
    });
};
