import { LazyGetDataParams, RequestQueryOptions } from '@approvalmax/data';
import { PascalCaseToCamelCase } from '@approvalmax/types';
import { useGetLazy } from 'shared/data';

import { integrationApiPaths } from '../paths';
import { GetLazyNetSuiteVendor } from './useGetLazyNetSuiteVendor.types';

export const useGetLazyNetSuiteVendor = (queryOptions?: RequestQueryOptions<GetLazyNetSuiteVendor<true>>) => {
    return useGetLazy<
        LazyGetDataParams<{}, GetLazyNetSuiteVendor['params']['query']>,
        PascalCaseToCamelCase<GetLazyNetSuiteVendor['response']>
    >(integrationApiPaths.getNetSuiteVendor, {
        queryOptions,
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};
