import { mixins, mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled, { css } from 'styled-components';

import { Box } from '../Box/Box';
import { LabelProps } from './Label.types';

export const Sub = styled.sub`
    vertical-align: top;
    color: ${theme.color.red100};
`;

export const Root = styled(Box)<
    StyledMods<Pick<LabelProps, 'color' | 'size' | 'wrap' | 'spacing' | 'font' | 'fontWeight'>>
>`
    ${mods.color(mixins.color)};

    ${mods.size(
        (value, props) => css`
            &,
            ${Sub} {
                ${mixins.font(props.$font || 'label', value, props.$fontWeight)};
            }
        `
    )};

    ${mods.spacing(
        (value) => css`
            display: inline-block;
            margin: ${value};
        `
    )};

    ${mods.wrap.false`
        white-space: nowrap;
    `};
`;
