import { Guid, ValueOf } from '@approvalmax/types';
import { arrayHelpers, errorHelpers, intl, mathService, numberHelpers, typeGuardHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import { DiscountType } from 'modules/data/backend';
import { defineMessages } from 'react-intl';
import { createSelector } from 'reselect';

import { checkFieldValueWithRules } from '../../data/Request';
import { XeroContext } from '../../data/xero/XeroContext';
import * as xeroLineItem from '../../data/xero/XeroLineItem';
import { Context } from '../../reducers/page/contextReducer';
import { getContext, getPage } from '../pageSelectors';
import {
    getFieldsApprovalPermissionBySystemPurpose,
    getRequestEditMode,
    getRequiredFields,
    RequestEditMode,
} from '../requestSelectors';
import { ExpandedXeroLineItem } from '../types/ExpandedXeroLineItem';
import { getXeroContext } from './xeroSelectors';

const i18nPrefix = 'requestForm/selectors/xero/lineItemSelectors';
const messages = defineMessages({
    invalidAmountText: {
        id: `${i18nPrefix}.invalidAmountText`,
        defaultMessage: 'Amount {amount} exceeds maximum Amount: 999,999,999,999,999.99',
    },
    splitLineItemsAmountMismatch: {
        id: `${i18nPrefix}.splitLineItemsAmountMismatch`,
        defaultMessage:
            '*Total Amount of any split or changed line items (now {received}) ' +
            'must remain the original Total Amount (was {expected}). Please correct ' +
            'line items: {affected}.',
    },
    noTaxIsInvalidText: {
        id: `${i18nPrefix}.noTaxIsInvalidText`,
        defaultMessage: 'You cannot select "No Tax" amounts for this request.',
    },
    invalidAccountText: {
        id: `${i18nPrefix}.invalidAccountText`,
        defaultMessage: 'This account is not allowed according to the rules. Please select a different account.',
    },
    invalidTrackingText: {
        id: `${i18nPrefix}.invalidTrackingText`,
        defaultMessage: 'This {category} is not allowed according to the rules. Please select a different {category}.',
    },
    invalidAccountTaxMatchText: {
        id: `${i18nPrefix}.invalidAccountTaxMatchText`,
        defaultMessage: 'The tax "{tax}" cannot be used with account "{account}".',
    },
});

/**
 * Checks whether the given line item is empty-like. Empty like line items have no financial info.
 */
export function isEmptyLikeXeroLineItem(li: domain.XeroLineItem, lineAmountType: ValueOf<domain.LineAmountType>) {
    return (
        !li.qty &&
        !li.discount &&
        !li.unitPrice &&
        (!li.tax || lineAmountType === domain.LineAmountType.NoTax) &&
        !li.item &&
        !li.account &&
        (li.tracking.length === 0 || li.tracking.every((v) => !v))
    );
}

function isInvalidAmount(amount: number | undefined) {
    if (amount == null) {
        return undefined;
    }

    const invalidAmount = amount > 999999999999.99;

    let invalidAmountText;

    if (invalidAmount) {
        invalidAmountText = intl.formatMessage(messages.invalidAmountText, {
            amount: intl.formatNumber(amount, 2),
        });
    }

    return invalidAmountText;
}

function isInvalidAccount(li: domain.XeroLineItem, rules: domain.MatrixCondition[]) {
    if (!li.account) {
        return undefined;
    }

    const isValid = checkFieldValueWithRules(rules, domain.FieldSystemPurpose.XeroAccount, li.account.id);

    if (!isValid) {
        return intl.formatMessage(messages.invalidAccountText);
    }

    return undefined;
}

function isInvalidAccountTaxMatch(li: domain.XeroLineItem) {
    if (!li.account || !li.tax) {
        return undefined;
    }

    const account = li.account as domain.XeroAccount;

    if (!account.accountClass) {
        return undefined;
    }

    if (!li.tax.applicableToAccountClasses.includes(account.accountClass)) {
        return intl.formatMessage(messages.invalidAccountTaxMatchText, {
            tax: li.tax.text,
            account: account.text,
        });
    }

    return undefined;
}

function getInvalidTrackingCategories(li: domain.XeroLineItem, rules: domain.MatrixCondition[]) {
    let invalidCategories: Array<{
        categoryId: Guid;
        errorText: string;
    }> = [];

    li.tracking.forEach((t) => {
        const isValid = checkFieldValueWithRules(rules, t.category.id, t.value.id);

        if (!isValid) {
            invalidCategories.push({
                categoryId: t.category.id,
                errorText: intl.formatMessage(messages.invalidTrackingText, {
                    category: t.category.text,
                }),
            });
        }
    });

    return invalidCategories;
}

function isInvalidLineAmountType(
    li: domain.XeroLineItem,
    lineAmountType: ValueOf<domain.LineAmountType>,
    xeroContext: XeroContext
) {
    if (lineAmountType === domain.LineAmountType.NoTax && xeroContext.noTaxIsInvalid) {
        return intl.formatMessage(messages.noTaxIsInvalidText);
    }

    return undefined;
}

function isValidXeroLineItem(
    li: domain.XeroLineItem,
    computedAmount: number | undefined,
    requiredFields: ReturnType<typeof getRequiredFields>,
    context: Context,
    xeroContext: XeroContext,
    lineAmountType: ValueOf<domain.LineAmountType>,
    editMode: RequestEditMode,
    computedTaxAmount: number | undefined
) {
    const hasRequiredTrackings = xeroContext.trackingCategories
        .filter((tc) => requiredFields.fieldIds.includes(tc.category.id))
        .every((tc) => li.tracking.some((t) => t.category.id === tc.category.id));

    const itemField = context.fields.find((f) => f.systemPurpose === domain.FieldSystemPurpose.XeroItem);
    const itemFieldRequired = itemField && requiredFields.fieldIds.includes(itemField.id);
    const accountField = context.fields.find((f) => f.systemPurpose === domain.FieldSystemPurpose.XeroAccount);
    const isApproverMode = editMode === RequestEditMode.Approver;
    const accountFieldRequired =
        isApproverMode || (!!computedAmount && accountField && requiredFields.fieldIds.includes(accountField.id));
    const taxField = context.fields.find((f) => f.systemPurpose === domain.FieldSystemPurpose.XeroTax);
    const taxFieldRequired =
        (isApproverMode || !!computedAmount) && taxField && requiredFields.fieldIds.includes(taxField.id);
    const taxAmountField = context.fields.find((f) => f.systemPurpose === domain.FieldSystemPurpose.XeroLineTaxAmount);
    const taxAmountFieldRequired =
        (isApproverMode || !!computedAmount) && taxAmountField && requiredFields.fieldIds.includes(taxAmountField.id);
    const discountField = context.fields.find((f) => f.systemPurpose === domain.FieldSystemPurpose.XeroLineDiscount);
    const discountFieldRequired = discountField && requiredFields.fieldIds.includes(discountField.id);

    const isCorrectlyFilled =
        Boolean(
            (!itemFieldRequired || li.item) &&
                li.description &&
                li.qty != null &&
                li.unitPrice != null &&
                (!accountFieldRequired || li.account) &&
                (!taxFieldRequired || li.tax || lineAmountType === domain.LineAmountType.NoTax) &&
                (taxAmountFieldRequired ? li.taxAmount || computedTaxAmount : true) &&
                (discountFieldRequired
                    ? numberHelpers.isNumber(li.discount) || numberHelpers.isNumber(li.discountAmount)
                    : true)
        ) && hasRequiredTrackings;

    const emptyLike = isEmptyLikeXeroLineItem(li, lineAmountType);

    return emptyLike || isCorrectlyFilled;
}

const toFloatPr = (v: number, pr = 2) => {
    const prVal = pr && pr === 4 ? 10000 : 100;

    return v ? mathService.round(v * prVal) / prVal : v;
};

export function getComputedLineAmount(lineItem: domain.XeroLineItem) {
    const qty = lineItem.qty;
    const unitPrice = lineItem.unitPrice;
    const discount = lineItem.discount;
    const discountAmount = lineItem.discountAmount;
    const discountType = lineItem.discountType;

    if (unitPrice == null || qty == null) {
        return undefined;
    }

    let effectiveAmount: number;

    switch (discountType) {
        case DiscountType.Amount:
        case DiscountType.PreAmount:
            effectiveAmount = discountAmount
                ? mathService.subtract(mathService.multiply(unitPrice, qty), discountAmount)
                : mathService.multiply(unitPrice, qty);
            break;

        default:
            effectiveAmount = discount
                ? mathService.divide(
                      mathService.multiply(mathService.multiply(unitPrice, qty), mathService.subtract(100, discount)),
                      100
                  )
                : mathService.multiply(unitPrice, qty);
    }

    const isNegativeUnitPrice = Number(unitPrice) < 0;

    const absLineAmount = mathService.round(Math.abs(effectiveAmount));

    return isNegativeUnitPrice ? -absLineAmount : absLineAmount;
}

export function getComputedLineTaxAmount(
    lineItem: domain.XeroLineItem,
    lineAmountType: ValueOf<domain.LineAmountType>,
    amount: number | undefined
) {
    if (amount == null || !lineItem.tax) {
        return undefined;
    }

    const taxRate = lineItem.tax.rateEffective;

    let taxAmount;

    switch (lineAmountType) {
        case domain.LineAmountType.TaxExclusive:
            taxAmount = mathService.round(mathService.divide(mathService.multiply(amount, taxRate), 100), 2);
            // taxAmount = (amount * taxRate) / 100;
            break;

        case domain.LineAmountType.TaxInclusive:
            taxAmount = mathService.round(
                mathService.divide(mathService.multiply(amount, taxRate), mathService.add(100, taxRate)),
                2
            );
            // taxAmount = (amount * taxRate) / (100 + taxRate);
            break;

        case domain.LineAmountType.NoTax:
            taxAmount = 0;
            break;

        default:
            throw errorHelpers.assertNever(lineAmountType);
    }

    return toFloatPr(taxAmount);
}

function getComputedLineTotalAmount(
    amount: number | undefined,
    taxAmount: number | undefined,
    lineAmountType: ValueOf<domain.LineAmountType>
) {
    switch (lineAmountType) {
        case domain.LineAmountType.TaxExclusive:
            if (amount == null || taxAmount == null) {
                return undefined;
            }

            return toFloatPr(amount + taxAmount);

        case domain.LineAmountType.TaxInclusive:
        case domain.LineAmountType.NoTax:
            return amount;

        default:
            throw errorHelpers.assertNever(lineAmountType);
    }
}

const getApproverCanCloneLineItem = (state: State, request: domain.Request) => {
    const editMode = getRequestEditMode(state, request);
    const [
        descriptionFieldPermission,
        unitPriceFieldPermission,
        qtyFieldPermission,
        accountFieldPermission,
        taxFieldPermission,
    ] = getFieldsApprovalPermissionBySystemPurpose(state, request, [
        domain.FieldSystemPurpose.XeroLineDescription,
        domain.FieldSystemPurpose.XeroLineUnitPrice,
        domain.FieldSystemPurpose.XeroLineQty,
        domain.FieldSystemPurpose.XeroAccount,
        domain.FieldSystemPurpose.XeroTax,
    ]);

    return (
        editMode === RequestEditMode.Approver &&
        !descriptionFieldPermission.disabled &&
        !unitPriceFieldPermission.disabled &&
        !qtyFieldPermission.disabled &&
        !accountFieldPermission.disabled &&
        !taxFieldPermission.disabled
    );
};

const expandXeroLineItem = (options: {
    lineAmountType: ValueOf<domain.LineAmountType>;
    lineItem: domain.XeroLineItem;
    editMode: RequestEditMode;
    requiredFields: ReturnType<typeof getRequiredFields>;
    context: Context;
    xeroContext: XeroContext;
    rules: domain.MatrixCondition[];
    approverCanCloneLineItem: boolean;
    lineOrder: number;
}) => {
    const {
        lineAmountType,
        lineItem,
        editMode,
        requiredFields,
        context,
        xeroContext,
        rules,
        approverCanCloneLineItem,
        lineOrder,
    } = options;

    const emptyLike = isEmptyLikeXeroLineItem(lineItem, lineAmountType);
    const computedAmount = getComputedLineAmount(lineItem);
    const computedTaxAmount = getComputedLineTaxAmount(lineItem, lineAmountType, computedAmount);
    const computedTotalAmount = getComputedLineTotalAmount(computedAmount, computedTaxAmount, lineAmountType);

    const invalidAmountText = isInvalidAmount(computedAmount);
    const invalidAccountText = isInvalidAccount(lineItem, rules);
    const invalidLineAmountTypeText = isInvalidLineAmountType(lineItem, lineAmountType, xeroContext);
    const invalidTrackingCategories = getInvalidTrackingCategories(lineItem, rules);
    const invalidAccountTaxMatchText = isInvalidAccountTaxMatch(lineItem);

    const isNew = xeroLineItem.isNewXeroLineItem(lineItem);
    const isEditorMode = editMode === RequestEditMode.Editor;
    const isApproverMode = editMode === RequestEditMode.Approver;

    return {
        ...lineItem,
        isNew,
        valid: isValidXeroLineItem(
            lineItem,
            computedAmount,
            requiredFields,
            context,
            xeroContext,
            lineAmountType,
            editMode,
            computedTaxAmount
        ),
        empty: emptyLike && !lineItem.description,
        emptyLike,
        descriptionOnly: Boolean(emptyLike && lineItem.description),
        accountIsReadonly: Boolean(lineItem.item && lineItem.item.isInventory),
        invalidAmount: Boolean(invalidAmountText),
        invalidAmountText,
        invalidTax: Boolean(invalidLineAmountTypeText),
        invalidTaxText: invalidLineAmountTypeText,
        invalidAccount: Boolean(invalidAccountText),
        invalidAccountText,
        invalidTrackingCategories,
        invalidAccountTaxMatch: Boolean(invalidAccountTaxMatchText),
        invalidAccountTaxMatchText,
        computedAmount,
        computedTaxAmount,
        computedTotalAmount,
        hideRemove: (isApproverMode && (!isNew || !approverCanCloneLineItem)) || (isEditorMode && !isNew),
        hideClone: isApproverMode && !approverCanCloneLineItem,
        lineOrder: lineOrder,
    };
};

type XeroRequest =
    | domain.XeroPoRequest
    | domain.XeroBillRequest
    | domain.XeroQuoteRequest
    | domain.XeroInvoiceRequest
    | domain.XeroCreditNotesReceivableRequest
    | domain.XeroCreditNotesPayableRequest;

export const getXeroLineItems: (state: State, request: XeroRequest) => ExpandedXeroLineItem[] = createSelector(
    (state: State, request: XeroRequest) => request.details.lineItems,
    (state: State, request: XeroRequest) => request.details.lineAmountType,
    (state: State, request: XeroRequest) => getRequiredFields(state, request),
    (state: State, _request: XeroRequest) => getContext(state),
    (state: State, _request: XeroRequest) => getXeroContext(state),
    (state: State, request: XeroRequest) => getRequestEditMode(state, request),
    (state: State, request: XeroRequest) => {
        const editMode = getRequestEditMode(state, request);

        if (editMode === RequestEditMode.Editor) {
            // Selecting the right editor rules
            const activeStep = request.steps[0];
            const editors = activeStep?.editors || [];
            const me = selectors.profile.getProfileUser(state).id;
            const editor = editors.find((e) => e.userId === me);

            return editor ? editor.rules : [];
        }

        if (editMode === RequestEditMode.Approver || editMode === RequestEditMode.Reviewer) {
            return [];
        }

        return request.authorRules;
    },
    (state: State, request: XeroRequest) => getApproverCanCloneLineItem(state, request),
    (lineItems, lineAmountType, requiredFields, context, xeroContext, editMode, rules, approverCanCloneLineItem) => {
        return lineItems.map((lineItem, index) =>
            expandXeroLineItem({
                lineAmountType,
                lineItem,
                editMode,
                requiredFields,
                context,
                xeroContext,
                rules,
                approverCanCloneLineItem,
                lineOrder: index,
            })
        );
    }
);

export const getXeroLineItemById = (
    state: State,
    request: domain.XeroPoRequest | domain.XeroBillRequest | domain.XeroQuoteRequest,
    lineItemId: string
) => {
    const lineItem = getXeroLineItems(state, request).find((li) => li.id === lineItemId);

    if (!lineItem) {
        throw errorHelpers.notFoundError(`Line item ${lineItemId}`);
    }

    return lineItem;
};

function getLineItemMismatches(state: State, request: XeroRequest): string[] {
    const editMode = getRequestEditMode(state, request);
    const currency = request.currency;

    const validateForApprover = editMode === RequestEditMode.Approver;
    const validateForReviewerV1 =
        editMode === RequestEditMode.Editor && request.integrationCode === domain.IntegrationCode.XeroBill;

    if (!validateForReviewerV1 && !validateForApprover) {
        return arrayHelpers.emptyArray();
    }

    const xeroContext = getXeroContext(state);
    const lineItems = getXeroLineItems(state, request);
    const lineItemsExtra = getPage(state).xeroLineItemExtra;

    function checkLineItem(unchangedTotalAmount: number | undefined, items: ExpandedXeroLineItem[]) {
        const lineAmountType = request.details.lineAmountType;
        const expected = unchangedTotalAmount;

        const received = toFloatPr(
            items.reduce((m, v) => {
                let amount;

                // if manually edited taxAmount
                if (v.taxAmount !== undefined && v.unitPrice !== undefined && v.qty !== undefined) {
                    switch (lineAmountType) {
                        case domain.LineAmountType.TaxExclusive: {
                            // INFO: taxAmount + unitPrice * qty
                            const priceMultipliedByQtyUnrounded = mathService.multiply(v.unitPrice, v.qty);
                            const isNegativePriceMultipliedByQtyUnrounded = priceMultipliedByQtyUnrounded < 0;
                            const absPriceMultipliedByQtyUnrounded = Math.abs(priceMultipliedByQtyUnrounded);
                            const priceMultipliedByQty = isNegativePriceMultipliedByQtyUnrounded
                                ? -mathService.round(absPriceMultipliedByQtyUnrounded, 2)
                                : mathService.round(absPriceMultipliedByQtyUnrounded, 2);

                            amount = mathService.add(v.taxAmount, priceMultipliedByQty);
                            break;
                        }

                        case domain.LineAmountType.TaxInclusive:
                        case domain.LineAmountType.NoTax: {
                            // INFO: unitPrice * qty
                            const priceMultipliedByQtyUnrounded = mathService.multiply(v.unitPrice, v.qty);
                            const isNegativePriceMultipliedByQtyUnrounded = priceMultipliedByQtyUnrounded < 0;
                            const absPriceMultipliedByQtyUnrounded = Math.abs(priceMultipliedByQtyUnrounded);
                            const absPriceMultipliedByQty = mathService.round(absPriceMultipliedByQtyUnrounded, 2);

                            amount = isNegativePriceMultipliedByQtyUnrounded
                                ? -absPriceMultipliedByQty
                                : absPriceMultipliedByQty;
                            break;
                        }

                        default:
                            throw errorHelpers.assertNever(lineAmountType);
                    }
                } else {
                    amount = v.computedTotalAmount;
                }

                return amount != null ? amount + m : m;
            }, 0)
        );

        if (expected == null || expected === received) {
            return undefined as any;
        }

        const affectedItemsStr = arrayHelpers.arraySort(items.map((li) => `#${lineItems.indexOf(li) + 1}`)).join(', ');

        return intl.formatMessage(messages.splitLineItemsAmountMismatch, {
            expected: intl.formatCurrency(expected, currency),
            received: intl.formatCurrency(received, currency),
            affected: affectedItemsStr,
        });
    }

    function getTotalAmount(li: domain.XeroLineItem) {
        const lineAmountType = request.details.lineAmountType;

        // if manually edited values
        if (li.amount !== undefined && li.taxAmount !== undefined) {
            switch (lineAmountType) {
                case domain.LineAmountType.TaxExclusive:
                    // INFO: amount + taxAmount
                    return mathService.round(mathService.add(li.amount, li.taxAmount), 2);

                case domain.LineAmountType.TaxInclusive:
                case domain.LineAmountType.NoTax:
                    return mathService.round(li.amount, 2);

                default:
                    throw errorHelpers.assertNever(lineAmountType);
            }
        }

        const computedAmount = getComputedLineAmount(li);
        const computedTaxAmount = getComputedLineTaxAmount(li, lineAmountType, computedAmount);
        const computedTotalAmount = getComputedLineTotalAmount(computedAmount, computedTaxAmount, lineAmountType);

        return computedTotalAmount;
    }

    let errors: string[] = [];
    let processedGroups: any[] = [];

    lineItems.forEach((lineItem) => {
        const groupEntry = lineItemsExtra.find((e) => e.lineItemId === lineItem.id);

        if (groupEntry) {
            // parse whole group if not parsed yet
            if (!processedGroups.includes(groupEntry.rootSplitItemId)) {
                const itemEntries = lineItemsExtra.filter((e) => e.rootSplitItemId === groupEntry.rootSplitItemId);
                const unchangedRoot = xeroContext.unchangedRequestLineItems.find(
                    (x) => x.id === groupEntry.rootSplitItemId
                );

                if (unchangedRoot) {
                    const errorText = checkLineItem(
                        getTotalAmount(unchangedRoot),
                        itemEntries
                            .map((x) => lineItems.find((li) => li.id === x.lineItemId))
                            .filter(typeGuardHelpers.isTruthy)
                    );

                    if (errorText) {
                        errors.push(errorText);
                    }

                    processedGroups.push(groupEntry.rootSplitItemId);
                }
            }
        } else {
            // check individual item
            const unchangedRoot = xeroContext.unchangedRequestLineItems.find((x) => x.id === lineItem.id);

            if (unchangedRoot) {
                const errorText = checkLineItem(getTotalAmount(unchangedRoot), [lineItem]);

                if (errorText) {
                    errors.push(errorText);
                }
            }
        }
    });

    return errors;
}

export function getXeroLineItemsSummary(state: State, r: XeroRequest) {
    const lineItems = getXeroLineItems(state, r);
    const lineAmountType = r.details.lineAmountType;

    let subtotalAmount = 0;
    let taxTotal = 0;
    let taxInfo: {
        [taxRateString: string]: number;
    } = {};

    lineItems.forEach((li) => {
        if (li.computedAmount) {
            subtotalAmount = mathService.add(subtotalAmount, li.computedAmount);
        }

        const taxAmount = li.taxAmount != null ? li.taxAmount : li.computedTaxAmount;

        if (taxAmount) {
            taxTotal = mathService.add(taxTotal, taxAmount);
        }

        if (li.tax && taxAmount) {
            taxInfo[li.tax.text] = mathService.add(taxInfo[li.tax.text] || 0, taxAmount);
        }
    });

    let taxAdjustmentsAmount = 0;

    if (
        r.integrationCode === domain.IntegrationCode.XeroBill &&
        lineAmountType === domain.LineAmountType.TaxExclusive
    ) {
        taxAdjustmentsAmount = lineItems
            .filter((li) => !li.emptyLike && li.taxAmount != null && li.computedTaxAmount != null)
            .reduce((adjustment, li) => {
                const diff = li.taxAmount! - li.computedTaxAmount!;

                return mathService.add(adjustment, diff);
            }, 0);
    }

    let totalAmount =
        lineAmountType === domain.LineAmountType.TaxExclusive
            ? mathService.add(subtotalAmount, taxTotal)
            : subtotalAmount;

    return {
        subtotalAmount,
        taxInfo: Object.entries(taxInfo).map(([taxRateString, taxAmount]) => ({
            taxRateString,
            taxAmount,
        })),
        taxAdjustmentsAmount,
        totalAmount,
        amountMismatches: getLineItemMismatches(state, r),
    };
}
