import { RequestMutationOptions } from '@approvalmax/data';
import { routerHelpers } from '@approvalmax/utils';
import { useQueryClient } from '@tanstack/react-query';
import { useMutate } from 'shared/data';

import { budgetsApiPaths } from '../paths';
import { UpdateSyncQBooksBudgets } from './useUpdateSyncQBooksBudgets.types';

export const useUpdateSyncQBooksBudgets = (mutationOptions?: RequestMutationOptions<UpdateSyncQBooksBudgets>) => {
    const queryClient = useQueryClient();

    return useMutate(budgetsApiPaths.qBooksBudgetsSync, {
        mutationOptions: {
            ...mutationOptions,
            onSuccess: async (data, variables, context) => {
                const budgetsApiPath = routerHelpers.pathToUrl(budgetsApiPaths.qBooksBudgets, {
                    companyId: variables.params?.path?.companyId,
                });

                await queryClient.invalidateQueries([budgetsApiPath]);

                mutationOptions?.onSuccess?.(data, variables, context);
            },
        },
        method: 'patch',
        apiVersion: 'v2',
    });
};
