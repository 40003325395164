import { oPopupPadding } from '@approvalmax/ui';
import styled from 'styled-components';

export const Content = styled.div`
    display: flex;
    flex-flow: column;
    ${oPopupPadding()};
`;

export const Description = styled.div`
    margin-top: 20px;
    font-size: 13px;
`;
