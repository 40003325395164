import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { companiesApiPaths } from '../paths';
import { GetMatchingSettings } from './useGetMatchingSettings.types';

export const useGetMatchingSettings = (
    params?: RequestParams<GetMatchingSettings>,
    queryOptions?: RequestQueryOptions<GetMatchingSettings>
) => {
    return useGet(companiesApiPaths.getMatchingSettings, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetMatchingSettings['params']['query']>(
                {
                    companyId: params?.query?.companyId,
                },
                queryOptions
            ),
        },
        apiVersion: 'v1',
    });
};
