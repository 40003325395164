import { Guid, MentionedUser, Reference } from '@approvalmax/types';

import { backend, domain } from '..';
import { EmailToContact } from './EmailToContact';
import { Field } from './Field';
import { MatrixCondition } from './Matrix';
import { TemplateStepType } from './Template';
import { User } from './User';
import { Watcher } from './Watcher';

export enum RequestAttachmentType {
    General = 'General',
    PurchaseOrderPDF = 'PurchaseOrderPDF',
    DocumentPDF = 'DocumentPDF',
}

export interface RequestAttachment {
    id: Guid;
    name: string;
    size: number;
    createdDate?: string;
    authorId?: Guid;
    attachmentType: keyof typeof RequestAttachmentType;
    externalId?: string;
    isNew?: boolean;
    ocrStatus?: keyof typeof OcrStatus;
    isOcrOngoing?: boolean;
}

export enum OcrStatus {
    Succeeded = 'Succeeded',
    InProgress = 'InProgress',
    Failed = 'Failed',
    Requested = 'Requested',
    Skipped = 'Skipped',
}

export enum RequestHistoryEventType {
    AirwallexBatchPaymentRequestPaid = 'AirwallexBatchPaymentRequestPaid',
    AirwallexRequestScheduledForPayment = 'AirwallexRequestScheduledForPayment',
    AirwallexScheduledPaymentCancelled = 'AirwallexScheduledPaymentCancelled',
    AirwallexScheduledPaymentFailedDueToBeneficiaryDiscrepancy = 'AirwallexScheduledPaymentFailedDueToBeneficiaryDiscrepancy',
    AirwallexScheduledPaymentFailedDueToCompanyIntegrationDisabled = 'AirwallexScheduledPaymentFailedDueToCompanyIntegrationDisabled',
    AirwallexScheduledPaymentFailedDueToExceededAmountDue = 'AirwallexScheduledPaymentFailedDueToExceededAmountDue',
    AirwallexScheduledPaymentFailedDueToInsufficientFunds = 'AirwallexScheduledPaymentFailedDueToInsufficientFunds',
    AirwallexScheduledPaymentFailedDueToPaymentServiceIntegrationDisconnected = 'AirwallexScheduledPaymentFailedDueToPaymentServiceIntegrationDisconnected',
    AmaxPayBatchPaymentBillPaymentCancelled = 'AmaxPayBatchPaymentBillPaymentCancelled',
    ApprovedInXero = 'ApprovedInXero',
    ApproverApproved = 'ApproverApproved',
    ApproverApprovedRequestApproved = 'ApproverApprovedRequestApproved',
    ApproverApprovedStepApproved = 'ApproverApprovedStepApproved',
    ApproverEdited = 'ApproverEdited',
    ApproverRejected = 'ApproverRejected',
    ApproverRevoked = 'ApproverRevoked',
    ApproverRevokedStepChanged = 'ApproverRevokedStepChanged',
    AttachmentDeleted = 'AttachmentDeleted',
    AttachmentUploadFailed = 'AttachmentUploadFailed',
    AttachmentsAdded = 'AttachmentsAdded',
    AutoMarkedAsBilled = 'AutoMarkedAsBilled',
    AutoUnmarkedAsBilled = 'AutoUnmarkedAsBilled',
    CancelledExternally = 'CancelledExternally',
    CancelledInXero = 'CancelledInXero',
    Comment = 'Comment',
    CommentDeleted = 'CommentDeleted',
    DocumentClosed = 'DocumentClosed',
    DocumentClosedStatusSynced = 'DocumentClosedStatusSynced',
    DocumentOpened = 'DocumentOpened',
    DocumentOpenedStatusSynced = 'DocumentOpenedStatusSynced',
    EditorEdited = 'EditorEdited',
    EditorEditedRequestApproved = 'EditorEditedRequestApproved',
    EditorEditedStepApproved = 'EditorEditedStepApproved',
    EmailToPartnerSent = 'EmailToPartnerSent',
    ForcedApproval = 'ForcedApproval',
    ForcedReject = 'ForcedReject',
    GrnChangedToFullyReceived = 'GrnChangedToFullyReceived',
    GrnChangedToNotReceived = 'GrnChangedToNotReceived',
    GrnChangedToPartiallyReceived = 'GrnChangedToPartiallyReceived',
    MarkAsBilledSynced = 'MarkAsBilledSynced',
    MarkAsCustomerDecisionSynced = 'MarkAsCustomerDecisionSynced',
    MarkedAsBilled = 'MarkedAsBilled',
    MarkedAsSentSynced = 'MarkedAsSentSynced',
    NetSuiteApprovedExternally = 'NetSuiteApprovedExternally',
    ParticipantsWereAddedDueToMatchingBillWithPO = 'ParticipantsWereAddedDueToMatchingBillWithPO',
    ParticipantsWereChanged = 'ParticipantsWereChanged',
    ParticipantsWereChangedRequestApproved = 'ParticipantsWereChangedRequestApproved',
    ParticipantsWereChangedStepChanged = 'ParticipantsWereChangedStepChanged',
    PostApprovalChangeDetected = 'PostApprovalChangeDetected',
    PulledFromSource = 'PulledFromSource',
    PushedToSource = 'PushedToSource',
    PutOnHold = 'PutOnHold',
    QBooksMatchingAdded = 'QBooksMatchingAdded',
    QBooksMatchingRemoved = 'QBooksMatchingRemoved',
    QBooksStatusRejected = 'QBooksStatusRejected',
    RejectedExternally = 'RejectedExternally',
    RequestMatchedBySystem = 'RequestMatchedBySystem',
    RequestMatchedByUser = 'RequestMatchedByUser',
    RequestUnmatchedBySystem = 'RequestUnmatchedBySystem',
    RequestUnmatchedByUser = 'RequestUnmatchedByUser',
    RequesterCancelled = 'RequesterCancelled',
    RequesterClosed = 'RequesterClosed',
    RequesterReopened = 'RequesterReopened',
    RequesterReopenedRequestApproved = 'RequesterReopenedRequestApproved',
    RequesterReopenedStepChanged = 'RequesterReopenedStepChanged',
    RequesterReopenedWithReset = 'RequesterReopenedWithReset',
    RequesterResetTemplate = 'RequesterResetTemplate',
    RequesterSubmitted = 'RequesterSubmitted',
    RequesterUpdated = 'RequesterUpdated',
    RequesterUpdatedRequestApproved = 'RequesterUpdatedRequestApproved',
    RequesterUpdatedStepChanged = 'RequesterUpdatedStepChanged',
    RequesterUpdatedWithReset = 'RequesterUpdatedWithReset',
    ReturnedToReview = 'ReturnedToReview',
    ReviewCompleted = 'ReviewCompleted',
    ReviewForcedByAdmin = 'ReviewForcedByAdmin',
    ReviewSkipped = 'ReviewSkipped',
    ReviewerEdited = 'ReviewerEdited',
    ReviewersChanged = 'ReviewersChanged',
    StepSkipped = 'StepSkipped',
    StepSkippedRequestApproved = 'StepSkippedRequestApproved',
    StepSkippedRequestRejected = 'StepSkippedRequestRejected',
    TakenOffHold = 'TakenOffHold',
    UnmarkedAsBilled = 'UnmarkedAsBilled',
    UpdatedExternallyResetTemplate = 'UpdatedExternallyResetTemplate',
    XeroMatchingAdded = 'XeroMatchingAdded',
    XeroMatchingRemoved = 'XeroMatchingRemoved',
}

export enum RequestHistoryUserPlatform {
    Web = 'web',
    Mobile = 'mobile',
    Slack = 'slack',
    Email = 'email',
}

export interface RequestHistoryFieldChangeData {
    fieldName: string;
    oldValue: string;
    newValue: string;
    oldDetails: string;
    newDetails: string;
}

export interface RequestHistoryApproverBackendInfo {
    user: backend.UserAnswer;
    delegateFor: backend.UserAnswer;
}

export interface RequestHistoryStepChangeData {
    step: Reference;
    name?: string;
    deadline?: string;
    deadlineRemoved?: boolean;
    addedApprovers?: RequestHistoryApproverBackendInfo[];
    removedApprovers?: RequestHistoryApproverBackendInfo[];
}

export interface RequestHistoryReviewStepChangeData {
    stepId: string;
    addedReviewers?: backend.UserAnswer[];
    removedReviewers?: backend.UserAnswer[];
}

export interface RequestHistoryEvent {
    id: Guid;
    date: string;
    description?: string;
    type: RequestHistoryEventType;
    step?: Reference;
    nextStep?: Reference;
    authorId?: Guid;
    onBehalfOfId?: Guid;
    isSystem: boolean;
    userPlatform?: RequestHistoryUserPlatform;
    comment?: {
        id: Guid;
        createdDate: string;
        text: string;
        author: Guid;
        attachments: RequestAttachment[];
        mentionedUsers?: MentionedUser[];
    };
    changes?: {
        newName?: string;
        newDescription?: string;
        fieldChanges?: RequestHistoryFieldChangeData[];
        amountFieldChange?: RequestHistoryFieldChangeData;
        addedAttachments?: RequestAttachment[];
        removedAttachments?: RequestAttachment[];
        stepChanges?: RequestHistoryStepChangeData[];
        reviewStepChanges?: RequestHistoryReviewStepChangeData;
    };
}

export enum RequestFraudulentActivity {
    PostApprovalChange = 'PostApprovalChange',
    ExternalResolving = 'ExternalResolving',
    AutomaticResolving = 'AutomaticResolving',
    AdminEnforcedResolving = 'AdminEnforcedResolving',
    DroppingOffApprovers = 'DroppingOffApprovers',
}

export enum RequestResolutionOrigin {
    ResolvedRegularly = 'ResolvedRegularly',
    EnforcedExternally = 'EnforcedExternally',
    EnforcedByAdmin = 'EnforcedByAdmin',
    ResolvedAutomatically = 'ResolvedAutomatically',
    ChangedApprovers = 'ChangedApprovers',
    ResolvedExternally = 'ResolvedExternally',
}

export enum RequestStatusV2 {
    Draft = 'draft',
    OnApproval = 'on-approval',
    Approved = 'approved',
    Rejected = 'rejected',
    Cancelled = 'cancelled',
    OnReview = 'on-review',
    OnHold = 'on-hold',
}

export enum RequestOrigin {
    ApprovalMax = 'ApprovalMax',
    Xero = 'Xero',
    QBooks = 'QBooks',
    ReceiptBank = 'ReceiptBank',
    NetSuite = 'NetSuite',
    Dear = 'Dear',
    PublicApi = 'PublicApi',
    Email = 'Email',
}

export enum ExchangeRateSource {
    Bills = 'Bills',
    Cin7Core = 'Cin7Core',
    CoinLayer = 'CoinLayer',
    Fixer = 'Fixer',
    Manual = 'Manual',
    NetSuite = 'NetSuite',
    None = 'None',
    OpenExchangeRate = 'OpenExchangeRate',
    QBooks = 'QBooks',
    Xero = 'Xero',
}

export enum RequestStepState {
    NotStarted = 'NotStarted',
    Active = 'Active',
    Done = 'Done',
}

export enum RequestStepResolution {
    NoResolution = 'NoResolution',
    Rejected = 'Rejected',
    Approved = 'Approved',
}

export interface RequestStep {
    id: string;
    name: string;
    createdDate: string;
    modifiedDate: string;
    state: RequestStepState;
    resolution: RequestStepResolution;
    resolutionDate: string | null;
    dueDate: string | null;
    participants: RequestStepParticipant[];
    editors: RequestStepEditor[];
    requiredFieldIds: Guid[];
    editPermissionsRequiredFieldIds: Guid[];
    type: TemplateStepType;
    approvalCount: number | null;
}

export enum RequestStepParticipantDecision {
    NoResponse = 'NoResponse',
    Reject = 'Reject',
    Approve = 'Approve',
}

export enum RequestStepParticipantOrigin {
    ByRules = 'ByRules',
    IsBackup = 'IsBackup',
    Manually = 'Manually',
    ByMatching = 'ByMatching',
}

export enum RequestStepReviewerSource {
    Backup = 'backup',
    Manual = 'manual',
    Rules = 'rules',
}

export interface RequestStepParticipant {
    userId: Guid;
    decision: RequestStepParticipantDecision;
    decisionDate: string | null;
    enabledAccordingToRule: boolean;
    required: boolean;
    source: RequestStepParticipantOrigin;
    addedBy: Guid | null;
    delegateFor: Guid | null;
    canEdit: boolean;
    editPermissions: MatrixCondition[];
}

export interface RequestReviewer extends User {
    addedBy: Guid | null;
    source: RequestStepReviewerSource;
    delegateFor: string | null;
}

export interface RequestStepEditor {
    userId: Guid;
    rules: MatrixCondition[];
}

export interface RequestFieldEntry {
    field: Field;
    value: Reference | null;
}

export interface RequestNote {
    value: string;
    errorMessages: null | string[];
}

export type RequestReviewStep = {
    reviewStepId: string;
    reviewers: RequestReviewer[];
    completedAt: string | null;
    completedByUser: User | null;
    isCompleted: boolean;
};

export type BaseRequest = {
    id: Guid;
    name: string;
    description: string;
    fieldValues: domain.RequestFieldEntry[];
    statusV2: RequestStatusV2;
    origin: RequestOrigin;
    resolutionOrigin: RequestResolutionOrigin;
    resolutionDate: string | null;
    authorId: Guid;
    template: Reference;
    templateVersionIsObsolete: boolean;
    createdDate: string;
    modifiedDate: string;
    exchangeRate: number | null;
    exchangeRateDate: string | null;
    exchangeRateSource: ExchangeRateSource | null;
    companyId: Guid;
    displayName: string;
    requestNote: RequestNote;
    currency: string;
    currencyExchangeRate: number | null;
    amount: number;
    attachments: RequestAttachment[];
    auditReportUrl: string | null;
    history: RequestHistoryEvent[];
    fraudulentActivity: RequestFraudulentActivity[];
    steps: RequestStep[];
    requiredFieldIds: Guid[];
    requiresEditingForReviewerV1: boolean;
    requiresEditingForEditOnApproval: boolean;
    editPermissionsRequiredFieldsThatShouldBeFilled: Field[];
    canCreateContact: boolean;
    canCreateBeneficiary: boolean;
    authorRules: MatrixCondition[];
    version: number;
    companyCurrency: string;
    isManualExchangeRate: boolean;
    canEditManualRate: boolean;
    payers: User[];
    deadlineDate: string | null;
    watchers: Watcher[];
    workflowVersionId: Guid;
    reviewStep: RequestReviewStep | null;
    emailToContact?: EmailToContact | null;
};
