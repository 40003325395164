import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { profileApiPaths } from '../paths';
import { SendLoginTFAEmailCode } from './useSendLoginTFAEmailCode.types';

export const useSendLoginTFAEmailCode = (mutationOptions?: RequestMutationOptions<SendLoginTFAEmailCode>) => {
    return useMutate(profileApiPaths.sendLoginTFAEmailCode, {
        mutationOptions,
        apiVersion: 'v1',
    });
};
