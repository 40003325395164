import { MutateDataParams, MutationOptions } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { twoFaApiPaths } from '../paths';
import { UseVerifyTFAEnablingCodeRequest, UseVerifyTFAEnablingCodeResponse } from './useVerifyTFAEnablingCode.types';

export const useVerifyTFAEnablingCode = (
    mutationOptions?: MutationOptions<
        UseVerifyTFAEnablingCodeResponse,
        MutateDataParams<UseVerifyTFAEnablingCodeRequest>
    >
) => {
    return useMutateLegacy<MutateDataParams<UseVerifyTFAEnablingCodeRequest>, UseVerifyTFAEnablingCodeResponse>(
        twoFaApiPaths.enablingAppCodeVerify,
        { mutationOptions }
    );
};
