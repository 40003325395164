import './sectionContainer.scss';

import { FC, PropsWithChildren, ReactNode } from 'react';
import bemFactory from 'react-bem-factory';

const bem = bemFactory.block('reql-section-container');

interface SectionContainerProps extends PropsWithChildren {
    className?: string;
    text: ReactNode;
    noPadding?: boolean;
    qa?: string;
}

/**
 * @deprecated
 * @use frontend/apps/web-app/src/pages/requestList/components/SectionContainer/SectionContainer.tsx
 */
const SectionContainer: FC<SectionContainerProps> = (props) => {
    const { className, children, text, noPadding, qa } = props;

    return (
        <section
            className={bem.add(className)(null, {
                'no-padding': noPadding,
            })}
            data-qa={qa}
        >
            <header className={bem('header')}>{text}</header>

            <div className={bem('body')}>{children}</div>
        </section>
    );
};

export default SectionContainer;
