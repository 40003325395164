import { getLockDateWarningText } from 'modules/common/selectors/requestSelectors.Xero';
import { FC, memo } from 'react';
import bemFactory from 'react-bem-factory';

import FieldsSection from '../../../components/FieldsSection';
import { messages } from './XeroInvoiceFields.messages';
import { XeroInvoiceFieldsProps } from './XeroInvoiceFields.types';

const qa = bemFactory.qa('reql-xero-fields-section');

const XeroInvoiceFields: FC<XeroInvoiceFieldsProps> = memo((props) => {
    const { request } = props;

    const details = request.details;
    const lockDateWarningText = getLockDateWarningText(request);

    return (
        <FieldsSection>
            <FieldsSection.Field
                qa={qa('date')}
                title={messages.invoiceDateFieldText}
                valueType={FieldsSection.Field.ValueType.Date}
                value={details.date}
                warningText={lockDateWarningText}
            />

            <FieldsSection.Field
                qa={qa('dueDate')}
                title={messages.invoiceDueDateFieldText}
                valueType={FieldsSection.Field.ValueType.Date}
                value={details.dueDate}
            />

            <FieldsSection.Field qa={qa('number')} title={messages.invoiceNumberFieldText} value={details.number} />

            {details.reference && (
                <FieldsSection.Field
                    qa={qa('reference')}
                    title={messages.invoiceReferenceFieldText}
                    value={details.reference}
                />
            )}

            {details.brandingTheme && (
                <FieldsSection.Field
                    qa={qa('brandingTheme')}
                    title={messages.invoiceBrandingThemeFieldText}
                    value={details.brandingTheme}
                />
            )}
        </FieldsSection>
    );
});

export default XeroInvoiceFields;
