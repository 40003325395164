import { theme } from '@approvalmax/theme';
import { ArrowReplayIcon } from '@approvalmax/ui';
import { Box } from '@approvalmax/ui/src/components';
import styled from 'styled-components';

export const TableContainer = styled(Box)`
    /**
     * Table doesn't have height attribute
     * and applying 100% to table's container doesn't work
     * so we need to set table's container some height value not in percentage
     * to make table scrollable
     *
     * 32px - Popup top and bottom spacing
     * 80px - Popup header height
     * 56px - Popup body bottom spacing
     */
    max-height: calc(100vh - 32px - 32px - 80px - 56px);
`;

export const AmountDueWarning = styled.span`
    color: ${theme.color.red100};
`;

export const StyledArrowReplayIcon = styled(ArrowReplayIcon)`
    flex-shrink: 0;
`;
