import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { useCallback, useMemo, useState } from 'react';

import { messages } from './GoodsReceivedNotes.messages';
import { GoodsReceivedNotesProps } from './GoodsReceivedNotes.types';

export const useGoodsReceiveNotesPopup = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const onPopupOpen = useCallback(() => {
        setIsPopupOpen(true);
    }, []);

    const onPopupClose = useCallback(() => {
        setIsPopupOpen(false);
    }, []);

    return {
        isPopupOpen,
        onPopupOpen,
        onPopupClose,
    };
};

export const useGoodsReceivedNotesData = (request: GoodsReceivedNotesProps['request']) => {
    const data = useMemo(() => {
        const items = request.history.filter(
            (history) =>
                history.type === domain.RequestHistoryEventType.GrnChangedToNotReceived ||
                history.type === domain.RequestHistoryEventType.GrnChangedToPartiallyReceived ||
                history.type === domain.RequestHistoryEventType.GrnChangedToFullyReceived
        );

        return items.length ? { ...items[items.length - 1] } : null;
    }, [request.history]);

    const author = useSelector((state) => {
        return data && data.authorId ? selectors.user.getUserById(state, data.authorId) : null;
    });

    return data ? { ...data, author } : null;
};

export const useGoodsReceivedNotesLabel = (request: GoodsReceivedNotesProps['request']) => {
    const details = request.details;

    switch (details.grnStatus) {
        case domain.GoodsReceivedNotesStatus.PartiallyReceived:
            return { status: details.grnStatus, title: messages.statusPartiallyReceived };

        case domain.GoodsReceivedNotesStatus.FullyReceived:
            return { status: details.grnStatus, title: messages.statusFullyReceived };

        case domain.GoodsReceivedNotesStatus.NotReceived:
            return { status: details.grnStatus, title: messages.statusNotReceived };

        default:
            return null;
    }
};
