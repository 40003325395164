import './lineItemsSection.scss';

import { ConnectedProps } from 'modules/react-redux';
import React from 'react';
import bemFactory from 'react-bem-factory';
import { connect } from 'react-redux';

function mapStateToProps() {
    return {};
}

const mapDispatchToProps = {};

interface OwnProps {
    className?: string;
    qa?: string;
    renderTable: () => React.ReactNode;
    renderFooter: () => React.ReactNode;
}

type Props = ConnectedProps<OwnProps, typeof mapStateToProps, typeof mapDispatchToProps>;

class LineItemsSection extends React.Component<Props> {
    public render() {
        const { className, qa: qa$, renderTable, renderFooter } = this.props;

        const bem = bemFactory.block('reql-line-items-section');
        const qa = bemFactory.qa('reql-line-items-section');

        return (
            <div className={bem.add(className)()} data-qa={qa()} data-qa-id={qa$}>
                {renderTable()}

                {renderFooter()}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LineItemsSection);
