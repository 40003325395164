import { hooks } from '@approvalmax/utils';
import { FC, memo, useCallback, useEffect } from 'react';
import bemFactory from 'react-bem-factory';
import { useToggle } from 'react-use';
import { useRecoilState, useRecoilValue } from 'recoil';

import { BellIcon, MenuIcon, PinIcon, PinPinnedIcon } from '../../../../icons';
import { AmLogo } from '../../../../logos';
import Badge from '../../../Badge/Badge';
import Button from '../../../Button/Button';
import ConditionWrapper from '../../../ConditionWrapper/ConditionWrapper';
import Dropdown from '../../../Dropdown/Dropdown';
import { Flex } from '../../../Flex/Flex';
import { collapseDrawerState, openDrawerState, pinDrawerState } from '../../Drawer.states';
import { useScreenAdaptation } from './Header.hooks';
import { ExpandButton, Inner, Root } from './Header.styles';
import { HeaderProps } from './Header.types';

const qa = bemFactory.qa('page-nav-bar');

const Header: FC<HeaderProps> = memo((props) => {
    const { root, notifications, notificationsCount, ...restProps } = props;

    const { sticky, stickyRef } = hooks.useSticky({ root });
    const { isHidePinButton } = useScreenAdaptation();

    const isDrawerCollapsed = useRecoilValue(collapseDrawerState);
    const [isDrawerPinned, setIsDrawerPinned] = useRecoilState(pinDrawerState);
    const [isDrawerOpen, setIsDrawerOpen] = useRecoilState(openDrawerState);

    const pinDrawer = useCallback(() => setIsDrawerPinned(true), [setIsDrawerPinned]);
    const unpinDrawer = useCallback(() => {
        setIsDrawerPinned(false);
        setIsDrawerOpen(true);
    }, [setIsDrawerPinned, setIsDrawerOpen]);

    const [isNotificationsOpen, setIsNotificationsOpen] = useToggle(false);

    useEffect(() => {
        if (!notificationsCount) {
            setIsNotificationsOpen(false);
        }
    }, [notificationsCount, setIsNotificationsOpen]);

    return (
        <>
            <div ref={stickyRef} />

            <Root $sticky={sticky} $open={isDrawerOpen} {...restProps}>
                {isDrawerCollapsed ? (
                    <ExpandButton
                        $collapsed={isDrawerCollapsed}
                        icon
                        block
                        size='large'
                        onClick={unpinDrawer}
                        data-qa={qa('drawer-button')}
                    >
                        <ConditionWrapper
                            condition={notificationsCount}
                            wrapperIf={(children) => (
                                <Badge size={8} placement='right-start' color='red100'>
                                    {children}
                                </Badge>
                            )}
                        >
                            <MenuIcon size={24} />
                        </ConditionWrapper>
                    </ExpandButton>
                ) : (
                    <Inner $collapsed={isDrawerCollapsed}>
                        <AmLogo width='132px' height='20px' />

                        <Flex>
                            {!!notificationsCount && (
                                <Dropdown
                                    open={isNotificationsOpen}
                                    onOpen={setIsNotificationsOpen}
                                    activator={
                                        <Button size='small' color='red40' startIcon={<BellIcon />}>
                                            {notificationsCount}
                                        </Button>
                                    }
                                    closeOnClickInnerButton
                                    disabled={!notificationsCount}
                                    width={280}
                                    spacing='20'
                                >
                                    {notifications}
                                </Dropdown>
                            )}

                            {!isHidePinButton && (
                                <>
                                    {isDrawerPinned ? (
                                        <Button
                                            icon
                                            size='large'
                                            color='midnight40'
                                            onClick={unpinDrawer}
                                            data-qa='page-nav-bar__unpin-button'
                                        >
                                            <PinPinnedIcon size={20} />
                                        </Button>
                                    ) : (
                                        <Button
                                            icon
                                            size='large'
                                            color='blue10'
                                            onClick={pinDrawer}
                                            data-qa='page-nav-bar__pin-button'
                                        >
                                            <PinIcon size={20} />
                                        </Button>
                                    )}
                                </>
                            )}
                        </Flex>
                    </Inner>
                )}
            </Root>
        </>
    );
});

export default Header;
