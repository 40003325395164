import { domain } from 'modules/data';
import { TemplateRequestListFilter } from 'pages/requestList/config';

import { messages } from './EditPopup.messages';
import { StatusOption } from './EditPopup.types';

export const statusOptions: StatusOption[] = [
    { id: domain.GoodsReceivedNotesStatus.NotReceived, text: messages.statusNotReceived },
    { id: domain.GoodsReceivedNotesStatus.PartiallyReceived, text: messages.statusPartiallyReceived },
    { id: domain.GoodsReceivedNotesStatus.FullyReceived, text: messages.statusFullyReceived },
];

export const grnStatusToTemplateFilterMap = {
    [domain.GoodsReceivedNotesStatus.FullyReceived]: TemplateRequestListFilter.GrnFullyReceived,
    [domain.GoodsReceivedNotesStatus.NotReceived]: TemplateRequestListFilter.GrnNotReceived,
    [domain.GoodsReceivedNotesStatus.PartiallyReceived]: TemplateRequestListFilter.GrnPartiallyReceived,
};
