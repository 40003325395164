import { validators } from '@approvalmax/ui';
import { useCallback, useRef, useState } from 'react';
import { useStartAddingTFAAlternativeEmail } from 'shared/data';

import { messages } from './StepEmailEnter.messages';
import { StepEmailEnterProps } from './StepEmailEnter.types';

export const useStepEmailEnter = (props: StepEmailEnterProps) => {
    const { email, setEmail, setStateId, onNext } = props;
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const inputRef = useRef<HTMLInputElement>(null);
    const { mutate: addEmail, reset, isError, isLoading } = useStartAddingTFAAlternativeEmail();

    const onChange = useCallback(
        (value: string) => {
            if (isError) reset();

            setEmail(value.trim());
        },
        [isError, reset, setEmail]
    );

    const onSubmit = useCallback(() => {
        if (!validators.isEmail(email)) {
            setErrorMessage(messages.invalidEmailErrorText);

            return;
        }

        if (email) {
            addEmail(
                {
                    body: { alternativeEmail: email },
                },
                {
                    onSuccess: (response) => {
                        onNext();
                        setStateId(response.stateId);
                    },
                    onError: (err) => {
                        setErrorMessage(err.message);

                        inputRef.current!.focus();
                    },
                }
            );
        }
    }, [addEmail, email, onNext, setStateId]);

    return {
        inputRef,
        email,
        onChange,
        onSubmit,
        isLoading,
        errorMessage,
        canSubmit: Boolean(email) && !isLoading,
    };
};
