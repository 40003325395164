import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { budgetsApiPaths } from '../paths';
import { GetQBooksBudgetsImpact } from './useGetQBooksBudgetsImpact.types';

export const useGetQBooksBudgetsImpact = (
    params: RequestParams<GetQBooksBudgetsImpact>,
    queryOptions?: RequestQueryOptions<GetQBooksBudgetsImpact>
) => {
    return useGet(budgetsApiPaths.qBooksBudgetsImpact, {
        params,
        queryOptions,
        apiVersion: 'v2',
    });
};
