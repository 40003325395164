import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { GetDextTransaction } from './useGetDextTransaction.types';

export const useGetDextTransaction = (
    params?: RequestParams<GetDextTransaction>,
    queryOptions?: RequestQueryOptions<GetDextTransaction>
) => {
    return useGet(requestsApiPaths.dextTransaction, {
        params,
        queryOptions,
        apiVersion: 'v2',
    });
};
