import { domain } from 'modules/data';
import { findDocumentFieldState } from 'shared/helpers';

export const useExpenseLinesFieldSettings = (documentFields: domain.DocumentField[]) => ({
    expensesAccount: findDocumentFieldState(documentFields, 'ExpensesAccount'),
    expensesAmount: findDocumentFieldState(documentFields, 'ExpensesAmount'),
    expensesAttachment: findDocumentFieldState(documentFields, 'ExpensesAttachment'),
    expensesCategory: findDocumentFieldState(documentFields, 'ExpensesCategory'),
    expensesClass: findDocumentFieldState(documentFields, 'ExpensesClass'),
    expensesCurrency: findDocumentFieldState(documentFields, 'ExpensesCurrency'),
    expensesCustomer: findDocumentFieldState(documentFields, 'ExpensesCustomer'),
    expensesDate: findDocumentFieldState(documentFields, 'ExpensesDate'),
    expensesDepartment: findDocumentFieldState(documentFields, 'ExpensesDepartment'),
    expensesExchangeRate: findDocumentFieldState(documentFields, 'ExpensesExchangeRate'),
    expensesForeignAmount: findDocumentFieldState(documentFields, 'ExpensesForeignAmount'),
    expensesGrossAmount: findDocumentFieldState(documentFields, 'ExpensesGrossAmount'),
    expensesIsBillable: findDocumentFieldState(documentFields, 'ExpensesIsBillable'),
    expensesIsCorporateCreditCard: findDocumentFieldState(documentFields, 'ExpensesIsCorporateCreditCard'),
    expensesIsNonReimbursable: findDocumentFieldState(documentFields, 'ExpensesIsNonReimbursable'),
    expensesIsReceipt: findDocumentFieldState(documentFields, 'ExpensesIsReceipt'),
    expensesLocation: findDocumentFieldState(documentFields, 'ExpensesLocation'),
    expensesMemo: findDocumentFieldState(documentFields, 'ExpensesMemo'),
    expensesRefLineNumber: findDocumentFieldState(documentFields, 'ExpensesRefLineNumber'),
    expensesTaxAmount: findDocumentFieldState(documentFields, 'ExpensesTaxAmount'),
    expensesTaxCode: findDocumentFieldState(documentFields, 'ExpensesTaxCode'),
    expensesTaxRate: findDocumentFieldState(documentFields, 'ExpensesTaxRate'),
});
