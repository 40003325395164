import { selectors } from 'modules/common';
import { useSelector } from 'modules/react-redux';
import { getRequest as requestFormGetActiveRequest } from 'pages/requestForm/selectors/pageSelectors';
import { FC, memo, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { ContentSplitViewMode, contentSplitViewState } from 'shared/states';

import RequestCardSkeleton from '../../../components/RequestCard/RequestCard.skeleton';
import { getActiveRequestSafe as requestListGetActiveRequest } from '../../../selectors/pageSelectors';
import { XeroRequestCard } from '../XeroRequestCard/XeroRequestCard';
import { useBillByIntegration } from './XeroRequestCardWrapper.hooks';
import { XeroRequestCardWrapperProps } from './XeroRequestCardWrapper.types';

export const XeroRequestCardWrapper: FC<XeroRequestCardWrapperProps> = memo((props) => {
    const { setContainerTitle } = props;

    const contentSplitView = useRecoilValue(contentSplitViewState);
    const activePageId = useSelector(selectors.navigation.getActivePageId);

    const getActiveRequest = activePageId === 'requestForm' ? requestFormGetActiveRequest : requestListGetActiveRequest;

    const batchPaymentRequest = useSelector(getActiveRequest);
    const batchPaymentRequestId = batchPaymentRequest?.id;
    const { companyId } = batchPaymentRequest || {};

    const billLinkedToBatchPaymentId =
        (contentSplitView.mode === ContentSplitViewMode.BillLinkedToBatchPayment && contentSplitView.billId) || null;

    const { request, requestDetails, matchingData } = useBillByIntegration({
        companyId,
        billId: billLinkedToBatchPaymentId || '',
        batchPaymentRequestId: batchPaymentRequestId,
        integrationCode: batchPaymentRequest?.integrationCode,
        enabled: !!(batchPaymentRequestId && billLinkedToBatchPaymentId && companyId),
    });

    useEffect(() => {
        if (request && request.displayName) {
            setContainerTitle(request.displayName);
        }
    }, [request, setContainerTitle]);

    if (!request) return <RequestCardSkeleton />;

    return (
        <XeroRequestCard
            request={request}
            preloadedRequestDetails={requestDetails}
            preloadedMatchingData={matchingData}
            readonly
        />
    );
});

XeroRequestCardWrapper.displayName = 'XeroRequestCardWrapper';
