import { openSplitViewState } from '@approvalmax/ui/src/components';
import { errorHelpers, intl } from '@approvalmax/utils';
import isBoolean from 'lodash/isBoolean';
import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { isGrnAvailable } from 'modules/utils';
import EmailToSupplier from 'pages/requestList/components/EmailToSupplier/EmailToSupplier';
import { RequestListPayAndCapturePromotion } from 'pages/requestList/components/RequestListPayAndCapturePromotion/RequestListPayAndCapturePromotion';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { useUnmount } from 'react-use';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { activeAttachmentIdState, ContentSplitViewMode, contentSplitViewState } from 'shared/states';
import { AddonPromotionType, AftPromotionType, pageVisiblePromotionsState } from 'shared/states/pageVisiblePromotions';

import GoodsReceivedNotes from '../../../components/GoodsReceivedNotes/GoodsReceivedNotes';
import RequestNoteSection from '../../../components/RequestNoteSection';
import ActivitySection from '../../card/ActivitySection';
import AttachmentsSection from '../../card/AttachmentsSection';
import Toolbar from '../../card/Toolbar/Toolbar';
import { WorkflowSection } from '../../card/WorkflowSection';
import CustomerDecisionSection from '../CustomerDecisionSection/CustomerDecisionSection';
import XeroBilledStatusSection from '../XeroBilledStatusSection/XeroBilledStatusSection';
import XeroBudgetingSection from '../XeroBudgetingSection/XeroBudgetingSection';
import XeroFieldsSection from '../XeroFieldsSection';
import XeroLineItemsSection from '../XeroLineItemsSection';
import { XeroManualJournalLinesSection } from '../XeroManualJournalLinesSection/XeroManualJournalLinesSection';
import XeroMatchingV2Section from '../XeroMatchingV2Section/XeroMatchingV2Section';
import XeroReceiptBankPaymentDetailsSection from '../XeroReceiptBankPaymentDetailsSection';
import SchedulePaymentPopup from './components/SchedulePaymentPopup/SchedulePaymentPopup';
import XeroPoDeliveryDetails from './components/XeroPoDeliveryDetails/XeroPoDeliveryDetails';
import { useClosePromotion, useGetNextPromotions } from './XeroRequestCard.hooks';
import { messages } from './XeroRequestCard.messages';
import {
    Body,
    Content,
    PendingStatusSection,
    Root,
    StyledAddCommentSection,
    StyledFeaturePromotion,
} from './XeroRequestCard.styles';
import { XeroRequestCardProps } from './XeroRequestCard.types';

const labelsEmailQuote = {
    title: messages.titleQuoteEmailToCustomer,
    attachmentsTitle: messages.attachmentsTitleQuoteEmailToCustomer,
};

const labelsEmailSalesInvoice = {
    title: messages.titleSalesInvoiceEmailToCustomer,
    attachmentsTitle: messages.attachmentsTitleSalesInvoiceEmailToCustomer,
};

export const XeroRequestCard = memo<XeroRequestCardProps>((props) => {
    const { className, request, preloadedRequestDetails, preloadedMatchingData, readonly } = props;
    const setOpenSplitView = useSetRecoilState(openSplitViewState);
    const setContentSplitView = useSetRecoilState(contentSplitViewState);

    const activeAttachmentId = useRecoilValue(activeAttachmentIdState);
    const company = useSelector((state: State) => selectors.company.getCompanyById(state, request.companyId));
    const workflows = useSelector((state: State) =>
        selectors.template.getIntegrationTemplatesByCompanyId(state, company.id)
    );

    const [pageVisiblePromotions, setPageVisiblePromotions] = useRecoilState(pageVisiblePromotionsState);
    const getNextPromotion = useGetNextPromotions();

    const closePromotion = useClosePromotion(request);

    useEffect(() => {
        const promotion = getNextPromotion(request, company, workflows);

        setPageVisiblePromotions(promotion ? [promotion] : null);
    }, [company, getNextPromotion, request, setPageVisiblePromotions, workflows]);

    useUnmount(() => {
        setPageVisiblePromotions(null);
    });

    const visiblePromotion = pageVisiblePromotions?.[0];

    const onClosePromotion = useCallback(
        (promotionType: AftPromotionType | AddonPromotionType) => {
            closePromotion(promotionType);
        },
        [closePromotion]
    );

    const onEmailToSupplierAttachmentOpen = (attachment: domain.RequestAttachment) => {
        setOpenSplitView(true);
        setContentSplitView({
            attachmentId: attachment.id,
            mode: ContentSplitViewMode.AttachmentsToEmailToSupplier,
        });
    };

    if (request.integrationType !== domain.IntegrationType.Xero) {
        throw errorHelpers.formatError();
    }

    const showMatchingV2Section = company.flags.isXeroMatchingV2 || company.flags.isXeroMatchingV2ReadOnly;

    const isBudgetingAllowed =
        company.integration?.integrationType === domain.IntegrationType.Xero &&
        company.licenseFeatures.includes(domain.CompanyLicenseFeature.XeroBudgets);

    const invoicePaymentDetails =
        request.integrationCode === domain.IntegrationCode.XeroBill && request.details.paymentDetails;
    const isPo = request.integrationCode === domain.IntegrationCode.XeroPo;
    const isQuote = request.integrationCode === domain.IntegrationCode.XeroQuote;
    const isBill = request.integrationCode === domain.IntegrationCode.XeroBill;
    const isInvoice = request.integrationCode === domain.IntegrationCode.XeroInvoice;
    const isBillBatchPayment = request.integrationCode === domain.IntegrationCode.XeroBillBatchPayment;
    const isAirwallexBatchPayment = request.integrationCode === domain.IntegrationCode.XeroAirwallexBatchPayment;
    const isXeroCreditNotesPayable = request.integrationCode === domain.IntegrationCode.XeroCreditNotesPayable;
    const isXeroCreditNotesReceivable = request.integrationCode === domain.IntegrationCode.XeroCreditNotesReceivable;
    const isXeroManualJournal = request.integrationCode === domain.IntegrationCode.XeroManualJournal;

    const isVisibleGRN =
        isGrnAvailable(company, domain.IntegrationType.Xero) &&
        company.xeroPurchaseOrderSettings?.isGrnEnabled &&
        request.integrationCode === domain.IntegrationCode.XeroPo &&
        request.statusV2 === domain.RequestStatusV2.Approved;

    const isCustomerDecisionSectionVisible =
        request.integrationCode === domain.IntegrationCode.XeroQuote &&
        request.statusV2 === domain.RequestStatusV2.Approved &&
        request.details.quoteStatus === domain.XeroQuoteStatus.Sent &&
        !isBoolean(request.details.isAccepted);

    const poEmailToSupplierReplacements = useMemo(() => {
        if (request.integrationCode === domain.IntegrationCode.XeroPo) {
            const supplier = request.details.contact;
            // TODO: should be provided by backend in request details
            // const contactPerson = supplier?.contactPersons?.find((p) => Boolean(p.firstName)) || null;

            return {
                [domain.SupplierEmailReplacements.poNumber]: request.details.number,
                [domain.SupplierEmailReplacements.totalAmount]: intl.formatCurrency(request.amount, request.currency),
                [domain.SupplierEmailReplacements.supplierName]: supplier?.text || null,
                [domain.SupplierEmailReplacements.customerName]: supplier?.text || null,
                [domain.SupplierEmailReplacements.companyName]: company?.displayName || null,
                // TODO: should be provided by backend in request details
                [domain.SupplierEmailReplacements.contactPersonName]: null, // contactPerson?.firstName || null,
            };
        }

        return {};
    }, [request, company]);

    const quoteEmailToSupplierReplacements = useMemo(() => {
        if (request.integrationCode === domain.IntegrationCode.XeroQuote) {
            const supplier = request.details.contact;
            // TODO: should be provided by backend in request details
            // const contactPerson = supplier?.contactPersons?.find((p) => Boolean(p.firstName)) || null;

            return {
                [domain.SupplierEmailReplacements.poNumber]: null,
                [domain.SupplierEmailReplacements.quoteNumber]: request.details.number,
                [domain.SupplierEmailReplacements.totalAmount]: intl.formatCurrency(request.amount, request.currency),
                [domain.SupplierEmailReplacements.supplierName]: supplier?.text || null,
                [domain.SupplierEmailReplacements.customerName]: supplier?.text || null,
                [domain.SupplierEmailReplacements.companyName]: company?.displayName || null,
                // TODO: should be provided by backend in request details
                [domain.SupplierEmailReplacements.contactPersonName]: null, // contactPerson?.firstName || null,
                [domain.SupplierEmailReplacements.terms]: request.details.terms || null,
                [domain.SupplierEmailReplacements.decisionMakingWebPageLink]: null,
            };
        }

        return {};
    }, [request, company]);

    const salesInvoiceEmailToSupplierReplacements = useMemo(() => {
        if (request.integrationCode === domain.IntegrationCode.XeroInvoice) {
            const supplier = request.details.contact;
            // TODO: should be provided by backend in request details
            // const contactPerson = supplier?.contactPersons?.find((p) => Boolean(p.firstName)) || null;

            return {
                [domain.SupplierEmailReplacements.poNumber]: null,
                [domain.SupplierEmailReplacements.quoteNumber]: null,
                [domain.SupplierEmailReplacements.invoiceNumber]: request.details.number,
                [domain.SupplierEmailReplacements.totalAmount]: intl.formatCurrency(request.amount, request.currency),
                [domain.SupplierEmailReplacements.customerName]: supplier?.text || null,
                [domain.SupplierEmailReplacements.companyName]: company?.displayName || null,
                // TODO: should be provided by backend in request details
                [domain.SupplierEmailReplacements.contactPersonName]: null, // contactPerson?.firstName || null,
            };
        }

        return {};
    }, [request, company]);

    return (
        <Root className={className}>
            {(visiblePromotion === AddonPromotionType.Pay || visiblePromotion === AddonPromotionType.Capture) && (
                <RequestListPayAndCapturePromotion type={visiblePromotion} />
            )}

            <Toolbar
                request={request}
                preloadedRequestDetails={preloadedRequestDetails}
                preloadedMatchingData={preloadedMatchingData}
                readonly={readonly}
            />

            {(isBill ||
                isInvoice ||
                isBillBatchPayment ||
                isXeroCreditNotesPayable ||
                isXeroCreditNotesReceivable ||
                isQuote ||
                isXeroManualJournal) && <PendingStatusSection request={request} />}

            <Body scrollResetKey={request.id}>
                <Content>
                    <RequestNoteSection text={request.requestNote.value} />

                    {invoicePaymentDetails && (
                        <XeroReceiptBankPaymentDetailsSection paymentDetails={invoicePaymentDetails} />
                    )}

                    {isCustomerDecisionSectionVisible && <CustomerDecisionSection request={request} />}

                    <XeroFieldsSection request={request} />

                    {isVisibleGRN && <GoodsReceivedNotes request={request} readonly={readonly} />}

                    {!isVisibleGRN && visiblePromotion === AftPromotionType.GoodsReceivedNote && (
                        <StyledFeaturePromotion
                            company={company}
                            integrationType={domain.IntegrationType.Xero}
                            promotionType={AftPromotionType.GoodsReceivedNote}
                            onClose={onClosePromotion}
                        />
                    )}

                    {request.integrationCode === domain.IntegrationCode.XeroManualJournal && (
                        <XeroManualJournalLinesSection request={request} />
                    )}

                    {(isBill ||
                        isPo ||
                        isInvoice ||
                        isQuote ||
                        isXeroCreditNotesPayable ||
                        isXeroCreditNotesReceivable) && <XeroLineItemsSection request={request} />}

                    <AttachmentsSection request={request} />

                    {isBudgetingAllowed && !company.isReadonly && !readonly && (
                        <XeroBudgetingSection request={request} />
                    )}

                    {!isBudgetingAllowed && visiblePromotion === AftPromotionType.XeroBudgetChecking && (
                        <StyledFeaturePromotion
                            company={company}
                            integrationType={domain.IntegrationType.Xero}
                            promotionType={AftPromotionType.XeroBudgetChecking}
                            onClose={onClosePromotion}
                        />
                    )}

                    {isPo && <XeroBilledStatusSection request={request} readonly={readonly} />}

                    {showMatchingV2Section && (
                        <XeroMatchingV2Section
                            request={request}
                            preloadedMatchingData={preloadedMatchingData}
                            readonly={readonly}
                        />
                    )}

                    {!showMatchingV2Section &&
                        (visiblePromotion === AftPromotionType.BillToPoMatchingBill ||
                            visiblePromotion === AftPromotionType.BillToPoMatchingPO) && (
                            <StyledFeaturePromotion
                                company={company}
                                integrationType={domain.IntegrationType.Xero}
                                promotionType={visiblePromotion}
                                onClose={onClosePromotion}
                            />
                        )}

                    {isPo && (
                        <>
                            <EmailToSupplier
                                request={request}
                                placeholderReplacements={poEmailToSupplierReplacements}
                                onAttachmentOpen={onEmailToSupplierAttachmentOpen}
                                activeAttachmentId={activeAttachmentId}
                            />

                            <XeroPoDeliveryDetails details={request.details} />
                        </>
                    )}

                    {isQuote && (
                        <EmailToSupplier
                            request={request}
                            labels={labelsEmailQuote}
                            placeholderReplacements={quoteEmailToSupplierReplacements}
                            onAttachmentOpen={onEmailToSupplierAttachmentOpen}
                            activeAttachmentId={activeAttachmentId}
                        />
                    )}

                    {isInvoice && (
                        <EmailToSupplier
                            request={request}
                            labels={labelsEmailSalesInvoice}
                            placeholderReplacements={salesInvoiceEmailToSupplierReplacements}
                            onAttachmentOpen={onEmailToSupplierAttachmentOpen}
                            activeAttachmentId={activeAttachmentId}
                        />
                    )}

                    <WorkflowSection request={request} readonly={readonly} />

                    <ActivitySection request={request} />
                </Content>
            </Body>

            {!request.flags.status.isDraft && !readonly && <StyledAddCommentSection request={request} />}

            {isAirwallexBatchPayment && <SchedulePaymentPopup request={request} />}
        </Root>
    );
});

XeroRequestCard.displayName = 'XeroRequestCard';
