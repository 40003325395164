import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { matchingApiPaths } from '../paths';
import type { AddMatching } from './useAddMatching.types';

export const useAddMatching = (mutationOptions?: RequestMutationOptions<AddMatching>) =>
    useMutate(matchingApiPaths.addMatching, {
        mutationOptions,
        apiVersion: 'v1',
        method: 'post',
    });
