import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages(
    'pages.RequestList.XeroAmaxPayBatchPaymentRequestCard.TransactionDetailsPopupContent',
    {
        transactionDetails: 'Transaction details',
        date: 'Date',
        status: 'Status',
        source: 'Source',
        paymentDetails: 'Payment details',
        total: 'Total',
        emptyContent: 'No transactions were initiated yet.',
        billsFrom: 'Bills from {contactName}',
        paymentIsScheduled: 'Payment is scheduled on {date}',
    }
);
