import { DuplicateIcon } from 'modules/sprites';
import { FC, memo } from 'react';
import { Link } from 'react-router-dom';
import { getPath, Path } from 'urlBuilder';

import { messages } from './DuplicateBillWarning.messages';
import { BillItem, Container, Description, IconContainer, Wrapper } from './DuplicateBillWarning.styles';
import { DuplicateBillWarningProps } from './DuplicateBillWarning.types';

const DuplicateBillWarning: FC<DuplicateBillWarningProps> = (props) => {
    const { possibleDuplicates, className, companyId } = props;

    return (
        <Wrapper className={className}>
            <Container>
                <IconContainer>
                    <DuplicateIcon />
                </IconContainer>

                <div>
                    <Description>{messages.description}</Description>

                    {possibleDuplicates.map((item) => (
                        <BillItem key={item.requestId}>
                            <Link
                                to={getPath(Path.request, item.requestId, companyId)}
                                target='_blank'
                                rel='noreferrer'
                            >
                                {item.friendlyName}
                            </Link>
                        </BillItem>
                    ))}
                </div>
            </Container>
        </Wrapper>
    );
};

DuplicateBillWarning.displayName = 'DuplicateBillWarning';

export default memo(DuplicateBillWarning);
