import { AmLogo, Button } from '@approvalmax/ui';
import { Flex, Popup } from '@approvalmax/ui/src/components';
import { dateTimeHelpers } from '@approvalmax/utils';
import { memo } from 'react';

import { messages } from './AuthWarningPopup.message';
import { Actions, Description, Title } from './AuthWarningPopup.styles';
import { AuthWarningPopupProps } from './AuthWarningPopup.types';

const AuthWarningPopup = memo<AuthWarningPopupProps>((props) => {
    const { remainingTimeMs, loading, open, onRequestClose } = props;

    const time = dateTimeHelpers.formatTime(remainingTimeMs);

    return (
        <Popup open={open} closable={false} size='xsmall' zIndex={2000}>
            <Popup.Body spacing='32 80 40 80'>
                <Flex direction='column' alignItems='center' spacing='0'>
                    <AmLogo width={140} height={27} />

                    <Title>{messages.title({ time })}</Title>

                    <Description>{messages.description}</Description>

                    <Actions>
                        <Button execute={onRequestClose} disabled={loading}>
                            {messages.continueButton}
                        </Button>
                    </Actions>
                </Flex>
            </Popup.Body>
        </Popup>
    );
});

export default AuthWarningPopup;
