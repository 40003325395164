import { Reference } from '@approvalmax/types';

import { Address } from './Request';

export enum QBooksAddressType {
    Postal = 'Postal',
    Physical = 'Physical',
}

export interface QBooksAddress extends Reference {
    type: QBooksAddressType;
    address: Address;
    attentionTo: string;
}
