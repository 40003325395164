/**
 * Required format for `date`
 *
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/date
 */
export const textFieldMonthFormat = 'YYYY-MM-DD';

export const textFieldYearFormat = 'YYYY-MM';

export const textFieldYearSafariFormat = 'MMMM YYYY';
