import { MutateDataParams } from '@approvalmax/data';
import { useMutateLegacy } from 'shared/data';

import { twoFaApiPaths } from '../paths';
import { VerifyTFAWithEmailCodeRequest, VerifyTFAWithEmailCodeResponse } from './useVerifyTFAWithEmailCode.types';

export const useVerifyTFAWithEmailCode = () => {
    return useMutateLegacy<MutateDataParams<VerifyTFAWithEmailCodeRequest>, VerifyTFAWithEmailCodeResponse>(
        twoFaApiPaths.verifyTFAWithEmailCode
    );
};
