import { DropdownEditor, FocusImplementer } from '@approvalmax/ui';
import { FC, memo } from 'react';

import NetSuiteCustomReferenceDataProvider from '../NetSuiteCustomReferenceDataProvider/NetSuiteCustomReferenceDataProvider';
import { NetSuiteLineItemCustomReferenceProps } from './NetSuiteLineItemCustomReferenceCell.types';

export const NetSuiteLineItemCustomReferenceCell: FC<NetSuiteLineItemCustomReferenceProps> = memo((props) => {
    const { value, onChange, placeholder, singleLinePerItem, sublistType, fieldId, disabled, multiple } = props;

    return (
        <FocusImplementer>
            {({ registerFocusable }) => (
                <NetSuiteCustomReferenceDataProvider fieldId={fieldId} sublistType={sublistType}>
                    <DropdownEditor
                        ref={registerFocusable}
                        value={value || null}
                        singleLinePerItem={singleLinePerItem}
                        placeholder={placeholder}
                        multiple={multiple}
                        onChange={onChange}
                        theme='transparent'
                        disabled={disabled}
                    />
                </NetSuiteCustomReferenceDataProvider>
            )}
        </FocusImplementer>
    );
});

NetSuiteLineItemCustomReferenceCell.displayName = 'NetSuiteLineItemCustomReferenceCell';
