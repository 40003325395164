import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { authApiPaths } from '../paths';
import { DeleteFido2Credential } from './useDeleteFido2Credential.types';

export const useDeleteFido2Credential = (mutationOptions?: RequestMutationOptions<DeleteFido2Credential>) => {
    return useMutate(authApiPaths.fido2Credentials, {
        mutationOptions,
        method: 'delete',
        apiVersion: 'v2',
    });
};
