import { Action, ActionMeta } from '../types/Action';

export function createErrorAction<T extends string, P>(
    type: T,
    error: Error,
    payload: P = {} as P,
    meta?: ActionMeta
): Action<T, P, ActionMeta, undefined> {
    const result: Action<T, P, ActionMeta, undefined> = {
        type,
        payload: payload || ({} as P),
        error,
    };

    if (meta) {
        result.meta = meta;
    }

    return result;
}
