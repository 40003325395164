import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { isEnoughDaysAfter } from 'shared/helpers';

const storageKeyClosedBillToPoMatchingBill = 'closedPromotionBillToPoMatchingBill';
const storageKeyClosedXeroBudgetCheckingBill = 'closedPromotionXeroBudgetCheckingBill';

const storageKeyClosedBillToPoMatchingPO = 'closedPromotionBillToPoMatchingPO';
const storageKeyClosedXeroBudgetCheckingPO = 'closedPromotionXeroBudgetCheckingPO';
const storageKeyClosedGoodsReceivedNotePO = 'closedPromotionClosedGoodsReceivedNotePO';

const sessionKeyTimeClosedBillPromo = 'closedTimeBillPromo';
const sessionKeyTimeClosedPOPromo = 'closedTimePOPromo';

export const canShowNextBillPromo = () => {
    return isEnoughDaysAfter(2, sessionStorage.getItem(sessionKeyTimeClosedBillPromo));
};

export const canShowNextPOPromo = () => {
    return isEnoughDaysAfter(2, sessionStorage.getItem(sessionKeyTimeClosedPOPromo));
};

export const isBillToPoMatchingBillClosed = () => {
    return localStorage.getItem(storageKeyClosedBillToPoMatchingBill) === 'true';
};

export const closeBillToPoMatchingBill = () => {
    localStorage.setItem(storageKeyClosedBillToPoMatchingBill, 'true');
    sessionStorage.setItem(sessionKeyTimeClosedBillPromo, String(Date.now()));
};

export const isXeroBudgetCheckingBillClosed = () => {
    return localStorage.getItem(storageKeyClosedXeroBudgetCheckingBill) === 'true';
};

export const closeXeroBudgetCheckingBill = () => {
    localStorage.setItem(storageKeyClosedXeroBudgetCheckingBill, 'true');
    sessionStorage.setItem(sessionKeyTimeClosedBillPromo, String(Date.now()));
};

export const isBillToPoMatchingPOClosed = () => {
    return localStorage.getItem(storageKeyClosedBillToPoMatchingPO) === 'true';
};

export const closeBillToPoMatchingPO = () => {
    localStorage.setItem(storageKeyClosedBillToPoMatchingPO, 'true');
    sessionStorage.setItem(sessionKeyTimeClosedPOPromo, String(Date.now()));
};

export const isXeroBudgetCheckingPOClosed = () => {
    return localStorage.getItem(storageKeyClosedXeroBudgetCheckingPO) === 'true';
};

export const closeXeroBudgetCheckingPO = () => {
    localStorage.setItem(storageKeyClosedXeroBudgetCheckingPO, 'true');
    sessionStorage.setItem(sessionKeyTimeClosedPOPromo, String(Date.now()));
};

export const isGoodsReceivedNotePOClosed = () => {
    return localStorage.getItem(storageKeyClosedGoodsReceivedNotePO) === 'true';
};

export const closeGoodsReceivedNotePO = () => {
    localStorage.setItem(storageKeyClosedGoodsReceivedNotePO, 'true');
    sessionStorage.setItem(sessionKeyTimeClosedPOPromo, String(Date.now()));
};

export const isEnabledBillAndPOWorkflows = (
    workflows: selectors.types.ExpandedTemplate[],
    integrationType: domain.IntegrationType.Xero | domain.IntegrationType.QBooks
) => {
    const isQbooks = integrationType === domain.IntegrationType.QBooks;
    const codePO = isQbooks ? domain.IntegrationCode.QBooksPo : domain.IntegrationCode.XeroPo;
    const codeBill = isQbooks ? domain.IntegrationCode.QBooksBill : domain.IntegrationCode.XeroBill;

    let isPOWfEnabled = false;
    let isBillWfEnabled = false;

    workflows.forEach((wf) => {
        if (wf.integrationCode === codePO && wf.enabled) {
            isPOWfEnabled = true;
        } else if (wf.integrationCode === codeBill && wf.enabled) {
            isBillWfEnabled = true;
        }
    });

    return isPOWfEnabled && isBillWfEnabled;
};

const processedStatus = [
    domain.RequestStatusV2.Approved,
    domain.RequestStatusV2.Cancelled,
    domain.RequestStatusV2.Rejected,
];

export const isUnprocessedBill = (request: selectors.types.ExpandedRequest) => {
    const isQbooks = request.integrationType === domain.IntegrationType.QBooks;
    const codeBill = isQbooks ? domain.IntegrationCode.QBooksBill : domain.IntegrationCode.XeroBill;

    return request.integrationCode === codeBill && !processedStatus.includes(request.statusV2);
};

export const isApprovedPO = (request: selectors.types.ExpandedRequest) => {
    const isQbooks = request.integrationType === domain.IntegrationType.QBooks;
    const codePO = isQbooks ? domain.IntegrationCode.QBooksPo : domain.IntegrationCode.XeroPo;

    return request.integrationCode === codePO && request.statusV2 === domain.RequestStatusV2.Approved;
};
