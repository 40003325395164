import type { ApiError } from '@approvalmax/types';
import { routerHelpers } from '@approvalmax/utils';
import { useQuery } from '@tanstack/react-query';
import { State } from 'modules/data';
import { hooks as pageHooks } from 'modules/page';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { loadPageData, loadRequestContext } from '../actions';
import { QueryKeys } from '../config';

const useEditRequestPageData = (
    enabled: boolean = true,
    onSuccessCallback?: (data: Awaited<ReturnType<typeof loadRequestContext>>) => void
) => {
    const dispatch = useDispatch();
    const params = useParams<{ companyId: string; requestId: string }>();
    const search = useLocation().search;
    const requestId = params.requestId?.toLocaleLowerCase() || '';
    const activeAttachmentId = routerHelpers.getParamAsString(search, 'activeAttachmentId', '');
    const state = useSelector((state: State) => state);
    const { company } = pageHooks.useNonExistentCompanyError(params.companyId);

    const { error, isInitialLoading, refetch } = useQuery<Awaited<ReturnType<typeof loadRequestContext>>, ApiError>(
        [QueryKeys.EditReqeustPage, requestId, params.companyId],
        async () => {
            return await loadRequestContext(requestId, params.companyId, dispatch, state);
        },
        {
            enabled: enabled && !!company,
            onSuccess: (data) => {
                if (data) {
                    dispatch(loadPageData({ ...data, activeAttachmentId }));
                }

                onSuccessCallback?.(data);
            },
        }
    );

    pageHooks.usePageError(error);

    return {
        isInitialLoading,
        refetch,
    };
};

export default useEditRequestPageData;
