import { domain } from 'modules/data';
import { atom } from 'recoil';

export type NetSuiteBillPaymentFields = Omit<
    domain.NetSuiteBillPaymentDetails,
    'applyList' | 'creditList' | 'payee'
> & {
    payee: domain.NetSuitePayee | null;
};

export const netSuiteBillPaymentFieldValuesState = atom<Partial<NetSuiteBillPaymentFields>>({
    key: 'netSuiteBillPaymentFieldValuesState',
    default: {},
});
