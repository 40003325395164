import './activityItem.scss';

import { Guid } from '@approvalmax/types';
import { AmCircleLogo, QBooksLogo, TransparentButton } from '@approvalmax/ui';
import { openSplitViewState } from '@approvalmax/ui/src/components';
import { errorHelpers, miscHelpers } from '@approvalmax/utils';
import { selectors, ui } from 'modules/common';
import { domain } from 'modules/data';
import { addArrayItem, removeArrayItem } from 'modules/immutable';
import {
    AttachmentMiniIcon,
    BubbleCloseIcon,
    Cin7Logo,
    DextLogoIcon,
    OracleNetsuiteLogo,
    XeroIcon,
} from 'modules/sprites';
import moment from 'moment';
import { FC, Fragment, memo, SVGProps, useMemo, useState } from 'react';
import bemFactory from 'react-bem-factory';
import { FormattedMessage } from 'react-intl';
import { useSetRecoilState } from 'recoil';
import { TextWithMentions } from 'shared/components';
import { ContentSplitViewMode, contentSplitViewState } from 'shared/states';

import {
    getEventChanges,
    getEventFooter,
    getEventHeader,
    getSystemEventOrigin,
} from '../../selectors/requestActivitySelectors';
import { messages } from './ActivityItem.messages';

const i18nPrefix = 'requestList/components/activitySection/CommentItem';

const bem = bemFactory.block('reql-activity-item');
const qa = bemFactory.qa('reql-activity-item');

interface ActivityItemProps {
    className?: string;
    request: selectors.types.ExpandedRequest;
    event: domain.RequestHistoryEvent;
    me: selectors.types.ExpandedUser;
    getUser: (userId: Guid) => selectors.types.ExpandedUser;
    onRemoveCommentAttachment: (attachmentId: string) => void | Promise<void>;
}

const grnEventTypes = [
    domain.RequestHistoryEventType.GrnChangedToNotReceived,
    domain.RequestHistoryEventType.GrnChangedToPartiallyReceived,
    domain.RequestHistoryEventType.GrnChangedToFullyReceived,
];

const userPlatformLabel: Record<domain.RequestHistoryUserPlatform, string> = {
    [domain.RequestHistoryUserPlatform.Web]: 'Web',
    [domain.RequestHistoryUserPlatform.Mobile]: 'Mobile app',
    [domain.RequestHistoryUserPlatform.Slack]: 'Slack',
    [domain.RequestHistoryUserPlatform.Email]: 'Email',
};

const ActivityItem: FC<ActivityItemProps> = (props) => {
    const { className, event, request, me, getUser, onRemoveCommentAttachment } = props;

    const [halfDeletedCommentAttachments, setHalfDeletedCommentAttachments] = useState<string[]>([]);

    let isSystem = event.isSystem || event.authorId === 'fakeemailforfakeuser@fakeemailforfakeuser.fffff';

    const author = !isSystem && event.authorId ? getUser(event.authorId) : null;

    const dateText = moment(event.date).format('lll');
    const userPlatformText =
        !event.isSystem && event.userPlatform ? ` via ${userPlatformLabel[event.userPlatform]}` : '';

    const changes = getEventChanges(request, event);

    const setOpenSplitView = useSetRecoilState(openSplitViewState);
    const setContentSplitView = useSetRecoilState(contentSplitViewState);

    let SystemIcon: FC<SVGProps<SVGSVGElement>> | undefined;

    if (isSystem) {
        if (
            event.type === domain.RequestHistoryEventType.PulledFromSource &&
            request.origin === domain.RequestOrigin.ReceiptBank
        ) {
            SystemIcon = DextLogoIcon;
        } else {
            const systemIconType = getSystemEventOrigin(request, event);

            switch (systemIconType) {
                case domain.IntegrationType.Xero:
                    SystemIcon = XeroIcon;
                    break;

                case domain.IntegrationType.QBooks:
                    SystemIcon = QBooksLogo;
                    break;

                case domain.IntegrationType.NetSuite:
                    SystemIcon = OracleNetsuiteLogo;
                    break;

                case domain.IntegrationType.Dear:
                    SystemIcon = Cin7Logo;
                    break;

                case domain.IntegrationType.None:
                    SystemIcon = AmCircleLogo;
                    break;

                default:
                    throw errorHelpers.assertNever(systemIconType);
            }
        }
    }

    const message = useMemo(() => {
        const header = <span>{getEventHeader(request, event, me, getUser, Boolean(changes))}</span>;
        const footer = <span>{getEventFooter(request, event)}</span>;

        let status = '';

        switch (event.type) {
            case domain.RequestHistoryEventType.GrnChangedToNotReceived:
                status = messages.grnNotReceived;
                break;

            case domain.RequestHistoryEventType.GrnChangedToPartiallyReceived:
                status = messages.grnPartiallyReceived;
                break;

            case domain.RequestHistoryEventType.GrnChangedToFullyReceived:
                status = messages.grnFullyReceived;
                break;
        }

        const grnDescription = grnEventTypes.includes(event.type) ? messages.grnDescription({ status }) : null;

        const description = <TextWithMentions html={event.description} request={request} />;

        const content = [header, grnDescription, changes, footer].filter(miscHelpers.notEmptyFilter);

        content.splice(1, 0, description);

        return content;
    }, [changes, event, getUser, me, request]);

    const myEvent = event.authorId === me.id && !isSystem;
    const commentDeletionDisabled = !myEvent || true;

    return (
        <div className={bem.add(className)(null, { right: myEvent })} data-qa={qa()} data-qa-id={event.id}>
            {author ? (
                <Fragment>
                    <ui.UserHover className={bem('icon')} user={author}>
                        <ui.Avatar user={author} size={30} />
                    </ui.UserHover>

                    <div className={bem('author-name-row')}>
                        <ui.UserHover className={bem('author-name')} qa={qa('author-name')} user={author}>
                            {event.onBehalfOfId ? (
                                <FormattedMessage
                                    id={`${i18nPrefix}.onBehalfOfDisplayName`}
                                    defaultMessage='{author} on behalf of {onBehalfOf}'
                                    values={{
                                        author: author.displayName,
                                        onBehalfOf: getUser(event.onBehalfOfId).displayName,
                                    }}
                                />
                            ) : (
                                author.displayName
                            )}
                        </ui.UserHover>
                    </div>
                </Fragment>
            ) : (
                <div
                    className={bem('icon', {
                        rounded: SystemIcon !== AmCircleLogo,
                        system: true,
                        unclickable: true,
                    })}
                >
                    {SystemIcon && <SystemIcon width={30} height={30} />}
                </div>
            )}

            {message && (
                <div className={bem('message-row')} data-qa={qa('message')}>
                    {message.map((message, index) => (
                        <Fragment key={index}>{message}</Fragment>
                    ))}
                </div>
            )}

            {event.comment && (
                <>
                    {Boolean((event.comment.text || '').trim()) && (
                        <div className={bem('comment-row')}>
                            <div className={bem('comment-text')} data-qa={qa('comment-text')}>
                                <TextWithMentions
                                    html={event.comment.text || ''}
                                    mentionedUsers={event.comment.mentionedUsers}
                                    request={request}
                                />
                            </div>
                        </div>
                    )}

                    {event.comment.attachments.length > 0 && (
                        <div className={bem('comment-files-row')} data-qa={qa('comment-files')}>
                            {event.comment.attachments.map((attachment) => {
                                const deleting = halfDeletedCommentAttachments.includes(attachment.id);

                                return (
                                    <div
                                        key={attachment.id}
                                        className={bem('comment-file-item', { deleting })}
                                        title={attachment.name}
                                        onClick={() => {
                                            if (event.comment) {
                                                setOpenSplitView(true);
                                                setContentSplitView({
                                                    attachmentId: attachment.id,
                                                    mode: ContentSplitViewMode.AttachmentsToComment,
                                                    commentId: event.comment.id,
                                                });
                                            }
                                        }}
                                    >
                                        <AttachmentMiniIcon
                                            className={bem('comment-file-icon')}
                                            width={16}
                                            height={14}
                                        />

                                        <div className={bem('comment-file-text')}>{attachment.name}</div>

                                        {!commentDeletionDisabled && !deleting && (
                                            <TransparentButton
                                                execute={async () => {
                                                    setHalfDeletedCommentAttachments(
                                                        addArrayItem(halfDeletedCommentAttachments, attachment.id)
                                                    );

                                                    try {
                                                        await onRemoveCommentAttachment(attachment.id);
                                                    } finally {
                                                        setHalfDeletedCommentAttachments(
                                                            removeArrayItem(
                                                                halfDeletedCommentAttachments,
                                                                attachment.id
                                                            )
                                                        );
                                                    }
                                                }}
                                            >
                                                <BubbleCloseIcon
                                                    className={bem('comment-file-delete-button')}
                                                    width={9}
                                                    height={9}
                                                />
                                            </TransparentButton>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </>
            )}

            <div className={bem('event-date')} data-qa={qa('event-date')}>
                {dateText}

                {userPlatformText}
            </div>
        </div>
    );
};

ActivityItem.displayName = 'ActivityItem';

export default memo(ActivityItem);
